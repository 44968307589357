import Axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { ADMIN_SESSION_KEY } from "../types/adm_elements";
import { getSessionKey } from "./Ls";

export const WrapperAxios = {
  ...Axios,
  get: (url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>> => {
    const _config: any = { ...config };
    const headers = _config.headers ? _config?.headers : {};
    headers[ADMIN_SESSION_KEY] = getSessionKey();
    _config["headers"] = headers;
    return Axios.get(url, _config);
  },
  post: (url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>> => {
    const _config: any = { ...config };
    const headers = _config.headers ? _config?.headers : {};
    headers[ADMIN_SESSION_KEY] = getSessionKey();
    _config["headers"] = headers;
    return Axios.post(url, data, _config);
  },
  put: (url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>> => {
    const _config: any = { ...config };
    const headers = _config.headers ? _config?.headers : {};
    headers[ADMIN_SESSION_KEY] = getSessionKey();
    _config["headers"] = headers;
    return Axios.put(url, data, _config);
  },
  delete: (url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>> => {
    const _config: any = { ...config };
    const headers = _config.headers ? _config?.headers : {};
    headers[ADMIN_SESSION_KEY] = getSessionKey();
    _config["headers"] = headers;
    return Axios.delete(url, _config);
  },
};
