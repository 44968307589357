import { Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import useItemMeta from "../../../hooks/useItemMeta";
import { ItemMeta, ItemMetaType } from "../../../types/elements";
import ItemMetaDialog from "./ItemMetaDialog";

interface ItemMetaListProps {
  type: ItemMetaType;
}
const ItemMetaList: React.FC<ItemMetaListProps> = (props) => {
  const [list, setList] = useState<ItemMeta[]>([]);
  const { refresh } = useItemMeta();

  const load = useCallback(() => {
    gqladm.getItemMetas({ type: props.type }).then((result) => {
      console.log("getItemMetas", result);
      if (result.list) setList(result.list);
    });
    refresh();
  }, []);
  useEffect(() => {
    load();
  }, []);

  const [obj, setObj] = useState<ItemMeta | undefined>();
  const handleCloseObjDialog = (refresh?: boolean) => {
    setObj(undefined);
    if (refresh) load();
  };

  const handleClickCreate = () => setObj({ type: props.type, seq: 0 });
  const handleClickEdit = (im: ItemMeta) => setObj({ ...im });
  const handleClickDelete = (id: number) => {
    console.log("handleClickDelete", id);
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      gqladm.removeItemMeta(id).then((result) => {
        if (result.ok) {
          load();
        }
      });
    }
  };

  return (
    <div>
      <div>
        <Button variant="contained" onClick={handleClickCreate}>
          Create
        </Button>
        <ItemMetaDialog open={obj !== undefined} itemMeta={obj} onClose={handleCloseObjDialog} />
      </div>
      <div>
        {list.map((im) => {
          return <ItemMetaListItem im={im} onEdit={handleClickEdit} onDelete={handleClickDelete} />;
        })}
      </div>
    </div>
  );
};

const ItemMetaListItem: React.FC<{
  im: ItemMeta;
  onEdit: (im: ItemMeta) => any;
  onDelete: (id: number) => any;
}> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        border: "1px solid lightgray",
        padding: "10px",
      }}>
      <div>
        id:{props.im.id} - name:{props.im.name} - desc:{props.im.desc} - seq:{props.im.seq} - key:
        {props.im.key}
      </div>
      <div>
        <Button variant="contained" onClick={() => props.onEdit(props.im)}>
          Edit
        </Button>
        <Button variant="contained" onClick={() => props.im.id && props.onDelete(props.im.id)}>
          Delete
        </Button>
      </div>
    </div>
  );
};
export default ItemMetaList;
