import React from "react";

export const FlexBetween: React.FC<{
  left?: any;
  right?: any;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...props.style,
      }}>
      {props.left}
      {props.children} {props.right}
    </div>
  );
};

export const FlexDirCol: React.FC<{
  top?: any;
  bottom?: any;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}> = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", ...props.style }}>
      {props.top} {props.children} {props.bottom}
    </div>
  );
};

export const FlexBox: React.FC<{
  first?: any;
  last?: any;
  style?: React.CSSProperties;
  className?: string;
  dir?: "column" | "row";
  ialign?: "center";
  jalign?: "center" | "space-between";
  children?: React.ReactNode;
}> = (props) => {
  return (
    <div
      className={props.className ? props.className : undefined}
      style={{
        display: "flex",
        flexDirection: props.dir ? props.dir : undefined,
        alignItems: props.ialign ? props.ialign : undefined,
        justifyContent: props.jalign ? props.jalign : undefined,
        ...props.style,
      }}>
      {props.first} {props.children} {props.last}
    </div>
  );
};

export const KvDiv: React.FC<{
  k: any;
  k_tail?: any;
  v: any;
  dir?: "row" | "column";
  style?: React.CSSProperties;
  className?: string;
}> = (props) => {
  const layout: React.CSSProperties = {};
  if (props.dir === "column") {
    layout.flexDirection = "column";
  } else {
    layout.flexDirection = "row";
    layout.justifyContent = "space-between";
    layout.alignItems = "center";
  }
  return (
    <div className={props.className} style={{ display: "flex", ...layout, ...props.style }}>
      {props.dir === "column" ? (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>{props.k}</div>
          {props.k_tail && <div>{props.k_tail}</div>}
        </div>
      ) : (
        <div>{props.k}</div>
      )}
      <div>{props.v}</div>
    </div>
  );
};

// KvTable
export const KvTable: React.FC<{ children?: React.ReactNode }> = (props) => {
  return (
    <table>
      <tbody>{props.children}</tbody>
    </table>
  );
};
export const KvTr: React.FC<{ k: any; v: any }> = (props) => {
  return (
    <tr>
      <td>{props.k}</td>
      <td>{props.v}</td>
    </tr>
  );
};
