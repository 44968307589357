import React, { useState, useEffect, createRef } from "react";
import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../../store/states/SnackState";

import { ImageAPI } from "../../api";

import CloudUpload from "@mui/icons-material/CloudUpload";
import { Image, APIResStatus } from "../../types/elements";
import { Button, IconButton } from "@mui/material";
import { useAppCommon } from "../../hooks/useAppCommon";

interface PublicImageViewProps {}

const PublicImageView: React.FC<PublicImageViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "Public Images" });
  const openSnack = useSetRecoilState(SnackStateSelector);
  const [images, setImages] = useState<Image[]>([]);

  const load = () => {
    ImageAPI.getItemImages(-1).then((result) => {
      console.log(result);
      const data = result.data;
      if (data.result) {
        setImages(data.result);
      }
    });
  };

  useEffect(() => {
    load();
  }, []);

  const fileUploadInput = createRef<HTMLInputElement>();

  const onClickImageUploadButton = () => {
    console.log("onClickImageUploadButton", fileUploadInput);
    if (fileUploadInput && fileUploadInput.current) {
      fileUploadInput.current.click();
    }
  };

  const handleFiles = (e: any) => {
    const files = Array.from<File>(e.target.files);
    if (FileReader && files && files.length) {
      const _images: Array<Image> = [...images];
      for (const i in files) {
        const file = files[i];
        const image: Image = {
          name: file.name,
          type: file.type,
          modified: file.lastModified,
          file: file,
          url: "",
          itemId: -1,
        };
        _images.push(image);

        ImageAPI.postItemImage(image)
          .then((result) => {
            console.log("handleUploadImage", result);
            const data = result.data;
            if (data.result) {
              openSnack({ msg: "이미지가 업로드되었습니다." });
              image.url = data.result.url;
              setImages(_images);
            }
          })
          .catch((err) => {
            openSnack({
              msg: "이미지가 업로드가 실패하였습니다." + err.message,
              severity: "error",
            });
          });
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDeleteImage = async (image: Image) => {
    const confirm = window.confirm("Are you sure?");
    if (confirm === true && image.uid) {
      console.log(image);
      ImageAPI.deleteItemImage(image.uid).then((result) => {
        console.log(result);
        const data = result.data;
        if (data.status === "SUCCESS" && data.result) {
          const _images = images.filter((o) => o.id !== image.id);
          setImages(_images);
        }
      });
    }
  };

  return (
    <div>
      <IconButton onClick={onClickImageUploadButton}>
        <CloudUpload /> Upload Image
      </IconButton>
      <input ref={fileUploadInput} type="file" style={{ display: "none" }} onChange={handleFiles} />
      <hr />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {images.map((o: Image, idx: number) => {
          return (
            <div
              style={{ width: "200px", padding: "5px", border: "1px solid lightgray" }}
              key={idx}>
              <Button onClick={() => handleDeleteImage(o)}>삭제</Button>
              <br />
              File Name: {o.name}
              <br />
              URL: /{o.url}
              <br />
              <img style={{ width: "100%" }} src={o.url} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PublicImageView;
