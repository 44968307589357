import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  TableBody,
  Table,
} from "@mui/material";
import { User } from "../../types/elements";
import { UserSearchOption } from "../../types/adm_elements";
import { client } from "../../graphql/client";
import { QueryIF } from "../../graphql/types";
import { gql } from "apollo-boost";
import UserTableHeader from "../molecules/UserTableHeader";
import UserTableBodyRow from "../molecules/UserTableBodyRow";
import { gqladm } from "../../graphql/gqladm";
// import { Pagination } from "@mui/lab";
import Pagination from "@mui/material/Pagination";

const GET_USERS = gql`
  query GetUsers($text: String) {
    users(text: $text) {
      total
      result {
        email
        name
        phone
      }
    }
  }
`;

const LIMIT = 20;
interface SelectUserDialogProps {
  open: boolean;
  onClose: () => any;
  onSelect: (user: User) => any;
}
const SelectUserDialog: React.FC<SelectUserDialogProps> = (props) => {
  const [sopt, setSopt] = useState<UserSearchOption>({ text: "", offset: 0, limit: LIMIT });
  const [list, setList] = useState<User[]>([]);
  const [total, setTotal] = useState<number>(0);
  const load = (opt: UserSearchOption) => {
    gqladm.getUsers({ ...sopt, ...opt }).then((result) => {
      if (result.list) setList(result.list);
      if (result.total) setTotal(result.total);
      setSopt({
        ...sopt,
        limit: result.limit ? result.limit : LIMIT,
        offset: result.offset ? result.offset : 0,
      });
    });
  };
  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };
  return (
    <Dialog open={props.open} onClose={() => props.onClose()} fullWidth>
      <DialogTitle>유저 선택</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ border: "1px solid gray", backgroundColor: "lightgray", padding: "20px" }}>
            검색:{" "}
            <div style={{ display: "flex" }}>
              <TextField
                value={sopt.text}
                onChange={(e) => setSopt({ ...sopt, text: e.target.value })}
                size="small"
              />
              <Button variant="contained" onClick={() => load({})} size="small">
                검색
              </Button>
            </div>
          </div>

          <div>
            검색결과
            <Pagination
              count={Math.ceil(total / (sopt.limit ?? LIMIT))}
              page={Math.ceil((sopt.offset ?? 0) / (sopt.limit ?? LIMIT)) + 1}
              onChange={handleChangePage}
            />
            <Table size="small">
              <UserTableHeader />
              <TableBody>
                {list.map((user, idx) => (
                  <UserTableBodyRow key={idx} user={user} onSelect={props.onSelect} />
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectUserDialog;
