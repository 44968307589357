import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gqladm } from "../../../../graphql/gqladm";
import { useAppCommon } from "../../../../hooks/useAppCommon";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { Styling, StylingSearchOption, StylingStatusArray } from "../../../../types/elements";
import StylingDetail from "./StylingDetail";
import StylingResultList from "./StylingResultList";
import StylingResultTable from "./StylingResultTable";
import StylingSearchBox from "./StylingSearchBox";

export interface StylingResultProps {
  list: Styling[];
  openDialog: (id: number) => any;
  openUser?: (user_id: number) => void;
}

interface StylingListViewProps {}
const StylingListView: React.FC<StylingListViewProps> = (props) => {
  const navi = useNavigate();
  const {} = useAppCommon({ headerTitle: "스타일링 신청 리스트" });
  const { isMobile } = useWindowSize();

  const [list, setList] = useState<Styling[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);

  const [sopt, setSopt] = useState<StylingSearchOption>({
    ordering: "newerfirst",
    limit: 20,
    statuses: StylingStatusArray.filter((o) => o !== "작성 중" && o !== "취소"),
  });
  const handleSearch = (opt: StylingSearchOption) => {
    load(opt);
  };

  const load = (opt: StylingSearchOption) => {
    gqladm.stylings(opt).then((result) => {
      if (result.list !== undefined) setList(result.list);
      if (result.total !== undefined) setTotal(result.total);
      if (result.offset !== undefined) setOffset(result.offset);
      if (result.limit !== undefined) setLimit(result.limit);
    });
  };

  const handleChangePage = (page: number) => {
    console.log("handleChangePage", page);
    const offset = (page - 1) * (sopt.limit ? sopt.limit : 10);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  const [stylingId, setStylingId] = useState<number | undefined>();
  const handleOpenDialog = (id: number) => {
    setStylingId(id);
  };

  return (
    <div>
      <StylingSearchBox sopt={sopt} setSopt={setSopt} onSearch={handleSearch} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          onChange={(e, p) => handleChangePage(p)}
          count={Math.ceil(total / limit)}
          page={Math.ceil(offset / limit) + 1}
        />
      </div>
      <div>
        {isMobile ? (
          <StylingResultList
            list={list}
            openDialog={handleOpenDialog}
            openUser={(user_id) => user_id && navi("/user/" + user_id)}
          />
        ) : (
          <StylingResultTable
            list={list}
            openDialog={handleOpenDialog}
            openUser={(user_id) => user_id && navi("/user/" + user_id)}
          />
        )}
        <Dialog open={!!stylingId} onClose={() => setStylingId(undefined)}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            {stylingId && (
              <StylingDetail id={stylingId} onSaveDone={() => setStylingId(undefined)} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setStylingId(undefined)}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default StylingListView;
