import { Button, Chip, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { ItemSearchOption } from "../../../types/elements";

interface CSVExtractBtnDialogProps {
  sopt: ItemSearchOption;
}
const FIELDS = [
  "id",
  "type",
  "visible",
  "name",
  "description",
  "status",
  "price",
  "vatAdded",
  "priceBefore",
  "search_text",
  "sort_level",
  "stock_avail",
  "priceOpen",
  "discountRate",
  "isLabelConsignment",
  "isLabelLastOne",
];
const CSVExtractBtnDialog: React.FC<CSVExtractBtnDialogProps> = ({ sopt }) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => setOpen(false);
  const [fields, setFields] = useState<string[]>([
    "id",
    "type",
    "name",
    "status",
    "price",
    "vatAdded",
    "priceBefore",
    "discountRate",
    "stock_avail",
    "priceOpen",
    "isLabelConsignment",
    "isLabelLastOne",
  ]);
  const handleDownloadCSV = () => {
    console.log(fields, sopt);
    gqladm.getItemsWithFields({ ...sopt, offset: 0, limit: undefined }, fields).then((result) => {
      console.log("result", result);
      if (result.list && result.list.length > 0) {
        const csvContent =
          "data:text/csv;charset=utf-8," +
          fields.join(",") +
          "\n" +
          result.list.map((item: any) => fields.map((fi) => item[fi]).join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "adsc_items_extract.csv");
        link.click();
      }
    });
  };
  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)} sx={{ marginLeft: "1rem" }}>
        CSV Download
      </Button>
      <Dialog open={open} onClose={() => handleClose()}>
        <DialogContent>
          추출하고자하는 필드를 선택해주세요
          <div>
            {FIELDS.map((fi) => (
              <Chip
                label={fi}
                color={fields.includes(fi) ? "primary" : "default"}
                onClick={() =>
                  setFields((fis) =>
                    fis.includes(fi) ? fis.filter((f) => f !== fi) : [...fis, fi]
                  )
                }
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDownloadCSV}>CSV Download</Button>
          <Button onClick={() => handleClose()}>닫기</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CSVExtractBtnDialog;
