import {
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  FormControlLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import {
  ItemSearchOption,
  ItemStatus,
  ItemStatusArr,
  ItemType,
  ItemTypeArr,
  ItemVisible,
  ItemVisibleArr,
} from "../../../types/elements";

import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { useRecoilValue } from "recoil";
import { ItemMetaState } from "../../../store/states/ItemMetaState";
import { useWindowSize } from "../../../hooks/useWindowSize";

interface ItemSearchEnabled {
  text?: boolean;
  type?: boolean;
  visible?: boolean;
  status?: boolean;
  category?: boolean;
  designer?: boolean;
  brand?: boolean;
}

interface ItemSearchConditionProps {
  sopt: ItemSearchOption;
  setSopt: React.Dispatch<React.SetStateAction<ItemSearchOption>>;
  onSearch: () => any;
  actions?: React.ReactNode;
}
const ItemSearchCondition: React.FC<ItemSearchConditionProps> = (props) => {
  const { isMobile } = useWindowSize();
  const sopt = props.sopt;
  const setSopt = props.setSopt;
  const [enabled, setEnabled] = useState<ItemSearchEnabled>({});

  const [categoryId, setCategoryId] = useState<number | undefined>();
  const [inclSub, setInclSub] = useState<boolean>(false);

  const itemMetaState = useRecoilValue(ItemMetaState);

  const [id, setId] = useState<number | undefined>();
  const handleChangeId = (e: any) => {
    setId(e.target.value);
    if (e.target.value) setSopt((so) => ({ ...so, ids: [Number(e.target.value)] }));
    else setSopt((so) => ({ ...so, ids: undefined }));
  };

  const handleChangeText = (e: any) => {
    const text = e.target.value;
    const textEnabled = !!e.target.value;
    if (!enabled.text && text) setEnabled((en) => ({ ...en, text: true }));
    if (enabled.text && !textEnabled) setEnabled((en) => ({ ...en, text: false }));
    setSopt((so) => ({ ...so, text: text }));
  };

  const handleToggleSoptType = (type: ItemType) => (e: any) => {
    setSopt({ ...sopt, type: type });
  };

  const handleToggleSoptVisible = (visible: ItemVisible) => (e: any) => {
    if (sopt.visibles) {
      let visibles = [...sopt.visibles];
      if (visibles.indexOf(visible) === -1) visibles.push(visible);
      else visibles = visibles.filter((o) => o !== visible);
      setSopt({ ...sopt, visibles: visibles });
    }
  };
  const handleToggleSoptStatus = (status: ItemStatus) => (e: any) => {
    if (sopt.statuses) {
      let statuses = [...sopt.statuses];
      if (statuses.indexOf(status) === -1) statuses.push(status);
      else statuses = statuses.filter((o) => o !== status);
      setSopt({ ...sopt, statuses: statuses });
    }
  };

  const handleToggleInclSub = (bInclSub: boolean) => {
    setInclSub(bInclSub);
    setSopt((sopt) => ({
      ...sopt,
      categoryId: bInclSub ? undefined : categoryId,
      categoryLike: bInclSub ? categoryId : undefined,
    }));
  };
  const handleChangeCategoryId = (e: any) => {
    console.log("handleChangeCategoryId", e);
    const catId = e.target.value !== -1 ? e.target.value : undefined;
    setCategoryId(catId);
    setSopt((sopt) => ({
      ...sopt,
      categoryId: inclSub ? undefined : catId,
      categoryLike: inclSub ? catId : undefined,
    }));
  };

  const handleChangeDesignerId = (e: any) => {
    console.log("handleChangeDesignerId", e);
    setSopt((sopt) => ({
      ...sopt,
      designerId: e.target.value !== -1 ? e.target.value : undefined,
    }));
  };
  const handleChangeBrandId = (e: any) => {
    console.log("handleChangeBrandId", e);
    setSopt((sopt) => ({
      ...sopt,
      brandId: e.target.value !== -1 ? e.target.value : undefined,
    }));
  };

  return (
    <div className={"_itemSearchCondition" + (isMobile ? " m" : "")}>
      <div className="condBox">
        <div className="condItem">
          <div className="name">ID</div>
          <TextField
            fullWidth
            className="body"
            variant="outlined"
            size="small"
            value={id}
            onChange={handleChangeId}
          />
        </div>

        <div className="condItem">
          <div className="name">Text</div>
          <TextField
            fullWidth
            className="body"
            variant="outlined"
            size="small"
            value={sopt.text}
            onChange={handleChangeText}
          />
        </div>

        <div className="condItem">
          <div className="name">Type</div>

          <ButtonGroup className="body" size="small">
            {ItemTypeArr.map((o, idx) => (
              <Button
                key={idx}
                color={sopt.type === o ? "primary" : "inherit"}
                variant="contained"
                onClick={handleToggleSoptType(o)}>
                {o}
              </Button>
            ))}
          </ButtonGroup>
        </div>

        <div className="condItem">
          <div className="name">Visible</div>
          <div className="body">
            {ItemVisibleArr.map((o, idx) => (
              <Chip
                key={idx}
                label={
                  o === "OPEN" ? "노출" : o === "CLOSED" ? "비노출" : o === "DELETED" ? "삭제" : o
                }
                size="small"
                icon={sopt.visibles && sopt.visibles.indexOf(o) > -1 ? <DoneIcon /> : undefined}
                color={sopt.visibles && sopt.visibles.indexOf(o) > -1 ? "primary" : "default"}
                onClick={handleToggleSoptVisible(o)}
              />
            ))}
          </div>
        </div>

        <div className="condItem">
          <div className="name">Status</div>
          <div className="body">
            {ItemStatusArr.map((o, idx) => (
              <Chip
                key={idx}
                label={o === "SALE" ? "판매중" : o === "SOLDOUT" ? "품절표시" : o}
                size="small"
                icon={sopt.statuses && sopt.statuses.indexOf(o) > -1 ? <DoneIcon /> : undefined}
                color={sopt.statuses && sopt.statuses.indexOf(o) > -1 ? "primary" : "default"}
                onClick={handleToggleSoptStatus(o)}
              />
            ))}
          </div>
        </div>

        <div className="condItem">
          <div className="name">Category</div>
          <div className="body">
            <Select
              fullWidth
              size="small"
              value={sopt.categoryId}
              onChange={handleChangeCategoryId}>
              <MenuItem value={-1}>&gt;&gt; Not Set &lt;&lt;</MenuItem>
              {itemMetaState.category.map((o, idx) => (
                <MenuItem key={idx} value={o.id ? o.id : -1}>
                  {o.treenodes && o.treenodes?.map((o) => o.name).join(" > ")}
                </MenuItem>
              ))}
            </Select>
            <Chip
              label="incl. Sub"
              size="small"
              color={inclSub ? "primary" : "default"}
              onClick={() => handleToggleInclSub(!inclSub)}
              onDelete={() => handleToggleInclSub(!inclSub)}
              deleteIcon={<DoneIcon />}
            />
          </div>
        </div>

        <div className="condItem">
          <div className="name">Designer</div>
          <Select
            className="body"
            fullWidth
            size="small"
            value={sopt.designerId}
            onChange={handleChangeDesignerId}>
            <MenuItem value={-1}>&gt;&gt; Not Set &lt;&lt;</MenuItem>
            {Array(26)
              .fill(1)
              .map((o, i) => String.fromCharCode(65 + i))
              .map((chr) => {
                const filteredDesigner = itemMetaState.designer.filter(
                  (o) => o.name && o.name.toUpperCase().startsWith(chr)
                );
                if (filteredDesigner.length > 0) {
                  return [
                    <MenuItem key={chr} disabled>
                      {chr}
                    </MenuItem>,
                    filteredDesigner
                      .sort((a, b) => (a.name && b.name && a.name > b.name ? 1 : -1))
                      .map((brd) => (
                        <MenuItem key={brd.id} value={brd.id}>
                          {brd.name}
                        </MenuItem>
                      )),
                  ];
                }
              })
              .reduce((p, n) => {
                if (n && n.length > 0) n.forEach((no) => p?.push(no));
                return p;
              }, [])}
          </Select>
        </div>

        <div className="condItem">
          <div className="name">Brand</div>
          <Select
            className="body"
            fullWidth
            size="small"
            value={sopt.brandId}
            onChange={handleChangeBrandId}>
            <MenuItem value={-1}>&gt;&gt; Not Set &lt;&lt;</MenuItem>
            {Array(26)
              .fill(1)
              .map((o, i) => String.fromCharCode(65 + i))
              .map((chr) => {
                const filteredBrand = itemMetaState.brand.filter(
                  (o) => o.name && o.name.toUpperCase().startsWith(chr)
                );
                if (filteredBrand.length > 0) {
                  return [
                    <MenuItem key={chr} disabled>
                      {chr}
                    </MenuItem>,
                    filteredBrand
                      .sort((a, b) => (a.name && b.name && a.name > b.name ? 1 : -1))
                      .map((brd) => (
                        <MenuItem key={brd.id} value={brd.id}>
                          {brd.name}
                        </MenuItem>
                      )),
                  ];
                }
              })
              .reduce((p, n) => {
                if (n && n.length > 0) n.forEach((no) => p?.push(no));
                return p;
              }, [])}
          </Select>
        </div>

        <div className="condItem">
          <div className="name">Label</div>
          <div className="body">
            <Chip
              label="위탁"
              size="small"
              icon={
                sopt.isLabelConsignment === undefined ? undefined : sopt.isLabelConsignment ? (
                  <DoneIcon />
                ) : (
                  <ClearIcon />
                )
              }
              color={sopt.isLabelConsignment !== undefined ? "primary" : "default"}
              onClick={() =>
                setSopt((opt) => ({
                  ...opt,
                  isLabelConsignment:
                    opt.isLabelConsignment === undefined
                      ? true
                      : opt.isLabelConsignment
                      ? false
                      : undefined,
                }))
              }
            />
            <Chip
              label="1 LEFT"
              size="small"
              icon={
                sopt.isLabelLastOne === undefined ? undefined : sopt.isLabelLastOne ? (
                  <DoneIcon />
                ) : (
                  <ClearIcon />
                )
              }
              color={sopt.isLabelLastOne !== undefined ? "primary" : "default"}
              onClick={() =>
                setSopt((opt) => ({
                  ...opt,
                  isLabelLastOne:
                    opt.isLabelLastOne === undefined
                      ? true
                      : opt.isLabelLastOne
                      ? false
                      : undefined,
                }))
              }
            />
          </div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button color="primary" variant="contained" onClick={() => props.onSearch()}>
          Search
        </Button>
        {props.actions}
      </div>
    </div>
  );
};

export default ItemSearchCondition;
