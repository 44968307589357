import React from "react";
import { Image, OrderedItem, OrderItem } from "../../../types/elements";
import { Button, TableHead, TableRow, Table, TableBody, TableCell } from "@mui/material";
import useItemMeta from "../../../hooks/useItemMeta";

interface OrderItemListProps {
  list: OrderItem[];
  orderedItemList: OrderedItem[];
  onClickOpenManageOrderedItem: (itemOptionId: number) => any;
  onClickOpenStockManage: (itemOptionId: number) => any;
}
const OrderItemList: React.FC<OrderItemListProps> = ({
  list,
  orderedItemList,
  onClickOpenManageOrderedItem,
  onClickOpenStockManage,
}) => {
  const { getItemMeta } = useItemMeta();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Designer</TableCell>
          <TableCell>상품</TableCell>
          <TableCell>옵션</TableCell>
          <TableCell>단가</TableCell>
          <TableCell>VAT</TableCell>
          <TableCell>금액</TableCell>
          <TableCell>주문수량</TableCell>
          <TableCell>출고</TableCell>
          <TableCell>재고매핑</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {list.map((orderItem: OrderItem, idx: number) => {
          // cc - 주문 수량, oc - 출고수량
          const cc = orderItem.count ? orderItem.count : 0;
          const eachOrderedItems = orderedItemList
            ? orderedItemList.filter((oi) => oi.itemOptionId === orderItem.item_option_id)
            : [];
          const oc = eachOrderedItems.reduce(
            (p, n) => p + (n.orderedCount ? n.orderedCount : 0),
            0
          );

          const price = orderItem.item?.price ? orderItem.item.price : 0;
          const vat = orderItem.item?.vatAdded
            ? 0
            : orderItem.item?.price
            ? orderItem.item?.price * 0.1
            : 0;
          const totalPrice = price + vat;
          const currentDesigner = getItemMeta("Designer", orderItem.item?.designer_id);
          return (
            <TableRow key={idx}>
              <TableCell>{orderItem.id}</TableCell>
              <TableCell>{currentDesigner?.name}</TableCell>
              <TableCell>
                #{orderItem.item?.id}-{orderItem.item?.name}
                <br />
                {orderItem.item?.topImages &&
                  orderItem.item?.topImages.map((img: Image, img_idx: number) => {
                    return <img key={img_idx} className="rect72" src={"/" + img.url} />;
                  })}
              </TableCell>
              <TableCell>
                #{orderItem.item_option_id}-{orderItem?.itemOption?.name}
              </TableCell>
              <TableCell>{price}</TableCell>
              <TableCell>{vat}</TableCell>
              <TableCell>{totalPrice}</TableCell>
              <TableCell>{cc}</TableCell>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // flexDirection: "column",
                    alignItems: "center",
                  }}>
                  <div>
                    {cc > oc
                      ? "미출고 " + (cc - oc) + "개"
                      : oc > cc
                      ? "초과 출고 " + (oc - cc) + "개"
                      : "출고 완료"}
                  </div>
                  &nbsp;
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        orderItem.item_option_id &&
                        onClickOpenManageOrderedItem(orderItem.item_option_id)
                      }>
                      출고 등록
                    </Button>
                  </div>
                </div>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() =>
                    orderItem.item_option_id && onClickOpenStockManage(orderItem.item_option_id)
                  }>
                  재고관리
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default OrderItemList;
