import { TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
interface UserTableHeaderProps {}
const UserTableHeader: React.FC<UserTableHeaderProps> = (props) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Phone</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default UserTableHeader;
