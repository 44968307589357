import React from "react";
import { Button, Chip } from "@mui/material";
import { CommonUtil as cu, getThumbUrl } from "../../../../types/elements";
import { ConsignmentResultProps } from "./ConsignmentListView";

interface ConsignmentResultListProps extends ConsignmentResultProps {}
const ConsignmentResultList: React.FC<ConsignmentResultListProps> = ({
  list,
  openDialog,
  openUser,
}) => {
  return (
    <div>
      {list.map((cr, crIdx) => (
        <div
          key={cr.id}
          style={{ backgroundColor: "#f5f5f5", margin: "0.5rem 0", padding: "0.3rem" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Button size="small" onClick={() => openDialog && cr.id && openDialog(cr.id)}>
                #{cr.id}
              </Button>
            </div>
            <div>
              <Chip size="small" label={cr.status} />
            </div>
            <div>{cr.created && cu.getFormattedDate(cr.created, "yyyy-MM-dd hh:mm")}</div>
          </div>
          {cr.user && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>User: {cr.user.name} </div>
              <Button size="small" onClick={() => cr.user_id && openUser && openUser(cr.user_id)}>
                유저정보
              </Button>
            </div>
          )}
          {cr.items && cr.items.length > 0 ? (
            <div>
              {cr.items.map((item, itemIdx) => {
                return (
                  <div
                    key={itemIdx}
                    style={{
                      padding: "0.3rem",
                      backgroundColor: "#f0f0f0",
                      marginBottom: "0.5rem",
                      border: "1px solid gray",
                      borderRadius: "5px",
                    }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <div>{item.status}</div>
                        <div>
                          {item.designer} - {item.brand}
                        </div>
                      </div>
                      <div>
                        {item.images && item.images.length > 0 && (
                          <img
                            style={{ maxWidth: "4rem" }}
                            src={item.images[0].url && getThumbUrl(item.images[0].url)}
                          />
                        )}
                      </div>
                      <div>
                        <div>구매금액: {item.origin_price}만원</div>
                        <div>판매금액: {item.expect_price}만원</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>신청상품이 아직 없습니다. </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ConsignmentResultList;
