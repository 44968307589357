import { ADMIN_SESSION_ID, ADMIN_SESSION_KEY } from "../types/adm_elements";

export const Ls = {
  getItems: (key: string) => {
    let items = window.localStorage.getItem(key);
    if (items === null) {
      items = "[]";
    }
    try {
      const parsed = JSON.parse(items);
      return parsed;
    } catch (err) {
      console.log("JSON parsing error", err);
      return [];
    }
  },
  setItems: (key: string, items: any[]) => {
    window.localStorage.setItem(key, JSON.stringify(items));
  },
  getItem: (key: string) => {
    let item = window.localStorage.getItem(key);
    if (item === null) {
      item = "{}";
    }
    try {
      const parsed = JSON.parse(item);
      return parsed;
    } catch (err) {
      console.log("JSON parsing error", err);
      return {};
    }
  },
  setItem: (key: string, item: any) => {
    window.localStorage.setItem(key, JSON.stringify(item));
  },
};

export const getSessionId = (): string | null => {
  const id = window.localStorage.getItem(ADMIN_SESSION_ID);
  return id;
};

export const setSessionId = (id: string) => {
  window.localStorage.setItem(ADMIN_SESSION_ID, id);
};

export const getSessionKey = (): string | null => {
  const key = window.localStorage.getItem(ADMIN_SESSION_KEY);
  return key;
};

export const setSessionKey = (key: string) => {
  window.localStorage.setItem(ADMIN_SESSION_KEY, key);
};
