import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../graphql/gqladm";
import { PaymentAnnotation } from "../../types/adm_elements";
import { CommonUtil as cu } from "../../types/elements";

interface PaymentsListProps {
  m_id?: string;
}
const PaymentsList: React.FC<PaymentsListProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (props.m_id) setLoading(true);
    gqladm
      .getIamportPaymentsMid({ m_id: props.m_id })
      .then((result) => {
        if (result.list) setList(result.list);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.m_id]);

  const [list, setList] = useState<PaymentAnnotation[]>([]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>imp_uid</TableCell>
          <TableCell>merchant_uid</TableCell>
          <TableCell>name</TableCell>
          <TableCell>amount</TableCell>
          <TableCell>buyer_name</TableCell>
          <TableCell>buyer_tel</TableCell>
          <TableCell>started_at</TableCell>
          <TableCell>status</TableCell>
          <TableCell>paid_at</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell colSpan={9}>로딩중</TableCell>
          </TableRow>
        ) : list.length === 0 ? (
          <TableRow>
            <TableCell colSpan={9}>데이터 없음</TableCell>
          </TableRow>
        ) : (
          list.map((o, idx) => (
            <TableRow key={"row_" + idx}>
              <TableCell>{o.imp_uid}</TableCell>
              <TableCell>{o.merchant_uid}</TableCell>
              <TableCell>{o.name}</TableCell>
              <TableCell>{o.amount}</TableCell>
              <TableCell>{o.buyer_name}</TableCell>
              <TableCell>{o.buyer_tel}</TableCell>
              <TableCell>
                {o.started_at && cu.getFormattedDate(o.started_at * 1000, "yyyy-MM-dd hh:mm")}
              </TableCell>
              <TableCell>{o.status}</TableCell>
              <TableCell>
                {o.paid_at && cu.getFormattedDate(o.paid_at * 1000, "yyyy-MM-dd hh:mm")}
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default PaymentsList;
