import React from "react";

import { CommonUtil as cu, DAY, WEEK } from "../../types/elements";

interface CalendarDayContent {
  date?: string;
  content?: any;
}
interface CalendarProps {
  year: number;
  month: number;
  onChange?: (year: number, month: number) => any;
  selectedDayNum?: number;
  onClickDay?: (dayNum: number) => any;
  dayContents: CalendarDayContent[];
}
// const useStyles = makeStyles(() => {
//   return {
//     root: {
//       borderCollapse: "collapse",
//       "& .selectedDay": {
//         backgroundColor: "#f0f0f0",
//       },
//       "& td": {
//         width: "40px",
//         height: "40px",
//         fontSize: "16px",
//         fontWeight: "bold",
//         cursor: "pointer",
//         textAlign: "center",
//         verticalAlign: "top",
//       },
//       "& .sun": {
//         color: "red",
//       },
//       "& .sat": {
//         color: "blue",
//       },
//       "& .upHeader th .CalNav": {
//         display: "flex",
//         justifyContent: "space-between",
//         alignItems: "center",
//         backgroundColor: "#f3ba1a",
//         height: "40px",
//         borderRadius: "20px",
//         padding: "20px",
//         fontSize: "20px",
//       },
//       "& .downHeader th": { borderBottom: "1px solid black", height: "30px", fontSize: "14px" },
//     },
//   };
// });
const Calendar: React.FC<CalendarProps> = (props) => {
  // const classes = useStyles(props);
  const sDayOfMonth = new Date(props.year, props.month - 1, 1);
  const eDayOfMonth = new Date(props.year, props.month, 0);
  const viewStartDay = new Date(props.year, props.month - 1, -sDayOfMonth.getDay() + 1);
  const weekLength = Math.ceil((eDayOfMonth.getTime() - viewStartDay.getTime()) / WEEK);

  const handleChange = (m: number) => {
    const _d = new Date(Date.UTC(props.year, props.month - 1 + m, 1));
    props.onChange && props.onChange(_d.getFullYear(), _d.getMonth() + 1);
  };

  return (
    <table
      className={
        "calendar"
        // +  classes.root
      }>
      <thead>
        <tr className="upHeader">
          <th colSpan={7}>
            <div className="CalNav">
              <div style={{ cursor: "pointer" }} onClick={() => handleChange(-1)}>
                ◀
              </div>
              <div>
                {props.year}-{props.month}
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => handleChange(1)}>
                ▶
              </div>
            </div>
          </th>
        </tr>
        <tr className="downHeader">
          <th className="sun">S</th>
          <th className="mon">M</th>
          <th className="tue">T</th>
          <th className="wed">W</th>
          <th className="thu">T</th>
          <th className="fri">F</th>
          <th className="sat">S</th>
        </tr>
      </thead>
      <tbody>
        {new Array(weekLength).fill(1).map((o, weekIdx) => {
          return (
            <tr>
              {new Array(7).fill(1).map((oo, dayIdx) => {
                const dayNum = viewStartDay.getTime() / DAY + dayIdx + weekIdx * 7;
                const _d = new Date(dayNum * DAY);
                const _dStr = `${_d.getFullYear()}-${cu.lpad(_d.getMonth() + 1, 2)}-${cu.lpad(
                  _d.getDate(),
                  2
                )}`;
                const isSameMonth =
                  sDayOfMonth.getFullYear() === _d.getFullYear() &&
                  sDayOfMonth.getMonth() === _d.getMonth();
                return (
                  <td className={["sun", "mon", "tue", "wed", "thu", "fri", "sat"][_d.getDay()]}>
                    <div
                      className={
                        props.selectedDayNum && props.selectedDayNum === dayNum ? "selectedDay" : ""
                      }
                      onClick={() => props.onClickDay && props.onClickDay(dayNum)}>
                      <div className="dayTitle">{isSameMonth && _d.getDate()}</div>
                      <div className="dayContent">
                        {props.dayContents
                          .filter((o) => o.date === _dStr)
                          .map((o) => {
                            return <div>{o.content}</div>;
                          })}
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Calendar;
