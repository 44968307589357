import { Pagination, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../../graphql/gqladm";
import { useAppCommon } from "../../../../hooks/useAppCommon";
import {
  UserAlertInfo,
  UserAlertInfoSearchOption,
  CommonUtil as cu,
} from "../../../../types/elements";

const LIMIT = 20;
interface UserAlertInfoViewProps {}
const UserAlertInfoView: React.FC<UserAlertInfoViewProps> = (props) => {
  useAppCommon({ headerTitle: "User Alert Infos" });

  const [total, setTotal] = useState<number>(0);
  const [list, setList] = useState<UserAlertInfo[]>([]);
  const [sopt, setSopt] = useState<UserAlertInfoSearchOption>({ offset: 0, limit: LIMIT });

  const load = (sopt: UserAlertInfoSearchOption) => {
    gqladm.searchUserAlertInfo(sopt).then((result) => {
      if (result.list) setList(result.list);
      if (result.total !== undefined) setTotal(result.total);
      setSopt({
        ...sopt,
        offset: result.offset ?? sopt.offset,
        limit: result.limit ?? sopt.limit,
      });
    });
  };

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  useEffect(() => load(sopt), []);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Pagination
          count={Math.ceil(total / (sopt.limit ?? LIMIT))}
          page={Math.ceil((sopt.offset ?? 0) / (sopt.limit ?? LIMIT)) + 1}
          onChange={handleChangePage}
        />
      </div>
      <div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Type ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((tr) => {
              return (
                <TableRow key={"key-" + tr.id}>
                  <TableCell>{tr.id}</TableCell>
                  <TableCell>
                    {tr.created ? cu.getFormattedDate(tr.created, "yyyy-MM-dd hh:mm") : ""}
                  </TableCell>
                  <TableCell>{tr.user_id}</TableCell>
                  <TableCell>{tr.type}</TableCell>
                  <TableCell>{tr.type_id}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Pagination
          count={Math.ceil(total / (sopt.limit ?? LIMIT))}
          page={Math.ceil((sopt.offset ?? 0) / (sopt.limit ?? LIMIT)) + 1}
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default UserAlertInfoView;
