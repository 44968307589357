import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { convertItemProperty, ItemProperty } from "../../../types/elements";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  TextField,
  IconButton,
  Select,
  Theme,
  FormControl,
  MenuItem,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
} from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ListAltTwoToneIcon from "@mui/icons-material/ListAltTwoTone";

interface ItemDetailPropertyInfoProps {
  item_id?: number;
}
const ItemDetailPropertyInfo: React.FC<ItemDetailPropertyInfoProps> = (props) => {
  // const classes = useStyles();
  const [names, setNames] = useState<string[]>([]);
  const [values, setValues] = useState<string[]>([]);
  const [list, setList] = useState<ItemProperty[]>([]);

  const [isListName, setIsListName] = useState<boolean>(false);
  const [isListValue, setIsListValue] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [value, setValue] = useState<string>("");
  useEffect(() => setName(isListName && names.length > 0 ? names[0] : ""), [isListName]);
  useEffect(() => setValue(isListValue && values.length > 0 ? values[0] : ""), [isListValue]);
  useEffect(() => {
    if (isListName && names.indexOf(name) > -1)
      gqladm.getItemPropertyDistinct("value", name).then((result) => setValues(result));
  }, [isListName, name]);
  useEffect(() => {
    if (!isListName && isListValue)
      gqladm.getItemPropertyDistinct("value").then((result) => setValues(result));
  }, [isListName, isListValue]);

  const handleChangeName = (e: any) => {
    setName(e.target.value);
  };
  const handleChangeValue = (e: any) => {
    setValue(e.target.value);
  };

  const handleAdd = () => {
    if (props.item_id && name && value) {
      gqladm.saveItemProperty(props.item_id, 3, name, value).then((result) => {
        if (result.ok) {
          refresh();
          alert("추가되었습니다.");
        } else {
          alert("추가되지 못했습니다. ");
        }
      });
    }
  };

  useEffect(() => {
    if (props.item_id) refresh();
  }, [props.item_id]);

  const refresh = async () => {
    if (props.item_id) {
      gqladm.getItemProperties({ item_id: props.item_id }).then((result) => {
        setList(
          result
            .map((o) => {
              const ret = convertItemProperty(o);
              return ret ? ret : {};
            })
            .sort((a, b) => (a.seq && b.seq && a.seq > b.seq ? 1 : -1))
        );
      });
      gqladm.getItemPropertyDistinct("name").then((result) => setNames(result));
      gqladm.getItemPropertyDistinct("value").then((result) => setValues(result));
    }
  };

  const handleEditSeq = (ip: ItemProperty) => () => {
    if (ip.item_id) {
      const seq = window.prompt("SEQ ?", "" + ip.seq);
      console.log(isNaN(Number(seq)), seq);
      if (seq !== "" && !isNaN(Number(seq)))
        gqladm.saveItemProperty(ip.item_id, Number(seq), ip.name, ip.value).then((result) => {
          if (result.ok) {
            refresh();
            alert("SEQ가 정상적으로 수정되었습니다.");
          } else {
            alert("SEQ가 정상적으로 수정되지 않았습니다. ");
          }
        });
      else alert("값이 제대로 입력되지 않았습니다. ");
    }
  };

  const handleEditValue = (ip: ItemProperty) => () => {
    if (ip.item_id) {
      const value = window.prompt("Value ? ", "" + ip.value);
      if (value !== null)
        gqladm.saveItemProperty(ip.item_id, ip.seq, ip.name, value).then((result) => {
          if (result.ok) {
            refresh();
            alert("Value가 정상적으로 수정되었습니다.");
          } else {
            alert("Value가 정상적으로 수정되지 않았습니다. ");
          }
        });
    }
  };

  const handleDelete = (ip: ItemProperty) => () => {
    if (window.confirm("삭제하시겠습니까? ") && ip.id)
      gqladm.removeItemProperty(ip.id).then((result) => {
        if (result.ok) {
          refresh();
          alert("삭제되었습니다. ");
        } else {
          alert("삭제되지 않았습니다. ");
        }
      });
  };

  return (
    <div className={/* classes.root */ "itemDetailPropertyInfo"}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>Properties | </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          Name:
          <IconButton onClick={() => setIsListName(!isListName)}>
            {isListName ? <ListAltTwoToneIcon /> : <ListAltIcon />}
          </IconButton>
          <FormControl style={{ width: 180 }}>
            {isListName ? (
              <Select variant="filled" value={name} onChange={handleChangeName}>
                {names.map((o, idx) => (
                  <MenuItem key={idx} value={o}>
                    {o}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <TextField variant="outlined" value={name} size="small" onChange={handleChangeName} />
            )}
          </FormControl>
          &nbsp;&nbsp;&nbsp;| Value:
          <IconButton onClick={() => setIsListValue(!isListValue)}>
            {isListValue ? <ListAltTwoToneIcon /> : <ListAltIcon />}
          </IconButton>
          <FormControl style={{ width: 180 }}>
            {isListValue ? (
              <Select variant="filled" style={{}} value={value} onChange={handleChangeValue}>
                {values.map((o, idx) => (
                  <MenuItem key={idx} value={o}>
                    {o}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <TextField
                variant="outlined"
                value={value}
                size="small"
                onChange={handleChangeValue}
              />
            )}
          </FormControl>
          <IconButton onClick={handleAdd}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <hr />
      <Table>
        <TableHead>
          <TableRow>
            {/* <th>ID</th> */}
            <TableCell>NAME</TableCell>
            <TableCell>SEQ</TableCell>
            <TableCell>VALUE</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((ip, idx) => {
            return (
              <TableRow key={idx}>
                {/* <TableCell>{ip.id}</TableCell> */}
                <TableCell>{ip.name}</TableCell>
                <TableCell>
                  {ip.seq}
                  <IconButton onClick={handleEditSeq(ip)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  {ip.value}
                  <IconButton onClick={handleEditValue(ip)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={handleDelete(ip)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {/* <Popover></Popover> */}
    </div>
  );
};

export default ItemDetailPropertyInfo;
