import React, { useState, useEffect, useRef } from "react";

import { useSetRecoilState, useRecoilState } from "recoil";
import { SnackStateSelector } from "../../store/states/SnackState";

import { Input, Button } from "@mui/material";
import { AuthAPI, SmsAPI } from "../../api";
import { ResSmsAligoRemain } from "../../types/adm_elements";
import { setSessionId, setSessionKey } from "../../api/Ls";
import { gqladm } from "../../graphql/gqladm";
import useAdminUser from "../../hooks/useAdminUser";
import { useAppCommon } from "../../hooks/useAppCommon";

// import { useHistory } from 'react-router-dom';

interface LoginViewProps {
  isLoad?: boolean;
}

const LoginView: React.FC<LoginViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "" });
  const openSnack = useSetRecoilState(SnackStateSelector);
  const { getAdminAuth } = useAdminUser();

  const authCodeRef = useRef<HTMLInputElement>();

  // SMS remain counts
  const [smsRemain, setSmeRemain] = useState<ResSmsAligoRemain>({
    result_code: 0,
    message: "",
    SMS_CNT: 0,
    LMS_CNT: 0,
    MMS_CNT: 0,
  });
  useEffect(() => {
    if (props.isLoad) {
      SmsAPI.getSmsRemain().then((result) => {
        const data = result.data;
        if (data.result) {
          const smsResult = data.result;
          setSmeRemain(smsResult);
        }
      });
    }
  }, [props.isLoad]);

  const [fields, setFields] = useState({ phone: "", authcode: "" });
  const [check, setCheck] = useState("");

  const handleUpdateFields = (fieldname: string) => (e: any) => {
    const mobj: any = {};
    mobj[fieldname] = e.currentTarget.value;
    setFields({ ...fields, ...mobj });
  };

  const handleClickSendAuth = () => {
    gqladm
      .postAdminLoginRequest(fields.phone)
      .then((result) => {
        if (result.value) {
          setCheck(result.value);
          openSnack({ msg: "Authcode is sent." });
          if (authCodeRef && authCodeRef.current) {
            authCodeRef.current.focus();
          }
        }
      })
      .catch((err) => {
        openSnack({ msg: "Error on sending AuthCode " + err, severity: "error" });
      });
  };
  const handleKeyToSendAuthCode = (e: any) => {
    if (e.key === "Enter") {
      handleClickSendAuth();
    }
  };

  const handleClickLogin = () => {
    gqladm
      .postAdminLoginSubmit(fields.phone, check, fields.authcode)
      .then((result) => {
        if (result.value) {
          setSessionId(fields.phone);
          setSessionKey(result.value);
          openSnack({ msg: "Admin Login Success. ", severity: "success", duration: 1000 });
          setTimeout(() => getAdminAuth(), 500);
        }
      })
      .catch((err) => {
        openSnack({ msg: "Error on login " + err, severity: "error", duration: 1000 });
      });
  };
  const handleKeyToLogin = (e: any) => {
    if (e.key === "Enter") {
      handleClickLogin();
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      Login (sms: {smsRemain.SMS_CNT}){" "}
      <a href="https://smartsms.aligo.in/login.html" target="_blank">
        aligo
      </a>
      <br />
      phone:{" "}
      <Input
        value={fields.phone}
        onChange={handleUpdateFields("phone")}
        onKeyPress={handleKeyToSendAuthCode}
      />
      <Button onClick={handleClickSendAuth}> Send Auth </Button>
      <br />
      auth code:{" "}
      <Input
        value={fields.authcode}
        onChange={handleUpdateFields("authcode")}
        inputRef={authCodeRef}
        onKeyPress={handleKeyToLogin}
      />{" "}
      <br />
      <Button onClick={handleClickLogin}>Login</Button>
    </div>
  );
};

export default LoginView;
