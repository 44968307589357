import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { gqladm } from "../graphql/gqladm";
import { ItemMetaState, ItemMetaStateIF } from "../store/states/ItemMetaState";
import { ItemMetaExtUI, ItemMetaType, makeItemMetaTreeNodes } from "../types/elements";

const useItemMeta = () => {
  const [itemMetaState, setItemMetaState] = useRecoilState(ItemMetaState);

  const refresh = useCallback(async () => {
    const [category, brand, designer] = await Promise.all([
      gqladm.getItemMetas({ type: "Category" }),
      gqladm.getItemMetas({ type: "Brand" }),
      gqladm.getItemMetas({ type: "Designer" }),
    ]);
    const newState: ItemMetaStateIF = { topCategory: [], category: [], brand: [], designer: [] };
    if (category.list) {
      const allNodes = category.list.map((o) => ({ ...o }));
      newState.topCategory = makeItemMetaTreeNodes(allNodes);
      newState.category = allNodes.sort((a, b) => (a.seq && b.seq && a.seq > b.seq ? 1 : -1));
    }
    if (brand.list) {
      newState.brand = brand.list.map((o) => ({ ...o }));
    }
    if (designer.list) {
      newState.designer = designer.list.map((o) => ({ ...o }));
    }
    setItemMetaState(newState);
    return newState;
  }, [itemMetaState, setItemMetaState, gqladm.getItemMetas]);

  const getItemMeta = useCallback(
    (type: ItemMetaType, id?: number): ItemMetaExtUI | undefined => {
      const list =
        type === "Brand"
          ? itemMetaState.brand
          : type === "Designer"
          ? itemMetaState.designer
          : type === "Category"
          ? itemMetaState.category
          : [];
      return list.find((o) => o.id === id);
    },
    [itemMetaState]
  );

  const consignmentCategorys = itemMetaState.topCategory
    .map((o) => o.name)
    .filter((o) => o && ["새상품", "개인결제창"].indexOf(o) === -1);

  return { refresh, getItemMeta, consignmentCategorys };
};
export default useItemMeta;
