import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React from "react";
import useItemMeta from "../../hooks/useItemMeta";
import { getThumbUrl, Item } from "../../types/elements";

interface ItemListItemProps {
  item?: Item;
}
const ItemListItem: React.FC<ItemListItemProps> = ({ item }) => {
  const { getItemMeta } = useItemMeta();
  const imageUrl =
    item?.topImages && item.topImages.length > 0 && item.topImages[0].url
      ? getThumbUrl("/" + item.topImages[0].url)
      : undefined;
  const brandName = item?.brand_id ? getItemMeta("Brand", item?.brand_id)?.name ?? "" : "";
  const designerName = item?.designer_id
    ? getItemMeta("Designer", item.designer_id)?.name ?? ""
    : "";
  return (
    <ListItem sx={{ "&.MuiListItem-root": { border: "1px solid black" } }}>
      <ListItemAvatar>
        <Avatar sx={{ "&.MuiAvatar-root": { borderRadius: 0 } }} alt="Remy Sharp" src={imageUrl} />
      </ListItemAvatar>
      <ListItemText primary={brandName + " / " + designerName} secondary={item?.name} />
    </ListItem>
  );
};

export default ItemListItem;
