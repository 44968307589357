import React from "react";

interface ImageBoxProps {
  src?: string;
  width?: string;
  height?: string;
}
const ImageBox: React.FC<ImageBoxProps> = (props) => {
  return (
    <div style={{ display: "flex", height: props.height ? props.height : "100%" }}>
      <img
        style={{ margin: "auto", maxHeight: "100%", width: props.width ? props.width : undefined }}
        src={props.src}
      />
    </div>
  );
};

export default ImageBox;
