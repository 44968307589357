import { useSetRecoilState } from "recoil";
import { gqladm } from "../graphql/gqladm";
import { SnackStateSelector } from "../store/states/SnackState";
import { SimpleResult } from "../types/elements";

const useUserMemo = () => {
  const openSnack = useSetRecoilState(SnackStateSelector);
  const addMemo = (user_id: number): Promise<SimpleResult> => {
    return gqladm.searchUserMemo({ user_id: user_id, limit: 1, offset: 0 }).then((result) => {
      let memo;

      if (result.list && result.list.length > 0) {
        memo = prompt(
          "기존 메모는 \n\n" + result.list[0].memo + "\n\n입니다. 새 메모를 입력해주세요",
          result.list[0].memo
        );
      } else {
        memo = prompt("기존메모가 없습니다. 입력해주세요", "");
      }

      if (memo !== null)
        return gqladm.addUserMemo(user_id, memo).then((addResult) => {
          if (addResult.ok === true) openSnack({ msg: "메모가 입력되었습니다." });
          else openSnack({ msg: "메모가 입력이 실패하였습니다." });
          return addResult;
        });
      else return { ok: false, msg: "No Memo" };
    });
  };
  return { addMemo };
};

export default useUserMemo;
