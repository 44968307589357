import React, { useState, useEffect } from "react";

import { useSetRecoilState, useRecoilValue } from "recoil";
import { SnackStateSelector } from "../../store/states/SnackState";
import { AdminAuthState } from "../../store/states/AdminAuthState";

import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import { CommonUtil as cu, Qna, QnaSearchOption } from "../../types/elements";
import { AdminQnaAPI } from "../../api";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useAppCommon } from "../../hooks/useAppCommon";

interface QnaViewProps {}

const QnaView: React.FC<QnaViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "" });
  const openSnack = useSetRecoilState(SnackStateSelector);
  const adminAuthState = useRecoilValue(AdminAuthState);

  const [list, setList] = useState<Array<Qna>>([]);
  const [total, setTotal] = useState<number>(0);

  const loadQna = (sopt: QnaSearchOption) => {
    return AdminQnaAPI.getQnas(sopt).then((result) => {
      const data = result.data;
      if (data.result) {
        setList(data.result);
        if (data.total) {
          setTotal(data.total);
        }
      }
    });
  };
  useEffect(() => {
    loadQna({});
  }, []);

  // 답변달기
  interface UI_REPLY {
    open: boolean;
    qna: Qna;
    content: string;
  }
  const INIT_REPLY: UI_REPLY = { open: false, qna: {}, content: "" };
  const [reply, setReply] = useState<UI_REPLY>(INIT_REPLY);
  const handleOpenReply = (qna: Qna) => {
    setReply({ ...reply, open: true, qna: qna });
  };
  const handleCloseReply = () => {
    setReply(INIT_REPLY);
  };
  const handlePostReply = () => {
    const id = reply.qna.id;
    if (id) {
      AdminQnaAPI.postItemQnaReply(id, {
        useremail: adminAuthState.admin.email,
        username: adminAuthState.admin.name,
        content: reply.content,
      })
        .then((result) => {
          console.log(result);
          handleCloseReply();
          loadQna({});
        })
        .catch((err) => {
          console.log(err);
          openSnack({ msg: "Error on postReply" + err, severity: "error" });
        });
    }
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>상품번호</TableCell>
              <TableCell>내용</TableCell>
              <TableCell>일자</TableCell>
              <TableCell>작성자</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {list.map((qna, idx) => (
            <TableBody key={idx}>
              <TableRow>
                <TableCell>{qna.id}</TableCell>
                <TableCell>{qna.itemId}</TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {qna.isSecret ? <LockOutlinedIcon /> : <LockOpenIcon />}{" "}
                    {qna.isDeleted ? <DeleteIcon /> : ""}{" "}
                    <span style={{ textDecoration: qna.isDeleted ? "line-through" : "" }}>
                      {qna.content}
                    </span>
                  </div>
                </TableCell>
                <TableCell>{qna.created?.toLocaleString()}</TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PersonOutlineIcon />
                    {qna.username && qna.username + "/"}
                    {qna.useremail}
                  </div>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={"Mark as " + (qna.isDeleted ? "Undeleted" : "Deleted")}
                    placement="top">
                    <IconButton>
                      {qna.isDeleted ? <DeleteOutlineIcon /> : <DeleteIcon />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete from DB" placement="top">
                    <IconButton>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                  <Button variant="outlined" onClick={() => handleOpenReply(qna)}>
                    답변달기 - ({qna.replys ? qna.replys.length : 0})
                  </Button>
                </TableCell>
              </TableRow>
              {/* {qna.replys && qna.replys.map((rep: QnaReply, idx) => {
                                return <TableRow key={idx}>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{rep.content}</TableCell>
                                    <TableCell>{rep.created?.toLocaleString()}</TableCell>
                                    <TableCell>{rep.isAdmin === true ? <SupervisorAccountIcon /> : <PersonOutlineIcon />}{rep.username}/{rep.useremail}</TableCell>
                                </TableRow>
                            })} */}
            </TableBody>
          ))}
        </Table>
      </TableContainer>

      <Dialog open={reply.open} onClose={handleCloseReply}>
        <DialogTitle>Reply ITEM QNA </DialogTitle>
        <DialogContent>
          <div
            style={{
              paddingBottom: "1rem",
              borderBottom: "1px solid lightgray",
              marginBottom: "1rem",
            }}>
            <div>
              user : {reply.qna.username && reply.qna.username + " / "}
              {reply.qna.useremail}
            </div>
            <div>content : {reply.qna.content}</div>
            <div>
              created:{" "}
              {reply.qna.created && cu.getFormattedDate(reply.qna.created, "yyyy-MM-dd HH:mm:ss")}
            </div>
          </div>
          <div>
            Comments {reply.qna.replys && reply.qna.replys.length}
            <br />
            {reply.qna.replys &&
              reply.qna.replys.map((rep, idx) => {
                console.log(rep);
                return (
                  <div
                    key={idx}
                    style={{
                      paddingBottom: "0.5rem",
                      borderBottom: "1px solid lightgray",
                      marginBottom: "0.5rem",
                    }}>
                    {rep.isAdmin ? "Admin" : "User"} - {rep.username}{" "}
                    {rep.username && rep.useremail && "-"} {rep.useremail} : (created:{" "}
                    {rep.created?.toLocaleString()})<div>{rep.content}</div>
                  </div>
                );
              })}
          </div>
          <TextField
            fullWidth
            value={reply.content}
            onChange={(e: any) => setReply({ ...reply, content: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePostReply}>Post</Button>
          <Button onClick={handleCloseReply}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QnaView;
