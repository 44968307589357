import {
  Button,
  Hidden,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Item, ItemOption } from "../../types/elements";
import ItemStockTable from "./ItemStockTable";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";

import ItemOptionDialog from "./ItemOptionDialog";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

import { gqladm } from "../../graphql/gqladm";
import HelpIcon from "../molecules/HelpIcon";
import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../../store/states/SnackState";

interface ItemOptionStockTableProps {
  setDefaultItemOption?: (io: ItemOption) => any;
  item_id?: number;
  defaultItemOptionId?: number;
  updateItem: () => any;

  options: ItemOption[];
  refresh: () => void;
}
const ItemOptionStockTable: React.FC<ItemOptionStockTableProps> = (props) => {
  const openSnack = useSetRecoilState(SnackStateSelector);

  const [selected, setSelected] = useState<ItemOption>({});
  // const [options, setOptions] = useState<ItemOption[]>([]);
  const { options, refresh } = props;

  useEffect(() => {
    refresh();
  }, [props.item_id]);

  const handleSelectOption = (option: ItemOption) => () => {
    setSelected(option);
  };

  const handleUpdateItem = () => {
    refresh();
    props.updateItem();
  };

  const handleOpenEditOption = (o?: ItemOption) => (e: any) => {
    console.log(props.item_id, o);
    if (o === undefined) {
      setEdit({ itemId: props.item_id, name: "default", status: "SHOW" });
    } else {
      setEdit({ ...o, itemId: props.item_id, status: o.status === "SHOW" ? o.status : "HIDDEN" });
    }
    setOpenEdit(true);
  };

  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [edit, setEdit] = useState<ItemOption>({});
  const handleCloseEdit = (completed?: boolean) => {
    if (completed) refresh();
    setOpenEdit(false);
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
      <div>
        <h2>상품 옵션 리스트 </h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Name
                <Button onClick={handleOpenEditOption()}>
                  <AddIcon />
                </Button>
              </TableCell>
              <TableCell>Visible</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  Ordered
                  <HelpIcon>출고가 된 상품의 숫자입니다</HelpIcon>
                </div>
              </TableCell>
              <TableCell>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  Order
                  <HelpIcon>
                    Order의 결제완료 상태의 상품리스트 입니다. 출고가 되었다면, Order의 상태를
                    변경해주세요.
                  </HelpIcon>
                </div>
              </TableCell>
              <TableCell>Available</TableCell>
              <TableCell>Default</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {options.map((o) => {
              return (
                <TableRow key={o.id}>
                  <TableCell>
                    <Button variant="outlined" onClick={handleSelectOption(o)}>
                      {o.name}
                      <PlaylistAddCheckIcon />
                    </Button>
                  </TableCell>
                  <TableCell>
                    {o.status}
                    <Button onClick={handleOpenEditOption(o)}>
                      <EditIcon />
                    </Button>
                  </TableCell>
                  <TableCell>{o.count_total !== undefined ? o.count_total : "N/A"}</TableCell>
                  <TableCell>{o.count_ordered !== undefined ? o.count_ordered : "N/A"}</TableCell>
                  <TableCell>{o.count_order !== undefined ? o.count_order : "N/A"}</TableCell>
                  <TableCell>{o.stock_avail}</TableCell>
                  <TableCell>
                    {props.defaultItemOptionId === o.id ? (
                      <DoneIcon />
                    ) : (
                      <Button
                        onClick={() => props.setDefaultItemOption && props.setDefaultItemOption(o)}>
                        Set
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <ItemStockTable option={selected} updateItem={handleUpdateItem} />
      <ItemOptionDialog
        open={openEdit}
        onClose={handleCloseEdit}
        editOption={edit}
        isDefault={props.defaultItemOptionId === edit.id}
      />
    </div>
  );
};

export default ItemOptionStockTable;
