import { useState } from "react";
import { gqladm } from "../../../../graphql/gqladm";
import { WithDrawUserSearchOption } from "../../../../types/adm_elements";
import { WithDrawUser } from "../../../../types/elements";

const LIMIT = 20;
export const useWithDrawUser = () => {
  const [sopt, setSopt] = useState<WithDrawUserSearchOption>({
    orderby: "created_desc",
    offset: 0,
    limit: LIMIT,
  });
  const [list, setList] = useState<WithDrawUser[]>([]);
  const [total, setTotal] = useState<number>(0);

  const query = (sopt: WithDrawUserSearchOption) => {
    gqladm.withDrawUsers(sopt).then((result) => {
      if (result.total !== undefined) setTotal(result.total);
      if (result.list) setList(result.list);
      if (result.offset !== undefined && result.limit !== undefined) {
        setSopt((so) => ({ ...so, offset: result.offset, limit: result.limit }));
      }
    });
  };

  const load = () => {
    query(sopt);
  };
  const loadPage = (page: number) => {
    query({ ...sopt, offset: page * LIMIT });
  };
  return { list, load, loadPage };
};
