import EditIcon from "@mui/icons-material/Edit";
import { Chip } from "@mui/material";
import React from "react";

interface CircleCounterProps {
  num: number;
  set: (num: number) => any;
  min?: number;
  max?: number;
  showModifyLevel?: number;
}
const CircleCounter: React.FC<CircleCounterProps> = (props) => {
  const min = props.min ?? 1;
  const max = props.max ?? 10;
  const { showModifyLevel, num, set } = props;
  const handleModify = () => {
    const v = prompt("값을 입력해주세요.", "" + (props.num ?? min));
    console.log(v);
    if (v !== null && !isNaN(Number(v))) props.set(Number(v));
  };
  return (
    <>
      <Chip
        size="small"
        label={num + " "}
        onClick={() => set(num + 1 > max ? min : num + 1)}
        onDelete={showModifyLevel !== undefined && num > showModifyLevel ? handleModify : undefined}
        deleteIcon={
          showModifyLevel !== undefined && num > showModifyLevel ? <EditIcon /> : undefined
        }
      />
    </>
  );
};

export default CircleCounter;
