import React, { CSSProperties, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  isSameNumberArray,
  Paging,
  User,
  UserAlertInfoSearchCond,
  UserAlertType_TypeConsignment,
  UserAlertType_TypeNew,
} from "../../../types/elements";

import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import HelpIcon from "../../molecules/HelpIcon";

export interface CandidateSearchOption extends Paging {
  hasNewLabel?: boolean;
  hasConfignmentLabel?: boolean;
  category_id?: number;
  designer_id?: number;
  brand_id?: number;
}

interface CandidatesListProps {
  style?: CSSProperties;
  list?: User[];
  total: number;
  limit: number;
  page: number;
  selectPage: (page: number) => void;
  add?: (user_ids: number[]) => void;
  onOpenCustomUser?: () => void;
  onOpenCandidateSearch?: () => void;
  header?: React.ReactNode;
}
const CandidatesList: React.FC<CandidatesListProps> = ({
  style,
  list,
  total,
  limit,
  page,
  selectPage,
  add,
  onOpenCustomUser,
  onOpenCandidateSearch,
  header,
}) => {
  // paging
  const lastPage = Math.floor(total / (limit ?? 10)) + 1;
  const handleChangePage = () => {
    const newPage = window.prompt("Go to ", "" + page);
    selectPage && selectPage(Number(newPage));
  };
  const handleBack = () => {
    selectPage && page - 1 > 0 && selectPage(page - 1);
  };
  const handleForward = () => {
    selectPage && page + 1 <= lastPage && selectPage(page + 1);
  };

  // selected
  const [selected, setSelected] = useState<number[]>([]);
  const handleToggleAll = (e: React.ChangeEvent, c: boolean) => {
    if (c) setSelected(list?.map((o) => o.id ?? 0) ?? []);
    else setSelected([]);
  };

  return (
    <List style={{ ...(style ?? {}) }}>
      {header}
      <ListItem>
        <b>FCM Push Candidates</b>
      </ListItem>
      <ListItem sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton color="primary" onClick={() => onOpenCustomUser && onOpenCustomUser()}>
          <PersonSearchIcon />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => onOpenCandidateSearch && onOpenCandidateSearch()}>
          <ManageSearchIcon />
        </IconButton>
        <Button
          size="small"
          variant="outlined"
          sx={{ textTransform: "none" }}
          onClick={handleChangePage}>
          Page {page} of {lastPage}({total})
        </Button>
        <IconButton onClick={handleBack}>
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={handleForward}>
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      </ListItem>

      {/* // ! Selected */}
      <ListItem
        secondaryAction={
          <IconButton disabled={selected.length === 0} onClick={() => add && add(selected)}>
            <PersonAddAlt1Icon />
          </IconButton>
        }>
        <ListItemIcon>
          <Checkbox
            onChange={handleToggleAll}
            checked={isSameNumberArray(
              selected.sort((a, b) => (a > b ? 1 : -1)),
              ((list ?? []).map((o) => o.id) as number[]).sort((a, b) => (a > b ? 1 : -1))
            )}
          />
        </ListItemIcon>
        <ListItemText primary={`selected: ${selected.length}`}></ListItemText>
      </ListItem>
      <Divider />

      {list?.map((candidate) => {
        return (
          <ListItem
            key={"candidate_" + candidate.id}
            secondaryAction={
              <IconButton onClick={() => add && candidate.id && add([candidate.id])}>
                <PersonAddAlt1Icon />
              </IconButton>
            }>
            <ListItemIcon>
              <Checkbox
                checked={candidate.id ? selected.includes(candidate.id) : false}
                onChange={(e, v) =>
                  setSelected((sl) =>
                    candidate.id
                      ? sl.indexOf(candidate.id) > -1
                        ? sl.filter((o) => o !== candidate.id)
                        : [...sl, candidate.id]
                      : sl
                  )
                }
              />
            </ListItemIcon>
            <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
              <div>#{candidate.id}</div>
              <div>{candidate.name}</div>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                AD
                <HelpIcon fontSize="small">사용자의 Active Device 갯수</HelpIcon> :{" "}
                {candidate.num_active_devices}
              </div>
            </div>
          </ListItem>
        );
      })}
    </List>
  );
};

export default CandidatesList;
