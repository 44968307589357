import { Button, ButtonGroup, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchedAddress } from "../../../../api/PublicAddressAPI";
import ColumnDiv from "../../../molecules/ColumnDiv";
import MonthPicker from "../../../molecules/MonthPicker";
import AddressSelectDialog from "../../../organisms/AddressSelectDialog";
import { gqladm } from "../../../../graphql/gqladm";
import useSnack from "../../../../hooks/useSnack";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import {
  Styling,
  CommonUtil as cu,
  StylingStatus,
  StylingStatusArray,
  AreaSizeTypeArray,
  AreaTypeArray,
  pick,
} from "../../../../types/elements";

interface StylingDetailProps {
  id: number;
  onSaveDone?: () => any;
}
const StylingDetail: React.FC<StylingDetailProps> = (props) => {
  const { openSnack } = useSnack();
  const { isMobile } = useWindowSize();
  const [styling, setStyling] = useState<Styling | undefined>();

  // Budget setting
  const [budget, setBudget] = useState<string>("");
  const handleChangeBudget = (e: any) => {
    setBudget(e.target.value);
    if (!isNaN(Number(e.target.value)))
      setStyling((s) => ({ ...s, budget: Number(e.target.value) }));
  };
  const handleBlurBudget = () => {
    if (!isNaN(Number(budget))) {
      setStyling((s) => ({ ...s, budget: Number(budget) }));
      setBudget("" + Number(budget));
    }
  };

  const load = (id: number) => {
    gqladm.stylings({ id }).then((result) => {
      if (result?.list && result.list.length === 1) {
        const sty = result.list[0];
        setStyling(sty);
        if (sty.budget) setBudget("" + sty.budget);
      }
    });
  };

  useEffect(() => {
    if (props.id) load(props.id);
  }, []);

  // Status
  const [changeStatus, setChangeStatus] = useState<StylingStatus | undefined>();
  const handleChangeStatus = () => {
    if (styling?.status === changeStatus) return;
    styling?.id &&
      changeStatus !== undefined &&
      gqladm.updateStylingStatus(styling.id, changeStatus).then((result) => {
        if (result.ok) {
          openSnack({ msg: "상태가 변경되었습니다. " });
          load(props.id);
        }
      });
  };

  // handleSelectAddress
  const handleSelectAddress = (se: SearchedAddress) => {
    console.log("handleSelectAddress", se);
    setStyling((sty) => ({
      ...sty,
      zipcode: se.zipNo,
      addr1: se.roadAddr,
    }));
  };

  const handleSelectExpectMonth = (y: number, m: number) => {
    setStyling((s) => ({ ...s, expect_month: cu.getFormattedDate(new Date(y, m, 1), "yyyy-MM") }));
  };

  // handleSave
  const handleSave = () => {
    if (styling)
      gqladm
        .updateStyling(
          pick<Styling>(styling, [
            "id",
            "zipcode",
            "addr1",
            "addr2",
            "request_detail",
            "expect_month",
            "areatype",
            "areasize",
            "budget",
          ])
        )
        .then((result) => {
          if (result.ok) {
            if (props.onSaveDone) props.onSaveDone();
            openSnack({ msg: "저장 되었습니다. " });
            load(props.id);
          }
        });
  };

  return (
    <div className="serviceDetail">
      <ColumnDiv label="스타일링 요청 상세"></ColumnDiv>
      <ColumnDiv label="유저">
        <div style={{ border: "1px solid black", padding: "1rem" }}>
          <div>이름: {styling?.user?.name}</div>
          <div>전화: {styling?.user?.phone}</div>
          <div>이메일: {styling?.user?.email}</div>
        </div>
      </ColumnDiv>
      <hr />

      <ColumnDiv label="id">
        <TextField size="small" disabled value={props.id} fullWidth />
      </ColumnDiv>

      <ColumnDiv label="CREATED">
        {styling?.created !== undefined && cu.getFormattedDate(styling.created, "yyyy-MM-dd")}
      </ColumnDiv>

      <ColumnDiv label="STATUS">
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>{styling?.status}</div>
          <div>=&gt;</div>
          <Select
            value={changeStatus}
            onChange={(e: any) => setChangeStatus(e.target.value)}
            size="small">
            {StylingStatusArray.map((o, oIdx) => (
              <MenuItem key={oIdx} value={o}>
                {o}
              </MenuItem>
            ))}
          </Select>
          <Button
            size="small"
            onClick={handleChangeStatus}
            disabled={styling?.status === changeStatus}>
            변경
          </Button>
        </div>
      </ColumnDiv>

      <ColumnDiv label="배송정보">
        우편번호: {styling?.zipcode}
        <AddressSelectDialog onSelect={handleSelectAddress}>
          <Button>검색</Button>
        </AddressSelectDialog>
        <br />
        주소 1: <TextField size="small" disabled value={styling?.addr1} />
        <br />
        주소 2:{" "}
        <TextField
          size="small"
          value={styling?.addr2}
          onChange={(e) => setStyling((s) => ({ ...s, addr2: e.target.value }))}
        />
      </ColumnDiv>

      <ColumnDiv label="요청사항">
        <TextField
          size="small"
          fullWidth
          multiline
          value={styling?.request_detail}
          onChange={(e) => setStyling((s) => ({ ...s, request_detail: e.target.value }))}
        />
      </ColumnDiv>

      <ColumnDiv label="희망 일정">
        <MonthPicker value={styling?.expect_month} onSelect={handleSelectExpectMonth} />
      </ColumnDiv>

      <ColumnDiv label="공간 유형">
        <ButtonGroup fullWidth size="small">
          {AreaTypeArray.map((o, idx) => (
            <Button
              key={idx}
              variant="contained"
              color={styling?.areatype === o ? "primary" : "inherit"}
              onClick={() => setStyling((s) => ({ ...s, areatype: o }))}>
              {o}
            </Button>
          ))}
        </ButtonGroup>
      </ColumnDiv>

      <ColumnDiv label="공간 평수">
        <ButtonGroup fullWidth size="small" orientation={isMobile ? "vertical" : "horizontal"}>
          {AreaSizeTypeArray.map((o, idx) => (
            <Button
              key={idx}
              variant="contained"
              color={styling?.areasize === o ? "primary" : "inherit"}
              onClick={() => setStyling((s) => ({ ...s, areasize: o }))}>
              {o}
            </Button>
          ))}
        </ButtonGroup>
      </ColumnDiv>
      <ColumnDiv label="예산">
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
          <TextField
            size="small"
            type="number"
            value={budget}
            onChange={handleChangeBudget}
            onBlur={handleBlurBudget}
          />
          <div>만원</div>
        </div>
      </ColumnDiv>

      <Button onClick={handleSave} fullWidth variant="contained">
        Save
      </Button>
    </div>
  );
};

export default StylingDetail;
