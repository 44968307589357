import React, { SyntheticEvent, useState, useEffect, createRef, ChangeEvent } from "react";

import { useParams } from "react-router-dom";

import { ItemStock, ItemOption, Item } from "../../types/elements";
import {
  Button,
  Theme,
  Grid,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Table,
  Typography,
} from "@mui/material";

import ItemStockDialog from "../organisms/ItemStockDialog";
import { gqladm } from "../../graphql/gqladm";

import { useAppCommon } from "../../hooks/useAppCommon";

interface ItemStockListViewProps {}

const ItemStockListView: React.FC<ItemStockListViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "재고 관리" });
  const params = useParams<{ itemId: string }>();
  const itemId = Number(params.itemId);
  const [item, setItem] = useState<Item>({});
  const [itemOption, setItemOption] = useState<ItemOption>({});

  const load = () => {
    gqladm
      .getItemInStockManage(itemId)
      .then((result) => {
        if (result) {
          setItem({ ...result });
          if (result.itemOptions && result.itemOptions.length > 0) {
            setItemOption(result.itemOptions[0]);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    load();
  }, []);

  const [editStock, setEditStock] = useState<ItemStock | undefined>();
  const handleOnCloseItemStockDialog = (completed?: boolean) => {
    setEditStock(undefined);
  };
  const handleOpenItemStockDialog = (stock: ItemStock) => (e: any) => {
    setEditStock(stock);
  };

  return (
    <div>
      <Typography>상품정보 </Typography>
      <div>
        Name: {item.name} <br />
      </div>
      <br />
      <br />
      <Grid container>
        <Grid></Grid>
        <Grid></Grid>
      </Grid>

      <Typography>재고 정보 </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Option</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Ordered</TableCell>
            <TableCell>Available</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {item.itemOptions &&
            item.itemOptions.map((io, idx: number) => {
              return (
                <TableRow key={idx}>
                  <TableCell>{io.name} </TableCell>
                  <TableCell>{io.count_total}</TableCell>
                  <TableCell>{io.count_ordered}</TableCell>
                  <TableCell>
                    {(io.count_total ? io.count_total : 0) -
                      (io.count_ordered ? io.count_ordered : 0)}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      <br />
      <br />

      <Typography>입고 정보 : {itemOption.name}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Stock ID</TableCell>
            <TableCell>위치</TableCell>
            <TableCell>수량</TableCell>
            <TableCell>일자</TableCell>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenItemStockDialog({ itemOptionId: itemOption.id })}>
                입고추가
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itemOption.stocks &&
            itemOption.stocks.map((stock, idx: number) => {
              return (
                <TableRow key={idx}>
                  <TableCell>{stock.stockId}</TableCell>
                  <TableCell>
                    {stock.warehouse ? stock.warehouse.name : stock.warehouseId}
                  </TableCell>
                  <TableCell>{stock.stockCount}</TableCell>
                  <TableCell>
                    {stock.created ? new Date(stock.created).toLocaleString() : ""}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <ItemStockDialog
        open={editStock !== undefined}
        onClose={handleOnCloseItemStockDialog}
        stock={editStock}
      />
    </div>
  );
};

export default ItemStockListView;
