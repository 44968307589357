import { Button, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { Order, OrderSearchOption, CommonUtil as cu, getThumbUrl } from "../../../types/elements";

const LIMIT = 10;
interface UserOrderListProps {
  user_id: number;
}
const UserOrderList: React.FC<UserOrderListProps> = (props) => {
  const [list, setList] = useState<Order[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [sopt, setSopt] = useState<OrderSearchOption>({
    limit: LIMIT,
    offset: 0,
    user_id: props.user_id,
    ordering: "updated_desc",
  });

  useEffect(() => {
    if (props.user_id) load(sopt);
  }, [props.user_id]);

  const load = (opt: OrderSearchOption) => {
    gqladm
      .getOrders(opt)
      .then((result) => {
        if (result.list) setList(result.list);
        if (result.total) setTotal(result.total);
        setSopt({
          ...sopt,
          limit: result.limit !== undefined ? result.limit : sopt.limit,
          offset: result.offset !== undefined ? result.offset : sopt.offset,
        });
      })
      .catch((err) => {
        console.log("Error on load", err);
      });
  };
  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  return (
    <div>
      <div>
        <div>
          Count:&nbsp;
          {sopt.offset !== undefined ? sopt.offset + 1 : -1}~
          {sopt.offset !== undefined
            ? sopt.offset + (list.length < LIMIT ? list.length : LIMIT)
            : -1}
          &nbsp;/&nbsp;
          {total}
        </div>
      </div>

      <div>
        {list.map((o) => {
          console.log(o);
          return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {o.updated && <div>{cu.getFormattedDate(o.updated, "yyyy-MM-dd")}</div>}
              <div>
                {o.orderItems &&
                  o.orderItems.map((oi) => {
                    return oi.item?.topImages && oi.item?.topImages.length > 0 ? (
                      <div>
                        <img src={getThumbUrl(oi.item.topImages[0].url ?? "")} width="48px" /> *{" "}
                        {oi.count}
                      </div>
                    ) : (
                      <div>No Img</div>
                    );
                  })}
              </div>

              <div>{o.status}</div>
              <div>₩{cu.numberWithCommas(o.price)}</div>
            </div>
          );
        })}
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
          page={
            Math.ceil(
              (sopt.offset !== undefined ? sopt.offset : 0) /
                (sopt.limit !== undefined ? sopt.limit : LIMIT)
            ) + 1
          }
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default UserOrderList;
