import { Button, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gqladm } from "../../../../../graphql/gqladm";
import {
  ServiceRequest,
  ServiceRequestSearchOption,
  getFormattedDate,
} from "../../../../../types/elements";
import UserDetailRequestNewButton from "./UserDetailRequestNewButton";

const LIMIT = 20;
interface UserDetailRequestProps {
  user_id: number;
}
const UserDetailRequest: React.FC<UserDetailRequestProps> = (props) => {
  const navi = useNavigate();
  const [total, setTotal] = useState<number>(0);
  const [list, setList] = useState<ServiceRequest[]>([]);
  const [sopt, setSopt] = useState<ServiceRequestSearchOption>({
    limit: 20,
    offset: 0,
    user_id: props.user_id,
  });

  const load = (sopt: ServiceRequestSearchOption) => {
    gqladm.serviceRequests(sopt).then((result) => {
      if (result.total !== undefined) setTotal(result.total);
      if (result.list) setList(result.list);
      setSopt((so) => ({ ...so, offset: result.offset, limit: result.limit }));
    });
  };
  useEffect(() => {
    load(sopt);
  }, []);

  const handleChangePage = (page: number) => {
    console.log("handleChangePage", page);
    const offset = (page - 1) * (sopt.limit ? sopt.limit : 10);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div></div>
        <div>
          <UserDetailRequestNewButton user_id={props.user_id} />
        </div>
      </div>
      <div style={{ padding: "1rem" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>total: {total}</span>
          <Pagination
            onChange={(e, p) => handleChangePage(p)}
            count={Math.ceil((total ? total : 0) / (sopt.limit ? sopt.limit : 10))}
            page={Math.ceil((sopt?.offset ? sopt.offset : 0) / (sopt?.limit ? sopt.limit : 10)) + 1}
          />
        </div>
        <table className="grayStyle">
          <thead>
            <tr>
              <th>ID</th>
              <th>TYPE</th>
              <th>Created</th>
              <th>Updated</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {list.map((sq, i) => {
              return (
                <tr key={"sqlist_" + i}>
                  <td>
                    <Button
                      onClick={() =>
                        sq.type && navi(`/servicerequest/${sq.type.toLowerCase()}/${sq.id}`)
                      }>
                      {sq.id}
                    </Button>
                  </td>
                  <td>{sq.type}</td>
                  <td>{sq.created && getFormattedDate(sq.created, "yyyy-MM-dd hh:mm")}</td>
                  <td>{sq.updated && getFormattedDate(sq.updated, "yyyy-MM-dd hh:mm")}</td>
                  <td>{sq.consignment?.status ?? sq.rental?.status ?? sq.styling?.status ?? ""}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>total: {total}</span>
          <Pagination
            onChange={(e, p) => handleChangePage(p)}
            count={Math.ceil((total ? total : 0) / (sopt.limit ? sopt.limit : 10))}
            page={Math.ceil((sopt?.offset ? sopt.offset : 0) / (sopt?.limit ? sopt.limit : 10)) + 1}
          />
        </div>
      </div>
    </div>
  );
};

export default UserDetailRequest;
