import React, { useState, useEffect } from "react";

import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../../../store/states/SnackState";

import {
  TableContainer,
  TableRow,
  TableHead,
  Table,
  TableCell,
  TableBody,
  Button,
  Switch,
  IconButton,
} from "@mui/material";
import { AdminUser } from "../../../types/adm_elements";
import { gqladm } from "../../../graphql/gqladm";
import AdminUserDialog from "../../organisms/AdminUserDialog";
import { useAppCommon } from "../../../hooks/useAppCommon";
import { Delete, Remove } from "@mui/icons-material";
import AdminNotiSettingBtn from "./AdminNotiSettingBtn";

interface AdminUserViewProps {}

const AdminUserView: React.FC<AdminUserViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "" });
  const openSnack = useSetRecoilState(SnackStateSelector);
  const [adminlist, setAdminlist] = useState<AdminUser[]>([]);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    return gqladm
      .getAdminUsers({})
      .then((result) => {
        if (result.list) setAdminlist(result.list);
      })
      .catch((err) => {
        openSnack({ msg: "Error on getAdminUsers " + err, severity: "error" });
      });
  };

  // AdminUserDialog
  const [editAdminUser, setEditAdminUser] = useState<AdminUser | undefined>();
  const handleAdd = () => {
    setEditAdminUser({});
  };
  const handleEdit = (au: AdminUser) => (e: any) => {
    setEditAdminUser(au);
  };
  const handleClose = (isCompleted?: boolean) => {
    if (isCompleted) load();
    setEditAdminUser(undefined);
  };

  const handleChangeTelegramLoginAllow = (adminId: number, allow: boolean) => {
    gqladm.changeTelegramLoginAllow(adminId, allow).then((result) => {
      if (result.ok) {
        load();
      }
    });
  };

  const handleDeleteTelegramId = (adminId: number) => {
    if (window.confirm("관리자의 텔레그램 관련 정보가 삭제됩니다. 진행하시겠습니까? "))
      gqladm.removeAdminUserTelegramInfo(adminId).then((result) => {
        if (result.ok) {
          load();
        }
      });
  };

  return (
    <div>
      <Button onClick={handleAdd}>Register</Button>
      <AdminUserDialog
        open={editAdminUser !== undefined}
        onClose={handleClose}
        adminUser={editAdminUser}
      />

      <div style={{ border: "1px solid black" }}>
        텔레그램 설정 <br />- 텔레그램의 관리자 bot을 계정에 등록한다. (개발서버 :{" "}
        <a href="https://t.me/AndersoncAdminDevBot">t.me/AndersoncAdminDevBot</a>, 운영서버 :{" "}
        <a href="https://t.me/AndersoncAdminBot">t.me/AndersoncAdminBot</a>) <br />
        - "/reg 휴대전화" 커맨드로 관리자 계정의 등록을 시도한다.
        <br />
        - "/regcheck 인증번호" 커맨드로 휴대폰에 전송된 인증번호를 등록함으로써 관리자 계정을
        연결한다.
        <br />- "/login" 커맨드로 간편하게 로그인할수 있는 url을 받는다.
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>name</TableCell>
              <TableCell>phone</TableCell>
              <TableCell>email</TableCell>
              <TableCell>blocked?</TableCell>
              <TableCell>lastlogined</TableCell>
              <TableCell>Telegram Login allow</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminlist.map((auser: AdminUser, idx: number) => (
              <TableRow key={idx}>
                <TableCell>{auser.id}</TableCell>
                <TableCell>{auser.name}</TableCell>
                <TableCell>{auser.phone}</TableCell>
                <TableCell>{auser.email}</TableCell>
                <TableCell>
                  {!auser.blocked ? "" : new Date(auser.blocked).toLocaleDateString()}{" "}
                </TableCell>
                <TableCell>
                  {auser.lastLogined ? new Date(auser.lastLogined).toLocaleDateString() : ""}
                </TableCell>
                <TableCell>
                  {auser.telegramId}
                  {auser.telegramId && (
                    <Switch
                      checked={auser.telegramLoginAllow}
                      onChange={(e, allow) =>
                        auser.id && handleChangeTelegramLoginAllow(auser.id, allow)
                      }
                    />
                  )}
                  {auser.telegramId && (
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => auser.id && handleDeleteTelegramId(auser.id)}>
                      <Delete />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell>
                  <Button onClick={handleEdit(auser)}>Edit</Button>
                  {auser.id && <AdminNotiSettingBtn admin_id={auser.id} />}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AdminUserView;
