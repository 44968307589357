import { file } from "@babel/types";
import React, { createRef } from "react";
import { ImageAPI } from "../../api";
import { Image, MultiImageTarget } from "../../types/elements";

interface MultiImageUploadButtonProps {
  className?: string;
  target: MultiImageTarget;
  targetId: number;
  onChangeFileUpload: (
    temp_id: number,
    status: "ing" | "done" | "fail",
    image_id?: number,
    mi_id?: number
  ) => any;
  children?: React.ReactNode;
}
const MultiImageUploadButton: React.FC<MultiImageUploadButtonProps> = (props) => {
  const uploadInputRef = createRef<HTMLInputElement>();
  const handleClickButton = () => {
    if (uploadInputRef && uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  };

  const handleFiles = (e: any) => {
    if (props.target === undefined || props.targetId === undefined) return;
    const now = Date.now();
    const files = Array.from<File>(e.target.files);
    if (FileReader && files && files.length) {
      files.forEach((file, idx, all) => {
        const temp_id = now * 100 + idx;
        const image: Image = {
          name: file.name,
          type: file.type,
          modified: file.lastModified,
          file: file,
          url: "",
          uid: "",
          itemId: -2,
        };
        props.onChangeFileUpload(temp_id, "ing");
        ImageAPI.postMultiImage(image, props.target, props.targetId)
          .then((result) => {
            props.onChangeFileUpload(temp_id, "done");
          })
          .catch((err) => {
            props.onChangeFileUpload(temp_id, "fail");
          });
      });
    }
  };

  return (
    <div className={props.className}>
      <input
        ref={uploadInputRef}
        type="file"
        multiple
        style={{ display: "none" }}
        onChange={handleFiles}
      />
      <div onClick={handleClickButton}>{props.children}</div>
    </div>
  );
};

export default MultiImageUploadButton;
