import { Button } from "@mui/material";
import React from "react";
import { gqladm } from "../../../../graphql/gqladm";
import useSnack from "../../../../hooks/useSnack";

interface SyncItemStockAvailButtonProps {
  selected: number[];
  onRefresh: () => any;
}
const SyncItemStockAvailButton: React.FC<SyncItemStockAvailButtonProps> = ({ selected }) => {
  const handleClick = () => {
    console.log("Sync_StockAvail");
    gqladm.syncItemsStockAvail(selected).then((result) => {
      console.log(result);
    });
  };
  return <Button onClick={handleClick}>Sync_StockAvail</Button>;
};

export default SyncItemStockAvailButton;
