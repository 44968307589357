import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { gqladm } from "../../graphql/gqladm";
import { SnackStateSelector } from "../../store/states/SnackState";
import { ItemOption, ItemStock } from "../../types/elements";
import ItemStockDialog from "./ItemStockDialog";

interface ItemStockTableProps {
  updateItem: () => any;
  option: ItemOption;
}
const ItemStockTable: React.FC<ItemStockTableProps> = (props) => {
  const openSnack = useSetRecoilState(SnackStateSelector);
  const [actStock, setActStock] = useState<ItemStock | undefined>();

  const [stocks, setStocks] = useState<ItemStock[]>([]);

  useEffect(() => {
    if (props.option.id !== undefined) refresh();
  }, [props.option.id]);

  const refresh = () => {
    if (props.option.id !== undefined)
      gqladm.getItemStocks(props.option.id).then((result) => {
        setStocks(result);
      });
  };

  const handleOpenAddStock = () => {
    if (!props.option.id) {
      openSnack({ msg: "Option 을 선택해주세요. " });
      return;
    }
    setActStock({ itemOptionId: props.option.id, itemId: props.option.itemId });
  };
  const handleOpenEditStock = (o: ItemStock) => () => {
    console.log(o);
    setActStock({ ...o, itemOptionId: props.option.id });
  };

  const handleCloseStockDialog = (completed?: boolean) => {
    console.log("handleCloseStockDialog");
    if (completed) {
      props.updateItem();
      refresh();
    }
    setActStock(undefined);
  };

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={3}>
              <Button variant="outlined">{props.option.name}</Button>
            </TableCell>
            <TableCell>
              <Button onClick={handleOpenAddStock}>Stock 추가</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>StockId</TableCell>
            <TableCell>창고</TableCell>
            <TableCell>수량</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stocks.map((o) => {
            const usedCount = o.orderedItems
              ? o.orderedItems.reduce((p, n) => p + (n.orderedCount ? n.orderedCount : 0), 0)
              : undefined;
            return (
              <TableRow>
                <TableCell>{o.stockId}</TableCell>
                <TableCell>{o.warehouse?.name ? o.warehouse.name : o.warehouseId}</TableCell>
                <TableCell>
                  {o.stockCount} {usedCount ? "(sold " + usedCount + ")" : ""}
                </TableCell>
                <TableCell>
                  <Button onClick={handleOpenEditStock(o)}>수정</Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ItemStockDialog
        open={actStock !== undefined}
        stock={actStock}
        onClose={handleCloseStockDialog}
      />
    </div>
  );
};

export default ItemStockTable;
