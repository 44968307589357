import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Theme,
} from "@mui/material";
import { ConstantKeys, CustomImage, Image, UILoad } from "../../../types/elements";
import { AddCircle } from "@mui/icons-material";
import PublicImageSelectorDialog from "../../organisms/PublicImageSelectorDialog";
import MobileMainSlide from "./MobileMainSlide";
import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../../../store/states/SnackState";
import { gqladm } from "../../../graphql/gqladm";
import UILoadEl from "../../molecules/UILoad";
import useConstant from "../../../hooks/useConstant";

const CONSTANT_KEY: ConstantKeys = "STATIC_CUSTOM_SLIDE_1";

interface ConstantCustomSlide1DialogProps {
  open: boolean;
  onClose: () => any;
}
const ConstantCustomSlide1Dialog: React.FC<ConstantCustomSlide1DialogProps> = (props) => {
  const openSnack = useSetRecoilState(SnackStateSelector);
  // const classes = useStyles();
  const { getConstant } = useConstant();
  const [uiLoad, setUILoad] = useState<UILoad>("init");
  const [uiSave, setUISave] = useState<UILoad>("init");
  const [list, setList] = useState<CustomImage[]>([]);
  const [cur, setCur] = useState<CustomImage | undefined>(undefined);

  const [defaultImageUrl, setDefaultImageUrl] = useState<string>("");
  const loadDefaultImage = () => {
    getConstant("STATIC_IMAGE_MAIN").then((result) => {
      if (result && typeof result.value === "string") setDefaultImageUrl(result.value);
    });
  };

  useEffect(() => {
    if (props.open === true) {
      load();
      loadDefaultImage();
    }
  }, [props.open]);

  const load = () => {
    setUILoad("loading");
    gqladm
      .searchConstants({ key: CONSTANT_KEY, isUniqueKey: true })
      .then((result) => {
        setUILoad("success");
        console.log("result", result);
        if (
          result.list &&
          result.list.length > 0 &&
          result.list[0].isJson === true &&
          typeof result.list[0].value === "string"
        ) {
          try {
            const data: CustomImage[] = JSON.parse(result.list[0].value);
            setList(data);
          } catch (err) {}
        }
      })
      .catch((err) => {
        setUILoad("error");
        console.log("error", err);
      });
  };
  const save = () => {
    console.log("save");
    setUISave("loading");
    gqladm
      .addConstant({ isJson: true, isPublic: true, key: CONSTANT_KEY, value: JSON.stringify(list) })
      .then((result) => {
        setUISave("success");
        console.log("result", result);
      })
      .catch((err) => {
        setUISave("error");
        console.log("Error ", err);
      });
  };

  const handleAddCustomImage = () => {
    setList([
      ...list,
      {
        id:
          list.length === 0
            ? 1
            : Math.max.apply(
                {},
                list.map((o) => (o.id ? o.id : -1))
              ) + 1,
        button_image_path: "",
        button_link: "",
        button_text: "",
        desc_text: "",
        image_link: "",
        image_path: "",
        title_text: "",
        title_tb: "", // TODO check top-bottom 위치 설정
        title_tbv: "",
        title_lr: "",
        title_lrv: "",
      },
    ]);
  };

  const handleSelectCustomImage = (o: CustomImage | undefined) => {
    console.log("cur", { ...o });
    setCur(o !== undefined ? { ...o } : undefined);
  };

  const handleDeleteCustomImage = (o: CustomImage) => {
    setList(list.filter((ci) => o.id !== ci.id));
    setCur(undefined);
  };

  const [imageSelectTarget, setImageSelectTarget] = useState<"main" | "button" | undefined>(
    undefined
  );
  const handleSelectImage = (selected?: Image) => {
    console.log("handleSelectImage", selected);
    if (imageSelectTarget === "main" && selected && selected.url && cur !== undefined) {
      setCur({ ...cur, image_path: selected.url });
    } else if (imageSelectTarget === "main" && selected && selected.url && cur !== undefined) {
      setCur({ ...cur, button_image_path: selected.url });
    }
    setImageSelectTarget(undefined);
  };

  const handleChangeField = (field: "title_text" | "desc_text" | "image_link") => (e: any) => {
    console.log("field", field, e.target.vlaue, cur);
    const obj: any = { ...cur };
    obj[field] = e.target.value;
    setCur(obj);
  };

  const handleSaveCur = () => {
    setList(list.map((o) => (cur && cur.id === o.id ? { ...cur } : o)));
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="lg">
      <DialogTitle>CUSTOM_MOBILE_MAIN_SLIDER </DialogTitle>
      <DialogContent>
        <div
          className={
            "constantCustomSlide1DialogRoot "
            // +  classes.dialogRoot
          }>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                border: "1px solid lightgray",
                padding: "10px",
                maxWidth: "310px",
                maxHeight: "400px",
              }}>
              <div>화면예시</div>
              <MobileMainSlide
                width={300}
                customImages={list}
                defaultImageUrl={defaultImageUrl}
                onClickImage={(link?: string) =>
                  openSnack({
                    msg: link
                      ? `이미지가 클릭되었고, 설정된 링크('${link}')로 이동합니다.`
                      : `이미지가 클릭되었지만, 설정된 링크가 없어서 아무동작을 하지 않습니다.`,
                  })
                }
                autoplay={false}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid lightgray",
              marginLeft: "5px",
            }}>
            {uiLoad === "init" && (
              <div className={/* classes.dialogRoot */ "constantCustomSlide1DialogRoot"}>
                로딩중
              </div>
            )}
            {uiLoad === "loading" && (
              <div className={/* classes.dialogRoot */ "constantCustomSlide1DialogRoot"}>
                로딩중
              </div>
            )}
            {uiLoad === "error" && (
              <div className={/* classes.dialogRoot */ "constantCustomSlide1DialogRoot"}>
                에러 발생
              </div>
            )}
            {uiLoad === "success" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#f0f0f0",
                }}>
                <div
                  style={{
                    display: "flex",
                    width: "400px",
                    overflowX: "auto",
                    padding: "10px",
                  }}>
                  {list.map((o) => {
                    return (
                      <div
                        className={"thumbBox" + (cur && cur.id === o.id ? " selected" : "")}
                        onClick={() => handleSelectCustomImage(o)}>
                        {o.image_path && (
                          <img
                            style={{ maxWidth: "48px", maxHeight: "64px" }}
                            src={"/" + o.image_path}
                          />
                        )}
                        <div className="thumbBox_content">{o.id}</div>
                      </div>
                    );
                  })}
                  {list.length === 0 && (
                    <div>
                      우측의 <AddCircle /> 버튼을 눌러 슬라이드를 추가해주세요
                    </div>
                  )}
                </div>
                <div>
                  <IconButton onClick={handleAddCustomImage}>
                    <AddCircle />
                  </IconButton>
                </div>
              </div>
            )}
            {cur !== undefined ? (
              <div>
                {/* <div>이미지 설정 id={cur.id} </div> */}
                <div>
                  <table>
                    <tr>
                      <th>이미지</th>
                      <td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {cur.image_path && (
                            <img
                              src={"/" + cur.image_path}
                              style={{ width: "48px", height: "64px" }}
                            />
                          )}
                          <Button
                            onClick={() => setImageSelectTarget("main")}
                            variant="contained"
                            color="primary">
                            이미지 선택
                          </Button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Title</th>
                      <td>
                        <TextField
                          onChange={handleChangeField("title_text")}
                          value={cur.title_text}
                          size="small"
                        />
                        {/* <div>
                          Position
                          <div>
                            <Button
                              onClick={() => {
                                const c = { ...cur };
                                if (c)
                                  c.title_tb =
                                    c.title_tb === ""
                                      ? "top"
                                      : c.title_tb === "top"
                                      ? "bottom"
                                      : c.title_tb === "bottom"
                                      ? ""
                                      : "";
                                setCur(c);
                              }}>
                              {cur.title_tb === undefined || cur.title_tb === ""
                                ? "No Top-Bottom"
                                : cur.title_tb}
                            </Button>
                            <TextField
                              value={cur.title_tbv}
                              onChange={(e) => {
                                const c = { ...cur };
                                c.title_tbv = e.target.value;
                                setCur(c);
                              }}
                              size="small"
                            />
                          </div>
                          <div>
                            <Button></Button>
                          </div>
                        </div> */}
                      </td>
                    </tr>

                    <tr>
                      <th>Description</th>
                      <td>
                        <TextField
                          value={cur.desc_text}
                          onChange={handleChangeField("desc_text")}
                          size="small"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Image Link</th>
                      <td>
                        <TextField
                          value={cur.image_link}
                          onChange={handleChangeField("image_link")}
                          size="small"
                        />
                      </td>
                    </tr>
                  </table>
                  <PublicImageSelectorDialog
                    open={imageSelectTarget !== undefined}
                    onClose={handleSelectImage}
                  />
                </div>

                <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                  <Button onClick={() => handleSaveCur()} variant="contained" color="primary">
                    저장
                  </Button>
                  &nbsp;
                  <Button onClick={() => handleSelectCustomImage(undefined)} variant="contained">
                    취소
                  </Button>
                  &nbsp;
                  <Button
                    onClick={() => handleDeleteCustomImage(cur)}
                    variant="contained"
                    color="secondary">
                    삭제
                  </Button>
                </div>
              </div>
            ) : (
              <div>* 상단의 이미지를 선택해주세요</div>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div>전체 작업하신후 저장을 누르셔야합니다. </div>
        <Button variant="contained" color="primary" onClick={save} disabled={uiSave === "loading"}>
          <UILoadEl
            uiload={uiSave}
            loading="저장중"
            init="저장"
            success="저장완료"
            error="에러발생"
          />
        </Button>
        <Button variant="contained" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConstantCustomSlide1Dialog;
