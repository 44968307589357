import React from "react";
import { getThumbUrl, Image, ItemImagePlaceType } from "../../../types/elements";
import FetchImg from "../../molecules/FetchImg";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from "@mui/icons-material/Delete";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import { IconButton } from "@mui/material";
import { gqladm } from "../../../graphql/gqladm";
import { ImageAPI } from "../../../api";
import useSnack from "../../../hooks/useSnack";

interface UploadedItemImageProps {
  image: Image;
  refresh: () => any;
  item_id?: number;
  disableDeleteButton?: boolean;
}
const UploadedItemImage: React.FC<UploadedItemImageProps> = ({
  item_id,
  image,
  refresh,
  disableDeleteButton,
}) => {
  const { openSnack } = useSnack();
  //  bind image
  const handleBindImage = (image: Image, place: ItemImagePlaceType) => {
    item_id &&
      image.id &&
      gqladm.saveItemImage(item_id, image.id, Date.now(), "top").then((result) => {
        console.log("bindresult ", result);
        refresh();
      });
  };

  //   delete image
  const handleDeleteImage = (image: Image) => {
    const confirm = window.confirm("Are you sure?");
    console.log(image);
    if (confirm === true && image.uid) {
      console.log(image);
      ImageAPI.deleteItemImage(image.uid).then((result) => {
        console.log(result);
        const data = result.data;
        if (data.status === "SUCCESS" && data.result) {
          refresh();
        }
      });
    } else {
      openSnack({ msg: "이미지 id에 오류가 있습니다." });
    }
  };

  const handleCreateImageThumbnail = (imageId: number) => {
    console.log("handleCreateImageThumbnail", imageId);
    gqladm
      .createImageThumbnail(imageId)
      .then((result) => {
        console.log("createImageThumbnail", result);
        refresh();
      })
      .catch((err) => {
        console.log("Error createImageThumbnail", err);
      });
  };

  return (
    <div style={{ display: "inline-block" }}>
      <FetchImg
        width="128"
        height="128"
        style={{ display: "block" }}
        thumbnail={image.url && "/" + getThumbUrl(image.url)}
        url={image.url && "/" + image.url}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          borderRadius: "10px",
          backgroundColor: "#f0f0f0",
        }}>
        <IconButton size="small" onClick={() => handleBindImage(image, "top")}>
          <AddPhotoAlternateIcon />
        </IconButton>

        <IconButton size="small" onClick={() => image.id && handleCreateImageThumbnail(image.id)}>
          <PhotoSizeSelectLargeIcon />
        </IconButton>

        <IconButton
          size="small"
          onClick={() => handleDeleteImage(image)}
          disabled={disableDeleteButton}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default UploadedItemImage;
