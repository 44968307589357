import { Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import useItemMeta from "../../../../hooks/useItemMeta";
import { Consignment, CommonUtil as cu } from "../../../../types/elements";
import ImageViewClickPanel from "../../../organisms/ImageViewClickPanel";
import { ConsignmentResultProps } from "./ConsignmentListView";

interface ConsignmentResultTableProps extends ConsignmentResultProps {}

const ConsignmentResultTable: React.FC<ConsignmentResultTableProps> = ({
  list,
  openDialog,
  openUser,
}) => {
  const handleOpenView = (id?: number) => {
    if (id && openDialog) openDialog(id);
  };

  return (
    <div>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell className="noBreakWord">Status / 신청일자</TableCell>
            <TableCell>위탁상품</TableCell>
            <TableCell>Account Info</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((mr, mrIdx) => {
            return (
              <TableRow key={mrIdx}>
                <TableCell>
                  <Button onClick={() => handleOpenView(mr?.id)}>{mr?.id}</Button>
                </TableCell>
                <TableCell className="noBreakWord">
                  {mr?.status}
                  <br />
                  {mr?.created && cu.getFormattedDate(mr.created, "yyyy-MM-dd")}
                </TableCell>
                <TableCell>
                  {mr.items && mr.items.length > 0 && (
                    <Table size="small">
                      <TableHead>
                        <TableCell className="noBreakWord">상태</TableCell>
                        <TableCell className="noBreakWord">상세</TableCell>
                        <TableCell className="noBreakWord">구입처</TableCell>
                        <TableCell className="noBreakWord">구입금액</TableCell>
                        <TableCell className="noBreakWord">희망판매금액</TableCell>
                      </TableHead>
                      <TableBody>
                        {mr.items.map((item, itemIdx) => {
                          return (
                            <TableRow key={itemIdx}>
                              <TableCell className="noBreakWord">{item.status}</TableCell>
                              <TableCell>
                                <div style={{ display: "flex" }}>
                                  <div>
                                    {item?.images &&
                                      item.images.map((image) => (
                                        <ImageViewClickPanel
                                          key={"image_" + image.id}
                                          url={image.url}>
                                          <img
                                            key={image.url}
                                            src={image.url}
                                            style={{ maxWidth: "100px", marginRight: "1px" }}
                                          />
                                        </ImageViewClickPanel>
                                      ))}
                                  </div>
                                  <div></div>
                                  {item?.designer}
                                </div>
                              </TableCell>
                              <TableCell>{item?.pop}</TableCell>
                              <TableCell>{item?.origin_price}</TableCell>
                              <TableCell>{item?.expect_price}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </TableCell>
                <TableCell className="noBreakWord">
                  <Button onClick={() => mr.user_id && openUser && openUser(mr.user_id)}>
                    #{mr?.user_id} {mr?.user?.name}
                  </Button>
                  <br />
                  {mr?.user?.phone}
                  <br />
                  {mr?.user?.email}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default ConsignmentResultTable;
