import React from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../../../types/elements";
import UserDetailAccountInfo from "./UserDetailAccountInfo";
import UserDetailDashboard from "./UserDetailDashboard";
import UserDetailOrders from "./order/UserDetailOrder";
import UserDetailRequest from "./request/UserDetailRequest";
import UserDetailReservation from "./UserDetailReservations";
import { UserDetailViewIdType } from "./UserDetailView";
import UserDetailWish from "./UserDetailWish";
import UserMemoList from "../../../organisms/userinfodialog/UserMemoList";

interface UserInfoPCLayoutProps {
  user: User;
  user_id: number;
  view_id: UserDetailViewIdType;
  onChangeViewId: (viewId: UserDetailViewIdType) => void;
}
const UserInfoPCLayout: React.FC<UserInfoPCLayoutProps> = (props) => {

  const userId = props.user_id;
  const viewId: UserDetailViewIdType =
    ["", undefined].indexOf(props.view_id) > -1 ? "dashboard" : props.view_id;

  const go = (targetViewId: UserDetailViewIdType) => {
    // navi(`/user/${props.user.id}/${targetViewId}`);
    props.onChangeViewId(targetViewId);
  };
  return (
    <div className="userInfoPCLayout">
      <div className="leftMenu">
        <div className="userName">{props.user.name} 님</div>
        <div className="menuBody">
          <div
            className={`menuItem ${viewId === "dashboard" && " selected"}`}
            onClick={() => go("dashboard")}>
            DashBoard
          </div>
          <div
            className={`menuItem ${viewId === "account_information" && "selected"}`}
            onClick={() => go("account_information")}>
            Account Information
          </div>
          <div
            className={`menuItem ${viewId === "order" && "selected"}`}
            onClick={() => go("order")}>
            Orders
          </div>
          <div
            className={`menuItem ${viewId === "reservation" && "selected"}`}
            onClick={() => go("reservation")}>
            Reservation
          </div>
          <div
            className={`menuItem ${viewId === "request" && "selected"}`}
            onClick={() => go("request")}>
            Service Request
          </div>
          <div className={`menuItem ${viewId === "wish" && "selected"}`} onClick={() => go("wish")}>
            Wish List
          </div>
        </div>
        <UserMemoList user_id={userId} />
      </div>
      <div className="rightBody">
        {viewId === "dashboard" ? (
          <UserDetailDashboard user_id={userId} />
        ) : viewId === "account_information" ? (
          <UserDetailAccountInfo user_id={userId} />
        ) : viewId === "order" ? (
          <UserDetailOrders user_id={userId} />
        ) : viewId === "reservation" ? (
          <UserDetailReservation user_id={userId} />
        ) : viewId === "request" ? (
          <UserDetailRequest user_id={userId} />
        ) : viewId === "wish" ? (
          <UserDetailWish />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default UserInfoPCLayout;
