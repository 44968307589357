import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import useSnack from "../../../hooks/useSnack";
import { ItemMeta, ItemMetaExtUI } from "../../../types/elements";
import { KvTable, KvTr } from "../../molecules/Layout";

interface ItemMetaDialogProps {
  open: boolean;
  onClose: (refresh?: boolean) => any;
  itemMeta?: ItemMeta;
  allList?: ItemMetaExtUI[];
}
const ItemMetaDialog: React.FC<ItemMetaDialogProps> = (props) => {
  const loadingRef = useRef<boolean>();
  const [obj, setObj] = useState<ItemMeta>({});
  const { openSnack } = useSnack();

  const handleClose = () => {
    setObj({});
    props.onClose();
  };

  useEffect(() => {
    if (props.open && props.itemMeta) {
      loadingRef.current = false;
      setObj(props.itemMeta);
    }
  }, [props.open, props.itemMeta]);

  const handleChange = (col: "name" | "desc" | "key" | "seq" | "parent") => (e: any) => {
    const value = e.target.value;
    setObj((o) => {
      const ob: ItemMeta = { ...o };
      ob[col] = value;
      return ob;
    });
  };

  const handleClickSave = () => {
    if (loadingRef.current === false) {
      loadingRef.current = true;
      if (!obj.name) {
        openSnack({ msg: "name은 필수값입니다. " });
        loadingRef.current = false;
        return;
      }
      if (isNaN(Number(obj.seq))) {
        openSnack({ msg: "seq는 숫자값이어야 합니다." });
        loadingRef.current = false;
        return;
      }
      gqladm
        .saveItemMeta({
          id: Number(obj.id),
          name: obj.name,
          desc: obj.desc,
          key: obj.key,
          parent: Number(obj.parent),
          seq: Number(obj.seq),
          type: obj.type,
        })
        .then((result) => {
          console.log("result", result);
          loadingRef.current = false;
          if (result.ok) props.onClose(true);
        })
        .catch((err) => {
          loadingRef.current = false;
        });
    } else {
      openSnack({ msg: "진행중입니다." });
    }
  };
  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <KvTable>
          <KvTr k={"type"} v={props.itemMeta?.type} />
          <KvTr k={"id"} v={props.itemMeta?.id} />
          <KvTr k={"name"} v={<TextField value={obj.name} onChange={handleChange("name")} />} />
          <KvTr k={"desc"} v={<TextField value={obj.desc} onChange={handleChange("desc")} />} />
          <KvTr k={"key"} v={<TextField value={obj.key} onChange={handleChange("key")} />} />
          <KvTr
            k={"seq"}
            v={<TextField value={obj.seq} onChange={handleChange("seq")} type="number" />}
          />
          {props.allList !== undefined && (
            <KvTr
              k={"parent"}
              v={
                <Select value={obj.parent} onChange={handleChange("parent")}>
                  <MenuItem value={""}>&gt;&gt;Top Category&lt;&lt;</MenuItem>
                  {props.allList.map((pl: ItemMetaExtUI) => {
                    const disabled =
                      pl.treenodes && pl.treenodes.filter((o) => o.id === obj.id).length > 0;
                    return (
                      <MenuItem key={pl.id} value={pl.id} disabled={disabled}>
                        {pl.treenodes?.map((o) => o.name).join(" > ")}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
            />
          )}
        </KvTable>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickSave}>Save</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemMetaDialog;
