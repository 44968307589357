import { Settings } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";

interface UserActionMenuButtonProps {
  user_id: number;
}
const UserActionMenuButton: React.FC<UserActionMenuButtonProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Settings />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose}>
        <MenuItem onClick={(e) => console.log("Update user phone. clicked todo develop")}>
          Update user phone
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserActionMenuButton;
