import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { CommonUtil as cu, DAY } from "../../../../types/elements";
import UserMemoBox from "../../../organisms/UserMemoBox";
import { User } from "../../../../types/adm_elements";
import { UserResultProps } from "./UserListView";
import SelectedActions from "./SelectedActions";
import HelpIcon from "../../../molecules/HelpIcon";

type UserFieldType = "f1" | "f2" | "f3" | "f4" | "f5" | "f6" | "f7" | "f8" | "f9" | "f10" | "f11";
interface UserResultTableProps extends UserResultProps {
  selected: number[];
  toggleSelectedAll: () => void;
  toggleSelected: (id: number) => void;
}
const UserResultTable: React.FC<UserResultTableProps> = ({
  list,
  openUser,
  selected,
  toggleSelectedAll,
  toggleSelected,
}) => {
  const [openSelectFields, setOpenSelectFields] = useState<boolean>(false);
  const [showFields, setShowFields] = useState<UserFieldType[]>([
    "f1",
    "f2",
    // "f3",
    // "f4",
    // "f5",
    // "f6",
    "f7",
    "f8",
    "f9",
    "f11",
    "f10",
  ]);
  const toggleShowFields = (fieldKey: UserFieldType) => {
    setShowFields((fs) =>
      fs.includes(fieldKey) ? fs.filter((o) => o != fieldKey) : [...fs, fieldKey]
    );
  };
  const isShow = (fieldKey: UserFieldType) => showFields.includes(fieldKey);
  return (
    <>
      {selected.length > 0 && <SelectedActions selected={selected} />}
      {selected.length === 0 && (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Button onClick={() => setOpenSelectFields(true)}>노출필드선택</Button>
        </div>
      )}
      <Dialog open={openSelectFields} onClose={() => setOpenSelectFields(false)}>
        <DialogTitle>필드 선택</DialogTitle>
        <DialogContent>
          <Chip
            label="ID"
            color={isShow("f1") ? "primary" : "default"}
            // onClick={() => toggleShowFields("f1")}
          />
          <Chip
            label="TYPE"
            color={isShow("f8") ? "primary" : "default"}
            onClick={() => toggleShowFields("f8")}
          />
          <Chip
            label="Name/Phone"
            color={isShow("f2") ? "primary" : "default"}
            onClick={() => toggleShowFields("f2")}
          />
          <Chip
            label="Email"
            color={isShow("f3") ? "primary" : "default"}
            onClick={() => toggleShowFields("f3")}
          />
          <Chip
            label="Subscription Date"
            color={isShow("f4") ? "primary" : "default"}
            onClick={() => toggleShowFields("f4")}
          />
          <Chip
            label="Email/Phone Authorized"
            color={isShow("f5") ? "primary" : "default"}
            onClick={() => toggleShowFields("f5")}
          />
          <Chip
            label="Mail/SMS"
            color={isShow("f6") ? "primary" : "default"}
            onClick={() => toggleShowFields("f6")}
          />
          <Chip
            label="통계"
            color={isShow("f9") ? "primary" : "default"}
            onClick={() => toggleShowFields("f9")}
          />
          <Chip
            label="Note"
            color={isShow("f7") ? "primary" : "default"}
            onClick={() => toggleShowFields("f7")}
          />
          <Chip
            label="가입일"
            color={isShow("f11") ? "primary" : "default"}
            onClick={() => toggleShowFields("f11")}
          />
          <Chip
            label="최근 로그인"
            color={isShow("f10") ? "primary" : "default"}
            onClick={() => toggleShowFields("f10")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSelectFields(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox checked={list.length === selected.length} onClick={toggleSelectedAll} />
              </TableCell>
              {isShow("f1") && <TableCell align="center">ID</TableCell>}
              {isShow("f8") && <TableCell>TYPE</TableCell>}
              {isShow("f2") && <TableCell>Name/Phone</TableCell>}
              {isShow("f3") && <TableCell>Email</TableCell>}
              {isShow("f4") && <TableCell>Subscription Date</TableCell>}
              {isShow("f5") && <TableCell>Email/Phone Authorized</TableCell>}
              {isShow("f6") && <TableCell>Mail/SMS</TableCell>}
              {isShow("f9") && (
                <TableCell>
                  통계
                  <HelpIcon fontSize="small">
                    통계정보는 (예약 노쇼 횟수 / 주문 완료 횟수 / 주문 완료 금액) 순으로 표시됩니다.
                    통계가 나타나지 않는 정보 및 오래된 정보는 행을 선택한뒤 "통계갱신" 버튼을 통해
                    정보를 갱신해주세요. 일반적으로는 유저들의 각 액션들에 따라 자동적용 되지만,
                    안그런 항목도 있을수 있습니다
                  </HelpIcon>
                </TableCell>
              )}
              {isShow("f7") && <TableCell>Note</TableCell>}
              {isShow("f11") && <TableCell>가입일</TableCell>}
              {isShow("f10") && <TableCell>최근 로그인</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((user: User) => {
              return (
                <TableRow key={user.id}>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        size="small"
                        checked={user.id !== undefined && selected.indexOf(user.id) > -1}
                        onClick={() => user.id !== undefined && toggleSelected(user.id)}
                      />
                    </div>
                  </TableCell>
                  {isShow("f1") && (
                    <TableCell align="center">
                      <Button
                        size="small"
                        onClick={() => user.id !== undefined && openUser(user.id)}>
                        {user.id}
                      </Button>
                    </TableCell>
                  )}
                  {isShow("f8") && (
                    <TableCell>
                      {user.type === "Offline"
                        ? "오프라인"
                        : user.type === "Online"
                        ? "온라인"
                        : ""}
                    </TableCell>
                  )}
                  {isShow("f2") && (
                    <TableCell>
                      {user.name}
                      <br />
                      {user.phone}
                    </TableCell>
                  )}
                  {isShow("f3") && <TableCell>{user.email}</TableCell>}
                  {isShow("f4") && (
                    <TableCell>
                      {user.created && cu.getFormattedDate(user.created, "yyyy-MM-dd")}
                    </TableCell>
                  )}
                  {isShow("f5") && (
                    <TableCell>
                      {user.isEmailAuthorized ? "O" : "X"} / {user.isPhoneAuthorized ? "O" : "X"}
                    </TableCell>
                  )}
                  {isShow("f6") && (
                    <TableCell>
                      {user.agreeEmailDate ? "O" : "X"} / {user.agreeSMSDate ? "O" : "X"}
                    </TableCell>
                  )}
                  {isShow("f9") && (
                    <TableCell>
                      {user.num_reservation_noshow} / {user.num_order_done_count} /{" "}
                      {user.num_order_done_price}
                    </TableCell>
                  )}

                  {isShow("f7") && (
                    <TableCell>
                      {user.id ? (
                        <UserMemoBox user_id={user.id} userMemos={user.userMemos?.list} />
                      ) : (
                        "No user.id"
                      )}
                    </TableCell>
                  )}
                  {isShow("f11") && (
                    <TableCell>
                      {user.created &&
                        cu.getFormattedDate(user.created - DAY / 2, "yyyy-MM-dd hh:mm")}
                    </TableCell>
                  )}
                  {isShow("f10") && (
                    <TableCell>
                      {user.sessionExpired &&
                        cu.getFormattedDate(user.sessionExpired - DAY / 2, "yyyy-MM-dd hh:mm")}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UserResultTable;
