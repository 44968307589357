import React, { useEffect, useState } from "react";
import {
  ServiceRequest,
  getFormattedDate,
  getServiceRequestTypeKr,
} from "../../../../../types/elements";
import { gqladm } from "../../../../../graphql/gqladm";

const tdStyle: React.CSSProperties = { textAlign: "center" };

interface UserInfoServiceRequestProps {
  user_id: number;
}
const UserInfoServiceRequest: React.FC<UserInfoServiceRequestProps> = (props) => {
  const [list, setList] = useState<ServiceRequest[]>([]);
  useEffect(() => {
    if (props.user_id) {
      gqladm
        .serviceRequests({ user_id: props.user_id, limit: 10, ordering: "newerfirst" })
        .then((result) => {
          if (result.list) setList(result.list);
        });
    }
  }, [props.user_id]);
  return (
    <div className="simple1panel" style={{ minWidth: "320px", flex: 1 }}>
      <div className="simple1panel_head">Service Request</div>
      <div className="simple1panel_body">
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th>날짜</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {list.map((sq, idx) => {
              return (
                <tr key={"sq_" + idx}>
                  <td style={tdStyle}>{sq.id}</td>
                  <td style={tdStyle}>{getServiceRequestTypeKr(sq.type)}</td>
                  <td style={tdStyle}>
                    {sq.updated && getFormattedDate(sq.updated, "yyyy-MM-dd")}
                  </td>
                  <td style={tdStyle}>
                    {sq.consignment?.status ?? sq.rental?.status ?? sq.styling?.status ?? ""}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserInfoServiceRequest;
