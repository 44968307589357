import { useEffect, useState } from "react";
import { gqladm } from "../graphql/gqladm";

export const useDashUserCount = () => {
  const [total, setTotal] = useState<number | undefined>();
  const [online, setOnline] = useState<number | undefined>();
  const [offline, setOffline] = useState<number | undefined>();
  useEffect(() => {
    gqladm.getUsers({ limit: 1 }).then((result) => {
      if (result.total !== undefined) setTotal(result.total);
    });
    gqladm.getUsers({ limit: 1, type: "Online" }).then((result) => {
      if (result.total !== undefined) setOnline(result.total);
    });
    gqladm.getUsers({ limit: 1, type: "Offline" }).then((result) => {
      if (result.total !== undefined) setOffline(result.total);
    });
  }, []);
  return { total_count: total, online_count: online, offline_count: offline };
};
