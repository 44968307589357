import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gqladm } from "../../../../graphql/gqladm";
import { useAppCommon } from "../../../../hooks/useAppCommon";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { Rental, RentalSearchOption } from "../../../../types/elements";
import RentalDetail from "./RentalDetail";
import RentalResultList from "./RentalResultList";
import RentalResultTable from "./RentalResultTable";
import RentalSearchBox from "./RentalSearchBox";

export interface RentalResultProps {
  list: Rental[];
  openDialog: (id: number) => any;
  openUser: (user_id: number) => void;
}

interface RentalListViewProps {}
const RentalListView: React.FC<RentalListViewProps> = (props) => {
  const navi = useNavigate();
  const {} = useAppCommon({ headerTitle: "렌탈 신청 리스트" });
  const { isMobile } = useWindowSize();

  const [list, setList] = useState<Rental[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);

  const [sopt, setSopt] = useState<RentalSearchOption>({ ordering: "newerfirst", limit: 20 });
  const handleSearch = (opt: RentalSearchOption) => {
    load({ ...sopt, ...opt, offset: 0 });
  };
  const load = (opt: RentalSearchOption) => {
    gqladm.rentals(opt).then((result) => {
      console.log(result);
      if (result.list !== undefined) setList(result.list);
      if (result.total !== undefined) setTotal(result.total);
      if (result.offset !== undefined) setOffset(result.offset);
      if (result.limit !== undefined) setLimit(result.limit);
    });
  };

  const handleChangePage = (page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : 20);
    load({ ...sopt, offset });
  };

  // for dialog
  const [rentalId, setRentalId] = useState<number | undefined>();

  const handleOpenDialog = (id: number) => {
    console.log("handleOpenDialog", id);
    setRentalId(id);
  };
  return (
    <div>
      <RentalSearchBox onSearch={handleSearch} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          onChange={(e, p) => handleChangePage(p)}
          count={Math.ceil(total / limit)}
          page={Math.ceil(offset / limit) + 1}
        />
      </div>
      <div>
        {isMobile ? (
          <RentalResultList
            list={list}
            openDialog={handleOpenDialog}
            openUser={(user_id) => navi("/user/" + user_id)}
          />
        ) : (
          <RentalResultTable
            list={list}
            openDialog={handleOpenDialog}
            openUser={(user_id) => navi("/user/" + user_id)}
          />
        )}
        <Dialog open={rentalId !== undefined} onClose={() => setRentalId(undefined)}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            {rentalId && <RentalDetail id={rentalId} onSaveDone={() => setRentalId(undefined)} />}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setRentalId(undefined)}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default RentalListView;
