// import { Pagination } from "@mui/lab";
import Pagination from "@mui/material/Pagination";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { Reservation, ReservationSearchOption } from "../../../types/elements";
import { CommonUtil as cu } from "../../../types/elements";

const LIMIT = 10;
interface UserReservationListProps {
  user_id: number;
}
const UserReservationList: React.FC<UserReservationListProps> = (props) => {
  const [list, setList] = useState<Reservation[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [sopt, setSopt] = useState<ReservationSearchOption>({
    limit: LIMIT,
    offset: 0,
    user_id: props.user_id,
  });
  useEffect(() => {
    if (props.user_id) load(sopt);
  }, [props.user_id]);

  const load = (opt: ReservationSearchOption) => {
    gqladm
      .searchReservation({ ...opt })
      .then((result) => {
        if (result.list) setList(result.list);
        if (result.total) setTotal(result.total);
        setSopt({
          ...sopt,
          limit: result.limit !== undefined ? result.limit : sopt.limit,
          offset: result.offset !== undefined ? result.offset : sopt.offset,
        });
      })
      .catch((err) => {
        console.log("Error on load", err);
      });
  };

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };
  return (
    <div>
      <div>
        <div>
          Count:&nbsp;
          {sopt.offset !== undefined ? sopt.offset + 1 : -1}~
          {sopt.offset !== undefined
            ? sopt.offset + (list.length < LIMIT ? list.length : LIMIT)
            : -1}
          &nbsp;/&nbsp;
          {total}
        </div>
      </div>
      <table className="simpletable">
        <tr>
          <th>ID</th>
          <th>날짜</th>
          <th>시간</th>
          <th>인증번호</th>
          <th>메모</th>
          <th>예약시간</th>
        </tr>
        {list.map((um) => {
          return (
            <tr key={um.id}>
              <td>{um.id} </td>
              <td>{um.date}</td>
              <td>{um.time}</td>
              <td>{um.phone}</td>
              <td>{um.memo}</td>
              <td>{um.created && cu.getFormattedDate(um.created, "yyyy-MM-dd HH:mm:ss")} </td>
            </tr>
          );
        })}
      </table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
          page={
            Math.ceil(
              (sopt.offset !== undefined ? sopt.offset : 0) /
                (sopt.limit !== undefined ? sopt.limit : LIMIT)
            ) + 1
          }
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default UserReservationList;
