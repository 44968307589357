import { Button, Chip, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { ItemStock, ItemStockSearchOption, Warehouse } from "../../../types/elements";
import StockTable from "./stocklist/StockTable";

const LIMIT = 10;
interface StockListProps {
  warehouses: Warehouse[];
}
const StockList: React.FC<StockListProps> = ({ warehouses }) => {
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(1);
  const [list, setList] = useState<ItemStock[]>([]);
  const [sopt, setSopt] = useState<ItemStockSearchOption>({
    orderby: "created_desc",
    warehouseIds: [],
  });

  const load = (sopt: ItemStockSearchOption) => {
    gqladm.searchItemStocks(sopt).then((result) => {
      if (result.total !== undefined) setTotal(result.total);
      if (result.list !== undefined) setList(result.list);
      setSopt((so) => ({
        ...so,
        ...sopt,
        offset: result.offset !== undefined ? result.offset : sopt.offset,
        limit: result.limit !== undefined ? result.limit : sopt.offset,
      }));
    });
  };

  useEffect(() => {
    load(sopt);
  }, []);

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  return (
    <div>
      <div>
        재고 정보 검색 - 창고 :
        {warehouses.map((wh) => (
          <Chip
            key={wh.id}
            label={wh.name}
            color={wh.id && sopt.warehouseIds?.includes(wh.id) ? "primary" : "default"}
            onClick={() => {
              setSopt((so) => {
                const sopt = { ...so };
                const whid = wh.id ?? -1;
                const whs = so.warehouseIds ?? [];
                sopt.warehouseIds = whs.includes(whid)
                  ? whs.filter((o) => o !== whid)
                  : [...whs, whid];
                return sopt;
              });
            }}
          />
        ))}{" "}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div></div>
        <div>
          <Pagination
            count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
            page={
              Math.ceil((sopt.offset ? sopt.offset : 0) / (sopt.limit ? sopt.limit : LIMIT)) + 1
            }
            onChange={handleChangePage}
          />
        </div>
        <Button color="primary" variant="contained" onClick={() => load(sopt)}>
          검색
        </Button>
      </div>
      <StockTable list={list} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
          page={Math.ceil((sopt.offset ? sopt.offset : 0) / (sopt.limit ? sopt.limit : LIMIT)) + 1}
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default StockList;
