import Axios from "axios";
import { APIResponse, Image, API_PATH, MultiImageTarget } from "../types/elements";

export const ImageAPI = {
  getItemImages: (itemId: number): Promise<APIResponse<Image[]>> => {
    return Axios.get("/admin" + API_PATH.IMAGE + "?itemId=" + itemId);
  },
  postItemImage: (image: Image): Promise<APIResponse<Image>> => {
    const formData = new FormData();
    formData.append("file", image.file);
    formData.append("itemId", "" + image.itemId);
    // formData.append("modified", "" + image.modified?.getTime());
    formData.append("modified", "" + image.modified);
    const config = { headers: { "content-type": "multipart/form-data" } };
    return Axios.post("/admin" + API_PATH.IMAGE, formData, config);
  },
  deleteItemImage: (imageId: string): Promise<APIResponse<any>> => {
    return Axios.delete("/admin" + API_PATH.IMAGE + "/" + imageId);
  },

  // MultiImage
  postMultiImage: (
    image: Image,
    target: MultiImageTarget,
    targetId: number
  ): Promise<APIResponse<Image>> => {
    const formData = new FormData();
    formData.append("file", image.file);
    formData.append("itemId", "" + -2);
    formData.append("modified", "" + image.modified);
    formData.append("mmt", "" + target);
    formData.append("mmti", "" + targetId);

    const config = { headers: { "content-type": "multipart/form-data" } };
    return Axios.post("/admin" + API_PATH.IMAGE, formData, config);
  },
};
