import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { gqladm } from "../../../graphql/gqladm";
import { useAppCommon } from "../../../hooks/useAppCommon";
import { Reservation } from "../../../types/elements";
import { KvTable, KvTr } from "../../molecules/Layout";

interface ReservationDetailViewProps {}
const ReservationDetailView: React.FC<ReservationDetailViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "예약 상세" });
  const params = useParams<{ id: string }>();
  const [reserv, setReserv] = useState<Reservation | undefined>(undefined);

  useEffect(() => {
    if (params.id && !isNaN(Number(params.id))) {
      gqladm.searchReservation({ id: Number(params.id) }).then((r) => {
        if (r.list && r.list.length === 1) {
          setReserv(r.list[0]);
        }
      });
    }
  }, [params.id]);
  return (
    <div>
      {reserv && (
        <Paper>
          <KvTable>
            <KvTr k="id" v={reserv.id} />
            <KvTr k="date" v={reserv.date} />
            <KvTr k="time" v={reserv.time} />
            <KvTr k="status" v={reserv.status} />
            <KvTr k="memo" v={reserv.memo} />

            <KvTr k="user_id" v={reserv.user_id} />
            <KvTr k="name" v={reserv.name} />
            <KvTr k="phone" v={reserv.phone} />
            <KvTr k="noShowCount" v={reserv.noShowCount} />
            <KvTr k="reservedCount" v={reserv.reservedCount} />
          </KvTable>
        </Paper>
      )}
    </div>
  );
};

export default ReservationDetailView;
