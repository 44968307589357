import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Input,
  Radio,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { StockAPI } from "../../api";
import { client } from "../../graphql/client";
import { gqladm } from "../../graphql/gqladm";
import { MutationIF, SAVE_ITEM_STOCK } from "../../graphql/types";
import { SnackStateSelector } from "../../store/states/SnackState";
import { ItemStock, Warehouse } from "../../types/elements";
interface ItemStockDialogProps {
  open: boolean;
  onClose: (completed?: boolean) => any;
  stock?: ItemStock;
}
const ItemStockDialog: React.FC<ItemStockDialogProps> = (props) => {
  const openSnack = useSetRecoilState(SnackStateSelector);
  const [stock, setStock] = useState<ItemStock>(props.stock ? props.stock : {});
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  useEffect(() => {
    gqladm
      .getWarehouses({})
      .then((result) => {
        if (result.list) setWarehouses(result.list);
      })
      .catch((err) => console.log("Error", err));
  }, []);
  useEffect(() => {
    setStock(props.stock ? props.stock : {});
  }, [props.stock]);

  const handleChangeStockCount = (e: any) => {
    setStock({ ...stock, stockCount: Number(e.target.value) });
  };
  const handleChangeWarehooue = (wid?: number) => (e: any) => {
    setStock({ ...stock, warehouseId: wid ? wid : -1 });
  };
  const handleUpdate = () => {
    console.log("handleUpdate", stock);
    if (stock.itemOptionId === undefined) {
      openSnack({ msg: "itemOptionId is not set. please contact developer ", severity: "error" });
      return;
    } else if (stock.warehouseId === -1) {
      openSnack({ msg: "Please select a warehouse", severity: "error" });
      return;
    } else if (isNaN(Number(stock.stockCount))) {
      openSnack({ msg: "Please input number of count", severity: "error" });
      return;
    }
    const obj: ItemStock = {};
    obj.stockId = stock.stockId;
    obj.warehouseId = stock.warehouseId;
    obj.itemId = stock.itemId;
    obj.itemOptionId = stock.itemOptionId;
    obj.stockCount = stock.stockCount;
    obj.memo = stock.memo;

    client
      .mutate<MutationIF, { stock?: ItemStock }>({
        mutation: SAVE_ITEM_STOCK,
        variables: { stock: obj },
      })
      .then((result) => {
        console.log("SAVE_ITEM_STOCK", result);
        props.onClose(true);
      })
      .catch((err) => {
        console.log("Error", err);
      });
    // TODO dev
  };
  return (
    <Dialog open={props.open}>
      <DialogTitle>재고 정보 {props.stock?.stockId ? "수정" : "입력"}</DialogTitle>
      <DialogContent>
        창고:
        {warehouses.map((o) => (
          <FormControlLabel
            key={o.id}
            control={<Radio checked={stock.warehouseId === o.id} />}
            label={o.name}
            onClick={handleChangeWarehooue(o.id)}
          />
        ))}
        <br />
        수량: <TextField value={stock.stockCount} onChange={handleChangeStockCount} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpdate}>{props.stock?.stockId ? "수정" : "입력"}</Button>
        <Button onClick={() => props.onClose(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemStockDialog;
