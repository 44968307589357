import { WrapperAxios as Axios } from "./base";
import { CommonUtil, APIResponse, QnaSearchOption, Qna, QnaReply, SpamSearchOption, Spam } from "../types/elements";
import { ADMIN_API_PATH } from "../types/adm_elements";

export const AdminSpamAPI = {
  getSpams: (opt: SpamSearchOption): Promise<APIResponse<Array<Spam>>> => {
    const query = CommonUtil.createQueryString(opt);
    return Axios.get("/admin" + ADMIN_API_PATH.SPAM + query).then((result) => {
      const data: any = result.data;
      if (data.result && data.result instanceof Array) {
        const list: Qna[] = data.result;
        data.result = list.map((o: any) => {
          const ret: Qna = {
            ...o,
            created: new Date(o.created),
          };
          return ret;
        });
      }
      return result;
    });
  },
  putSpam: (spam: Spam): Promise<APIResponse<any>> => {
    return Axios.put("/admin" + ADMIN_API_PATH.SPAM, spam);
  },
  postSpam: (spam: Spam): Promise<APIResponse<any>> => {
    return Axios.post("/admin" + ADMIN_API_PATH.SPAM, spam);
  },
};
