import React from "react";
import { useNavigate } from "react-router-dom";

const PrimaryLink = ({ name, onClick }: { name: string; onClick?: () => any }) => {
  return (
    <div
      onClick={onClick}
      style={{
        color: "#DCDDDD",
        fontWeight: "bold",
        fontSize: "24px",
        marginTop: "20px",
        paddingLeft: "10px",
        cursor: "pointer",
      }}>
      {name}
    </div>
  );
};
const SecondaryLink = ({ name, onClick }: { name: string; onClick?: () => any }) => {
  return (
    <div
      onClick={onClick}
      style={{ color: "#DCDDDD", fontSize: "16px", paddingLeft: "30px", cursor: "pointer" }}>
      {name}
    </div>
  );
};

interface DrawerListProps {
  closeDrawer: () => any;
}
const DrawerList: React.FC<DrawerListProps> = (props) => {
  const navi = useNavigate();
  const handleOpenUrl = (url: string) => {
    navi(url);
    props.closeDrawer();
  };
  return (
    <div style={{ paddingBottom: "3rem" }}>
      <PrimaryLink name="Home" onClick={() => handleOpenUrl("/")} />

      <PrimaryLink name="Reservation" onClick={() => handleOpenUrl("/reservation")} />

      <PrimaryLink name="Customers" onClick={() => handleOpenUrl("/user")} />
      <SecondaryLink name="Alert infos" onClick={() => handleOpenUrl("/useralertinfo")} />
      <SecondaryLink name="Mobile Device(in dev)" onClick={() => handleOpenUrl("/mobiledevice")} />
      <SecondaryLink name="Fcm Push(in dev)" onClick={() => handleOpenUrl("/fcmpush")} />
      <SecondaryLink name="Notifications" onClick={() => handleOpenUrl("/usernotis")} />
      <SecondaryLink name="Fcm Message(in dev)" onClick={() => handleOpenUrl("/fcmmessage")} />
      <SecondaryLink name="Withdraws" onClick={() => handleOpenUrl("/withdrawusers")} />

      <PrimaryLink name="Sales" />
      <SecondaryLink name="Products" onClick={() => handleOpenUrl("/item")} />
      <SecondaryLink name="Orders" onClick={() => handleOpenUrl("/order")} />
      <SecondaryLink name="Order waiting" onClick={() => handleOpenUrl("/orderwaiting")} />
      <SecondaryLink name="Payments" onClick={() => handleOpenUrl("/payments")} />

      <PrimaryLink name="Request" />
      <SecondaryLink name="Consignment" onClick={() => handleOpenUrl("/consignments")} />
      <SecondaryLink name="Buy In" />
      <SecondaryLink name="Styling" onClick={() => handleOpenUrl("/stylings")} />
      <SecondaryLink name="Rental" onClick={() => handleOpenUrl("/rentals")} />

      <PrimaryLink name="Content" />
      <SecondaryLink name="Banner" onClick={() => handleOpenUrl("/baseconfig")} />
      <SecondaryLink name="Public Image" onClick={() => handleOpenUrl("/publicimage")} />
      <SecondaryLink name="Constants" onClick={() => handleOpenUrl("/constant")} />
      <SecondaryLink name="Board" onClick={() => handleOpenUrl("/board")} />

      <PrimaryLink name="CS" />
      <SecondaryLink name="Notice" />
      <SecondaryLink name="Q&A" onClick={() => handleOpenUrl("/qna")} />

      <PrimaryLink name="Setting" />
      <SecondaryLink name="Tags" onClick={() => handleOpenUrl("/tag")} />
      <SecondaryLink name="ItemMeta" onClick={() => handleOpenUrl("/itemmeta")} />
      <SecondaryLink
        name="Notification Template"
        onClick={() => handleOpenUrl("/notification/template")}
      />
      <SecondaryLink name="Migration" onClick={() => handleOpenUrl("/migration")} />
      <SecondaryLink name="Images" onClick={() => handleOpenUrl("/images")} />
      <SecondaryLink name="Spam" onClick={() => handleOpenUrl("/spam")} />
      <SecondaryLink name="Admin Users" onClick={() => handleOpenUrl("/adminuser")} />
      <SecondaryLink name="Warehouse" onClick={() => handleOpenUrl("/warehouse")} />

      <PrimaryLink name="Report" />
      <SecondaryLink name="SMS History" onClick={() => handleOpenUrl("/smshistory")} />
      <SecondaryLink name="Auth History" onClick={() => handleOpenUrl("/authhistory")} />
      <SecondaryLink name="Log" onClick={() => handleOpenUrl("/log")} />
      <SecondaryLink name="Admin History" onClick={() => handleOpenUrl("/adminhistory")} />
    </div>
  );
};

export default DrawerList;
