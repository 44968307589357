import React, { useState, useEffect } from "react";

import { CommonUtil as cu, SearchResult } from "../../../../types/elements";
import { User } from "../../../../types/adm_elements";

import {
  Button,
  TextField,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import SmsDialog from "../../../organisms/SmsDialog";
import { UserSearchOption } from "../../../../types/adm_elements";
import { gqladm } from "../../../../graphql/gqladm";

import Pagination from "@mui/material/Pagination";

import NewUserDialog from "./NewUserDialog";
import ColumnDiv from "../../../molecules/ColumnDiv";

import { useWindowSize } from "../../../../hooks/useWindowSize";
import UserResultList from "./UserResultList";
import UserResultTable from "./UserResultTable";
import { useAppCommon } from "../../../../hooks/useAppCommon";
import { useNavigate } from "react-router-dom";
import useSnack from "../../../../hooks/useSnack";

const LIMIT = 20;
export interface UserResultProps {
  list: User[];
  openUser: (id: number) => any;
}
interface UserListProps {}
const UserListView: React.FC<UserListProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "Customer List" });
  const navi = useNavigate();
  const { isMobile } = useWindowSize();
  const { openSnack } = useSnack();

  const [total, setTotal] = useState<number>(0);
  const [userList, setList] = useState<Array<User>>([]);
  const [uso, setUso] = useState<UserSearchOption>({ limit: LIMIT, orderby: "created_desc" });

  const loadData = (usopt: UserSearchOption) => {
    return gqladm
      .getUsers(usopt)
      .then((result: SearchResult<User>) => {
        if (result.total !== undefined) setTotal(result.total);
        if (result.list !== undefined) setList(result.list);
        setSelected([]);
        setUso({
          ...uso,
          offset: result.offset !== undefined ? result.offset : uso.offset,
          limit: result.limit !== undefined ? result.limit : uso.limit,
        });
      })
      .catch((err: any) => {
        console.log("Error ", err);
        openSnack({ msg: "Error " + err, severity: "error" });
      });
  };
  useEffect(() => {
    loadData(uso);
  }, []);

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (uso.limit ? uso.limit : LIMIT);
    if (offset !== uso.offset) {
      loadData({ ...uso, offset: offset });
    }
  };

  // 선택 액션
  const [selected, setSelected] = useState<number[]>([]);
  const toggleSelectedAll = () => {
    if (userList.length === selected.length && selected.length !== 0) {
      setSelected([]);
    } else {
      setSelected(userList.map((o) => o.id ?? -1));
    }
  };
  const toggleSelected = (id: number) => {
    setSelected((sltc) => {
      if (sltc.indexOf(id) > -1) return sltc.filter((o) => o !== id);
      else return [...sltc, id];
    });
  };

  const [openNewUserDialog, setOpenNewUserDialog] = useState<boolean>(false);
  const handleOpenUserInfoDialog = (user_id?: number) => {
    setOpenNewUserDialog(true);
  };
  const handleCloseNewUserDialog = (created_user_id?: number, user?: User) => {
    setOpenNewUserDialog(false);
    const _uso: UserSearchOption = { phone: user?.phone, offset: 0, limit: LIMIT };
    setUso(_uso);
    loadData(_uso);
  };

  const handleOpenUser = (user_id: number) => {
    navi("/user/" + user_id);
  };

  return (
    <div className={"userListView"}>
      <div>
        <Button onClick={() => handleOpenUserInfoDialog()}>오프라인 유저 추가</Button>
        <NewUserDialog open={openNewUserDialog} onClose={handleCloseNewUserDialog} />
      </div>

      <div className="searchDiv">
        <div className="searchOptionList">
          <ColumnDiv label="ID(email)">
            <TextField
              value={uso.email}
              onChange={(e) => setUso({ ...uso, email: e.target.value })}
              size="small"
            />
          </ColumnDiv>
          <ColumnDiv label="Name">
            <TextField
              value={uso.name}
              onChange={(e) => setUso({ ...uso, name: e.target.value })}
              size="small"
            />
          </ColumnDiv>
          <ColumnDiv label="Phone">
            <TextField
              value={uso.phone}
              onChange={(e) => setUso({ ...uso, phone: e.target.value })}
              size="small"
            />
          </ColumnDiv>
          <ColumnDiv label="Type">
            <ToggleButtonGroup
              size="small"
              exclusive
              value={uso.type === undefined ? "A" : uso.type}
              onChange={(e, v) => {
                v !== null && setUso({ ...uso, type: v === "A" ? undefined : v });
              }}>
              <ToggleButton value="A">전체</ToggleButton>
              <ToggleButton value="Online">온라인</ToggleButton>
              <ToggleButton value="Offline">오프라인</ToggleButton>
            </ToggleButtonGroup>
          </ColumnDiv>
          <ColumnDiv label="SMS 수신">
            <ToggleButtonGroup
              size="small"
              exclusive
              value={uso.agSms === undefined ? "A" : uso.agSms}
              onChange={(e, v) => {
                v !== null && setUso({ ...uso, agSms: v === "A" ? undefined : v });
              }}>
              <ToggleButton value="A">전체</ToggleButton>
              <ToggleButton value="T">수신허용</ToggleButton>
              <ToggleButton value="F">수신거부</ToggleButton>
            </ToggleButtonGroup>
          </ColumnDiv>

          <ColumnDiv label="이메일 수신">
            <ToggleButtonGroup
              size="small"
              exclusive
              value={uso.agEmail === undefined ? "A" : uso.agEmail}
              onChange={(e, v) => {
                v !== null && setUso({ ...uso, agEmail: v === "A" ? undefined : v });
              }}>
              <ToggleButton value="A">전체</ToggleButton>
              <ToggleButton value="T">수신허용</ToggleButton>
              <ToggleButton value="F">수신거부</ToggleButton>
            </ToggleButtonGroup>
          </ColumnDiv>

          <ColumnDiv label="정렬">
            <Select
              value={uso.orderby}
              size="small"
              onChange={(e) => {
                setUso({
                  ...uso,
                  orderby: e.target.value as
                    | "created_desc"
                    | "created_asc"
                    | "expired_desc"
                    | "expired_asc"
                    | "order_done_price_desc"
                    | "order_done_count_desc"
                    | "reservation_noshow_desc",
                });
              }}>
              <MenuItem value="created_desc">최근 가입</MenuItem>
              <MenuItem value="created_asc">오랜 가입</MenuItem>
              <MenuItem value="expired_desc">최근 로그인</MenuItem>
              <MenuItem value="expired_asc">오랜 로그인</MenuItem>
              <MenuItem value="order_done_price_desc">주문 완료 금액 많은 순</MenuItem>
              <MenuItem value="order_done_count_desc">주문 완료 횟수 많은 순</MenuItem>
              <MenuItem value="reservation_noshow_desc">예약 노쇼 많은 순</MenuItem>
            </Select>
          </ColumnDiv>
        </div>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={() => loadData({ ...uso, offset: 0 })}>
          검색
        </Button>
      </div>

      <div>
        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
          <div
            style={{
              minWidth: "200px",
              whiteSpace: "nowrap",
              flex: 1,
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}>
            검색결과 {total}건
          </div>
          <div
            style={{
              minWidth: "200px",
              whiteSpace: "nowrap",
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Pagination
              count={Math.ceil(total / (uso.limit ? uso.limit : LIMIT))}
              page={Math.ceil((uso.offset ? uso.offset : 0) / (uso.limit ? uso.limit : LIMIT)) + 1}
              onChange={handleChangePage}
            />
          </div>
          <div
            style={{
              minWidth: "200px",
              whiteSpace: "nowrap",
              flex: 1,
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}>
            페이지 당 출력 수 :&nbsp;
            <Select
              size="small"
              value={uso.limit}
              onChange={(e) => setUso({ ...uso, limit: Number(e.target.value) })}>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={60}>60</MenuItem>
              <MenuItem value={80}>80</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </div>
        </div>

        {isMobile ? (
          <UserResultList
            list={userList}
            openUser={handleOpenUser}
            selected={selected}
            toggleSelectedAll={toggleSelectedAll}
            toggleSelected={toggleSelected}
          />
        ) : (
          <UserResultTable
            list={userList}
            openUser={handleOpenUser}
            selected={selected}
            toggleSelectedAll={toggleSelectedAll}
            toggleSelected={toggleSelected}
          />
        )}

        <hr />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <Pagination
              count={Math.ceil(total / (uso.limit ? uso.limit : LIMIT))}
              page={Math.ceil((uso.offset ? uso.offset : 0) / (uso.limit ? uso.limit : LIMIT)) + 1}
              onChange={handleChangePage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserListView;
