import { Delete, Circle, Check, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import useConstant from "../../../hooks/useConstant";
import useSnack from "../../../hooks/useSnack";
import { CalendarMeta, ConstantKeys } from "../../../types/elements";

const CONSTANT_KEY: ConstantKeys = "CalendarMeta";
interface ConstantCalendarMetaDialogProps {
  open: boolean;
  onClose: () => any;
}
const ConstantCalendarMetaDialog: React.FC<ConstantCalendarMetaDialogProps> = (props) => {
  const { openSnack } = useSnack();
  const { getConstant } = useConstant();
  const [list, setList] = useState<CalendarMeta[]>([]);
  const [obj, setObj] = useState<CalendarMeta>({ date: "", name: "", color: "" });

  const load = () => {
    gqladm.searchConstants({ key: CONSTANT_KEY, isUniqueKey: true }).then((result) => {
      if (
        result.list &&
        result.list.length > 0 &&
        result.list[0].isJson === true &&
        typeof result.list[0].value === "string"
      ) {
        try {
          const data: CalendarMeta[] = JSON.parse(result.list[0].value);
          setList(data);
        } catch (err) {}
      }
    });
  };
  useEffect(() => {
    load();
  }, []);

  const handleDelete = (date: string) => {
    setList((cms) => cms.filter((o) => o.date !== date));
  };
  const handleAdd = () => {
    if (obj.date && obj.date.length < 10) {
      openSnack({ msg: "date를 확인해주세요" });
      return;
    }
    if (!obj.name) {
      openSnack({ msg: "name을 확인해주세요" });
      return;
    }
    if (list.filter((o) => o.date === obj.date).length > 0) {
      if (window.confirm("동일한 날짜가 있습니다. 수정하시겠습니까?")) {
        setList((l) => l.map((o) => (o.date === obj.date ? { ...obj } : o)));
        setObj({ date: "", name: "", color: "" });
      }
    } else {
      setList((l) => [...l, { ...obj }]);
      setObj({ date: "", name: "", color: "" });
    }
  };

  const handleSave = () => {
    gqladm
      .addConstant({
        isJson: true,
        isPublic: true,
        key: CONSTANT_KEY,
        value: JSON.stringify(list),
      })
      .then((result) => {
        if (result.ok) {
          openSnack({ msg: "캘린더 정보를 저장하였습니다. " });
          props.onClose();
        } else {
          openSnack({ msg: "오류가 발생했습니다. " });
        }
      })
      .catch((err) => {
        openSnack({ msg: "오류가 발생했습니다. " + err });
      });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Calendar Meta</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex" }}>
          <TextField
            size="small"
            style={{ flex: 1 }}
            value={obj.date}
            onChange={(e) => setObj((o) => ({ ...o, date: e.target.value }))}
            placeholder="YYYY-MM-DD"
          />
          <TextField
            size="small"
            style={{ flex: 1 }}
            value={obj.name}
            onChange={(e) => setObj((o) => ({ ...o, name: e.target.value }))}
            placeholder=""
          />
          <TextField
            size="small"
            style={{ flex: 1 }}
            value={obj.color}
            onChange={(e) => setObj((o) => ({ ...o, color: e.target.value }))}
            InputProps={{ endAdornment: <Circle style={{ color: obj.color }} /> }}
          />
          <Button
            size="small"
            style={{ flex: 0.5 }}
            color="primary"
            onClick={() => setObj((o) => ({ ...o, isPublicHoliday: !o.isPublicHoliday }))}>
            {obj.isPublicHoliday ? <Check /> : <CheckBoxOutlineBlank />} 공휴일
          </Button>
          <Button
            size="small"
            style={{ flex: 0.5 }}
            variant="contained"
            color="primary"
            onClick={handleAdd}>
            Add
          </Button>
        </div>
        <hr />
        {list.map((cm: CalendarMeta, idx: number) => {
          return (
            <div key={idx} style={{ display: "flex", alignItems: "center", color: cm.color }}>
              <div style={{ flex: 1 }}>{cm.date}</div>
              <div style={{ flex: 1 }}>{cm.name}</div>
              <div style={{ flex: 1 }}>{cm.color}</div>
              <div style={{ flex: 0.5 }}>{cm.isPublicHoliday ? "공휴일" : ""}</div>
              <div style={{ flex: 0.5 }}>
                <IconButton onClick={(o) => cm.date && handleDelete(cm.date)}>
                  <Delete />
                </IconButton>
              </div>
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConstantCalendarMetaDialog;
