import { AppBar, IconButton, Theme, Toolbar, Typography } from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useAppCommon } from "../../../hooks/useAppCommon";
import TagVersion from "./TagVersion";
import AccountPopoverBtn from "./AccountPopoverBtn";

interface HeaderProps {
  title: string;
}
const Header: React.FC<HeaderProps> = (props) => {
  const { toggleMenuOpen } = useAppCommon();
  return (
    <div className={"header "}>
      <AppBar position="relative" color="transparent">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => toggleMenuOpen()}>
            <MenuIcon />
          </IconButton>
          <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
            {props.title}
          </Typography>
          <AccountPopoverBtn />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
