import React, { useState, useEffect } from "react";
import { LogAPI } from "../../../api";
import { Log, LogTargetArray, LogTargetType } from "../../../types/elements";
import { LogSearchOption } from "../../../types/adm_elements";
import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../../../store/states/SnackState";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from "@mui/material";
import { gqladm } from "../../../graphql/gqladm";
import Pagination from "@mui/material/Pagination";
import { useAppCommon } from "../../../hooks/useAppCommon";

const LIMIT = 30;
interface LogListViewProps {}
const LogListView: React.FC<LogListViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "Log List" });
  const openSnack = useSetRecoilState(SnackStateSelector);

  const [elapsed, setElapsed] = useState<number>(0);
  const [selected, setSelected] = useState<number[]>([]);
  const [logs, setLogs] = useState<Log[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [sopt, setSopt] = useState<LogSearchOption>({
    targets: [],
    limit: LIMIT,
    offset: 0,
    status: "ERROR",
    orderby: "created_desc",
  });

  const loadData = (sopt: LogSearchOption) => {
    const now = Date.now();
    setElapsed(0);
    return gqladm
      .getLogs(sopt)
      .then((result) => {
        setElapsed(Date.now() - now);
        setSelected([]);
        if (result.list) setLogs(result.list);
        if (result.total !== undefined) setTotal(result.total);
        if (sopt.offset !== undefined) setSopt({ ...sopt, offset: sopt.offset });
      })
      .catch((err) => {
        setElapsed(Date.now() - now);
        openSnack({ msg: "Error on load Logs " + sopt, severity: "error" });
      });
  };

  useEffect(() => {
    loadData(sopt);
  }, []);

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      loadData({ ...sopt, offset: offset });
    }
  };

  const handleToggleAll = () => {
    setSelected((slct) => {
      const logsSort = logs.map((o) => (o.id ? o.id : -1)).sort((a, b) => (a > b ? 1 : -1));
      if (slct.length === 0) return logsSort;
      const selectedSort = slct.sort((a, b) => (a > b ? 1 : -1));
      if (
        selectedSort.length === logsSort.length &&
        selectedSort.filter((ss, idx) => ss !== logsSort[idx]).length === 0
      ) {
        return [];
      } else {
        return logsSort;
      }
    });
  };

  const handleDelete = () => {
    if (selected.length > 0) {
      gqladm.deleteLogsByIds(selected).then((result) => {
        if (result.ok) {
          openSnack({ msg: `${result.count}개의 로그가 삭제되었습니다.` });
          loadData(sopt);
        } else if (result.msg) {
          openSnack({ msg: result.msg });
        }
      });
    }
  };

  const handleDeleteBefore = () => {
    const day = window.prompt("몇일 이전의 로그들을 삭제하시겠습니까? ", "30");
    if (Number(day) < 7) {
      openSnack({ msg: "일주일 이내 로그는 선별적으로 삭제 가능합니다." });
    } else {
      gqladm.deleteLogsDayBefore(Number(day)).then((result) => {
        if (result.ok) {
          openSnack({ msg: `${result.count}개의 로그가 삭제되었습니다.` });
          loadData(sopt);
        } else if (result.msg) {
          openSnack({ msg: result.msg });
        }
      });
    }
  };

  return (
    <div className={/* classes.LogListView */ "logListView"}>
      <Paper>
        <div style={{ display: "flex", justifyContent: "space-between", padding: "1rem" }}>
          <div>
            로그상태
            <ButtonGroup size="small">
              <Button
                variant="contained"
                color={sopt.status === undefined ? "primary" : "inherit"}
                onClick={() => setSopt((sopt) => ({ ...sopt, status: undefined }))}>
                전체
              </Button>
              <Button
                variant="contained"
                color={sopt.status === "SUCCESS" ? "primary" : "inherit"}
                onClick={() => setSopt((sopt) => ({ ...sopt, status: "SUCCESS" }))}>
                SUCCESS
              </Button>
              <Button
                variant="contained"
                color={sopt.status === "ERROR" ? "primary" : "inherit"}
                onClick={() => setSopt((sopt) => ({ ...sopt, status: "ERROR" }))}>
                ERROR
              </Button>
            </ButtonGroup>
            정렬:
            <Select
              size="small"
              value={sopt.orderby}
              onChange={(e, v) => setSopt((so) => ({ ...so, orderby: e.target.value as any }))}>
              <MenuItem value="created_desc">최근 생성</MenuItem>
              <MenuItem value="created_asc">예전 생성</MenuItem>
              <MenuItem value="elapsed_desc">긴 수행시간 순</MenuItem>
              <MenuItem value="elapsed_asc">짧은 수행시간순</MenuItem>
            </Select>
            Row:{" "}
            <Select
              size="small"
              value={sopt.limit}
              onChange={(e) => setSopt((so) => ({ ...so, limit: Number(e.target.value) }))}>
              <MenuItem value="30">30</MenuItem>
              <MenuItem value="100">100</MenuItem>
              <MenuItem value="300">300</MenuItem>
              <MenuItem value="500">500</MenuItem>
              <MenuItem value="1000">1000</MenuItem>
            </Select>
            <br />
            타겟:
            {LogTargetArray.filter((o) => o !== "").map((o) => (
              <Chip
                label={o}
                size="small"
                color={sopt.targets?.includes(o) ? "primary" : "default"}
                onClick={() =>
                  setSopt((so) => ({
                    ...so,
                    targets: so.targets?.includes(o)
                      ? so.targets.filter((t) => t !== o)
                      : [...(so.targets ?? []), o],
                  }))
                }
              />
            ))}
            <Button variant="contained" onClick={() => loadData(sopt)} fullWidth>
              Search
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            {`${selected.length}/${logs.length} selected `}&nbsp;
            <Button color="inherit" variant="contained" disabled>
              Treat
            </Button>
            &nbsp;
            <Button
              color={selected.length > 0 ? "secondary" : "inherit"}
              variant="contained"
              onClick={handleDelete}>
              Delete
            </Button>
            |
            <Button color="secondary" variant="contained" onClick={handleDeleteBefore}>
              Delete Before
            </Button>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>total:{total}</span>
          <Pagination
            count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
            page={
              Math.ceil((sopt.offset ? sopt.offset : 0) / (sopt.limit ? sopt.limit : LIMIT)) + 1
            }
            onChange={handleChangePage}
          />
          <span>{elapsed ? elapsed + "ms elapsed." : ""}</span>
        </div>
      </Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                onClick={handleToggleAll}
                checked={selected.length === logs.length && logs.length !== 0}
                size="small"
              />
            </TableCell>
            <TableCell>id</TableCell>
            <TableCell>target</TableCell>
            <TableCell>status</TableCell>
            <TableCell>created</TableCell>
            <TableCell>elapsed</TableCell>
            <TableCell>msg</TableCell>
            <TableCell>data</TableCell>
            <TableCell>url</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((log) => {
            let datastr = "";
            try {
              datastr = log.data ? JSON.stringify(log.data) : "";
            } catch (err) {}
            return (
              <TableRow
                key={log.id}
                className={log.id && selected.indexOf(log.id) > -1 ? "selected" : ""}>
                <TableCell>
                  <Checkbox
                    onClick={() =>
                      setSelected((slcted) =>
                        log.id && selected.indexOf(log.id) > -1
                          ? slcted.filter((o) => o != log.id)
                          : [...slcted, log.id ? log.id : -1]
                      )
                    }
                    checked={log.id && selected.indexOf(log.id) > -1 ? true : false}
                    size="small"
                  />
                </TableCell>
                <TableCell>{log.id}</TableCell>
                <TableCell>{log.target}</TableCell>
                <TableCell>{log.status}</TableCell>
                <TableCell>{log.created ? new Date(log.created).toLocaleString() : ""}</TableCell>
                <TableCell style={{ textAlign: "right" }}>{log.elapsed}</TableCell>
                <TableCell>{log.msg}</TableCell>
                <TableCell>{datastr}</TableCell>
                <TableCell>{log.url}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default LogListView;
