import { WrapperAxios as Axios } from "./base";

import { APIResponse } from "../types/elements";
import { ADMIN_API_PATH, NotificationTemplate, NotificationTest, NotificationSetup } from "../types/adm_elements";
export const AdminNotificationTemplateAPI = {
  getNotificationTemplates(): Promise<APIResponse<any>> {
    return Axios.get("/admin" + ADMIN_API_PATH.NOTIFICATION_TEMPLATE);
  },
  postNotificationTemplate(et: NotificationTemplate): Promise<APIResponse<any>> {
    return Axios.post("/admin" + ADMIN_API_PATH.NOTIFICATION_TEMPLATE, et);
  },
  putNotificationTemplate(id: number, et: NotificationTemplate): Promise<APIResponse<any>> {
    return Axios.put("/admin" + ADMIN_API_PATH.NOTIFICATION_TEMPLATE + "/" + id, et);
  },
  deleteItemOption(id: number): Promise<APIResponse<any>> {
    return Axios.delete("/admin" + ADMIN_API_PATH.NOTIFICATION_TEMPLATE + "/" + id);
  },

  getNotificationMetas(): Promise<APIResponse<any>> {
    return Axios.get("/admin" + ADMIN_API_PATH.NOTIFICATION_META);
  },
  postNotificationGenerate(templateBody: string, data: any): Promise<APIResponse<any>> {
    return Axios.post("/admin" + ADMIN_API_PATH.NOTIFICATION_GENERATE, { templateBody: templateBody, data: data });
  },
  postNotificationTest(testBody: NotificationTest): Promise<APIResponse<any>> {
    return Axios.post("/admin" + ADMIN_API_PATH.NOTIFICATION_TEST, testBody);
  },

  getNotificationSetups(): Promise<APIResponse<any>> {
    return Axios.get("/admin" + ADMIN_API_PATH.NOTIFICATION_SETUP);
  },
  setNotificationSetup(ns: NotificationSetup): Promise<APIResponse<any>> {
    return Axios.put("/admin" + ADMIN_API_PATH.NOTIFICATION_SETUP, ns);
  },
};
