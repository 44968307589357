import { Button, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { User } from "../../types/elements";
interface UserTableBodyRowProps {
  user: User;
  onSelect?: (user: User) => any;
}
const UserTableBodyRow: React.FC<UserTableBodyRowProps> = (props) => {
  const o = props.user;
  return (
    <TableRow>
      <TableCell>
        {props.onSelect && (
          <Button onClick={() => props.onSelect && props.onSelect(o)}>선택</Button>
        )}
      </TableCell>
      <TableCell>{o.email}</TableCell>
      <TableCell>{o.name}</TableCell>
      <TableCell>{o.phone}</TableCell>
    </TableRow>
  );
};

export default UserTableBodyRow;
