import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Board } from "../../../types/elements";
import { gqladm } from "../../../graphql/gqladm";
import useSnack from "../../../hooks/useSnack";

export interface ChangeBoardSortLevelDialogProps {
  board?: Board;
  onClose: () => void;
}

export const ChangeBoardSortLevelDialog: React.FC<ChangeBoardSortLevelDialogProps> = (props) => {
  const [sortLevel, setSortLevel] = useState<number>(0);
  const { openSnack } = useSnack();
  useEffect(() => {
    if (props.board?.sort_level !== undefined) setSortLevel(props.board.sort_level);
  }, [props.board]);

  const handleSaveSortLevel = () => {
    if (props.board?.id)
      gqladm.saveBoardSortLevel(props.board?.id, sortLevel).then((result) => {
        if (result.ok) {
          openSnack({ msg: "저장되었습니다.", severity: "success" });
          props.onClose();
        } else {
          openSnack({ msg: "에러가 발생하였습니다.", severity: "error" });
        }
      });
  };

  return (
    <Dialog open={props.board?.id !== undefined} onClose={props.onClose}>
      <DialogTitle>Change Board Sort_Level</DialogTitle>
      <DialogContent>
        Board ID : {props.board?.id} <br />
        <TextField value={sortLevel} onChange={(e) => setSortLevel(Number(e.target.value))} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSaveSortLevel}>
          Save
        </Button>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
