import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../store/states/SnackState";

const useSnack = () => {
  const openSnack = useSetRecoilState(SnackStateSelector);

  return { openSnack };
};

export default useSnack;
