import {
  Button,
  ButtonGroup,
  Chip,
  MenuItem,
  Pagination,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { gqladm } from "../../../graphql/gqladm";
import { useAppCommon } from "../../../hooks/useAppCommon";
import { Board, BoardKeyList, BoardSearchOption } from "../../../types/elements";
import { SearchConditionBox, SearchFieldBox } from "../../layout/SearchConditionLayout";
import AddBoardButton from "./AddBoardButton";
import { ChangeBoardSortLevelDialog } from "./ChangeBoardSortLevelDialog";

const LIMIT = 20;
interface BoardListViewProps {}
const BoardListView: React.FC<BoardListViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "Board" });
  const navi = useNavigate();

  const [total, setTotal] = useState<number>(0);
  const [list, setList] = useState<Board[]>([]);
  const [sopt, setSopt] = useState<BoardSearchOption>({
    limit: 10,
    offset: 0,
    board_key: "",
    orderBy: "sort_level_desc",
  });

  const load = (opt: BoardSearchOption) => {
    gqladm.getBoards(opt).then((result) => {
      if (result.list) setList(result.list);
      if (result.total) setTotal(result.total);
      setSopt({ ...sopt, offset: result.offset, limit: result.limit });
    });
  };
  useEffect(() => {
    load(sopt);
  }, []);

  const [id, setId] = useState<string>("");
  const handleChangeId = (e: any) => {
    if (e.target.value && !isNaN(Number(e.target.value))) {
      setId(e.target.value);
      setSopt((opt) => ({ ...opt, id: Number(e.target.value) }));
    } else {
      setId("");
      setSopt((opt) => ({ ...opt, id: undefined }));
    }
  };

  const handleChangeTitle = (e: any) => {
    setSopt((opt) => ({ ...opt, title: e.target.value }));
  };

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ?? LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  const handleChangeIsShow = (id?: number) => (e: any, v: boolean) => {
    if (id)
      gqladm.saveBoardIsShow(id, v).then((result) => {
        if (result.ok && result.count === 1) load(sopt);
      });
  };

  const [boardForChangeSortLevel, setBoardForChangeSortLevel] = useState<Board | undefined>(
    undefined
  );
  return (
    <div>
      <AddBoardButton />
      <SearchConditionBox>
        <SearchFieldBox name="ID">
          <TextField size="small" value={id} onChange={handleChangeId} />
        </SearchFieldBox>
        <SearchFieldBox name="Title">
          <TextField size="small" value={sopt.title} onChange={handleChangeTitle} />
        </SearchFieldBox>
        <SearchFieldBox name="List Key">
          <Select
            size="small"
            style={{ minWidth: "200px" }}
            value={sopt.board_key}
            onChange={(e: any) => {
              setSopt((opt) => ({ ...opt, board_key: e.target.value }));
            }}>
            <MenuItem value={""}>미선택</MenuItem>
            {BoardKeyList.map((key) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </SearchFieldBox>
        <SearchFieldBox name="IsShow">
          <ButtonGroup>
            <Button
              variant={sopt.isShow === "all" ? "contained" : "outlined"}
              onClick={() => setSopt((opt) => ({ ...opt, isShow: "all" }))}>
              ALL
            </Button>
            <Button
              variant={sopt.isShow === "show" ? "contained" : "outlined"}
              onClick={() => setSopt((opt) => ({ ...opt, isShow: "show" }))}>
              SHOW
            </Button>
            <Button
              variant={sopt.isShow === "hide" ? "contained" : "outlined"}
              onClick={() => setSopt((opt) => ({ ...opt, isShow: "hide" }))}>
              HIDE
            </Button>
          </ButtonGroup>
        </SearchFieldBox>
        <SearchFieldBox name=""></SearchFieldBox>
      </SearchConditionBox>
      <div className="searchConditionBox"></div>
      <Button variant="contained" color="primary" fullWidth onClick={() => load(sopt)}>
        Search
      </Button>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}>
        <Pagination
          count={Math.ceil(total / (sopt.limit ?? LIMIT))}
          page={Math.ceil((sopt.offset ?? 0) / (sopt.limit ?? LIMIT)) + 1}
          onChange={handleChangePage}
        />
      </div>
      <div>Result - {list.length}</div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Sort Level</TableCell>
            <TableCell>LIST KEY</TableCell>
            <TableCell>TITLE</TableCell>
            <TableCell>IS Show</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((tr) => {
            return (
              <TableRow key={tr.id}>
                <TableCell>
                  <Button onClick={() => navi("/board/" + tr.id)}>{tr.id}</Button>
                </TableCell>
                <TableCell>
                  <Chip label={tr.sort_level} onClick={() => setBoardForChangeSortLevel(tr)} />
                </TableCell>
                <TableCell>{tr.board_key}</TableCell>
                <TableCell>{tr.title}</TableCell>
                <TableCell>
                  <Switch checked={tr.isShow} onChange={handleChangeIsShow(tr.id)} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ChangeBoardSortLevelDialog
        board={boardForChangeSortLevel}
        onClose={() => {
          setBoardForChangeSortLevel(undefined);
          load(sopt);
        }}
      />
    </div>
  );
};

export default BoardListView;
