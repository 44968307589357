import { gql } from "@apollo/react-hooks";
import {
  Item,
  ItemOption,
  ItemStock,
  Order,
  SearchResult,
  User,
  Warehouse,
} from "../types/elements";
import { NotificationTemplate } from "../types/adm_elements";

export interface QueryIF {
  admin?: {
    warehouses?: SearchResult<Warehouse>;
  };
  item?: Item;
  items?: {
    total?: number;
    hasNext?: boolean;
    result?: Item[];
  };
  itemOption?: ItemOption;
  order?: Order;
  orders?: SearchResult<Order>;

  users?: {
    total?: number;
    result?: User[];
  };
}

export const GET_ITEM_OPTION_IN_ITEM_STOCK_TABLE_DIALOG = gql`
  query GetItemOptionInItemStockTableDialog($itemOptionId: Int) {
    itemOption(itemOptionId: $itemOptionId) {
      id
      itemId
      name
      status
      stocks {
        stockId
        stockCount
        warehouseId
        warehouse {
          name
        }
        orderedItems {
          orderedCount
        }
      }
    }
  }
`;

export interface MutationIF {
  saveItemStock?: ItemStock;
  saveItemOption?: ItemOption;
  setDefaultItemOption?: Item;
  removeOrderedItem?: {
    ok: boolean;
  };
  saveNotificationTemplate?: NotificationTemplate;
  removeNotificationTemplate?: {
    ok: boolean;
    count: number;
  };

  createPrivatePayItem?: Item;
  deleteReservationLimit?: {
    ok?: boolean;
  };
  fixDateField?: {
    ok?: boolean;
    count?: boolean;
  };
}

// const SAVE_ITEM = gql`
//   mutation SaveItem($item: ItemInput) {
//     saveItem(item: $item) {
//       id
//     }
//   }
// `;

export const SAVE_ITEM_STOCK = gql`
  mutation SaveItemStock($stock: ItemStockInput) {
    saveItemStock(stock: $stock) {
      stockId
      itemOptionId
      warehouseId
      stockCount
      itemId
    }
  }
`;

// const SET_DEFAULT_ITEM_OPTION = gql`
//   mutation SetDefaultItemOption($itemId: BigInt, $itemOptionId: Int) {
//     setDefaultItemOption(itemId: $itemId, itemOptionId: $itemOptionId) {
//       id
//       defaultItemOptionId
//     }
//   }
// `;

export const SAVE_ORDERED_ITEM = gql`
  mutation SaveOrderedItem($orderedItem: OrderedItemInput) {
    saveOrderedItem(orderedItem: $orderedItem) {
      id
    }
  }
`;

export const REMOVE_ORDERED_ITEM = gql`
  mutation RemoveOrderedItem($id: BigInteger) {
    removeOrderedItem(id: $id) {
      ok
    }
  }
`;

// const REMOVE_ORDERS = gql`
//   mutation RemoveOrders($_ids: [String]) {
//     removeOrders(_ids: $_ids) {
//       ok
//       count
//     }
//   }
// `;

export const SAVE_NOTIFICATION_TEMPLATE = gql`
  mutation SaveNotificationTemplate($obj: NotificationTemplateInput) {
    saveNotificationTemplate(obj: $obj) {
      id
      name
      title
      body
      sms
      datatype
      created
      updated
    }
  }
`;

export const REMOVE_NOTIFICATION_TEMPLATE = gql`
  mutation RemoveNotificationTemplate($id: Int) {
    removeNotificationTemplate(id: $id) {
      ok
      count
    }
  }
`;

export const SAVE_NOTIFICATION_SETUP = gql`
  mutation SaveNotificationSetup($obj: NotificationTemplateInput) {
    saveNotificationSetup(obj: $obj) {
      id
      name
      title
      body
      sms
      datatype
      created
      updated
    }
  }
`;
