import { Button, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { Consignment, ConsignmentSearchOption } from "../../../types/elements";
// import ConsignmentDialog from "../../../pages/service/consignment/ConsignmentDialog";

const LIMIT = 10;
interface UserConsignmentListProps {
  user_id: number;
}
const UserConsignmentList: React.FC<UserConsignmentListProps> = (props) => {
  const [list, setList] = useState<Consignment[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [sopt, setSopt] = useState<ConsignmentSearchOption>({
    limit: LIMIT,
    offset: 0,
    user_id: props.user_id,
  });

  useEffect(() => {
    if (props.user_id) load(sopt);
  }, [props.user_id]);

  const load = (opt: ConsignmentSearchOption) => {
    gqladm
      .consignments({ ...opt, user_id: props.user_id })
      .then((result) => {
        if (result.list) setList(result.list);
        if (result.total) setTotal(result.total);
        setSopt({
          ...sopt,
          limit: result.limit !== undefined ? result.limit : sopt.limit,
          offset: result.offset !== undefined ? result.offset : sopt.offset,
        });
      })
      .catch((err) => {
        console.log("Error on load", err);
      });
  };

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  const handleClickAddNew = () => {
    if (window.confirm("새 위탁상품을 만드시겠습니까? ")) {
      gqladm.addNewConsignment(props.user_id).then((result) => {
        load(sopt);
      });
    }
  };
  const [mcon, setMcon] = useState<Consignment | undefined>(undefined);
  const openMcon = mcon !== undefined;
  const handleClickModify = (consignment: Consignment) => {
    setMcon(consignment);
  };

  return (
    <div>
      {/* <ConsignmentDialog
        open={openMcon}
        onClose={() => setMcon(undefined)}
        consignment_id={mcon?.id}
      /> */}
      <div style={{ margin: "0.5rem 0" }}>
        <Button variant="contained" fullWidth size="small" onClick={handleClickAddNew}>
          Add New Consignment
        </Button>
      </div>

      {/* Counts */}
      <div>
        <div>
          Count:&nbsp;
          {sopt.offset !== undefined ? sopt.offset + 1 : -1}~
          {sopt.offset !== undefined
            ? sopt.offset + (list.length < LIMIT ? list.length : LIMIT)
            : -1}
          &nbsp;/&nbsp;
          {total}
        </div>
      </div>

      {/* List */}
      <div>
        {list.map((o) => {
          return (
            <div>
              Consignment {o.id} - {o.status}
              <Button variant="contained" size="small" onClick={() => handleClickModify(o)}>
                수정
              </Button>
            </div>
          );
        })}
      </div>

      {/* Pagination */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
          page={
            Math.ceil(
              (sopt.offset !== undefined ? sopt.offset : 0) /
                (sopt.limit !== undefined ? sopt.limit : LIMIT)
            ) + 1
          }
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default UserConsignmentList;
