import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import useSnack from "../../../hooks/useSnack";
import { UIRequestStatus } from "../../../store/types";
import {
  Order,
  getKrOrderStatus,
  OrderStatus,
  OrderStatusArray,
  OrderAdminMsg,
  CommonUtil as cu,
  OrderItem,
} from "../../../types/elements";
import {
  Select,
  Button,
  TextField,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  MenuItem,
  Paper,
  Grid,
  Typography,
  Chip,
  Avatar,
  Box,
  TableFooter,
  Switch,
  Stack,
} from "@mui/material";
import { useRecoilValue } from "recoil";
import { AdminAuthState } from "../../../store/states/AdminAuthState";
import { OrderAPI } from "../../../api";
import ItemStockTableDialog from "../../organisms/ItemStockTableDialog";
import OrderedItemDialog from "../../organisms/OrderedItemDialog";
import OrderItemList from "./OrderItemList";
import { useWindowSize } from "../../../hooks/useWindowSize";
import useItemMeta from "../../../hooks/useItemMeta";

import ChangeStatusDialog from "./ChangeStatusDialog";
import ChangeAddressDialog from "./ChangeAddressDialog";
import ChangeReceiptDialog from "./ChangeReceiptDialog";
import ChangeOrderItemAddDialog from "./ChangeOrderItemAddDialog";
import ChangeOrderItemCountDeleteDialog from "./ChangeOrderItemCountDeleteDialog";
import PaymentsList from "../../organisms/PaymentsList";
import ItemDetailDialog from "../itemDetail/ItemDetailDialog";
import ChangeOrderPriceButton from "./btns/ChangeOrderPriceButton";
import UserDetailDialog from "../customers/userDetail/UserDetailDialog";
import DeleteOrderDialog from "./DeleteOrderDialog";
import { useNavigate } from "react-router";

interface OrderDetailProps {
  orderId: number;
}

const OrderDetail: React.FC<OrderDetailProps> = ({ orderId }) => {
  const { isMobile } = useWindowSize();
  const navi = useNavigate();

  const { getItemMeta } = useItemMeta();
  const { openSnack } = useSnack();

  const pUnit = isMobile ? 1000 : 1;

  const [order, setOrder] = useState<Order>({});
  const [orderMsgs, setOrderMsgs] = useState<OrderAdminMsg[]>([]);

  //   for UI
  const [loading, setLoading] = useState<UIRequestStatus>("INITIALIZED");
  const [openItemId, setOpenItemId] = useState<number | undefined>();

  // Mobile - 출고관련 혹은 가격으로 조회조건 변경
  const [showPrice, setShowPrice] = useState<boolean>(false);

  // 상태변경 다이얼로그
  const [openStatusChange, setOpenStatusChange] = useState<boolean>(false);
  const handleCloseStatusChange = (isCompleted?: boolean) => {
    if (isCompleted) loadOrder();
    setOpenStatusChange(false);
  };
  // 배송지정보 수정 다이얼로그
  const [openAddressChange, setOpenAddressChange] = useState<boolean>(false);
  const handleCloseAddressChange = (isCompleted?: boolean) => {
    if (isCompleted) loadOrder();
    setOpenAddressChange(false);
  };
  // 세금계산서/현금영수증 수정 다이얼로그
  const [openReceiptChange, setOpenReceiptChange] = useState<boolean>(false);
  const handleCloseReceiptChange = (isCompleted?: boolean) => {
    if (isCompleted) loadOrder();
    setOpenReceiptChange(false);
  };

  // 주문상품 추가 다이얼로그
  const [openItemAdd, setOpenItemAdd] = useState<boolean>(false);
  const handleCloseItemAdd = (isCompleted?: boolean) => {
    if (isCompleted) loadOrder();
    setOpenItemAdd(false);
  };

  // 주문상품 수정/삭제 다이얼로그
  const [editOrderItem, setEditOrderItem] = useState<OrderItem | undefined>(undefined);
  const openOrderItemCountDelete = editOrderItem !== undefined;
  const handleOpenOrderCountDelete = (oi: OrderItem) => {
    setEditOrderItem(oi);
  };
  const handleCloseOrderCountDelete = (isCompleted?: boolean) => {
    if (isCompleted) loadOrder();
    setEditOrderItem(undefined);
  };

  // @Deprecated
  const [chgStatus, setChgStatus] = useState<OrderStatus>("NONE");
  const handleChangeStatus = (e: any) => {
    setChgStatus(e.target.value);
  };

  const loadOrder = () => {
    setLoading("REQUESTED");
    gqladm
      .getOrder(orderId)
      .then((result) => {
        console.log(result);
        if (result) setOrder(result);
        if (result.adminMsgs) setOrderMsgs([...result.adminMsgs]);
        setLoading("DONE");
        // 완료된 상품의 경우 자동으로 가격보기로 전환
        if (result.status && (["DONE"] as OrderStatus[]).includes(result.status))
          setShowPrice(true);
      })
      .catch((err) => {
        setLoading("ERROR");
        console.log("err ", err);
      });
  };

  useEffect(() => {
    loadOrder();
  }, [orderId]);

  const handleClickChangeStatus = () => {
    console.log("handleClickChangeStatus", chgStatus);
    if (order && order.id)
      gqladm
        .changeOrderStatus(order.id, chgStatus)
        .then((result) => {
          console.log("result", result);
          loadOrder();
          setChgStatus("NONE");
        })
        .catch((err) => {
          console.log("Error", err);
        });
  };

  //   for AdminMsg
  const adminAuthState = useRecoilValue(AdminAuthState);
  const [editAdminMsg, setEditAdminMsg] = useState<{ msg: string }>({ msg: "" });
  const handleChangeAdminMsg = (e: any) => {
    setEditAdminMsg({ ...editAdminMsg, msg: e.target.value });
  };
  const handleInsertAdminMsg = () => {
    OrderAPI.postOrderAdminMsg({ orderId: orderId, msg: editAdminMsg.msg }).then((result) => {
      const data = result.data;
      console.log("postOrderAdminMsg", data);
    });
  };

  // for OrderedItemDialog
  const [orderItem_ItemOptionId, setOrderItem_ItemOptionId] = useState<number>(-1);
  const handleOpenManageOrderedItem = (itemOptionId?: number) => {
    if (itemOptionId) setOrderItem_ItemOptionId(itemOptionId);
    else {
      setOrderItem_ItemOptionId(-1);
      openSnack({ msg: "ItemOptionId가 없습니다 " });
    }
  };
  const handleCloseManageOrderedItem = (completed?: boolean) => {
    completed === true && loadOrder();
    setOrderItem_ItemOptionId(-1);
  };

  // For ItemStockTableDialog
  const [stockManageId, setStockManageId] = useState<number | undefined>();
  const handleOpenStockManage = (optionId?: number) => (e: any) => {
    setStockManageId(optionId);
  };

  const [userDialogId, setUserDialogId] = useState<number | undefined>();

  // Delete stats
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [orderForDelete, setOrderForDelete] = useState<Order | undefined>(undefined);

  return (
    <>
      <div>
        {editOrderItem && (
          <ChangeOrderItemCountDeleteDialog
            // ! 주문 상품 수량 수정 혹은 삭제 다이얼로그
            open={openOrderItemCountDelete}
            onClose={handleCloseOrderCountDelete}
            orderItem={editOrderItem}
          />
        )}
        {loading === "DONE" ? "" : <div>로딩중... </div>}
        {order.id !== undefined && (
          <div>
            <Paper elevation={3} sx={{ padding: isMobile ? "0.1rem" : "1rem" }}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="overline">Order</Typography>
                  <Typography variant="h5">{order.id}</Typography> {order.type}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      display: isMobile ? "flex" : "block",
                      justifyContent: isMobile ? "flex-start" : "flex-end",
                      textAlign: "right",
                    }}>
                    <Chip label={getKrOrderStatus(order.status)} />{" "}
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => setOpenStatusChange(true)}>
                      상태변경
                    </Button>
                    <ChangeStatusDialog
                      open={openStatusChange}
                      onClose={handleCloseStatusChange}
                      order={order}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="overline">User info</Typography>
                  <br />
                  <Button onClick={() => order.user_id && setUserDialogId(order.user_id)}>
                    {order.user?.name} {order.user?.phone}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="overline">Delivery info</Typography>
                  {order.type === "Offline" && (
                    <>
                      <Button size="small" onClick={() => setOpenAddressChange(true)}>
                        배송정보 수정
                      </Button>
                      <ChangeAddressDialog
                        open={openAddressChange}
                        onClose={handleCloseAddressChange}
                        orderAddress={{ ...order }}
                      />
                    </>
                  )}
                  <br />({order.addr_zipcode}) {order.addr_addr1}
                  <br />
                  {order.addr_addr2}
                  <br />
                  {order.addr_username} ({order.addr_phone})
                  <br />
                  배송메모: {order.deliver_msg}
                  <br />
                  관리자메모: {order.admin_msg}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="overline">Date Ordered </Typography>
                  <br />
                  {order.ordered ? cu.getFormattedDate(order.ordered, "yyyy-MM-dd") : ""}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="overline">Pay </Typography>
                  <br />
                  {order.paytype === "credit" ? (
                    <>카드결제</>
                  ) : order.paytype === "direct" ? (
                    <>계좌입금</>
                  ) : (
                    ""
                  )}
                  <br />
                  <br />

                  {order.type === "Offline" && (
                    <>
                      <Button size="small" onClick={() => setOpenReceiptChange(true)}>
                        세금계산서/현금영수증 정보 수정
                      </Button>
                      <ChangeReceiptDialog
                        open={openReceiptChange}
                        onClose={handleCloseReceiptChange}
                        orderReceipt={{ ...order }}
                      />
                    </>
                  )}
                  <br />
                  {order.receipt_target === "personal" ? (
                    <>
                      현금영수증 발행
                      <br />
                      전화번호:{order.receipt_number}
                    </>
                  ) : order.receipt_target === "company" ? (
                    <>
                      세금계산서 발행
                      <br />
                      사업자번호:{order.receipt_c_num}
                      <br />
                      상호명:{order.receipt_c_bn}
                      <br />
                      대표자명:{order.receipt_c_rn}
                      <br />
                      이메일주소:{order.receipt_c_email}
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <span>{showPrice && isMobile && `단위: ${pUnit}원`}</span>
                    {isMobile && (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>출고</Typography>
                        <Switch
                          inputProps={{ "aria-label": "ant design" }}
                          checked={showPrice}
                          onChange={(e, v) => setShowPrice(v)}
                        />
                        <Typography>가격</Typography>
                      </Stack>
                    )}
                  </div>

                  <Table
                    sx={{ width: "100%", tableLayout: "fixed" }}
                    size={isMobile ? "small" : "medium"}>
                    <TableHead>
                      <TableCell>
                        Item
                        {order.type === "Offline" && (
                          <>
                            <Button size="small" onClick={() => setOpenItemAdd(true)}>
                              상품 추가
                            </Button>
                            <ChangeOrderItemAddDialog
                              open={openItemAdd}
                              onClose={handleCloseItemAdd}
                              order_id={order.id}
                              orderItems={order.orderItems ?? []}
                            />
                          </>
                        )}
                      </TableCell>
                      <TableCell width={isMobile ? "40px" : "80px"}>Qty</TableCell>
                      {(showPrice || !isMobile) && (
                        <TableCell width={isMobile ? "50px" : "100px"}>VAT</TableCell>
                      )}
                      {(showPrice || !isMobile) && (
                        <TableCell width={isMobile ? "50px" : "100px"}>Unit price</TableCell>
                      )}
                      {(showPrice || !isMobile) && (
                        <TableCell width={isMobile ? "60px" : "120px"} align="right">
                          Total
                        </TableCell>
                      )}
                      {(!showPrice || !isMobile) && <TableCell align="center">출고</TableCell>}
                    </TableHead>
                    <TableBody>
                      {order.orderItems &&
                        order.orderItems.map((oi) => {
                          const orderedItemList = order.orderedItems ?? [];
                          // cc - 주문 수량, oc - 출고수량
                          const cc = oi.count ? oi.count : 0;
                          const eachOrderedItems = orderedItemList
                            ? orderedItemList.filter(
                                (ooi) => ooi.itemOptionId === oi.item_option_id
                              )
                            : [];
                          const oc = eachOrderedItems.reduce(
                            (p, n) => p + (n.orderedCount ? n.orderedCount : 0),
                            0
                          );

                          return (
                            <TableRow key={oi.id}>
                              <TableCell sx={{ overflow: "hidden" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    // flexWrap: isMobile ? "wrap" : "nowrap",
                                  }}>
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => setOpenItemId(oi.item_id)}>
                                      #{oi.item_id}
                                    </Button>

                                    {oi.item?.topImages &&
                                      oi.item.topImages.map((ti) => (
                                        <Avatar src={"/" + ti.url} sx={{ borderRadius: "8px" }} />
                                      ))}
                                  </div>
                                </Box>
                                <Box sx={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                                  <b>{oi.item?.name}</b>
                                  {oi.item?.designer_id
                                    ? " - " + getItemMeta("Designer", oi.item.designer_id)?.name
                                    : ""}
                                </Box>
                              </TableCell>
                              <TableCell>{oi.count}</TableCell>
                              {(showPrice || !isMobile) && (
                                <TableCell>
                                  {oi.item?.price
                                    ? Math.floor(
                                        oi.item.price * (oi.item?.vatAdded ? 0 : 0.1) * (1 / pUnit)
                                      )
                                    : ""}
                                </TableCell>
                              )}
                              {(showPrice || !isMobile) && (
                                <TableCell>{(oi.item?.price ?? 0) * (1 / pUnit)}</TableCell>
                              )}
                              {(showPrice || !isMobile) && (
                                <TableCell align="right">
                                  {oi.item?.price
                                    ? Math.floor(
                                        oi.item.price * (oi.item?.vatAdded ? 1 : 1.1) * (1 / pUnit)
                                      )
                                    : ""}
                                </TableCell>
                              )}
                              {(!showPrice || !isMobile) && (
                                <TableCell align="center">
                                  {cc > oc
                                    ? "미출고 " + (cc - oc) + "개"
                                    : oc > cc
                                    ? "초과 출고 " + (oc - cc) + "개"
                                    : "출고 완료"}
                                  <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      oi.item_option_id &&
                                      handleOpenManageOrderedItem(oi.item_option_id)
                                    }>
                                    출고 등록
                                  </Button>
                                  {order.type === "Offline" && (
                                    <Button onClick={() => handleOpenOrderCountDelete(oi)}>
                                      수량 수정 or 삭제
                                    </Button>
                                  )}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                    {(showPrice || !isMobile) && (
                      <TableBody>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell colSpan={2}>총 수량:</TableCell>
                          <TableCell colSpan={2} align="right">
                            {order.orderItems &&
                              order.orderItems.map((o) => o.count ?? 0).reduce((p, n) => p + n, 0)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell colSpan={2}>제품가격:</TableCell>
                          <TableCell colSpan={2} align="right">
                            {order.orderItems &&
                              order.orderItems
                                .map((o) =>
                                  o.item?.price && o.count
                                    ? Math.floor(o.item?.price * o.count * (1 / pUnit))
                                    : 0
                                )
                                .reduce((p, n) => p + n, 0)}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell></TableCell>

                          <TableCell colSpan={2}>부가세:</TableCell>
                          <TableCell colSpan={2} align="right">
                            {order.orderItems &&
                              order.orderItems
                                .map((o) =>
                                  o.item?.price && o.count
                                    ? Math.floor(
                                        o.item?.price *
                                          (o.item.vatAdded ? 0 : 0.1) *
                                          o.count *
                                          (1 / pUnit)
                                      )
                                    : 0
                                )
                                .reduce((p, n) => p + n, 0)}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell colSpan={2}>계산금액</TableCell>
                          <TableCell colSpan={2} align="right">
                            {order.orderItems &&
                              order.orderItems
                                .map((o) =>
                                  o.item?.price && o.count
                                    ? Math.floor(
                                        (o.item?.price ?? 0) *
                                          (o.item.vatAdded ? 1 : 1.1) *
                                          o.count *
                                          (1 / pUnit)
                                      )
                                    : 0
                                )
                                .reduce((p, n) => p + n, 0)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell colSpan={2}>주문금액</TableCell>
                          <TableCell colSpan={2} align="right">
                            <ChangeOrderPriceButton
                              order={order}
                              btn_txt="변경"
                              reload={() => loadOrder()}
                            />
                            {order.price}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    <TableFooter></TableFooter>
                  </Table>
                </Grid>
              </Grid>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant={openDelete ? "contained" : "outlined"}
                  color={openDelete ? "error" : "inherit"}
                  onClick={() => {
                    if (openDelete) setOrderForDelete(order);
                    else setOpenDelete(true);
                  }}>
                  {openDelete ? "주문 삭제 (! 이 작업은 되돌릴수 없습니다.)" : "주문 삭제"}
                </Button>
                {orderForDelete && (
                  <DeleteOrderDialog
                    open={orderForDelete !== undefined}
                    onClose={() => {
                      setOpenDelete(false);
                      setOrderForDelete(undefined);
                      navi("/order");
                    }}
                    order={orderForDelete}
                  />
                )}
              </div>
            </Paper>
            <br />
            <details>
              <summary>Payments</summary>
              <div>{order.m_id ? <PaymentsList m_id={order.m_id} /> : "m_id 가 없음"}</div>
            </details>
            <details>
              <summary>Deprecated UI</summary>
              <div>
                <div className="row">
                  <div className="label">ID</div>
                  <div className="content">
                    <TextField fullWidth variant="outlined" disabled value={order.id} />
                  </div>
                  <div className="label">Created</div>
                  <div className="content">
                    <TextField
                      fullWidth
                      variant="outlined"
                      disabled
                      value={order.created && new Date(order.created).toLocaleString()}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="label">주문금액</div>
                  <div className="content">
                    <TextField fullWidth variant="outlined" disabled value={order.price} />
                  </div>
                  <div className="label">결제수단</div>
                  <div className="content">
                    <TextField
                      fullWidth
                      variant="outlined"
                      disabled
                      value={
                        order.paytype === "credit"
                          ? "카드결제"
                          : order.paytype === "direct"
                          ? "무통장입금"
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="label">STATUS</div>
                  <div className="content">
                    {getKrOrderStatus(order.status)}&nbsp;&nbsp; =&gt;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Select value={chgStatus} onChange={handleChangeStatus} variant="filled">
                      {OrderStatusArray.map((os: OrderStatus, idx: number) => {
                        return (
                          <MenuItem key={idx} value={os}>
                            {getKrOrderStatus(os)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    &nbsp;
                    <Button onClick={handleClickChangeStatus} variant="contained" color="primary">
                      변경
                    </Button>
                    {order.isOrderedDone === true && order.status === "ORDERED" && (
                      <div style={{ marginLeft: "1rem" }}>
                        출고정보가 정상입니다. 결제완료에서 상태를 다른 상태로 변경 바랍니다.
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="label">관리자 메세지</div>
                  <div className="content">
                    {adminAuthState.admin.name} :{" "}
                    <TextField value={editAdminMsg.msg} onChange={handleChangeAdminMsg} />{" "}
                    <Button onClick={handleInsertAdminMsg}>메세지입력</Button>
                    <br />
                    {orderMsgs.map((om: OrderAdminMsg, idx: number) => {
                      return (
                        <div key={idx}>
                          - {om.adminMeta?.name} {om.created && new Date(om.created).toTimeString()}{" "}
                          : {om.msg}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="row">
                  <div className="label">배송정보</div>
                  <div className="content">
                    <div style={{ padding: "8px", border: "2px solid gray" }}>
                      이름: {order.addr_username}
                      <br />
                      이메일: {order.useremail}
                      <br />
                      전화번호{order.addr_phone}
                      <br />
                      주소1: {order.addr_addr1} <br />
                      주소2: {order.addr_addr2} <br />
                      우편번호: {order.addr_zipcode}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="label">주문항목</div>
                  <div className="content">
                    {order.orderItems && (
                      <div>
                        <OrderItemList
                          list={order.orderItems}
                          orderedItemList={order.orderedItems ? order.orderedItems : []}
                          onClickOpenManageOrderedItem={handleOpenManageOrderedItem}
                          onClickOpenStockManage={handleOpenStockManage}
                        />
                        <ItemStockTableDialog
                          open={stockManageId !== undefined}
                          onClose={() => setStockManageId(undefined)}
                          itemOptionId={stockManageId}
                        />
                        <OrderedItemDialog
                          open={orderItem_ItemOptionId > -1}
                          onClose={handleCloseManageOrderedItem}
                          itemOptionId={orderItem_ItemOptionId}
                          orderId={orderId}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="label">사업자 및 현금영수증 정보</div>
                  <div className="content">
                    대상 : {order.receipt_target}
                    {order.receipt_target === "personal" && (
                      <div>
                        <br />
                        요청번호 : {order.receipt_number}
                        <br />
                        현금영수증 요청 여부 : {order.cash_receipt}
                      </div>
                    )}
                    {order.receipt_target === "company" && (
                      <div>
                        사업자번호 : {order.receipt_c_num} <br />
                        상호명 : {order.receipt_c_bn} <br />
                        대표자명 : {order.receipt_c_rn} <br />
                        이메일 : {order.receipt_c_email}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </details>
          </div>
        )}
        <ItemDetailDialog itemId={openItemId} onClose={() => setOpenItemId(undefined)} />
      </div>
      <UserDetailDialog user_id={userDialogId} onClose={() => setUserDialogId(undefined)} />
    </>
  );
};

export default OrderDetail;
