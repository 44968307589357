export const MINUTE = 1000 * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;

export type PeriodType = "기간 전체" | "최근 1주일" | "최근 1개월" | "최근 3개월" | "최근 6개월" | "최근 1년";
export const PeriodTypeArray: PeriodType[] = [
  "기간 전체",
  "최근 1주일",
  "최근 1개월",
  "최근 3개월",
  "최근 6개월",
  "최근 1년",
];

export const toNum = (n?: number) => {
  return n !== undefined && n !== null ? Number(n) : undefined;
};

export const pick: <T>(obj: T, keys: Array<keyof T>, addvals?: T) => any = (obj, keys, addvals) => {
  return keys.reduce((p, n) => {
    const ret = { ...p } as any;
    if (obj[n]) ret[n] = obj[n];
    return { ...ret, ...(addvals ?? {}) };
  }, {});
};

export type ErrType =
  | "Unknown"
  | "Unauthorized"
  | "SessionExpired"
  | "InvalidSession"
  | "DataNotFound"
  | "Duplicated"
  | "BadRequest"
  | "NotModified"
  | "NotImplemented"
  | "NotAcceptable"
  | "PasswordNotMatched"
  | "UserNotFound";

export interface Err {
  message: string;
  type: ErrType;
  errCode?: number;
  trace?: any;
}
export const ErrCodeMsg: { [k: string]: string } = {
  // 1: '테스트',
  "400": "요청정보가 올바르지 않습니다.",
  "401": "로그인정보가 올바르지 않습니다.",
  "404": "항목이 존재하지 않습니다.",
  "409": "데이터에 오류가 발생했습니다.",
  "500": "오류가 발생했습니다.",
  // 600 - 예약,
  "600": "이미 예약이 완료되었습니다.",
  "601": "예약이 불가능합니다.",
  "602": "이미 취소된 예약입니다.",
  "603": "당일 중복 예약은 불가 합니다.",
  "604": "이번주 예약건이 존재합니다. 완료된 예약건을 취소 후, 재예약 바랍니다.",
  "605": '"마이페이지 > 예약내역확인" 에서\n 예약수정이 가능합니다 :)',
  // 700 서비스 신청 (위탁, 렌탈, 스타일링)
  // 900 사용자 에러
  "901": "인증코드는 발송후 3분후 재발송 가능합니다.",
  "902": "메세지가 전송되지 않았습니다.",
};

export type UILoad = "loading" | "error" | "success" | "init";

export interface Paging {
  offset?: number;
  limit?: number;
  total?: number;
}

export interface SimpleResult {
  ok?: boolean;
  count?: number;
  target?: string;
  value?: string;
  msg?: string;
  id?: number;
  amount?: number;
  code?: string;
  elapsed?: number;
  expired?: number;
  failed?: number;
  errtype?: ErrType;
  deleted?: number;
  hasNext?: boolean;
  total?: number;
  limit?: number;
}

export interface StatusCount {
  status?: string;
  count?: number;
}
export interface SearchResult<T> extends Paging {
  list?: T[];
}

// ! API_PATH
export enum API_PATH {
  CONSTANTS = "/constants",
  AUTH = "/auth",
  LOGIN = "/login",
  LOGOUT = "/logout",

  AUTH_SEND = "/authcode/send",
  AUTH_CHECK = "/authcode/check",

  USER_CHECK = "/user/check",
  USER_PWD_UPDATE = "/user/pwd/update",
  USER_PROFILE = "/user/profile",

  JOIN = "/join",
  JOIN_EMAIL_CHECK = "/join/emailCheck",
  JOIN_PHONE_CHECK = "/join/phoneCheck",

  ADDR = "/addr",

  ITEM = "/item",

  IMAGE = "/image",
  IMAGE_ID = "/image/:id",

  ITEM_STOCK = "/itemstock",
  ITEM_STOCK_ID = "/itemstock/:id",

  CART = "/cart",
  CART_ID = "/cart/:id",

  TAG = "/tag",
  TAG_ID = "/tag/:id",

  SPAM = "/spam",
}

export interface APIResult<T = any> {
  status: APIResStatus;
  result?: T;

  message?: string;
  insertedId?: any;
  updatedCount?: number;

  condition?: any;

  hasNext?: boolean;
  total?: number;
  limit?: number;
  page?: number;
}

export interface APIResponse<T = any> {
  data: APIResult<T>;
  status: number;
  statusText: string;
  headers: any;
  request?: any;
}

export interface OutAPIResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  request?: any;

  // additional
  originalData?: any;
}

export type APIResStatus = "SUCCESS" | "ERROR";

// ! delete it
// export const categoryIdRegEx = /(?<=,)[^\,]*(?=\,])/;
export const phoneRegEx = /[^0-9]/g;
export const convertNoDashPhoneNumber = (phone: string) => {
  return phone.replace(phoneRegEx, "");
};

export const convertDashedPhoneNumber = (phone: string) => {
  try {
    const noDashed = convertNoDashPhoneNumber(phone);
    if (noDashed.length !== 11) {
      throw new Error("Phone Number is not fit type");
    }
    return noDashed.substr(0, 3) + "-" + noDashed.substr(3, 4) + "-" + noDashed.substr(7, 4);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// ! User
export type UserType = "Online" | "Offline";
export interface User {
  id?: number;
  created?: number;
  type?: UserType;

  email?: string; // id
  name?: string;
  phone?: string;
  password?: string;

  isEmailAuthorized?: number;
  isPhoneAuthorized?: number;
  agreeSMSDate?: number;
  agreeEmailDate?: number;

  sessionKey?: string;
  sessionExpired?: number;

  zipcode?: string;
  addr1?: string;
  addr2?: string;

  // 회원의 숫자통계딛
  num_updated?: number;
  num_order_done_price?: number;
  num_order_done_count?: number;
  num_reservation_noshow?: number;
  num_active_devices?: number;
  num_unread_noti?: number;
  num_reservation_done?: number; // TODO add
  num_service_request_done?: number; // TODO add

  // Related
  favorites?: Array<number>;
  carts?: Cart[];
  notis?: UserNoti[];
  orders?: SearchResult<Order>;
}
export interface WithDrawUser {
  id?: number;
  created?: number;
  admin_id?: number;

  user_id?: number;
  email?: string;
  name?: string;
  phone?: string;
}
export type DeviceOsType = "ios" | "android";
export interface UserDevice {
  id?: number;
  created?: number;
  updated?: number;

  user_id?: number;
  sessionKey?: string;

  fcmToken?: string;
  os?: DeviceOsType;
  version?: string;
  app_version?: string;
  app_buildno?: string;
  // related
  user?: User;
}

// ! UserAlertInfo
export type UserAlertType = "type" | "category" | "designer" | "brand";
export const UserAlertType_TypeNew = 1;
export const UserAlertType_TypeConsignment = 2;
export interface UserAlertInfo {
  id?: number;
  created?: number;
  user_id?: number;
  type?: UserAlertType;
  type_id?: number;
}
export interface UserAlertInfoSearchCond {
  type?: UserAlertType;
  type_id?: number;
}
export interface UserAlertInfoSearchOption extends Paging {
  user_id?: number;
  user_ids?: number[];
  conds?: UserAlertInfoSearchCond[];
  notInFcmPushId?: number;
  orderby?: "created_desc" | "created_asc";
}

// ! UserNoti
export interface UserNoti {
  id?: number;
  user_id?: number;
  created?: number;
  readed?: number;
  fp_id?: number;

  title?: string;
  body?: string;
  imageUrl?: string;
  dataUrl?: string;

  // Related
  user?: User;
}
export interface UserNotiSearchOption extends Paging {
  id?: number;
  user_id?: number;
  orderby?: "created_desc" | "created_asc";
}

export interface RNWebViewPostMessage {
  type?: "login" | "logout" | "log" | "pay" | "imp-uid";
  value?: string;
}
export const IS_RNAPP = "IS_RNAPP";
export const RNOS = "RNOS";
export const RNVer = "RNVer";

export interface AdminMeta {
  id?: number;
  name?: string;
  phone?: string;
  email?: string;
}

// UserFavorite
export interface UserFavorite {
  id?: number;
  created?: number;
  user_id?: number;
  item_id?: number;
}

// * Auth
export interface AuthStatusBody {
  logged: boolean;
  user?: User;
}

// export const SESSION_KEY = 'sessionKey';
export const SESSION_KEY = "x-session-key";
export const SESSION_EXPIRED = "sessionExpired";
export const FCM_TOKEN = "x-fcm-token";

export interface Addr {
  useremail?: string;

  default?: boolean;
  alias?: string;
  username?: string;
  phone?: string;
  addr1?: string;
  addr2?: string;
  zipcode?: string;
}

// Regular Expressions
export const regExpEmailCheck = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
export const regExpPhoneNumberCheck = /^\d{3}-\d{3,4}-\d{4}$/;

// deprecated
// const regExpPwdCheck = /(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{2,50}).{8,50}$/;

export const regExpPwdCheck1 = /[a-zA-Z]/;
export const regExpPwdCheck2 = /[0-9]/;
export const regExpPwdCheck3 = /[~`!@#$%&*()-+=]/;
export const regExpPwdCheck4 = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

// Validation functions
export const checkJoinValidation = (user: User): boolean => {
  // check email
  // check phone validation check
  // check password rules
  return true;
};

export const convertPhoneNumber = (phone: string): string => {
  return "";
};

/**
 * Route Spec naming rule = {Type}{Method}{Name}{etc}
 * type: Req, Res
 * Method: Get, Post, Put, Delete
 * Name: API Name
 * etc: Body? or others
 */

// GetAuth
export interface ResGetAuthBody extends AuthStatusBody {}
// PostLogin
export interface ReqPostLoginBody {
  email: string;
  pwd: string;
}
export interface ResPostLoginBody extends AuthStatusBody {}

// * Phone check Route Interfaces
export type AuthKind = "JoinPhoneCheck" | "UpdatePassword" | "AdminLogin" | "ReservationPhoneCheck";
export const AuthKindArr: AuthKind[] = ["JoinPhoneCheck", "UpdatePassword", "AdminLogin", "ReservationPhoneCheck"];

export type AuthType = "email" | "sms";
export const AuthTypeArr: AuthType[] = ["email", "sms"];

export interface AuthCodeCheckBody {
  authName?: AuthKind;
  authType?: AuthType;
  email?: string;
  phone?: string;
  str_key?: string;
  num_key?: string;

  // optional for UpdatePassword
  password?: string;
}

// ! Cart
export interface Cart {
  id?: number;
  created?: number;
  user_id?: number;
  item_id?: number;
  item_option_id?: number;
  count?: number;
  selected?: boolean;
  // Related
  user?: User;
  item?: Item;
  itemOption?: ItemOption;
}

// ! Contact // todo remove
export type ContactType = "렌탈 문의" | "협찬홍보 문의" | "프로젝트 문의" | "세금계산서 문의";
export const ContactTypeArray: ContactType[] = ["렌탈 문의", "협찬홍보 문의", "프로젝트 문의", "세금계산서 문의"];

export interface Contact {
  id?: number;
  type?: ContactType | "";
  title?: string;
  name?: string;
  phone?: string;
  email?: string;
  msg?: string;
  files?: UploadFile[];
  created?: number;
}

export const INIT_CONTACT: Contact = {
  type: "",
  title: "",
  name: "",
  phone: "",
  email: "",
  msg: "",
  files: [],
};

export interface ContactSearchOptions extends Paging {}

export type BoardKeyType =
  | "앤더슨씨 청담점"
  | "앤더슨씨 나인원"
  | "앤더슨씨 삼성점"
  | "앤더슨씨 도산점"
  | "앤더슨씨 압구정점"
  | "렌탈 포트폴리오"
  | "스타일링 포트폴리오"
  | "위탁 포트폴리오";
export const BoardKeyList: BoardKeyType[] = [
  "앤더슨씨 청담점",
  "앤더슨씨 나인원",
  "앤더슨씨 삼성점",
  "앤더슨씨 도산점",
  "앤더슨씨 압구정점",
  "렌탈 포트폴리오",
  "스타일링 포트폴리오",
  "위탁 포트폴리오",
];
// ! Board
export interface Board {
  id?: number;
  created?: number;
  sort_level?: number;
  board_key?: string;
  title?: string;
  subtitle?: string;
  isShow?: boolean;
  thumb_image_id?: number;

  // info
  period?: string; // Formating to 'yyyyMMdd-yyyyMMdd'

  // related
  thumb_image?: Image;
  blocks?: BoardBlock[];
}
export type BoardBlockType = "image" | "text";
export interface BoardBlock {
  id?: number;
  board_id?: number;
  seq?: number;
  type?: BoardBlockType;
  image_ids?: string; // Formatting to ,id,id,id, ...
  body?: string;

  // related
  binded_images?: Image[];
  uploaded_images?: Image[];
}

export interface BoardSearchOption extends Paging {
  id?: number;
  board_key?: string;
  isShow?: "all" | "show" | "hide";
  title?: string;
  orderBy?: "created_desc" | "created_asc" | "sort_level_desc";
}

// ! Image
export interface Image {
  id?: number;
  uid?: string;
  name?: string;
  url?: string;
  type?: string;
  modified?: number;

  itemId?: number; // -1 : Public Image, -2 : MultiImage

  // related for outside
  file?: any;
  mi_id?: number; // MultiImage Id
  // related with graphql
  mi?: MultiImage;

  // internal use
  seq?: number;
}
export interface ImageSearchOption extends Paging {
  id?: number;
  itemId?: number;
  orderby?: "modified_desc" | "modified_asc";
}

// ! MultiImage - 다양한 용도의 이미지 구성
export type MultiImageTarget = "ConsignmentItem" | "Rental" | "RentalCompanyInfo" | "BoardBlock";
export interface MultiImage {
  id?: number;
  image_id?: number;
  target?: MultiImageTarget;
  targetId?: number;
  seq?: number;
}
export interface MultiImageSearchOption extends Paging {
  id?: number;
  target?: MultiImageTarget;
  targetId?: number;
}
export const convertMultiImage = (o?: MultiImage): MultiImage | undefined => {
  if (o) return { ...o, id: toNum(o.id), image_id: toNum(o.image_id), targetId: toNum(o.targetId), seq: toNum(o.seq) };
};

// ! CustomImage - in constant
export interface CustomImage {
  id: number;
  image_path: string;
  image_link: string;
  title_text: string;
  title_tb: "" | "top" | "bottom";
  title_tbv: string;
  title_lr: "" | "left" | "right";
  title_lrv: string;
  desc_text: string;
  button_text: string;
  button_image_path: string;
  button_link: string;
}
// ! Calendar Meta - in constant
export interface CalendarMeta {
  date?: string;
  name?: string;
  color?: string;
  isPublicHoliday?: boolean;
}
// ! ReservationBanner - in constant
export interface ReservationBanner {
  onoff?: "on" | "off";
  text?: string;
}

//  ! Item
const formatNumMetric = (num: number, logCnt: number, metArr: Array<string>, approxStr: string) => {
  const level = Math.floor(Math.floor(Math.log10(num)) / logCnt);
  const met = level < metArr.length ? metArr[level] : "...";
  const shorten = Math.floor((num / 10 ** (level * logCnt)) * 10) / 10;
  let prefix = "";
  if (shorten * 10 ** (level * logCnt) !== num) {
    prefix = approxStr;
  }
  return prefix + shorten + "" + met;
};

export const getNumMetric = (num: number | undefined, metric: "en" | "kr") => {
  switch (metric) {
    case "en":
      return formatNumMetric(num ? num : 0, 3, ["", "T", "M", "B", "T", "Q"], "approx. ");
    case "kr":
      return formatNumMetric(num ? num : 0, 4, ["", "만", "억", "조"], "약 ");
  }
};

export interface FavoriteItem {
  useremail?: string;
  itemId?: number;
  created?: number;
}

export type ItemType = "NORMAL" | "PRIVATE";
export const ItemTypeArr: Array<ItemType> = ["NORMAL", "PRIVATE"];

export type ItemVisible = "CLOSED" | "OPEN" | "DELETED";
export const ItemVisibleArr: Array<ItemVisible> = ["CLOSED", "OPEN", "DELETED"];

export type ItemStatus = "SALE" | "SOLDOUT";
export const ItemStatusArr: Array<ItemStatus> = ["SALE", "SOLDOUT"];

export type ItemPriceOpen = "PUBLIC" | "MEMBER" | "ASK";
export const ItemPriceOpenArr: Array<ItemPriceOpen> = ["PUBLIC", "MEMBER", "ASK"];

export type ItemShowHide = "SHOW" | "HIDE";
export const ItemShowHideArr: Array<ItemShowHide> = ["SHOW", "HIDE"];

export type ItemLabel = "Consignment" | "LastOne";
export const ItemLabelArr: Array<ItemLabel> = ["Consignment", "LastOne"];

export interface Item extends ItemPrice {
  id?: number;
  created?: number;
  updated?: number;
  type?: ItemType;
  visible?: ItemVisible;
  status?: ItemStatus;

  defaultItemOptionId?: number;

  isLabelLastOne?: boolean;
  isLabelConsignment?: boolean;

  // for search and sort
  sort_level?: number;
  search_text?: string;
  stock_avail?: number;

  // informational
  name?: string;
  description?: string;
  hashtag?: string;

  category_ids?: string; // 데이터 형태 [,아이디,아이디,아이디,]
  category_id?: number;
  brand_id?: number;
  designer_id?: number;

  //showHide columns
  sh_hashtag?: ItemShowHide;
  sh_itemoptions?: ItemShowHide;
  sh_desc?: ItemShowHide;

  // private payments
  parent_id?: number;

  // Counts
  fcm_sent?: number;

  // related with server
  private_users?: string[];
  properties?: Array<ItemProperty>;
  itemOption?: ItemOption;
  itemOptions?: Array<ItemOption>;
  images?: Array<Image>;
  topImages?: Array<Image>;
  discountRate?: number; // 할인금액 (priceBefore가 price보다 클때 적용, 아닐시 0)
}

// 가격정보를 상품정보에서 분리 - 전체 로직상의 문제는 없음
export interface ItemPrice {
  id?: number; // item id
  priceOpen?: ItemPriceOpen;
  price?: number;
  priceBefore?: number; // * 할인전 금액 (price 보다 클때만 적용)
  vatAdded?: boolean;
  discountRate?: number;
}

export interface ItemPrivate {
  id?: number;
  item_id?: number;
  user_id?: number;
}

export interface ItemProperty {
  id?: number;
  item_id?: number;
  seq?: number;
  name?: string;
  value?: string;
}

export interface ItemSearchOption extends Paging {
  ids?: Array<number>;
  type?: ItemType;
  ordering?: "price_asc" | "price_desc" | "recently" | "id_desc";
  visibles?: ItemVisible[];
  statuses?: ItemStatus[];
  priceLow?: number;
  priceHigh?: number;
  text?: string; // to search text in item
  private_user?: string;

  hideSoldOut?: boolean;
  isSoldoutLast?: boolean;

  brandId?: number;
  designerId?: number;
  categoryId?: number;
  categoryLike?: number;

  isLabelConsignment?: boolean;
  isLabelLastOne?: boolean;
}
export const MAX_SEARCH_PRICE = 10000000;
export const MIN_SEARCH_PRICE = 0;
export const COUNT_OF_LIST = 12;

export type ItemImagePlaceType = "top";
export interface ItemImage {
  id?: number;
  item_id?: number;
  image_id?: number;
  place?: ItemImagePlaceType | string;
  seq?: number;
  updated?: number;

  // related
}

// TODO develop
export type ItemHistoryType = "";
export interface ItemHistory {
  id?: number;
  admin_id?: number;
  user_id?: number;
  type?: ItemHistoryType;
  msg?: string;
}

export interface ItemStock {
  stockId?: number;
  created?: number;

  itemId?: number;
  itemOptionId?: number;
  warehouseId?: number;
  stockCount?: number;
  memo?: string;

  // related
  item?: Item;
  itemOption?: ItemOption;
  warehouse?: Warehouse;
  orderedItems?: OrderedItem[];
}

export interface ItemStockSearchOption extends Paging {
  orderby: "created_desc";
  warehouseIds?: number[];
}

// TODO remove
export interface StockInfo {
  // TODO modify
  // orders: Order[];
  stocks: ItemStock[];
  item: Item;
  stockCount: number;
  optionCount: Array<ItemOption>;
}

// item option
export type ItemOptionStatus = "SHOW" | "HIDDEN";
export const ItemOptionStatusArr = ["SHOW", "HIDDEN"];

export interface ItemOption {
  id?: number; // => optionId
  created?: number;
  itemId?: number;
  name?: string;
  status?: ItemOptionStatus;
  stock_avail?: number;

  // extra
  count_total?: number;
  count_ordered?: number;
  count_order?: number;

  // related
  stocks?: ItemStock[];
  item?: Item;
}

// warehouse
export interface Warehouse {
  id?: number;
  name?: string;
  created?: number;
}

export interface PrivatePayReq {
  id?: number;
  useremail?: string;
  itemId?: number;
  created?: number;
}

// ! Order

export type OrderStatus =
  | "NONE"
  | "INITIALIZED"
  | "ORDERED"
  | "CHECKING"
  | "PREPARING"
  | "DELIVERYING"
  | "DONE"
  | "RETURN"
  | "RETURNING"
  | "RETURNED"
  | "CHANGE"
  | "CHANGED"
  | "CANCEL"
  | "CANCELLED";

export const OrderStatusArray: OrderStatus[] = [
  "INITIALIZED",
  "ORDERED",
  "CHECKING",
  "PREPARING",
  "DELIVERYING",
  "DONE",
  "RETURN",
  "RETURNING",
  "RETURNED",
  "CHANGE",
  "CHANGED",
  "CANCEL",
  "CANCELLED",
];

const orkr: any = {
  INITIALIZED: "주문 생성",

  ORDERED: "결제 완료",
  CHECKING: "주문 확인중",
  PREPARING: "상품 준비중",

  DELIVERYING: "배송중",

  DONE: "배송완료",

  RETURN: "반품 요청",
  RETURNING: "반품중",
  RETURNED: "반품 완료",

  CHANGE: "교환요청",
  CHANGED: "교환완료",

  CANCEL: "주문취소요청",
  CANCELLED: "취소완료",
};

export const getKrOrderStatus = (status?: OrderStatus) => {
  return orkr[status ? status : ""];
};

export interface OrderMessage {
  id?: number;
  created?: number;
  order_id?: number;
  status?: OrderStatus;
  useremail?: string; // TODO remove
  user_id?: number;
  message?: string;
}

export interface OrderItem {
  id?: number;
  created?: number;
  order_id?: number;

  item_id?: number;
  item_option_id?: number;
  count?: number;

  consignment_item_id?: number; // 재위탁 신청시 재위탁상품정보

  // related
  item?: Item;
  itemOption?: ItemOption;
  order?: Order;
}

export interface MyOrder {
  beforePay?: number; // 입금전 - ”INITIALIZED": "주문 생성”
  ordered?: number; // 주문완료 - ”ORDERED": "결제 완료",
  preparing?: number; // "배송준비중"  -  ”CHECKING": "주문 확인중", ”PREPARING": "상품 준비중”
  delivering?: number; // "배송중" - ”DELIVERYING": “배송중",
  done?: number; // "배송완료" - ”DONE": “배송완료"
  returning?: number; // "반품" - ”RETURN": "반품 요청",  ”RETURNING": “반품중",  ”RETURNED": "반품 완료”
  changing?: number; // "교환" - 교환요청 / 교환완료
  cancelling?: number; // "취소" - 주문취소요청 / 취소완료(QnA에서 문의 후 어드민 승인 후)
}
export type OrderType = "Online" | "Offline";
export type PayType = "credit" | "direct";
export interface Order extends OrderAddress, OrderReceipt {
  id?: number;
  created?: number;
  type?: OrderType;

  user_id?: number;
  m_id?: string;

  // 주문자 정보
  useremail?: string;
  userphone?: string;
  username?: string;

  // 배송자 정보 - OrderAddress

  // 결제정보
  price?: number;
  paytype?: PayType;
  cash_receipt?: boolean; // TODO deprecated 현금영수증 발행 여부 => 의무화

  // 영수증정보 - OrderReceipt

  direct_name?: string;
  credit_card_number?: string;
  credit_approved_id?: string;

  // 주문상태 정보
  status?: OrderStatus;
  ordered?: number;
  updated?: number;

  // 관리자 메모
  admin_msg?: string;

  // related
  user?: User;
  orderItems?: Array<OrderItem>;
  orderMessages?: Array<OrderMessage>;
  adminMsgs?: OrderAdminMsg[];
  orderedItems?: OrderedItem[];

  // extra function value
  isOrderedDone?: boolean;
}

export interface OrderAddress {
  id?: number;
  addr_zipcode?: string;
  addr_addr1?: string;
  addr_addr2?: string;
  addr_phone?: string;
  addr_username?: string;
  deliver_msg?: string;
}
export interface OrderReceipt {
  id?: number;
  receipt_target?: "company" | "personal";
  receipt_number?: string; // 개인-휴대전화
  receipt_c_num?: string; // 사업자-번호
  receipt_c_bn?: string; // 사업자-상호명
  receipt_c_rn?: string; // 사업자-대표자명
  receipt_c_email?: string; // 사업자-이메일주소
}

export interface OrderAdminMsg {
  id?: number;
  created?: number;
  orderId?: number;
  adminId?: number;
  msg?: string;

  fromStatus?: OrderStatus;
  toStatus?: OrderStatus;

  // Extra
  adminMeta?: AdminMeta;

  // adminname?: string;
  // adminemail?: string;
  // adminphone?: string;
}

export type OrderSearchOptionOrderingTypes = "created_desc" | "created_asc" | "updated_asc" | "updated_desc";
export const OrderSearchOptionOrderingArr: OrderSearchOptionOrderingTypes[] = ["created_desc", "created_asc"];
export interface OrderSearchOption extends Paging {
  orderId?: number;
  orderIds?: number[];
  useremail?: string;
  user_id?: number;
  m_id?: string;
  type?: OrderType;
  ordering?: OrderSearchOptionOrderingTypes;
  statuses?: OrderStatus[];
  hasItemIds?: number[];
}

export interface OrderedItem {
  // 주문처리 완료 된 상품 - 재고수량과 함께 관리됨, 재고를 완료 시켜서 검색목록에서 차후 제외될수 있음
  id?: number;
  orderId?: number;
  stockId?: number;
  orderedCount?: number;
  memo?: string;
  created?: number;

  // optional
  itemId?: number;
  itemOptionId?: number;

  // Related
  itemStock?: ItemStock;
  order?: Order;
}

export interface OrderedItemSearchOption extends Paging {
  orderby: "created_desc";
}

export interface OrderChat {
  id?: number;
  created?: number;
  orderId?: number;
  username?: string;
  useremail?: string;
  msg?: string;
}

export interface OrderWaiting {
  id?: number;
  created?: number;

  // 주문대기 상태 - 생성시 WAITING / 주문시 DONE
  ow_status?: "WAITING" | "DONE" | "FAILED" | "CANCELLED";

  m_id?: string;
  user_id?: number;

  // 주문자정보
  useremail?: string;
  userphone?: string;
  username?: string;

  addr_zipcode?: string;
  addr_addr1?: string;
  addr_addr2?: string;
  addr_phone?: string;
  addr_username?: string;
  deliver_msg?: string;

  // 결제정보
  price?: number;
  paytype?: "credit" | "direct";
  cash_receipt?: boolean; // TODO deprecated
  receipt_target?: "company" | "personal";
  receipt_number?: string;

  receipt_c_num?: string; // 사업자-번호
  receipt_c_bn?: string; // 사업자-상호명
  receipt_c_rn?: string; // 사업자-대표자명
  receipt_c_email?: string; // 사업자-이메일주소

  direct_name?: string;
  credit_card_number?: string;
  credit_approved_id?: string;

  // related
  orderWaitingItems?: Array<OrderWaitingItem>;
  order?: Order;
  user?: User;
}
export interface OrderWaitingItem {
  id?: number;
  created?: number;

  m_id?: string;
  order_waiting_id?: number;

  item_id?: number;
  item_option_id?: number;
  count?: number;
  // related
  item?: Item;
  itemOption?: ItemOption;
}

export interface OrderWaitingSearchOption extends Paging {
  id?: number;
  user_id?: number;
  m_id?: string;
  ow_status?: "WAITING" | "DONE";
}
export const BankList = [
  "국민",
  "기업",
  "농협",
  "신한",
  "신협",
  "새마을금고",
  "씨티",
  "우리",
  "우체국",
  "저축",
  "제일",
  "하나",
  "경남",
  "광주",
  "대구",
  "부산",
  "산업",
  "수협",
  "전북",
  "제주",
  "카카오뱅크",
  "케이뱅크",
  "토스뱅크",
];

// export const BankCode = {
//   "001": "한국은행",
//   "002": "한국산업은행",
//   "003": "중소기업은행",
//   "004": "KB국민은행",
//   "005": "하나(구.외환)",
//   "006": "국민(구.주택)",
//   "007": "수협은행",
//   "008": "수출입",
//   "009": "수협은행<구.한국장기신용은행",
//   "010": "NH농협은행",
//   "011": "NH농협은행",
//   "012": "농.축협",
//   "013": "농.축협",
//   "014": "농.축협",
//   "015": "농.축협",
//   "016": "NH농협은행",
//   "017": "농.축협",
//   "018": "농.축협",
//   "019": "KB국민은행",
//   "020": "우리은행",
//   "021": "신한(구.조흥)",
//   "023": "SC제일은행",
//   "025": "하나(구.서울)",
//   "026": "신한",
//   "027": "씨티은행",
//   "031": "DGB대구은행",
//   "032": "BNK부산은행",
//   "034": "광주은행",
//   "035": "제주은행",
//   "037": "전북은행",
//   "039": "BNK경남은행",
//   "041": "우리카드",
//   "043": "중소기업은행",
//   "044": "하나카드",
//   "045": "새마을금고",
//   "046": "새마을금고",
//   "047": "신협",
//   "048": "신협",
//   "049": "신협",
// };

export interface RequestPay_IMP {
  pg?: "inicis";
  pay_method?: "card"; // 결제수단	card	card(신용카드) trans(실시간계좌이체)  vbank(가상계좌)  phone(휴대폰소액결제)  samsung(삼성페이 / 이니시스, KCP 전용)  kpay(KPay앱 직접호출 / 이니시스 전용)  cultureland(문화상품권 / KG이니시스, KCP, LGU+ 지원)  smartculture(스마트문상 / KG이니시스, KCP, LGU+ 지원)  happymoney(해피머니 / KG이니시스, KCP지원)booknlife(도서문화상품권 / KG이니시스, KCP, LGU + 지원)
  merchant_uid?: string; // 가맹점에서 생성 / 관리하는 고유 주문번호	random(필수항목) 결제가 된 적이 있는 merchant_uid로는 재결제 불가	1.0.0부터
  name?: string; // 주문명	undefined(선택항목) 원활한 결제정보 확인을 위해 입력 권장(PG사마다 차이가 있지만) 16자이내로 작성하시길 권장	1.0.0부터
  amount?: number; // 결제할 금액	undefined(필수항목)	1.0.0부터
  buyer_email?: string;
  buyer_name?: string;
  buyer_tel?: string;
  buyer_addr?: string;
  buyer_postcode?: string;

  escrow?: boolean; // 	에스크로 결제여부	false(선택항목) 에스크로가 적용되는 결제창을 호출	1.0.0부터
  tax_free?: number; // 	amount 중 면세공급가액	undefined(선택항목) 면세공급가액을 지정합니다.amount 중에서 면세공급가액에 해당하는 금액을 지정합니다. (부가세는(amount - tax_free) / 11 로 계산됩니다)	1.0.0부터
  vat?: number; // 	amount 중 부가세 금액	undefined(Deprecated) 복합과세 적용시 더 정확한 계산을 위해 tax_free파라메터 사용을 권장합니다.부가세금액을 지정합니다.vat와 무관하게 amount는 고객으로부터 결제될 금액을 의미합니다.	1.0.0부터
  currency?: string; // 	화폐단위	KRW  (페이팔의 경우에는 USD가 기본값)(선택항목) KRW / USD / EUR / JPY
  language?: string; // 	결제창의 언어 설정	ko(선택항목) 구매자에게 제공되는 결제창 화면의 언어 설정 1. KG이니시스, LGU +, 나이스페이먼츠 : 아래 값으로 적용 가능(KG이니시스, 나이스페이먼츠는 PC결제창만 지원됨)en 또는 ko 2. Paypal의 경우 2자리 코드 적용 Paypal 언어 설정 코드 참조	1.0.0부터
  custom_data?: object; // 	가맹점 임의 지정 데이터	undefined(선택항목)주문건에 대해 부가정보를 저장할 공간이 필요할 때 사용.json notation(string)으로 저장됨	1.0.0부터
  notice_url?: string; // array of string	Notification URL	undefined(선택항목) 아임포트 관리자 페이지에서 설정하는 Notification URL을 overwrite할 수 있음.주문마다 다른 Notification URL이 필요하거나 복수의 Notification URL이 필요한 경우 사용	1.0.0부터
  display?: object; // 	결제화면과 관련한 옵션 설정	undefined(선택항목) 구매자에게 제공되는 결제창 화면에 대한 UI옵션. 2.1.1.a참조	1.0.0부터
  company?: string; // 	가맹점 상호	undefined(선택항목) 일부 PG사(KCP / 다날)에 적용됨.결제창 내에 회사 상호를 출력할 때 사용

  m_redirect_url?: string; //
  app_scheme?: string;
}

export interface ResponsePay_IMP {
  success?: boolean; // 결제처리가 성공적이었는지 여부 실제 결제승인이 이뤄졌거나, 가상계좌 발급이 성공된 경우, true
  error_code?: string; // 결제처리에 실패한 경우 단축메세지 현재 코드체계는 없음
  error_msg?: string; //  결제처리에 실패한 경우 상세메세지
  imp_uid?: string; // 아임포트 거래 고유 번호 아임포트에서 부여하는 거래건 당 고유한 번호(success: false일 때, 사전 validation에 실패한 경우라면 imp_uid는 null일 수 있음)
  merchant_uid?: string; // 가맹점에서 생성 / 관리하는 고유 주문번호
  pay_method?: string; //  결제수단 card(신용카드), trans(실시간계좌이체), vbank(가상계좌), phone(휴대폰소액결제)
  paid_amount?: number; // 결제금액 실제 결제승인된 금액이나 가상계좌 입금예정 금액
  status?: string; //  결제상태 ready(미결제), paid(결제완료), cancelled(결제취소, 부분취소포함), failed(결제실패)
  name?: string; //  주문명
  pg_provider?: string; // 결제승인 / 시도된 PG사 html5_inicis(웹표준방식의 KG이니시스), inicis(일반 KG이니시스), kakaopay(카카오페이), uplus(LGU +), nice(나이스정보통신), jtnet(JTNet), danal(다날)
  pg_tid?: string; // PG사 거래고유번호
  buyer_name?: string; // 주문자 이름
  buyer_email?: string; // 주문자 Email
  buyer_tel?: string; // 주문자 연락처
  buyer_addr?: string; // 주문자 주소
  buyer_postcode?: string; // 주문자 우편번호
  custom_data?: object; // 가맹점 임의 지정 데이터
  paid_at?: number; // 결제승인시각 UNIX timestamp
  receipt_url?: string; //  PG사에서 발행되는 거래 매출전표 URL 전달되는 URL을 그대로 open하면 매출전표 확인가능

  apply_num?: string; // 카드사 승인번호 신용카드결제에 한하여 제공
  vbank_num?: string; // 가상계좌 입금계좌번호 PG사로부터 전달된 정보 그대로 제공하므로 숫자 외 dash(-)또는 기타 기호가 포함되어 있을 수 있음
  vbank_name?: string; // 가상계좌 은행명
  vbank_holder?: string; // 가상계좌 예금주 계약된 사업자명으로 항상 일정함.단, 일부 PG사의 경우 null반환하므로 자체 처리 필요
  vbank_date?: number; // 가상계좌 입금기한 UNIX timestamp
}
// ! QNA

export type QnaType = "배송문의" | "상품문의" | "가격문의" | "기타문의";
export const QnaTypeArray = ["배송문의", "상품문의", "가격문의", "기타문의"];

export interface Qna {
  id?: number;
  type?: QnaType;
  created?: number;
  itemId?: number; // for 상품문의

  useremail?: string;
  username?: string; // optional
  isLogined?: boolean;

  title?: string;
  content?: string;
  link1?: string;
  isSecret?: boolean;
  password?: string;
  isDeleted?: boolean;
  replys?: Array<QnaReply>;

  history?: Array<Qna>;
}

export interface QnaReply {
  created?: number;
  username?: string;
  useremail?: string;
  password?: string;
  isAdmin?: boolean;
  content?: string;
}

export interface QnaSearchOption extends Paging {
  text_target?: "title";
  text?: string;
  type?: string;
  itemId?: number;
  period?: "all" | "week";
}
// ! Spam

export interface Spam {
  id?: number;
  type?: "phone" | "email";
  target?: string;
  created?: number;
  isCancelled?: boolean;
  cancelled?: number;
}

export interface SpamSearchOption extends Paging {}

// ! Voice of Customer

export interface VoiceOfCustomer {
  useremail?: string;
  title?: string;
  desc?: string;
  type?: string;
}

// ! Log
export interface Log {
  id?: number;
  target?: LogTargetType;
  view?: string;
  msg?: string;
  data?: any;
  url?: string;
  status?: LogStatusType;

  started?: number;
  ended?: number;
  elapsed?: number;
  numvalue?: number;

  created?: number;
  useremail?: string;

  treat?: LogTreatType;
  treatmsg?: string;
}
export type LogTargetType =
  | "GRAPHQL_ADMIN_WEBAPP"
  | "GRAPHQL_WEBAPP"
  | "OrderService"
  | "useNewOrder.checkPaid"
  | "useNewOrder.IMP.request_pay.notsuccess"
  | "useNewOrder.IMP.request_pay.success,no_uid"
  | "useNewOrder.IMP.request_pay.success"
  | "ItemAPI.getItem"
  | "AuthService"
  | "App"
  | "AppPay"
  | "GraphqlOperation"
  | "GraphqlOperation_Admin"
  | "RedisCheck"
  | "SystemHeapUsed"
  | "";
export const LogTargetArray: LogTargetType[] = [
  "GRAPHQL_ADMIN_WEBAPP",
  "GRAPHQL_WEBAPP",
  "OrderService",
  "useNewOrder.checkPaid",
  "useNewOrder.IMP.request_pay.notsuccess",
  "useNewOrder.IMP.request_pay.success,no_uid",
  "useNewOrder.IMP.request_pay.success",
  "ItemAPI.getItem",
  "AuthService",
  "App",
  "AppPay",
  "GraphqlOperation",
  "GraphqlOperation_Admin",
  "RedisCheck",
  "SystemHeapUsed",
  "",
];
export type LogStatusType = "SUCCESS" | "ERROR";
export const LogStatusArray = ["SUCCESS", "ERROR"];
export type LogTreatType = "ERROR" | "WATCHING";

// ! Reservation

export type ReservationDay = "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";
export const ReservationDayArray: Array<ReservationDay> = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export type ReservationTime =
  | "10:00"
  | "10:30"
  | "11:00"
  | "11:30"
  | "12:00"
  | "12:30"
  | "1:00"
  | "1:30"
  | "2:00"
  | "2:30"
  | "3:00"
  | "3:30"
  | "4:00"
  | "4:30"
  | "5:00"
  | "5:30"
  | "6:00";

export const ReservationTimeAM: Array<ReservationTime> = ["10:00", "10:30", "11:00", "11:30"];
export const ReservationTimePM: Array<ReservationTime> = [
  "12:00",
  "12:30",
  "1:00",
  "1:30",
  "2:00",
  "2:30",
  "3:00",
  "3:30",
  "4:00",
  "4:30",
  "5:00",
  "5:30",
  "6:00",
];
export const ReservationTimeArray: Array<ReservationTime> = [
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "1:00",
  "1:30",
  "2:00",
  "2:30",
  "3:00",
  "3:30",
  "4:00",
  "4:30",
  "5:00",
  "5:30",
  "6:00",
];

export type ReservationStatus = "Requested" | "Reserved" | "Rejected" | "Cancelled" | "NoShow";
export const ValidReservationStatus: ReservationStatus[] = ["Reserved", "Requested"];
export const ReservationStatusArray: Array<ReservationStatus> = [
  "Requested",
  "Reserved",
  "Rejected",
  "Cancelled",
  "NoShow",
];
export const getReservationStatusKr = (reservationStatus: ReservationStatus) => {
  switch (reservationStatus) {
    case "Requested":
      return "대기";
    case "Reserved":
      return "확정";
    case "Cancelled":
      return "취소";
    case "Rejected":
      return "불가";
    case "NoShow":
      return "노쇼";
    default:
      return "";
  }
};

// ! New DEVELOPMENT
/**
 * FREE: 자동으로 예약 확정
 * ALLOW: 예약시 무조건 대기상태, 관리자 승인으로 확정변경
 * REJECT: 마감표시, 예약불가
 */
export type ReserveSlotStatus = "FREE" | "REJECT" | "ALLOW";
export const ReserveSlotStatusArray: Array<ReserveSlotStatus> = ["FREE", "REJECT", "ALLOW"];
export type ReserveSlotPlace = "gallery";
export interface ReserveSlot {
  date?: string;
  time?: ReservationTime;
  place?: ReserveSlotPlace;

  created?: number;

  show?: boolean;
  status?: ReserveSlotStatus;
  count?: number;

  enableStart?: number;
  enableEnd?: number;

  // external
  reserved?: number;
}
export interface ReserveSlotSearchOption {
  date?: string;
  time?: ReservationTime;
  place?: string;
}

export interface Reservation {
  id?: number;
  created?: number;
  user_id?: number;
  email?: string; // TODO remove
  name?: string;
  phone?: string;
  date?: string; // date format YYYY-MM-DD
  time?: ReservationTime;
  place?: ReserveSlotPlace;
  status?: ReservationStatus;
  memberCount?: number;
  memo?: string;
  // related
  user?: User;
  reservedCount?: number;
  noShowCount?: number;
}

export interface ReservationSearchOption extends Paging {
  id?: number;
  month?: string;
  date?: string;
  time?: string;
  user_id?: number;
  phone?: string;
  name?: string;
  overToday?: boolean;
}

export interface ReservationConfirmed {
  time?: ReservationTime;
  count?: number;
}

export interface ReserveSlotTime {
  show?: boolean;
  time?: ReservationTime;
  // reason?: string;
  status?: ReserveSlotStatus;
  maxCount?: number;
}

export interface ReservationConfirmedSearchOption {
  date?: string;
}

export interface ReservationLimitSearchOption extends Paging {
  month?: string;
  date?: string;
}

export interface ReservationSetting {
  defaultDays: ReservationDay[];
  defaultTimes: ReservationTime[];
  defaultCount: number;
}

// ! ItemMeta
export type ItemMetaType = "Category" | "Brand" | "Designer";
export interface ItemMeta {
  id?: number;
  type?: ItemMetaType;
  key?: string; // optional 검색 및 url 대응용
  name?: string;
  desc?: string;
  parent?: number;
  seq?: number;
  created?: number;
  updated?: number;
}
export interface ItemMetaSearchOption extends Paging {
  id?: number;
  type?: ItemMetaType;
  key?: string;
  name?: string;
  parent?: number;
}

export interface ItemMetaExtUI extends ItemMeta {
  children?: ItemMetaExtUI[];
  treenodes?: ItemMetaExtUI[];
}
export const makeItemMetaTreeNodes = (allnodes: ItemMetaExtUI[]): ItemMetaExtUI[] => {
  const toplist = allnodes.filter(o => !o.parent);
  const addTreeNodes = (cur: ItemMetaExtUI, upper?: ItemMetaExtUI) => {
    cur.treenodes = upper?.treenodes && upper.treenodes.length > 0 ? [...upper.treenodes, cur] : [cur];
    cur.children = allnodes.filter(o => o.parent === cur.id).sort((a, b) => (a.seq && b.seq && a.seq > b.seq ? 1 : -1));
    if (cur.children.length > 0) cur.children.forEach(ch => addTreeNodes(ch, cur));
  };
  toplist.forEach(o => addTreeNodes(o));
  return toplist.sort((a, b) => (a.seq && b.seq && a.seq > b.seq ? 1 : -1));
};

// ! ServiceRequest - including Consignment, Styling, Rental
export type ServiceRequestType = "Consignment" | "Styling" | "Rental";
export const getServiceRequestTypeKr = (type?: ServiceRequestType) => {
  return type === "Consignment" ? "위탁" : type === "Styling" ? "스타일링" : type === "Rental" ? "렌탈" : "";
};
export interface ServiceRequest {
  id?: number;
  user_id?: number;
  created?: number;
  updated?: number;

  type?: ServiceRequestType;

  // related
  consignment?: Consignment;
  rental?: Rental;
  styling?: Styling;
}
export interface ServiceRequestSearchOption extends Paging {
  id?: number;
  ids?: number[];
  user_id?: number;
  user_ids?: number[];
  type?: ServiceRequestType;
  types?: ServiceRequestType[];
  beforeCreated?: number;
  beforeUpdated?: number;
  ordering?: "newerfirst" | "olderfirst";
  // not query
  status?: string;
  statuses?: string[];
}

export type SQCommonStatus = ConsignmentStatus & RentalStatus & StylingStatus;
export const SQCommonStatusArray: Array<SQCommonStatus> = ["검토 중", "신청 완료", "작성 중", "취소"];
export const SQConsignmentFilterStatusArray: Array<ConsignmentStatus> = ["신청 완료", "판매 중", "정산 완료", "취소"];

// ! SQ - Consignment
export type ConsignmentStatus =
  | "작성 중"
  | "신청 완료"
  | "검토 중"
  | "승인 완료"
  | "픽업 대기"
  | "픽업 완료"
  | "검수 완료"
  | "판매 중"
  | "판매 완료"
  | "정산 완료"
  | "취소";
export const ConsignmentStatusArray: Array<ConsignmentStatus> = [
  "작성 중",
  "신청 완료",
  "검토 중",
  "승인 완료",
  "픽업 대기",
  "픽업 완료",
  "검수 완료",
  "판매 중",
  "판매 완료",
  "정산 완료",
  "취소",
];

// export const ConsignmentCategorys = ["체어", "소파 / 벤치", "수납장 / 월유닛", "테이블", "조명", "소품"];
export interface Consignment {
  id?: number;
  user_id?: number;
  created?: number;
  updated?: number;
  status?: ConsignmentStatus;

  // info
  request_detail?: string;
  delivery_zipcode?: string;
  delivery_addr1?: string;
  delivery_addr2?: string;
  account_bank?: string;
  account_number?: string;
  account_name?: string;

  // etc - w/o db
  items?: ConsignmentItem[];
  user?: User;

  // 상품 검수 위치 주소
  // 계좌정보
  // 요청사항
  // 위탁항목은 아래 ConsignmentItem 으로 설정됨
}
export interface ConsignmentSearchOption extends Paging {
  id?: number;
  user_id?: number;
  status?: ConsignmentStatus;
  statuses?: Array<ConsignmentStatus>;
  beforeCreated?: number;
  beforeUpdated?: number;
  ordering?: "newerfirst" | "olderfirst";
}
export type ConsignmentItemStatus = "작성 중" | "검수 대기" | "위탁 픽업 완료" | "위탁 중" | "정산 완료" | "위탁 취소";
export const ConsignmentItemStatuses: ConsignmentItemStatus[] = [
  "작성 중",
  "검수 대기",
  "위탁 픽업 완료",
  "위탁 중",
  "정산 완료",
  "위탁 취소",
];
export type ConsignmentItemWarranty = "있음" | "없음" | "정보없음";
export interface ConsignmentItem {
  id?: number;
  consignment_id?: number;
  status?: ConsignmentItemStatus;
  count?: number;
  origin_price?: number;
  expect_price?: number;
  category?: string;
  designer?: string;
  brand?: string;
  yom?: string; // Year of Manufacture
  pop?: string; // place of purchase
  warranty?: ConsignmentItemWarranty;
  desc?: string;

  order_item_id?: number; // 주문으로 부터 위탁 된 경우 설정 - 이값이 있을 경우 위탁상품정보를 수정하지 못함

  // related
  images?: Image[];
}

// ! SQ - Rental
export type RentalStatus =
  | "작성 중"
  | "신청 완료"
  | "검토 중"
  | "인보이스 발송"
  | "결제 완료"
  | "픽업 대기"
  | "렌탈 중"
  | "반납 완료"
  | "검수 완료"
  | "취소";
export const RentalStatusArray: RentalStatus[] = [
  "작성 중",
  "신청 완료",
  "검토 중",
  "인보이스 발송",
  "결제 완료",
  "픽업 대기",
  "렌탈 중",
  "반납 완료",
  "검수 완료",
  "취소",
];
export interface Rental {
  id?: number;
  user_id?: number;
  created?: number;
  updated?: number;
  status?: RentalStatus;

  rental_start?: string;
  rental_end?: string;
  days?: number;
  request_detail?: string;

  receipt_target?: "company" | "personal";
  receipt_number?: string; // 개인-휴대전화

  // related
  user?: User;
  images?: Image[];
  companyImages?: Image[];
}

export interface RentalSearchOption extends Paging {
  id?: number;
  ids?: number[];
  user_id?: number;
  status?: RentalStatus;
  statuses?: Array<RentalStatus>;
  beforeCreated?: number;
  beforeUpdated?: number;
  ordering?: "newerfirst" | "olderfirst";
}
// ! SQ - Styling
export type StylingStatus =
  | "작성 중"
  | "신청 완료"
  | "검토 중"
  | "상담 완료"
  | "계약 완료"
  | "스타일링 진행 중"
  | "스타일링 완료"
  | "취소";
export const StylingStatusArray: StylingStatus[] = [
  "작성 중",
  "신청 완료",
  "검토 중",
  "상담 완료",
  "계약 완료",
  "스타일링 진행 중",
  "스타일링 완료",
  "취소",
];

export type AreaType = "주거 공간" | "상업 공간";
export const AreaTypeArray: AreaType[] = ["주거 공간", "상업 공간"];
export type AreaSizeType = "10평 - 19평" | "20평 - 39평" | "40평 - 59평" | "60평 - 79평" | "80평 - 99평" | "100평 이상";
export const AreaSizeTypeArray: AreaSizeType[] = [
  "10평 - 19평",
  "20평 - 39평",
  "40평 - 59평",
  "60평 - 79평",
  "80평 - 99평",
  "100평 이상",
];
export interface Styling {
  id?: number;
  user_id?: number;
  created?: number;
  updated?: number;
  status?: StylingStatus;

  zipcode?: string;
  addr1?: string;
  addr2?: string;
  areatype?: AreaType;
  areasize?: AreaSizeType;
  expect_month?: string;
  budget?: number;
  request_detail?: string;

  // related
  user?: User;
}
export interface StylingSearchOption extends Paging {
  id?: number;
  ids?: number[];
  user_id?: number;
  status?: StylingStatus;
  statuses?: StylingStatus[];
  beforeCreated?: number;
  beforeUpdated?: number;
  ordering?: "newerfirst" | "olderfirst";
}

// ! UploadFile
export type UploadFileTarget = "Contact";
export interface UploadFile {
  created?: number;

  id?: string;
  type?: string;
  filename?: string;
  target?: UploadFileTarget;
  targetId?: number;

  file?: any;
}

export interface UploadFileSearchOptions extends Paging {
  target?: string;
}

// ! Constant
export interface Constant {
  id?: number;
  created?: number;
  key?: string | ConstantKeys;
  value?: string | object;
  isPublic?: boolean;
  isJson?: boolean;
}
export interface ConstantSearchOption extends Paging {
  id?: number;
  orderby?: "created_desc";
  key?: string | ConstantKeys;
  isPublic?: boolean;
  isJson?: boolean;
  isUniqueKey?: boolean;
}

export interface ConstantsAPIResult {
  constants?: Constant[];
  brand?: ItemMeta[];
  designer?: ItemMeta[];
  category?: ItemMeta[];

  banners?: Banner[];
}

export type ConstantKeys =
  | "TEST"
  | "STATIC_IMAGE_MAIN"
  | "NOTIFICATION_EMAIL_JOIN"
  | "NOTIFICATION_SMS_JOIN"
  | "NOTIFICATION_EMAIL_JOIN"
  | "NOTIFICATION_SMS_JOIN"
  | "RESERVATION_SETTING"
  | "STATIC_CUSTOM_SLIDE_1"
  | "STATIC_CUSTOM_SLIDE_2"
  | "STATIC_CUSTOM_SLIDE_3"
  | "CalendarMeta"
  | "TAGS"
  | "RESERVATION_BANNER";

export const textConstant = {
  term: `
전자상거래(인터넷사이버몰) 표준약관

        이용약관 동의 (필수)                           


        제1조(목적)
        이 약관은 AndersonC(앤더슨씨)회사 (전자상거래 사업자)가 운영하는 AndersonC(앤더슨씨) 사이버 몰(이하 “몰”이라 한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.

        ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」

        제2조(정의)
        ① “몰”이란 OO 회사가 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.
        ② “이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
        ③ ‘회원’이라 함은 “몰”에 회원등록을 한 자로서, 계속적으로 “몰”이 제공하는 서비스를 이용할 수 있는 자를 말합니다.
        ④ ‘비회원’이라 함은 회원에 가입하지 않고 “몰”이 제공하는 서비스를 이용하는 자를 말합니다.

        제3조 (약관 등의 명시와 설명 및 개정)
        ① “몰”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호․모사전송번호․전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 00 사이버몰의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
        ② “몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회․배송책임․환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
        ③ “몰”은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
        ④ “몰”이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.  이 경우 "몰“은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
        ⑤ “몰”이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 “몰”에 송신하여 “몰”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
        ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.

        제4조(서비스의 제공 및 변경)

        ① “몰”은 다음과 같은 업무를 수행합니다.
        1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
        2. 구매계약이 체결된 재화 또는 용역의 배송
        3. 기타 “몰”이 정하는 업무
        ② “몰”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
        ③ “몰”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
        ④ 전항의 경우 “몰”은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.

        제5조(서비스의 중단)
        ① “몰”은 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
        ② “몰”은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
        ③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “몰”은 제8조에 정한 방법으로 이용자에게 통지하고 당초 “몰”에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, “몰”이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 “몰”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.

        제6조(회원가입)
        ① 이용자는 “몰”이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
        ② “몰”은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
        1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 “몰”의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
        2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
        3. 기타 회원으로 등록하는 것이 “몰”의 기술상 현저히 지장이 있다고 판단되는 경우
        ③ 회원가입계약의 성립 시기는 “몰”의 승낙이 회원에게 도달한 시점으로 합니다.
        ④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 “몰”에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.

        제7조(회원 탈퇴 및 자격 상실 등)
        ① 회원은 “몰”에 언제든지 탈퇴를 요청할 수 있으며 “몰”은 즉시 회원탈퇴를 처리합니다.
        ② 회원이 다음 각 호의 사유에 해당하는 경우, “몰”은 회원자격을 제한 및 정지시킬 수 있습니다.
        1. 가입 신청 시에 허위 내용을 등록한 경우
        2. “몰”을 이용하여 구입한 재화 등의 대금, 기타 “몰”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
        3. 다른 사람의 “몰” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
        4. “몰”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
        ③ “몰”이 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “몰”은 회원자격을 상실시킬 수 있습니다.
        ④ “몰”이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.

        제8조(회원에 대한 통지)
        ① “몰”이 회원에 대한 통지를 하는 경우, 회원이 “몰”과 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.
        ② “몰”은 불특정다수 회원에 대한 통지의 경우 1주일이상 “몰” 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.

        제9조(구매신청 및 개인정보 제공 동의 등)
        ① “몰”이용자는 “몰”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “몰”은 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
        1. 재화 등의 검색 및 선택
        2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력
        3. 약관내용, 청약철회권이 제한되는 서비스, 배송료․설치비 등의 비용부담과 관련한 내용에 대한 확인
        4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시
        (예, 마우스 클릭)
        5. 재화등의 구매신청 및 이에 관한 확인 또는 “몰”의 확인에 대한 동의
        6. 결제방법의 선택
        ② “몰”이 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)
        ③ “몰”이 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.


        제10조 (계약의 성립)
        ①  “몰”은 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
        1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
        2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우
        3. 기타 구매신청에 승낙하는 것이 “몰” 기술상 현저히 지장이 있다고 판단하는 경우
        ② “몰”의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
        ③ “몰”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.

        제11조(지급방법)
        “몰”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수 있습니다. 단, “몰”은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도  추가하여 징수할 수 없습니다.
        1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
        2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
        3. 온라인무통장입금
        4. 전자화폐에 의한 결제
        5. 수령 시 대금지급
        6. 마일리지 등 “몰”이 지급한 포인트에 의한 결제
        7. “몰”과 계약을 맺었거나 “몰”이 인정한 상품권에 의한 결제
        8. 기타 전자적 지급 방법에 의한 대금 지급 등

        제12조(수신확인통지․구매신청 변경 및 취소)
        ① “몰”은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.
        ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 “몰”은 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.

        제13조(재화 등의 공급)
        ① “몰”은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, “몰”이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다.  이때 “몰”은 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
        ② “몰”은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 “몰”이 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 “몰”이 고의․과실이 없음을 입증한 경우에는 그러하지 아니합니다.

        제14조(환급)
        “몰”은 이용자가 구매신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.

        제15조(청약철회 등)
        ① “몰”과 재화등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
        ② 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.
        1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)
        2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우
        3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가 현저히 감소한 경우
        4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
        ③ 제2항제2호 내지 제4호의 경우에 “몰”이 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.
        ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.

        제16조(청약철회 등의 효과)
        ① “몰”은 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 “몰”이 이용자에게 재화등의 환급을 지연한때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
        ② “몰”은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
        ③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. “몰”은 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 “몰”이 부담합니다.
        ④ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 “몰”은 청약철회 시 그 비용을  누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.

        제17조(개인정보보호)
        ① “몰”은 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
        ② “몰”은 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
        ③ “몰”은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.
        ④ “몰”은 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
        ⑤ “몰”이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
        ⑥ 이용자는 언제든지 “몰”이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “몰”은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “몰”은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
        ⑦ “몰”은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를  최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
        ⑧ “몰” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
        ⑨ “몰”은 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.

        제18조(“몰“의 의무)
        ① “몰”은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.
        ② “몰”은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
        ③ “몰”이 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
        ④ “몰”은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.

        제19조(회원의 ID 및 비밀번호에 대한 의무)
        ① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
        ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
        ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “몰”에 통보하고 “몰”의 안내가 있는 경우에는 그에 따라야 합니다.

        제20조(이용자의 의무) 이용자는 다음 행위를 하여서는 안 됩니다.
        1. 신청 또는 변경시 허위 내용의 등록
        2. 타인의 정보 도용
        3. “몰”에 게시된 정보의 변경
        4. “몰”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
        5. “몰” 기타 제3자의 저작권 등 지적재산권에 대한 침해
        6. “몰” 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
        7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위

        제21조(연결“몰”과 피연결“몰” 간의 관계)
        ① 상위 “몰”과 하위 “몰”이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 “몰”(웹 사이트)이라고 하고 후자를 피연결 “몰”(웹사이트)이라고 합니다.
        ② 연결“몰”은 피연결“몰”이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서 보증 책임을 지지 않는다는 뜻을 연결“몰”의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증 책임을 지지 않습니다.

        제22조(저작권의 귀속 및 이용제한)
        ① “몰“이 작성한 저작물에 대한 저작권 기타 지적재산권은 ”몰“에 귀속합니다.
        ② 이용자는 “몰”을 이용함으로써 얻은 정보 중 “몰”에게 지적재산권이 귀속된 정보를 “몰”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
        ③ “몰”은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.

        제23조(분쟁해결)
        ① “몰”은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.
        ② “몰”은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
        ③ “몰”과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.

        제24조(재판권 및 준거법)
        ① “몰”과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
        ② “몰”과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.


        부 칙(시행일)
        이 약관은 2020년 00월 00일부터 시행합니다.

`,
  privacy: `
개인정보 수집 및 이용 동의(필수)


수집하는 개인정보 항목
‘AndersonC(앤더슨씨)’는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
- 수집항목 : 이름 , 생년월일 , 성별 , 로그인ID , 비밀번호 , 비밀번호 질문과 답변 , 자택 전화번호 , 자택 주소 , 휴대전화번호 , 이메일 , 직업 , 회사명 , 부서 , 직책 , 회사전화번호 , 취미 , 결혼여부 , 기념일 , 법정대리인정보 , 서비스 이용기록 , 접속 로그 , 접속 IP 정보 , 결제기록
- 개인정보 수집방법 : 홈페이지(회원가입) , 서면양식
 
* 개인정보의 수집 및 이용목적
- 회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
- 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 콘텐츠 제공 , 구매 및 요금 결제 , 물품배송 또는 청구지 등 발송
- 회원 관리
    - 회원제 서비스 이용에 따른 본인확인 , 개인 식별 , 연령확인 , 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인 , 고지사항 전달
- 마케팅 및 광고에 활용
    - 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계
 
개인정보의 보유 및 이용기간
회사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니다.


`,
};

// ! CommonUtil
export const reduceSum = () => {
  return (p?: number, n?: number) => (p ? p : 0) + (n ? n : 0);
};

export const makePhoneString = (phone?: string): string => {
  let ret = "";
  (phone ? phone : "").split("").forEach((c, i) => {
    if (!isNaN(Number(c))) {
      if ([3, 8].indexOf(ret.length) > -1) {
        ret += "-";
      }
      ret += c;
    }
  });
  return ret;
};

export const findOne = <T>(key: string, array: Array<T>, keyCol?: string): T | undefined => {
  try {
    const _keycol = keyCol ? keyCol : "id";
    const filtered = array.filter((o: T) => {
      const oo: any = o;
      return oo[_keycol] === key;
    });
    if (filtered.length === 1) {
      return filtered[0];
    }
  } catch (err) {
    console.log("Error on find");
    throw err;
  }
};

export const lpad = (original: number, length: number, fillchar?: string): string => {
  const _fillchar = fillchar ? fillchar : "0";
  let ret = "" + original;
  while (ret.length < length) {
    ret = _fillchar + ret;
  }
  return ret;
};

export const makeIntervalString = (num: number) => {
  const min = Math.floor(num / 60);
  const sec = num - 60 * min;
  return min + ":" + lpad(sec, 2);
};

export const createQueryString = (obj: any) => {
  return (
    "?" +
    Object.keys(obj)
      .map(key => {
        const v = obj[key];
        if (v && typeof v === "object" && v instanceof Array && v.length > 0) {
          return "&" + key + "=" + v.join(",");
        } else if (v && (typeof v === "string" || typeof v === "number" || typeof v === "boolean")) {
          return "&" + key + "=" + v;
        } else {
          return "";
        }
      })
      .filter(o => o)
      .join("&")
  );
};

export const getURLQuery = (search: string) => {
  const ret: any = {};
  if (search) {
    const list = search
      .split("?")
      .filter(o => o)
      .join("")
      .split("&")
      .filter(o => o);
    list.forEach((item: string) => {
      const kv = item.split("=");
      if (kv.length === 2) {
        ret[kv[0]] = kv[1];
      } else if (kv.length === 1) {
        ret[kv[0]] = true;
      }
    });
  }
  return ret;
};

export const getDiffTime = (from: number, to: number) => {
  const diff = (from - to) / 1000;

  const formatter = new Intl.RelativeTimeFormat("ko", {
    numeric: "auto",
  });

  const times = [
    { name: "year", milliSeconds: 60 * 60 * 24 * 365 },
    { name: "month", milliSeconds: 60 * 60 * 24 * 30 },
    { name: "day", milliSeconds: 60 * 60 * 24 },
    { name: "hour", milliSeconds: 60 * 60 },
    { name: "minute", milliSeconds: 60 },
  ] as { name: Intl.RelativeTimeFormatUnit; milliSeconds: number }[];

  for (const value of times) {
    const betweenTime = Math.floor(Math.abs(diff / value.milliSeconds));

    if (betweenTime > 0) {
      return formatter.format(betweenTime * (diff < 0 ? -1 : 1), value.name);
    }
  }
  return "방금 전";
};

export function numberWithCommas(x?: number) {
  const _x = x ? x : 0;
  return _x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const str = (c: string, len: number) => {
  var s = "",
    i = 0;
  while (i++ < len) {
    s += c;
  }
  return s;
};
const szf = (n: string, len: number) => {
  return str("0", len - n.length) + n;
};
const nzf = (n: number, len: number) => {
  return szf(n.toString(), len);
};

export const getFormattedDate = (date: Date | number, format: string) => {
  let d: Date;
  if (typeof date === "number") {
    d = new Date(date);
  } else if (typeof date === "object" && date instanceof Date) {
    d = date;
  } else {
    d = new Date(date);
  }

  const weekKorName = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
  const weekKorShortName = ["일", "월", "화", "수", "목", "금", "토"];
  const weekEngName = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const weekEngShortName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let h;
  return format.replace(/(yyyy|yy|MM|dd|KS|KL|ES|EL|HH|hh|mm|ss|a\/p)/gi, function ($1): string {
    switch ($1) {
      case "yyyy":
        return "" + d.getFullYear(); // 년 (4자리)
      case "yy":
        return nzf(d.getFullYear() % 1000, 2); // 년 (2자리)
      case "MM":
        return nzf(d.getMonth() + 1, 2); // 월 (2자리)
      case "dd":
        return nzf(d.getDate(), 2); // 일 (2자리)
      case "KS":
        return weekKorShortName[d.getDay()]; // 요일 (짧은 한글)
      case "KL":
        return weekKorName[d.getDay()]; // 요일 (긴 한글)
      case "ES":
        return weekEngShortName[d.getDay()]; // 요일 (짧은 영어)
      case "EL":
        return weekEngName[d.getDay()]; // 요일 (긴 영어)
      case "HH":
        return nzf(d.getHours(), 2); // 시간 (24시간 기준, 2자리)
      case "hh":
        h = d.getHours() % 12;
        return nzf(h ? h : 12, 2); // 시간 (12시간 기준, 2자리)
      case "mm":
        return nzf(d.getMinutes(), 2); // 분 (2자리)
      case "ss":
        return nzf(d.getSeconds(), 2); // 초 (2자리)
      case "a/p":
        return d.getHours() < 12 ? "오전" : "오후"; // 오전/오후 구분
      default:
        return $1;
    }
  });
};

const getByteLength = (s: string) => {
  let b, i, c;
  for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 11 ? 3 : c >> 7 ? 2 : 1);
  return b;
};

const getSMSByteLength = (s: string) => {
  let b, i, c;
  for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 7 ? 2 : 1);
  return b;
};

const getCardNumberMasking = (s: string) => {
  if (s.length > 4) {
    return s.substr(0, 4) + "-XXXX-XXXX-XXXX";
  } else {
    return s;
  }
};

export const getFirstDay = (d: Date): number => {
  return (1 - (d.getDate() % 7) + d.getDay() + 70) % 7;
};
export const getLastDate = (d: Date): number => {
  return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
};

const getDayClass = (day: number, lang?: "en" | "ko"): string => {
  const dayClass = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  const weekKorShortName = ["일", "월", "화", "수", "목", "금", "토"];
  return lang === "ko" ? weekKorShortName[day % 7] : dayClass[day % 7];
};

export const filterObject = <T>(input: T, filterColums: Array<string>): T => {
  const o: any = { ...input };
  for (const i in filterColums) {
    const key = filterColums[i];
    o[key] = undefined;
    delete o[key];
  }
  return o;
};

export const cint = (n: number | undefined, d?: number) => (n !== undefined ? n : d ? d : 0);

export const genRandKey = (n: number) => {
  return Math.floor(Math.random() * n * 10 + n) % (n * 10);
};

export const CommonUtil = {
  reduceSum,
  makePhoneString,
  lpad,
  createQueryString,
  findOne,
  makeIntervalString,
  getURLQuery,
  numberWithCommas,
  getFormattedDate,
  getByteLength,
  getSMSByteLength,
  getCardNumberMasking,
  getFirstDay,
  getLastDate,
  getDayClass,
  filterObject,
  cint,
  genRandKey,
};

// ! Banner

export type BannerType = "mobile";
export interface Banner {
  id?: number;
  created?: number;

  show?: boolean;
  type?: BannerType;
  msg?: string;
  url?: string;
  onlyShowNonLogin?: boolean;
}
// TODO Refactoring-2 배너엔티티는 삭제하고, 배너서치옵션도 삭제, Constant에 배너 추가 로직 설정하기
export interface BannerSearchOption extends Paging {}

export const convertSimpleResult = (o?: SimpleResult): SimpleResult | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      elapsed: toNum(o.elapsed),
      count: toNum(o.count),
      amount: toNum(o.amount),
      failed: toNum(o.failed),
    };
};
export const convertStatusCount = (o?: StatusCount): StatusCount | undefined => {
  if (o) return { ...o, count: toNum(o.count) };
};
export const convertBanner = (o?: Banner): Banner | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      created: toNum(o.created),
    };
};
export const convertBoard = (o?: Board): Board | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      created: toNum(o.created),
      thumb_image_id: toNum(o.thumb_image_id),
    };
};
export const convertBoardBlock = (o?: BoardBlock): BoardBlock | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      board_id: toNum(o.board_id),
      seq: toNum(o.seq),
    };
};

export const convertUser = (o?: User): User | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      created: toNum(o.created),
      password: undefined,
      isEmailAuthorized: toNum(o.isEmailAuthorized),
      isPhoneAuthorized: toNum(o.isPhoneAuthorized),
      agreeEmailDate: toNum(o.agreeEmailDate),
      agreeSMSDate: toNum(o.agreeSMSDate),
      sessionExpired: toNum(o.sessionExpired),

      num_updated: toNum(o.num_updated),
      num_order_done_price: toNum(o.num_order_done_price),
      num_order_done_count: toNum(o.num_order_done_count),
      num_reservation_noshow: toNum(o.num_reservation_noshow),
      num_active_devices: toNum(o.num_active_devices),
      num_unread_noti: toNum(o.num_unread_noti),
      num_reservation_done: toNum(o.num_reservation_done),
      num_service_request_done: toNum(o.num_service_request_done),
    };
};
export const convertUserDevice = (o?: UserDevice): UserDevice | undefined => {
  if (o)
    return { ...o, id: toNum(o.id), user_id: toNum(o.user_id), created: toNum(o.created), updated: toNum(o.updated) };
};

export const convertServiceRequest = (o?: ServiceRequest): ServiceRequest | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      user_id: toNum(o.user_id),
      created: toNum(o.created),
      updated: toNum(o.updated),
      consignment: convertConsignment(o.consignment),
      rental: convertRental(o.rental),
      styling: convertStyling(o.styling),
    };
};
export const convertConsignment = (o?: Consignment): Consignment | undefined => {
  if (o)
    return { ...o, id: toNum(o.id), user_id: toNum(o.user_id), created: toNum(o.created), updated: toNum(o.updated) };
};
export const convertConsignmentItem = (o?: ConsignmentItem): ConsignmentItem | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      consignment_id: toNum(o.consignment_id),
      count: toNum(o.count),
      origin_price: toNum(o.origin_price),
      expect_price: toNum(o.expect_price),
    };
};

export const convertRental = (o?: Rental): Rental | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      user_id: toNum(o.user_id),
      created: toNum(o.created),
      updated: toNum(o.updated),
      days: toNum(o.days),
    };
};
// export const convertRentalItem = (o?: RentalItem): RentalItem | undefined => {
//   if (o) return { ...o, rental_id: toNum(o.rental_id), item_id: toNum(o.item_id), count: toNum(o.count) };
// };
export const convertStyling = (o?: Styling): Styling | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      user_id: toNum(o.user_id),
      created: toNum(o.created),
      updated: toNum(o.updated),
      budget: toNum(o.budget),
    };
};

export const convertConstant = (o?: Constant): Constant | undefined => {
  if (o) return { ...o, id: toNum(o.id), created: toNum(o.created) };
};

export const convertItemOption = (o?: ItemOption): ItemOption | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      created: toNum(o.created),
      itemId: toNum(o.itemId),
      stock_avail: toNum(o.stock_avail),
      count_total: toNum(o.count_total),
      count_ordered: toNum(o.count_ordered),
      count_order: toNum(o.count_order),
    };
};

export const convertItemStock = (o?: ItemStock): ItemStock | undefined => {
  if (o)
    return {
      ...o,
      stockId: toNum(o.stockId),
      itemId: toNum(o.itemId),
      itemOptionId: toNum(o.itemOptionId),
      stockCount: toNum(o.stockCount),
      created: toNum(o.created),
      warehouseId: toNum(o.warehouseId),
    };
};
export const convertWarehouse = (o?: Warehouse): Warehouse | undefined => {
  if (o)
    return {
      ...o,
      id: o.id ? Number(o.id) : undefined,
      created: o.created ? Number(o.created) : undefined,
    };
};
export const convertItemProperty = (o?: ItemProperty): ItemProperty | undefined => {
  if (o) return { ...o, id: o.id ? Number(o.id) : undefined, seq: o.seq ? Number(o.seq) : undefined };
};
export const convertItem = (o?: Item): Item | undefined => {
  if (o) {
    const price = toNum(o.price);
    const priceBefore = toNum(o.priceBefore);
    const discountRate =
      price && priceBefore
        ? price < priceBefore
          ? Math.floor(((priceBefore - price) / priceBefore) * 100)
          : 0
        : undefined;

    return {
      ...o,
      id: toNum(o.id),
      created: toNum(o.created),
      updated: toNum(o.updated),
      parent_id: toNum(o.parent_id),
      defaultItemOptionId: toNum(o.defaultItemOptionId),
      price: price,
      priceBefore: priceBefore,
      sort_level: toNum(o.sort_level),
      stock_avail: toNum(o.stock_avail),
      discountRate: discountRate,
      category_id: toNum(o.category_id),
      brand_id: toNum(o.brand_id),
      designer_id: toNum(o.designer_id),
    };
  }
};
export const convertItemPrice = (o?: ItemPrice): ItemPrice | undefined => {
  const price = toNum(o?.price);
  const priceBefore = toNum(o?.priceBefore);
  const discountRate =
    price && priceBefore
      ? price < priceBefore
        ? Math.floor(((priceBefore - price) / priceBefore) * 100)
        : 0
      : undefined;
  if (o)
    return {
      id: toNum(o.id),
      price: toNum(o.price),
      priceBefore: toNum(o.priceBefore),
      discountRate,
      priceOpen: o.priceOpen,
      vatAdded: o.vatAdded,
    };
};
export const convertItemPrivate = (o?: ItemPrivate): ItemPrivate | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      user_id: toNum(o.user_id),
      item_id: toNum(o.item_id),
    };
};
export const convertItemImage = (o?: ItemImage): ItemImage | undefined => {
  if (o)
    return {
      ...o,
      id: o.id !== null ? Number(o.id) : undefined,
      image_id: o.image_id !== null ? Number(o.image_id) : undefined,
      item_id: o.item_id !== null ? Number(o.item_id) : undefined,
      seq: o.seq !== null ? Number(o.seq) : undefined,
      updated: o.updated !== null ? Number(o.updated) : undefined,
    };
};
export const convertImage = (o?: Image): Image | undefined => {
  if (o)
    return {
      ...o,
      id: o.id ? Number(o.id) : undefined,
      itemId: o.itemId ? Number(o.itemId) : undefined,
      modified: o.modified ? Number(o.modified) : undefined,
    };
};
export const convertOrderItem = (o?: OrderItem): OrderItem | undefined => {
  if (o)
    return {
      ...o,
      id: o.id ? Number(o.id) : undefined,
      created: o.created ? Number(o.created) : undefined,
      item_id: o.item_id ? Number(o.item_id) : undefined,
      item_option_id: o.item_option_id ? Number(o.item_option_id) : undefined,
      order_id: o.order_id ? Number(o.order_id) : undefined,
      count: o.count ? Number(o.count) : undefined,
    };
};
export const convertMyOrder = (o?: MyOrder): MyOrder | undefined => {
  if (o)
    return {
      ...o,
      beforePay: toNum(o.beforePay),
      preparing: toNum(o.preparing),
      delivering: toNum(o.delivering),
      done: toNum(o.done),
      returning: toNum(o.returning),
      changing: toNum(o.changing),
      cancelling: toNum(o.cancelling),
    };
};
export const convertOrder = (o?: Order): Order | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      user_id: toNum(o.user_id),
      price: toNum(o.price),
      cash_receipt: Boolean(o.cash_receipt),
      created: toNum(o.created),
      ordered: toNum(o.ordered),
      updated: toNum(o.updated),
    };
};
export const convertOrderWaiting = (o?: OrderWaiting): OrderWaiting | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      created: toNum(o.created),
      user_id: toNum(o.user_id),
      price: toNum(o.price),
    };
};
export const convertOrderWaitingItem = (o?: OrderWaitingItem): OrderWaitingItem | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      order_waiting_id: toNum(o.order_waiting_id),
      created: toNum(o.created),
      item_id: toNum(o.item_id),
      item_option_id: toNum(o.item_option_id),
      count: toNum(o.count),
    };
};
export const convertOrderedItem = (o?: OrderedItem): OrderedItem | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      itemOptionId: toNum(o.itemOptionId),
      itemId: toNum(o.itemId),
      created: toNum(o.created),
      orderId: toNum(o.orderId),
      orderedCount: toNum(o.orderedCount),
      stockId: toNum(o.stockId),
    };
};
export const convertReservation = (o?: Reservation): Reservation | undefined => {
  if (o) return { ...o, id: toNum(o.id), created: toNum(o.created), user_id: toNum(o.user_id) };
};
export const convertReserveSlot = (o?: ReserveSlot): ReserveSlot | undefined => {
  if (o)
    return {
      ...o,
      enableStart: toNum(o.enableStart),
      enableEnd: toNum(o.enableEnd),
      count: toNum(o.count),
      created: toNum(o.created),
      show: Boolean(o.show),
    };
};
export const convertCart = (o?: Cart): Cart | undefined => {
  if (o) {
    return {
      ...o,
      id: o.id ? Number(o.id) : undefined,
      created: o.created !== undefined ? Number(o.created) : undefined,
      user_id: o.user_id !== undefined ? Number(o.user_id) : undefined,
      item_id: o.item_id !== undefined ? Number(o.item_id) : undefined,
      item_option_id: o.item_option_id !== undefined ? Number(o.item_option_id) : undefined,
      count: o.count !== undefined ? Number(o.count) : undefined,
    };
  }
};
export const convertItemMeta = (o?: ItemMeta): ItemMeta | undefined => {
  if (o) {
    return {
      ...o,
      id: toNum(o.id),
      seq: toNum(o.seq),
      parent: toNum(o.parent),
      created: toNum(o.created),
      updated: toNum(o.updated),
    };
  }
};

export const convertUserAlertInfo = (o?: UserAlertInfo): UserAlertInfo | undefined => {
  if (o)
    return { ...o, id: toNum(o.id), created: toNum(o.created), user_id: toNum(o.user_id), type_id: toNum(o.type_id) };
};

export const convertUserNoti = (o?: UserNoti): UserNoti | undefined => {
  if (o)
    return { ...o, id: toNum(o.id), created: toNum(o.created), user_id: toNum(o.user_id), readed: toNum(o.readed) };
};

export interface whIF {
  width: number;
  height: number;
}

export const calcMobilePx = (px: number, width?: number) => {
  return (px * (width !== undefined ? width : document.documentElement.clientWidth)) / 1128;
};

// caching for calcMobilePx
let lastWidth: number = 0;
let tempCalcPx: any = {};
export const calcPx = (px: number, w?: number): string => {
  if (w) {
    if (w !== lastWidth) tempCalcPx = {}; // W가 달라졌을때 캐시를 삭제
    lastWidth = w;
  }
  if (tempCalcPx["px" + px] === undefined) tempCalcPx["px" + px] = calcMobilePx(px, w);
  return tempCalcPx["px" + px] + "px";
};

export const getThumbUrl = (url: string) => {
  if (url.startsWith("image/")) {
    const splited = url.split("/");
    if (splited.length === 2) return [splited[0], "thumb_" + splited[1]].join("/");
  }
  return url;
};

export const getThumbUrlWithSlash = (url: string) => {
  if (url.startsWith("/image/")) {
    const splited = url.split("/").filter(o => o);
    if (splited.length === 2) return "/" + [splited[0], "thumb_" + splited[1]].join("/");
  }
  return url;
};

export const isSameNumberArray = (a: number[], b: number[]) => {
  if (a.length !== b.length) return false;
  const aar = [...a].sort((a, b) => (a > b ? 1 : -1));
  const bar = [...b].sort((a, b) => (a > b ? 1 : -1));
  for (const i in aar) {
    const av = aar[i];
    const bv = bar[i];
    if (av !== bv) return false;
  }
  return true;
};
