// src/apollo/client.ts
import {
  ApolloClient,
  ApolloQueryResult,
  createHttpLink,
  InMemoryCache,
  MutationOptions,
  OperationVariables,
  QueryOptions,
  DefaultContext,
  // ApolloCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { FetchResult } from "apollo-boost";
import { LogAPI } from "../api";
import { getSessionId, getSessionKey } from "../api/Ls";
import { Log } from "../types/elements";
import { ADMIN_SESSION_ID, ADMIN_SESSION_KEY } from "../types/adm_elements";

const httpLink = createHttpLink({
  uri: "/api/gql",
});

const authLink = setContext((_: any, { headers }) => {
  const _headers = { ...headers };
  _headers[ADMIN_SESSION_ID] = getSessionId();
  _headers[ADMIN_SESSION_KEY] = getSessionKey();
  _headers["x-target"] = "andersonc-admin";

  return {
    headers: _headers,
    // headers: {
    //     ...headers,
    //     authorization: token ? `Bearer ${token}` : "",
    // }
  };
});
const createApolloClient = () => {
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: { query: { fetchPolicy: "network-only" }, mutate: { fetchPolicy: "no-cache" } },
  });

  const _query = client.query;
  // wrapping Error Catching
  client.query = <T = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables, T>
  ): Promise<ApolloQueryResult<T>> => {
    const log: Log = {
      started: Date.now(),
      url: window.location.href,
      target: "GRAPHQL_ADMIN_WEBAPP",
      msg: "Query",
      data: {
        query: options.query.loc?.source?.body,
        variables: options.variables,
      },
    };
    return _query(options)
      .then((result) => {
        log.ended = Date.now();
        log.elapsed = log.ended - (log.started ? log.started : 0);
        log.status = "SUCCESS";
        LogAPI.post(log).catch((err) => {});
        return result;
      })
      .catch((err) => {
        console.log("GraphQL Error Catching", err);
        log.ended = Date.now();
        log.elapsed = log.ended - (log.started ? log.started : 0);
        log.status = "ERROR";
        log.data = { ...log.data, error: err };
        LogAPI.post(log).catch((err) => {});
        throw err;
      });
  };

  const _mutate = client.mutate;
  client.mutate = <
    TData = any,
    TVariables = OperationVariables,
    TContext = DefaultContext
    // TCache extends ApolloCache<any> = ApolloCache<any>
  >(
    options: MutationOptions<TData, TVariables, TContext>
  ): Promise<FetchResult<TData>> => {
    const log: Log = {
      started: Date.now(),
      url: window.location.href,
      target: "GRAPHQL_ADMIN_WEBAPP",
      msg: "Mutation",
      data: {
        query: options.mutation.loc?.source?.body,
        variables: options.variables,
      },
    };
    return _mutate(options)
      .then((result) => {
        log.ended = Date.now();
        log.elapsed = log.ended - (log.started ? log.started : 0);
        log.status = "SUCCESS";
        LogAPI.post(log).catch((err) => {});
        return result;
      })
      .catch((err) => {
        console.log("GraphQL Error Catching", err);
        log.ended = Date.now();
        log.elapsed = log.ended - (log.started ? log.started : 0);
        log.status = "ERROR";
        log.data = { ...log.data, error: err };
        LogAPI.post(log).catch((err) => {});
        throw err;
      });
  };
  return client;
};

export const client: ApolloClient<any> = createApolloClient();
