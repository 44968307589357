import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { Item, UserDevice } from "../../../types/elements";
import { useSearchUserDevice } from "../../pages/customers/mobiledevice/list/useSearchUserDevice";
import SelectItemDialog from "../SelectItemDialog";
import AreaPush from "./AreaPush";
import MiniItemInfo from "./MiniItemInfo";

interface SendAllMessageDialogProps {
  open: boolean;
  onClose: () => void;
}
const SendAllMessageDialog: React.FC<SendAllMessageDialogProps> = (props) => {
  const [devices, setDevices] = useState<UserDevice[]>([]);
  const [dataUrl, setDataUrl] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");

  const { total, list, sopt, load } = useSearchUserDevice({ limit: 9999999 });

  const applyToSend = () => {
    setDevices(list);
  };

  //   상품추가
  const [selectedItem, setSelectedItem] = useState<Item | undefined>(undefined);
  const [openItemDialog, setOpenItemDialog] = useState<boolean>(false);
  const handleOpenItemDialog = () => {
    setOpenItemDialog(true);
  };

  return (
    <>
      <Dialog open={props.open} onClose={() => props.onClose()}>
        <DialogTitle>전체 메세지 보내기</DialogTitle>
        <DialogContent>
          <div style={{ padding: "0.5rem" }}>
            <div>검색조건</div>
            <div>
              <Button onClick={() => load(sopt)}>검색</Button>
            </div>
            <div>Total: {total} devices.</div>
            <Button fullWidth variant="contained" onClick={applyToSend}>
              메세지 전송 대상 적용
            </Button>
          </div>
          <div style={{ padding: "0.5rem" }}>
            <Button onClick={handleOpenItemDialog} fullWidth variant="contained">
              상품 정보 추가
            </Button>
            {selectedItem && (
              <div style={{ padding: "1rem 0 " }}>
                선택된 상품
                <MiniItemInfo
                  item={selectedItem}
                  onClose={() => setSelectedItem(undefined)}
                  onSelectItemUrl={setDataUrl}
                  onSelectImageUrl={setImageUrl}
                />
              </div>
            )}
          </div>

          <AreaPush devices={devices} dataUrl={dataUrl} imageUrl={imageUrl} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onClose()}>닫기</Button>
        </DialogActions>
      </Dialog>
      <SelectItemDialog
        open={openItemDialog}
        onClose={() => setOpenItemDialog(false)}
        onSelect={(item) => {
          setSelectedItem(item);
          setOpenItemDialog(false);
        }}
      />
    </>
  );
};

export default SendAllMessageDialog;
