import Add from "@mui/icons-material/Add";
import { Button, FormControlLabel, MenuItem, Radio, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ColumnDiv from "../../../molecules/ColumnDiv";
import DatePicker from "../../../molecules/DatePicker";
import MultiImageUploadButton from "../../../organisms/MultiImageUploadButton";
import { gqladm } from "../../../../graphql/gqladm";
import useSnack from "../../../../hooks/useSnack";
import {
  Rental,
  CommonUtil as cu,
  RentalStatusArray,
  RentalStatus,
  DAY,
  pick,
  Image,
} from "../../../../types/elements";
import ImageViewClickPanel from "../../../organisms/ImageViewClickPanel";

interface RentalDetailProps {
  id: number;
  onSaveDone?: () => any;
}
const RentalDetail: React.FC<RentalDetailProps> = (props) => {
  const { openSnack } = useSnack();
  const [rental, setRental] = useState<Rental | undefined>();

  const load = (id: number) => {
    gqladm.rentals({ id }).then((result) => {
      if (result?.list && result.list.length === 1) setRental(result.list[0]);
    });
  };

  useEffect(() => {
    if (props.id) load(props.id);
  }, []);

  // Status
  const [changeStatus, setChangeStatus] = useState<RentalStatus | undefined>();
  const handleChangeStatus = () => {
    if (rental?.status === changeStatus) return;
    rental?.id &&
      changeStatus !== undefined &&
      gqladm.updateRentalStatus(rental.id, changeStatus).then((result) => {
        if (result.ok) {
          openSnack({ msg: "상태가 변경되었습니다. " });
          load(props.id);
        }
      });
  };

  // Calendar
  const handleSelectStart = (y: number, m: number, d: number) => {
    const start = new Date(y, m, d);
    setRental((r) => ({
      ...r,
      rental_start: cu.getFormattedDate(start, "yyyy-MM-dd"),
      days: r?.rental_end
        ? (new Date(r.rental_end).getTime() -
            start.getTime() +
            start.getTimezoneOffset() * 60 * 1000) /
            DAY +
          1
        : r?.days,
    }));
  };

  const handleSelectEnd = (y: number, m: number, d: number) => {
    const end = new Date(y, m, d);
    setRental((r) => ({
      ...r,
      rental_end: cu.getFormattedDate(end, "yyyy-MM-dd"),
      days: r?.rental_start
        ? (end.getTime() -
            new Date(r.rental_start).getTime() -
            end.getTimezoneOffset() * 60 * 1000) /
            DAY +
          1
        : r?.days,
    }));
  };

  const handleChangeFileUpload = (temp_id: number, status: "ing" | "done" | "fail") => {
    if (status === "done" && load) load(props.id);
  };
  const handleDelImage = (image: Image) => {
    if (props.id && image.id)
      gqladm
        .deleteMultiImage("Rental", props.id, image.id)
        .then((result) => {
          if (result.ok) load(props.id);
        })
        .catch((err) => {
          openSnack({ msg: "Error " + err });
        });
  };

  const handleSave = () => {
    if (rental)
      gqladm
        .updateRental(
          pick<Rental>(rental, [
            "id",
            "rental_start",
            "rental_end",
            "days",
            "receipt_target",
            "receipt_number",
          ])
        )
        .then((result) => {
          if (result.ok) {
            if (props.onSaveDone) props.onSaveDone();
            openSnack({ msg: "저장 되었습니다. " });
            load(props.id);
          }
        });
  };

  return (
    <div className="serviceDetail">
      <ColumnDiv label="렌탈 요청 상세"></ColumnDiv>
      <ColumnDiv label="유저">
        <div style={{ border: "1px solid black", padding: "1rem" }}>
          <div>이름: {rental?.user?.name}</div>
          <div>전화: {rental?.user?.phone}</div>
          <div>이메일: {rental?.user?.email}</div>
        </div>
      </ColumnDiv>
      <hr />

      <ColumnDiv label="id">
        <TextField size="small" disabled value={props.id} fullWidth />
      </ColumnDiv>

      <ColumnDiv label="CREATED">
        {rental?.created !== undefined && cu.getFormattedDate(rental.created, "yyyy-MM-dd")}
      </ColumnDiv>

      <ColumnDiv label="STATUS">
        <div style={{ minWidth: "100px", display: "inline-block" }}>&nbsp;{rental?.status}</div>
        =&gt;
        <Select
          value={changeStatus}
          onChange={(e: any) => setChangeStatus(e.target.value)}
          size="small">
          {RentalStatusArray.map((o) => (
            <MenuItem value={o}>{o}</MenuItem>
          ))}
        </Select>
        <Button
          size="small"
          onClick={handleChangeStatus}
          disabled={rental?.status === changeStatus}>
          변경
        </Button>
      </ColumnDiv>
      <ColumnDiv label="사업자정보">
        <div>
          <FormControlLabel
            control={<Radio checked={rental?.receipt_target === "company"} />}
            onClick={() => setRental((r) => ({ ...r, receipt_target: "company" }))}
            label="Company"
          />
          <FormControlLabel
            control={<Radio checked={rental?.receipt_target === "personal"} />}
            onClick={() => setRental((r) => ({ ...r, receipt_target: "personal" }))}
            label="Personal"
          />
        </div>
        {rental?.receipt_target === "company" && (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {rental?.companyImages?.map((image, idx) => {
              return (
                <div key={"img_" + image.id}>
                  <ImageViewClickPanel url={image.url}>
                    <img src={"/" + image.url} style={{ width: "96px", height: "96px" }} />
                  </ImageViewClickPanel>
                  {/* <Button
                    size="small"
                    fullWidth
                    variant="outlined"
                    style={{ display: "flex" }}
                    onClick={() => handleDelImage(image)}>
                    x
                  </Button> */}
                </div>
              );
            })}
          </div>
        )}
        {rental?.receipt_target === "personal" && (
          <div>
            개인 휴대전화{" "}
            <TextField
              size="small"
              value={rental.receipt_number}
              onChange={(e) => setRental((r) => ({ ...r, receipt_number: e.target.value }))}
            />
          </div>
        )}
      </ColumnDiv>

      <ColumnDiv label="렌탈 일정">
        <div style={{ display: "flex", alignItems: "center" }}>
          <DatePicker size="small" value={rental?.rental_start} onSelect={handleSelectStart} /> ~
          <DatePicker size="small" value={rental?.rental_end} onSelect={handleSelectEnd} />
        </div>
        <div>{rental?.days ? ` ${rental.days} days ` : ""}</div>
      </ColumnDiv>

      <ColumnDiv label="렌탈 희망 이미지">
        <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
          {rental?.images?.map((image, idx) => {
            return (
              <div key={"img_" + image.id}>
                <ImageViewClickPanel url={image.url}>
                  <img src={"/" + image.url} style={{ width: "96px", height: "96px" }} />
                </ImageViewClickPanel>
                <Button
                  size="small"
                  fullWidth
                  variant="outlined"
                  style={{ display: "flex" }}
                  onClick={() => window.confirm("Delete confirm?") && handleDelImage(image)}>
                  x
                </Button>
              </div>
            );
          })}
          <MultiImageUploadButton
            target="Rental"
            targetId={rental?.id ?? -1}
            onChangeFileUpload={handleChangeFileUpload}>
            <div
              style={{
                display: "flex",
                width: "96px",
                height: "96px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                border: "1px solid black",
              }}>
              <div>
                <Add />
              </div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <span className="orange">{(rental?.images && rental?.images?.length) ?? 0}</span>
                /10
              </div>
            </div>
          </MultiImageUploadButton>
        </div>
      </ColumnDiv>

      <Button onClick={handleSave} fullWidth variant="contained">
        Save
      </Button>
    </div>
  );
};

export default RentalDetail;
