import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { client } from "../../graphql/client";
import { GET_ITEM_OPTION_IN_ITEM_STOCK_TABLE_DIALOG, QueryIF } from "../../graphql/types";
import { ItemOption } from "../../types/elements";
import ItemStockTable from "./ItemStockTable";

interface ItemStockTableDialogProps {
  open: boolean;
  onClose: (completed?: boolean) => any;

  itemOptionId?: number;
}
const ItemStockTableDialog: React.FC<ItemStockTableDialogProps> = (props) => {
  const [itemOption, setItemOption] = useState<ItemOption>({});
  const load = () => {
    if (props.itemOptionId !== undefined) {
      console.log("load itemOption");
      client
        .query<QueryIF, { itemOptionId: number }>({
          query: GET_ITEM_OPTION_IN_ITEM_STOCK_TABLE_DIALOG,
          variables: { itemOptionId: props.itemOptionId },
          fetchPolicy: "network-only",
        })
        .then((result) => {
          console.log("GetItemOptionInItemStockTableDialog", result);
          const data = result.data;
          if (data.itemOption) {
            setItemOption(data.itemOption);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    load();
  }, [props.itemOptionId]);

  const handleUpdated = () => {
    load();
  };
  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <ItemStockTable option={itemOption} updateItem={handleUpdated} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemStockTableDialog;
