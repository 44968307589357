import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { ItemStock, CommonUtil as cu } from "../../../../types/elements";

interface StockTableProps {
  list: ItemStock[];
}
const StockTable: React.FC<StockTableProps> = ({ list }) => {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>상품</TableCell>
            <TableCell>상품옵션</TableCell>
            <TableCell>수량(주문/등록)</TableCell>
            <TableCell>등록일자</TableCell>
            <TableCell>위치</TableCell>
            <TableCell>메모</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.length > 0 ? (
            list.map((si) => {
              return (
                <TableRow>
                  <TableCell>{si.stockId}</TableCell>
                  <TableCell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {si.item?.topImages && si.item.topImages?.length > 0 && (
                        <img
                          src={si.item.topImages[0].url}
                          height="32px"
                          style={{ marginRight: "1rem" }}
                        />
                      )}
                      {si.item?.name}
                    </div>
                  </TableCell>
                  <TableCell>{si.itemOption?.name}</TableCell>
                  <TableCell>
                    {(si.orderedItems ?? [])
                      .map((oi) => oi.orderedCount ?? 0)
                      .reduce((p, n) => p + n, 0)}
                    /{si.stockCount}
                  </TableCell>
                  <TableCell>
                    {si.created && cu.getFormattedDate(si.created, "yyyy-MM-dd hh:mm")}
                  </TableCell>
                  <TableCell>
                    {si.warehouseId} - {si.warehouse?.name}
                  </TableCell>
                  <TableCell>{si.memo}</TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell>데이터가 없습니다. </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default StockTable;
