import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../../graphql/gqladm";
import { useAppCommon } from "../../../../hooks/useAppCommon";
import { getFormattedDate, UserNoti, UserNotiSearchOption } from "../../../../types/elements";
import PushMessageListItem from "../../../molecules/PushMessageListItem";

const LIMIT = 50;
interface UserNotiListViewProps {}
const UserNotiListView: React.FC<UserNotiListViewProps> = (props) => {
  useAppCommon({ headerTitle: "유저 알림 목록" });
  const [list, setList] = useState<UserNoti[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [sopt, setSopt] = useState<UserNotiSearchOption>({
    limit: LIMIT,
    offset: 0,
    orderby: "created_desc",
  });

  useEffect(() => {
    load(sopt);
  }, []);
  const load = (sopt: UserNotiSearchOption) => {
    gqladm.getUserNotis(sopt).then((result) => {
      if (result.total !== undefined) setTotal(result.total);
      if (result.list) setList(result.list);
      setSopt((so) => ({ ...so, offset: result.offset ?? 0 }));
    });
  };

  return (
    <div>
      <Table size="small">
        <TableHead></TableHead>
        <TableBody>
          {list.map((un) => (
            <TableRow>
              <TableCell>{un.id}</TableCell>
              <TableCell>
                #{un.user_id} {un.user?.name}
              </TableCell>
              <TableCell>
                <PushMessageListItem
                  title={un?.title}
                  body={un?.body}
                  imageUrl={un?.imageUrl}
                  dataUrl={un?.dataUrl}
                />
                {un.dataUrl}
              </TableCell>
              <TableCell>
                {un.created && getFormattedDate(un.created, "yyyy-MM-dd hh:mm")}
              </TableCell>
              <TableCell>FcmPush#{un.fp_id}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default UserNotiListView;
