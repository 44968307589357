import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { User, CommonUtil as cu } from "../../types/elements";
import MiniTable from "./MiniTable";
import { SmsAPI } from "../../api";
import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../../store/states/SnackState";
import { template } from "@babel/core";
import { gqladm } from "../../graphql/gqladm";

interface SmsDialogProps {
  open: boolean;
  onClose: () => any;
  // users: Array<User>;
  users: number[];
}
const SmsDialog: React.FC<SmsDialogProps> = (props) => {
  const openSnack = useSetRecoilState(SnackStateSelector);

  const [adhead, setAdhead] = useState<string>("<Ad> Andersonc");
  const [adtail, setAdtail] = useState<string>("무료수신거부 andersonc-official.com/nospam");
  const [adheadOn, setAdheadOn] = useState<boolean>(true);
  const [adtailOn, setAdtailOn] = useState<boolean>(true);
  const [content, setContent] = useState<string>("");

  const [tempList, setTempList] = useState<string[]>([]);
  const [tempNum, setTempNum] = useState<string>("");
  const handleAddTempNum = () => {
    if (tempNum && tempNum.length >= 11) {
      setTempList([...tempList, tempNum]);
      setTempNum("");
    }
  };

  const [users, setUsers] = useState<User[]>([]);
  useEffect(() => {
    if (props.users.length > 0)
      gqladm.getUsers({ ids: props.users, limit: 9999 }).then((result) => {
        if (result.list) setUsers(result.list);
      });
  }, [props.users]);

  const smsbody = adhead.trim() + "\n" + content + "\n" + adtail.trim() + " ";
  const contentLength = cu.getSMSByteLength(smsbody);

  const handleSendSms = () => {
    const useremails: string[] = [];
    users.forEach((user) => {
      if (user.email) {
        useremails.push(user.email);
      }
    });
    SmsAPI.postSms(smsbody, useremails, tempList).then((result) => {
      const data = result.data;
      if (data.result) {
        openSnack({ msg: "메세지 전송이 성공했습니다." });
        props.onClose();
      }
    });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="lg">
      <DialogTitle>SMS 전송</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: "1 1 40%",
              display: "flex",
              flexDirection: "column",
              padding: "0px 24px",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <span>- 메세지 -</span>
            <div style={{ border: "1px solid black" }}>
              {adheadOn && (
                <TextField value={adhead} fullWidth onChange={(e) => setAdhead(e.target.value)} />
              )}
              <TextField
                variant="outlined"
                multiline
                rows={5}
                onChange={(e) => setContent(e.target.value)}
                fullWidth
              />
              {adtailOn && (
                <TextField value={adtail} fullWidth onChange={(e) => setAdtail(e.target.value)} />
              )}
            </div>
            <span>
              {contentLength} bytes = {contentLength > 90 ? "LMS" : "SMS"}
            </span>
            <div>
              <FormControlLabel
                control={<Checkbox checked={adheadOn} />}
                label="광고"
                onClick={() => setAdheadOn(!adheadOn)}
              />
              <FormControlLabel
                control={<Checkbox checked={adtailOn} />}
                label="수신거부"
                onClick={() => setAdtailOn(!adtailOn)}
              />
            </div>
          </div>
          <div style={{ flex: "1 1 60%", overflow: "auto" }}>
            <MiniTable items={users} cols={["name", "phone", "created", "agreeSMSDate"]} />
            {tempList.length > 0 && (
              <div>
                임의 등록 번호
                <MiniTable
                  items={tempList.map((o) => {
                    return { phone: o };
                  })}
                  cols={["phone"]}
                />
              </div>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <TextField value={tempNum} onChange={(e) => setTempNum(e.target.value)}></TextField>
        <Button onClick={handleAddTempNum}>임의 수신번호 추가</Button>
        <Button onClick={handleSendSms}>전송</Button>
        <Button onClick={props.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SmsDialog;
