import { WrapperAxios as Axios } from "./base";

import { APIResponse, Warehouse } from "../types/elements";
export const AdminWarehouseAPI = {
  getWarehouses(): Promise<APIResponse<any>> {
    return Axios.get("/admin/warehouse");
  },
  postWarehouse(wh: Warehouse): Promise<APIResponse<any>> {
    return Axios.post("/admin/warehouse", wh);
  },
  putWarehouse(wh: Warehouse): Promise<APIResponse<any>> {
    return Axios.put("/admin/warehouse/" + wh.id, wh);
  },
  deleteWarehouse(id: number): Promise<APIResponse<any>> {
    return Axios.delete("/admin/warehouse/" + id);
  },
};
