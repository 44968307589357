import { WrapperAxios as Axios } from "./base";

import { CommonUtil, APIResponse, Constant, ConstantKeys, convertConstant } from "../types/elements";
import { ADMIN_API_PATH } from "../types/adm_elements";

export const AdminConstantAPI = {
  getConstants(key?: ConstantKeys): Promise<APIResponse<Constant[]>> {
    let query = "";
    if (key) {
      query = "?key=" + key;
    }
    return Axios.get("/admin" + ADMIN_API_PATH.CONSTANT + query).then((res) => {
      const data = res.data;
      if (data.result) {
        data.result = data.result.map((c: Constant) => {
          const ret = convertConstant(c);
          return ret ? ret : c;
        });
      }
      return res;
    });
  },
  postConstant(et: Constant): Promise<APIResponse<any>> {
    return Axios.post("/admin" + ADMIN_API_PATH.CONSTANT, et);
  },
  deleteConstant(obj: Constant): Promise<APIResponse<any>> {
    const query = CommonUtil.createQueryString(obj);
    return Axios.delete("/admin" + ADMIN_API_PATH.CONSTANT + query);
  },
};
