import { useState } from "react";
import { gqladm } from "../graphql/gqladm";
import { FcmMessage } from "../types/adm_elements";

const splitArray = (data: any[], size: number) => {
  let count = Math.floor(data.length / size) + Math.floor(data.length % size > 0 ? 1 : 0);
  let resultData = [];

  for (let i = 0; i < count; i++) {
    resultData.push(data.splice(0, size));
  }

  return resultData;
};

export const useSendPush = () => {
  const [succCount, setSuccCount] = useState<number>(0);
  const [failCount, setFailCount] = useState<number>(0);
  const [allCount, setAllCount] = useState<number>(0);

  const [allRunCount, setAllRunCount] = useState<number>(0);
  const [runCount, setRunCount] = useState<number>(0);

  const sendPushs = (opt: FcmMessage[]) => {
    setSuccCount(0);
    setFailCount(0);
    setAllCount(0);
    setRunCount(0);
    setAllRunCount(0);

    const willSends = splitArray(opt, 100);

    setAllRunCount(willSends.length);

    willSends.forEach((sd) =>
      gqladm.sendPushs(sd).then((result) => {
        if (result.count !== undefined) setSuccCount((r) => r + (result.count ?? 0));
        if (result.failed !== undefined) setFailCount((r) => r + (result.failed ?? 0));
        if (result.amount !== undefined) setAllCount((r) => r + (result.amount ?? 0));
        setRunCount((r) => r + 1);
      })
    );
  };

  return {
    sendPushs,
    succCount,
    failCount,
    allCount,
    status: runCount === allRunCount ? "Done" : "Running",
  };
};
