import { Alert } from "@mui/material";
import React from "react";
import { gqladm } from "../../../graphql/gqladm";
import useSnack from "../../../hooks/useSnack";
import { Item, OrderItem } from "../../../types/elements";
import SelectItemDialog from "../../organisms/SelectItemDialog";

interface ChangeOrderItemAddDialogProps {
  open: boolean;
  onClose: (completed?: boolean) => void;
  order_id: number;
  orderItems: OrderItem[];
}
const ChangeOrderItemAddDialog: React.FC<ChangeOrderItemAddDialogProps> = (props) => {
  const { openSnack } = useSnack();
  const handleSelect = (item: Item) => {
    if (props.orderItems.find((o) => o.item_id === item.id))
      openSnack({ msg: "이미 추가되어있는 상품입니다." });
    else {
      if (item.id && item.defaultItemOptionId)
        gqladm
          .changeOrderItemCount(props.order_id, item.id, item.defaultItemOptionId, 1)
          .then((result) => {
            if (result.ok) {
              props.onClose(true);
            } else {
              openSnack({ msg: "에러 - " + result.msg });
            }
          });
    }
  };
  return (
    <>
      <SelectItemDialog open={props.open} onClose={() => props.onClose()} onSelect={handleSelect} />
    </>
  );
};

export default ChangeOrderItemAddDialog;
