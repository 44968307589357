import { Button, Checkbox, IconButton, Paper } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import SelectedActions from "./SelectedActions";

import { UserResultProps } from "./UserListView";

interface UserResultListProps extends UserResultProps {
  selected: number[];
  toggleSelectedAll: () => void;
  toggleSelected: (id: number) => void;
}
const UserResultList: React.FC<UserResultListProps> = ({
  list,
  openUser,
  selected,
  toggleSelectedAll,
  toggleSelected,
}) => {
  const navi = useNavigate();
  return (
    <div>
      {selected.length > 0 && <SelectedActions selected={selected} />}
      <div style={{ backgroundColor: "#f0f0f0", margin: "0.5rem 0", padding: "0.3rem" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Checkbox
            size="small"
            checked={list.length === selected.length}
            onClick={toggleSelectedAll}
          />
          <div></div>
          <div></div>
        </div>
      </div>
      {list.map((user) => {
        return (
          <div
            key={user.id}
            style={{ backgroundColor: "#f0f0f0", margin: "0.5rem 0", padding: "0.3rem" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Checkbox
                size="small"
                checked={user.id !== undefined && selected.indexOf(user.id) > -1}
                onClick={() => user.id !== undefined && toggleSelected(user.id)}
              />
              <div>
                #{user.id} - {user.name} - {user.phone}
              </div>
              <div>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => user.id && openUser(user.id)}>
                  Detail
                </Button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserResultList;
