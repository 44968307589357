import React from "react";
import { User } from "../../../types/elements";

interface AreaSmsProps {
  users: User[];
}
const AreaSms: React.FC<AreaSmsProps> = (props) => {
  return (
    <div>
      <div>AreaSms</div>
    </div>
  );
};

export default AreaSms;
