import { Button, IconButton } from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Image } from "../../../types/elements";
import { ImageAPI } from "../../../api";
import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../../../store/states/SnackState";
import { gqladm } from "../../../graphql/gqladm";
import UploadedItemImage from "./UploadedItemImage";

interface ItemDetailImageInfoProps {
  header?: any;
  item_id?: number;
}
const ItemDetailImageInfo: React.FC<ItemDetailImageInfoProps> = (props) => {
  const openSnack = useSetRecoilState(SnackStateSelector);
  const [topImages, setTopImages] = useState<Array<Image>>([]);
  const [images, setImages] = useState<Array<Image>>([]);

  useEffect(() => {
    props.item_id && refresh();
  }, [props.item_id]);

  //    refresh
  const refresh = () => {
    console.log("refresh", props.item_id);
    setTopImages([]);
    setImages([]);
    if (props.item_id)
      return gqladm.getItemImages(props.item_id).then((result) => {
        console.log(result);
        setTopImages(result.topImages ? result.topImages : []);
        setImages(result.images ? result.images : []);
      });
  };

  // unbind image
  const handleUnbindImage = (image: Image, place: string) => {
    props.item_id &&
      image.id &&
      gqladm.removeItemImage(props.item_id, image.id, "top").then((result) => {
        refresh();
      });
  };

  const disableDeleteImageButton = (image: Image): boolean => {
    return topImages.filter((o) => o.id === image.id).length > 0;
  };
  // upload
  const fileUploadInput = createRef<HTMLInputElement>();

  //   TODO
  //   const handlePaste = (e: any) => {
  //     console.log("handlePaste", e);
  //     console.log(e.clipboardData, e.clipboardData.items.length);
  //     console.log(Array.from(e.clipboardData.items).filter((o: any) => o.type.startsWith("image")));

  //     const files = Array.from(e.clipboardData.items).filter((o: any) => o.type.startsWith("image"));
  //     files.forEach((file: any) => {
  //       if (file.type.startsWith("image")) {
  //         const image: Image = {
  //           name: "clipboard",
  //           type: file.type,
  //           modified: Date.now(),
  //           file: file.getAsFile(),
  //           url: "",
  //           uid: "",
  //           itemId: item.id,
  //         };
  //         console.log(image);
  //         const _images: Array<Image> = [...images, image];
  //         ImageAPI.postItemImage(image).then((result) => {
  //           console.log("handleUploadImage", result);
  //           const data = result.data;
  //           if (data.result) {
  //             console.log(data.result);
  //             image.id = data.result.id;
  //             image.url = data.result.url;
  //             setImages(_images);
  //           }
  //         });
  //       }
  //     });
  //     // const file = e.clipboardData.items[0];
  //     // console.log(file);
  //   };

  const onClickImageUploadButton = () => {
    if (fileUploadInput && fileUploadInput.current) {
      fileUploadInput.current.click();
    }
  };

  const handleFiles = (e: any) => {
    const files = Array.from<File>(e.target.files);
    if (FileReader && files && files.length) {
      const _images: Array<Image> = [...images];
      for (const i in files) {
        const file = files[i];
        const image: Image = {
          name: file.name,
          type: file.type,
          modified: file.lastModified,
          file: file,
          url: "",
          uid: "",
          itemId: props.item_id,
        };
        _images.push(image);

        ImageAPI.postItemImage(image)
          .then((result) => {
            console.log("handleUploadImage", result);
            const data = result.data;
            if (data.result) {
              openSnack({ msg: "이미지가 업로드되었습니다." });
              image.uid = data.result.uid;
              image.url = data.result.url;
              setImages(_images);
              refresh();
            }
          })
          .catch((err) => {
            openSnack({
              msg: "이미지가 업로드가 실패하였습니다." + err.message,
              severity: "error",
            });
          });
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleCreateAllThumbnails = async () => {
    const imageCount = images.length;
    if (imageCount > 0) {
      try {
        for (const idx in images) {
          const image = images[idx];
          if (image.id) await gqladm.createImageThumbnail(image.id);
        }
        setImages([]);
        await refresh();
        openSnack({ msg: `썸네일 생성을 완료했습니다. (${imageCount}/${imageCount})` });
      } catch (err) {
        openSnack({ msg: "Error on handleCreateAllThumbnails" + err });
      }
    }
  };

  return (
    <div>
      {props.header && (
        <h1>
          {props.header}{" "}
          <Button variant="contained" onClick={() => handleCreateAllThumbnails()}>
            Create all thumbnail images
          </Button>
        </h1>
      )}
      <h4>
        적용이미지
        <IconButton onClick={refresh}>
          <RefreshIcon />
        </IconButton>
      </h4>
      <div>
        {topImages.map((image) => {
          return (
            <div
              key={`${Date.now()}-${image.id}`}
              style={{ display: "inline-block" }}
              onClick={() => handleUnbindImage(image, "top")}>
              <img width="128" height="128" src={"/" + image.url} />
            </div>
          );
        })}
      </div>
      <hr />
      <h4>
        업로드된 이미지
        <IconButton onClick={onClickImageUploadButton}>
          <CloudUploadIcon /> &nbsp; Upload
        </IconButton>
        <input
          ref={fileUploadInput}
          type="file"
          multiple
          style={{ display: "none" }}
          onChange={handleFiles}
        />
        <div>
          {images.map((image) => {
            return (
              <UploadedItemImage
                key={`${Date.now()}-${image.id}`}
                image={image}
                refresh={refresh}
                item_id={props.item_id}
                disableDeleteButton={disableDeleteImageButton(image)}
              />
            );
          })}
        </div>
      </h4>
    </div>
  );
};
export default ItemDetailImageInfo;
