import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { useAppCommon } from "../../../hooks/useAppCommon";
import { FcmPush, FcmPushSearchOption } from "../../../types/adm_elements";
import { getDiffTime, getFormattedDate } from "../../../types/elements";
import PushMessageListItem from "../../molecules/PushMessageListItem";

const LIMIT = 20;
interface FcmPushListViewProps {}
const FcmPushListView: React.FC<FcmPushListViewProps> = (props) => {
  useAppCommon({ headerTitle: "FcmPush" });

  const [sopt, setSopt] = useState<FcmPushSearchOption>({ limit: LIMIT, offset: 0 });

  const [total, setTotal] = useState<number>(0);
  const [list, setList] = useState<FcmPush[]>([]);

  const load = (sopt: FcmPushSearchOption) => {
    gqladm.getFcmPushs(sopt).then((result) => {
      if (result.list) setList(result.list);
      if (result.total !== undefined) setTotal(result.total);
      setSopt({
        ...sopt,
        offset: result.offset ?? sopt.offset,
        limit: result.limit ?? sopt.limit,
      });
    });
  };

  useEffect(() => load(sopt), []);
  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  /**
   * FcmPush 생성 다이얼로그가 필요함.
   *    - 메세지 보낼 대상들 추가
   *    - 메세지 보낼 대상 목록 확인 가능
   *    - 상품id가 지정될경우, 상품에 따른 알림조건으로 유저-장비 목록을 도출
   *    - 테스트 대상 설정하기 필요 - 테스트 푸쉬 보내기 활성화 (테스트에는 테스트라고 표시)
   * 메세지 갯수 조회 기능 필요 - 전체 메세지 갯수
   */
  return (
    <div>
      <div></div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>Total {total}</div>
        <Pagination
          count={Math.ceil(total / (sopt.limit ?? LIMIT))}
          page={Math.ceil((sopt.offset ?? 0) / (sopt.limit ?? LIMIT)) + 1}
          onChange={handleChangePage}
        />
        <div></div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Msg</TableCell>
            <TableCell>Send</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((o) => {
            return (
              <TableRow key={"fcmpush_table_row_" + o.id}>
                <TableCell>
                  <a href={"/fcmpush/" + o.id} target="_blank" className="uline">
                    {o.id}
                  </a>
                </TableCell>
                <TableCell>
                  <a href={"/item/" + o.item_id} target="_blank" className="uline">
                    {o.item_id}
                  </a>
                </TableCell>
                <TableCell>
                  <PushMessageListItem
                    title={o?.title}
                    body={o?.body}
                    imageUrl={o?.imageUrl}
                    dataUrl={o?.dataUrl}
                  />
                  {o?.dataUrl}
                </TableCell>
                <TableCell>
                  <Tooltip
                    placement="top"
                    title={
                      (o.send_start ? getFormattedDate(o.send_start, "yyyy-MM-dd hh:mm") : "") +
                      " ~ " +
                      (o.send_end ? getFormattedDate(o.send_end, "yyyy-MM-dd hh:mm") : "")
                    }>
                    <Button size="small" color="inherit">
                      {o.send_end && getDiffTime(o.send_end, Date.now())}
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default FcmPushListView;
