import { gql } from "apollo-boost";
import {
  PagedPaymentAnnotation,
  PaymentsByMidParams,
  PaymentsStatusOption,
} from "../../types/adm_elements";
import { SimpleResult } from "../../types/elements";
import { client } from "../client";

interface QueryIF {
  admin?: {
    iamportToken?: SimpleResult;
    iamportPaymentsStatus?: PagedPaymentAnnotation;
    iamportPaymentsMid?: PagedPaymentAnnotation;
  };
}

export const getIamportToken = (): Promise<SimpleResult> =>
  client
    .query<QueryIF>({
      query: gql`
        query GetIamportToken {
          admin {
            iamportToken {
              ok
              value
              expired
            }
          }
        }
      `,
    })
    .then((result) => {
      if (result.data.admin?.iamportToken) return result.data.admin.iamportToken;
      throw new Error("Err");
    });

const PAYMENTS_FIELDS = ` imp_uid merchant_uid pay_method channel pg_provider pg_tid pg_id escrow apply_num bank_code bank_name card_code card_name card_quota card_number card_type vbank_code vbank_name vbank_num vbank_holder vbank_date vbank_issued_at name amount cancel_amount currency buyer_name buyer_email buyer_tel buyer_addr buyer_postcode custom_data user_agent status started_at paid_at failed_at cancelled_at fail_reason cancel_reason receipt_url cancel_receipt_urls cash_receipt_issued customer_uid customer_uid_usage `;
export const getIamportPaymentsStatus = (
  opt: PaymentsStatusOption
): Promise<PagedPaymentAnnotation> =>
  client
    .query<QueryIF>({
      query: gql`
        query GetImportPaymentsStatus($opt: PaymentsStatusSearchInput) {
          admin {
            iamportPaymentsStatus(opt: $opt) {
              next previous total list { ${PAYMENTS_FIELDS} }
            }
          }
        }
      `,
      variables: { opt },
    })
    .then((result) => {
      if (result.data.admin?.iamportPaymentsStatus) return result.data.admin.iamportPaymentsStatus;
      throw new Error("Err");
    });
export const getIamportPaymentsMid = (opt: PaymentsByMidParams): Promise<PagedPaymentAnnotation> =>
  client
    .query<QueryIF>({
      query: gql`
        query GetIamportPaymentsMid($opt: PaymentsMidSearchInput) {
          admin {
            iamportPaymentsMid(opt:$opt) {
              next previous total list { ${PAYMENTS_FIELDS} }
            }
          }
        }`,
      variables: { opt },
    })
    .then((result) => {
      if (result.data.admin?.iamportPaymentsMid) return result.data.admin.iamportPaymentsMid;
      throw new Error("Err");
    });
