import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";
import { FcmPushUser } from "../../../types/adm_elements";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { isSameNumberArray } from "../../../types/elements";

interface TargetListProps {
  style?: CSSProperties;
  list: FcmPushUser[];
  remove?: (user_ids: number[]) => void;
  total: number;
  limit: number;
  page: number;
  selectPage?: (page: number) => void;
}
const TargetList: React.FC<TargetListProps> = ({
  style,
  list,
  remove,
  selectPage,
  page,
  total,
  limit,
}) => {
  const lastPage = Math.floor(total / (limit ?? 10)) + 1;
  const handleChangePage = () => {
    const newPage = window.prompt("Go to ", "" + page);
    selectPage && selectPage(Number(newPage));
  };
  const handleBack = () => {};
  const handleForward = () => {};

  useEffect(() => {
    setSelected([]);
  }, [list]);

  // selected
  const [selected, setSelected] = useState<number[]>([]);

  return (
    <div style={{ ...(style ?? {}) }}>
      <List>
        <ListItem>
          <b>FCM Push Target Users</b>
        </ListItem>

        <ListItem sx={{ display: "flex", justifyContent: "space-between" }}>
          <div></div>
          <div>
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: "none" }}
              onClick={handleChangePage}>
              Page: {page} of {lastPage} ({total})
            </Button>
            <IconButton onClick={handleBack}>
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={handleForward}>
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </div>
        </ListItem>

        <ListItem
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="delete"
              disabled={selected.length === 0}
              onClick={() => remove && remove(selected)}>
              <DeleteIcon />
            </IconButton>
          }>
          <ListItemIcon>
            <Checkbox
              checked={
                selected.length > 0 &&
                isSameNumberArray(
                  selected,
                  list.map((o) => o.user_id ?? -1)
                )
              }
              onChange={(p, v) => {
                setSelected(v ? list.map((o) => o.user_id ?? -1).filter((o) => o !== -1) : []);
              }}
            />
          </ListItemIcon>
          <ListItemText primary={`selected: ${selected.length}`}></ListItemText>
        </ListItem>
      </List>

      <List>
        {list?.map((fcmPushUser) => {
          return (
            <ListItem
              key={"fcmPushUser_" + fcmPushUser.id}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => remove && fcmPushUser.user_id && remove([fcmPushUser.user_id])}>
                  <DeleteIcon />
                </IconButton>
              }>
              <ListItemIcon>
                <Checkbox
                  checked={fcmPushUser.user_id ? selected.includes(fcmPushUser.user_id) : false}
                  onChange={(e, v) =>
                    setSelected((sl) =>
                      fcmPushUser.user_id
                        ? sl.indexOf(fcmPushUser.user_id) > -1
                          ? sl.filter((o) => o !== fcmPushUser.user_id)
                          : [...sl, fcmPushUser.user_id]
                        : sl
                    )
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={`#${fcmPushUser.id} User#${fcmPushUser.user_id} ${fcmPushUser.user?.name}`}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default TargetList;
