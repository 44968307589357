import React from "react";
import { useAppCommon } from "../../../hooks/useAppCommon";
import AdminHistoryList from "./AdminHistoryList";

interface AdminHistoryViewProps {}
const AdminHistoryView: React.FC<AdminHistoryViewProps> = (props) => {
  useAppCommon({ headerTitle: "AdminHistory" });

  return (
    <div>
      <AdminHistoryList />
    </div>
  );
};

export default AdminHistoryView;
