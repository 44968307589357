import React, { useEffect, useState } from "react";
import { gqladm } from "../../../../../graphql/gqladm";
import {
  getReservationStatusKr,
  Reservation,
  ReservationStatus,
} from "../../../../../types/elements";

const SimpleTr: React.FC<{ id?: string | number; date?: string; status?: string }> = (props) => {
  return (
    <tr>
      <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{props.id}</td>
      <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{props.date}</td>
      <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>
        {props.status && getReservationStatusKr(props.status as ReservationStatus)}
      </td>
    </tr>
  );
};

interface UserInfoReservationsProps {
  user_id: number;
}
const UserInfoReservations: React.FC<UserInfoReservationsProps> = (props) => {
  const [list, setList] = useState<Reservation[]>([]);
  useEffect(() => {
    if (props.user_id)
      gqladm.searchReservation({ user_id: props.user_id, limit: 10 }).then((result) => {
        if (result.list) setList(result.list);
      });
  }, [props.user_id]);

  return (
    <div className="simple1panel" style={{ minWidth: "320px", flex: 1 }}>
      <div className="simple1panel_head">Reservation</div>
      <div className="simple1panel_body">
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>방문일</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {list.map((reserv, idx) => {
              return (
                <SimpleTr key={idx} id={reserv.id} date={reserv.date} status={reserv.status} />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserInfoReservations;
