import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Table,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import useSnack from "../../../hooks/useSnack";
import { ConstantKeys, ReservationBanner } from "../../../types/elements";

const CONSTANT_KEY: ConstantKeys = "RESERVATION_BANNER";
interface ConstantReservationBannerDialogProps {
  open: boolean;
  onClose: () => any;
}
const ConstantReservationBannerDialog: React.FC<ConstantReservationBannerDialogProps> = ({
  open,
  onClose,
}) => {
  const { openSnack } = useSnack();
  const [rb, setRb] = useState<ReservationBanner>({ onoff: "off", text: "" });
  const load = () => {
    gqladm.searchConstants({ key: CONSTANT_KEY, isUniqueKey: true }).then((result) => {
      if (
        result.list &&
        result.list.length > 0 &&
        result.list[0].isJson === true &&
        typeof result.list[0].value === "string"
      ) {
        try {
          const data: ReservationBanner = JSON.parse(result.list[0].value);
          setRb(data);
        } catch (err) {}
      }
    });
  };

  useEffect(() => {
    load();
  }, []);

  const handleSave = () => {
    gqladm
      .addConstant({
        isJson: true,
        isPublic: true,
        key: CONSTANT_KEY,
        value: JSON.stringify(rb),
      })
      .then((result) => {
        if (result.ok) {
          openSnack({ msg: "예약화면 배너를 저장하였습니다. " });
          onClose();
        } else {
          openSnack({ msg: "오류가 발생했습니다. " });
        }
      })
      .catch((err) => {
        openSnack({ msg: "오류가 발생했습니다. " + err });
      });
  };

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>예약 화면 내 배너 설정</DialogTitle>
      <DialogContent>
        <Table>
          <TableRow>
            <TableCell>배너 ON/OFF</TableCell>
            <TableCell>
              <Switch
                checked={rb.onoff === "on"}
                onChange={(e, c) => setRb((r) => ({ ...r, onoff: c ? "on" : "off" }))}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>내용</TableCell>
            <TableCell>
              <TextField
                value={rb.text}
                onChange={(e) => setRb((r) => ({ ...r, text: e.target.value }))}
              />
            </TableCell>
          </TableRow>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSave}>
          저장
        </Button>
        <Button onClick={() => onClose()}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConstantReservationBannerDialog;
