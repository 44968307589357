import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React from "react";

interface PushMessageListItemProps {
  title?: string;
  body?: string;
  imageUrl?: string;
  dataUrl?: string;
}
const PushMessageListItem: React.FC<PushMessageListItemProps> = ({
  title,
  body,
  imageUrl,
  dataUrl,
}) => {
  return (
    <ListItem sx={{ "&.MuiListItem-root": { border: "1px solid black" } }}>
      <ListItemAvatar>
        <Avatar
          sx={{ "&.MuiAvatar-root": { borderRadius: 0 } }}
          alt="Remy Sharp"
          src={"/" + imageUrl}
        />
      </ListItemAvatar>
      <ListItemText primary={title} secondary={body} />
    </ListItem>
  );
};

export default PushMessageListItem;
