import { Button, Chip, imageListClasses } from "@mui/material";
import React from "react";
import { Rental, CommonUtil as cu } from "../../../../types/elements";
import { RentalResultProps } from "./RentalListView";

interface RentalResultListProps extends RentalResultProps {}
const RentalResultList: React.FC<RentalResultListProps> = ({ list, openDialog, openUser }) => {
  return (
    <div>
      {list.map((r) => (
        <div
          key={r.id}
          style={{ backgroundColor: "#f0f0f0", margin: "0.5rem 0", padding: "0.3rem" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Button size="small" onClick={() => openDialog && r.id && openDialog(r.id)}>
                #{r.id}
              </Button>
            </div>
            <div>
              <Chip size="small" label={r.status} />
            </div>
            <div>{r.created && cu.getFormattedDate(r.created, "yyyy-MM-dd hh:mm")}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 2 }}>
              기간: {r.rental_start}~{r.rental_end} ({r.days}일)
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {r.images?.map((image, imageIdx) => {
              return (
                <div key={imageIdx} style={{ maxWidth: "19%" }}>
                  <img src={image.url} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                </div>
              );
            })}
          </div>
          <div>
            <Button size="small" onClick={() => r.user_id && openUser(r.user_id)}>
              #{r.user_id} {r.user?.name}
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RentalResultList;
