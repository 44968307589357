import { Badge, Button, Divider, MenuItem, MenuList, Paper, Popover } from "@mui/material";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import useAdminUser from "../../../hooks/useAdminUser";
import { AdminAuthState } from "../../../store/states/AdminAuthState";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { gqladm } from "../../../graphql/gqladm";
import { setSessionId, setSessionKey } from "../../../api/Ls";
import TagVersion from "./TagVersion";
import AdminNotiPaper from "./AdminNotiPaper";

interface AccountPopoverBtnProps {}
const AccountPopoverBtn: React.FC<AccountPopoverBtnProps> = (props) => {
  const adminAuthState = useRecoilValue(AdminAuthState);
  const { getAdminAuth } = useAdminUser();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleLogout = () => {
    if (window.confirm("로그아웃 하시겠습니까?"))
      gqladm.logout().then((result) => {
        console.log(result);
        setSessionId("");
        setSessionKey("");
        getAdminAuth();
      });
  };

  const [notiAnchorEl, setNotiAnchorEl] = useState<any>(null);
  const openNoti = Boolean(notiAnchorEl);

  const handleOpenNotification = (e: any) => {
    console.log(e.currentTarget);
    setNotiAnchorEl(e.currentTarget);
  };

  return (
    <>
      <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
        {adminAuthState.admin.name}
        <Badge badgeContent={adminAuthState.admin.unreadNotiCount ?? 0} color={"primary"}>
          <NotificationsNoneIcon fontSize="small" />
        </Badge>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <Paper sx={{ width: "200px" }}>
          <MenuList>
            <MenuItem disabled>{adminAuthState.admin.name}</MenuItem>
            <Divider />
            <MenuItem onClick={handleOpenNotification}>알림목록</MenuItem>
            {/* <AdminNotiPaper /> */}
            <Popover
              open={openNoti}
              anchorEl={notiAnchorEl}
              onClose={() => setNotiAnchorEl(null)}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}>
              <AdminNotiPaper
                onCloseUpper={() => {
                  setNotiAnchorEl(null);
                  setAnchorEl(null);
                }}
              />
            </Popover>
            <Divider />
            <MenuItem disabled>
              <TagVersion />
            </MenuItem>
            <MenuItem onClick={handleLogout}>로그아웃</MenuItem>
          </MenuList>
        </Paper>
      </Popover>
    </>
  );
};

export default AccountPopoverBtn;
