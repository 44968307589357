import { WrapperAxios as Axios } from "./base";

import { CommonUtil, APIResponse, API_PATH, Order, OrderAdminMsg, OrderWaiting } from "../types/elements";
import { convertItem } from "../util/converter";
import { ADMIN_API_PATH } from "../types/adm_elements";

export const OrderAPI = {
  getOrders: (opt: any): Promise<APIResponse<Array<Order>>> => {
    const query = CommonUtil.createQueryString(opt);
    return Axios.get(`/admin${ADMIN_API_PATH.ORDER}${query}`);
  },
  getOrder: (orderId: number): Promise<APIResponse<Order>> => {
    return Axios.get(`/admin${ADMIN_API_PATH.ORDER}/${orderId}`);
  },
  putOrder: (order: Order): Promise<APIResponse<any>> => {
    return Axios.put(`/admin${ADMIN_API_PATH.ORDER}/${order.id}`, order);
  },

  getOrderAdminMsgs: (orderId: number): Promise<APIResponse<OrderAdminMsg[]>> => {
    return Axios.get(`/admin${ADMIN_API_PATH.ORDER}/${orderId}/adminmsg`).then((res) => {
      const data = res.data;
      if (data.result) {
        data.result = data.result.map((c: OrderAdminMsg) => {
          // c.created = c.created ? new Date(c.created) : undefined;
          return c;
        });
      }
      return res;
    });
  },
  postOrderAdminMsg: (oam: OrderAdminMsg): Promise<APIResponse<any>> => {
    return Axios.post(`/admin${ADMIN_API_PATH.ORDER}/${oam.orderId}/adminmsg`, oam);
  },

  getOrderWaitings: (): Promise<APIResponse<OrderWaiting[]>> => {
    return Axios.get(`/admin${ADMIN_API_PATH.ORDER_WAITING}?ts=` + Date.now());
  },
};
