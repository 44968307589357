import React from "react";
import { Order } from "../../../types/elements";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
interface OrderListProps {
  list: Order[];
}
const OrderList: React.FC<OrderListProps> = (props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>Login User</TableCell>
          <TableCell>Order User</TableCell>
          <TableCell>Deliver To</TableCell>
          <TableCell>Address</TableCell>
          <TableCell>Items</TableCell>
          <TableCell>Deliver Msg</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.list.map((o) => {
          return (
            <TableRow>
              <TableCell>{o.id}</TableCell>
              <TableCell>{o.status}</TableCell>
              <TableCell>{o.price}</TableCell>
              <TableCell>
                #{o.user_id} {o.user?.name}
              </TableCell>
              <TableCell>
                {o.username} <br />
                {o.useremail} <br />
                {o.userphone}
              </TableCell>
              <TableCell>
                {o.addr_username} <br />
                {o.addr_phone}
              </TableCell>
              <TableCell>
                {o.addr_zipcode} <br />
                {o.addr_addr1} <br />
                {o.addr_addr2}
              </TableCell>
              <TableCell>
                {o.orderItems?.map((oi) => {
                  return (
                    <div>
                      [#{oi.item_id}-#{oi.item_option_id}] {oi.item?.name} * {oi.count}
                    </div>
                  );
                })}
              </TableCell>
              <TableCell>{o.deliver_msg}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default OrderList;
