import React from "react";
import MobileDeviceList from "./list/MobileDeviceList";

interface MobildDeviceViewProps {}
const MobildDeviceView: React.FC<MobildDeviceViewProps> = (props) => {
  return (
    <div>
      <MobileDeviceList />
    </div>
  );
};

export default MobildDeviceView;
