import React, { useEffect, useState } from "react";
interface FetchImgProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  thumbnail?: string;
  url?: string;
  onLoadUrl?: (loaded: boolean) => any;
}
const HTTP_SUCCESS = [200, 201, 302, 304];
const FetchImg: React.FC<FetchImgProps> = (props) => {
  const inherited = { ...props, url: undefined, onLoadUrl: undefined, thumbnail: undefined };
  const [src, setSrc] = useState<string | undefined>(props.src);
  const [isThumbNail, setIsThumbnail] = useState<boolean>(false);

  const loadUrl = () => {
    return fetch("" + props.url, { method: "GET" })
      .then((result) => {
        if (HTTP_SUCCESS.indexOf(result.status) > -1) {
          setSrc(props.url);
          props.onLoadUrl && props.onLoadUrl(true);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (props.thumbnail) {
      fetch("" + props.thumbnail, { method: "GET" })
        .then((result) => {
          console.log("loaded", result);
          if (HTTP_SUCCESS.indexOf(result.status) > -1) {
            props.onLoadUrl && props.onLoadUrl(true);
            setSrc(props.thumbnail);
            setIsThumbnail(true);
          } else {
            return loadUrl();
          }
        })
        .catch((err) => {
          console.log("error", err);
          return loadUrl();
        });
    } else {
      loadUrl();
    }
  }, [props.url, props.onLoadUrl]);

  return (
    <div style={{ position: "relative" }}>
      {isThumbNail && (
        <span
          style={{
            position: "absolute",
            backgroundColor: "#e0e0e0",
            borderBottomRightRadius: "10px",
            padding: "2px 4px 2px 2px",
            fontSize: "0.5rem",
          }}>
          Thumbnail
        </span>
      )}
      <img {...inherited} src={src} />
    </div>
  );
};
export default FetchImg;
