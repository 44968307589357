import { Button, Chip, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import useSnack from "../../../hooks/useSnack";
import { getKrOrderStatus, Order, OrderStatus, OrderStatusArray } from "../../../types/elements";

interface ChangeStatusDialogProps {
  open: boolean;
  onClose: (isCompleted?: boolean) => any;
  order?: Order;
}
const ChangeStatusDialog: React.FC<ChangeStatusDialogProps> = (props) => {
  const { openSnack } = useSnack();
  const [chgStatus, setChgStatus] = useState<OrderStatus | undefined>();

  const handleChange = () => {
    if (chgStatus === props.order?.status) {
      openSnack({ msg: "변경될 상태가 같습니다. " });
      return;
    }
    if (props.order?.id && chgStatus)
      gqladm.changeOrderStatus(props.order.id, chgStatus).then((result) => {
        if (result.ok) props.onClose(true);
        else openSnack({ msg: "Error  - " + result.msg });
      });
  };
  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogContent>
        주문번호 {props.order?.id}의 현재 상태{" "}
        <Chip label={props.order?.status && getKrOrderStatus(props.order?.status)} />
        에서 아래 어떤 상태로 바꾸시겠습니까?
        <div>
          {OrderStatusArray.map((os) => (
            <Chip
              disabled={props.order?.status === os}
              label={getKrOrderStatus(os)}
              color={chgStatus === os ? "primary" : "default"}
              onClick={() => setChgStatus(os)}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={chgStatus === undefined} variant="contained" onClick={handleChange}>
          수정
        </Button>
        <Button onClick={() => props.onClose()}>취소</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeStatusDialog;
