import {
  Alert,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import useItemMeta from "../../../hooks/useItemMeta";
import useSnack from "../../../hooks/useSnack";
import { OrderItem } from "../../../types/elements";

interface ChangeOrderItemCountDeleteDialogProps {
  open: boolean;
  onClose: (completed?: boolean) => void;
  orderItem: OrderItem;
}
const ChangeOrderItemCountDeleteDialog: React.FC<ChangeOrderItemCountDeleteDialogProps> = (
  props
) => {
  const { getItemMeta } = useItemMeta();
  const { openSnack } = useSnack();
  const oi = props.orderItem;
  const [count, setCount] = useState<string>("1");

  useEffect(() => {
    if (props.open) setCount((props.orderItem.count ?? 1) + "");
  }, [props.open, props.orderItem]);

  const handleDelete = () => {
    if (oi.order_id && oi.item_id && oi.item_option_id)
      gqladm.changeOrderItemCount(oi.order_id, oi.item_id, oi.item_option_id, 0).then((result) => {
        if (result.ok) {
          props.onClose(true);
          openSnack({ msg: "항목이 삭제되었습니다. " });
        } else {
          openSnack({ msg: "Error - " + result.msg, severity: "error" });
        }
      });
  };

  const handleChange = () => {
    console.log("handleChange", oi.order_id, oi.item_id, oi.item_option_id);
    const cnt = Number(count);
    if (!isNaN(cnt) && oi.order_id && oi.item_id && oi.item_option_id)
      gqladm
        .changeOrderItemCount(oi.order_id, oi.item_id, oi.item_option_id, cnt)
        .then((result) => {
          if (result.ok) {
            props.onClose(true);
            openSnack({ msg: "수량이 변경되었습니다. " });
          } else {
            openSnack({ msg: "Error - " + result.msg });
          }
        });
  };

  return (
    <Dialog open={props.open} onClose={() => props.onClose()} fullWidth maxWidth="xl">
      <DialogTitle>주문 상품 수량 수정 or 삭제</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          수정 혹은 삭제될 경우 해당하는 출고 등록정보는 초기화 됩니다.
        </Alert>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>VAT</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Qty</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ fontSize: "0.7rem" }}>#{oi.item_id}</span>
                    {oi.item?.topImages &&
                      oi.item.topImages.map((ti) => (
                        <Avatar src={"/" + ti.url} sx={{ borderRadius: "8px" }} />
                      ))}
                  </div>
                </Box>
                <Box sx={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                  <b>{oi.item?.name}</b>
                  <br />
                  {oi.item?.designer_id
                    ? " - " + getItemMeta("Designer", oi.item.designer_id)?.name
                    : ""}
                </Box>
              </TableCell>
              <TableCell>
                {oi.item?.price ? Math.floor(oi.item.price * (oi.item?.vatAdded ? 0 : 0.1)) : ""}
              </TableCell>
              <TableCell>{oi.item?.price ?? 0}</TableCell>
              <TableCell>
                {oi.item?.price ? Math.floor(oi.item.price * (oi.item?.vatAdded ? 1 : 1.1)) : ""}
              </TableCell>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    whiteSpace: "nowrap",
                    alignItems: "center",
                  }}>
                  {`${oi.count} => `}
                  <TextField
                    style={{ minWidth: "60px" }}
                    size="small"
                    value={count}
                    onChange={(e) => setCount(e.target.value)}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          <div>
            <Button variant="contained" color="secondary" onClick={handleDelete}>
              삭제
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleChange}
              disabled={Number(count) === oi.count}>
              수정
            </Button>
            <Button onClick={() => props.onClose()}>닫기</Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeOrderItemCountDeleteDialog;
