import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { Order, SearchResult } from "../../../types/elements";
import OrderResultList from "../orderList/OrderResultList";
import OrderResultTable from "../orderList/OrderResultTable";

interface OrderedListProps {
  item_id: number;
  onLoaded?: (result: SearchResult<Order>) => void;
}
const OrderedOrderList: React.FC<OrderedListProps> = ({ item_id, onLoaded }) => {
  const { isMobile } = useWindowSize();
  const [list, setList] = useState<Order[]>([]);
  const [noData, setNoData] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    gqladm.getOrders({ hasItemIds: [item_id] }).then((result) => {
      console.log(result);
      if (onLoaded) onLoaded(result);
      if (result.list) setList(result.list);
      if (result.list && result.list.length === 0) setNoData(true);
      console.log(result);
    });
  }, [item_id]);
  return noData ? (
    <div>No Orders</div>
  ) : isMobile ? (
    <OrderResultList list={list} />
  ) : (
    <OrderResultTable list={list} />
  );
};

export default OrderedOrderList;
