import React, { useState } from "react";
import { IconButton, Paper, Popover, TextField } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

interface DatePickerProps {
  value?: any;
  size?: "small";
  onSelect?: (y: number, m: number, d: number) => any;
  disableCondition?: (y: number, m: number, d: number) => any;
}
const DatePicker: React.FC<DatePickerProps> = (props) => {
  const [el, setEl] = useState<HTMLElement | null>(null);
  const open = Boolean(el);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(new Date().getMonth());

  const dayCounts = new Date(year, month + 1, 0).getDate();
  const firstDate = new Date(year, month, 1);
  const firstDay = firstDate.getDay();

  const handleClickCalendar = (d: number) => {
    const dobj = new Date(year, month, d);
    props.onSelect && props.onSelect(dobj.getFullYear(), dobj.getMonth(), dobj.getDate());
    setEl(null);
  };

  return (
    <div>
      <TextField size={props.size} value={props.value} onClick={(e) => setEl(e.currentTarget)} />
      <Popover
        open={open}
        anchorEl={el}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={() => setEl(null)}>
        <Paper>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <IconButton onClick={() => setMonth((m) => m - 1)}>
                <KeyboardArrowLeftIcon />
              </IconButton>
            </div>
            <div>
              {firstDate.getFullYear()}-{firstDate.getMonth() + 1}
            </div>
            <div>
              <IconButton onClick={() => setMonth((m) => m + 1)}>
                <KeyboardArrowRightIcon />
              </IconButton>
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", maxWidth: "350px", gap: "0.3rem 0" }}>
            {Array(
              dayCounts +
                firstDay +
                (7 - ((dayCounts + firstDay) % 7)) +
                (dayCounts + firstDay < 35 ? 7 : 0)
            )
              .fill(1)
              .map((o, i) => {
                if (i < firstDay || i - firstDay + 1 > dayCounts)
                  return (
                    <div key={Date.now() + "" + i} style={{ flex: 1, minWidth: "14%", opacity: 0 }}>
                      <IconButton size="small">0</IconButton>
                    </div>
                  );
                else
                  return (
                    <div
                      style={{
                        flex: 1,
                        minWidth: "14%",
                        display: "flex",
                        justifyContent: "center",
                        //   alignItems: "center",
                      }}>
                      <IconButton
                        size="small"
                        onClick={() => handleClickCalendar(i - firstDay + 1)}>
                        {i - firstDay + 1}
                      </IconButton>
                    </div>
                  );
              })}
          </div>
        </Paper>
      </Popover>
    </div>
  );
};

export default DatePicker;
