import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchedAddress } from "../../../api/PublicAddressAPI";
import { gqladm } from "../../../graphql/gqladm";
import useSnack from "../../../hooks/useSnack";
import { OrderAddress } from "../../../types/elements";
import AddressSelectDialog from "../../organisms/AddressSelectDialog";

interface ChangeAddressDialogProps {
  open: boolean;
  onClose: (completed?: boolean) => void;
  orderAddress?: OrderAddress;
}
const ChangeAddressDialog: React.FC<ChangeAddressDialogProps> = (props) => {
  const { openSnack } = useSnack();
  const [zipcode, setZipcode] = useState<string>("");
  const [addr1, setAddr1] = useState<string>("");
  const [addr2, setAddr2] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [msg, setMsg] = useState<string>("");

  useEffect(() => {
    if (props.open) {
      setZipcode(props.orderAddress?.addr_zipcode ?? "");
      setAddr1(props.orderAddress?.addr_addr1 ?? "");
      setAddr2(props.orderAddress?.addr_addr2 ?? "");
      setUsername(props.orderAddress?.addr_username ?? "");
      setPhone(props.orderAddress?.addr_phone ?? "");
      setMsg(props.orderAddress?.deliver_msg ?? "");
    }
  }, [props.open]);

  const handleSelectAddress = (se: SearchedAddress) => {
    if (se) {
      setZipcode(se.zipNo);
      setAddr1(se.roadAddr);
    }
  };
  const hasChanging =
    props.orderAddress?.addr_zipcode !== zipcode ||
    props.orderAddress?.addr_addr1 !== addr1 ||
    props.orderAddress?.addr_addr2 !== addr2 ||
    props.orderAddress?.addr_username !== username ||
    props.orderAddress?.addr_phone !== phone ||
    props.orderAddress?.deliver_msg !== msg;

  const handleChange = () => {
    if (hasChanging) {
      gqladm
        .changeOrderAddress({
          id: props.orderAddress?.id,
          addr_zipcode: zipcode,
          addr_addr1: addr1,
          addr_addr2: addr2,
          addr_username: username,
          addr_phone: phone,
          deliver_msg: msg,
        })
        .then((result) => {
          if (result.ok) {
            props.onClose(true);
          } else {
            openSnack({ msg: result.msg ?? "에러가 발생하였습니다. " });
          }
        });
    } else {
      openSnack({ msg: "수정된 항목이 없습니다. " });
    }
  };

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>주문 배송 정보 수정</DialogTitle>
      <DialogContent>
        우편번호 {props.orderAddress?.addr_zipcode} =&gt;
        <AddressSelectDialog onSelect={handleSelectAddress}>
          <Button>우편번호 검색</Button>
        </AddressSelectDialog>
        <TextField
          size="small"
          value={zipcode}
          fullWidth
          disabled={props.orderAddress?.addr_zipcode === zipcode}
          error={props.orderAddress?.addr_zipcode !== zipcode}
        />
        주소 {props.orderAddress?.addr_addr1} =&gt;
        <TextField
          size="small"
          value={addr1}
          fullWidth
          disabled={props.orderAddress?.addr_addr1 === addr1}
          error={props.orderAddress?.addr_addr1 !== addr1}
        />
        상세주소 {props.orderAddress?.addr_addr2} =&gt;
        <TextField
          size="small"
          value={addr2}
          onChange={(e) => setAddr2(e.target.value)}
          fullWidth
          error={props.orderAddress?.addr_addr2 !== addr2}
        />
        배송자 성명 {props.orderAddress?.addr_username} =&gt;
        <TextField
          size="small"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          error={props.orderAddress?.addr_username !== username}
        />
        배송자 전화번호 {props.orderAddress?.addr_phone} =&gt;
        <TextField
          size="small"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          fullWidth
          error={props.orderAddress?.addr_phone !== phone}
        />
        배송 메모 {props.orderAddress?.deliver_msg} =&gt;
        <TextField
          size="small"
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
          fullWidth
          error={props.orderAddress?.deliver_msg !== msg}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleChange}>변경</Button>
        <Button onClick={() => props.onClose()}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeAddressDialog;
