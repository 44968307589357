import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import Calendar from "../../molecules/Calendar";
import ReserveSlotDialog from "./ReserveSlotDialog";
import { CommonUtil as cu, ReserveSlot } from "../../../types/elements";

interface ReserveSlotCalendarProps {
  onChange?: (y: number, m: number) => any;
}
const ReserveSlotCalendar: React.FC<ReserveSlotCalendarProps> = (props) => {
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1);

  const [selectedDayNum, setSelectedDayNum] = useState<number | undefined>();
  const onClickDay = (dayNum: number) => {
    setSelectedDayNum(dayNum);
  };

  const [slotDate, setSlotDate] = useState<string[]>([]);

  const handleChangeCalendar = (y: number, m: number) => {
    setYear(y);
    setMonth(m);
  };

  useEffect(() => {
    gqladm.getReserveSlots({ date: `${year}-${cu.lpad(month, 2)}` }).then((result) => {
      setSlotDate(Array.from(new Set(result.map((o) => (o.date ? o.date : "")))));
    });
    props.onChange && props.onChange(year, month);
  }, [year, month]);

  return (
    <div>
      <Calendar
        year={year}
        month={month}
        dayContents={slotDate.map((o) => {
          return { date: o, content: "•" };
        })}
        selectedDayNum={selectedDayNum}
        onClickDay={onClickDay}
        onChange={handleChangeCalendar}
      />
      <ReserveSlotDialog
        open={selectedDayNum !== undefined}
        dayNum={selectedDayNum}
        onClose={() => setSelectedDayNum(undefined)}
      />
    </div>
  );
};

export default ReserveSlotCalendar;
