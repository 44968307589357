import MenuIcon from "@mui/icons-material/Menu";
import { Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../../graphql/gqladm";
import useSnack from "../../../../hooks/useSnack";
import { ConsignmentItem, getThumbUrl, pick } from "../../../../types/elements";
import ColumnDiv from "../../../molecules/ColumnDiv";
import MultiImageUploadButton from "../../../organisms/MultiImageUploadButton";
import useItemMeta from "../../../../hooks/useItemMeta";

interface ConsignmentItemDialogProps {
  open: boolean;
  onClose: (isCompleted?: boolean) => any;
  item?: ConsignmentItem;
}
const ConsignmentItemDialog: React.FC<ConsignmentItemDialogProps> = (props) => {
  const { openSnack } = useSnack();
  const { consignmentCategorys } = useItemMeta();
  const [consignmentItem, setConsignmentItem] = useState<ConsignmentItem | undefined>(undefined);
  const [isCustomCategory, setIsCustomCategory] = useState<boolean>(false);
  const [oprice, setOprice] = useState<string>("");
  const [eprice, setEprice] = useState<string>("");

  useEffect(() => {
    if (props.open && props?.item?.id !== undefined) load(props.item.id);
    else setConsignmentItem(undefined);
  }, [props.item?.id, props.open]);
  const load = (id: number) => {
    gqladm.consignmentItem(id).then((result) => {
      if (result) setConsignmentItem(result);
    });
  };

  const handleChangeCategory = (v: string) => {
    setConsignmentItem((ci) => ({ ...ci, category: v }));
  };
  const handleSelectCategory = (v: string) => {
    console.log("handleSelectCategory", v);
    setConsignmentItem((ci) => ({ ...ci, category: v === "직접입력" ? "" : v }));
    setIsCustomCategory(v === "직접입력");
  };

  const handleChangeFileUpload = (
    temp_id: number,
    status: "ing" | "done" | "fail",
    image_id?: number,
    mi_id?: number
  ) => {
    console.log("handleChangeFileUpload", temp_id, status, image_id, mi_id);
  };

  const handleDeleteImage = (image_id?: number) => {
    console.log("handleDeleteImage", consignmentItem?.id, image_id);
    if (consignmentItem?.id && image_id)
      gqladm.deleteMultiImage("ConsignmentItem", consignmentItem?.id, image_id).then((result) => {
        if (result.ok) {
          openSnack({ msg: "이미지가 삭제되었습니다." });
          consignmentItem.id && load(consignmentItem.id);
        }
      });
  };

  const handleSave = () => {
    console.log("handleSave", consignmentItem);
    if (consignmentItem !== undefined)
      gqladm
        .updateConsignmentItem(
          pick<ConsignmentItem>(consignmentItem, [
            "id",
            "category",
            "designer",
            "brand",
            "count",
            "yom",
            "pop",
            "origin_price",
            "expect_price",
          ])
        )
        .then((result) => {
          if (result.ok) {
            openSnack({ msg: "저장되었습니다." });
            props.onClose(true);
          }
        });
  };

  return (
    <Dialog open={props.open} onClose={() => props.onClose && props.onClose()}>
      <DialogTitle>위탁상품</DialogTitle>
      <DialogContent>
        {consignmentItem === undefined ? (
          <CircularProgress />
        ) : (
          <div>
            id: {consignmentItem?.id}
            <ColumnDiv label="카테고리">
              {!isCustomCategory ? (
                <Select
                  size="small"
                  fullWidth
                  value={consignmentItem.category}
                  onChange={(e) => handleSelectCategory((e.target as any).value)}>
                  {consignmentCategorys.map((cat, catIdx) => (
                    <MenuItem key={catIdx} value={cat}>
                      {cat}
                    </MenuItem>
                  ))}
                  <MenuItem value="직접입력">직접입력</MenuItem>
                </Select>
              ) : (
                <TextField
                  size="small"
                  fullWidth
                  value={consignmentItem.category}
                  onChange={(e) => handleChangeCategory(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton size="small" onClick={() => handleSelectCategory("체어")}>
                          <MenuIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </ColumnDiv>
            <ColumnDiv label="디자이너">
              <TextField
                size="small"
                fullWidth
                placeholder="예) 조지넬슨, George Nelson"
                value={consignmentItem.designer}
                onChange={(e) => setConsignmentItem((ci) => ({ ...ci, designer: e.target.value }))}
              />
            </ColumnDiv>
            <ColumnDiv label="제조사">
              <TextField
                size="small"
                fullWidth
                placeholder="예) 허먼밀러, Herman Miller"
                value={consignmentItem.brand}
                onChange={(e) => setConsignmentItem((ci) => ({ ...ci, brand: e.target.value }))}
              />
            </ColumnDiv>
            <ColumnDiv label="제조년도">
              <TextField
                fullWidth
                size="small"
                placeholder="예) 1970s, 1970년대, 1973년"
                value={consignmentItem.yom}
                onChange={(e) => setConsignmentItem((ci) => ({ ...ci, yom: e.target.value }))}
              />
            </ColumnDiv>
            <ColumnDiv label="상품수량">
              <TextField
                size="small"
                fullWidth
                type="number"
                value={consignmentItem.count}
                onChange={(e) =>
                  setConsignmentItem((ci) => ({ ...ci, count: Number(e.target.value) }))
                }
              />
            </ColumnDiv>
            <ColumnDiv label="상품사진 첨부*(제품당 최소 3장, 최대 10장)">
              <div>
                {consignmentItem?.id === undefined ? (
                  <div></div>
                ) : (
                  <MultiImageUploadButton
                    target="ConsignmentItem"
                    targetId={consignmentItem?.id}
                    onChangeFileUpload={handleChangeFileUpload}>
                    <Button>추가</Button>
                  </MultiImageUploadButton>
                )}
                <div style={{ display: "flex" }}>
                  {consignmentItem?.images?.map((image, image_idx) => {
                    return (
                      <div key={image_idx}>
                        <img width="64px" src={image.url ? getThumbUrl("/" + image.url) : ""} />
                        <IconButton onClick={() => handleDeleteImage(image.id)}>
                          <Delete />
                        </IconButton>
                      </div>
                    );
                  })}
                </div>
              </div>
            </ColumnDiv>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>저장</Button>
        <Button onClick={() => props.onClose()}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsignmentItemDialog;
