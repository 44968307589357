import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { FcmPush } from "../../../types/adm_elements";
import PushMessageListItem from "../../molecules/PushMessageListItem";

interface FcmPushSendDialogProps {
  open: boolean;
  onClose: (isCompleted?: boolean) => void;
  fcmPush: FcmPush;
}
const FcmPushSendDialog: React.FC<FcmPushSendDialogProps> = (props) => {
  const { fcmPush } = props;
  const [leftCount, setLeftCount] = useState<number | undefined>();

  const sentCountRef = useRef<number>(0); // 최대 전송 횟수 10회를 넘지 않도록 설정 값

  const send = () => {
    props.fcmPush.id &&
      sentCountRef.current < 10 &&
      gqladm.sendFcmPush(props.fcmPush.id).then((result) => {
        sentCountRef.current = sentCountRef.current + 1;
        setLeftCount((result.total ?? 0) - (result.count ?? 0));
        if (result.hasNext) send();
        else {
          props.onClose(true);
        }
      });
  };
  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>FCM 메세지 전송</DialogTitle>
      <DialogContent>
        <ListItem>
          <ListItemText>FcmPush ID : {props.fcmPush.id} </ListItemText>
        </ListItem>
        <PushMessageListItem
          title={fcmPush?.title}
          body={fcmPush?.body}
          imageUrl={fcmPush?.imageUrl}
          dataUrl={fcmPush?.dataUrl}
        />
        <ListItem>
          <ListItemText>Left {leftCount} </ListItemText>
        </ListItem>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            sentCountRef.current = 0; // 최대 전송횟수 리셋
            send();
          }}>
          전송
        </Button>
        <Button onClick={() => props.onClose()}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FcmPushSendDialog;
