import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../../graphql/gqladm";
import useSnack from "../../../../hooks/useSnack";
import {
  ConsignmentItem,
  ConsignmentItemStatus,
  ConsignmentItemStatuses,
} from "../../../../types/elements";

interface ConsignmentItemStatusDialogProps {
  open: boolean;
  consignmentItemId: number;
  onClose: (completed?: boolean) => any;
}
const ConsignmentItemStatusDialog: React.FC<ConsignmentItemStatusDialogProps> = (props) => {
  const { openSnack } = useSnack();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [consignmentItem, setConsignmentItem] = useState<ConsignmentItem | undefined>();
  const [changeStatus, setChangeStatus] = useState<ConsignmentItemStatus>("검수 대기");
  useEffect(() => {
    if (props.consignmentItemId && props.consignmentItemId !== -1)
      gqladm
        .consignmentItem(props.consignmentItemId)
        .then((result) => {
          if (result) {
            setConsignmentItem(result);
          }
        })
        .catch((err) => {
          openSnack({ msg: "에러가 발생했습니다. " + err });
        })
        .finally(() => {
          setLoaded(true);
        });
  }, [props.consignmentItemId]);
  const isActive = consignmentItem?.status !== changeStatus;
  const handleSave = () => {
    if (isActive)
      gqladm.updateConsignmentItemStatus(props.consignmentItemId, changeStatus).then((result) => {
        if (result.ok) {
          props.onClose(true);
        }
      });
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        {loaded === false ? (
          <CircularProgress />
        ) : (
          <div>
            <div>ConsignmentItemId: {consignmentItem?.id}</div>
            <div>수정전: {consignmentItem?.status}</div>
            <div>
              수정후:{" "}
              <Select
                size="small"
                value={changeStatus}
                onChange={(e) => setChangeStatus(e.target.value as ConsignmentItemStatus)}>
                {ConsignmentItemStatuses.map((o) => (
                  <MenuItem value={o}>{o}</MenuItem>
                ))}
              </Select>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Change</Button>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsignmentItemStatusDialog;
