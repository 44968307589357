import { Button, CircularProgress, IconButton } from "@mui/material";
import axios from "axios";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { getThumbUrl, Image, CommonUtil as cu, MultiImageTarget } from "../../../types/elements";

import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import { gqladm } from "../../../graphql/gqladm";
import useSnack from "../../../hooks/useSnack";
import { minHeight } from "@mui/system";

const getMiTargetName = (target?: MultiImageTarget) => {
  if (target === "Rental") return "렌탈";
  else if (target === "ConsignmentItem") return "위탁상품";
  else if (target === "RentalCompanyInfo") return "렌탈사업자";
  else return "";
};
interface ImageListItemProps {
  image: Image;
  hideHasThumbnail?: boolean;
}
const ImageListItem: React.FC<ImageListItemProps> = ({ image, hideHasThumbnail }) => {
  const { openSnack } = useSnack();

  const [hasThumbnail, setHasThumbnail] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const imgRef = useRef<HTMLImageElement | null>(null);
  const checkThumbnail = () => {
    setUrl("");
    if (image.url) {
      const thumb_url = "/" + getThumbUrl(image.url);
      axios
        .get(thumb_url)
        .then((result) => {
          if ((result.status = 200)) {
            setUrl(thumb_url);
            setHasThumbnail(true);
          } else {
            setUrl("/" + image.url);
          }
        })
        .catch((err) => {
          setUrl("/" + image.url);
        });
    }
  };
  useEffect(() => {
    checkThumbnail();
  }, [image.url]);

  const handleLoadImage = (e: SyntheticEvent<HTMLImageElement>) => {
    console.log("handleLoadImage", e);
    const target = e.target as any;
    if (target["naturalWidth"] && target["naturalHeight"]) {
      setWidth(target["naturalWidth"] as number);
      setHeight(target["naturalHeight"] as number);
    }
    console.log(e, e.target);
  };

  const handleCreateThumbnail = () => {
    if (
      image.id &&
      ((hasThumbnail && window.confirm("썸네일이 존재하고 있는데 새로 생성하시겠습니까?")) ||
        !hasThumbnail)
    ) {
      gqladm.createImageThumbnail(image.id).then((result) => {
        if (result.ok) checkThumbnail();
        else openSnack({ msg: "에러가 발생했습니다. - 썸네일 생성실패" });
      });
    } else {
      openSnack({ msg: "에러가 발생했습니다. - 현재 이미지확인 요망" });
    }
  };

  return hideHasThumbnail && hasThumbnail ? (
    <></>
  ) : (
    <div
      style={{
        width: "200px",
        padding: "0.5rem",
        backgroundColor: "lightgray",
        borderRadius: "0.5rem",
        margin: "0.5rem",
      }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100px",
        }}>
        {url !== "" ? (
          <img
            ref={imgRef}
            src={url}
            style={{ width: "100%", height: "100% ", objectFit: "contain" }}
            onLoad={handleLoadImage}
          />
        ) : (
          <CircularProgress />
        )}
      </div>

      <div>
        종류:
        {image.itemId && image.itemId > 0 && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => window.open("/item/" + image.itemId, "_blank")}>
            상품 - {image.itemId}
          </Button>
        )}
        {image.itemId === -1 && "Public Image"}
        {image.itemId === -2 &&
          image.mi &&
          `기타-${getMiTargetName(image.mi.target)} - ${image.mi.targetId}`}
      </div>
      {url !== "" && (
        <div>
          해상도 - {width}*{height}{" "}
          <IconButton size="small" color="primary" onClick={handleCreateThumbnail}>
            {hasThumbnail ? <PhotoSizeSelectLargeIcon /> : <PhotoSizeSelectActualIcon />}
          </IconButton>
        </div>
      )}
      <div style={{ fontSize: "0.8rem" }}>
        작성일자:
        {image.modified &&
          image.modified > 10000 &&
          cu.getFormattedDate(image.modified, "yyyy-MM-dd hh:mm")}
      </div>
    </div>
  );
};

export default ImageListItem;
