import {
  Button,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import Pagination from "@mui/material/Pagination";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { gqladm } from "../../graphql/gqladm";
import { SnackStateSelector } from "../../store/states/SnackState";
import { Banner, BannerSearchOption, getFormattedDate } from "../../types/elements";
import BannerDialog from "./BannerDialog";

const LIMIT = 10;
const BannerList: React.FC = (props) => {
  const openSnack = useSetRecoilState(SnackStateSelector);

  const [list, setList] = useState<Banner[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [sopt, setSopt] = useState<BannerSearchOption>({ offset: 0, limit: LIMIT });

  const [open, setOpen] = useState<boolean>(false);
  const handleClosebanner = (completed?: boolean) => {
    setOpen(false);
    if (completed) load(sopt);
  };

  const errorMapper = (err: any) => {
    openSnack({ msg: `에러가 발생했습니다.  ` + err });
  };

  const load = (opt: BannerSearchOption) => {
    return gqladm
      .getBanners({ ...opt })
      .then((result) => {
        if (result.list) setList(result.list);
        if (result.total) setTotal(result.total);
        setSopt({
          ...sopt,
          offset: result.offset,
          limit: result.limit,
        });
        return result.list;
      })
      .catch(errorMapper);
  };
  useEffect(() => {
    load(sopt);
  }, []);

  const handleChangeShow = (banner: Banner) => (e: any, checked: boolean) => {
    console.log("handleChangeShow", e, checked, banner);
    if (banner && banner.id)
      gqladm
        .updateBannerShow(banner.id, checked)
        .then((result) => {
          openSnack({ msg: `배너 show 업데이트가 ${result.ok ? "성공" : "실패"}하였습니다. ` });
          if (result.ok) load(sopt);
        })
        .catch(errorMapper);
  };
  const handleChangeOnlyShowNonLogin = (banner: Banner) => (e: any, checked: boolean) => {
    console.log("handleChangeShow", e, checked, banner);
    if (banner && banner.id)
      gqladm
        .updateBannerOnlyShowNonLogin(banner.id, checked)
        .then((result) => {
          openSnack({
            msg: `배너 OnlyShowNonLogin 업데이트가 ${result.ok ? "성공" : "실패"}하였습니다. `,
          });
          if (result.ok) load(sopt);
        })
        .catch(errorMapper);
  };
  const handleDeleteBanner = (banner: Banner) => () => {
    if (banner && banner.id)
      gqladm
        .removeBanner(banner.id)
        .then((result) => {
          openSnack({ msg: `배너삭제가 ${result.ok ? "성공" : "실패"}하였습니다. ` });
          if (result.ok) load(sopt);
        })
        .catch(errorMapper);
  };

  return (
    <div>
      배너정보 -
      <Button variant="contained" onClick={() => setOpen(true)}>
        입력
      </Button>
      <br />
      <br />
      <br />* url 입력: /~~~ 형태로 입력 가능 현재 사이트의 하위메뉴로 이동 (ex, /reservation 입력시
      예약창으로 이동)
      <br />* 현재 show 상태인 가장 최근 생성된 배너만 적용
      <BannerDialog open={open} onClose={handleClosebanner} />
      <Pagination></Pagination>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>created</TableCell>
            <TableCell>show</TableCell>
            <TableCell>Only Show Non-Login</TableCell>
            <TableCell>type</TableCell>
            <TableCell>msg</TableCell>
            <TableCell>url</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((banner) => {
            return (
              <TableRow>
                <TableCell>{banner.id}</TableCell>
                <TableCell>
                  {banner.created && getFormattedDate(banner.created, "yyyy-MM-dd HH:mm")}
                </TableCell>
                <TableCell>
                  <Switch checked={banner.show} onChange={handleChangeShow(banner)} />
                </TableCell>
                <TableCell>
                  <Switch
                    checked={banner.onlyShowNonLogin}
                    onChange={handleChangeOnlyShowNonLogin(banner)}
                  />
                </TableCell>
                <TableCell>{banner.type}</TableCell>
                <TableCell>{banner.msg}</TableCell>
                <TableCell>{banner.url}</TableCell>
                <TableCell>
                  <IconButton onClick={handleDeleteBanner(banner)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default BannerList;
