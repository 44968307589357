import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import useItemMeta from "../../../hooks/useItemMeta";
import {
  getKrOrderStatus,
  Item,
  Order,
  OrderItem,
  OrderStatusArray,
  User,
  CommonUtil as cu,
  getThumbUrl,
} from "../../../types/elements";
import ColumnDiv from "../../molecules/ColumnDiv";
import AddressFormDialog from "../../organisms/AddressFormDialog";
import SelectItemDialog from "../../organisms/SelectItemDialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SelectUserDialog from "../../organisms/SelectUserDialog";
import useSnack from "../../../hooks/useSnack";
import CircleCounter from "../../atoms/CircleCounter";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import NewOrderItemListItem from "./NewOrderItemListItem";
import { useNavigate } from "react-router-dom";

interface NewOrderDialogProps {
  open: boolean;
  onClose: () => any;
  user_id?: number;
}
const NewOrderDialog: React.FC<NewOrderDialogProps> = (props) => {
  const navi = useNavigate();
  const { openSnack } = useSnack();

  const isSavingRef = useRef<boolean>(false);
  const [order, setOrder] = useState<Order>({
    type: "Offline",
    status: "INITIALIZED",
    orderItems: [],
    paytype: "credit",
    receipt_target: "personal",
    price: 0,
    deliver_msg: "",
    addr_addr1: "",
    addr_addr2: "",
    addr_zipcode: "",
  });
  const [user, setUser] = useState<User | undefined>(undefined);
  useEffect(() => {
    if (props.user_id) {
      gqladm.getUsers({ id: props.user_id, orderby: "created_desc", limit: 10 }).then((result) => {
        if (result.list && result.list.length === 1) {
          const _user = result.list[0];
          setUser(_user);
          setOrder((or) => ({
            ...or,
            addr_addr1: _user.addr1,
            addr_addr2: _user.addr2,
            addr_zipcode: _user.zipcode,
            user_id: _user.id,
            username: _user.name,
            userphone: _user.phone,
            useremail: _user.email,
          }));
        }
      });
    }
  }, [props.user_id]);

  // OpenAddressForm
  const [openAddressForm, setOpenAddressForm] = useState<boolean>(false);
  const handleOpenAddressForm = () => {
    setOpenAddressForm(true);
  };
  const handleSetAddress = (addr1: string, addr2: string, zipcode: string) => {
    setOrder((or) => ({ ...or, addr_addr1: addr1, addr_addr2: addr2, addr_zipcode: zipcode }));
  };

  // 배송자 정보
  const [isSameUser, setSameUser] = useState<boolean>(false);
  const handleClickIsSameUser = (e: any, v: boolean) => {
    setSameUser(v);
    if (v) {
      setOrder((or) => ({ ...or, addr_username: user?.name, addr_phone: user?.phone }));
    }
  };

  //  SelectItemDialog
  const [openSelectItem, setOpenSelectItem] = useState<boolean>(false);
  const handleSelectItem = (item: Item) => {
    setOrder((or) => {
      const orderItems = or.orderItems ?? [];
      const itemOptions = item.itemOptions ?? [];
      const preAdded = orderItems.filter(
        (oi) => itemOptions.map((o) => o.id).indexOf(oi.item_option_id) > -1
      );
      if (preAdded && preAdded?.length > 0) return or;
      const ord: Order = {
        ...or,
        orderItems: [
          ...(or.orderItems ?? []),
          {
            item_id: item.id,
            item_option_id: item.defaultItemOptionId,
            item: item,
            itemOption: itemOptions.find((io) => io.id === item.defaultItemOptionId),
            count: 1,
          },
        ],
      };
      //   가격계산
      ord.price = Math.floor(
        (ord.orderItems ?? [])
          .map((o) => (o.item?.price ?? 0) * (o.item?.vatAdded ? 1 : 1.1) * (o.count ?? 0))
          .reduce((p, n) => p + n, 0)
      );
      console.log(or.orderItems);
      return ord;
    });
    setOpenSelectItem(false);
  };

  const handleModifyItemCount = (idx: number, diff: number) => {
    setOrder((or) => {
      if (or.orderItems && or.orderItems.length > idx) {
        const item = or.orderItems[idx];
        or.orderItems[idx] = { ...or.orderItems[idx], count: (item.count ?? 0) + diff };
        or.orderItems = [...or.orderItems];
      }
      or.price = (or.orderItems ?? [])
        .map((o) => (o.item?.price ?? 0) * (o.item?.vatAdded ? 1 : 1.1) * (o.count ?? 0))
        .reduce((p, n) => p + n, 0);
      return { ...or };
    });
  };

  const handleSave = () => {
    console.log("handleSave", order);
    if (!order.user_id) {
      openSnack({ msg: "유저가 선택되지 않았습니다. " });
      return;
    }
    if (!order.addr_zipcode || !order.addr_addr1 || !order.addr_addr2) {
      openSnack({ msg: "주소가 제대로 입력되지 않았습니다." });
      return;
    }
    if ((order.orderItems ?? []).length === 0) {
      openSnack({ msg: "주문 상품이 없습니다." });
      return;
    }
    if (
      order.receipt_target === "company" &&
      (!order.receipt_c_bn || !order.receipt_c_email || !order.receipt_c_num || !order.receipt_c_rn)
    ) {
      openSnack({ msg: "사업자 정보가 부족합니다." });
      return;
    }
    // if (order.receipt_target === "personal" && !order.receipt_number) {
    //   openSnack({ msg: "현금영수증 정보가 부족합니다." });
    //   return;
    // }
    if (!order.receipt_target) {
      openSnack({ msg: "사업자 및 현금영수증을 선택해주세요" });
      return;
    }

    const obj: Order = {
      user_id: order.user_id,
      username: order.username,
      userphone: order.userphone,
      useremail: order.useremail,

      addr_zipcode: order.addr_zipcode,
      addr_addr1: order.addr_addr1,
      addr_addr2: order.addr_addr2,
      addr_username: order.addr_username,
      addr_phone: order.addr_phone,
      deliver_msg: order.deliver_msg,

      status: order.status,
      price: order.price,
      paytype: order.paytype,
      cash_receipt: order.cash_receipt,
      receipt_target: order.receipt_target,
      receipt_number: order.receipt_number,

      receipt_c_num: order.receipt_c_num,
      receipt_c_bn: order.receipt_c_bn,
      receipt_c_rn: order.receipt_c_rn,
      receipt_c_email: order.receipt_c_email,

      admin_msg: order.admin_msg,

      orderItems: order?.orderItems?.map(
        (oi) =>
          ({
            item_id: oi.item_id,
            item_option_id: oi.item_option_id,
            count: oi.count,
          } as OrderItem)
      ),
    };

    if (isSavingRef.current === true) {
      alert(
        "주문 정보가 저장중입니다. 시간이 지나도 화면변화가 없다면 새로 고침후 다시 수행 바랍니다. "
      );
    }
    isSavingRef.current = true;
    gqladm.addOfflineOrder(obj).then((result) => {
      console.log(result);
      if (result.ok) {
        openSnack({ msg: "주문이 생성되었습니다. " });
        navi("/order/" + result.id);
      } else if (result.msg) {
        openSnack({ msg: "Error " + result.msg, severity: "error" });
        isSavingRef.current = false;
      }
    });
  };

  const [openUserSelect, setOpenUserSelect] = useState<boolean>(false);
  const handleSelectUser = (user: User) => {
    setUser(user);
    setOrder((o) => ({
      ...o,
      user_id: user.id,
      username: user.name,
      useremail: user.email,
      userphone: user.phone,
    }));
    setOpenUserSelect(false);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="xl" fullScreen>
      <DialogTitle>신규주문생성</DialogTitle>
      <DialogContent>
        <div>
          <ColumnDiv label="유저">
            <div style={{ border: "1px solid black", padding: "1rem" }}>
              <div>이름: {user?.name}</div>
              <div>전화: {user?.phone}</div>
              <div>이메일: {user?.email}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <Button size="small" onClick={() => setOpenUserSelect(true)}>
                유저 변경
              </Button>
              <SelectUserDialog
                open={openUserSelect}
                onClose={() => setOpenUserSelect(false)}
                onSelect={handleSelectUser}
              />
            </div>
          </ColumnDiv>
          <hr />
          <ColumnDiv label="ID">
            <TextField value={"ID Will be generated."} disabled size="small" />
          </ColumnDiv>
          <ColumnDiv label="Type">
            <TextField value={order.type} disabled size="small" />
          </ColumnDiv>
          <ColumnDiv label="Status">
            <Select value={order.status} size="small">
              {OrderStatusArray.map((os, osIdx) => (
                <MenuItem key={osIdx} value={os}>
                  {getKrOrderStatus(os)}
                </MenuItem>
              ))}
            </Select>
          </ColumnDiv>
          <ColumnDiv
            label={
              <div style={{ padding: "0.5rem" }}>
                주소
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleOpenAddressForm}>
                  주소입력
                </Button>
                <AddressFormDialog
                  open={openAddressForm}
                  onClose={() => setOpenAddressForm(false)}
                  setAddress={handleSetAddress}
                />
              </div>
            }>
            <div style={{ border: "1px solid lightgray", padding: "1rem" }}>
              <div>주소1: {order.addr_addr1}</div>
              <div>주소2: {order.addr_addr2}</div>
              <div>우편번호: {order.addr_zipcode}</div>
            </div>
          </ColumnDiv>
          <ColumnDiv
            label={
              <div
                style={{
                  paddingLeft: "0.5rem",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                배송
                <FormControlLabel
                  label="회원정보와 동일"
                  control={
                    <Checkbox
                      checked={isSameUser}
                      size="small"
                      color="primary"
                      onChange={handleClickIsSameUser}
                    />
                  }
                />
              </div>
            }>
            <div style={{ border: "1px solid lightgray", padding: "1rem" }}>
              <div style={{ display: "flex", whiteSpace: "nowrap", alignItems: "center" }}>
                전화:{" "}
                <TextField
                  disabled={isSameUser}
                  value={order.addr_phone}
                  size="small"
                  fullWidth
                  onChange={(e) => setOrder((or) => ({ ...or, addr_phone: e.target.value }))}
                />
              </div>
              <div style={{ display: "flex", whiteSpace: "nowrap", alignItems: "center" }}>
                이름:{" "}
                <TextField
                  disabled={isSameUser}
                  value={order.addr_username}
                  size="small"
                  fullWidth
                  onChange={(e) => setOrder((or) => ({ ...or, addr_username: e.target.value }))}
                />
              </div>
            </div>
          </ColumnDiv>

          <ColumnDiv label="메모">
            <TextField
              size="small"
              fullWidth
              multiline
              minRows={3}
              value={order.deliver_msg}
              onChange={(e) => setOrder((or) => ({ ...or, deliver_msg: e.target.value }))}
            />
          </ColumnDiv>
          <ColumnDiv label="메모(관리자)">
            <TextField
              size="small"
              fullWidth
              multiline
              minRows={3}
              value={order.admin_msg}
              onChange={(e) => setOrder((or) => ({ ...or, admin_msg: e.target.value }))}
            />
          </ColumnDiv>
          <ColumnDiv
            label={
              <div>
                주문항목
                <Button size="small" variant="contained" onClick={() => setOpenSelectItem(true)}>
                  상품 선택
                </Button>
                <SelectItemDialog
                  open={openSelectItem}
                  onClose={() => setOpenSelectItem(false)}
                  onSelect={handleSelectItem}
                />
              </div>
            }>
            <List>
              {order.orderItems?.map((oi, idx) => (
                <NewOrderItemListItem
                  key={idx}
                  oi={oi}
                  updateCount={(num) => {
                    setOrder((or) => {
                      const orderItems =
                        or.orderItems?.map((oroi) => ({
                          ...oroi,
                          count: oroi.item_id === oi.item_id ? num : oroi.count,
                        })) ?? [];
                      const price = Math.floor(
                        orderItems
                          .map(
                            (o) =>
                              (o?.item?.price ?? 0) * (o?.item?.vatAdded ? 1 : 1.1) * (o.count ?? 0)
                          )
                          .reduce((p, n) => p + n, 0)
                      );
                      return {
                        ...or,
                        orderItems,
                        price,
                      };
                    });
                  }}
                  delete={() => {
                    setOrder((or) => {
                      const orderItems = or.orderItems?.filter((oroi) => oroi.id !== oi.id) ?? [];
                      const price = Math.floor(
                        orderItems
                          .map(
                            (o) =>
                              (o?.item?.price ?? 0) * (o?.item?.vatAdded ? 1 : 1.1) * (o.count ?? 0)
                          )
                          .reduce((p, n) => p + n, 0)
                      );
                      return { ...or, orderItems, price };
                    });
                  }}
                />
              ))}
            </List>
          </ColumnDiv>
          <ColumnDiv label="결제방법">
            <FormControlLabel
              onClick={() => setOrder((or) => ({ ...or, paytype: "credit" }))}
              label="카드결제"
              control={<Radio checked={order.paytype === "credit"} />}
            />
            <FormControlLabel
              onClick={() => setOrder((or) => ({ ...or, paytype: "direct" }))}
              label="무통장입금"
              control={<Radio checked={order.paytype === "direct"} />}
            />
          </ColumnDiv>
          <ColumnDiv label="사업자 및 현금영수증 정보">
            <div>
              <FormControlLabel
                onClick={() => setOrder((or) => ({ ...or, receipt_target: "personal" }))}
                label="현금영수증"
                control={<Radio checked={order.receipt_target === "personal"} />}
              />
              <FormControlLabel
                onClick={() => setOrder((or) => ({ ...or, receipt_target: "company" }))}
                label="세금계산서"
                control={<Radio checked={order.receipt_target === "company"} />}
              />
            </div>
            {order.receipt_target === "personal" ? (
              <div>
                <TextField
                  label="휴대전화"
                  value={order.receipt_number}
                  onChange={(e) => setOrder((or) => ({ ...or, receipt_number: e.target.value }))}
                />
              </div>
            ) : order.receipt_target === "company" ? (
              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
                <TextField
                  label="사업자번호"
                  value={order.receipt_c_num}
                  onChange={(e) => setOrder((or) => ({ ...or, receipt_c_num: e.target.value }))}
                />
                <TextField
                  label="상호명"
                  value={order.receipt_c_bn}
                  onChange={(e) => setOrder((or) => ({ ...or, receipt_c_bn: e.target.value }))}
                />
                <TextField
                  label="대표자명"
                  value={order.receipt_c_rn}
                  onChange={(e) => setOrder((or) => ({ ...or, receipt_c_rn: e.target.value }))}
                />
                <TextField
                  label="이메일주소"
                  value={order.receipt_c_email}
                  onChange={(e) => setOrder((or) => ({ ...or, receipt_c_email: e.target.value }))}
                />
              </div>
            ) : (
              ""
            )}
          </ColumnDiv>
        </div>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <div>총 금액: ₩ {cu.numberWithCommas(order.price)}</div>
          <div>
            <Button onClick={handleSave}>저장</Button>
            <Button onClick={props.onClose}>취소</Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default NewOrderDialog;
