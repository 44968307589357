import Axios from "axios";

import { APIResponse, Log } from "../types/elements";
import { ReqSmsBody } from "../types/adm_elements";
import {
  ResSmsAligoRemain,
  ResSmsAligoList,
  ReqSmsAligoList,
  ReqSmsAligoSmsList,
  ResSmsAligoSmsList,
} from "../types/adm_elements";

export const SmsAPI = {
  // getLogs: (): Promise<APIResponse<Log[]>> => {
  //     return Axios.get("/admin/log");
  // },
  postSms(message: string, userEmails: string[], phoneNumbers: string[]): Promise<APIResponse<any>> {
    const body: ReqSmsBody = {
      smsMsg: message,
      users: userEmails,
      phoneNumbers: phoneNumbers,
    };
    return Axios.post("/admin/sms", body);
  },
  getSmsRemain(): Promise<APIResponse<ResSmsAligoRemain>> {
    return Axios.get("/admin/sms/remain");
  },
  getSmsHistory(opt: ReqSmsAligoList): Promise<APIResponse<ResSmsAligoList>> {
    console.log(opt);
    const _opt: any = { ...opt };
    const queries = Object.keys(opt)
      .map((k: string) => (_opt[k] !== undefined ? k + "=" + _opt[k] : undefined))
      .filter((o) => o)
      .join("&");
    return Axios.get("/admin/sms/list?" + queries);
  },
  getSmsDetail(opt: ReqSmsAligoSmsList): Promise<APIResponse<ResSmsAligoSmsList>> {
    const page = opt.page ? opt.page : 1;
    const page_sise = opt.page_size ? opt.page_size : 30;
    return Axios.get("/admin/sms/list/" + opt.mid + "?page=" + page + "&page_size=" + page_sise);
  },
};
