import React, { createRef, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  Select,
  TextField,
} from "@mui/material";
import { gqladm } from "../../../graphql/gqladm";
import useItemMeta from "../../../hooks/useItemMeta";
import {
  getKrOrderStatus,
  Item,
  Order,
  OrderItem,
  OrderStatusArray,
  User,
  CommonUtil as cu,
  getThumbUrl,
} from "../../../types/elements";
import ColumnDiv from "../../molecules/ColumnDiv";
import AddressFormDialog from "../../organisms/AddressFormDialog";
import SelectItemDialog from "../../organisms/SelectItemDialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SelectUserDialog from "../../organisms/SelectUserDialog";
import useSnack from "../../../hooks/useSnack";
import CircleCounter from "../../atoms/CircleCounter";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

interface NewOrderItemListItemProps {
  oi: OrderItem;
  updateCount: (num: number) => any;
  delete: () => any;
}
const NewOrderItemListItem: React.FC<NewOrderItemListItemProps> = (props) => {
  const { getItemMeta } = useItemMeta();

  const { oi } = props;
  const { item } = oi;
  const oneImage = item?.topImages && item?.topImages.length > 0 ? item.topImages[0] : undefined;

  const [moreRef, setMoreRef] = useState<HTMLElement | null>(null);

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar
          src={oneImage?.url ? getThumbUrl("/" + oneImage.url) : ""}
          style={{ borderRadius: "4px" }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={`[${oi.item_id}] ${item?.name} - ${
          getItemMeta("Designer", item?.designer_id)?.name
        }`}
        secondary={
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              ₩{item?.price && cu.numberWithCommas(item?.price)}
              {item?.vatAdded ? "부가세 포함됨" : ""}
              x
              <CircleCounter num={oi.count ?? 1} set={(num) => props.updateCount(num)} />
              {item?.price && oi.count && oi.count > 1 && (
                <>
                  <ArrowForwardOutlinedIcon fontSize="small" /> ₩
                  {cu.numberWithCommas(item.price * oi.count)}
                </>
              )}
            </div>
          </>
        }
      />
      <IconButton size="small" onClick={(e) => setMoreRef(e.currentTarget)}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu anchorEl={moreRef} open={Boolean(moreRef)} onClose={() => setMoreRef(null)}>
        <MenuItem disabled>재고: {item?.stock_avail} </MenuItem>
        <Divider />
        <MenuItem onClick={() => props.delete()}>제거</MenuItem>
      </Menu>
    </ListItem>
  );
};

export default NewOrderItemListItem;
