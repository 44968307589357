import { Delete } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../../../graphql/gqladm";
import { User, getFormattedDate } from "../../../../../types/elements";

const SimpleTr: React.FC<{ title?: string; body?: any }> = (props) => {
  return (
    <tr>
      <td style={{ whiteSpace: "nowrap" }}>{props.title}&nbsp;</td>
      <td style={{ wordBreak: "break-all" }}>{props.body}</td>
    </tr>
  );
};

interface UserInfoAccountInfoProps {
  user_id: number;
}
const UserInfoAccountInfo: React.FC<UserInfoAccountInfoProps> = (props) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  useEffect(() => {
    if (props.user_id) {
      gqladm.getUsers({ id: props.user_id, orderby: "created_desc", limit: 10 }).then((result) => {
        if (result.list && result.list.length === 1) setUser(result.list[0]);
      });
    }
  }, [props.user_id]);

  return (
    <div className="simple1panel" style={{ minWidth: "320px", flex: 1 }}>
      <div className="simple1panel_head">Account Information</div>
      <div className="simple1panel_body">
        {user === undefined ? (
          <CircularProgress />
        ) : (
          <div>
            <table>
              <tbody>
                <SimpleTr title="ID" body={user.id} />
                <SimpleTr />
                <SimpleTr
                  title="회원가입"
                  body={user.created ? getFormattedDate(user.created, "yyyy-MM-dd hh:mm") : ""}
                />
                <SimpleTr
                  title="최근 로그인"
                  body={
                    user.sessionExpired && getFormattedDate(user.sessionExpired, "yyyy-MM-dd hh:mm")
                  }
                />
                <SimpleTr />
                <SimpleTr title="이름" body={user.name} />
                <SimpleTr title="이메일" body={user.email} />
                <SimpleTr title="전화번호" body={user.phone} />
                <SimpleTr />
                <SimpleTr title="주소1" body={user.addr1} />
                <SimpleTr title="주소2" body={user.addr2} />
                <SimpleTr title="우편번호" body={user.zipcode} />
                <SimpleTr />
                <SimpleTr title="마케팅 정보 수신동의" />
                <SimpleTr title="E-mail" body={user.agreeEmailDate} />
                <SimpleTr title="SMS" body={user.agreeSMSDate} />
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserInfoAccountInfo;
