import { atom } from "recoil";
import { AdminUser } from "../../types/adm_elements";

interface AdminAuthStateIF {
  loaded: boolean;
  admin: AdminUser;
}

export const INIT_ADMIN_AUTH_STATE: AdminAuthStateIF = {
  loaded: false,
  admin: {},
};

export const AdminAuthState = atom<AdminAuthStateIF>({
  key: "AdminAuthState",
  default: INIT_ADMIN_AUTH_STATE,
});

// export const getAuth = (get: AdminAuthStateIF, set: SetterOrUpdater<AdminAuthStateIF>) => {
//   return AuthAPI.getAuth()
//     .then((result) => {
//       const data = result.data;
//       if (data.status === "SUCCESS") {
//         set({ loaded: true, admin: { ...data.result } });
//       } else {
//         set({ loaded: true, admin: {} });
//       }
//       return data.result;
//     })
//     .catch((err) => {
//       console.log("ERROR");
//       set({ loaded: true, admin: {} });
//     });
// };
