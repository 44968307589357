import React, { useEffect, useState } from "react";
import { Button, IconButton, TextField } from "@mui/material";
import { FlexDirCol, FlexBetween } from "../../molecules/Layout";
import SearchIcon from "@mui/icons-material/Search";
import {
  CommonUtil as cu,
  Reservation,
  ReservationSearchOption,
  SearchResult,
} from "../../../types/elements";
import { gqladm } from "../../../graphql/gqladm";
import useSnack from "../../../hooks/useSnack";

interface ReserveSearchProps {
  onSearched: (result: SearchResult<Reservation>, sopt: ReservationSearchOption) => any;
}
const ReserveSearch: React.FC<ReserveSearchProps> = (props) => {
  const { openSnack } = useSnack();
  const [sopt, setSopt] = useState<ReservationSearchOption>({ limit: 20, offset: 0 });

  useEffect(() => {
    const now = new Date();
    setSopt({ ...sopt, date: cu.getFormattedDate(now, "yyyy-MM-dd") });
    handleQueryOverToday();
  }, []);

  const handleChangeName = (e: any) => setSopt({ ...sopt, name: e.target.value });
  const handleChangePhone = (e: any) => setSopt({ ...sopt, phone: e.target.value });
  const handleChangeDate = (e: any) => setSopt({ ...sopt, date: e.target.value });

  const handleQuery = (target: string) => () => {
    console.log("handleQuery", target, sopt);
    const _sopt: any = { limit: 20, offset: 0 };
    _sopt[target] = (sopt as any)[target];
    if (!_sopt[target]) {
      openSnack({ msg: "조회 값이 없습니다" });
      return;
    }
    gqladm.searchReservation(_sopt).then((result) => {
      props.onSearched(result, _sopt);
    });
  };

  const handleQueryOverToday = () => {
    const _sopt = { overToday: true, limit: 20 };
    gqladm.searchReservation(_sopt).then((result) => {
      props.onSearched(result, _sopt);
    });
  };

  return (
    <FlexDirCol>
      <FlexBetween
        left={
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search By 이름"
            value={sopt.name}
            onChange={handleChangeName}
          />
        }
        right={
          <IconButton onClick={handleQuery("name")}>
            <SearchIcon />
          </IconButton>
        }
      />
      <FlexBetween
        left={
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search By 전화번호"
            value={sopt.phone}
            onChange={handleChangePhone}
          />
        }
        right={
          <IconButton onClick={handleQuery("phone")}>
            <SearchIcon />
          </IconButton>
        }
      />
      <FlexBetween
        left={
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search By 방문일"
            type="date"
            value={sopt.date}
            onChange={handleChangeDate}
          />
        }
        right={
          <IconButton onClick={handleQuery("date")}>
            <SearchIcon />
          </IconButton>
        }
      />
      <Button fullWidth variant="contained" onClick={handleQueryOverToday}>
        <SearchIcon /> 오늘 이후 데이터 조회
      </Button>
    </FlexDirCol>
  );
};

export default ReserveSearch;
