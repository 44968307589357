import React, { useState, useEffect } from "react";

import { OrderWaiting, OrderWaitingSearchOption } from "../../types/elements";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { gqladm } from "../../graphql/gqladm";
// import { Pagination } from "@mui/lab";
import Pagination from "@mui/material/Pagination";
import useSnack from "../../hooks/useSnack";
import CreateOrderByOwDialog from "../organisms/CreateOrderByOwDialog";
import MerchantChecker from "./payments/MerchantChecker";
import { useAppCommon } from "../../hooks/useAppCommon";

const LIMIT = 20;
interface OrderWaitingListProps {}
const OrderWaitingListView: React.FC<OrderWaitingListProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "주문 대기 정보" });

  const { openSnack } = useSnack();

  const [sopt, setSopt] = useState<OrderWaitingSearchOption>({ limit: LIMIT, offset: 0 });
  const [list, setList] = useState<OrderWaiting[]>([]);
  const [total, setTotal] = useState<number>(0);

  const load = (opt: OrderWaitingSearchOption) => {
    gqladm.getOrderWaitings({ ...opt }).then((result) => {
      console.log("getOrderWaitings", result);
      if (result.list) setList(result.list);
      if (result.total) setTotal(result.total);
      setSopt({
        ...sopt,
        limit: result.limit !== undefined ? result.limit : sopt.limit,
        offset: result.offset !== undefined ? result.offset : sopt.offset,
      });
    });
  };

  useEffect(() => {
    load(sopt);
  }, []);

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  const [owIdForCreateOrder, setOwIdForCreateOrder] = useState<number | undefined>();
  const handleClickCreateOrderByOrderWaiting = (owid?: number) => {
    console.log("handleClickCreateOrderByOrderWaiting", owid);
    if (!owid) {
      openSnack({ msg: "No OrderWaiting Id" });
      return;
    }
    setOwIdForCreateOrder(owid);
  };

  return (
    <div>
      <div>
        <Pagination
          onChange={handleChangePage}
          count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
          page={Math.ceil((sopt.offset ? sopt.offset : 0) / (sopt.limit ? sopt.limit : LIMIT)) + 1}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>User</TableCell>
            <TableCell>m_id </TableCell>
            <TableCell>Deliver msg</TableCell>
            <TableCell>Items</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Created</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {list.map((ow, ow_idx) => {
            return (
              <TableRow key={ow_idx}>
                <TableCell>{ow.id}</TableCell>
                <TableCell>
                  {ow.useremail}
                  <br />
                  {ow.username}
                  <br />
                  {ow.userphone}
                </TableCell>
                <TableCell>
                  {ow.m_id}
                  {ow.m_id && <MerchantChecker m_id={ow.m_id} />}
                </TableCell>
                <TableCell>{ow.deliver_msg}</TableCell>
                <TableCell>
                  {ow.orderWaitingItems &&
                    ow.orderWaitingItems.map((owi, item_idx) => {
                      return (
                        <div key={item_idx}>
                          [#{owi.item_id}-opt#{owi.item_option_id}] {owi.item?.name}{" "}
                          {owi.item?.price} * {owi.count}
                        </div>
                      );
                    })}
                </TableCell>
                <TableCell>{ow.price}</TableCell>
                <TableCell>{ow.created ? new Date(ow.created).toLocaleString() : ""}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => handleClickCreateOrderByOrderWaiting(ow.id)}>
                    Create Order
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <CreateOrderByOwDialog
        open={owIdForCreateOrder !== undefined}
        onClose={() => setOwIdForCreateOrder(undefined)}
        owid={owIdForCreateOrder}
      />
    </div>
  );
};

export default OrderWaitingListView;
