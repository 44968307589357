import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Reservation,
  ReservationSearchOption,
  getReservationStatusKr,
} from "../../../../types/elements";
import { Button, Pagination } from "@mui/material";
import { gqladm } from "../../../../graphql/gqladm";

interface UserDetailReservationProps {
  user_id: number;
}
const UserDetailReservation: React.FC<UserDetailReservationProps> = (props) => {
  const navi = useNavigate();
  const [sopt, setSopt] = useState<ReservationSearchOption>({ limit: 10 });
  const [list, setList] = useState<Reservation[]>([]);
  const [total, setTotal] = useState<number>(0);

  const load = (sopt: ReservationSearchOption) => {
    if (props.user_id) {
      gqladm.searchReservation({ ...sopt, user_id: props.user_id }).then((result) => {
        if (result.list) setList(result.list);
        if (result.total !== undefined) setTotal(result.total);
        setSopt((so) => ({ ...so, offset: result.offset }));
      });
    }
  };

  useEffect(() => {
    load(sopt);
  }, [props.user_id]);

  const handleChangePage = (page: number) => {
    console.log("handleChangePage", page);
    const offset = (page - 1) * (sopt.limit ? sopt.limit : 10);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  return (
    <div style={{ padding: "1rem" }}>
      <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
        <span>total: {total}</span>
        <Pagination
          onChange={(e, p) => handleChangePage(p)}
          count={Math.ceil((total ? total : 0) / (sopt.limit ? sopt.limit : 10))}
          page={Math.ceil((sopt?.offset ? sopt.offset : 0) / (sopt?.limit ? sopt.limit : 10)) + 1}
        />
      </div>

      <div style={{ overflow: "auto" }}>
        <table className="grayStyle" cellSpacing={0}>
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ textAlign: "center" }}>상태</th>
              <th style={{ textAlign: "center" }}>날짜</th>
              <th style={{ textAlign: "center" }}>시간</th>
              <th style={{ textAlign: "center" }}>인원</th>
              <th style={{ textAlign: "center" }}>이름</th>
              <th style={{ textAlign: "center" }}>전화번호</th>
              <th style={{ textAlign: "center" }}>메모</th>
            </tr>
          </thead>
          <tbody>
            {list.map((reservation, idx) => {
              return (
                <tr>
                  <td>
                    <Button onClick={() => navi("/reservation/" + reservation.id)}>
                      {reservation.id}
                    </Button>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {reservation?.status && getReservationStatusKr(reservation.status)}
                  </td>

                  <td style={{ textAlign: "center" }}>{reservation.date}</td>
                  <td style={{ textAlign: "center" }}>{reservation.time}</td>
                  <td style={{ textAlign: "center" }}>{reservation.memberCount}</td>
                  <td style={{ textAlign: "center" }}>{reservation.name}</td>
                  <td style={{ textAlign: "center" }}>{reservation.phone}</td>
                  <td style={{ textAlign: "center" }}>{reservation.memo}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
        <span>total: {total}</span>
        <Pagination
          onChange={(e, p) => handleChangePage(p)}
          count={Math.ceil((total ? total : 0) / (sopt.limit ? sopt.limit : 10))}
          page={Math.ceil((sopt?.offset ? sopt.offset : 0) / (sopt?.limit ? sopt.limit : 10)) + 1}
        />
      </div>
    </div>
  );
};

export default UserDetailReservation;
