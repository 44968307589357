import React, { useState, useEffect } from "react";

import { SmsAPI } from "../../api";
import { SmsAligoHistoryItem, ReqSmsAligoList, SmsDetailItem } from "../../types/adm_elements";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, TableBody, TableRow, TableCell, TableHead, Button, TextField } from "@mui/material";
import SmsHistoryDetailDialog from "../organisms/SmsHistoryDetailDialog";
import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../../store/states/SnackState";
import { useAppCommon } from "../../hooks/useAppCommon";

const LIMIT = 30;
interface SmsHistoryListViewProps {}

interface HeadCell {
  disablePadding: boolean;
  id: keyof SmsAligoHistoryItem;
  label: string;
  numeric: boolean;
}

const SmsHistoryListView: React.FC<SmsHistoryListViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "SMS History" });
  const navi = useNavigate();

  const openSnack = useSetRecoilState(SnackStateSelector);

  const [list, setList] = useState<Array<SmsAligoHistoryItem>>([]);
  const [hasNext, setHasNext] = useState<"Y" | "N">("Y");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const location = useLocation();
  const opt: ReqSmsAligoList = {};
  location.search
    .split("?")
    .filter((o) => o)
    .join("")
    .split("&")
    .forEach((o) => {
      const query = o.split("=");
      if (query[0] === "page") {
        opt.page = Number(query[1]);
      } else if (query[0] === "page_size") {
        opt.page_size = Number(query[1]);
      } else if (query[0] === "limit_day") {
        opt.limit_day = Number(query[1]);
      } else if (query[0] in ["start_date"]) {
        opt.start_date = query[1];
      }
    });
  if (opt.page === undefined) {
    opt.page = 1;
  }
  useEffect(() => {
    if (isLoading === true) {
      openSnack({ msg: "로딩중입니다." });
      return;
    }
    setIsLoading(true);
    SmsAPI.getSmsHistory(opt)
      .then((result) => {
        setIsLoading(false);
        console.log(result);
        const data = result.data;
        if (data.result) {
          if (filterText !== "") {
            setList(data.result.list.filter((o: any) => o.msg.indexOf(filterText) > -1));
          } else {
            setList(data.result.list);
          }

          setHasNext(data.result.next_yn === "Y" ? "Y" : "N");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("error", err);
      });
  }, [opt.page, opt.page_size, opt.start_date, opt.limit_day]);

  const handleMoveNext = (diff: number) => () => {
    const mopt: any = { ...opt };
    mopt.page = opt.page ? opt.page + diff : 1;
    if (mopt.page < 1) {
      mopt.page = 1;
    }
    mopt.page_size = pageSize;
    navi(
      location.pathname +
        "?" +
        Object.keys(mopt)
          .map((k) => k + "=" + mopt[k])
          .join("&")
    );
  };

  const headCells: HeadCell[] = [
    { id: "mid", numeric: false, disablePadding: true, label: "메세지ID" },
    { id: "type", numeric: true, disablePadding: false, label: "문자구분(유형)" },
    { id: "sender", numeric: true, disablePadding: false, label: "발신번호" },
    { id: "sms_count", numeric: true, disablePadding: false, label: "전송요청수" },
    { id: "reserve_state", numeric: true, disablePadding: false, label: "요청상태" },
    { id: "msg", numeric: false, disablePadding: false, label: "메세지" },
    { id: "fail_count", numeric: true, disablePadding: false, label: "처리실패건수" },
    { id: "reg_date", numeric: true, disablePadding: false, label: "등록일" },
    { id: "reserve", numeric: true, disablePadding: false, label: "예약일자" },
  ];
  const [pageSize, setPageSize] = useState(opt.page_size ? opt.page_size : 30);
  const handleChangePageSize = (e: any) => {
    setPageSize(e.target.value);
  };

  const [offset, setOffset] = useState<number>(0);

  const [filterText, setFilterText] = useState<string>("");
  const handleChangeFilterText = (e: any) => {
    setFilterText(e.target.value);
  };

  const [mid, setMid] = useState(0);
  const [detailOpen, setDetailOpen] = useState<boolean>(false);
  const handleOpenDetail = (mid: number) => () => {
    setMid(mid);
    setDetailOpen(true);
  };
  const handleCloseDetail = () => {
    setDetailOpen(false);
  };
  return (
    <div>
      <Button disabled={opt.page <= 1} onClick={handleMoveNext(-1)}>
        Previous
      </Button>
      Count:{" "}
      <TextField style={{ width: "40px" }} value={pageSize} onChange={handleChangePageSize} />
      Page: {opt.page}
      <Button disabled={hasNext !== "Y"} onClick={handleMoveNext(1)}>
        Next
      </Button>
      filter:
      <TextField value={filterText} onChange={handleChangeFilterText} />
      {isLoading === true && "로딩중"}
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id}>{headCell.label}</TableCell>
            ))}
            <TableCell>수신번호 - 결과</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((o, i) => {
            return (
              <TableRow key={i}>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id}>
                    {headCell.id === "mid" ? (
                      <Button children={o[headCell.id]} onClick={handleOpenDetail(o.mid)} />
                    ) : headCell.id !== "details" ? (
                      o[headCell.id]
                    ) : undefined}
                  </TableCell>
                ))}
                <TableCell>
                  {o.details && o.details.length > 0 && (
                    <div>
                      {o.details.map((oo: SmsDetailItem, oidx: number) => {
                        return (
                          <div key={oidx}>
                            {oo.receiver} - {oo.sms_state}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <SmsHistoryDetailDialog open={detailOpen} mid={mid} close={handleCloseDetail} />
    </div>
  );
};

export default SmsHistoryListView;
