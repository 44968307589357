import React from "react";
import { useParams } from "react-router-dom";
import ConsignmentDetail from "./consignment/ConsignmentDetail";
import RentalDetail from "./rental/RentalDetail";
import StylingDetail from "./styling/StylingDetail";

interface ServiceRequestDetailViewProps {}
const ServiceRequestDetailView: React.FC<ServiceRequestDetailViewProps> = (props) => {
  const params = useParams<{ type: "consignment" | "styling" | "rental"; id: string }>();
  const type = params.type;
  const id = Number(params.id);

  return type === "consignment" ? (
    <ConsignmentDetail id={id} />
  ) : type === "rental" ? (
    <RentalDetail id={id} />
  ) : type === "styling" ? (
    <StylingDetail id={id} />
  ) : (
    <div></div>
  );
};

export default ServiceRequestDetailView;
