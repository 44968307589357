import React, { useState, useEffect } from "react";

import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  TableBody,
  TableRow,
  Table,
  TableHead,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  TextField,
  MenuItem,
  Select,
  InputBase,
  Paper,
  IconButton,
  DialogActions,
} from "@mui/material";

import { PaymentsStatusOption, PagedPaymentAnnotation } from "../../../types/adm_elements";

import { CommonUtil as cu } from "../../../types/elements";
import Pagination from "@mui/material/Pagination";
import MerchantChecker from "./MerchantChecker";
import { useAppCommon } from "../../../hooks/useAppCommon";
import { gqladm } from "../../../graphql/gqladm";

interface PaymentsListProps {}

const ALL_COLUMNS = [
  "imp_uid",
  "merchant_uid",
  "pay_method",
  "channel",
  "pg_provider",
  "pg_tid",
  "pg_id",
  "escrow",
  "apply_num",
  "bank_code",
  "bank_name",
  "card_code",
  "card_name",
  "card_quota",
  "card_number",
  "card_type",
  "vbank_code",
  "vbank_name",
  "vbank_num",
  "vbank_holder",
  "vbank_date",
  "vbank_issued_at",
  "name",
  "amount",
  "cancel_amount",
  "currency",
  "buyer_name",
  "buyer_email",
  "buyer_tel",
  "buyer_addr",
  "buyer_postcode",
  "custom_data",
  "user_agent",
  "status",
  "started_at",
  "paid_at",
  "failed_at",
  "cancelled_at",
  "fail_reason",
  "cancel_reason",
  "receipt_url",
  "cancel_history",
  "cancel_receipt_urls",
  "cash_receipt_issued",
  "customer_uid",
  "customer_uid_usage",
];

const LIMIT = 20;
const PaymentsListView: React.FC<PaymentsListProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "" });

  const [opt, setOpt] = useState<PaymentsStatusOption>({ status: "all", limit: LIMIT, page: 1 });
  const [pagedList, setPagedList] = useState<PagedPaymentAnnotation>({});
  const [columns, setColumns] = useState<string[]>([
    "imp_uid",
    "merchant_uid",
    "name",
    "amount",
    "status",
    "buyer_name",
    "buyer_email",
    "buyer_tel",
    "paid_at",
    "started_at",
  ]);
  const [openSelectColumns, setOpenSelectColumns] = useState<boolean>(false);

  const loadPayments = async (opt: PaymentsStatusOption) => {
    console.log("loadPayments");
    setOpt({ ...opt, page: opt.page });

    const result = await gqladm.getIamportPaymentsStatus({
      status: opt.status ?? "all",
      ...opt,
    });

    if (result) setPagedList(result);
  };

  useEffect(() => {
    loadPayments({ ...opt, page: 1 });
  }, []);

  const handleChangePage = (e: any, page: number) => {
    if (opt.page !== page) {
      loadPayments({ ...opt, page: page });
    }
  };

  return (
    <div>
      <div
        style={{
          border: "1px solid gray",
          padding: "10px",
          margin: "10px",
          display: "flex",
          flexDirection: "column",
        }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>*Search</span>
        </div>
        <div>
          STATUS:&nbsp;
          <FormControlLabel
            control={<Radio checked={opt.status === "all"} />}
            label="all"
            onClick={() => setOpt({ ...opt, status: "all" })}
          />
          <FormControlLabel
            control={<Radio checked={opt.status === "ready"} />}
            label="ready"
            onClick={() => setOpt({ ...opt, status: "ready" })}
          />
          <FormControlLabel
            control={<Radio checked={opt.status === "paid"} />}
            label="paid"
            onClick={() => setOpt({ ...opt, status: "paid" })}
          />
          <FormControlLabel
            control={<Radio checked={opt.status === "cancelled"} />}
            label="cancelled"
            onClick={() => setOpt({ ...opt, status: "cancelled" })}
          />
          <FormControlLabel
            control={<Radio checked={opt.status === "failed"} />}
            label="failed"
            onClick={() => setOpt({ ...opt, status: "failed" })}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>Total:{pagedList.total}</div>
          <div>
            Count:
            <Select
              value={opt.limit}
              onChange={(e: any) => setOpt({ ...opt, limit: e.target.value })}>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={60}>60</MenuItem>
              <MenuItem value={80}>80</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </div>
          <div>
            {/* page:
            <Paper style={{ display: "inline-block" }}>
              <IconButton size="small" onClick={() => setOpt({ ...opt, page: opt.page ? opt.page - 1 : 0 })}>
                <RemoveIcon />
              </IconButton>
              <InputBase value={opt.page} type="number" style={{ width: "40px" }} />
              <IconButton size="small" onClick={() => setOpt({ ...opt, page: opt.page ? opt.page + 1 : 1 })}>
                <AddIcon />
              </IconButton>
            </Paper> */}
            <Pagination
              onChange={handleChangePage}
              count={Math.ceil(
                (pagedList.total ? pagedList.total : 0) / (opt.limit ? opt.limit : LIMIT)
              )}
              page={opt.page}
            />
          </div>

          <Button onClick={() => loadPayments({ ...opt })}>Search</Button>
          <Button onClick={() => setOpenSelectColumns(true)}>Columns Define</Button>
        </div>
      </div>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((o) => {
                return <TableCell key={"col_" + o}>{o}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedList.list?.map((pay, idx) => {
              return (
                <TableRow key={"row_" + idx}>
                  {columns.map((col) => {
                    const _pay: any = pay;
                    let out = _pay[col];
                    if (["paid_at", 'started_at'].indexOf(col) > -1) {
                      if (_pay[col] !== 0)
                        out = cu.getFormattedDate(
                          new Date(Number(_pay[col]) * 1000),
                          "yyyy-MM-dd HH:mm:ss"
                        );
                      else out = "";
                    }
                    return (
                      <TableCell key={`row_${idx}_col_${col}`}>
                        {out}
                        {col === "merchant_uid" && <MerchantChecker m_id={out} />}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <Dialog open={openSelectColumns} onClose={() => setOpenSelectColumns(false)}>
        <DialogTitle>Column select</DialogTitle>
        <DialogContent>
          {ALL_COLUMNS.map((o) => {
            const handleUpdateColumns = () => {
              columns.indexOf(o) > -1
                ? setColumns(columns.filter((c) => c !== o))
                : setColumns([...columns, o]);
            };
            return (
              <FormControlLabel
                key={"col_d_" + o}
                control={<Checkbox checked={columns.indexOf(o) > -1} />}
                label={o}
                onClick={handleUpdateColumns}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSelectColumns(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentsListView;
