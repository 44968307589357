import { CircularProgress } from "@mui/material";
import React from "react";

interface LoadingPageProps {}
const LoadingPage: React.FC<LoadingPageProps> = (props) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "3rem" }}>
      <CircularProgress />
    </div>
  );
};

export default LoadingPage;
