import React, { useEffect } from "react";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { SnackState } from "./store/states/SnackState";

import "./App.scss";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Drawer, ListItem, CssBaseline, Snackbar, Alert } from "@mui/material";

import ItemListView from "./components/pages/itemlist/ItemListView";
import ItemDetailView from "./components/pages/itemDetail/ItemDetailView";
import DashBoardView from "./components/pages/dashboard/DashBoardView";
import OrderListView from "./components/pages/orderList/OrderListView";
import OrderDetailView from "./components/pages/OrderDetailView";
import AuthHistoryView from "./components/pages/AuthHistoryView";
import UserListView from "./components/pages/customers/userlist/UserListView";
import UserDetailView from "./components/pages/customers/userDetail/UserDetailView";

import LogListView from "./components/pages/loglist/LogListView";
import SmsHistoryListView from "./components/pages/SmsHistoryListView";
import AdminUserView from "./components/pages/adminusers/AdminUserView";
import LoginView from "./components/pages/LoginView";
import ItemStockListView from "./components/pages/ItemStockListView";
import WarehouseListView from "./components/pages/warehouse/WarehouseListView";

import EmailTemplateView from "./components/pages/NotificationTemplateView";
import ConstantView from "./components/pages/constants/ConstantView";
import PublicImageView from "./components/pages/PublicImageView";
import { AdminAuthState } from "./store/states/AdminAuthState";
import OrderWaitingListView from "./components/pages/OrderWaitingListView";
import PaymentsListView from "./components/pages/payments/PaymentsListView";
import QnaView from "./components/pages/QnaView";
import SpamListView from "./components/pages/SpamListView";
import ReservationView from "./components/pages/reservation/ReservationView";

import BaseConfigView from "./components/pages/BaseConfigView";
import DrawerList from "./components/pages/common/DrawerList";
import LoadingPage from "./components/common/LoadingPage";
import ItemMetaView from "./components/pages/itemMeta/ItemMetaView";
import useItemMeta from "./hooks/useItemMeta";
import useAdminUser from "./hooks/useAdminUser";
import { useAppCommon } from "./hooks/useAppCommon";

import ConsignmentListView from "./components/pages/service/consignment/ConsignmentListView";
import Header from "./components/pages/common/Header";
import { WindowResizeState } from "./store/states/WindowResizeState";
import StylingListView from "./components/pages/service/styling/StylingListView";
import RentalListView from "./components/pages/service/rental/RentalListView";
import ServiceRequestDetailView from "./components/pages/service/ServiceRequestDetailView";
import ReservationDetailView from "./components/pages/reservation/ReservationDetailView";
import ImageListView from "./components/pages/imagelist/ImageListView";
import BoardListView from "./components/pages/board/BoardListView";
import BoardDetailView from "./components/pages/board/BoardDetailView";
import MobildDeviceView from "./components/pages/customers/mobiledevice/MobileDeviceView";
import FcmMessageList from "./components/pages/customers/fcmmessage/FcmMessageListView";
import AdminHistoryView from "./components/pages/adminHistory/AdminHistoryView";
import WithDrawUserList from "./components/pages/customers/withdrawusers/WithDrawUserList";
import UserAlertInfoView from "./components/pages/customers/useralertinfo/UserAlertInfoView";
import FcmPushListView from "./components/pages/fcmpush/FcmPushListView";
import FcmPushDetailView from "./components/pages/fcmpushdetail/FcmPushDetailView";
import UserNotiListView from "./components/pages/customers/usernoti/UserNotiListView";
import { gqladm } from "./graphql/gqladm";

const drawerWidth = 240;

interface AppProps {}
const App: React.FC<AppProps> = (props) => {
  const [snackState, setSnackState] = useRecoilState(SnackState);
  const adminAuthState = useRecoilValue(AdminAuthState);
  const { refresh: refreshItemMeta } = useItemMeta();
  const { getAdminAuth, checkUrlParams } = useAdminUser();

  const { appCommonState, toggleMenuOpen } = useAppCommon();

  useEffect(() => {
    console.log("App init", window.location.search);
    checkUrlParams(window.location.search);
    getAdminAuth().then((result) => {
      if (result) refreshItemMeta();
    });
  }, []);

  // 윈도우 사이즈 계산로직
  const setWindowResize = useSetRecoilState(WindowResizeState);
  useEffect(() => {
    const handleResize = () => {
      setWindowResize({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
        isMobile: document.documentElement.clientWidth < 600,
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <CssBaseline />
        {adminAuthState.loaded === false ? (
          <LoadingPage />
        ) : adminAuthState.admin.name ? (
          <>
            <Drawer
              className={/* classes.drawer */ "drawer"}
              open={appCommonState.menuOpen}
              PaperProps={{
                style: {
                  width: drawerWidth,
                  backgroundColor: "#494542",
                },
              }}
              anchor="left"
              variant="persistent">
              <ListItem
                button
                style={{ display: "flex", justifyContent: "flex-end" }}
                onClick={() => toggleMenuOpen(false)}>
                X
              </ListItem>
              <DrawerList closeDrawer={() => toggleMenuOpen()} />
            </Drawer>

            <div
              style={{
                display: "flex",
                // , paddingLeft: "50px"
              }}>
              <div
                style={{
                  width: "3px",
                  backgroundColor: "#494542",
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 50,
                }}>
                {/* <IconButton onClick={() => toggleMenuOpen()}>
                <MenuIcon />
              </IconButton> */}
              </div>

              <div
                style={{
                  height: "100%",
                  overflow: "scroll",
                  position: "fixed",
                  left: "3px",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  flexGrow: 1,
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  marginLeft: 0,
                }}>
                <Header title={appCommonState.headerTitle} />
                <Routes>
                  <Route path="/" element={<DashBoardView />} />
                  <Route path="/baseconfig" element={<BaseConfigView />} />
                  <Route path="/login" element={<LoginView />} />
                  <Route path="/item" element={<ItemListView />} />
                  <Route path="/warehouse" element={<WarehouseListView />} />
                  <Route path="/item/:itemId" element={<ItemDetailView />} />
                  <Route path="/item/:itemId/stock" element={<ItemStockListView />} />
                  <Route path="/order" element={<OrderListView />} />
                  <Route path="/order/:orderId" element={<OrderDetailView />} />
                  <Route path="/orderwaiting" element={<OrderWaitingListView />} />
                  <Route path="/payments" element={<PaymentsListView />} />
                  <Route path="/user" element={<UserListView />} />
                  <Route path="/user/:userId" element={<UserDetailView />} />
                  <Route path="/user/:userId/:viewId" element={<UserDetailView />} />
                  <Route path="/authhistory" element={<AuthHistoryView />} />
                  <Route path="/itemmeta" element={<ItemMetaView />} />
                  <Route path="/smshistory" element={<SmsHistoryListView />} />
                  <Route path="/log" element={<LogListView />} />
                  <Route path="/adminuser" element={<AdminUserView />} />
                  <Route path="/notification/template" element={<EmailTemplateView />} />
                  <Route path="/publicimage" element={<PublicImageView />} />
                  <Route path="/constant" element={<ConstantView />} />
                  <Route path="/qna" element={<QnaView />} />
                  <Route path="/board" element={<BoardListView />} />
                  <Route path="/board/:id" element={<BoardDetailView />} />
                  <Route path="/spam" element={<SpamListView />} />
                  <Route path="/reservation" element={<ReservationView />} />
                  <Route path="/reservation/:id" element={<ReservationDetailView />} />
                  <Route path="/servicerequest/:type/:id" element={<ServiceRequestDetailView />} />
                  <Route path="/consignments" element={<ConsignmentListView />} />
                  <Route path="/stylings" element={<StylingListView />} />
                  <Route path="/rentals" element={<RentalListView />} />
                  <Route path="/images" element={<ImageListView />} />
                  <Route path="/mobiledevice" element={<MobildDeviceView />} />
                  <Route path="/fcmpush" element={<FcmPushListView />} />
                  <Route path="/fcmpush/:fcmPushId" element={<FcmPushDetailView />} />
                  <Route path="/fcmmessage" element={<FcmMessageList />} />
                  <Route path="/useralertinfo" element={<UserAlertInfoView />} />
                  <Route path="/usernotis" element={<UserNotiListView />} />

                  <Route path="/adminhistory" element={<AdminHistoryView />} />
                  <Route path="/withdrawusers" element={<WithDrawUserList />} />
                </Routes>
              </div>
            </div>
          </>
        ) : (
          <LoginView isLoad={adminAuthState.admin.name ? true : false} />
        )}

        <Snackbar
          open={snackState.open}
          autoHideDuration={snackState.duration}
          onClose={() => setSnackState({ ...snackState, open: false, duration: 2000 })}>
          <Alert
            color={
              snackState.severity === "error"
                ? "error"
                : snackState.severity === "warning"
                ? "warning"
                : snackState.severity === "info"
                ? "info"
                : "success"
            }>
            {snackState.msg}
          </Alert>
          {/* <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackState({ ...snackState, open: false, duration: 2000 })}
          severity={snackState.severity === "" ? "info" : snackState.severity}>
          {snackState.msg}
        </MuiAlert> */}
        </Snackbar>
      </BrowserRouter>
    </div>
  );
};

export default App;
