import React, { useEffect, useState } from "react";
import { UserMemo } from "../../types/adm_elements";
import { gqladm } from "../../graphql/gqladm";
import { IconButton } from "@mui/material";
import { Create, Contacts } from "@mui/icons-material";

import UserInfoDialog from "./userinfodialog/UserInfoDialog";
import useUserMemo from "../../hooks/useUserMemo";

interface UserMemoBoxProps {
  user_id: number;
  limit?: number;
  userMemos?: UserMemo[];
}

const UserMemoBox: React.FC<UserMemoBoxProps> = (props) => {
  const { addMemo } = useUserMemo();
  const [total, setTotal] = useState<number>(-1);
  const [userMemos, setUserMemos] = useState<UserMemo[]>(props.userMemos ? props.userMemos : []);
  useEffect(
    () =>
      props.userMemos &&
      setUserMemos(props.userMemos.filter((o, i) => (props.limit ? props.limit > i : true))),
    [props.userMemos, props.limit]
  );

  const refresh = () => {
    gqladm.searchUserMemo({ user_id: props.user_id }).then((result) => {
      if (result.list) setUserMemos(result.list);
      if (result.total) setTotal(result.total);
    });
  };

  const [openDialog, setOpenDIalog] = useState<boolean>(false);
  const handleClose = () => {
    setOpenDIalog(false);
  };

  const handleAddMemo = () => {
    addMemo(props.user_id).then((result) => {
      console.log("handleAddMemo", result);
      refresh();
    });
  };

  const handleOpen = () => {
    setOpenDIalog(true);
  };

  return (
    <div style={{ display: "flex" }}>
      <ul style={{ minWidth: "100px" }}>
        {userMemos.length === 0 && <div>-</div>}
        {userMemos.map((um, idx) => {
          return <li key={idx}>{um.memo}</li>;
        })}
      </ul>
      <div style={{ display: "flex" }}>
        <IconButton size="small" onClick={handleAddMemo}>
          <Create />
        </IconButton>
        <IconButton size="small" onClick={handleOpen}>
          <Contacts />
        </IconButton>
      </div>
      <UserInfoDialog open={openDialog} onClose={handleClose} user_id={props.user_id} />
    </div>
  );
};
export default UserMemoBox;
