import React from "react";
import UserInfoAccountInfo from "./dashboard/UserInfoAccountInfo";
import UserInfoOrders from "./dashboard/UserInfoOrders";
import UserInfoReservations from "./dashboard/UserInfoReservations";
import UserInfoServiceRequest from "./dashboard/UserInfoServiceRequest";
import UserInfoTotalInfo from "./dashboard/UserInfoTotalInfo";

const boxStyle: React.CSSProperties = {
  margin: "1rem",
  display: "flex",
  gap: "1rem",
  flexDirection: "column",
};
const rowStyle: React.CSSProperties = {
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  gap: "1rem",
};

interface UserDetailDashboardProps {
  user_id: number;
}
const UserDetailDashboard: React.FC<UserDetailDashboardProps> = (props) => {
  return (
    <div style={boxStyle}>
      <div style={rowStyle}>
        <UserInfoTotalInfo user_id={props.user_id} />
        <UserInfoAccountInfo user_id={props.user_id} />
        <UserInfoReservations user_id={props.user_id} />
        <UserInfoServiceRequest user_id={props.user_id} />
      </div>
      <div style={rowStyle}>
        <UserInfoOrders user_id={props.user_id} />
      </div>
    </div>
  );
};

export default UserDetailDashboard;
