import React, { useEffect, useState } from "react";
import { Avatar, Button, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { gqladm } from "../../../../../graphql/gqladm";

import {
  Order,
  OrderSearchOption,
  CommonUtil as cu,
  getKrOrderStatus,
  numberWithCommas,
} from "../../../../../types/elements";

import UserDetailOrderNewButton from "./UserDetailOrderNewButton";

interface UserDetailOrdersProps {
  user_id: number;
}
const UserDetailOrders: React.FC<UserDetailOrdersProps> = (props) => {
  const navi = useNavigate();
  const [sopt, setSopt] = useState<OrderSearchOption>({ limit: 10, ordering: 'updated_desc' });
  const [list, setList] = useState<Order[]>([]);
  const [total, setTotal] = useState<number>(0);

  const load = (sopt: OrderSearchOption) => {
    if (props.user_id) {
      gqladm.getOrders({ ...sopt, user_id: props.user_id }).then((result) => {
        if (result.list) setList(result.list);
        if (result.total !== undefined) setTotal(result.total);
        setSopt((so) => ({ ...so, offset: result.offset }));
      });
    }
  };

  useEffect(() => {
    load(sopt);
  }, [props.user_id]);

  const handleChangePage = (page: number) => {
    console.log("handleChangePage", page);
    const offset = (page - 1) * (sopt.limit ? sopt.limit : 10);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  return (
    <div style={{ padding: "1rem" }}>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1rem" }}>
        <UserDetailOrderNewButton user_id={props.user_id} />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
        <span>total: {total}</span>
        <Pagination
          onChange={(e, p) => handleChangePage(p)}
          count={Math.ceil((total ? total : 0) / (sopt.limit ? sopt.limit : 10))}
          page={Math.ceil((sopt?.offset ? sopt.offset : 0) / (sopt?.limit ? sopt.limit : 10)) + 1}
        />
      </div>
      <div style={{ overflow: "auto" }}>
        <table className="grayStyle" cellSpacing={0}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th>Created</th>
              <th style={{ textAlign: "center" }}>수량</th>
              <th style={{ textAlign: "right" }}>주문금액</th>
              <th style={{ textAlign: "center" }}>결제수단</th>
              <th style={{ textAlign: "center" }}>Status</th>
              <th>주문항목</th>
            </tr>
          </thead>
          <tbody>
            {list.map((order, idx) => {
              return (
                <tr>
                  <td>
                    <Button onClick={() => navi("/order/" + order.id)}>{order.id}</Button>
                  </td>
                  <td>{order.type}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {order.created && cu.getFormattedDate(order.created, "yyyy-MM-dd a/p hh:mm")}
                  </td>
                  <td style={{ textAlign: "center" }}>{order.orderItems?.length}</td>
                  <td style={{ textAlign: "right" }}>{numberWithCommas(order.price)}</td>
                  <td style={{ textAlign: "center" }}>{order.paytype}</td>
                  <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                    {getKrOrderStatus(order.status)}
                  </td>
                  <td>
                    {order.orderItems?.map((oi, idx) => {
                      return (
                        <div key={idx}>
                          #{oi.id} {oi.item?.name}
                          <Avatar
                            key={idx}
                            src={
                              oi?.item?.topImages &&
                              oi.item.topImages.length > 0 &&
                              oi.item.topImages[0].url
                                ? "/" + oi.item.topImages[0].url
                                : ""
                            }
                            sx={{ borderRadius: "8px", marginRight: "0.3rem" }}
                          />
                        </div>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
        <span>total: {total}</span>
        <Pagination
          onChange={(e, p) => handleChangePage(p)}
          count={Math.ceil((total ? total : 0) / (sopt.limit ? sopt.limit : 10))}
          page={Math.ceil((sopt?.offset ? sopt.offset : 0) / (sopt?.limit ? sopt.limit : 10)) + 1}
        />
      </div>
    </div>
  );
};

export default UserDetailOrders;
