import { Grid, Paper } from "@mui/material";
import React from "react";

interface TileContainerProps {
  title?: string;
  children?: React.ReactNode;
}
const TileContainer: React.FC<TileContainerProps> = (props) => {
  return (
    <Paper elevation={3} style={{ margin: "0.5rem", padding: "0.5rem" }}>
      <div>{props.title}</div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>{props.children}</div>
    </Paper>
  );
};

export default TileContainer;
