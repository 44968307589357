import React, { useEffect, useState } from "react";
import { AdminConstantAPI } from "../../../api/AdminConstantAPI";
import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  TextField,
  Checkbox,
} from "@mui/material";
import { CommonUtil as cu, Constant } from "../../../types/elements";
import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../../../store/states/SnackState";
import { gqladm } from "../../../graphql/gqladm";
import ConstantCustomSlide1Dialog from "./ConstantCustomSlide1Dialog";
import ConstantCustomSlide2Dialog from "./ConstantCustomSlide2Dialog";
import { useAppCommon } from "../../../hooks/useAppCommon";
import ConstantCalendarMetaDialog from "./ConstantCalendarMetaDialog";
import ConstantCustomSlide3Dialog from "./ConstantCustomSlide3Dialog";
import ConstantReservationBannerDialog from "./ConstantReservationBannerDialog";

interface ConstantViewProps {}

const ConstantView: React.FC<ConstantViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "Constants" });
  const openSnack = useSetRecoilState(SnackStateSelector);

  const [list, setList] = useState<Array<Constant>>([]);
  const [dlist, setDlist] = useState<Array<Constant>>([]);
  const loadContants = () => {
    gqladm.searchConstants({ isUniqueKey: true }).then((result) => {
      if (result.list) setDlist(result.list);
    });
    return AdminConstantAPI.getConstants().then((result) => {
      const data = result.data;
      if (data.result) {
        setList(data.result);
      }
    });
  };
  useEffect(() => {
    loadContants();
  }, []);

  // add dialog
  const [editConstant, setEditConstant] = useState<Constant>({});
  const [valueJson, setValueJson] = useState<string>("");
  const handleOpenCreateConstant = () => {
    setEditConstant({});
    setOpenDialog("add");
  };
  const handleOpenUpdateConatant = (c: Constant) => {
    setEditConstant(c);
    setOpenDialog("add");
  };
  const handleChangeKey = (e: any) => {
    setEditConstant({ ...editConstant, key: e.target.value });
  };
  const handleChangeValue = (e: any) => {
    setEditConstant({ ...editConstant, value: e.target.value });
    if (editConstant.isJson) {
      try {
        const obj = JSON.parse(e.target.value + "");
        const str = JSON.stringify(obj, undefined, "\t");
        console.log("suc", obj, str);
        setValueJson(str);
      } catch (err) {
        console.log("e.target.value", e.target.value, err);
      }
    }
  };
  const handleChangeIsPublic = (e: any, value: boolean) => {
    setEditConstant({ ...editConstant, isPublic: value });
  };
  const handleChangeIsJson = (e: any, value: boolean) => {
    setEditConstant({ ...editConstant, isJson: value });
  };

  const handleAddConstant = () => {
    console.log("editConstant", editConstant);
    const c: Constant = { ...editConstant, id: undefined };
    AdminConstantAPI.postConstant(c).then((result) => {
      console.log("result", result);
      loadContants();
      setOpenDialog(undefined);
    });
  };

  // delete
  const [keyOfList, setKeyOfList] = useState<string | undefined>(undefined);
  const handleOpenListConstant = (c: Constant) => {
    setKeyOfList(c.key);
    setOpenDialog("list");
  };

  const handleDeleteConstant = (c: Constant) => {
    if (c.id) {
      AdminConstantAPI.deleteConstant({ id: c.id })
        .then((result) => {
          const data = result.data;
          openSnack({ msg: data.updatedCount + "개의 Constant가 삭제되었습니다. " });
          loadContants();
          setOpenDialog(undefined);
        })
        .catch((err) => {
          openSnack({ msg: "Error - " + err.message });
        });
    }
  };
  const handleDeleteKey = (key?: string) => {
    if (key) {
      AdminConstantAPI.deleteConstant({ key: key })
        .then((result) => {
          const data = result.data;
          openSnack({ msg: data.updatedCount + "개의 Constant가 삭제되었습니다. " });
          loadContants();
          setOpenDialog(undefined);
        })
        .catch((err) => {
          openSnack({ msg: "Error - " + err.message });
        });
    }
  };

  // dialog open
  const [openDialog, setOpenDialog] = useState<"add" | "list" | undefined>(undefined);
  const handleCloseDialog = () => {
    setOpenDialog(undefined);
  };

  // handleSyncStaticConstants
  const handleSyncStaticConstants = () => {
    gqladm.syncStaticConstants().then((result) => {
      console.log(result);
      if (result.ok) openSnack({ msg: "static constants.json is updated" });
    });
  };

  const [customOpen, setCustomOpen] = useState<
    | "customSlide1"
    | "customSlide2"
    | "customSlide3"
    | "calendarMeta"
    | "reservationBanner"
    | undefined
  >(undefined);

  return (
    <div>
      <div>
        <div>Custom Setting</div>
        <div>
          <Button variant="contained" color="primary" onClick={() => setCustomOpen("customSlide1")}>
            Mobile Main Slide
          </Button>
          &nbsp;
          <Button variant="contained" color="primary" onClick={() => setCustomOpen("customSlide2")}>
            Mobile Sub Slide
          </Button>
          &nbsp;
          <Button variant="contained" color="primary" onClick={() => setCustomOpen("customSlide3")}>
            PC Main Slide
          </Button>
          &nbsp;
          <Button variant="contained" color="primary" onClick={() => setCustomOpen("calendarMeta")}>
            Calendar Meta
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCustomOpen("reservationBanner")}>
            예약창 배너설정
          </Button>
        </div>
        <ConstantCustomSlide1Dialog
          open={customOpen === "customSlide1"}
          onClose={() => setCustomOpen(undefined)}
        />
        <ConstantCustomSlide2Dialog
          open={customOpen === "customSlide2"}
          onClose={() => setCustomOpen(undefined)}
        />
        <ConstantCustomSlide3Dialog
          open={customOpen === "customSlide3"}
          onClose={() => setCustomOpen(undefined)}
        />

        <ConstantCalendarMetaDialog
          open={customOpen === "calendarMeta"}
          onClose={() => setCustomOpen(undefined)}
        />
        <ConstantReservationBannerDialog
          open={customOpen === "reservationBanner"}
          onClose={() => setCustomOpen(undefined)}
        />
      </div>
      <hr />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          Constants
          <Button variant="contained" color="primary" onClick={handleOpenCreateConstant}>
            Create
          </Button>
        </div>
        <Button variant="contained" color="secondary" onClick={handleSyncStaticConstants}>
          Sync Static Constants
        </Button>
      </div>
      <Dialog open={openDialog === "add"} onClose={handleCloseDialog}>
        <DialogTitle>{editConstant.id === undefined ? "Create" : "Update"} Constant</DialogTitle>
        <DialogContent style={{ minWidth: "500px" }}>
          <div style={{ marginBottom: "10px", display: "flex", justifyContent: "space-around" }}>
            <TextField
              variant="outlined"
              label="Key"
              disabled={editConstant.id !== undefined}
              value={editConstant.key}
              onChange={handleChangeKey}
            />
            <TextField
              variant="outlined"
              label="Value"
              value={editConstant.value}
              onChange={handleChangeValue}
              multiline={editConstant.isJson}
              rows={editConstant.isJson ? 3 : 1}
            />
          </div>
          {editConstant.isJson && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                variant="outlined"
                label="JSON"
                fullWidth
                disabled
                multiline
                value={valueJson}></TextField>
            </div>
          )}
          <div>
            isPublic: <Checkbox checked={editConstant.isPublic} onChange={handleChangeIsPublic} />
            isJson: <Checkbox checked={editConstant.isJson} onChange={handleChangeIsJson} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddConstant}>Add</Button>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog === "list"} onClose={handleCloseDialog}>
        <DialogTitle>List Constants</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell>key</TableCell>
                <TableCell>value</TableCell>
                <TableCell>created</TableCell>
                <TableCell>isPublic</TableCell>
                <TableCell>isJson</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list
                .filter((o) => o.key === keyOfList)
                .map((c: Constant, idx: number) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>{c.id}</TableCell>
                      <TableCell>{c.key}</TableCell>
                      <TableCell>{typeof c.value !== "object" ? c.value : undefined}</TableCell>
                      <TableCell>
                        {c.created ? cu.getFormattedDate(c.created, "yyyy-MM-dd HH:mm:ss") : ""}
                      </TableCell>
                      <TableCell>{c.isPublic ? "true" : "false"}</TableCell>
                      <TableCell>{c.isJson ? "true" : "false"}</TableCell>
                      <TableCell>
                        <Button onClick={() => handleDeleteConstant(c)}>Delete</Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteKey(keyOfList)}>Delete this key</Button>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>key</TableCell>
            <TableCell>value</TableCell>
            <TableCell>created</TableCell>
            <TableCell>isPublic</TableCell>
            <TableCell>isJSON</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dlist.map((c: Constant, idx: number) => {
            return (
              <TableRow key={idx}>
                <TableCell>{c.id}</TableCell>
                <TableCell>{c.key}</TableCell>
                <TableCell>{typeof c.value !== "object" ? c.value : undefined}</TableCell>
                <TableCell>
                  {c.created ? cu.getFormattedDate(c.created, "yyyy-MM-dd HH:mm:ss") : ""}
                </TableCell>
                <TableCell>{c.isPublic ? "true" : "false"}</TableCell>
                <TableCell>{c.isJson ? "true" : "false"}</TableCell>
                <TableCell>
                  <Button onClick={() => handleOpenUpdateConatant(c)}>Update</Button>
                  <Button onClick={() => handleOpenListConstant(c)}>List</Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default ConstantView;
