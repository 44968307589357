import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import SelectItemDialog from "./SelectItemDialog";
import { Item, User, Warehouse } from "../../types/elements";
import SelectUserDialog from "./SelectUserDialog";
import ItemTableBodyRow from "../molecules/ItemTableBodyRow";
import UserTableHeader from "../molecules/UserTableHeader";
import UserTableBodyRow from "../molecules/UserTableBodyRow";
import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../../store/states/SnackState";
import { client } from "../../graphql/client";
import { MutationIF } from "../../graphql/types";
import { gql } from "apollo-boost";
import { useNavigate } from "react-router-dom";
import { gqladm } from "../../graphql/gqladm";

interface CreatePrivatePayItemDialogProps {
  open: boolean;
  onClose: (completed?: boolean) => any;
}
const CreatePrivatePayItemDialog: React.FC<CreatePrivatePayItemDialogProps> = (props) => {
  const navi = useNavigate();
  const [ui, setUi] = useState<"INIT" | "REQUESTED" | "DONE">("INIT");
  const openSnack = useSetRecoilState(SnackStateSelector);

  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  useEffect(() => {
    gqladm
      .getWarehouses({})
      .then((result) => {
        if (result.list) setWarehouses(result.list);
      })
      .catch((err) => console.log("Err", err));
  }, []);

  const [selectedItem, setSelectedItem] = useState<Item | undefined>(undefined);
  const handleSelectItem = (item: Item) => {
    setSelectedItem(item);
    setOpenSelectItem(false);
  };

  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const handleSelectUser = (user: User) => {
    setSelectedUser(user);
    setOpenSelectUser(false);
  };

  const [stockCount, setStockCount] = useState<number>(1);
  const [warehouseId, setWarehouseId] = useState<number>(-1);

  const [openSelectItem, setOpenSelectItem] = useState<boolean>(false);
  const [openSelectUser, setOpenSelectUser] = useState<boolean>(false);

  const handleClickCreate = () => {
    console.log("handleClickCreate");
    if (
      selectedItem === undefined ||
      selectedItem.id === undefined ||
      selectedUser === undefined ||
      selectedUser.email === undefined
    ) {
      openSnack({ msg: "상품 및 유저를 선택해야합니다." });
      return;
    }
    if (stockCount < 1) {
      openSnack({ msg: "재고는 1 이상이어야 합니다." });
      return;
    }
    if (warehouseId === -1) {
      openSnack({ msg: "재고위치를 설정해야합니다. " });
      return;
    }
    const CREATE_PRIVATE_PAY_ITEM = gql`
      mutation CreatePrivatePayItem(
        $itemId: BigInt
        $email: String
        $stockCount: Int
        $warehouseId: Int
      ) {
        createPrivatePayItem(
          itemId: $itemId
          email: $email
          stockCount: $stockCount
          warehouseId: $warehouseId
        ) {
          id
          defaultItemOptionId
          itemOption {
            id
          }
        }
      }
    `;
    client
      .mutate<
        MutationIF,
        { itemId: number; email: string; stockCount: number; warehouseId: number }
      >({
        mutation: CREATE_PRIVATE_PAY_ITEM,
        variables: {
          itemId: selectedItem.id,
          email: selectedUser.email,
          stockCount: stockCount,
          warehouseId: warehouseId,
        },
      })
      .then((result) => {
        console.log(result);
        if (result.data?.createPrivatePayItem?.id) {
          if (props.onClose !== undefined) props.onClose();
          navi("/item/" + result.data.createPrivatePayItem.id);
        }
      });
  };
  return (
    <Dialog open={props.open} onClose={() => props.onClose()} fullWidth>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            상품 -
            <Button color="primary" variant="contained" onClick={() => setOpenSelectItem(true)}>
              상품선택
            </Button>
            <SelectItemDialog
              open={openSelectItem}
              onClose={() => setOpenSelectItem(false)}
              onSelect={handleSelectItem}
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>ID</TableCell>
                  <TableCell>Designer</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Visible</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{selectedItem && <ItemTableBodyRow item={selectedItem} />}</TableBody>
            </Table>
          </div>

          <div>
            유저 -
            <Button color="primary" variant="contained" onClick={() => setOpenSelectUser(true)}>
              유저선택
            </Button>
            <SelectUserDialog
              open={openSelectUser}
              onClose={() => setOpenSelectUser(false)}
              onSelect={handleSelectUser}
            />
            <Table>
              <UserTableHeader />
              <TableBody>{selectedUser && <UserTableBodyRow user={selectedUser} />}</TableBody>
            </Table>
          </div>
          <div>
            <div>
              재고 설정
              <TextField
                value={stockCount}
                onChange={(e) =>
                  !isNaN(Number(e.target.value)) &&
                  setStockCount(Math.floor(Number(e.target.value)))
                }
              />
            </div>
            <div>
              재고 위치
              <Select onChange={(e) => setWarehouseId(Number(e.target.value))}>
                {warehouses.map((o, i) => (
                  <MenuItem
                    key={"warehouses" + i}
                    onSelect={(e) => console.log("menuitem", e)}
                    value={o.id}>
                    {o.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickCreate}
          disabled={ui !== "INIT"}>
          Create
        </Button>
        <Button onClick={() => props.onClose && props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePrivatePayItemDialog;
