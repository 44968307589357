import React, { useEffect, useState } from "react";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";

import { Button } from "@mui/material";
import { gqladm } from "../../../../graphql/gqladm";

interface TextEditorProps {
  body: string;
  onSave: (body: string) => void;
}
const TextEditor: React.FC<TextEditorProps> = (props) => {
  const handleSave = () => {
    props.onSave(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  useEffect(() => {
    console.log("useEffect", props.body);
    const contentBlock = htmlToDraft(props.body);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [props.body]);

  return (
    <div>
      <div></div>
      <Editor
        wrapperClassName="textEditor-wrapper-class"
        editorClassName="textEditor-editor-class"
        toolbarClassName="textEditor-toolbar-class"
        toolbar={{
          inline: {
            inDropdown: false,
            options: [
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "monospace",
              "superscript",
              "subscript",
            ],
          },
          list: { inDropdown: true },
          textAlign: { inDropdown: false },
          link: { inDropdown: false },
          history: { inDropdown: false },
          image: {
            urlEnabled: false,
            uploadEnabled: false,
          },
        }}
        onChange={(e) => {
          console.log(
            e.blocks.map((o) => {
              return o.text;
            })
          );
        }}
        editorState={editorState}
        onContentStateChange={(e) => {
          console.log("onContentStateChange", e);
        }}
        onEditorStateChange={(e) => {
          console.log("onEditorStateChange", e);
          setEditorState(e);
        }}
      />

      <Button onClick={handleSave}>Save</Button>
    </div>
  );
};

export default TextEditor;
