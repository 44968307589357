import React, { useEffect, useState } from "react";
import { gqladm } from "../../../../graphql/gqladm";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { User } from "../../../../types/elements";
import { UserDetailViewIdType } from "./UserDetailView";
import UserInfoMWLayout from "./UserInfoMWLayout";
import UserInfoPCLayout from "./UserInfoPCLayout";

interface UserDetailProps {
  user_id: number;
  view_id: UserDetailViewIdType;
  onChangeViewId: (viewId:UserDetailViewIdType) => void;
}
const UserDetail: React.FC<UserDetailProps> = (props) => {
  const [user, setUser] = useState<User>({});
  const load = (user_id: number) => {
    gqladm.getUsers({ id: user_id, limit: 1, offset: 0 }).then((result) => {
      setUser(result.list && result.list.length === 1 ? result.list[0] : {});
    });
  };

  useEffect(() => {
    load(props.user_id);
  }, [props.user_id]);
  const { isMobile } = useWindowSize();
  return (
    <div>
      {isMobile ? (
        <UserInfoMWLayout user={user} user_id={props.user_id} view_id={props.view_id} onChangeViewId={props.onChangeViewId} />
      ) : (
        <UserInfoPCLayout user={user} user_id={props.user_id} view_id={props.view_id} onChangeViewId={props.onChangeViewId} />
      )}
    </div>
  );
};

export default UserDetail;
