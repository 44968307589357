import React from "react";

const ReserveSlotManage: React.FC = (props) => {
  return (
    <div>
      <div></div>
    </div>
  );
};

export default ReserveSlotManage;
