import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { RentalResultProps } from "./RentalListView";
import { CommonUtil as cu } from "../../../../types/elements";
import ImageViewClickPanel from "../../../organisms/ImageViewClickPanel";

interface RentalResultTableProps extends RentalResultProps {}
const RentalResultTable: React.FC<RentalResultTableProps> = ({ list, openDialog, openUser }) => {
  return (
    <div>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>상태/신청일자</TableCell>
            <TableCell>대여기간/신청사진</TableCell>
            <TableCell>유저</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((r) => {
            return (
              <TableRow key={r.id}>
                <TableCell>
                  <Button onClick={() => r.id && openDialog(r.id)}>{r.id}</Button>
                </TableCell>
                <TableCell>
                  {r.status} <br />
                  {r.created && cu.getFormattedDate(r.created, "yyyy-MM-dd")}
                </TableCell>
                <TableCell>
                  {r.rental_start} - {r.rental_end} ({r.days}일간)
                  <br />
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {r.images?.map((image, imageIdx) => {
                      return (
                        <div key={"image_" + imageIdx}>
                          <ImageViewClickPanel url={image.url}>
                            <img src={image.url} style={{ maxWidth: "100px" }} />
                          </ImageViewClickPanel>
                        </div>
                      );
                    })}
                  </div>
                </TableCell>

                <TableCell className="noBreakWord">
                  <Button onClick={() => r.user_id && openUser(r.user_id)}>
                    #{r?.user_id} {r?.user?.name}
                  </Button>
                  <br />
                  {r?.user?.phone}
                  <br />
                  {r?.user?.email}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default RentalResultTable;
