import EditIcon from "@mui/icons-material/Edit";
import { Button, Chip, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchedAddress } from "../../../../api/PublicAddressAPI";
import ColumnDiv from "../../../molecules/ColumnDiv";
import AddressSelectDialog from "../../../organisms/AddressSelectDialog";
import { gqladm } from "../../../../graphql/gqladm";
import useSnack from "../../../../hooks/useSnack";
import {
  Consignment,
  CommonUtil as cu,
  ConsignmentStatus,
  ConsignmentStatusArray,
  ConsignmentItem,
  pick,
} from "../../../../types/elements";
import ConsignmentItemDialog from "./ConsignmentItemDialog";
import ConsignmentItemStatusDialog from "./ConsignmentItemStatusDialog";

interface ConsignmentDetailProps {
  id: number;
  onSaveDone?: () => any;
}
const ConsignmentDetail: React.FC<ConsignmentDetailProps> = (props) => {
  const { openSnack } = useSnack();
  const [consignment, setConsignment] = useState<Consignment | undefined>();
  const [items, setItems] = useState<ConsignmentItem[]>([]);

  const load = (id: number) => {
    gqladm.consignments({ id }).then((result) => {
      if (result?.list && result.list.length > 0) setConsignment(result.list[0]);
    });
  };
  const loadItems = (consignment_id: number) => {
    gqladm.consignmentItemsByConsignmentId(consignment_id).then((result) => {
      if (result) setItems(result);
    });
  };

  useEffect(() => {
    if (props.id) {
      load(props.id);
      loadItems(props.id);
    }
  }, [props.id]);

  const [changeStatus, setChangeStatus] = useState<ConsignmentStatus | undefined>();
  const handleChangeStatus = () => {
    if (consignment?.status === changeStatus) return;
    consignment?.id &&
      changeStatus !== undefined &&
      gqladm.updateConsignmentStatus(consignment.id, changeStatus).then((result) => {
        if (result.ok) {
          openSnack({ msg: "상태가 변경되었습니다. " });
          load(props.id);
        }
      });
  };

  const handleSelectAddress = (se: SearchedAddress) => {
    console.log("handleSelectAddress", se);
    setConsignment((consignment) => ({
      ...consignment,
      delivery_zipcode: se.zipNo,
      delivery_addr1: se.roadAddr,
    }));
  };

  const handleChangeText = (
    field: "account_number" | "account_bank" | "account_name" | "request_detail" | "delivery_addr2",
    value: string
  ) => {
    setConsignment((consignment) => {
      const ret = { ...consignment } as any;
      ret[field] = value;
      return ret;
    });
  };

  // Modify Item
  const [item, setItem] = useState<ConsignmentItem | undefined>();
  const openItem = item !== undefined;
  const handleModifyItem = (o: ConsignmentItem) => {
    setItem(o);
  };
  const handleAddItem = () => {
    props.id &&
      gqladm.addNewConsignmentItem(props.id).then((result) => {
        console.log("addNewConsignmentItem result", result);
      });
  };

  // Modify ItemStatus
  const [modifyingItemStatusId, setModifyingItemStatusId] = useState<number | undefined>();
  const handleChangeItemStatus = (o: ConsignmentItem) => {
    if (o.id) setModifyingItemStatusId(o.id);
  };
  const handleCloseChangeItemStatus = (completed?: boolean) => {
    setModifyingItemStatusId(undefined);
    if (completed && props.id) loadItems(props.id);
  };

  const handleSave = () => {
    if (consignment)
      gqladm
        .updateConsignment(
          pick<Consignment>(consignment, [
            "id",
            "delivery_zipcode",
            "delivery_addr1",
            "delivery_addr2",
            "account_bank",
            "account_name",
            "account_number",
            "request_detail",
          ])
        )
        .then((result) => {
          console.log("result");
          if (result.ok && props.id) {
            if (props.onSaveDone) props.onSaveDone();
            load(props.id);
            loadItems(props.id);
          }
        });
  };

  return (
    <div className="consignmentDetail">
      <ColumnDiv label="위탁 상품 상세" />
      <ColumnDiv label="유저">
        <div style={{ border: "1px solid black", padding: "1rem" }}>
          <div>이름: {consignment?.user?.name}</div>
          <div>전화: {consignment?.user?.phone}</div>
          <div>이메일: {consignment?.user?.email}</div>
        </div>
      </ColumnDiv>
      <hr />

      <ColumnDiv label="id">
        <TextField size="small" disabled value={props.id} fullWidth />
      </ColumnDiv>

      <ColumnDiv label="CREATED">
        {consignment?.created && cu.getFormattedDate(consignment.created, "yyyy-MM-dd")}
      </ColumnDiv>

      <ColumnDiv label="STATUS">
        <div style={{ minWidth: "100px", display: "inline-block" }}>
          &nbsp;{consignment?.status}
        </div>
        =&gt;
        <Select
          value={changeStatus}
          onChange={(e: any) => setChangeStatus(e.target.value)}
          size="small">
          {ConsignmentStatusArray.map((o) => (
            <MenuItem value={o}>{o}</MenuItem>
          ))}
        </Select>
        <Button
          size="small"
          onClick={handleChangeStatus}
          disabled={consignment?.status === changeStatus}>
          변경
        </Button>
      </ColumnDiv>
      <ColumnDiv label="배송정보">
        우편번호: {consignment?.delivery_zipcode}
        <AddressSelectDialog onSelect={handleSelectAddress}>
          <Button>검색</Button>
        </AddressSelectDialog>
        <br />
        주소 1: <TextField size="small" disabled value={consignment?.delivery_addr1} />
        <br />
        주소 2:{" "}
        <TextField
          size="small"
          value={consignment?.delivery_addr2}
          onChange={(e) => handleChangeText("delivery_addr2", e.target.value)}
        />
      </ColumnDiv>
      <ColumnDiv label="계좌정보">
        은행:
        <TextField
          fullWidth
          size="small"
          value={consignment?.account_bank}
          onChange={(e) => handleChangeText("account_bank", e.target.value)}
        />
        계좌번호:
        <TextField
          fullWidth
          size="small"
          value={consignment?.account_number}
          onChange={(e) => handleChangeText("account_number", e.target.value)}
        />
        예금주:
        <TextField
          fullWidth
          size="small"
          value={consignment?.account_name}
          onChange={(e) => handleChangeText("account_name", e.target.value)}
        />
      </ColumnDiv>
      <ColumnDiv label="요청사항">
        <TextField
          size="small"
          fullWidth
          multiline
          value={consignment?.request_detail}
          onChange={(e) => handleChangeText("request_detail", e.target.value)}
        />
      </ColumnDiv>
      <ColumnDiv label="위탁항목">
        {items.map((o) => (
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                #{o.id} -{" "}
                <Chip
                  size="small"
                  label={o.status}
                  onDelete={() => handleChangeItemStatus(o)}
                  deleteIcon={<EditIcon />}
                />{" "}
                - {o.category} - {o.designer}
              </div>
              <Button onClick={() => handleModifyItem(o)}>수정</Button>
            </div>
          </div>
        ))}
        <Button onClick={handleAddItem}>추가</Button>
        <ConsignmentItemDialog open={openItem} onClose={() => setItem(undefined)} item={item} />
        <ConsignmentItemStatusDialog
          open={!!modifyingItemStatusId}
          consignmentItemId={modifyingItemStatusId ?? -1}
          onClose={handleCloseChangeItemStatus}
        />
      </ColumnDiv>
      <Button onClick={handleSave} fullWidth variant="contained">
        Save
      </Button>
    </div>
  );
};

export default ConsignmentDetail;
