import React, { useEffect, useState } from "react";
import { Divider, MenuItem, MenuList, Pagination, Paper, Typography } from "@mui/material";
import { AdminNoti } from "../../../types/adm_elements";
import { gqladm } from "../../../graphql/gqladm";
import { useRecoilValue } from "recoil";
import { AdminAuthState } from "../../../store/states/AdminAuthState";
import { useNavigate } from "react-router-dom";

const LIMIT = 10;
interface AdminNotiPaperProps {
  onCloseUpper?: () => void;
}
const AdminNotiPaper: React.FC<AdminNotiPaperProps> = ({ onCloseUpper }) => {
  const navi = useNavigate();
  const adminAuthState = useRecoilValue(AdminAuthState);
  const [list, setList] = useState<AdminNoti[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);

  const load = (offset: number) => {
    gqladm.adminNotis({ admin_id: adminAuthState.admin.id, limit: LIMIT, offset }).then((r) => {
      if (r.list) setList(r.list);
      if (r.total !== undefined) setTotal(r.total);
      if (r.offset !== undefined) setOffset(r.offset);
    });
  };
  useEffect(() => {
    load(0);
  }, []);

  const handleClick = (noti: AdminNoti) => {
    if (onCloseUpper) onCloseUpper();
    if (noti?.path) navi(noti.path);

    // 클릭시 읽음으로 표시 전환, 후 글로벌 새로 고침하여 숫자 조정
  };

  const handleChangePage = (e: any, page: number) => {
    const _off = (page - 1) * LIMIT;
    if (_off !== offset) {
      load(_off);
    }
  };

  return (
    <Paper sx={{ width: "300px" }}>
      <MenuList>
        <MenuItem disabled>알림 목록</MenuItem>
        {list.map((noti) => {
          return (
            <MenuItem
              key={noti.id}
              sx={{ whiteSpace: "break-spaces", flexDirection: "column" }}
              onClick={() => handleClick(noti)}>
              {noti.msg}

              <Typography variant="caption" display="block" gutterBottom>
                {noti.path}
              </Typography>
            </MenuItem>
          );
        })}
        <Divider />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            count={Math.ceil(total / LIMIT)}
            page={Math.ceil(offset / LIMIT) + 1}
            onChange={handleChangePage}
          />
        </div>
      </MenuList>
    </Paper>
  );
};

export default AdminNotiPaper;
