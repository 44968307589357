import { atom, DefaultValue, selector } from "recoil";

interface SnackStateIF {
  open?: boolean;
  severity?: "success" | "info" | "warning" | "error" | "";
  msg?: string;
  duration?: number;
  move?: {
    name: string;
    path: string;
    onClick?: () => any;
  };
}

const INIT_SNACK_STATE: SnackStateIF = {
  open: false,
  severity: "",
  msg: "",
  duration: 2000,
};

export const SnackState = atom<SnackStateIF>({
  key: "SnackState",
  default: INIT_SNACK_STATE,
});

export const SnackStateSelector = selector<SnackStateIF>({
  key: "SnackStateSelector",
  get: ({ get }) => {
    return get(SnackState);
  },
  set: ({ set }, newValue: SnackStateIF | DefaultValue) => {
    const snack: SnackStateIF = newValue as any;
    const severity = snack && snack.severity ? snack.severity : "";
    const duration = snack && snack.duration ? snack.duration : 2000;

    set(SnackState, {
      open: true,
      severity: severity,
      msg: snack.msg,
      duration: duration,
      move: snack.move,
    });
  },
});
