import {
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  Pagination,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { UserSearchOption } from "../../../types/adm_elements";
import { SearchResult, User, CommonUtil as cu, DAY } from "../../../types/elements";
import DashPanel from "../../molecules/DashPanel";

interface PanelLoginUsersProps {}
const PanelLoginUsers: React.FC<PanelLoginUsersProps> = (props) => {
  const [sopt, setSopt] = useState<UserSearchOption>({
    expiredAfter: Date.now(),
    limit: 10,
    offset: 0,
    orderby: "expired_desc",
  });

  const [result, setResult] = useState<SearchResult<User>>({});

  const load = (opt: UserSearchOption) => {
    gqladm
      .getUsers({ ...sopt, ...opt })
      .then((result) => {
        setResult(result);
        setSopt({ ...sopt, offset: result.offset ? result.offset : sopt.offset });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangePage = (page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : 10);
    load({ ...sopt, offset: offset });
  };

  useEffect(() => {
    load(sopt);
  }, []);

  return (
    <DashPanel
      title={`웹 로그인 사용자(12h이내 로그인-현재 로그인유효) - ${result.total}`}
      actions={<div></div>}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>이름</TableCell>
            <TableCell>전화번호</TableCell>
            <TableCell>주문완료</TableCell>
            <TableCell>주문금액</TableCell>
            <TableCell>로그인</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {result.list?.map((o) => {
            return (
              <TableRow key={o.id}>
                <TableCell>{o.id}</TableCell>
                <TableCell>{o.name}</TableCell>
                <TableCell>{o.phone}</TableCell>
                <TableCell>{o.num_order_done_count}</TableCell>
                <TableCell>{o.num_order_done_price}</TableCell>
                <TableCell>
                  {o.sessionExpired &&
                    cu.getFormattedDate(o.sessionExpired - DAY / 2, "MM/dd HH:mm")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={6}>
              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <Pagination
                  onChange={(e, p) => handleChangePage(p)}
                  count={Math.ceil(
                    (result.total ? result.total : 0) / (result?.limit ? result.limit : 10)
                  )}
                  page={
                    Math.ceil(
                      (result?.offset ? result.offset : 0) / (result?.limit ? result.limit : 10)
                    ) + 1
                  }
                />
              </div>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </DashPanel>
  );
};

export default PanelLoginUsers;
