import { Button, List, ListItem, ListItemText } from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import useSnack from "../../../hooks/useSnack";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { FcmPush, FcmPushUser, FcmPushUserSearchOption } from "../../../types/adm_elements";
import { getFormattedDate, Item, numberWithCommas, SimpleResult, User } from "../../../types/elements";
import ItemListItem from "../../molecules/ItemListItem";
import PushMessageListItem from "../../molecules/PushMessageListItem";
import SelectUserDialog from "../../organisms/SelectUserDialog";
import CandidateSearchOptionDialog, { convertSoptToConds } from "./CandidateSearchOptionDialog";
import CandidatesList, { CandidateSearchOption } from "./CandidatesList";
import FcmPushMsgDialog from "./FcmPushMsgDialog";
import FcmPushSendDialog from "./FcmPushSendDialog";
import TargetList from "./TargetList";

const style_row_wrapper = (isMobile: boolean): CSSProperties => {
  return {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
    margin: isMobile ? "1rem 0 0 0" : "1rem",
    minWidth: "320px",
  };
};
const style_gray_box = (isMobile: boolean): CSSProperties => {
  return {
    width: isMobile ? "calc(100%)" : "calc(50% - 0.5rem)",
    // flex: "auto",
    backgroundColor: "#eeeeee",
    borderRadius: "1rem",
    padding: "1rem",
  };
};

interface FcmPushDetailProps {
  fcmPushId: number;
}
const FcmPushDetail: React.FC<FcmPushDetailProps> = (props) => {
  const { isMobile } = useWindowSize();
  console.log("isMobile", isMobile);
  const { openSnack } = useSnack();
  const [fcmPush, setFcmPush] = useState<FcmPush | undefined>();
  const [item, setItem] = useState<Item | undefined>();
  useEffect(() => {
    if (props.fcmPushId) loadFcmPush(props.fcmPushId);
  }, [props.fcmPushId]);
  const loadFcmPush = (fcmPushId: number) => {
    gqladm.getFcmPushs({ id: fcmPushId }).then((result) => {
      if (result.list && result.list.length > 0) {
        const fp = result.list[0];
        setFcmPush(fp);
        if (fp.item_id) loadItem(fp.item_id);
      }
    });
  };
  const loadItem = (item_id: number) => {
    gqladm.getItem(item_id).then((item) => {
      if (item) setItem({ ...item });
    });
  };

  // 정보 수정
  const [editFcm, setEditFcm] = useState<FcmPush | undefined>();
  const openEditFcm = editFcm !== undefined;

  // 후보유저
  const [listCandidate, setListCandidate] = useState<User[]>([]);
  const [totalCandidate, setTotalCandidate] = useState<number>(0);
  const [soptCandidate, setSoptCandidate] = useState<CandidateSearchOption>({
    offset: 0,
    limit: 10,
  });
  const loadCandidate = (sopt: CandidateSearchOption) => {
    const conds = convertSoptToConds(sopt);
    return gqladm
      .getUsersByAlertInfo({
        limit: sopt.limit,
        offset: sopt.offset,
        conds,
        notInFcmPushId: fcmPush?.id,
        orderby: "created_desc",
      })
      .then((result) => {
        if (result.list) setListCandidate(result.list);
        if (result.total !== undefined) setTotalCandidate(result.total);
        setSoptCandidate({ ...sopt });
      });
  };
  useEffect(() => {
    console.log("init", fcmPush, item);
    if (fcmPush && item) {
      // TODO DB내 category_ids가 저장된 형태가 []를 포함하고 있어 수정이 필요함 db쪽
      const category_ids = (item.category_ids ?? "")
        .replace("[", "")
        .replace("]", "")
        .split(",")
        .filter((o) => o);
      const category_id = Number(category_ids.length > 0 ? category_ids[0] : item.category_id);
      const sopt: CandidateSearchOption = {
        ...soptCandidate,
        brand_id: item.brand_id,
        designer_id: item.designer_id,
        category_id: isNaN(category_id) ? undefined : category_id,
      };
      loadCandidate(sopt);
    }
  }, [fcmPush, item]);
  const addedAction = (result: SimpleResult) => {
    openSnack({ msg: `FcmPush에 ${result.count}명을 추가했습니다.` });
    loadCandidate(soptCandidate);
    loadTarget(soptTarget);
    setOpenCandidateSearch(false);
  };
  const handleAdd = (user_ids: number[]) => {
    if (fcmPush?.id) gqladm.addFcmPushUsers(fcmPush.id, user_ids).then(addedAction);
  };
  const handleAddAll = () => {
    if (fcmPush?.id) {
      const conds = convertSoptToConds(soptCandidate);
      gqladm.addFcmPushUsersFromConds(fcmPush.id, conds).then(addedAction);
    }
  };

  // 검색
  const [openSelectUser, setOpenSelectUser] = useState<boolean>(false);
  const [openCandidateSearch, setOpenCandidateSearch] = useState<boolean>(false);

  // 타겟유저
  const [soptTarget, setSoptTarget] = useState<FcmPushUserSearchOption>({
    fcmpush_id: props.fcmPushId,
    orderby: "created_desc",
    limit: 10,
    offset: 0,
  });
  const [totalTarget, setTotalTarget] = useState<number>(0);
  const [listTarget, setListTarget] = useState<FcmPushUser[]>([]);
  const loadTarget = (sopt: FcmPushUserSearchOption) => {
    gqladm.getFcmPushUsers(sopt).then((result) => {
      console.log(result);
      if (result.list) setListTarget(result.list);
      if (result.total !== undefined) setTotalTarget(result.total);
      setSoptTarget({ ...soptTarget, offset: result.offset });
    });
  };
  const removeFcmUserTarget = (user_ids: number[]) => {
    if (fcmPush?.id)
      gqladm.delFcmPushUsers(fcmPush.id, user_ids).then((result) => {
        loadCandidate(soptCandidate);
        loadTarget(soptTarget);
      });
  };
  useEffect(() => {
    if (fcmPush?.id) {
      const sopt: FcmPushUserSearchOption = { ...soptTarget, fcmpush_id: fcmPush?.id };
      loadTarget(sopt);
      setSoptTarget(sopt);
    }
  }, [fcmPush]);

  const [openSendFcmPush, setOpenSendFcmPush] = useState<boolean>(false);
  const handleClickSendFcmPush = () => {
    console.log("handleClickSendFcmPush");
    setOpenSendFcmPush(true);
  };
  const handleCloseSendFcmPushDialog = (isCompleted?: boolean) => {
    if (isCompleted && props.fcmPushId) loadFcmPush(props.fcmPushId);
    setOpenSendFcmPush(false);
  };

  const [imageSizeOfPushMessage, setImageSizeOfPushMessage] = useState<number>(0);
  useEffect(() => {
    if (fcmPush?.imageUrl) {
      fetch('/'+fcmPush.imageUrl)
        .then((r) => r.blob())
        .then((blob) => {
          console.log(blob)
          setImageSizeOfPushMessage(Math.round(blob.size/1000));
        });
    }
  }, [fcmPush?.imageUrl]);

  return (
    <div>
      <div style={style_row_wrapper(isMobile)}>
        <List style={style_gray_box(isMobile)}>
          <ListItem>
            <b>FcmPush ID: {fcmPush?.id}</b>
          </ListItem>
          <ListItem>
            생성일자:{" "}
            {fcmPush?.created ? getFormattedDate(fcmPush.created, "yyyy-MM-dd hh:mm") : ""}
          </ListItem>
          <ListItem>
            전송일자:{" "}
            {fcmPush?.send_start
              ? getFormattedDate(fcmPush.send_start, "yyyy-MM-dd hh:mm")
              : "미전송"}
            &nbsp;
            <Button
              disabled={!!fcmPush?.send_end || totalTarget === 0}
              variant="contained"
              size="small"
              onClick={handleClickSendFcmPush}>
              전송하기
            </Button>
          </ListItem>
          <ListItem>총 메세지수: {fcmPush?.cnt_msgs}</ListItem>
          <ListItem>전송 성공: {fcmPush?.cnt_succ}</ListItem>
          <ListItem>전송 실패: {fcmPush?.cnt_fail}</ListItem>
        </List>
        <List style={style_gray_box(isMobile)}>
          <ListItem>
            <b>선택된 상품 #{item?.id}</b>
          </ListItem>
          <ItemListItem item={item} />
          <ListItem sx={{ display: "flex", justifyContent: "space-between" }}>
            <div>보낼 메세지</div>
            <Button onClick={() => fcmPush && setEditFcm(fcmPush)}>수정</Button>
          </ListItem>

          <PushMessageListItem
            title={fcmPush?.title}
            body={fcmPush?.body}
            imageUrl={fcmPush?.imageUrl}
            dataUrl={fcmPush?.dataUrl}
          />
          <ListItem>
            <ListItemText>
              <b>ImageSize:</b> {numberWithCommas(imageSizeOfPushMessage)} kb
            </ListItemText>
            <ListItemText>
              <b>DataUrl:</b> {fcmPush?.dataUrl}
            </ListItemText>
          </ListItem>
        </List>
      </div>
      <div style={style_row_wrapper(isMobile)}>
        <CandidatesList
          style={style_gray_box(isMobile)}
          list={listCandidate}
          total={totalCandidate}
          limit={soptCandidate.limit ?? 10}
          page={Math.floor((soptCandidate.offset ?? 0) / (soptCandidate.limit ?? 10) + 1)}
          selectPage={(pageNum: number) =>
            loadCandidate({ ...soptCandidate, offset: (pageNum - 1) * (soptCandidate.limit ?? 10) })
          }
          add={handleAdd}
          onOpenCustomUser={() => setOpenSelectUser(true)}
          onOpenCandidateSearch={() => setOpenCandidateSearch(true)}
        />

        <TargetList
          style={style_gray_box(isMobile)}
          list={listTarget}
          total={totalTarget}
          limit={soptTarget.limit ?? 10}
          page={Math.floor((soptTarget.offset ?? 0) / (soptTarget.limit ?? 10) + 1)}
          remove={(user_ids) => removeFcmUserTarget(user_ids)}
          selectPage={(page) =>
            loadTarget({ ...soptTarget, offset: (page - 1) * (soptTarget.limit ?? 10) })
          }
        />
      </div>

      <FcmPushMsgDialog
        open={openEditFcm}
        onClose={(saved) => {
          setEditFcm(undefined);
          if (saved) loadFcmPush(props.fcmPushId);
        }}
        fcmPush={editFcm}
        item_id={item?.id}
      />
      <CandidateSearchOptionDialog
        open={openCandidateSearch}
        onClose={() => setOpenCandidateSearch(false)}
        sopt={soptCandidate}
        setSopt={setSoptCandidate}
        onSearch={() =>
          loadCandidate({ ...soptCandidate, offset: 0 }).then((result) =>
            setOpenCandidateSearch(false)
          )
        }
        item_id={item?.id}
        loadItem={() => item?.id && loadItem(item?.id)}
        addAll={handleAddAll}
      />
      <SelectUserDialog
        open={openSelectUser}
        onClose={() => setOpenSelectUser(false)}
        onSelect={(user) => user.id && handleAdd([user.id])}
      />
      {fcmPush && (
        <FcmPushSendDialog
          open={openSendFcmPush}
          onClose={handleCloseSendFcmPushDialog}
          fcmPush={fcmPush}
        />
      )}
    </div>
  );
};

export default FcmPushDetail;
