import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import OrderDetail from "../pages/order_detail/OrderDetail";

import { useAppCommon } from "../../hooks/useAppCommon";

interface OrderDetailViewProps {}
const OrderDetailView: React.FC<OrderDetailViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "주문정보 디테일" });
  // const classes = useStyles();
  const navi = useNavigate();
  const params = useParams<{ orderId: string }>();
  const orderId = Number(params.orderId);

  return (
    <div className={"orderDetailView "}>
      <IconButton onClick={(e) => navi("/order")}>
        <ArrowBackIosIcon />
        Order List
      </IconButton>
      <br />

      <OrderDetail orderId={orderId} />
    </div>
  );
};

export default OrderDetailView;
