import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import useSnack from "../../../hooks/useSnack";
import { OrderReceipt } from "../../../types/elements";

interface ChangeReceiptDialogProps {
  open: boolean;
  onClose: (completed?: boolean) => void;
  orderReceipt?: OrderReceipt;
}
const ChangeReceiptDialog: React.FC<ChangeReceiptDialogProps> = (props) => {
  const { openSnack } = useSnack();

  const [target, setTarget] = useState<"company" | "personal">("company");
  const [num, setNum] = useState<string>("");
  const [c_num, setCnum] = useState<string>("");
  const [c_bn, setCbn] = useState<string>("");
  const [c_rn, setCrn] = useState<string>("");
  const [c_email, setCemail] = useState<string>("");
  useEffect(() => {
    if (props.open) {
      setTarget(props.orderReceipt?.receipt_target ?? "company");
      setNum(props.orderReceipt?.receipt_number ?? "");
      setCnum(props.orderReceipt?.receipt_c_num ?? "");
      setCbn(props.orderReceipt?.receipt_c_bn ?? "");
      setCrn(props.orderReceipt?.receipt_c_rn ?? "");
      setCemail(props.orderReceipt?.receipt_c_email ?? "");
    }
  }, [props.open]);

  const hasChanging =
    props.orderReceipt?.receipt_target !== target ||
    (target === "company" &&
      (props.orderReceipt.receipt_c_num !== num ||
        props.orderReceipt.receipt_c_bn !== c_bn ||
        props.orderReceipt.receipt_c_rn !== c_rn ||
        props.orderReceipt.receipt_c_email !== c_email)) ||
    (target === "personal" && props.orderReceipt.receipt_number !== num);

  const handleChange = () => {
    if (hasChanging) {
      gqladm
        .changeOrderReceipt({
          id: props.orderReceipt?.id,
          receipt_target: target,
          receipt_c_bn: target === "company" ? c_bn : "",
          receipt_c_num: target === "company" ? c_num : "",
          receipt_c_rn: target === "company" ? c_rn : "",
          receipt_c_email: target === "company" ? c_email : "",
          receipt_number: target === "personal" ? num : "",
        })
        .then((result) => {
          if (result.ok) {
            props.onClose(true);
          } else {
            openSnack({ msg: result.msg ?? "에러가 발생하였습니다. " });
          }
        });
    } else {
      openSnack({ msg: "수정된 항목이 없습니다. " });
    }
  };
  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>세금계산서/현금영수증 정보 수정</DialogTitle>
      <DialogContent>
        세금계산서/현금영수증 선택
        <br />
        <FormControlLabel
          label="세금계산서"
          control={
            <Radio
              readOnly
              checked={target === "company"}
              color={
                props.orderReceipt?.receipt_target !== target && target === "company"
                  ? "error"
                  : "default"
              }
              onClick={() => setTarget("company")}
            />
          }
        />
        <FormControlLabel
          label="현금영수증"
          control={
            <Radio
              readOnly
              checked={target === "personal"}
              color={
                props.orderReceipt?.receipt_target !== target && target === "personal"
                  ? "error"
                  : "default"
              }
              onClick={() => setTarget("personal")}
            />
          }
        />
        <br />
        {target === "company" ? (
          <>
            사업자 번호 {props.orderReceipt?.receipt_c_num} =&gt;
            <TextField
              value={c_num}
              size="small"
              fullWidth
              onChange={(e) => setCnum(e.target.value)}
              error={props.orderReceipt?.receipt_c_num !== c_num}
            />
            <br />
            사업자 명칭 {props.orderReceipt?.receipt_c_bn} =&gt;
            <TextField
              value={c_bn}
              size="small"
              fullWidth
              onChange={(e) => setCbn(e.target.value)}
              error={props.orderReceipt?.receipt_c_bn !== c_bn}
            />
            사업주 이름 {props.orderReceipt?.receipt_c_rn} =&gt;
            <TextField
              value={c_rn}
              size="small"
              fullWidth
              onChange={(e) => setCrn(e.target.value)}
              error={props.orderReceipt?.receipt_c_rn !== c_rn}
            />
            사업주 이메일 {props.orderReceipt?.receipt_c_email} =&gt;
            <TextField
              value={c_email}
              fullWidth
              onChange={(e) => setCemail(e.target.value)}
              error={props.orderReceipt?.receipt_c_email !== c_email}
            />
          </>
        ) : (
          <>
            휴대전화번호 {props.orderReceipt?.receipt_number} =&gt;
            <TextField
              value={num}
              size="small"
              fullWidth
              onChange={(e) => setNum(e.target.value)}
              error={props.orderReceipt?.receipt_number !== num}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleChange}>변경</Button>
        <Button onClick={() => props.onClose()}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeReceiptDialog;
