import { Button, IconButton } from "@mui/material";
import React from "react";
import { getThumbUrl, Image, Item } from "../../../types/elements";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import useEnvironment from "../../../hooks/useEnvironment";

interface MiniItemInfoProps {
  item: Item;
  onClose?: () => void;
  onSelectItemUrl?: (url: string) => void;
  onSelectImageUrl?: (url: string) => void;
}
const MiniItemInfo: React.FC<MiniItemInfoProps> = ({
  item,
  onClose,
  onSelectItemUrl,
  onSelectImageUrl,
}) => {
  const { getServiceUrl, getServiceApiUrl } = useEnvironment();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid lightgray",
        padding: "0.5rem",
      }}>
      <div>
        {item.id} / name: {item.name} / {item.price}{" "}
        <IconButton
          size="small"
          onClick={() => onSelectItemUrl && onSelectItemUrl(getServiceUrl() + "/item/" + item.id)}>
          <ArrowCircleDownIcon />
        </IconButton>
        <br />
        <div style={{ display: "flex" }}>
          {item.topImages &&
            item.topImages.map((img) => {
              const imgurl = img.url ? getServiceApiUrl() + "/" + getThumbUrl(img.url) : "";
              return (
                img.url && (
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <img
                      key={`img-${img.id}`}
                      src={imgurl}
                      style={{ width: "100px", height: "100px", objectFit: "contain" }}
                    />
                    <IconButton
                      size="small"
                      onClick={() => onSelectImageUrl && onSelectImageUrl(imgurl)}>
                      <ArrowCircleDownIcon />
                    </IconButton>
                  </div>
                )
              );
            })}
        </div>
      </div>

      <Button onClick={() => onClose && onClose()}>선택해제</Button>
    </div>
  );
};

export default MiniItemInfo;
