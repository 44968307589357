import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { useWindowSize } from "../../../hooks/useWindowSize";
import ItemDetailView from "./ItemDetailView";

interface ItemDetailDialogProps {
  itemId?: number;
  onClose?: () => void;
}
const ItemDetailDialog: React.FC<ItemDetailDialogProps> = ({ itemId, onClose }) => {
  const { isMobile } = useWindowSize();
  return (
    <Dialog
      open={itemId !== undefined}
      onClose={() => onClose && onClose()}
      fullWidth
      fullScreen={isMobile}
      maxWidth="xl">
      <DialogTitle>상품 디테일</DialogTitle>
      <DialogContent sx={{ "&.MuiDialogContent-root": { padding: isMobile ? 0 : undefined } }}>
        {itemId !== undefined && <ItemDetailView itemId={itemId} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose && onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemDetailDialog;
