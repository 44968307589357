import { gql } from "@apollo/react-hooks";
import { client } from "../client";
import {
  convertUser,
  SearchResult,
  SimpleResult,
  User,
  UserAlertInfoSearchOption,
  UserNoti,
  UserNotiSearchOption,
} from "../../types/elements";
import { UserSearchOption } from "../../types/adm_elements";

interface QueryIF {
  admin?: {
    users?: SearchResult<User>;
    usersByAlertInfo?: SearchResult<User>;
    userNotis?: SearchResult<UserNoti>;
  };
}
interface MutationIF {
  admin?: {
    addOfflineUser?: SimpleResult;
    updateUserPhone?: SimpleResult;
    deleteUser?: SimpleResult;
    syncUserNumFields?: SimpleResult;
    withdrawUser?: SimpleResult;
  };
}

export const UserQueryApi = {
  getUsers: async (sopt: UserSearchOption): Promise<SearchResult<User>> => {
    return await client
      .query<QueryIF>({
        query: gql`
          query GetUsers($sopt: UserSearchInput) {
            admin {
              users(sopt: $sopt) {
                limit
                offset
                total
                list {
                  id
                  created
                  type
                  email
                  name
                  phone
                  isEmailAuthorized
                  isPhoneAuthorized
                  agreeEmailDate
                  agreeSMSDate
                  sessionKey
                  sessionExpired
                  zipcode
                  addr1
                  addr2

                  num_updated
                  num_order_done_count
                  num_order_done_price
                  num_reservation_noshow
                  num_reservation_done
                  num_service_request_done

                  userMemos(sopt: { limit: 1 }) {
                    limit
                    total
                    offset
                    list {
                      memo
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then((result) => {
        if (result.data.admin?.users)
          return {
            ...result.data.admin.users,
            list: result.data.admin.users.list
              ? (result.data.admin.users.list.map(convertUser) as User[])
              : [],
          };
        else throw new Error("Error");
      });
  },
  getUsersByAlertInfo: async (sopt: UserAlertInfoSearchOption) =>
    client
      .query<QueryIF>({
        query: gql`
          query GetUsersByAlertInfo($sopt: UserAlertInfoSearchInput) {
            admin {
              usersByAlertInfo(sopt: $sopt) {
                limit
                offset
                total
                list {
                  id
                  name
                  num_active_devices
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result.data.admin?.usersByAlertInfo)
          return {
            ...result.data.admin.usersByAlertInfo,
            list: result.data.admin.usersByAlertInfo.list
              ? (result.data.admin.usersByAlertInfo.list.map(convertUser) as User[])
              : [],
          };
        else throw new Error("Error");
      }),

  getUserNotis: async (sopt: UserNotiSearchOption) =>
    client
      .query<QueryIF>({
        query: gql`
          query GetUserNotis($sopt: UserNotiSearchInput) {
            admin {
              userNotis(sopt: $sopt) {
                total
                offset
                limit
                list {
                  id
                  user_id
                  created
                  readed
                  title
                  body
                  imageUrl
                  dataUrl
                  fp_id
                  user {
                    name
                  }
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result.data.admin?.userNotis) return result.data.admin?.userNotis;
        else throw new Error("Err");
      }),
};

export const UserApi = {
  addOfflineUser: async (user: User) => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddOfflineUser($user: UserInput) {
            admin {
              addOfflineUser(user: $user) {
                ok
                id
                msg
              }
            }
          }
        `,
        variables: { user },
      })
      .then((result) => {
        if (result.data?.admin?.addOfflineUser) return result.data.admin.addOfflineUser;
        else throw new Error("Err");
      });
  },
  updateUserPhone: async (id: number, phone: string) => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation updateUserPhone($id: BigInt, $phone: String) {
            admin {
              updateUserPhone(id: $id, phone: $phone) {
                ok
                count
                msg
              }
            }
          }
        `,
        variables: { id, phone },
      })
      .then((result) => {
        if (result.data?.admin?.updateUserPhone) return result.data?.admin?.updateUserPhone;
        else throw new Error("Err");
      });
  },
  deleteUser: async (user_id: number, phone: string) =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation DeleteUser($user_id: BigInt, $phone: String) {
            admin {
              deleteUser(user_id: $user_id, phone: $phone) {
                ok
                count
                code
                value
              }
            }
          }
        `,
        variables: { user_id, phone },
      })
      .then((result) => {
        if (result.data?.admin?.deleteUser) return result.data?.admin?.deleteUser;
        else throw new Error("Err");
      }),
  syncUserNumFields: async (user_ids: number[]) =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SyncUserNumFields($user_ids: [BigInt]) {
            admin {
              syncUserNumFields(user_ids: $user_ids) {
                ok
                count
              }
            }
          }
        `,
        variables: { user_ids },
      })
      .then((result) => {
        if (result.data?.admin?.syncUserNumFields) return result.data?.admin?.syncUserNumFields;
        else throw new Error("Err");
      }),
};
