import { Avatar, IconButton, Menu, MenuItem, Paper } from "@mui/material";
import React, { useState } from "react";
import { User } from "../../../../types/elements";
import { UserDetailViewIdType } from "./UserDetailView";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import UserDetailAccountInfo from "./UserDetailAccountInfo";
import UserDetailDashboard from "./UserDetailDashboard";
import UserDetailOrders from "./order/UserDetailOrder";
import UserDetailRequest from "./request/UserDetailRequest";
import UserDetailReservation from "./UserDetailReservations";
import UserDetailWish from "./UserDetailWish";
import UserMemoList from "../../../organisms/userinfodialog/UserMemoList";
interface UserInfoMWLayoutProps {
  user: User;
  user_id: number;
  view_id: UserDetailViewIdType;
  onChangeViewId : (viewId:UserDetailViewIdType) => void;
}
const UserInfoMWLayout: React.FC<UserInfoMWLayoutProps> = (props) => {

  const userId = props.user_id;
  const viewId: UserDetailViewIdType =
    ["", undefined].indexOf(props.view_id) > -1 ? "dashboard" : props.view_id;
  const go = (targetViewId: UserDetailViewIdType) => {
    // navi(`/user/${props.user.id}/${targetViewId}`);
    props.onChangeViewId(targetViewId);
    setMenuEl(null);
  };

  const [menuEl, setMenuEl] = useState<HTMLElement | null>(null);
  const openMenu = Boolean(menuEl);

  return (
    <div className="userInfoMWLayout">
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>{props.user.name}</div>
        <div>
          {viewId === "dashboard"
            ? "DashBoard"
            : viewId === "account_information"
            ? "Account Information"
            : viewId === "order"
            ? "Orders"
            : viewId === "reservation"
            ? "Reservation"
            : viewId === "request"
            ? "Service Request"
            : viewId === "wish"
            ? "Wish List"
            : ""}
        </div>
        <IconButton onClick={(e) => setMenuEl(e.currentTarget)}>
          <MenuIcon />
        </IconButton>
        <Menu open={openMenu} anchorEl={menuEl} onClose={() => setMenuEl(null)}>
          <MenuItem onClick={() => go("dashboard")} selected={viewId === "dashboard"}>
            DashBoard
          </MenuItem>
          <MenuItem
            onClick={() => go("account_information")}
            selected={viewId === "account_information"}>
            Account Infomation
          </MenuItem>
          <MenuItem onClick={() => go("order")} selected={viewId === "order"}>
            Orders
          </MenuItem>
          <MenuItem onClick={() => go("reservation")} selected={viewId === "reservation"}>
            Reservation
          </MenuItem>
          <MenuItem onClick={() => go("request")} selected={viewId === "request"}>
            Service Request
          </MenuItem>
          <MenuItem onClick={() => go("wish")} selected={viewId === "wish"}>
            Wish List
          </MenuItem>
        </Menu>
        
      </div>

      <div>
      <UserMemoList user_id={userId}/>
        {viewId === "dashboard" ? (
          <UserDetailDashboard user_id={userId} />
        ) : viewId === "account_information" ? (
          <UserDetailAccountInfo user_id={userId} />
        ) : viewId === "order" ? (
          <UserDetailOrders user_id={userId} />
        ) : viewId === "reservation" ? (
          <UserDetailReservation user_id={userId} />
        ) : viewId === "request" ? (
          <UserDetailRequest user_id={userId} />
        ) : viewId === "wish" ? (
          <UserDetailWish />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default UserInfoMWLayout;
