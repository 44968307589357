import { Delete } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gqladm } from "../../../../graphql/gqladm";
import useSnack from "../../../../hooks/useSnack";
import { User } from "../../../../types/elements";

const SimpleTr: React.FC<{ title?: string; body?: any }> = (props) => {
  return (
    <tr>
      <td style={{ textAlign: "left", paddingRight: "2rem" }}>{props.title}&nbsp;</td>
      <td style={{ paddingLeft: "2rem", borderLeft: "1px solid black " }}>{props.body}</td>
    </tr>
  );
};

interface UserDetailAccountInfoProps {
  user_id: number;
}
const UserDetailAccountInfo: React.FC<UserDetailAccountInfoProps> = (props) => {
  const navi = useNavigate();
  const [user, setUser] = useState<User | undefined>(undefined);
  const { openSnack } = useSnack();
  useEffect(() => {
    if (props.user_id) {
      gqladm.getUsers({ id: props.user_id, orderby: "created_desc", limit: 10 }).then((result) => {
        if (result.list && result.list.length === 1) setUser(result.list[0]);
      });
    }
  }, [props.user_id]);

  const handleDeleteUser = () => {
    if (
      user &&
      user.id &&
      user.phone &&
      prompt(
        `아래 유저를 삭제하시겠습니까? 삭제하시려면 삭제라고 입력해주세요. \n id:${user.id} name:${user.name}`
      ) === "삭제"
    ) {
      gqladm.deleteUser(user.id, user.phone).then((result) => {
        if (result.ok) openSnack({ msg: "유저가 삭제 되었습니다. ", severity: "error" });
        else openSnack({ msg: "에러가 발생했습니다 - " + result.code, severity: "error" });
        navi("/user");
      });
    } else {
      openSnack({ msg: "유저 삭제가 취소 되었습니다. ", severity: "warning" });
    }
  };

  return (
    <div className="simple1panel">
      <div className="simple1panel_head">UserDetailAccountInfo</div>
      <div className="simple1panel_body">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div></div>
          <div>
            <Button variant="contained" color="error" onClick={handleDeleteUser}>
              <Delete /> Delete User
            </Button>
          </div>
        </div>
        {user === undefined ? (
          <CircularProgress />
        ) : (
          <div>
            <table cellSpacing={0}>
              <tbody>
                <SimpleTr title="ID" body={user.id} />

                <SimpleTr title="회원가입" body={user.created} />
                <SimpleTr title="최근 로그인" body={user.sessionExpired} />

                <SimpleTr title="이름" body={user.name} />
                <SimpleTr title="이메일" body={user.email} />
                <SimpleTr title="전화번호" body={user.phone} />

                <SimpleTr title="주소1" body={user.addr1} />
                <SimpleTr title="주소2" body={user.addr2} />
                <SimpleTr title="우편번호" body={user.zipcode} />

                <SimpleTr title="마케팅 정보 수신동의" />
                <SimpleTr title="E-mail" body={user.agreeEmailDate} />
                <SimpleTr title="SMS" body={user.agreeSMSDate} />
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetailAccountInfo;
