import React, { useState, useEffect } from "react";

import {
  Order,
  getKrOrderStatus,
  OrderStatusArray,
  OrderSearchOption,
  SearchResult,
  StatusCount,
} from "../../../types/elements";
import { Button, Chip, ButtonGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { gqladm } from "../../../graphql/gqladm";

import Pagination from "@mui/material/Pagination";
import { useAppCommon } from "../../../hooks/useAppCommon";
import NewOrderDialog from "../neworderdialog/NewOrderDialog";
import useSnack from "../../../hooks/useSnack";
import OrderResultTable from "./OrderResultTable";
import { useWindowSize } from "../../../hooks/useWindowSize";
import OrderResultList from "./OrderResultList";

const LIMIT = 20;
export interface OrderResultProps {
  list: Order[];
  openDetail?: (id: number) => any;
}
interface OrderListProps {}
const OrderListView: React.FC<OrderListProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "주문 리스트" });
  const navi = useNavigate();
  const { openSnack } = useSnack();
  const { isMobile } = useWindowSize();

  // Order list and search options
  const [orders, setOrders] = useState<Array<Order>>([]);
  const [total, setTotal] = useState<number>(0);
  const [sopt, setSopt] = useState<OrderSearchOption>({
    ordering: "created_desc",
    statuses: ["INITIALIZED", "ORDERED", "CHECKING", "PREPARING", "DELIVERYING", "DONE"],
  });

  const loadOrders = (opt: OrderSearchOption) => {
    gqladm
      .getOrders(opt)
      .then((result: SearchResult<Order>) => {
        if (result.list) setOrders(result.list);
        if (result.total) setTotal(result.total);
        setSopt({
          ...sopt,
          limit: result.limit !== undefined ? result.limit : sopt.limit,
          offset: result.offset !== undefined ? result.offset : sopt.offset,
        });
      })
      .catch((err: any) => {
        console.log(err);
        openSnack({ msg: "Error on loadOrders " + err });
      });
  };

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      loadOrders({ ...sopt, offset: offset });
    }
  };

  // Order Counts
  const [statusCounts, setStatusCounts] = useState<StatusCount[]>([]);
  const loadOrderStatusCounts = () => {
    gqladm
      .getOrderStatusCounts()
      .then((result) => setStatusCounts(result))
      .catch((err: any) => {
        console.log(err);
        openSnack({ msg: "Error on loadOrders " + err });
      });
  };

  // On Load
  useEffect(() => {
    loadOrders(sopt);
    loadOrderStatusCounts();
  }, []);

  const handleOpenDetail = (id: number) => {
    navi("/order/" + id);
  };

  const [openNewOrder, setOpenNewOrder] = useState<boolean>(false);
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div></div>
        <Button onClick={() => setOpenNewOrder(true)}>새 주문 생성</Button>
        <NewOrderDialog open={openNewOrder} onClose={() => setOpenNewOrder(false)} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid gray",
          padding: "10px",
          margin: "10px",
        }}>
        <div style={{ display: "flex" }}>Order Search</div>
        <div>
          <div style={{ margin: "0.5rem 0" }}>
            STATUS -
            {OrderStatusArray.map((os, osIdx) => {
              const count = statusCounts.find((o) => o.status === os)?.count;
              const handleStatusCheck = () => {
                sopt.statuses && sopt.statuses.indexOf(os) > -1
                  ? setSopt({ ...sopt, statuses: sopt.statuses.filter((o) => o !== os) })
                  : setSopt({
                      ...sopt,
                      statuses: [...(sopt.statuses ? sopt.statuses : []), os],
                    });
              };
              return (
                <Chip
                  color={
                    (sopt.statuses ? sopt.statuses : []).indexOf(os) > -1 ? "primary" : "default"
                  }
                  key={osIdx}
                  label={getKrOrderStatus(os) + (count ? `(${count})` : "")}
                  onClick={handleStatusCheck}
                />
              );
            })}
          </div>
          <div style={{ margin: "0.5rem 0" }}>
            Type -
            <ButtonGroup size="small" variant="contained">
              <Button
                color={sopt.type === undefined ? "primary" : "inherit"}
                onClick={(e) => setSopt((opt) => ({ ...opt, type: undefined }))}>
                All
              </Button>
              <Button
                color={sopt.type === "Online" ? "primary" : "inherit"}
                onClick={(e) => setSopt((opt) => ({ ...opt, type: "Online" }))}>
                Online
              </Button>
              <Button
                color={sopt.type === "Offline" ? "primary" : "inherit"}
                onClick={(e) => setSopt((opt) => ({ ...opt, type: "Offline" }))}>
                Offline
              </Button>
            </ButtonGroup>
          </div>
          <div style={{ margin: "0.5rem 0" }}>
            정렬순서 -
            <ButtonGroup size="small" variant="contained">
              <Button
                color={sopt.ordering === undefined ? "primary" : "inherit"}
                onClick={(e) => setSopt({ ...sopt, ordering: undefined })}>
                No
              </Button>
              <Button
                color={sopt.ordering === "created_desc" ? "primary" : "inherit"}
                onClick={(e) => setSopt({ ...sopt, ordering: "created_desc" })}>
                최근부터
              </Button>
              <Button
                color={sopt.ordering === "created_asc" ? "primary" : "inherit"}
                onClick={(e) => setSopt({ ...sopt, ordering: "created_asc" })}>
                과거부터
              </Button>
            </ButtonGroup>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>검색 결과 총 {total} 건</div>

            <Button color="primary" variant="contained" onClick={() => loadOrders(sopt)}>
              Search
            </Button>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              size="small"
              onChange={handleChangePage}
              count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
              page={
                Math.ceil((sopt.offset ? sopt.offset : 0) / (sopt.limit ? sopt.limit : LIMIT)) + 1
              }
            />
          </div>
        </div>
      </div>

      <br />
      {isMobile ? (
        <OrderResultList list={orders} openDetail={handleOpenDetail} />
      ) : (
        <OrderResultTable list={orders} openDetail={handleOpenDetail} />
      )}
    </div>
  );
};

export default OrderListView;
