import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useEffect } from "react";
import { useAppCommon } from "../../../../hooks/useAppCommon";
import { useWithDrawUser } from "./useWithDrawUser";

interface WithDrawUserListProps {}
const WithDrawUserList: React.FC<WithDrawUserListProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "탈퇴 유저 목록" });
  const { list, load } = useWithDrawUser();

  useEffect(() => {
    load();
  }, []);
  return (
    <div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>admin_id</TableCell>
            <TableCell>created</TableCell>
            <TableCell>user_id</TableCell>
            <TableCell>email</TableCell>
            <TableCell>name</TableCell>
            <TableCell>phone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((o) => {
            return (
              <TableRow key={"withdraw_" + o.id}>
                <TableCell>{o.id}</TableCell>
                <TableCell>{o.admin_id}</TableCell>
                <TableCell>{o.created}</TableCell>
                <TableCell>{o.user_id}</TableCell>
                <TableCell>{o.email}</TableCell>
                <TableCell>{o.name}</TableCell>
                <TableCell>{o.phone}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default WithDrawUserList;
