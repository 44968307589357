import { useEffect, useState } from "react";
import { gqladm } from "../graphql/gqladm";
import { HOUR } from "../types/elements";

export const useDashDeviceCount = () => {
  const [deviceCount, setDeviceCount] = useState<number | undefined>();
  const [userDeviceCount, setUserDeviceCount] = useState<number | undefined>();
  const [activeDeviceCount, setActiveDeviceCount] = useState<number | undefined>();
  const [activeHour, setActiveHour] = useState<number>(12);

  useEffect(() => {
    gqladm.getUserDevices({ limit: 1 }).then((result) => {
      if (result.total !== undefined) setDeviceCount(result.total);
    });
    gqladm.getUserDevices({ limit: 1, hasUser: true }).then((result) => {
      if (result.total !== undefined) setUserDeviceCount(result.total);
    });
  }, []);

  useEffect(() => {
    gqladm
      .getUserDevices({ limit: 1, beforeUpdated: Date.now() - activeHour * HOUR })
      .then((result) => {
        if (result.total !== undefined) setActiveDeviceCount(result.total);
      });
  }, [activeHour]);
  return {
    total_device_count: deviceCount,
    user_device_count: userDeviceCount,
    activeDeviceCount,
    activeHour,
    setActiveHour,
  };
};
