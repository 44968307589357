import React from "react";
import { Button } from "@mui/material";
import { gqladm } from "../../../graphql/gqladm";
import { useNavigate } from "react-router";

interface AddBoardButtonProps {}
const AddBoardButton: React.FC<AddBoardButtonProps> = (props) => {
  const navi = useNavigate();
  const handleClick = () => {
    gqladm.addBoard().then((result) => {
      if (result.ok && result.id) {
        navi("/board/" + result.id);
      }
    });
  };
  return <Button onClick={handleClick}>Add Board</Button>;
};

export default AddBoardButton;
