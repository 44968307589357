import { Paper, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useAppCommon } from "../../../hooks/useAppCommon";

import ItemMetaList from "./ItemMetaList";
import ItemMetaTree from "./ItemMetaTree";

interface ItemMetaViewProps {}
const ItemMetaView: React.FC<ItemMetaViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "ItemMeta" });
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Category"></Tab>
        <Tab label="Brand"></Tab>
        <Tab label="Designer"></Tab>
      </Tabs>

      <Paper style={{ padding: "10px" }}>
        {tabValue === 0 && <ItemMetaTree type={"Category"} />}
        {tabValue === 1 && <ItemMetaList type={"Brand"} />}
        {tabValue === 2 && <ItemMetaList type={"Designer"} />}
      </Paper>
    </div>
  );
};

export default ItemMetaView;
