import React, { useState, useEffect, ChangeEvent } from "react";
import { useParams, Link } from "react-router-dom";

import { useSetRecoilState, useRecoilValue } from "recoil";
import { SnackStateSelector } from "../../../store/states/SnackState";

import {
  Item,
  ItemStatusArr,
  ItemVisibleArr,
  ItemOption,
  ItemPriceOpenArr,
  ItemShowHideArr,
  ItemMetaType,
  ItemMeta,
} from "../../../types/elements";

import ItemOptionStockTable from "../../organisms/ItemOptionStockTable";

import {
  TextField,
  Divider,
  IconButton,
  Paper,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Radio,
  SelectChangeEvent,
} from "@mui/material";

import {
  Save as SaveIcon,
  List as ListIcon,
  Add as AddIcon,
  Cancel as CancelIcon,
  Sync as SyncIcon,
} from "@mui/icons-material";

import ItemDetailImageInfo from "./ItemDetailImageInfo";
import ItemDetailPropertyInfo from "./ItemDetailPropertyInfo";
import { gqladm } from "../../../graphql/gqladm";
import { ItemMetaState } from "../../../store/states/ItemMetaState";

import useItemMeta from "../../../hooks/useItemMeta";
import { useAppCommon } from "../../../hooks/useAppCommon";
import ItemMetaDialog from "../itemMeta/ItemMetaDialog";
import OrderedOrderList from "./OrderedOrderList";

type TypeOfHandleChange =
  | "name"
  | "search_text"
  | "description"
  | "price"
  | "priceBefore"
  | "visible"
  | "status"
  | "priceOpen";

interface ItemChangeCheck {
  changed?: boolean;
  name?: boolean;
  search_text?: boolean;
  category_id?: boolean;
  brand_id?: boolean;
  designer_id?: boolean;

  visible?: boolean;
  status?: boolean;
  priceOpen?: boolean;
  price?: boolean;
  priceBefore?: boolean;
  vatAdded?: boolean;
  sh_itemoptions?: boolean;
  sh_hashtag?: boolean;
  hashtag?: boolean;
  sh_desc?: boolean;
  description?: boolean;
}
interface ItemDetailViewProps {
  itemId?: number;
}

const ItemDetailView: React.FC<ItemDetailViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "상품 정보" });

  // const classes = useStyles();
  const openSnack = useSetRecoilState(SnackStateSelector);

  const itemMetaState = useRecoilValue(ItemMetaState);
  const { refresh: refreshItemMeta } = useItemMeta();

  const params = useParams<{ itemId: string }>();
  const itemId = Number(props.itemId ? props.itemId : params.itemId);

  // item state
  const [loadingItem, setLoadingItem] = useState<boolean>(false);
  const [item, setItem] = useState<Item>({});

  // * change check
  const [changeCheck, setChangeCheck] = useState<ItemChangeCheck>({});
  const [loadedItem, setLoadedItem] = useState<Item>({});
  const handleChangeCheck = (item: Item) => {
    const chk: ItemChangeCheck = {};
    if (item.name !== loadedItem.name) chk.name = chk.changed = true;
    if (item.search_text !== loadedItem.search_text) chk.search_text = chk.changed = true;

    if (item.visible !== loadedItem.visible) chk.visible = chk.changed = true;
    if (item.status !== loadedItem.status) chk.status = chk.changed = true;
    if (item.priceOpen !== loadedItem.priceOpen) chk.priceOpen = chk.changed = true;
    if (item.price !== loadedItem.price) chk.price = chk.changed = true;
    if (item.priceBefore !== loadedItem.priceBefore) chk.priceBefore = chk.changed = true;
    if (item.vatAdded !== loadedItem.vatAdded) chk.vatAdded = chk.changed = true;
    if (item.sh_itemoptions !== loadedItem.sh_itemoptions) chk.sh_itemoptions = chk.changed = true;
    if (item.sh_hashtag !== loadedItem.sh_hashtag) chk.sh_hashtag = chk.changed = true;
    if (item.hashtag !== loadedItem.hashtag) chk.hashtag = chk.changed = true;
    if (item.sh_desc !== loadedItem.sh_desc) chk.sh_desc = chk.changed = true;
    if (item.category_id !== loadedItem.category_id) chk.category_id = chk.changed = true;
    if (item.brand_id !== loadedItem.brand_id) chk.brand_id = chk.changed = true;
    if (item.designer_id !== loadedItem.designer_id) chk.designer_id = chk.changed = true;

    if (item.description !== loadedItem.description) chk.description = chk.changed = true;
    setChangeCheck(chk);
  };

  useEffect(() => {
    loadAll();
  }, []);

  // Load All data
  const loadAll = () => {
    setLoadingItem(true);
    gqladm
      .getItem(itemId)
      .then((result) => {
        setLoadingItem(false);
        setItem(result);
        if (result.itemOptions) setOptions(result.itemOptions);
        setLoadedItem(result);
        setChangeCheck({});
      })
      .catch((err) => {
        setLoadingItem(false);
        openSnack({ msg: "Error on loading, " + err, severity: "error" });
      });
  };

  const handleChangeCategoryId = (e: SelectChangeEvent<number>) => {
    const category_id = Number(e.target.value);
    setItem((item) => {
      const _item = { ...item, category_id: category_id };
      const category = itemMetaState.category.find((o) => o.id === category_id);
      if (category?.treenodes)
        _item.category_ids = `[,${category.treenodes?.map((o) => o.id).join(",")},]`;
      handleChangeCheck(_item);
      return _item;
    });
  };
  const handleChangeBrandId = (e: SelectChangeEvent<number>) => {
    const _item = { ...item, brand_id: Number(e.target.value) };
    setItem(_item);
    handleChangeCheck(_item);
  };
  const handleChangeDesignerId = (e: SelectChangeEvent<number>) => {
    const _item = { ...item, designer_id: Number(e.target.value) };
    setItem(_item);
    handleChangeCheck(_item);
  };

  const [tempIteMeta, setTempItemMeta] = useState<ItemMeta | undefined>();
  const handleClickAddItemMeta = (type: ItemMetaType) => {
    setTempItemMeta({ type, seq: 0 });
  };

  const handleChange = (e: any, fieldName: TypeOfHandleChange) => {
    const _item: any = { ...item };
    if (["price"].includes(fieldName)) {
      const newValue = Number(e.target.value);
      if (!isNaN(newValue)) {
        _item[fieldName] = newValue;
      }
    } else if (["priceBefore"].includes(fieldName)) {
      const newValue = Number(e.target.value);
      if (!isNaN(newValue)) {
        _item[fieldName] = newValue;
      }
      _item["discountRate"] =
        _item.price && _item.priceBefore && _item.price < _item.priceBefore
          ? Math.floor(((_item.priceBefore - _item.price) / _item.priceBefore) * 100)
          : 0;
    } else {
      _item[fieldName] = e.target.value;
    }
    console.log(_item);
    setItem(_item);
    handleChangeCheck(_item);
  };
  const handleChangeRadio = (
    fieldName:
      | "visible"
      | "status"
      | "priceOpen"
      | "vatAdded"
      | "sh_itemoptions"
      | "sh_hashtag"
      | "sh_desc",
    value: any
  ) => {
    const _item = { ...item };
    _item[fieldName] = value;
    setItem(_item);
    handleChangeCheck(_item);
  };

  const handleChangeHashTag = (e: ChangeEvent<{ name?: string; value: string }>) => {
    console.log("handleChangeHashTag", e);
    const value = e.target.value;
    const hashtags =
      value
        .split(" ")
        .map((o) => o.replace("#", ""))
        .filter((o) => o)
        .map((o) => "#" + o)
        .join(" ") + (value[value.length - 1] === " " ? " " : "");
    const _item: Item = { ...item, hashtag: hashtags };
    setItem(_item);
    handleChangeCheck(_item);
  };

  const handleSaveItem = (e: any) => {
    console.log("handleSaveItem", item);
    const errFields = [];
    if (!(item.status && ItemStatusArr.indexOf(item.status) > -1)) errFields.push("status");
    if (item.price === undefined) errFields.push("price");
    if (!(item.priceOpen && ItemPriceOpenArr.indexOf(item.priceOpen) > -1))
      errFields.push("priceOpen");
    if (!(item.visible && ItemVisibleArr.indexOf(item.visible) > -1)) errFields.push("visible");
    if (item.vatAdded === undefined) errFields.push("vatAdded");
    if (item.sh_desc === undefined) errFields.push("sh_desc");
    if (item.sh_hashtag === undefined) errFields.push("sh_hashtag");
    if (item.sh_itemoptions === undefined) errFields.push("sh_itemoptions");

    if (errFields.length > 0) {
      openSnack({
        msg: "상품정보 필드 " + errFields.join(",") + "에 오류가 있습니다. ",
        severity: "error",
      });
      return;
    }

    gqladm
      .saveItem({
        id: item.id,
        type: item.type,
        visible: item.visible,
        status: item.status,
        priceOpen: item.priceOpen,
        price: item.price,
        priceBefore: item.priceBefore,
        vatAdded: item.vatAdded,
        defaultItemOptionId: item.defaultItemOptionId,

        // sort_level,stock_avail  // * Not need to update
        search_text: item.search_text,
        name: item.name,
        description: item.description,
        hashtag: item.hashtag,

        category_ids: item.category_ids,
        category_id: Number(item.category_id),
        brand_id: Number(item.brand_id),
        designer_id: Number(item.designer_id),

        sh_hashtag: item.sh_hashtag,
        sh_itemoptions: item.sh_itemoptions,
        sh_desc: item.sh_desc,

        parent_id: item.parent_id,
      })
      .then((result) => {
        if (result) {
          console.log("saveItem", result);
          openSnack({ msg: "상품정보가 저장되었습니다. " });
          loadAll();
        }
      })
      .catch((err) => {
        console.log("Error", err);
        openSnack({
          msg: "상품정보 저장이 실패하였습니다. " + err,
          severity: "error",
        });
      });
  };

  const handleCancelSave = () => {
    const _item = { ...loadedItem };
    setItem(_item);
    handleChangeCheck(_item);
  };

  const handleSetDefaultItemOption = (io: ItemOption) => {
    console.log("handleSetDefaultItemOption", io);
    if (io.itemId && io.id)
      gqladm
        .setDefaultItemOption(io.itemId, io.id)
        .then((result: Item) => {
          if (result) setItem({ ...item, defaultItemOptionId: result.defaultItemOptionId });
        })
        .catch((err: any) => {
          console.log("Error ", err);
        });
  };

  const handleSyncItemStockAvail = (itemId: number) => {
    gqladm
      .syncItemsStockAvail([itemId])
      .then((result) => {
        if (result !== undefined) {
          openSnack({
            msg: (result.ok ? "SUCCESS" : "FAIL") + " - Sync item avail  = " + result.count,
          });
          loadAll();
          refreshOptions();
        } else openSnack({ msg: "Error Sync item avail" });
      })
      .catch((err: any) => {
        openSnack({ msg: "Error Sync item avail" });
        console.log("err", err);
      });
  };

  const [options, setOptions] = useState<ItemOption[]>([]);
  const refreshOptions = () => {
    if (props.itemId !== undefined)
      gqladm.getItemOptions(props.itemId).then((result) => {
        setOptions(result);
      });
  };

  // Order count
  const [orderCount, setOrderCount] = useState<number | undefined>();

  return (
    <div className={/* classes.root */ "itemDetailView"}>
      {props.itemId === undefined && (
        <div>
          <Link to="/item">
            <IconButton>
              <ListIcon />
              목록으로
            </IconButton>
          </Link>
        </div>
      )}
      <Paper className="paragraph editBody" style={{ margin: "1rem", padding: "1rem" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h1>상품정보</h1>
          <div>
            {changeCheck.changed && (
              <span style={{ color: "red" }}>
                상품상세정보에 수정된 정보가 있습니다. 저장하지 않으면 수정사항이 유실됩니다.
              </span>
            )}
          </div>
        </div>
        <div className={loadingItem ? "loadingPaper" : ""}>
          <div className="row">
            <div className="label">ID</div>
            <div className="content">
              <TextField fullWidth value={item.id} disabled />
            </div>
            <div className="label">TYPE</div>
            <div className="content">
              <TextField fullWidth value={item.type} disabled />
            </div>
          </div>

          <div className="row">
            <div className="label">NAME</div>
            <div className="content">
              <TextField
                fullWidth
                value={item.name}
                onChange={(e: any) => handleChange(e, "name")}
                error={changeCheck.name}
              />
            </div>
            {item.type === "PRIVATE" && <div className="label">Private Buyer</div>}
            {item.type === "PRIVATE" && (
              <div className="content">
                <TextField fullWidth value={item.private_users?.join(",")} />
              </div>
            )}
          </div>

          <div className="row">
            <div className="label">SearchText</div>
            <div className="content">
              <TextField
                fullWidth
                value={item.search_text}
                onChange={(e: any) => handleChange(e, "search_text")}
                error={changeCheck.search_text}
              />
            </div>
          </div>

          <div className="row">
            <div className="label">Category</div>
            <div className="content">
              <Select
                onChange={handleChangeCategoryId}
                value={item.category_id ? item.category_id : -1}
                error={changeCheck.category_id}>
                {itemMetaState.category.map((o, idx) => (
                  <MenuItem key={idx} value={o.id ? o.id : -1}>
                    {o.treenodes && o.treenodes?.map((o) => o.name).join(" > ")}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="label"> </div>
            <div className="content"></div>
          </div>

          <ItemMetaDialog
            open={tempIteMeta !== undefined}
            itemMeta={tempIteMeta}
            onClose={(isRefresh) => {
              setTempItemMeta(undefined);
              if (isRefresh) refreshItemMeta();
            }}
          />
          <div className="row">
            <div className="label">Manufacturer</div>
            <div className="content">
              <Select
                onChange={handleChangeBrandId}
                value={item.brand_id ? item.brand_id : -1}
                error={changeCheck.brand_id}>
                {Array(26)
                  .fill(1)
                  .map((o, i) => String.fromCharCode(65 + i))
                  .map((chr) => {
                    const filteredBrand = itemMetaState.brand.filter(
                      (o) => o.name && o.name.toUpperCase().startsWith(chr)
                    );
                    if (filteredBrand.length > 0) {
                      return [
                        <MenuItem key={chr} disabled>
                          {chr}
                        </MenuItem>,
                        filteredBrand
                          .sort((a, b) => (a.name && b.name && a.name > b.name ? 1 : -1))
                          .map((brd) => (
                            <MenuItem key={brd.id} value={brd.id}>
                              {brd.name}
                            </MenuItem>
                          )),
                      ];
                    }
                  })
                  .reduce((p, n) => {
                    if (n && n.length > 0) n.forEach((no) => p?.push(no));
                    return p;
                  }, [])}
                <MenuItem disabled>ETC</MenuItem>
                {itemMetaState.brand
                  .filter((o) => {
                    const uppderName = (o.name && o.name.toUpperCase()) ?? "A";
                    return !!(uppderName.charCodeAt(0) < 65 || 90 < uppderName.charCodeAt(0));
                  })
                  .map((brd) => (
                    <MenuItem key={brd.id} value={brd.id}>
                      {brd.name}
                    </MenuItem>
                  ))}
              </Select>
              <Button onClick={() => handleClickAddItemMeta("Brand")}>
                <AddIcon />
              </Button>
            </div>
            <div className="label">Designer</div>
            <div className="content">
              <Select
                onChange={handleChangeDesignerId}
                value={item.designer_id ? item.designer_id : -1}
                error={changeCheck.designer_id}>
                {Array(26)
                  .fill(1)
                  .map((o, i) => String.fromCharCode(65 + i))
                  .map((chr) => {
                    const filteredDesigner = itemMetaState.designer.filter(
                      (o) => o.name && o.name.toUpperCase().startsWith(chr)
                    );
                    if (filteredDesigner.length > 0) {
                      return [
                        <MenuItem key={chr} disabled>
                          {chr}
                        </MenuItem>,
                        filteredDesigner
                          .sort((a, b) => (a.name && b.name && a.name > b.name ? 1 : -1))
                          .map((brd) => (
                            <MenuItem key={brd.id} value={brd.id}>
                              {brd.name}
                            </MenuItem>
                          )),
                      ];
                    }
                  })
                  .reduce((p, n) => {
                    if (n && n.length > 0) n.forEach((no) => p?.push(no));
                    return p;
                  }, [])}
                <MenuItem disabled>ETC</MenuItem>
                {itemMetaState.designer
                  .filter((o) => {
                    const uppderName = (o.name && o.name.toUpperCase()) ?? "A";
                    return !!(uppderName.charCodeAt(0) < 65 || 90 < uppderName.charCodeAt(0));
                  })
                  .map((brd) => (
                    <MenuItem key={brd.id} value={brd.id}>
                      {brd.name}
                    </MenuItem>
                  ))}
              </Select>
              <Button onClick={() => handleClickAddItemMeta("Designer")}>
                <AddIcon />
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="label">Visible</div>
            <div className="content">
              {ItemVisibleArr.map((visible) => (
                <FormControlLabel
                  label={visible}
                  control={
                    <Radio
                      checked={item.visible === visible}
                      color={changeCheck.visible ? "secondary" : "primary"}
                    />
                  }
                  onClick={() => handleChangeRadio("visible", visible)}
                />
              ))}
            </div>
            <div className="label"> STATUS</div>
            <div className="content">
              {ItemStatusArr.map((status) => (
                <FormControlLabel
                  label={status}
                  control={
                    <Radio
                      checked={item.status === status}
                      color={changeCheck.status ? "secondary" : "primary"}
                    />
                  }
                  onClick={(e) => handleChangeRadio("status", status)}
                />
              ))}
            </div>
          </div>

          <div className="row">
            <div className="label"> PRICE</div>
            <div className="content">
              {ItemPriceOpenArr.map((priceOpen) => (
                <FormControlLabel
                  label={priceOpen}
                  control={
                    <Radio
                      checked={item.priceOpen === priceOpen}
                      color={changeCheck.priceOpen ? "secondary" : "primary"}
                    />
                  }
                  onClick={() => handleChangeRadio("priceOpen", priceOpen)}
                />
              ))}
              <TextField
                fullWidth
                value={item.price}
                onChange={(e: any) => handleChange(e, "price")}
              />
            </div>
            <div className="label"> VAT Added</div>
            <div className="content">
              <FormControlLabel
                label={"Added"}
                control={
                  <Radio
                    checked={item.vatAdded === true}
                    color={changeCheck.vatAdded ? "secondary" : "primary"}
                  />
                }
                onClick={(e) => handleChangeRadio("vatAdded", true)}
              />
              <FormControlLabel
                label={"Not Added"}
                control={
                  <Radio
                    checked={item.vatAdded !== true}
                    color={changeCheck.vatAdded ? "secondary" : "primary"}
                  />
                }
                onClick={(e) => handleChangeRadio("vatAdded", false)}
              />
            </div>
          </div>

          <div className="row">
            <div className="label"> DISCOUNT</div>
            <div className="content">
              <span>할인전 금액: </span>
              <TextField
                value={item.priceBefore}
                onChange={(e: any) => handleChange(e, "priceBefore")}
              />

              <span>
                Discount Rate: <b>{item.discountRate}</b> %
              </span>

              {item.discountRate === 0 && (
                <span>할인전 금액이 현재가격보다 클때, 할인표시가 적용이 됩니다.</span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="label">OPTIONS</div>
            <div className="content">
              {ItemShowHideArr.map((o) => (
                <FormControlLabel
                  label={o}
                  control={
                    <Radio
                      checked={item.sh_itemoptions === o}
                      color={changeCheck.sh_itemoptions ? "secondary" : "primary"}
                    />
                  }
                  onClick={() => handleChangeRadio("sh_itemoptions", o)}
                />
              ))}
            </div>
          </div>

          <div className="row">
            <div className="label"> HASH TAG</div>
            <div className="content">
              {ItemShowHideArr.map((o) => (
                <FormControlLabel
                  label={o}
                  control={
                    <Radio
                      checked={item.sh_hashtag === o}
                      color={changeCheck.sh_hashtag ? "secondary" : "primary"}
                    />
                  }
                  onClick={() => handleChangeRadio("sh_hashtag", o)}
                />
              ))}
              <TextField
                error={changeCheck.hashtag}
                multiline
                fullWidth
                onChange={handleChangeHashTag}
                value={item.hashtag}
                disabled={item.sh_hashtag === "HIDE"}
              />
            </div>
          </div>

          <div className="row">
            <div className="label"> DESC</div>
            <div className="content">
              {ItemShowHideArr.map((o) => (
                <FormControlLabel
                  label={o}
                  control={
                    <Radio
                      checked={item.sh_desc === o}
                      color={changeCheck.sh_desc ? "secondary" : "primary"}
                    />
                  }
                  onClick={() => handleChangeRadio("sh_desc", o)}
                />
              ))}
              <TextField
                error={changeCheck.description}
                fullWidth
                multiline
                value={item.description}
                disabled={item.sh_desc === "HIDE"}
                onChange={(e: any) => handleChange(e, "description")}
              />
            </div>
          </div>

          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1rem",
            }}>
            <div>
              {changeCheck.changed && (
                <span style={{ color: "red" }}>
                  상품상세정보에 수정된 정보가 있습니다. 저장하지 않으면 수정사항이 유실됩니다.
                </span>
              )}
            </div>
            <div>
              <Button
                onClick={handleSaveItem}
                disabled={loadingItem}
                variant="contained"
                size="large"
                color={changeCheck.changed ? "primary" : "inherit"}>
                <SaveIcon />
                저장
              </Button>
              &nbsp; &nbsp;
              <Button
                variant="contained"
                size="large"
                onClick={handleCancelSave}
                color={changeCheck.changed ? "secondary" : "inherit"}>
                <CancelIcon /> 취소
              </Button>
            </div>
          </div>
        </div>
      </Paper>
      <Paper className="paragraph" style={{ margin: "1rem", padding: "1rem" }}>
        <h1>상품디테일 정보관리</h1>
        <ItemDetailPropertyInfo item_id={item.id} />
      </Paper>
      <Paper className="paragraph" style={{ margin: "1rem", padding: "1rem" }}>
        <ItemDetailImageInfo header="상품이미지 관리" item_id={item.id} />
      </Paper>

      <Paper className="paragraph" style={{ margin: "1rem", padding: "1rem" }}>
        <h1>
          재고관리 - 총 재고수량 - {item.stock_avail}
          <IconButton onClick={() => item.id && handleSyncItemStockAvail(item.id)}>
            <SyncIcon />
          </IconButton>
        </h1>
        <ItemOptionStockTable
          defaultItemOptionId={item.defaultItemOptionId}
          setDefaultItemOption={handleSetDefaultItemOption}
          item_id={item.id}
          updateItem={() => loadAll()}
          options={options}
          refresh={refreshOptions}
        />
      </Paper>
      {item.id && (
        <Paper className="paragraph" style={{ margin: "1rem", padding: "1rem" }}>
          <h1>주문 목록 {orderCount !== undefined ? `(${orderCount})` : ``}</h1>
          <OrderedOrderList item_id={item.id} onLoaded={(result) => setOrderCount(result.total)} />
        </Paper>
      )}
    </div>
  );
};

export default ItemDetailView;
