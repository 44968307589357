import React, { useState, useEffect } from "react";
import { Spam } from "../../types/elements";
import { AdminSpamAPI } from "../../api/AdminSpamAPI";
import { TableBody, Table, TableHead, TableRow, TableCell, Button } from "@mui/material";
import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../../store/states/SnackState";
import { useAppCommon } from "../../hooks/useAppCommon";

const SpamListView: React.FC = (props) => {
  const {} = useAppCommon({ headerTitle: "" });
  const openSnack = useSetRecoilState(SnackStateSelector);

  const [list, setList] = useState<Array<Spam>>([]);

  const loadData = () => {
    return AdminSpamAPI.getSpams({}).then((result) => {
      const data = result.data;
      if (data.result) {
        setList(data.result);
      }
    });
  };
  useEffect(() => {
    loadData();
  }, []);

  const handleCancelSpam = (spam: Spam) => {
    return AdminSpamAPI.putSpam({ ...spam, isCancelled: true })
      .then((result) => {
        const data = result.data;
        if (data.updatedCount === 1) {
          openSnack({ msg: "스팸등록 취소가 처리되었습니다." });
          loadData();
        } else {
          openSnack({ msg: "스팸등록 취소가 처리되지 않았습니다." });
        }
      })
      .catch((err) => {
        console.log(err);
        openSnack({ msg: "에러가 발생했습니다." });
      });
  };

  return (
    <div>
      <Table>
        <TableHead>
          <TableCell>type</TableCell>
          <TableCell>target</TableCell>
          <TableCell>created</TableCell>
          <TableCell>cancelled</TableCell>
        </TableHead>
        <TableBody>
          {list.map((spam, idx) => {
            return (
              <TableRow key={idx}>
                <TableCell>{spam.type}</TableCell>
                <TableCell>{spam.target}</TableCell>
                <TableCell>{spam.created?.toLocaleString()}</TableCell>
                <TableCell>
                  {spam.isCancelled ? (
                    spam.cancelled?.toLocaleString()
                  ) : (
                    <Button onClick={() => handleCancelSpam(spam)}>수신거부취소</Button>
                  )}{" "}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default SpamListView;
