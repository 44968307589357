import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { FcmPush } from "../../../types/adm_elements";
import { getThumbUrl } from "../../../types/elements";
import PushMessageListItem from "../../molecules/PushMessageListItem";

interface FcmPushMsgDialogProps {
  open: boolean;
  onClose: (saved?: boolean) => void;
  fcmPush?: FcmPush;
  item_id?: number;
}
const FcmPushMsgDialog: React.FC<FcmPushMsgDialogProps> = ({
  open,
  onClose,
  fcmPush: fcmPushProps,
  item_id,
}) => {
  const [fcmPush, setFcmPush] = useState<FcmPush | undefined>();
  useEffect(() => {
    console.log("useEffect", fcmPushProps, fcmPush);
    setFcmPush({ ...fcmPushProps });
  }, [fcmPushProps]);

  const isChanged = () => {
    if (fcmPush?.title !== fcmPushProps?.title) return true;
    if (fcmPush?.body !== fcmPushProps?.body) return true;
    if (fcmPush?.imageUrl !== fcmPushProps?.imageUrl) return true;
    if (fcmPush?.dataUrl !== fcmPushProps?.dataUrl) return true;
    return false;
  };

  const loadDefaultFromItem = (item_id: number) => {
    gqladm.getItem(item_id).then((item) => {
      const topImage = item.topImages && item.topImages.length > 0 ? item.topImages[0] : {};
      const topImageUrl = topImage.url ? getThumbUrl(topImage.url) : "";
      const fp_init: FcmPush = {
        item_id,
        title: "신상품",
        body: item.name ?? "[빈티지 상품 안내]",
        dataUrl: "/item/" + item_id,
        imageUrl: topImageUrl,
      };
      setFcmPush(fp_init);
    });
  };

  const handleSave = () => {
    console.log("handleSave", fcmPushProps?.id, fcmPush);
    if (fcmPushProps?.id)
      gqladm
        .putFcmPush({
          id: fcmPushProps.id,
          title: fcmPush?.title,
          body: fcmPush?.body,
          imageUrl: fcmPush?.imageUrl,
          dataUrl: fcmPush?.dataUrl,
        })
        .then((result) => {
          if (result.ok) onClose(true);
        });
  };

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <PushMessageListItem
          title={fcmPush?.title}
          body={fcmPush?.body}
          imageUrl={fcmPush?.imageUrl}
          dataUrl={fcmPush?.dataUrl}
        />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>
                #{item_id}{" "}
                <Button variant="contained" onClick={() => item_id && loadDefaultFromItem(item_id)}>
                  상품에서 정보 갱신
                </Button>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>
                <TextField
                  size="small"
                  fullWidth
                  value={fcmPush?.title ?? ""}
                  onChange={(e) => setFcmPush((fp) => ({ ...fp, title: e.target.value }))}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Body</TableCell>
              <TableCell>
                <TextField
                  size="small"
                  fullWidth
                  value={fcmPush?.body ?? ""}
                  onChange={(e) => setFcmPush((fp) => ({ ...fp, body: e.target.value }))}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ImageUrl</TableCell>
              <TableCell>
                <TextField
                  size="small"
                  fullWidth
                  value={fcmPush?.imageUrl ?? ""}
                  onChange={(e) => setFcmPush((fp) => ({ ...fp, imageUrl: e.target.value }))}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>DataUrl</TableCell>
              <TableCell>
                <TextField
                  size="small"
                  fullWidth
                  value={fcmPush?.dataUrl ?? ""}
                  onChange={(e) => setFcmPush((fp) => ({ ...fp, dataUrl: e.target.value }))}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={!isChanged()} onClick={handleSave}>
          저장
        </Button>
        <Button onClick={() => onClose()}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FcmPushMsgDialog;
