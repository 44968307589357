import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table } from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { Order, OrderWaiting } from "../../../types/elements";
import OrderList from "./OrderList";
import OrderWaitingList from "./OrderWaitingList";

interface MerchantCheckerProps {
  m_id: string;
}
const MerchantChecker: React.FC<MerchantCheckerProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [orderList, setOrderList] = useState<Order[]>([]);
  const [orderWaitingList, setOrderWaitingList] = useState<OrderWaiting[]>([]);

  useEffect(() => {
    if (open) {
      // 주문 정보 로드하기
      // 주문 웨이팅 로드하기
      gqladm.getMerchantCheckerData(props.m_id).then((result) => {
        console.log("result getMerchantCheckerData", result);
        setOrderList(result.orderResult.list ? result.orderResult.list : []);
        setOrderWaitingList(result.orderWaitingResult.list ? result.orderWaitingResult.list : []);
      });
    }
  }, [open]);
  return (
    <div style={{ display: "inline-block" }}>
      <Button size={"small"} variant="contained" onClick={() => setOpen(true)}>
        주문확인
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={"lg"}>
        <DialogTitle>주문 확인</DialogTitle>
        <DialogContent>
          Order
          <OrderList list={orderList} />
          <hr />
          OrderWaiting
          <OrderWaitingList list={orderWaitingList} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MerchantChecker;
