import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../../graphql/gqladm";
import { useAppCommon } from "../../../../hooks/useAppCommon";
import { FcmMessage, FcmMessageSearchOption } from "../../../../types/adm_elements";
import { getDiffTime, getFormattedDate } from "../../../../types/elements";

const LIMIT = 20;
interface FcmMessageListViewProps {}
const FcmMessageListView: React.FC<FcmMessageListViewProps> = (props) => {
  useAppCommon({ headerTitle: "Fcm Message List" });

  const [total, setTotal] = useState<number>(0);
  const [list, setList] = useState<FcmMessage[]>([]);
  const [sopt, setSopt] = useState<FcmMessageSearchOption>({ offset: 0, limit: LIMIT });

  const load = (sopt: FcmMessageSearchOption) => {
    gqladm.getFcmMessages(sopt).then((result) => {
      if (result.list) setList(result.list);
      if (result.total !== undefined) setTotal(result.total);
      setSopt({
        ...sopt,
        offset: result.offset ?? sopt.offset,
        limit: result.limit ?? sopt.limit,
      });
    });
  };

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  useEffect(() => {
    load(sopt);
  }, []);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Pagination
          count={Math.ceil(total / (sopt.limit ?? LIMIT))}
          page={Math.ceil((sopt.offset ?? 0) / (sopt.limit ?? LIMIT)) + 1}
          onChange={handleChangePage}
        />
      </div>
      <div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>FcmToken</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Body</TableCell>
              <TableCell>Data-Url</TableCell>
              <TableCell>Image-Url</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>ResultKey</TableCell>
              <TableCell>FcmPush</TableCell>
              <TableCell>UserNoti</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((tr) => {
              return (
                <TableRow key={"key-" + tr.id}>
                  <TableCell>{tr.id}</TableCell>
                  <TableCell>
                    <input value={tr.fcmToken} />
                  </TableCell>
                  <TableCell>
                    <a className="uline" href={"/user/" + tr.user_id} target="_blank">
                      #{tr.user_id}
                    </a>
                  </TableCell>
                  <TableCell>{tr.title}</TableCell>
                  <TableCell>{tr.body}</TableCell>
                  <TableCell>{tr.dataUrl}</TableCell>
                  <TableCell>
                    <input value={tr.imageUrl} />
                    {tr.imageUrl && (
                      <img src={tr.imageUrl} style={{ width: "64px", height: "64px" }} />
                    )}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      placement="top"
                      title={tr.created ? getFormattedDate(tr.created, "yyyy-MM-dd HH:mm") : ""}>
                      <Button size="small" color="inherit">
                        {tr.created && getDiffTime(tr.created, Date.now())}
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      placement="top"
                      title={tr.updated ? getFormattedDate(tr.updated, "yyyy-MM-dd HH:mm") : ""}>
                      <Button size="small" color="inherit">
                        {tr.updated && getDiffTime(tr.updated, Date.now())}
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <input value={tr.resultKey} />
                  </TableCell>
                  <TableCell>
                    <a className="uline" href={"/fcmpush/" + tr.fcmpush_id} target="_blank">
                      {tr.fcmpush_id}
                    </a>
                  </TableCell>
                  <TableCell>{tr.usernoti_id}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Pagination
          count={Math.ceil(total / (sopt.limit ?? LIMIT))}
          page={Math.ceil((sopt.offset ?? 0) / (sopt.limit ?? LIMIT)) + 1}
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default FcmMessageListView;
