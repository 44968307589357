import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
} from "@mui/material";
import { gqladm } from "../../../graphql/gqladm";
import { CommonUtil as cu } from "../../../types/elements";
import { User } from "../../../types/adm_elements";
import UserMemoList from "./UserMemoList";
import UserOrderList from "./UserOrderList";
import UserReservationList from "./UserReservationList";
import UserActionMenuButton from "./UserActionMenuButton";
import UserConsignmentList from "./UserConsignmentList";
import { useWindowSize } from "../../../hooks/useWindowSize";

interface UserInfoDialogProps {
  open: boolean;
  onClose: (updated?: boolean) => any;
  user_id: number;
}
const UserInfoDialog: React.FC<UserInfoDialogProps> = (props) => {
  const { isMobile } = useWindowSize();
  useEffect(() => {
    if (props.open === true && props.user_id !== undefined) load(props.user_id);
  }, [props.open, props.user_id]);

  const [user, setUser] = useState<User>({});
  const load = (user_id: number) => {
    gqladm.getUsers({ id: user_id, limit: 1, offset: 0 }).then((result) => {
      setUser(result.list && result.list.length === 1 ? result.list[0] : {});
    });
  };

  const [tabValue, setTabValue] = useState<"memo" | "reservation" | "consignment" | "order">(
    "memo"
  );
  const handleChangeTab = (e: any, value: any) => {
    // console.log(e, a);
    setTabValue(value);
  };
  return (
    <Dialog
      className="userInfoDialog"
      open={props.open}
      onClose={() => props.onClose()}
      fullWidth
      fullScreen={isMobile}>
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>UserInfo</span>
          <UserActionMenuButton user_id={props.user_id} />
        </div>
      </DialogTitle>
      <DialogContent className="userInfoDialogContent">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <table>
              <tr>
                <th>ID</th>
                <td>{user.id}</td>

                <th>이메일</th>
                <td>{user.email}</td>
              </tr>
              <tr>
                <th>이름</th>
                <td>{user.name}</td>

                <th>전화번호</th>
                <td>{user.phone}</td>
              </tr>
              <tr>
                <th>회원 가입</th>
                <td>{user.created && cu.getFormattedDate(user.created, "yy-MM-dd HH:mm")}</td>
                <th>최종 로그인 만료</th>
                <td>
                  {user.sessionExpired &&
                    cu.getFormattedDate(user.sessionExpired, "yy-MM-dd HH:mm")}
                </td>
              </tr>
            </table>
            <hr />

            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChangeTab}>
              <Tab label="메모" value="memo" />
              <Tab label="예약" value="reservation" />
              <Tab label="위탁(개발중)" value="consignment" />
              <Tab label="주문(개발중)" value="order" />
            </Tabs>
            {tabValue === "memo" && <UserMemoList user_id={props.user_id} />}
            {tabValue === "reservation" && <UserReservationList user_id={props.user_id} />}
            {tabValue === "consignment" && <UserConsignmentList user_id={props.user_id} />}
            {tabValue === "order" && <UserOrderList user_id={props.user_id} />}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
};
// TODO deprecated
export default UserInfoDialog;
