import React, { useState } from "react";

import {
  CommonUtil as cu,
  ReservationSearchOption,
  getReservationStatusKr,
  ReservationStatusArray,
  ReservationStatus,
  SearchResult,
} from "../../../types/elements";
import { Reservation } from "../../../types/adm_elements";

import { Button, DialogTitle, DialogActions, Dialog, DialogContent, Theme } from "@mui/material";

import ReserveSlotCalendar from "./ReserveSlotCalendar";
import { FlexBox } from "../../molecules/Layout";
import CustomTable from "../../molecules/CustomTable";
import ReserveSearch from "./ReserveSearch";
import EditIcon from "@mui/icons-material/Edit";
import ReserveSlotManage from "./ReserveSlotManage";
import { gqladm } from "../../../graphql/gqladm";
// import { Pagination } from "@mui/lab";
import Pagination from "@mui/material/Pagination";

import { useAppCommon } from "../../../hooks/useAppCommon";

import UserDetailDialog from "../customers/userDetail/UserDetailDialog";

// const useStyles = makeStyles((theme: Theme) => {
//   return {
//     root: {
//       "& span.daytime": {
//         padding: "0 5px",
//       },
//       "& .pastReservation": {
//         backgroundColor: "gray",
//       },
//     },
//   };
// });
const ReservationView: React.FC = (props) => {
  const {} = useAppCommon({ headerTitle: "Reservations" });

  const [list, setList] = useState<Array<Reservation>>([]);
  const [total, setTotal] = useState<number>(0);

  const resultMapperReservation = (result: SearchResult<Reservation>) => {
    if (result.list)
      setList([...result.list.sort((a, b) => (a.date && b.date && a.date < b.date ? 1 : -1))]);
    if (result.total) setTotal(result.total);
  };
  const [searchedSopt, setSearchedSopt] = useState<ReservationSearchOption>({});
  const handleOnSearched = (result: SearchResult<Reservation>, sopt: ReservationSearchOption) => {
    setSearchedSopt(sopt);
    resultMapperReservation(result);
  };

  const handleChangePage = (page: number) => {
    console.log("handleChangePage", page);
    const offset = (page - 1) * (searchedSopt.limit ? searchedSopt.limit : 10);
    if (offset !== searchedSopt.offset) {
      const _sopt = { ...searchedSopt, offset: offset };
      gqladm.searchReservation(_sopt).then((result) => {
        handleOnSearched(result, _sopt);
      });
    }
  };

  const [changeStatusItem, setChangeStatusItem] = useState<Reservation | undefined>(undefined);

  const handleSaveChangeStatus = (status: ReservationStatus) => (e: any) => {
    if (changeStatusItem?.id)
      gqladm.updateReservationStatus(changeStatusItem.id, status).then((result) => {
        if (result && result.ok && result.count !== undefined && result.count > 0) {
          setChangeStatusItem(undefined);
          gqladm.searchReservation(searchedSopt).then(resultMapperReservation);
        }
      });
  };

  const handleChangeCalendarMonth = (y: number, m: number) => {
    console.log("handleChangeCalendarMonth", y, m);
  };

  const [userDialogId, setUserDialogId] = useState<number | undefined>();

  return (
    <>
      <div className={/* classes.root */ "reservationView"}>
        <Dialog
          open={changeStatusItem !== undefined}
          onClose={() => setChangeStatusItem(undefined)}>
          <DialogTitle>
            {changeStatusItem?.date} - {changeStatusItem?.time}
          </DialogTitle>
          <DialogContent>
            현재 상태 ={" "}
            {changeStatusItem &&
              changeStatusItem.status !== undefined &&
              getReservationStatusKr(changeStatusItem.status)}
            <br />
            <hr />
            {ReservationStatusArray.map((st, idx) => {
              return (
                <Button key={idx} onClick={handleSaveChangeStatus(st)}>
                  {getReservationStatusKr(st)}
                </Button>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setChangeStatusItem(undefined)}>Close</Button>
          </DialogActions>
        </Dialog>

        <FlexBox dir="column">
          <FlexBox
            jalign="space-between"
            first={<ReserveSearch onSearched={handleOnSearched} />}
            last={<ReserveSlotCalendar onChange={handleChangeCalendarMonth} />}
          />
          <div>
            total : {total}
            <Pagination
              onChange={(e, p) => handleChangePage(p)}
              count={Math.ceil(
                (total ? total : 0) / (searchedSopt.limit ? searchedSopt.limit : 10)
              )}
              page={
                Math.ceil(
                  (searchedSopt?.offset ? searchedSopt.offset : 0) /
                    (searchedSopt?.limit ? searchedSopt.limit : 10)
                ) + 1
              }
            />
          </div>
          <CustomTable
            keyCol="id"
            headers={[
              { col: "id", header: "ID" },
              { col: "created", header: "신청일", sortable: true },
              { col: "date", header: "방문일", sortable: true },
              { col: "time", header: "시간", sortable: true },
              { col: "user", header: "User" },
              { col: "name", header: "이름" },
              { col: "count", header: "인원" },
              { col: "phone", header: "전화번호" },
              { col: "status", header: "상태" },
              { col: "memo", header: "메모" },
              { col: "countOfApply", header: "예약횟수" },
              { col: "countOfNoshow", header: "노쇼횟수" },
              { col: "countOfOrderDone", header: "구매횟수" },
            ]}
            list={list.map((r) => {
              return {
                id: r.id,
                created: r.created && cu.getFormattedDate(r.created, "yyyy.MM.dd"),
                date: r.date,
                time: r.time,
                user: (
                  <Button onClick={() => r.user_id && setUserDialogId(r.user_id)}>
                    #{r.user_id} {r.user?.name}
                  </Button>
                ),
                name: r.name,
                count: r.memberCount,
                phone: r.phone,
                status: (
                  <div>
                    {r.status && getReservationStatusKr(r.status)}
                    <Button onClick={() => setChangeStatusItem(r)}>
                      <EditIcon fontSize="small" />
                    </Button>
                  </div>
                ),
                memo:
                  r.user_id &&
                  r?.user?.userMemos?.list &&
                  r.user.userMemos.list.length > 0 &&
                  r.user.userMemos.list[0].memo,
                // memo: r.user_id && (
                //   <UserMemoBox user_id={r.user_id} userMemos={r?.user?.userMemos?.list} limit={1} />
                // ),
                countOfApply: r.reservedCount,
                countOfNoshow: r.noShowCount,
                countOfOrderDone: r.user?.num_order_done_count,
              };
            })}
          />
        </FlexBox>

        <ReserveSlotManage />
      </div>
      <UserDetailDialog user_id={userDialogId} onClose={() => setUserDialogId(undefined)} />
    </>
  );
};

export default ReservationView;
