import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import useItemMeta from "../../../hooks/useItemMeta";
import {
  UserAlertInfoSearchCond,
  UserAlertType_TypeConsignment,
  UserAlertType_TypeNew,
} from "../../../types/elements";
import { CandidateSearchOption } from "./CandidatesList";

export const convertSoptToConds = (sopt: CandidateSearchOption): UserAlertInfoSearchCond[] => {
  const conds: UserAlertInfoSearchCond[] = [];
  if (sopt.hasNewLabel) conds.push({ type: "type", type_id: UserAlertType_TypeNew });
  if (sopt.hasConfignmentLabel)
    conds.push({ type: "type", type_id: UserAlertType_TypeConsignment });
  if (sopt.category_id) conds.push({ type: "category", type_id: sopt.category_id });
  if (sopt.designer_id) conds.push({ type: "designer", type_id: sopt.designer_id });
  if (sopt.brand_id) conds.push({ type: "brand", type_id: sopt.brand_id });
  return conds;
};

interface CandidateSearchOptionDialogProps {
  open: boolean;
  onClose: () => void;
  item_id?: number;
  loadItem?: (item_id: number) => void;
  sopt: CandidateSearchOption;
  setSopt: React.Dispatch<React.SetStateAction<CandidateSearchOption>>;
  onSearch: () => Promise<void>;
  addAll: () => void;
}
const CandidateSearchOptionDialog: React.FC<CandidateSearchOptionDialogProps> = ({
  open,
  onClose,
  item_id,
  loadItem,
  sopt,
  setSopt,
  onSearch,
  addAll,
}) => {
  const { getItemMeta } = useItemMeta();

  return (
    <Dialog open={open}>
      <DialogTitle>메세지 전달 대상 검색</DialogTitle>
      <DialogContent>
        <div>
          <FormControlLabel
            control={<Checkbox checked={sopt.hasNewLabel} />}
            label="신상품"
            onChange={(e, v) => setSopt((opt) => ({ ...opt, hasNewLabel: v }))}
          />
          <FormControlLabel
            control={<Checkbox checked={sopt.hasConfignmentLabel} />}
            label="위탁상품"
            onChange={(e, v) => setSopt((opt) => ({ ...opt, hasConfignmentLabel: v }))}
          />
        </div>
        <div>
          상품 {item_id ? "#" + item_id : "없음"}{" "}
          <Button
            onClick={() => {
              if (item_id) loadItem && loadItem(item_id);
            }}>
            상품 설정 로드
          </Button>
        </div>
        <table>
          <tr>
            <td>카테고리</td>
            <td>{sopt.category_id ? getItemMeta("Category", sopt.category_id)?.name : ""}</td>
            <td>
              <Button onClick={() => setSopt((opt) => ({ ...opt, category_id: undefined }))}>
                설정제거
              </Button>
            </td>
          </tr>
          <tr>
            <td>디자이너</td>
            <td>{sopt.designer_id ? getItemMeta("Designer", sopt.designer_id)?.name : ""}</td>
            <td>
              <Button onClick={() => setSopt((opt) => ({ ...opt, designer_id: undefined }))}>
                설정제거
              </Button>
            </td>
          </tr>
          <tr>
            <td>제조사</td>
            <td>{sopt.brand_id ? getItemMeta("Brand", sopt.brand_id)?.name : ""}</td>
            <td>
              <Button onClick={() => setSopt((opt) => ({ ...opt, brand_id: undefined }))}>
                설정제거
              </Button>
            </td>
          </tr>
        </table>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="contained" color="secondary" onClick={() => addAll()}>
          조회목록을 전체 대상으로 저장
        </Button>
        <Button variant="contained" onClick={() => onSearch && onSearch()}>
          조회
        </Button>
        <div>
          <Button variant="outlined" onClick={() => onClose()}>
            닫기
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CandidateSearchOptionDialog;
