import { gql } from "apollo-boost";
import { Search } from "react-router";
import {
  convertFcmPush,
  convertFcmPushUser,
  FcmPush,
  FcmPushSearchOption,
  FcmPushUser,
  FcmPushUserSearchOption,
} from "../../types/adm_elements";
import {
  convertUserAlertInfo,
  convertUserDevice,
  SearchResult,
  UserAlertInfo,
  UserAlertInfoSearchOption,
  UserDevice,
} from "../../types/elements";
import { client } from "../client";

interface QueryIF {
  admin?: {
    count_dbconnected?: number;
    count_dbmaxconn?: number;
    fcmPushs?: SearchResult<FcmPush>;
    fcmPushUsers?: SearchResult<FcmPushUser>;
    userAlertInfos?: SearchResult<UserAlertInfo>;
    userDevicesFcmCandidate?: SearchResult<UserDevice>;
  };
}

export const getDBConnectedCount = () =>
  client
    .query<QueryIF>({
      query: gql`
        query GetDBConnectedCount {
          admin {
            count_dbconnected
          }
        }
      `,
    })
    .then((result) => {
      if (result.data.admin?.count_dbconnected) return result.data.admin?.count_dbconnected;
      else throw new Error("Error");
    });

export const getDBMaxConnCount = () =>
  client
    .query<QueryIF>({
      query: gql`
        query GetDBMaxConnCount {
          admin {
            count_dbmaxconn
          }
        }
      `,
    })
    .then((result) => {
      if (result.data.admin?.count_dbmaxconn) return result.data.admin?.count_dbmaxconn;
      else throw new Error("Error");
    });

export const getFcmPushs = (sopt: FcmPushSearchOption): Promise<SearchResult<FcmPush>> =>
  client
    .query<QueryIF>({
      query: gql`
        query GetFcmPushs($sopt: FcmPushSearchInput) {
          admin {
            fcmPushs(sopt: $sopt) {
              offset
              limit
              total
              list {
                id
                created
                send_start
                send_end
                cnt_succ
                cnt_fail
                item_id
                title
                body
                dataUrl
                imageUrl
              }
            }
          }
        }
      `,
      variables: { sopt },
    })
    .then((result) => {
      if (result.data.admin?.fcmPushs)
        return {
          ...result.data.admin?.fcmPushs,
          list: result.data.admin.fcmPushs.list?.map((r) => convertFcmPush(r) ?? {}) ?? [],
        };
      else throw new Error("Error");
    });

export const getFcmPushUsers = (
  sopt: FcmPushUserSearchOption
): Promise<SearchResult<FcmPushUser>> => {
  return client
    .query<QueryIF>({
      query: gql`
        query GetFcmPushUsers($sopt: FcmPushUserSearchInput) {
          admin {
            fcmPushUsers(sopt: $sopt) {
              offset
              limit
              total
              list {
                id
                fcmpush_id
                user_id
                user {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: { sopt },
    })
    .then((result) => {
      if (result.data.admin?.fcmPushUsers)
        return {
          ...result.data.admin?.fcmPushUsers,
          list: result.data.admin?.fcmPushUsers.list?.map((r) => convertFcmPushUser(r) ?? {}),
        };
      throw new Error("err");
    });
};

export const searchUserAlertInfo = (
  sopt: UserAlertInfoSearchOption
): Promise<SearchResult<UserAlertInfo>> =>
  client
    .query<QueryIF>({
      query: gql`
        query SearchUserAlertInfo($sopt: UserAlertInfoSearchInput) {
          admin {
            userAlertInfos(sopt: $sopt) {
              offset
              limit
              total
              list {
                id
                created
                user_id
                type
                type_id
              }
            }
          }
        }
      `,
      variables: { sopt },
    })
    .then((result) => {
      if (result.data.admin?.userAlertInfos)
        return {
          ...result.data.admin?.userAlertInfos,
          list:
            result.data.admin.userAlertInfos.list?.map((r) => convertUserAlertInfo(r) ?? {}) ?? [],
        };
      else throw new Error("Error");
    });
