import { Button, Chip, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import ColumnDiv from "../../../molecules/ColumnDiv";
import { gqladm } from "../../../../graphql/gqladm";
import {
  RentalSearchOption,
  RentalStatus,
  RentalStatusArray,
  StatusCount,
} from "../../../../types/elements";

interface RentalSearchBoxProps {
  onSearch: (opt: RentalSearchOption) => any;
}
const RentalSearchBox: React.FC<RentalSearchBoxProps> = (props) => {
  const [searchStatuses, setSearchStatuses] = useState<Array<RentalStatus>>(
    RentalStatusArray.filter((o) => o !== "작성 중" && o !== "취소")
  );
  const [searchOrder, setSearchOrder] = useState<"newerfirst" | "olderfirst" | "">("newerfirst");

  const [statusCounts, setStatusCounts] = useState<StatusCount[]>([]);
  const loadStatusCount = () => {
    gqladm.rentalStatusCounts().then((result) => {
      setStatusCounts(result);
    });
  };
  useEffect(() => {
    loadStatusCount();
    handleSearch();
  }, []);

  const handleToggleSearchStatus = (status: RentalStatus) => {
    setSearchStatuses((sss) =>
      sss.includes(status) ? sss.filter((o) => o !== status) : [...sss, status]
    );
  };

  const handleSearch = () => {
    const opt: RentalSearchOption = {};
    if (searchStatuses.length > 0) opt.statuses = searchStatuses;
    if (searchOrder) opt.ordering = searchOrder;
    props.onSearch(opt);
  };

  return (
    <div className="consignmentSearchBox">
      <div style={{ marginBottom: "8px", fontWeight: "bold" }}>Rental Search</div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        Status :&nbsp;
        {RentalStatusArray.map((cs, csIdx) => {
          const count = statusCounts
            .filter((o) => o.status === cs)
            .map((o) => o.count)
            .reduce((p, n) => (p ?? 0) + (n ?? 0), 0);
          return (
            <Chip
              key={csIdx}
              className="statusChip"
              label={cs + `(${count})`}
              onClick={() => handleToggleSearchStatus(cs)}
              color={searchStatuses.includes(cs) ? "primary" : "default"}
              size="small"
            />
          );
        })}
      </div>
      <ColumnDiv label="정렬순서">
        <ToggleButtonGroup
          size="small"
          exclusive
          value={searchOrder}
          onChange={(e, v) => {
            setSearchOrder(v);
          }}>
          <ToggleButton value="">No</ToggleButton>
          <ToggleButton value="newerfirst">최근부터</ToggleButton>
          <ToggleButton value="olderfirst">과거부터</ToggleButton>
        </ToggleButtonGroup>
      </ColumnDiv>
      <div style={{ padding: "0.2rem", display: "flex" }}>
        {/* <Button>접기</Button> */}
        <Button variant="contained" fullWidth onClick={handleSearch}>
          검색
        </Button>
      </div>
    </div>
  );
};

export default RentalSearchBox;
