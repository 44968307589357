import { Button } from "@mui/material";
import React, { useState } from "react";
import NewOrderDialog from "../../../neworderdialog/NewOrderDialog";

interface UserDetailOrderNewButtonProps {
  user_id: number;
}
const UserDetailOrderNewButton: React.FC<UserDetailOrderNewButtonProps> = (props) => {
  const [openNewOrderDialog, setOpenNewOrderDialog] = useState<boolean>(false);

  const handleOpenAddNewOrder = () => {
    setOpenNewOrderDialog(true);
  };
  const handleCloseAddNewOrder = () => {
    setOpenNewOrderDialog(false);
  };
  return (
    <>
      <Button size="small" variant="contained" color="primary" onClick={handleOpenAddNewOrder}>
        Add New Order
      </Button>
      <NewOrderDialog
        open={openNewOrderDialog}
        onClose={handleCloseAddNewOrder}
        user_id={props.user_id}
      />
    </>
  );
};

export default UserDetailOrderNewButton;
