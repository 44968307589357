import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { gqladm } from "../../graphql/gqladm";
import { SnackStateSelector } from "../../store/states/SnackState";
import { Banner } from "../../types/elements";

interface BannerDialogProps {
  open: boolean;
  onClose: (completed?: boolean) => any;
}
const BannerDialog: React.FC<BannerDialogProps> = (props) => {
  const [banner, setBanner] = useState<Banner>({});
  const openSnack = useSetRecoilState(SnackStateSelector);

  const updateField = (field: "msg" | "url", value: string) => {
    const bb = { ...banner };
    bb[field] = value;
    setBanner(bb);
  };

  const handleCreate = () => {
    gqladm
      .saveBanner(banner)
      .then((result) => {
        openSnack({ msg: `배너 입력이 ${result.ok ? "성공" : "실패"}하였습니다.` });
        if (result.ok) props.onClose && props.onClose(true);
      })
      .catch((err) => {
        openSnack({ msg: `에러가 발생하였습니다. ` + err });
      });
  };

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>배너 입력</DialogTitle>
      <DialogContent>
        <div>type: banner</div>
        <div>
          msg: <TextField onChange={(e) => updateField("msg", e.target.value)} fullWidth />
        </div>
        <div>
          url: <TextField onChange={(e) => updateField("url", e.target.value)} fullWidth />
        </div>
        <div>
          <br />
          * 이동하려는 페이지에 비로그인시 로그인페이지로, 로그인시 원하는 페이지로 이동하는 형태로
          하고 싶은 경우에는 /login?ref=[url]&amp;opt=autoRedirect 형태로 작성해야합니다. 이 경우
          url에 / &amp; = 등의 문자가 포함된 경우 encoding 된 형태로 작성하여 넣어주셔야 하며,
          https://meyerweb.com/eric/tools/dencoder/ 이 사이트에서 인코딩된 url을 얻을수 있습니다.
          <br />
          <br />
          예를들어 특정 카테고리 리스트에 로그인이후 보여주고자 하면 원하는 페이지url
          /list?category=5f07f49d1a6144001ee0b4ea 을 먼저 찾고, 인코딩된 문자열
          '%2Flist%3Fcategory%3D5f07f49d1a6144001ee0b4ea' 을 만들어냅니다. 그리고 url항목에 형식에
          맞게 넣으시면 됩니다.
          /login?ref=%2Flist%3Fcategory%3D5f07f49d1a6144001ee0b4ea&amp;opt=autoRedirect
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreate}>Create</Button>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BannerDialog;
