import { Button, Chip } from "@mui/material";
import React from "react";
import useItemMeta from "../../../hooks/useItemMeta";
import { getKrOrderStatus, CommonUtil as cu } from "../../../types/elements";
import { OrderResultProps } from "./OrderListView";

interface OrderResultListProps extends OrderResultProps {}
const OrderResultList: React.FC<OrderResultListProps> = (props) => {
  const { list, openDetail } = props;
  const { getItemMeta } = useItemMeta();
  return (
    <div>
      {props.list.map((order, orIdx) => {
        return (
          <div
            key={orIdx}
            style={{ backgroundColor: "#f0f0f0", margin: "0.5rem 0", padding: "0.3rem" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Button size="small" onClick={() => openDetail && order.id && openDetail(order.id)}>
                  #{order.id}
                </Button>
              </div>
              <div>
                <Chip size="small" label={getKrOrderStatus(order.status)} />
              </div>
              <div>{order.created && cu.getFormattedDate(order.created, "yyyy-MM-dd hh:mm")}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {(order.orderItems ? order.orderItems : []).map((oi, oiIdx) => {
                  const ordered = (order.orderedItems ? order.orderedItems : []).filter(
                    (o) => o.itemId === oi.id && o.itemOptionId === oi.item_option_id
                  );
                  const appliedCount = ordered.reduce(
                    (p, n) => p + (n.orderedCount ? n.orderedCount : 0),
                    0
                  );
                  const currentDesigner = getItemMeta("Designer", oi.item?.designer_id);
                  return (
                    <div key={oiIdx}>
                      [{oi.item_id}-{oi.item_option_id}] {oi.item?.name}
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>
                          {oi.item?.topImages && oi.item.topImages.length > 0 && (
                            <img className="rect48" src={oi.item.topImages[0].url} />
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}>
                          <div>- {currentDesigner?.name && <b>{currentDesigner?.name}</b>}</div>
                          <div>{`주문수량: ${oi.count} / (${appliedCount})`}</div>
                        </div>
                      </div>
                      {appliedCount > 0 && oi.count === appliedCount && "완료"}
                    </div>
                  );
                })}
              </div>
              <div>총주문가격: {cu.numberWithCommas(order.price)}원</div>
            </div>
            <div>
              유저:#{order.user?.id} - {order.user?.name} - {order.user?.phone}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrderResultList;
