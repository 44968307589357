import React, { useEffect, useState } from "react";
import { Table, TableRow, TableBody, TableHead, TableCell } from "@mui/material";

interface MiniTableProps {
  items: any[];
  cols?: Array<string>;
}
const MiniTable: React.FC<MiniTableProps> = (props) => {
  const [cols, setCols] = useState<string[]>([]);
  useEffect(() => {
    if (props.cols) {
      setCols(props.cols);
    } else {
      const cs: string[] = [];
      props.items.forEach((o) => {
        Object.keys(o).forEach((c) => {
          if (cs.indexOf(c) === -1) {
            cs.push(c);
          }
        });
      });
      setCols(cs);
    }
  }, [props.items, props.cols]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          {cols.map((col, idx) => {
            return <TableCell key={idx}>{col}</TableCell>;
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.items.map((o, idx) => {
          return (
            <TableRow key={idx}>
              {cols.map((col, cidx) => {
                const value =
                  typeof o[col] === "string"
                    ? o[col]
                    : typeof o[col] === "number"
                    ? o[col]
                    : typeof o[col] === "object" && o[col] instanceof Date
                    ? o[col].toLocaleString()
                    : "";
                return <TableCell key={cidx}>{value}</TableCell>;
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default MiniTable;
