import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton, Paper, Popover, TextField } from "@mui/material";
import React, { useState } from "react";

interface MonthPickerProps {
  value?: any;
  size?: "small";
  onSelect?: (y: number, m: number) => any;
}
const MonthPicker: React.FC<MonthPickerProps> = (props) => {
  const [el, setEl] = useState<HTMLElement | null>(null);
  const open = Boolean(el);
  const [year, setYear] = useState<number>(new Date().getFullYear());

  const handleClickCalendar = (m: number) => {
    const dobj = new Date(year, m, 1);
    props.onSelect && props.onSelect(dobj.getFullYear(), dobj.getMonth());
    setEl(null);
  };

  return (
    <div>
      <TextField size={props.size} value={props.value} onClick={(e) => setEl(e.currentTarget)} />
      <Popover
        open={open}
        anchorEl={el}
        onClose={() => setEl(null)}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}>
        <Paper>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <IconButton onClick={() => setYear((y) => y - 1)}>
                <KeyboardArrowLeftIcon />
              </IconButton>
            </div>
            <div>{year}</div>
            <div>
              <IconButton onClick={() => setYear((y) => y + 1)}>
                <KeyboardArrowRightIcon />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            {Array(12)
              .fill(1)
              .map((o, i) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "23%",
                  }}>
                  <IconButton size="small" onClick={() => handleClickCalendar(i)}>
                    {i + 1}월
                  </IconButton>
                </div>
              ))}
          </div>
        </Paper>
      </Popover>
    </div>
  );
};

export default MonthPicker;
