import { Alert, AlertTitle, Button } from "@mui/material";
import React, { useState } from "react";
import { gqladm } from "../../../../graphql/gqladm";
import useSnack from "../../../../hooks/useSnack";
import SmsDialog from "../../../organisms/SmsDialog";

interface SelectedActionsProps {
  selected: number[];
}
const SelectedActions: React.FC<SelectedActionsProps> = ({ selected }) => {
  const { openSnack } = useSnack();
  // 통계필드 싱크하기
  const handleSyncNumfields = () => {
    gqladm.syncUserNumFields(selected).then((result) => {
      openSnack({
        msg: result.ok
          ? result.count + "개의 유저통계가 업데이트 되었습니다."
          : "유저 통계 업데이트가 실패하였습니다 ",
      });
    });
  };

  //   SMS 보내기
  const [openSmsDialog, setOpenSmsDialog] = useState<boolean>(false);
  const handleSendSms = () => {
    setOpenSmsDialog(true);
  };
  return (
    <Alert severity="info">
      <AlertTitle>{selected.length} rows selected.</AlertTitle>
      <Button onClick={handleSendSms}>SMS보내기</Button>
      <SmsDialog open={openSmsDialog} onClose={() => setOpenSmsDialog(false)} users={selected} />
      <Button onClick={handleSyncNumfields}>통계갱신</Button>
    </Alert>
  );
};

export default SelectedActions;
