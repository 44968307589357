import { Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import useItemMeta from "../../../hooks/useItemMeta";

import { ItemMeta, ItemMetaExtUI, ItemMetaType } from "../../../types/elements";
import ItemMetaDialog from "./ItemMetaDialog";

interface ItemMetaTreeProps {
  type: ItemMetaType;
}
const ItemMetaTree: React.FC<ItemMetaTreeProps> = (props) => {
  const [list, setList] = useState<ItemMetaExtUI[]>([]);
  const [allList, setAllList] = useState<ItemMetaExtUI[]>([]);

  const { refresh } = useItemMeta();
  const load = () => {
    refresh().then((newState) => {
      const al =
        props.type === "Category"
          ? newState.category
          : props.type === "Brand"
          ? newState.brand
          : props.type === "Designer"
          ? newState.designer
          : [];
      setAllList(al);
      setList(al.filter((o) => !o.parent));
    });
  };

  useEffect(() => {
    load();
  }, []);

  const [obj, setObj] = useState<ItemMeta | undefined>();
  const handleCloseObjDialog = (refresh?: boolean) => {
    setObj(undefined);
    if (refresh) load();
  };

  const handleClickCreate = () => setObj({ type: props.type, seq: 0 });
  const handleClickEdit = (im: ItemMeta) => setObj({ ...im });
  const handleClickDelete = (id: number) => {
    console.log("handleClickDelete", id);
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      gqladm.removeItemMeta(id).then((result) => {
        if (result.ok) {
          load();
        }
      });
    }
  };

  return (
    <div>
      <div>
        <Button variant="contained" onClick={handleClickCreate}>
          Create
        </Button>
        <ItemMetaDialog
          open={obj !== undefined}
          itemMeta={obj}
          onClose={handleCloseObjDialog}
          allList={allList}
        />
      </div>
      <div>
        {list.map((im) => (
          <ItemMetaTreeItem im={im} onEdit={handleClickEdit} onDelete={handleClickDelete} />
        ))}
      </div>
    </div>
  );
};

const ItemMetaTreeItem: React.FC<{
  im: ItemMetaExtUI;
  onEdit: (im: ItemMeta) => any;
  onDelete: (id: number) => any;
}> = (props) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          backgroundColor: "#f0f0f0",
          border: "2px solid white",
          padding: "10px",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flex: 1,
            padding: "0.2rem 1rem",
          }}>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <span className="KvTag">
              <span className="key">id</span>:<span className="value">{props.im.id}</span>
            </span>
            <div style={{ display: "inline-block" }}>
              <span className="KvTag">
                <span className="key">name</span>:<span className="value">{props.im.name}</span>
              </span>
              <br /> {props.im.desc ? " - " + props.im.desc : ""}
            </div>
          </div>
          <div>
            {props.im.key ? (
              <span className="KvTag">
                <span className="key">key</span>:<span className="value">{props.im.key}</span>
              </span>
            ) : (
              ""
            )}
            <span className="KvTag">
              <span className="key">seq</span>:<span className="value">{props.im.seq}</span>
            </span>
          </div>
        </div>
        <div>
          <Button variant="contained" onClick={() => props.onEdit(props.im)}>
            Edit
          </Button>
          <Button variant="contained" onClick={() => props.im.id && props.onDelete(props.im.id)}>
            Delete
          </Button>
        </div>
      </div>
      {props.im.children && props.im.children.length > 0 && (
        <div style={{ paddingLeft: "2rem" }}>
          {props.im.children?.map((o) => {
            return (
              <ItemMetaTreeItem key={o.id} im={o} onEdit={props.onEdit} onDelete={props.onDelete} />
            );
          })}
        </div>
      )}
    </div>
  );
};
export default ItemMetaTree;
