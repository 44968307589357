import axios from "axios";
import React, { useEffect, useState } from "react";

interface TagVersionProps {}
const TagVersion: React.FC<TagVersionProps> = (props) => {
  const [tag, setTag] = useState<string>("");
  useEffect(() => {
    axios.get("/tag.json").then((result) => {
      setTag(result.data.tag);
    });
  }, []);
  return <div>{tag}</div>;
};

export default TagVersion;
