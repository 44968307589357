import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppCommon } from "../../../../hooks/useAppCommon";
import UserDetail from "./UserDetail";

export type UserDetailViewIdType =
  | ""
  | "dashboard"
  | "account_information"
  | "order"
  | "reservation"
  | "request"
  | "wish";
interface UserDetailViewProps {}
const UserDetailView: React.FC<UserDetailViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "Customer Detail" });
  const navi = useNavigate();
  const params = useParams<{ userId: string; viewId: UserDetailViewIdType }>();

  console.log(params);

  const handleChangeViewId = (viewId: UserDetailViewIdType) => {
    navi(`/user/${params.userId}/${viewId}`);
  };

  return (
    <div>
      {params?.userId && (
        <UserDetail
          user_id={Number(params.userId)}
          view_id={params.viewId ?? ""}
          onChangeViewId={handleChangeViewId}
        />
      )}
    </div>
  );
};

export default UserDetailView;
