import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CustomImage } from "../../../types/elements";

// const useStyles = makeStyles((theme: Theme) => {
//   return {
//     root: (props: whIF) => {
//       const w = props.width;
//       const temp: any = {};
//       const calcPx = (px: number): string => {
//         if (temp["px" + px] === undefined) temp["px" + px] = calcMobilePx(px, w);
//         return temp["px" + px] + "px";
//       };

//       return {
//         position: "relative",
//         width: "92.55319149vw", // calcPx(1044),
//         height: "33.68794326vw", // calcPx(380),
//         "& > .slick-slider > .slick-dots": {
//           bottom: "10px",
//           "& > li": {
//             margin: 0,
//             width: "12px",
//           },
//           "& > li > button:before": {
//             color: "#ffffff",
//             opacity: 1,
//           },
//           "& > li.slick-active > button:before": {
//             color: "#929292",
//           },
//         },
//         "& .innerCI": {
//           position: "relative",
//           width: "92.55319149vw", // calcPx(1044),
//           height: "33.68794326vw", // calcPx(380),
//           backgroundColor: "lightgray",
//           "& > img": {
//             width: "100%",
//             height: "100%",
//             objectFit: "cover",
//           },
//           "& > .overDiv": {
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "flex-end",
//             position: "absolute",
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             color: "white",
//             // padding: `${calcPx(40)} ${calcPx(40)} ${calcPx(120)} ${calcPx(40)}`,
//             padding: `3.546099291vw 3.546099291vw 10.63829787vw 3.546099291vw`,
//             "& > .overTitle": {
//               padding: "0 3.546099291vw", // `0 ${calcPx(40)}`,
//               fontSize: "10.63829787vw", // calcPx(120),
//               display: "flex",
//               justifyContent: "center",
//               fontWeight: "bold",
//             },
//             "& > .overDescription": {
//               padding: "0 5.319148936vw", // `0 ${calcPx(60)}`,
//               fontSize: "4.432624113vw", // calcPx(50),
//             },
//           },
//         },
//       };
//     },
//   };
// });
interface MobileSubSlideProps {
  width: number;
  customImages: CustomImage[];
  defaultImageUrl?: string;
  onClickImage?: (imageLink?: string) => any;
}
const MobileSubSlide: React.FC<MobileSubSlideProps> = (props) => {
  // const classes = useStyles({ width: props.width, height: 400 });
  const handleClickImage = (ci: CustomImage) => {
    if (props.onClickImage) props.onClickImage(ci.image_link);
  };

  return (
    <div
      className={
        "mobileSubSlide "
        // + classes.root
      }>
      <Slider dots={true} autoplay={true} autoplaySpeed={4000}>
        {(props.customImages.length === 0
          ? ([{ image_path: props.defaultImageUrl }] as CustomImage[])
          : props.customImages
        ).map((ci) => {
          return (
            <div key={ci.id}>
              <div className="innerCI" onClick={() => handleClickImage(ci)}>
                {ci.image_path !== undefined && ci.image_path !== "" && (
                  <img src={"/" + ci.image_path} />
                )}
                <div className="overDiv">
                  <div className="overTitle">{ci.title_text}</div>
                  <div className="overDescription">{ci.desc_text}</div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default MobileSubSlide;
