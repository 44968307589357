import { atom } from "recoil";

interface AppCommonStateIF {
  headerTitle: string;
  menuOpen: boolean;
}

export const INIT_APP_COMMON_STATE: AppCommonStateIF = {
  headerTitle: "",
  menuOpen: false,
};

export const AppCommonState = atom<AppCommonStateIF>({
  key: "AppCommonState",
  default: INIT_APP_COMMON_STATE,
});
