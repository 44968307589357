import React from "react";

interface SearchConditionBoxProps {
  children?: React.ReactNode;
}
export const SearchConditionBox: React.FC<SearchConditionBoxProps> = ({ children }) => {
  return <div className="searchConditionBox">{children}</div>;
};

interface SearchFieldBoxProps {
  name?: React.ReactNode;
  children?: React.ReactNode;
}
export const SearchFieldBox: React.FC<SearchFieldBoxProps> = ({ name, children }) => {
  return (
    <div className="fieldItem">
      <div className="fieldName">{name}</div>
      <div className="fieldBody">{children}</div>
    </div>
  );
};
