import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  CommonUtil as cu,
  ItemOption,
  ItemStock,
  Order,
  OrderedItem,
  OrderItem,
} from "../../types/elements";
import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../../store/states/SnackState";
import { gqladm } from "../../graphql/gqladm";

import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
// const useStyles = makeStyles((theme: Theme) => {
//   return {
//     contentsBody: {
//       "& ._panel": {
//         "& > ._title": {
//           fontWeight: "bold",
//         },
//         "& > ._body": {},
//       },
//     },
//   };
// });

// ! Stock - TableRow
const ItemStockTableRow: React.FC<{
  stock: ItemStock;
  orderId: number;
  refresh: () => any;
}> = (props) => {
  const stock = props.stock;
  let orderedCount = 0;
  let curCount = 0;

  stock.orderedItems?.forEach((o) => {
    if (o.orderId === props.orderId) {
      curCount += o.orderedCount ? o.orderedCount : 0;
    }
    orderedCount += o.orderedCount ? o.orderedCount : 0;
  });

  const availCount = (stock.stockCount ? stock.stockCount : 0) - orderedCount;

  const [outCount, setOutCount] = useState(0);
  const handleAddOrderedItem = (stock: ItemStock, count: number, avail: number) => {
    if (count === 0) return alert("출고 상품 수량이 0개입니다. 추가가 불가능합니다.");

    let msg = "";
    if (count < 0) msg = `출고 수량이 반대로 되어있습니다. 반납하는 수량이 ${count} 가 맞습니까?`;
    else if (count > avail)
      msg = `출고수량이 가능 수량을 넘습니다. 출고수량이 ${count} 가 맞습니까? 진행하시겠습니까? `;
    else msg = `출고수량이 ${count} 가 맞습니까?`;

    const memo = window.prompt(msg + "\n 아래는 메모작성란입니다. 특이사항을 적어주세요");
    if (memo !== null) {
      gqladm
        .addOrderedItem({
          stockId: stock.stockId,
          orderId: props.orderId,
          orderedCount: count,
          memo: memo,
        })
        .then((result) => {
          if (result.ok) {
            props.refresh();
          }
        });
    }
  };

  return (
    <TableRow>
      <TableCell>{stock.stockId}</TableCell>
      <TableCell>{stock.warehouse ? stock.warehouse.name : stock.warehouseId}</TableCell>
      <TableCell>
        {stock.created ? cu.getFormattedDate(stock.created, "yyyy-MM-dd HH:mm:ss") : ""}
      </TableCell>
      <TableCell>{stock.stockCount}</TableCell>
      <TableCell>{orderedCount}</TableCell>
      <TableCell>{availCount}</TableCell>
      <TableCell>{curCount}</TableCell>
      <TableCell>
        <div>
          <IconButton onClick={() => setOutCount(outCount - 1)}>
            <RemoveCircleOutline />
          </IconButton>
          {outCount}
          <IconButton onClick={() => setOutCount(outCount + 1)}>
            <AddCircleOutline />
          </IconButton>
        </div>
      </TableCell>
      <TableCell>
        <Button
          disabled={outCount === 0}
          variant="contained"
          color="primary"
          onClick={() => handleAddOrderedItem(stock, outCount, availCount)}>
          출고등록
        </Button>
      </TableCell>
    </TableRow>
  );
};

// ! OrderedItemDialog Component
interface OrderedItemDialogProps {
  open: boolean;
  onClose: (completed?: boolean) => any;
  itemOptionId: number;
  orderId: number;

  // orderedItem?: OrderedItem;
  // itemOption?: ItemOption;
}
const OrderedItemDialog: React.FC<OrderedItemDialogProps> = (props) => {
  // const classes = useStyles();
  const openSnack = useSetRecoilState(SnackStateSelector);

  const [itemOption, setItemOption] = useState<ItemOption | undefined>(undefined);
  const loadItemOption = () => {
    props.itemOptionId !== undefined &&
      gqladm
        .getItemOption(props.itemOptionId)
        .then((result) => {
          if (result) setItemOption(result);
        })
        .catch((err) => {});
  };
  useEffect(() => {
    if (props.itemOptionId > -1) {
      loadItemOption();
      loadItemStock();
    }
    loadOrder();
  }, [props.itemOptionId]);

  const [itemStocks, setItemStocks] = useState<ItemStock[]>([]);
  const [curOrderedItems, setCurOrderedItems] = useState<OrderedItem[]>([]);
  const loadItemStock = () => {
    props.itemOptionId > -1 &&
      gqladm
        .getItemStocks(props.itemOptionId)
        .then((result) => {
          if (result) {
            setItemStocks(result);
            const orderedItems: OrderedItem[] = [];
            result.forEach((is) => {
              console.log("is", is.stockId, is.orderedItems, props.orderId);
              if (is.orderedItems)
                is.orderedItems.forEach(
                  (oi) => oi.orderId === props.orderId && orderedItems.push(oi)
                );
            });
            console.log(orderedItems);
            setCurOrderedItems(orderedItems);
          }
        })
        .catch((err) => {
          openSnack({ msg: "재고정보 로드시 오류 발생" });
        });
  };

  const [order, setOrder] = useState<Order>({});
  const [orderItem, setOrderItem] = useState<OrderItem>({});
  const loadOrder = () => {
    props.orderId &&
      props.orderId > -1 &&
      gqladm
        .getOrder(props.orderId)
        .then((result) => {
          if (result) setOrder(result);
          if (result.orderItems) {
            const oi = result.orderItems.find((o) => o.item_option_id === props.itemOptionId);
            if (oi) setOrderItem(oi);
          }
        })
        .catch((err) => {
          console.log("Error loadOrder", err);
          openSnack({ msg: "Error loadOrder" + err });
        });
  };

  let orderedCount = 0;
  curOrderedItems.forEach((oi) => (orderedCount += oi.orderedCount ? oi.orderedCount : 0));
  console.log(orderedCount, curOrderedItems);
  return (
    <Dialog open={props.open} onClose={() => props.onClose()} maxWidth={"lg"}>
      <DialogTitle>출고 관리</DialogTitle>
      <DialogContent>
        <div className={/* classes.contentsBody */ "orderedItemDialogContentBody"}>
          <div style={{ display: "flex" }}>
            <div className="_panel" style={{ flex: 1 }}>
              <div className="_title">상품정보</div>
              <div className="_body">
                <div>
                  {itemOption?.item?.name} - {itemOption?.item?.price}
                </div>
                <div>
                  상품옵션정보 <br />
                  {itemOption?.name}
                </div>
              </div>
            </div>
            <div className="_panel">
              <div className="_title">주문수량</div>
              <div className="_body">
                <span style={{ fontWeight: "bold", fontSize: "4rem" }}>{orderItem.count}</span>
              </div>
            </div>
            <div className="_panel" style={{ marginLeft: "1rem" }}>
              <div className="_title">출고수량</div>
              <div className="_body">
                <span style={{ fontWeight: "bold", fontSize: "4rem" }}>{orderedCount}</span>
              </div>
            </div>
          </div>

          <hr />
          <div className="_panel">
            <div className="_title">주문정보</div>
            <div className="_body">
              배송 이름 : {order.addr_username}
              <br />
              배송 전화번호 : {order.addr_phone}
              <br />
              배송 주소 : ({order.addr_zipcode}) {order.addr_addr1} {order.addr_addr2}
            </div>
          </div>

          <hr />
          <div className="_panel">
            <div className="_title">출고 내역</div>
            <div className="_body">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>출고 ID</TableCell>
                    <TableCell>수량</TableCell>
                    <TableCell>StockID</TableCell>
                    <TableCell>일자</TableCell>
                    <TableCell>메모</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {curOrderedItems.map((coi) => {
                    return (
                      <TableRow>
                        <TableCell>{coi.id}</TableCell>
                        <TableCell>{coi.orderedCount}</TableCell>
                        <TableCell>{coi.stockId}</TableCell>
                        <TableCell>
                          {coi.created
                            ? cu.getFormattedDate(coi.created, "yyyy-MM-dd HH:mm:ss")
                            : ""}
                        </TableCell>
                        <TableCell>{coi.memo}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
          <hr />
          <div className="_panel">
            <div className="_title">재고정보</div>
            <div className="_body">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>StockId</TableCell>
                    <TableCell>재고위치</TableCell>
                    <TableCell>입고일자</TableCell>
                    <TableCell>입고</TableCell>
                    <TableCell>출고됨</TableCell>
                    <TableCell>가능</TableCell>
                    <TableCell>
                      현재상품
                      <br />
                      출고현황
                    </TableCell>
                    <TableCell>ApplyCount</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemStocks.map((stock, idx) => (
                    <ItemStockTableRow
                      key={idx}
                      stock={stock}
                      orderId={props.orderId}
                      refresh={() => loadItemStock()}
                    />
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleSave}>Save</Button> */}
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderedItemDialog;
