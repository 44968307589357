import React, { useState } from "react";
import { getThumbUrl } from "../../types/elements";

interface ImgSimpleProps {
  src?: string;
  style?: React.CSSProperties;
}
const ImgSimple: React.FC<ImgSimpleProps> = (props) => {
  const [isError, setError] = useState<boolean>(false);
  return isError || props.src === undefined ? (
    <>No Image</>
  ) : (
    <img src={getThumbUrl(props.src)} style={{ ...props.style }} />
  );
};

export default ImgSimple;
