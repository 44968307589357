import React from "react";
import { Item } from "../../types/elements";
interface ItemCardProps {
  type: "mini";
  item: Item;
}
const ItemCard: React.FC<ItemCardProps> = (props) => {
  return (
    <div>
      {props.type === "mini" && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {props.item?.topImages && props.item?.topImages?.length > 0 && (
            <img style={{ maxWidth: "3rem", maxHeight: "2rem" }} src={props.item?.topImages[0].url} />
          )}
          <span
            style={{
              width: "3rem",
              height: "1rem",
              display: "inline-block",
              overflow: "hidden",
              wordBreak: "keep-all",
            }}>
            {props.item?.name}
          </span>
        </div>
      )}
    </div>
  );
};

export default ItemCard;
