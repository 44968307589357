import Edit from "@mui/icons-material/Edit";
import { Badge, Chip, Switch } from "@mui/material";
import React from "react";

interface SwitchWithCounterProps {
  num?: number;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}
const SwitchWithCounter: React.FC<SwitchWithCounterProps> = (props) => {
  return (
    <Switch
      checked={props.checked}
      onChange={(e, b) => props.onChange && props.onChange(b)}
      checkedIcon={
        <Chip
          label={props.num ?? 0}
          size="small"
          sx={{ marginTop: "-2px", backgroundColor: "gray" }}
        />
      }
      sx={{
        "& .MuiSwitch-thumb ": { backgroundColor: "limegreen" },
        "& .MuiSwitch-track": { backgroundColor: "lime" },
        "& .Mui-checked+.MuiSwitch-track": { backgroundColor: "black" },
      }}
    />
  );
};

export default SwitchWithCounter;
