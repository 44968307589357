import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { AppCommonState } from "../store/states/AppCommonState";

export const useAppCommon = (opt?: { headerTitle?: string }) => {
  const [appCommonState, setAppCommonState] = useRecoilState(AppCommonState);

  useEffect(() => {
    if (opt?.headerTitle) setHeaderTitle(opt.headerTitle);
  }, [opt?.headerTitle]);

  const toggleMenuOpen = (open?: boolean) => {
    setAppCommonState((aps) => ({ ...aps, menuOpen: open !== undefined ? open : !aps.menuOpen }));
  };

  const setHeaderTitle = (title: string) => {
    setAppCommonState((acs) => ({ ...acs, headerTitle: title }));
  };
  return { appCommonState, toggleMenuOpen, setHeaderTitle };
};
