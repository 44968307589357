import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import { OrderStatus, OrderStatusArray } from "../../types/elements";
import { gqladm } from "../../graphql/gqladm";
import useSnack from "../../hooks/useSnack";

interface CreateOrderByOwDialogProps {
  open: boolean;
  onClose: (completed?: boolean) => any;
  owid?: number;
}
const CreateOrderByOwDialog: React.FC<CreateOrderByOwDialogProps> = (props) => {
  const { openSnack } = useSnack();
  const [orderStatus, setOrderStatus] = useState<OrderStatus>("ORDERED");

  const handleClickCreate = () => {
    console.log("handleClickCreate", props.owid, orderStatus);
    props.owid &&
      gqladm
        .createOrderByOrderWaiting(props.owid, orderStatus)
        .then((result) => {
          console.log("Result of createOrderByOrderWaiting", result);
          if (result.ok) {
            props.onClose(true);
          }
        })
        .catch((err) => {
          openSnack({ msg: "Error on CreateOrderByOrderWaiting" + err });
        });
  };

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <div>
          OrderWaiting ID = {props.owid} <br />
          생성할 주문의 상태를 선택하세요 <br />
          <Select value={orderStatus} onChange={(e: any) => setOrderStatus(e.target.value)}>
            {OrderStatusArray.map((o, i) => {
              return (
                <MenuItem key={"OrderStatusArray_" + i} value={o}>
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClickCreate}>
          Create
        </Button>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrderByOwDialog;
