import {
  Log,
  Paging,
  SearchResult,
  toNum,
  User as ELEMENT_USER,
  Reservation as ELEMENT_RESERVATION,
  AdminMeta,
  LogTargetType,
  LogStatusType,
  UserType,
  DAY,
  WithDrawUser,
  convertUser,
} from "./elements";
import { AuthKind, AuthType } from "./elements";
import { OrderStatus, getKrOrderStatus, ReservationStatus, ReservationTime } from "./elements";

export const ADMIN_SESSION_ID = "x-session-adm-id";
export const ADMIN_SESSION_KEY = "x-session-adm-key";
export const SESSION_VALID_TIME = DAY / 2;

export interface AdminUser extends AdminMeta {
  // * Extends
  // id?: number;
  // name?: string;
  // phone?: string;
  // email?: string;
  created?: number;
  blocked?: number;
  lastLogined?: number;
  sessionKey?: string;

  // When login
  check?: string;
  auth?: string;
  lastAuthRequested?: number;

  // Telegram
  telegramId?: number;
  telegramLoginAllow?: boolean;
  telegramRequestId?: number; // pre-telegramId;
  telegramRequestKey?: number; // random key for telegramRequest;
  telegramRequestTime?: number; // requested time for telegramRequestTime;

  // related
  adminNotiTypes?: AdminNotiType[];
  unreadNotiCount?: number;
}

export type AdminNotiType = "NewReservation" | "";

// 관리자 알림 목록
export interface AdminNoti {
  id?: number;
  admin_id?: number;
  created?: number;
  checked?: number;
  type?: AdminNotiType;
  path?: string;
  msg?: string;
}
export interface AdminNotiSearchOption extends Paging {
  admin_id?: number;
  type?: AdminNotiType;
  isCheckedNull?: boolean;
  orderby?: "created_desc";
}

// 관리자에게 적용하는지 안하는지에 대한 목록
export interface AdminNotiApply {
  id?: number;
  admin_id?: number;
  type?: AdminNotiType;
}

export interface User extends ELEMENT_USER {
  userMemos?: SearchResult<UserMemo>;
}

export interface Reservation extends ELEMENT_RESERVATION {
  user?: User;
}

// ! AdminMsg
export type AdminMsgTarget = "Consignment";
export type AdminMsgType = "System" | "Admin";
export interface AdminMsg {
  id?: number;
  created?: number;
  admin_id?: number;

  target?: AdminMsgTarget;
  target_id?: number;

  hide?: boolean;
  msg_type?: AdminMsgType;
  msg?: string;
}
export interface AdminMsgSearchOption extends Paging {
  id?: number;
  target?: AdminMsgTarget;
  target_id?: number;
  target_ids?: number[];
  admin_id?: number;
}

// ! UserMemo
export interface UserMemo {
  id?: number;
  created?: number;
  user_id?: number;
  admin_id?: number;
  memo?: string;

  // Related
  user?: ELEMENT_USER;
  admin?: AdminUser;
}
export interface UserMemoSearchOption extends Paging {
  user_id?: number;
  user_ids?: number[];
}

export interface UserSearchOption extends Paging {
  text?: string;
  id?: number;
  ids?: number[];
  type?: UserType;
  email?: string;
  name?: string;
  phone?: string;
  agSms?: "T" | "F";
  agEmail?: "T" | "F";
  orderby?:
    | "created_desc"
    | "created_asc"
    | "expired_desc"
    | "expired_asc"
    | "order_done_price_desc"
    | "order_done_count_desc"
    | "reservation_noshow_desc";
  expiredBefore?: number;
  expiredAfter?: number;
}
export interface UserDeviceSearchOption extends Paging {
  id?: number;
  ids?: number[];
  user_id?: number;
  user_ids?: number[];
  item_id_for_fcmpush_candidates?: number;
  hasUser?: boolean;
  beforeCreated?: number;
  beforeUpdated?: number;
  orderby?: "created_desc" | "created_asc" | "updated_desc" | "updated_asc";
}

// 메세지 묶음을 보내기 위한 메타 정보
export interface FcmPush {
  id?: number;
  created?: number;
  send_start?: number;
  send_end?: number;

  item_id?: number;
  title?: string;
  body?: string;
  dataUrl?: string;
  imageUrl?: string;

  cnt_succ?: number; // db
  cnt_fail?: number; // db
  cnt_msgs?: number; // calculated

  // Related
  messages?: FcmMessage[];
}
export interface FcmPushSearchOption extends Paging {
  id?: number;
  item_id?: number;
  isUpdated?: boolean;
  isNotUpdated?: boolean;
  orderby?: "created_desc" | "created_asc";
}
export interface FcmMessageBody {
  title?: string;
  body?: string;
  dataUrl?: string;
  imageUrl?: string;
}

export interface FcmMessage extends FcmMessageBody {
  id?: number;
  created?: number;
  updated?: number;
  fcmToken?: string;
  // title, body, dataUrl, imageUrl  - Extends from FcmMessageBody
  resultKey?: string;
  fcmpush_id?: number; // connected with fcmpush
  usernoti_id?: number; // connected with usernoti
  // Related
  user_id?: number; // connect with user
}
export interface FcmMessageSearchOption extends Paging {
  fcmpush_id?: number;
  orderby?: "created_desc" | "created_asc" | "updated_desc" | "updated_asc";
}

// 메세지 보낼 대상 테이블
export interface FcmPushUser {
  id?: number;
  created?: number;
  fcmpush_id?: number;
  user_id?: number;
  sent?: number;
  usernoti_id?: number;

  // Related
  user?: User;
  // fcmpush: FcmPush;
}
export interface FcmPushUserSearchOption extends Paging {
  id?: number;
  fcmpush_id?: number;
  user_id?: number;
  orderby?: "created_desc" | "created_asc";
}

export interface UserResolver {
  user: User;
}
export interface AdminResolver {
  adminUser?: AdminUser;
  adminUsers?: SearchResult<AdminUser>;
}

export interface AdminUserSearchOption extends Paging {
  admin_id?: number;
  name?: string;
  email?: string;
}
export interface LogSearchOption extends Paging {
  target?: LogTargetType;
  targets?: LogTargetType[];
  notTargets?: LogTargetType[];
  status?: LogStatusType;
  msg?: string;
  orderby?: "created_desc" | "created_asc" | "elapsed_desc" | "elapsed_asc";
  afterCreated?: number;
  beforeCreated?: number;
  afterElapsed?: number;
  beforeElapsed?: number;
}
export interface WarehouseSearchOption extends Paging {
  name?: string;
}

// ! ORDER - STOCK_APPLIED_ORDER_STATUSES
export const STOCK_APPLIED_ORDER_STATUSES: OrderStatus[] = ["ORDERED", "CHECKING", "PREPARING", "DELIVERYING", "DONE"];

// ! AdminApiPath
export enum ADMIN_API_PATH {
  QNA = "/qna",
  ORDER = "/order",
  STOCK = "/stock",
  SPAM = "/spam",
  RESERVATION = "/reservation",
  RESERVATION_BY_ID = "/reservation/:id",
  RESERVATIONLIMIT = "/reservationlimit",
  CONTACT = "/contact",
  CONTACT_BY_ID = "/contact/:id",
  // FILE = "/file",
  // FILE_BY_ID = "/file/:id",

  ORDER_WAITING = "/orderwaiting",

  NOTIFICATION_TEMPLATE = "/notification/template",
  NOTIFICATION_TEMPLATE_BY_ID = "/notification/template/:id",

  NOTIFICATION_GENERATE = "/notification/generate",
  NOTIFICATION_TEST = "/notification/test",
  NOTIFICATION_META = "/notification/meta",

  NOTIFICATION_SETUP = "/notification/setup",

  CONSTANT = "/constant",
  CONSTANT_BY_ID = "/constant/:id",

  // ITEM_QNA = '/itemqna',
  // ITEM_QNA_BY_ID = '/itemqna/:id',

  IMP_AUTH = "/imp_auth",
  // IMP_PAYMENTS = '/imp_payments',
  IMP_PAYMENTS_STATUS = "/imp_payments/status",
}

// export const ADMIN_AUTH = '/admin/auth';
// export const ADMIN_AUTH_HISTORY = '/admin/authhistory';

export interface ReqSmsBody {
  phoneNumbers: string[];
  users: string[];
  smsMsg: string;
}

export type AdminHistoryType =
  | "withDrawUser"
  | "changeOrderAddress"
  | "changeOrderReceipt"
  | "changeOrderItemCount"
  | "changeOrderItemDelete"
  | "changeOrderItemAdd"
  | "changeOrderPrice"
  | "changeOrderStatus"
  | "deleteOrder"
  | "syncOrderPrice"
  | "sendFcmPush";
export interface AdminHistory {
  id?: number;
  created?: number;
  admin_id?: number;
  type?: AdminHistoryType;
  target_id?: number;
  user_id?: number;
  msg?: string;
  // Related
  user?: User;
}

export interface AdminHistorySearchOption extends Paging {
  type?: AdminHistoryType;
  user_id?: number;
  target_id?: number;
  admin_id?: number;
  orderby?: "created_desc" | "created_asc";
}

export const convertAdminHistory = (o?: AdminHistory): AdminHistory | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      admin_id: toNum(o.admin_id),
      created: toNum(o.created),
      target_id: toNum(o.target_id),
      user_id: toNum(o.user_id),
    };
};

// ! AuthHistory
export interface AuthHistory {
  auth_name?: AuthKind;
  type?: AuthType;
  target?: string;
  str_key?: string;
  num_key?: string;
  valid?: boolean;
  authorized?: boolean;
  awsMessageId?: string;
  awsRequestId?: string;
  requestedDate?: number;
  authorizedDate?: number;
  updated?: number;
  checkcount?: number;
}

//  ! IamPort
export interface ImpResponse<T> {
  code?: number;
  message?: string;
  response?: T;
}

export interface PaymentsStatusOption {
  // token?: string;
  status?: PaymentStatus;

  page?: number; // 1부터 시작. 기본값 1
  limit?: number; // 한 번에 조회할 결제건수.(최대 100건, 기본값 20건)
  from?: number;
  to?: number;
  sorting?: "-started" | "started" | "-paid" | "paid" | "-updated" | "updated";
  // -started : 결제시작시각(결제창오픈시각) 기준 내림차순(DESC) 정렬
  // started : 결제시작시각(결제창오픈시각) 기준 오름차순(ASC) 정렬
  // -paid : 결제완료시각 기준 내림차순(DESC) 정렬
  // paid : 결제완료시각 기준 오름차순(ASC) 정렬
  // -updated : 최종수정시각(결제건 상태변화마다 수정시각 변경됨) 기준 내림차순(DESC) 정렬
  // updated : 최종수정시각(결제건 상태변화마다 수정시각 변경됨) 기준 오름차순(ASC) 정렬
}
export interface PaymentsByMidParams {
  m_id?: string;
  page?: number; // 1부터 시작. 기본값 1
  limit?: number; // 한 번에 조회할 결제건수.(최대 100건, 기본값 20건)
  sorting?: "-started" | "started" | "-paid" | "paid" | "-updated" | "updated";
}

export type PaymentStatus = "all" | "ready" | "paid" | "cancelled" | "failed";

export interface AuthAnnotation {
  access_token?: string; // 인증이 필요한 REST API요청에 사용할 access_token,
  expired_at?: number; // token만료시각.UNIX timestamp,
  now?: number; // 현재시각 UNIX timestamp.token만료시각을 정확히 계산하기 위해 사용
}

export interface PagedPaymentAnnotation {
  total?: number; // 검색한 결제 상태에 대한 전체 건수 ,
  previous?: number; //  이전 page숫자.이전 페이지가 없으면 0,
  next?: number; //  다음 page숫자.다음 페이지가 없으면 0,
  list?: Array<PaymentAnnotation>; //  결제 상세정보 배열(최대 20개).바로 아래 Payment structure를 확인하세요.
}

export interface PaymentAnnotation {
  imp_uid?: string; // 아임포트 결제 고유 UID ,
  merchant_uid?: string; // 가맹점에서 전달한 거래 고유 UID ,
  pay_method?: string; // samsung : 삼성페이 / card : 신용카드 / trans : 계좌이체 / vbank : 가상계좌 ,
  channel?: string; // 결제가 발생된 경로. pc:(인증방식)PC결제, mobile:(인증방식)모바일결제, api:정기결제 또는 비인증방식결제 = ['pc', 'mobile', 'api'],
  pg_provider?: string; // PG사 명칭. inicis(이니시스) / nice(나이스정보통신) ,
  pg_tid?: string; // PG사 승인정보 ,
  pg_id?: string; // 거래가 처리된 PG사 상점아이디 ,
  escrow?: boolean; // 에스크로결제 여부 ,
  apply_num?: string; // 카드사 승인정보(계좌이체/가상계좌는 값 없음) ,
  bank_code?: string; // 은행 표준코드 - (금융결제원기준) ,
  bank_name?: string; // 은행 명칭 - (실시간계좌이체 결제 건의 경우) ,
  card_code?: string; // 카드사 코드번호(금융결제원 표준코드번호) = ['361(BC카드)', '364(광주카드)', '365(삼성카드)', '366(신한카드)', '367(현대카드)', '368(롯데카드)', '369(수협카드)', '370(씨티카드)', '371(NH카드)', '372(전북카드)', '373(제주카드)', '374(하나SK카드)', '381(KB국민카드)', '041(우리카드)', '071(우체국)'],
  card_name?: string; // 카드사 명칭 - (신용카드 결제 건의 경우) ,
  card_quota?: number; // 할부개월 수(0이면 일시불) ,
  card_number?: string; // 결제에 사용된 마스킹된 카드번호. 7~12번째 자리를 마스킹하는 것이 일반적이지만, PG사의 정책/설정에 따라 다소 차이가 있을 수 있음 ,
  card_type?: number; // 카드유형. (주의)해당 정보를 제공하지 않는 일부 PG사의 경우 null 로 응답됨(ex. JTNet, 이니시스-빌링) = ['null', '0(신용카드)', '1(체크카드)'],
  vbank_code?: string; // 가상계좌 은행 표준코드 - (금융결제원기준) ,
  vbank_name?: string; // 입금받을 가상계좌 은행명 ,
  vbank_num?: string; // 입금받을 가상계좌 계좌번호 ,
  vbank_holder?: string; // 입금받을 가상계좌 예금주 ,
  vbank_date?: number; // 입금받을 가상계좌 마감기한 UNIX timestamp ,
  vbank_issued_at?: number; // 가상계좌 생성 시각 UNIX timestamp ,
  name?: string; // 주문명칭
  amount?: number; // 주문(결제)금액 ,
  cancel_amount?: number; // 결제취소금액 ,
  currency?: string; // 결제승인화폐단위(KRW:원, USD:미화달러, EUR:유로) ,
  buyer_name?: string; // 주문자명 ,
  buyer_email?: string; // 주문자 Email주소 ,
  buyer_tel?: string; // 주문자 전화번호 ,
  buyer_addr?: string; // 주문자 주소 ,
  buyer_postcode?: string; // 주문자 우편번호 ,
  custom_data?: string; // 가맹점에서 전달한 custom data. JSON string으로 전달 ,
  user_agent?: string; // 구매자가 결제를 시작한 단말기의 UserAgent 문자열 ,
  status?: "ready" | "paid" | "cancelled" | "failed"; // 결제상태. ready:미결제, paid:결제완료, cancelled:결제취소, failed:결제실패 = ['ready', 'paid', 'cancelled', 'failed'],
  started_at?: number; // 결제시작시점 UNIX timestamp. IMP.request_pay() 를 통해 결제창을 최초 오픈한 시각 ,
  paid_at?: number; // 결제완료시점 UNIX timestamp. 결제완료가 아닐 경우 0 ,
  failed_at?: number; // 결제실패시점 UNIX timestamp. 결제실패가 아닐 경우 0 ,
  cancelled_at?: number; // 결제취소시점 UNIX timestamp. 결제취소가 아닐 경우 0 ,
  fail_reason?: string; // 결제실패 사유 ,
  cancel_reason?: string; // 결제취소 사유 ,
  receipt_url?: string; // 신용카드 매출전표 확인 URL ,
  cancel_history?: Array<PaymentCancelAnnotation>; // 취소/부분취소 내역 ,
  cancel_receipt_urls?: Array<string>; // (Deprecated : cancel_history 사용 권장) 취소/부분취소 시 생성되는 취소 매출전표 확인 URL. 부분취소 횟수만큼 매출전표가 별도로 생성됨 ,
  cash_receipt_issued?: boolean; // 현금영수증 자동발급 여부 ,
  customer_uid?: string; // 해당 결제처리에 사용된 customer_uid. 결제창을 통해 빌링키 발급 성공한 결제건의 경우 요청된 customer_uid 값을 응답합니다. ,
  customer_uid_usage?: string; // customer_uid가 결제처리에 사용된 상세 용도.(null:일반결제, issue:빌링키 발급, payment:결제, payment.scheduled:예약결제 = ['issue', 'payment', 'payment.scheduled']
}

export interface PaymentCancelAnnotation {}

// ! Notification Template

export interface NotificationTemplate {
  id?: number;
  name?: string;
  created?: number;
  updated?: number;

  datatype?: NotificationEvent;
  enabledEmail?: boolean;
  enabledSms?: boolean;

  title?: string;
  body?: string;
  sms?: string;
}

export interface NotificationSetup {
  type: NotificationType;
  event: NotificationEvent;

  template_id: number;
}

export interface NotificationTest {
  type?: NotificationType;
  title?: string;
  body?: string;
  sms?: string;
  target?: string;
}

export type NotificationType = "email" | "sms";
export const NotificationTypeArr = ["email", "sms"];

export const getNotificationType = (type: string): NotificationType | undefined => {
  if (type === "email") {
    return "email";
  } else if (type === "sms") {
    return "sms";
  } else {
    return undefined;
  }
};

export type NotificationEvent =
  | "JOIN"
  | "ORDER_INITIALIZED_DIRECT"
  | "ORDER_ORDERED"
  | "ORDER_DELIVERYING"
  | "ORDER_DONE"
  | "RESERVATION_DONE";
// ORDER_UPDATE = 'ORDER_UPATE',
// EVENT = 'EVENT'
export const NotificationEventArr = [
  "JOIN",
  "ORDER_INITIALIZED_DIRECT",
  "ORDER_ORDERED",
  "ORDER_DELIVERYING",
  "ORDER_DONE",
  "RESERVATION_DONE",
];

export const getNotificationEvent = (event: string): NotificationEvent | undefined => {
  if (event === "JOIN") {
    return "JOIN";
  } else if (event === "ORDER_INITIALIZED_DIRECT") {
    return "ORDER_INITIALIZED_DIRECT";
  } else if (event === "ORDER_ORDERED") {
    return "ORDER_ORDERED";
  } else if (event === "ORDER_DELIVERYING") {
    return "ORDER_DELIVERYING";
  } else if (event === "ORDER_DONE") {
    return "ORDER_DONE";
  } else if (event === "RESERVATION_DONE") {
    return "RESERVATION_DONE";
  } else {
    return undefined;
  }
};

interface NotificationEventCommon {
  useremail: string;
  username: string;
  userphone: string;
  created: number;
}

export interface NotificationEventJoin extends NotificationEventCommon {}

export interface NotificationEventOrder extends NotificationEventCommon {
  orderId: number;
  // orderInfo: Order;

  status: OrderStatus;
  statuskr: string;
  status_text: string;
  ordered: number;
  ordered_text: string;
  updated: number;

  items: Array<{
    id: number;
    imgurl: string;
    name: string;
    optionName: string;
    price: number;
    price_text: string;
    count: number;
  }>;

  addr1: string;
  addr2: string;
  zipcode: string;
  addrphone: string;
  deliver_msg: string;

  paytype: "credit" | "direct";
  paytype_text: string;
  price: number;
  price_text: string;
}

export interface NotificationEventReservation extends NotificationEventCommon {
  reservationId: number;
  name?: string;
  phone?: string;
  date: string;
  time: ReservationTime;
  status: ReservationStatus;
  memberCount: number;
  memo: string;
  created: number;

  date_year: string;
  date_month: string;
  date_date: string;
  time_ampm: string;
  time_ampmkr: string;
}

export interface NotificationMeta {
  name: string;
  meta: any;
}

export const getNotificationSample = (
  evt: NotificationEvent
): NotificationEventJoin | NotificationEventOrder | NotificationEventReservation | undefined => {
  switch (evt) {
    case "JOIN":
      const ret1: NotificationEventJoin = {
        useremail: "simsungkyu@gmail.com",
        username: "심성규",
        created: Date.now(),
        userphone: "01031856086",
      };
      return ret1;
    case "ORDER_ORDERED":
      const ret2: NotificationEventOrder = {
        useremail: "simsungkyu@gmail.com",
        username: "심성규",
        userphone: "01031856086",
        created: Date.now(),

        orderId: 1,
        status: "ORDERED",
        statuskr: getKrOrderStatus("ORDERED"),
        status_text: "주문 접수",
        ordered: Date.now(),
        ordered_text: "2020-08-08",
        updated: Date.now(),

        items: [
          {
            id: 2,
            imgurl: "https://dev.andersonc-design.com/image/37454f90-74c5-11ea-af81-ff487f614c3b.png",
            name: "test item",
            optionName: "option",
            price: 200000,
            price_text: "₩ 200,000",
            count: 1,
          },
        ],

        addr1: "서울특별시 강남구 청담동 86-5",
        addr2: "헤이븐 빌딩 2층",
        zipcode: "01030",
        addrphone: "010-0000-0000",
        deliver_msg: "test",

        paytype: "credit",
        paytype_text: "신용카드",
        price: 200000,
        price_text: "₩ 2,000,000",
      };
      return ret2;
    case "ORDER_DELIVERYING":
      const ret3: NotificationEventOrder = {
        useremail: "simsungkyu@gmail.com",
        username: "심성규",
        userphone: "01031856086",
        created: Date.now(),

        orderId: 1,
        status: "DELIVERYING",
        statuskr: getKrOrderStatus("DELIVERYING"),
        status_text: "발송",
        ordered: Date.now(),
        ordered_text: "2020-08-08",
        updated: Date.now(),

        items: [
          {
            id: 2,
            imgurl: "https://dev.andersonc-design.com/image/37454f90-74c5-11ea-af81-ff487f614c3b.png",
            name: "test item",
            optionName: "option",
            price: 200000,
            price_text: "₩ 200,000",
            count: 1,
          },
        ],

        addr1: "서울특별시 강남구 청담동 86-5",
        addr2: "헤이븐 빌딩 2층",
        zipcode: "01030",
        addrphone: "010-0000-0000",
        deliver_msg: "test",

        paytype: "credit",
        paytype_text: "신용카드",
        price: 200000,
        price_text: "₩ 2,000,000",
      };
      return ret3;
    case "ORDER_DONE":
      const ret4: NotificationEventOrder = {
        useremail: "simsungkyu@gmail.com",
        username: "심성규",
        userphone: "01031856086",
        created: Date.now(),

        orderId: 1,
        status: "DONE",
        statuskr: getKrOrderStatus("DONE"),
        status_text: "완료",
        ordered: Date.now(),
        ordered_text: "2020-08-08",
        updated: Date.now(),

        items: [
          {
            id: 2,
            imgurl: "https://dev.andersonc-design.com/image/37454f90-74c5-11ea-af81-ff487f614c3b.png",
            name: "test item",
            optionName: "option",
            price: 200000,
            price_text: "₩ 200,000",
            count: 1,
          },
        ],

        addr1: "서울특별시 강남구 청담동 86-5",
        addr2: "헤이븐 빌딩 2층",
        zipcode: "01030",
        addrphone: "010-0000-0000",
        deliver_msg: "test",

        paytype: "credit",
        paytype_text: "신용카드",
        price: 200000,
        price_text: "₩ 2,000,000",
      };
      return ret4;
    case "ORDER_INITIALIZED_DIRECT":
      const ret5: NotificationEventOrder = {
        useremail: "simsungkyu@gmail.com",
        username: "심성규",
        userphone: "01031856086",
        created: Date.now(),

        orderId: 1,
        status: "INITIALIZED",
        statuskr: getKrOrderStatus("INITIALIZED"),
        status_text: "입금전",
        ordered: Date.now(),
        ordered_text: "2020-08-08",
        updated: Date.now(),

        items: [
          {
            id: 2,
            imgurl: "https://dev.andersonc-design.com/image/37454f90-74c5-11ea-af81-ff487f614c3b.png",
            name: "test item",
            optionName: "option",
            price: 200000,
            price_text: "₩ 200,000",
            count: 1,
          },
        ],

        addr1: "서울특별시 강남구 청담동 86-5",
        addr2: "헤이븐 빌딩 2층",
        zipcode: "01030",
        addrphone: "010-0000-0000",
        deliver_msg: "test",

        paytype: "direct",
        paytype_text: "무통장입금",
        price: 200000,
        price_text: "₩ 2,000,000",
      };
      return ret5;
    case "RESERVATION_DONE":
      const ret6: NotificationEventReservation = {
        useremail: "simsungkyu@gmail.com",
        username: "심성규",
        userphone: "01031856086",
        created: Date.now(),

        reservationId: 10,
        name: "심성규",
        phone: "01031856086",

        date: "2021-01-30",
        time: "11:30",
        status: "Reserved",
        memberCount: 2,
        memo: "",

        date_year: "2021",
        date_month: "1",
        date_date: "30",
        time_ampm: "AM",
        time_ampmkr: "오전",
      };
      return ret6;
    default:
      return;
  }
};

// ! SmsAligoTypes
interface reqCommon {
  key?: string;
  user_id?: string;
  sender?: string; // 발신자 전화번호(최대 16bytes)OString
}

interface resCommon {
  result_code?: number; //  결과코드(API 수신유무) Integer
  message?: string; // 결과 메세지( result_code 가 0 보다 작은경우 실패사유 표기) String
}

export interface ReqSmsAligoSend extends reqCommon {
  /** 수신자 전화번호 - 컴마()분기 입력으로 최대 1천명OString */
  receiver: string;
  /** 메시지 내용OString(1~2, 000Byte) */
  msg: string;
  /** SMS(단문), LMS(장문), MMS(그림문자) 구분X String */
  msg_type?: string;
  /** 문자제목(LMS, MMS만 허용) X String(1~44Byte) */
  title?: string;
  /** % 고객명 % 치환용 입력 X String */
  destination?: string;
  /** 예약일(현재일이상) X YYYYMMDD */
  rdate?: string;
  /** 예약시간 - 현재시간기준 10분이후 X HHII */
  rtime?: string;
  /** 첨부이미지 X JPEG, PNG, GIF */
  image?: string;
  /** 연동테스트시 Y 적용 X String */
  testmode_yn?: string;
}
export interface ResSmsAligoSend extends resCommon {
  /**  결과코드(API 수신유무) Integer */
  result_code: number;
  /**  결과 메세지( result_code 가 0 보다 작은경우 실패사유 표기) String */
  message: string;
  /**  메세지 고유 ID Integer */
  msg_id: string;
  /**  요청성공 건수 Integer */
  success_cnt: number;
  /** 요청실패 건수 Integer */
  error_cnt: number;
  /** 메시지 타입(1. SMS, 2.LMS, 3. MMS) String */
  msg_type: number;

  // extra
  /** 수신번호목록 */
  phones: Array<string>;
}

export interface MassMessage {
  /** 수신자 전화번호1 O String */
  rec: string;
  /** 메시지 내용1 O String (1~2,000Byte) */
  msg: string;
}
export interface ReqSmsAligoSendMass extends reqCommon {
  mass_msg: Array<MassMessage>;
  cnt: number; // 메세지 전송건수(번호,메세지 매칭건수) O Integer(1~500)
  title?: string; // 문자제목(LMS,MMS만 허용) X String (1~44Byte)
  msg_type: string; // SMS(단문) , LMS(장문), MMS(그림문자) 구분 O String
  rdate?: string; // 예약일 (현재일이상) X YYYYMMDD
  rtime?: string; // 예약시간 - 현재시간기준 10분이후 X HHII
  image?: string; // 첨부이미지 X JPEG,PNG,GIF
  testmode_yn?: string; //  연동테스트시 Y 적용 X String
}
export interface ResSmsAligoSendMass extends resCommon {
  msg_id: number; // 메세지 고유ID Integer
  success_cnt: number; // 요청성공 건수 Integer
  error_cnt: number; // 요청실패 건수 Integer
  msg_type: number; // 메시지 타입 (1. SMS, 2.LMS, 3. MMS) String
}

export interface ReqSmsAligoList extends reqCommon {
  page?: number; // 페이지번호 X(기본 1) Integer
  page_size?: number; //  페이지당 출력갯수 X(기본 30) 30~500 Integer
  start_date?: string; // 조회시작일자 X(기본 최근일자) YYYYMMDD
  limit_day?: number; // 조회마감일자 X Integer
}
export interface SmsAligoHistoryItem {
  /**  메세지ID Integer */
  mid: number;
  /** 문자구분(유형) String */
  type: string;
  /** 발신번호 String */
  sender: string;
  /** 전송요청수 Integer */
  sms_count: number;
  /** 요청상태 String */
  reserve_state: string;
  /** 메세지 내용 String */
  msg: string;
  /** 처리실패건수 Integer */
  fail_count: number;
  /** 등록일 YYYY - MM - DD HH: ii: ss */
  reg_date: string;
  /** 예약일자 YYYY - MM - DD HH: ii: ss */
  reserve: string;

  // extra
  details?: Array<SmsDetailItem>;
}
export interface ResSmsAligoList {
  //  결과코드(API 수신유무) Integer
  result_code: number;
  // 결과 메세지( result_code 가 0 보다 작은경우 실패사유 표기) String
  message: string;
  //  목록 배열 Array
  list: Array<SmsAligoHistoryItem>;
  // 다음조회목록 유무 String
  next_yn: string;
}

export interface SmsDetailItem {
  mdid: number; // 메세지 상세ID Integer
  type: string; // 문자구분(유형) String
  sender: string; // 발신번호 String
  receiver: string; // 수신번호 String
  sms_state: string; // 전송상태 String
  reg_date: string; // 등록일 YYYY - MM - DD HH: ii: ss
  send_date: string; // 전송일 YYYY - MM - DD HH: ii: ss
  reserve_date: string; // 예약일 YYYY - MM - DD HH: ii
}
export interface ReqSmsAligoSmsList extends reqCommon {
  mid: number; // 메세지 고유ID O Integer
  page?: number; // 페이지번호 X(기본 1) Integer
  page_size?: number; // 페이지당 출력갯수 X(기본 30) 30~500 Integer
}
export interface ResSmsAligoSmsList extends resCommon {
  list: Array<SmsDetailItem>; // 목록 배열 Array
  next_yn: string; // 다음조회목록 유무 String
}

export interface ReqSmsAligoRemain extends reqCommon {}
export interface ResSmsAligoRemain extends resCommon {
  SMS_CNT: number;
  LMS_CNT: number;
  MMS_CNT: number;
}

export interface ReqSmsAligoCancel extends reqCommon {
  mid: number; // 메세지ID O Integer
}
export interface ResSmsAligoCancel extends resCommon {
  result_code: number; // 결과코드(API 수신유무) Integer
  message: string; //결과 메세지( result_code 가 0 보다 작은경우 실패사유 표기) String
  cancel_date: string; // 취소일자 YYYY-MM-DD HH:II:SS
}

// ! convert

export const convertAdminNoti = (o?: AdminNoti): AdminNoti | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      admin_id: toNum(o.admin_id),
      created: toNum(o.created),
      checked: toNum(o.checked),
    };
};
export const convertAdminNotiApply = (o?: AdminNotiApply): AdminNotiApply | undefined => {
  if (o)
    return {
      ...o,
      admin_id: toNum(o.admin_id),
    };
};
export const convertAdminUser = (au?: AdminUser): AdminUser | undefined => {
  if (au)
    return {
      ...au,
      id: toNum(au.id),
      created: au.created ? Number(au.created) : undefined,
      blocked: au.blocked ? Number(au.blocked) : undefined,
      lastLogined: au.lastLogined ? Number(au.lastLogined) : undefined,
      lastAuthRequested: au.lastAuthRequested ? Number(au.lastAuthRequested) : undefined,
      telegramId: au.telegramId ? Number(au.telegramId) : undefined,
      telegramRequestId: au.telegramRequestId ? Number(au.telegramRequestId) : undefined,
      telegramRequestKey: au.telegramRequestKey ? Number(au.telegramRequestKey) : undefined,
      telegramRequestTime: au.telegramRequestTime ? Number(au.telegramRequestTime) : undefined,
    };
};
export const convertLog = (o?: Log): Log | undefined => {
  if (o)
    return {
      ...o,
      created: o.created ? Number(o.created) : undefined,
      elapsed: o.elapsed ? Number(o.elapsed) : undefined,
      started: o.started ? Number(o.started) : undefined,
      ended: o.ended ? Number(o.ended) : undefined,
    };
};

export const convertNotificationTemplate = (o?: NotificationTemplate): NotificationTemplate | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      created: toNum(o.created),
      updated: toNum(o.updated),
    };
};

export const convertUserMemo = (o?: UserMemo): UserMemo | undefined => {
  if (o)
    return { ...o, id: toNum(o.id), created: toNum(o.created), admin_id: toNum(o.admin_id), user_id: toNum(o.user_id) };
};

export interface ItemPropertySearchOption {
  item_id?: number;
  name?: string;
}
export const convertFcmPush = (o?: FcmPush): FcmPush | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      created: toNum(o.created),
      send_start: toNum(o.send_start),
      send_end: toNum(o.send_end),
      cnt_succ: toNum(o.cnt_succ),
      cnt_fail: toNum(o.cnt_fail),
      item_id: toNum(o.item_id),
    };
};
export const convertFcmPushUser = (o?: FcmPushUser): FcmPushUser | undefined => {
  if (o)
    return {
      ...o,
      id: toNum(o.id),
      created: toNum(o.created),
      fcmpush_id: toNum(o.fcmpush_id),
      user_id: toNum(o.user_id),
      user: convertUser(o.user),
    };
};
export const convertFcmMessage = (o?: FcmMessage): FcmMessage | undefined => {
  if (o) return { ...o, id: toNum(o.id), created: toNum(o.created), updated: toNum(o.updated) };
};

export interface WithDrawUserSearchOption extends Paging {
  orderby: "created_desc" | "created_asc";
}

export const convertWithDrawUser = (o?: WithDrawUser): WithDrawUser | undefined => {
  if (o)
    return { ...o, id: toNum(o.id), user_id: toNum(o.user_id), admin_id: toNum(o.admin_id), created: toNum(o.created) };
};
