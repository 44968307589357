import { Button, ButtonGroup, Pagination, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { useAppCommon } from "../../../hooks/useAppCommon";
import { Image } from "../../../types/elements";
import ImageListItem from "./ImageListItem";

interface ImageListViewProps {}
const ImageListView: React.FC<ImageListViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "이미지 관리" });

  const [limit, setLimit] = useState<number>(20);
  const [list, setList] = useState<Image[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);

  const [hideHasThumbnail, setHideHasThumbnail] = useState<boolean>(false);

  const loadPage = (page: number) => {
    gqladm.searchImages({ offset: (page - 1) * limit, limit }).then((result) => {
      if (result.list) setList(result.list);
      if (result.total !== undefined) setTotal(result.total);
      if (result.offset !== undefined) setOffset(result.offset);
    });
  };
  useEffect(() => {
    loadPage(1);
  }, []);

  const handleChangePage = (e: any, page: number) => {
    loadPage(page);
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div> 이미지 전체 수 : {total} </div>
        <div>
          페이지당 이미지 수 :
          <ButtonGroup size="small">
            <Button
              size="small"
              onClick={() => setLimit(20)}
              variant={limit === 20 ? "contained" : undefined}>
              20
            </Button>
            <Button
              size="small"
              onClick={() => setLimit(50)}
              variant={limit === 50 ? "contained" : undefined}>
              50
            </Button>
            <Button
              size="small"
              onClick={() => setLimit(100)}
              variant={limit === 100 ? "contained" : undefined}>
              100
            </Button>
            <Button
              size="small"
              onClick={() => setLimit(500)}
              variant={limit === 500 ? "contained" : undefined}>
              500
            </Button>
          </ButtonGroup>
        </div>
        <div>
          썸네일이 있는경우 숨기기
          <Switch value={hideHasThumbnail} onChange={(e, c) => setHideHasThumbnail(c)} />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Pagination
          count={Math.ceil(total / limit)}
          page={Math.ceil(offset / limit) + 1}
          onChange={handleChangePage}
        />
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {list.map((image) => {
          return (
            <ImageListItem
              key={`${Date.now()}-${image.id}`}
              image={image}
              hideHasThumbnail={hideHasThumbnail}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ImageListView;
