import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { CustomImage, Image, ImageSearchOption, SearchResult } from "../../types/elements";
import { gqladm } from "../../graphql/gqladm";
import Pagination from "@mui/material/Pagination";

const LIMIT = 10;
interface PublicImageSelectorDialogProps {
  open: boolean;
  onClose: (selected?: Image) => any;
}
const PublicImageSelectorDialog: React.FC<PublicImageSelectorDialogProps> = (props) => {
  const [sopt, setSopt] = useState<ImageSearchOption>({ limit: LIMIT, offset: 0, itemId: -1 });
  const [total, setTotal] = useState<number>(0);
  const [list, setList] = useState<Image[]>([]);
  useEffect(() => {
    if (props.open) {
      load(sopt);
    }
  }, [props.open]);
  const resultMapper = (result: SearchResult<Image>) => {
    console.log("result", result);
    if (result.list) setList(result.list);
    if (result.total) setTotal(result.total);
    setSopt({
      ...sopt,
      limit: result.limit !== undefined ? result.limit : 10,
      offset: result.offset !== undefined ? result.offset : 0,
    });
  };

  const errorMapper = (err: any) => {
    console.log("Error on searchImages", err);
  };
  const load = (sopt: ImageSearchOption) => {
    gqladm
      .searchImages({ ...sopt })
      .then(resultMapper)
      .catch(errorMapper);
  };

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Public Image Selector</DialogTitle>
      <DialogContent>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
              page={
                Math.ceil((sopt.offset ? sopt.offset : 0) / (sopt.limit ? sopt.limit : LIMIT)) + 1
              }
              onChange={handleChangePage}
            />
          </div>
          <div
            style={{
              display: "flex",
              minWidth: "500px",
              width: "500px",
              height: "500px",
              minHeight: "500px",
              flexWrap: "wrap",
              padding: "10px",
            }}>
            {list.map((image) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "5px",
                  }}>
                  <img style={{ width: "128px" }} src={"/" + image.url} />
                  <div>{image.name}</div>
                  <Button onClick={() => props.onClose(image)} variant="contained" color="primary">
                    선택
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PublicImageSelectorDialog;
