import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import { FcmMessage } from "../../../types/adm_elements";
import { UserDevice } from "../../../types/elements";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useSendPush } from "../../../hooks/useSendPush";

interface AreaPushProps {
  devices: UserDevice[];
  dataUrl?: string;
  imageUrl?: string;
}
const AreaPush: React.FC<AreaPushProps> = (props) => {
  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string>("");
  const [dataUrl, setDataUrl] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");

  const { sendPushs, succCount, failCount, allCount, status } = useSendPush();

  const handleSend = () => {
    const fcmMessages = props.devices.map(
      (dv) => ({ fcmToken: dv.fcmToken, title, body, dataUrl, imageUrl } as FcmMessage)
    );

    sendPushs(fcmMessages);
  };

  useEffect(() => void (props.dataUrl && setDataUrl(props.dataUrl)), [props.dataUrl]);
  useEffect(() => void (props.imageUrl && setImageUrl(props.imageUrl)), [props.imageUrl]);
  return (
    <div style={{ border: "1px solid gray", margin: "0.5rem", padding: "1rem" }}>
      <div>
        Push 메세지 전송 - 총 {props.devices.length}개 장치 (Android=
        {props.devices.filter((o) => o.os === "android").length} / iOS=
        {props.devices.filter((o) => o.os === "ios").length})
      </div>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 2 }}>
            <div>
              title:
              <TextField
                size="small"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div>
              body:
              <TextField
                size="small"
                fullWidth
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />
            </div>

            <div>
              DataUrl:
              <TextField
                size="small"
                fullWidth
                value={dataUrl}
                onChange={(e) => setDataUrl(e.target.value)}
              />
            </div>
            <div>
              ImageUrl:
              <TextField
                size="small"
                fullWidth
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
              />
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div>{title}</div>
            <div>{body}</div>
            <Button
              disabled={!dataUrl}
              variant={"contained"}
              size="small"
              onClick={() => window.open(dataUrl, "_blank")}>
              DataUrl 연결대상 확인&nbsp;
              <ExitToAppIcon />
            </Button>
            <img src={imageUrl} style={{ width: "100px", height: "100px" }} />
          </div>
        </div>
        <Button
          onClick={handleSend}
          variant={status === "Done" ? "contained" : undefined}
          disabled={status === "Running"}>
          Push 전송 {succCount}/{failCount}/{allCount}
        </Button>
      </div>
    </div>
  );
};

export default AreaPush;
