import { Button, ButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../../graphql/gqladm";
import { BoardBlock } from "../../../../types/elements";
import MultiImageUploadButton from "../../../organisms/MultiImageUploadButton";
import TextEditor from "./TextEditor";

interface BlockDetailProps {
  block: BoardBlock;
  onRefresh: () => void;
  onSelectThumbnaleId: (id: number) => void;
}
const BlockDetail: React.FC<BlockDetailProps> = (props) => {
  useEffect(() => {
    setBlock(props.block);
  }, [props.block]);
  const [block, setBlock] = useState<BoardBlock>(props.block);

  const load = (id: number) => {
    gqladm.getBoardBlock(id).then((result) => {
      if (result) setBlock(result);
    });
  };

  const handleUploadImageDone = (
    temp_id: number,
    status: "ing" | "done" | "fail",
    image_id?: number | undefined,
    mi_id?: number | undefined
  ) => {
    if (status === "done" && props.block.id) load(props.block.id);
  };

  const handleClickRemove = () => {
    if (window.confirm("정말로 삭제하시겠습니까? ") && props.block.id)
      gqladm.removeBoardBlock(props.block.id).then((result) => {
        if (result.ok) props.onRefresh();
      });
  };
  const handleClickBindImage = (image_id: number) => {
    const imageids = ((props.block.image_ids ?? ",") + `${image_id},`)
      .split(",")
      .filter((o) => o !== undefined)
      .map((o) => Number(o));

    if (props.block.id)
      gqladm
        .saveBlockImageIds(props.block.id, `,${Array.from(new Set(imageids)).join(",")},`)
        .then((result) => {
          if (result.ok && result.count === 1 && props.block.id) {
            // load(props.block.id);
            props.onRefresh();
          }
        });
  };

  const handleClickUnbindImage = (image_id: number) => {
    const imageids = (block.image_ids ?? ",")
      .split(",")
      .filter((o) => o !== undefined || o !== "")
      .map((o) => Number(o))
      .filter((o) => o !== image_id && o !== 0);

    console.log(imageids);

    if (props.block.id)
      gqladm
        .saveBlockImageIds(props.block.id, `,${Array.from(new Set(imageids)).join(",")},`)
        .then((result) => {
          if (result.ok && result.count === 1 && props.block.id) {
            // load(props.block.id);
            props.onRefresh();
          }
        });
  };
  const handleClickSaveBody = (body: string) => {
    if (props.block.id)
      gqladm.saveBlockBody(props.block.id, body).then((result) => {
        if (result.ok && result.count === 1) props.onRefresh();
      });
  };

  return (
    <div style={{ border: "1px solid gray", padding: "1rem", margin: "1rem" }}>
      블럭-{block.id} <Button onClick={handleClickRemove}>삭제</Button>
      <div>타입: {block.type}</div>
      {block.type === "text" && (
        <div>
          <TextEditor body={block.body ?? ""} onSave={handleClickSaveBody} />
        </div>
      )}
      {block.type === "image" && (
        <>
          <div>
            선택된 이미지들
            {block.binded_images &&
              block.binded_images.map((img) => (
                <div key={img.id}>
                  <img src={"/" + img.url} style={{ maxHeight: "200px" }} />
                  <Button onClick={() => handleClickUnbindImage(img.id ?? -1)}>선택취소</Button>
                </div>
              ))}
          </div>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              업로드된 이미지들
              {props.block.id && (
                <MultiImageUploadButton
                  target="BoardBlock"
                  targetId={props.block.id}
                  onChangeFileUpload={handleUploadImageDone}>
                  <Button variant="contained">업로드</Button>
                </MultiImageUploadButton>
              )}
            </div>
            {block.uploaded_images &&
              block.uploaded_images.map((img) => (
                <div key={img.id} style={{ height: "3rem" }}>
                  <img src={"/" + img.url} height="100%" />{" "}
                  <Button onClick={() => handleClickBindImage(img.id ?? -1)}>선택</Button>
                  <Button onClick={() => props.onSelectThumbnaleId(img.id ?? -1)}>
                    썸네일로 설정
                  </Button>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default BlockDetail;
