import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { setSessionId, setSessionKey } from "../api/Ls";
import { gqladm } from "../graphql/gqladm";
import { AdminAuthState } from "../store/states/AdminAuthState";

const useAdminUser = () => {
  const setAdminAuthState = useSetRecoilState(AdminAuthState);

  const getAdminAuth = useCallback(() => {
    return gqladm.getAdminAuth().then((adm) => {
      setAdminAuthState({ loaded: true, admin: adm ? adm : {} });
      return adm;
    });
  }, [gqladm.getAdminAuth]);

  const checkUrlParams = useCallback((pathstr: string) => {
    const paramsList = pathstr
      .split("?")
      .filter((o) => o)
      .join("")
      .split("&");
    const id = paramsList.find((o) => o.startsWith("admSessionId="))?.substring(13);
    const key = paramsList.find((o) => o.startsWith("admSessionKey="))?.substring(14);
    if (id && key) {
      setSessionId(id);
      setSessionKey(key);
      window.location.href = "/";
    }
  }, []);

  return { getAdminAuth, checkUrlParams };
};

export default useAdminUser;
