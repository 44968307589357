import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { AdminNotiType } from "../../../types/adm_elements";

interface AdminNotiSettingBtnProps {
  admin_id: number;
}
const AdminNotiSettingBtn: React.FC<AdminNotiSettingBtnProps> = ({ admin_id }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [adminNotiTypes, setAdminNotiTypes] = useState<AdminNotiType[]>([]);
  const [onLoadingTypes, setOnLoadingTypes] = useState<AdminNotiType[]>([]);

  const load = () =>
    gqladm.getAdminUsers({ admin_id }).then((r) => {
      if (r?.list && r.list.length > 0 && r.list[0].adminNotiTypes) {
        setAdminNotiTypes(r.list[0].adminNotiTypes);
      }
    });

  const handleOpenNotiSetting = () => {
    load().then((r) => setOpen(true));
  };

  useEffect(() => {
    if (!open) setAdminNotiTypes([]);
  }, [open]);

  const handleChangeAdminNoti = (type: AdminNotiType, v: boolean) => {
    setOnLoadingTypes((olt) => [...olt, type]);
    gqladm.applyAdminNoti(admin_id, type, v).then(async (r) => {
      if (r.ok) await load();
      setOnLoadingTypes((olt) => olt.filter((o) => o !== type));
    });
    console.log("handleChangeAdminNoti", type);
  };

  return (
    <>
      <Button onClick={handleOpenNotiSetting}>알림수정</Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>관리자 알림 수정</DialogTitle>
        <DialogContent>
          <div>
            <Switch
              checked={adminNotiTypes.includes("NewReservation")}
              disabled={onLoadingTypes.includes("NewReservation")}
              onChange={(e, v) => handleChangeAdminNoti("NewReservation", v)}
            />
            NewReservation
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>닫기</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminNotiSettingBtn;
