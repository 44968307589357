import React, { useState } from "react";

// const useStyles = makeStyles(() => {
//   return {
//     root: {
//       "& > thead": {
//         backgroundColor: "#595757",
//         color: "white",
//         "& > tr": {
//           height: "3rem",
//         },
//       },
//       "& > tbody": {
//         "& > tr": {
//           "&.evenRow": {
//             backgroundColor: "#e0e0e0",
//           },
//           height: "2rem",
//           "& > td": {
//             textAlign: "center",
//           },
//         },
//       },
//     },
//   };
// });

const CustomTable: React.FC<{
  list: any[];
  headers: { col: string; header: any; sortable?: boolean }[];
  keyCol: string;
}> = (props) => {
  const key = props.keyCol;
  const headers = props.headers;
  // const classes = useStyles(props);
  const [sortCol, setSortCol] = useState<string>("");
  const [sortDir, setSortDir] = useState<boolean>(false);
  const list =
    sortCol !== ""
      ? props.list.sort((a, b) => {
          return (sortDir ? a[sortCol] > b[sortCol] : a[sortCol] < b[sortCol]) ? 1 : -1;
        })
      : props.list;

  return (
    <table
      className={
        "customTable "
        // + classes.root
      }>
      <thead>
        <tr>
          {headers.map((h, hidx) => {
            const handleClickHeader = () => {
              if (h.sortable) {
                setSortCol(h.col);
                setSortDir(!sortDir);
              } else {
                setSortCol("");
                setSortDir(false);
              }
            };
            return (
              <th key={`header_${h.col}_${hidx}`} onClick={handleClickHeader}>
                {h.header}
                {h.sortable &&
                  (sortCol === h.col ? <span>{sortDir ? "▲" : "▼"}</span> : <span>▲▼</span>)}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {list.map((obj, idx) => {
          return (
            <tr
              key={`${obj[key]}_${idx}`}
              className={
                (obj.className ? obj.className + " " : "") + (idx % 2 === 0 ? "evenRow" : "oddRow")
              }>
              {headers.map((h, hidx) => {
                return <td key={`${h.col}_${obj[key]}_${idx}_${hidx}`}>{obj[h.col]}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CustomTable;
