import React from "react";
import { BoardBlock } from "../../../../types/elements";

interface BoardDetailProps {
  hasBackButton?: boolean;
  title?: string;
  period?: string;
  blocks: BoardBlock[];
}
const BoardDetail: React.FC<BoardDetailProps> = ({ hasBackButton, title, period, blocks }) => {
  return (
    <div className="a-landing">
      <div className="a-landing-detail">
        <div className="a-landing-detail__title">
          {hasBackButton && (
            <a href="#" className="btn_back">
              뒤로가기
            </a>
          )}
          <strong>{title}</strong>
          {period && <span>{period}</span>}
        </div>
        <div className="a-landing-detail__contents">
          {blocks.map((block) => {
            if (block.type === "image") {
              if (block.binded_images && block.binded_images.length > 0) {
                return <img key={"" + block.id} src={"/" + block.binded_images[0].url} alt="" />;
              }
            } else if (block.type === "text") {
              if (block.body) {
                return <div key={"" + block.id} dangerouslySetInnerHTML={{ __html: block.body }} />;
              }
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default BoardDetail;
