
import Pagination from "@mui/material/Pagination";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { UserMemo, UserMemoSearchOption } from "../../../types/adm_elements";
import { CommonUtil as cu } from "../../../types/elements";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {  IconButton,  } from "@mui/material";
import UserMemoInputForm from "../UserMemoInputForm";

const LIMIT = 10;
interface UserMemoListProps {
  user_id: number;
}
const UserMemoList: React.FC<UserMemoListProps> = (props) => {
  const [list, setList] = useState<UserMemo[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [sopt, setSopt] = useState<UserMemoSearchOption>({
    limit: LIMIT,
    offset: 0,
    user_id: props.user_id,
  });
  useEffect(() => {
    if (props.user_id) load(sopt);
  }, [props.user_id]);

  const load = (opt: UserMemoSearchOption) => {
    gqladm
      .searchUserMemo({ ...opt })
      .then((result) => {
        if (result.list) setList(result.list);
        if (result.total) setTotal(result.total);
        setSopt({
          ...sopt,
          limit: result.limit !== undefined ? result.limit : sopt.limit,
          offset: result.offset !== undefined ? result.offset : sopt.offset,
        });
      })
      .catch((err) => {
        console.log("Error on load", err);
      });
  };

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  const handleDeleteUserMemo = (um_id: number) => {
    if (window.confirm(um_id + "번 메모를 정말 삭제하시겠습니까? ")) {
      gqladm.removeUserMemos([um_id]).then((result) => {
        console.log("test", result);
        load({ ...sopt });
      });
    }
  };

  return (
    <div style={{ backgroundColor: "#f7f7f7", padding: "10px" }}>
      <div>
        <UserMemoInputForm user_id={props.user_id} onSuccess={() => load({ ...sopt })} />
        <div>
          Count:&nbsp;
          {sopt.offset !== undefined ? sopt.offset + 1 : -1}~
          {sopt.offset !== undefined
            ? sopt.offset + (list.length < LIMIT ? list.length : LIMIT)
            : -1}
          &nbsp;/&nbsp;
          {total}
        </div>
      </div>
      <table className="simpletable">
        <tr>
          <th>ID</th>
          <th>작성자</th>
          <th>내용</th>
          <th>생성일자</th>
        </tr>
        {list.map((um, idx) => {
          return (
            <tr key={idx}>
              <td>{um.id} </td>
              <td>{um.admin?.name} </td>
              <td>{um.memo} </td>
              <td>{um.created && cu.getFormattedDate(um.created, "yyyy-MM-dd HH:mm:ss")} </td>
              <td>
                <IconButton onClick={() => um.id && handleDeleteUserMemo(um.id)}>
                  <DeleteForeverIcon />
                </IconButton>
              </td>
            </tr>
          );
        })}
      </table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
          page={Math.ceil((sopt.offset ? sopt.offset : 0) / (sopt.limit ? sopt.limit : LIMIT)) + 1}
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default UserMemoList;
