import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import { FcmPush, FcmPushSearchOption } from "../../../types/adm_elements";
import { getFormattedDate } from "../../../types/elements";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PushMessageListItem from "../../molecules/PushMessageListItem";

interface FcmPushListByItemDialogProps {
  open: boolean;
  item_id?: number;
  onClose: () => void;
}
const FcmPushListByItemDialog: React.FC<FcmPushListByItemDialogProps> = ({
  open,
  onClose,
  item_id,
}) => {
  const [sopt, setSopt] = useState<FcmPushSearchOption>({ offset: 0, limit: 10 });
  const [list, setList] = useState<FcmPush[]>([]);
  const [total, setTotal] = useState<number>(0);
  const load = (sopt: FcmPushSearchOption) => {
    gqladm.getFcmPushs(sopt).then((result) => {
      if (result.list) setList(result.list);
      if (result.total !== undefined) setTotal(result.total);
    });
  };
  useEffect(() => {
    if (item_id) {
      const _sopt: FcmPushSearchOption = { ...sopt, item_id };
      setSopt(_sopt);
      load(_sopt);
    }
  }, [item_id]);

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ?? 10);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  const handleSaveNew = () => {
    gqladm.putFcmPush({ item_id, title: "새 메세지", body: "새 메세지 바디" }).then((result) => {
      if (result.ok) load({ ...sopt, offset: 0 });
    });
  };
  const handleDel = (fcmPushId: number) => {
    gqladm.delFcmPush(fcmPushId).then((result) => {
      if (result.ok) load({ ...sopt, offset: 0 });
    });
  };

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>상품에 연관된 FCM Push 목록</DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>생성일자</TableCell>
              <TableCell>발송일자</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>메세지</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((fp) => {
              return (
                <TableRow key={"fcmpush_" + fp.id}>
                  <TableCell>
                    <Button size="small" onClick={() => window.open("/fcmpush/" + fp.id)}>
                      #{fp.id}
                      <OpenInNewIcon fontSize="small" />
                    </Button>
                  </TableCell>
                  <TableCell>
                    {fp.created && getFormattedDate(fp.created, "yyyy-MM-dd HH:mm")}
                  </TableCell>
                  <TableCell>
                    {fp.send_start && getFormattedDate(fp.send_start, "yyyy-MM-dd HH:mm")}
                  </TableCell>
                  <TableCell>#{fp.item_id}</TableCell>
                  <TableCell>
                    <PushMessageListItem
                      title={fp.title}
                      body={fp.body}
                      imageUrl={fp.imageUrl}
                      dataUrl={fp.dataUrl}
                    />
                  </TableCell>
                  <TableCell>
                    {fp.send_start === undefined && (
                      <Button variant="contained" onClick={() => fp.id && handleDel(fp.id)}>
                        삭제
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell colSpan={6}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>Total: {total}</div>
                  <Pagination
                    count={Math.ceil(total / (sopt.limit ?? 10))}
                    page={Math.ceil((sopt.offset ?? 0) / (sopt.limit ?? 10)) + 1}
                    onChange={handleChangePage}
                  />
                  <div></div>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="contained" onClick={handleSaveNew}>
          새 FCM Push 만들기
        </Button>
        <Button onClick={() => onClose()}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FcmPushListByItemDialog;
