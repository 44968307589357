import React, { useEffect, useState } from "react";
import { gqladm } from "../../../../../graphql/gqladm";
import {
  Order,
  CommonUtil as cu,
  getKrOrderStatus,
  numberWithCommas,
} from "../../../../../types/elements";

const SimpleTr: React.FC<{ order: Order; idx?: number }> = (props) => {
  return (
    <tr>
      <td>{(props.idx ?? 0) + 1}</td>
      <td style={{ textAlign: "center" }}>{props.order.type}</td>
      <td style={{ textAlign: "center" }}>{props.order.id}</td>
      <td style={{ fontFamily: "Courier", whiteSpace: "nowrap" }}>
        {props.order.created && cu.getFormattedDate(props.order.created, "yyyy-MM-dd a/p hh:mm")}
      </td>
      <td style={{ textAlign: "center" }}>{props.order.orderItems?.length}</td>
      <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
        {numberWithCommas(props.order.price)}
      </td>
      <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
        {props.order.paytype === "credit"
          ? "카드결제"
          : props.order.paytype === "direct"
          ? "무통장입금"
          : ""}
      </td>
      <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
        {getKrOrderStatus(props.order.status)}
      </td>
      <td>
        {props.order.orderItems?.map((oi, idx) => {
          return (
            <div key={idx}>
              #{oi.id} {oi.item?.name}
            </div>
          );
        })}
      </td>
    </tr>
  );
};

interface UserInfoOrdersProps {
  user_id: number;
}
const UserInfoOrders: React.FC<UserInfoOrdersProps> = (props) => {
  const [list, setList] = useState<Order[]>([]);
  useEffect(() => {
    if (props.user_id)
      gqladm
        .getOrders({ user_id: props.user_id, limit: 10, ordering: "updated_desc" })
        .then((result) => {
          if (result.list) setList(result.list);
        });
  }, [props.user_id]);
  return (
    <div className="simple1panel" style={{ width: "100%" }}>
      <div className="simple1panel_head">Orders</div>
      <div className="simple1panel_body">
        <table style={{ width: "100%", borderCollapse: "separate", borderSpacing: "15px 5px" }}>
          <thead>
            <tr>
              <th>No.</th>
              <th>Type</th>
              <th>ID</th>
              <th>Created</th>
              <th style={{ whiteSpace: "nowrap" }}>주문수량</th>
              <th style={{ textAlign: "right", borderSpacing: "10px", whiteSpace: "nowrap" }}>
                주문금액
              </th>
              <th>결제수단</th>
              <th>Status</th>
              <th>주문항목</th>
            </tr>
          </thead>
          <tbody>
            {list.map((o, idx) => (
              <SimpleTr order={o} key={idx} idx={idx} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserInfoOrders;
