import React, { useState } from "react";
import { gqladm } from "../../../../../graphql/gqladm";
import { Button, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router";

interface NewcomponentProps {
  user_id: number;
}
const UserDetailRequestNewButton: React.FC<NewcomponentProps> = (props) => {
  const navi = useNavigate();
  const [newMenuEl, setNewMenuEl] = useState<HTMLElement | null>(null);
  const openNewMenu = Boolean(newMenuEl);

  const handleNewConsignment = () => {
    gqladm.addNewConsignment(props.user_id).then((result) => {
      if (result.ok && result.id) navi("/servicerequest/consignment/" + result.id);
    });
  };
  const handleNewRental = () => {
    gqladm.addNewRental(props.user_id).then((result) => {
      if (result.ok && result.id) navi("/servicerequest/rental/" + result.id);
    });
  };
  const handleNewStyling = () => {
    gqladm.addNewStyling(props.user_id).then((result) => {
      if (result.ok && result.id) navi("/servicerequest/styling/" + result.id);
    });
  };
  return (
    <>
      <Button variant="contained" onClick={(e) => setNewMenuEl(e.currentTarget)}>
        New
      </Button>
      <Menu open={openNewMenu} anchorEl={newMenuEl} onClose={() => setNewMenuEl(null)}>
        <MenuItem onClick={handleNewConsignment}>위탁</MenuItem>
        <MenuItem onClick={handleNewRental}>렌탈</MenuItem>
        <MenuItem onClick={handleNewStyling}>스타일링</MenuItem>
      </Menu>
    </>
  );
};

export default UserDetailRequestNewButton;
