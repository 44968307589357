import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";
import UserDetail from "./UserDetail";
import { UserDetailViewIdType } from "./UserDetailView";

interface UserDetailDialogProps {
  user_id?: number;
  onClose: () => void;
}
const UserDetailDialog: React.FC<UserDetailDialogProps> = (props) => {
  const [viewId, setViewId] = useState<UserDetailViewIdType | "">("");
  const handleChangeViewId = (viewId: UserDetailViewIdType) => {
    setViewId(viewId);
  };
  return (
    <>
      {props.user_id && (
        <Dialog
          open={props.user_id !== undefined}
          fullWidth={true}
          sx={{ height: "80%" }}
          maxWidth={false}>
          <DialogContent>
            <UserDetail
              user_id={props.user_id}
              onChangeViewId={handleChangeViewId}
              view_id={viewId}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.onClose()}>닫기</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UserDetailDialog;
