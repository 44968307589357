import Axios from "axios";
import { OutAPIResponse } from "../types/elements";

const devKey = "U01TX0FVVEgyMDIyMDUyMjE1MDUyNTExMjU5Nzc=";

const url = "http://www.juso.go.kr/addrlink/addrLinkApiJsonp.do";
const svcpath = "/api/juso";

export interface PublicJuso {
  detBdNmList: string;
  engAddr: string;
  rn: string;
  emdNm: string;
  zipNo: string;
  roadAddrPart2: string;
  emdNo: string;
  sggNm: string;
  jibunAddr: string;
  siNm: string;
  roadAddrPart1: string;
  bdNm: string;
  admCd: string;
  udrtYn: string;
  lnbrMnnm: string;
  roadAddr: string;
  lnbrSlno: string;
  buldMnnm: string;
  bdKdcd: string;
  liNm: string;
  rnMgtSn: string;
  mtYn: string;
  bdMgtSn: string;
  buldSlno: string;
}
export interface PublicAddressResult {
  results: {
    common: {
      errorMessage: string;
      countPerPage: string;
      totalCount: string;
      errorCode: string;
      currentPage: string;
    };
    juso: Array<PublicJuso>;
  };
}

export interface SearchedAddress {
  zipNo: string;
  roadAddr: string;
  jibunAddr: string;
}

export const PublicAddressAPI = {
  searchAddress: (
    searchText: string,
    page?: number,
    count?: number
  ): Promise<OutAPIResponse<Array<SearchedAddress>>> => {
    const _page = page === undefined ? 1 : page;
    const _count = count === undefined ? 5 : count;
    const q = [];
    q.push("confmKey=" + devKey);
    q.push("currentPage=" + _page);
    q.push("countPerPage=" + _count);
    q.push("resultType=json");
    q.push("keyword=" + encodeURIComponent(searchText));

    return Axios.get(svcpath + "?" + q.join("&")).then((result: OutAPIResponse<any>) => {
      if (typeof result.data === "string") {
        try {
          const parsed: PublicAddressResult = JSON.parse(
            result.data.substr(1, result.data.length - 2)
          );
          result.originalData = result.data;
          result.data = parsed.results.juso.map((j) => {
            const ret: SearchedAddress = {
              zipNo: j.zipNo,
              roadAddr: j.roadAddr,
              jibunAddr: j.jibunAddr,
            };
            return ret;
          });
        } catch (err) {
          console.log(err, result.data);
        }
      }
      return result;
    });
  },
};
