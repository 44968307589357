import { Button } from "@mui/material";
import React from "react";
import { gqladm } from "../../../../graphql/gqladm";
import useSnack from "../../../../hooks/useSnack";

interface SyncCategoryButtonProps {
  selected: number[];
  onRefresh: () => any;
}
const SyncCategoryButton: React.FC<SyncCategoryButtonProps> = (props) => {
  const { openSnack } = useSnack();
  const handleClick = () => {
    console.log("handleSyncItemMetaCategoryIds");
    if (props.selected.length > 0) {
      gqladm
        .syncItemMetaCategoryIds(props.selected)
        .then((result) => {
          openSnack({ msg: "카테고리 정보가 업데이트 되었습니다. " + result.msg });
          props.onRefresh();
        })
        .catch((err) => {
          openSnack({ msg: "에러가 발생했습니다." });
        });
    } else {
      openSnack({ msg: "선택된 상품이 없습니다. " });
    }
  };
  return <Button onClick={handleClick}>Sync_Category</Button>;
};

export default SyncCategoryButton;
