import React, { useEffect, useState } from "react";
import { User, numberWithCommas } from "../../../../../types/elements";
import { gqladm } from "../../../../../graphql/gqladm";

const titleStyle: React.CSSProperties = { fontSize: "3rem", fontWeight: "bold" };
const subStyle: React.CSSProperties = { fontSize: "1rem", fontWeight: "bold" };
const valStyle: React.CSSProperties = {
  fontSize: "1.8rem",
  fontWeight: "bold",
  color: "orange",
  marginBottom: "1rem",
};

interface UserInfoTotalInfoProps {
  user_id: number;
}
const UserInfoTotalInfo: React.FC<UserInfoTotalInfoProps> = (props) => {
  const [user, setUser] = useState<User | undefined>();

  useEffect(() => {
    if (props.user_id) {
      gqladm.syncUserNumFields([props.user_id]).then((resultOfSync) => {
        if (resultOfSync.ok)
          gqladm.getUsers({ id: props.user_id }).then((resultOfNewQuery) => {
            if (resultOfNewQuery.list && resultOfNewQuery.list.length > 0)
              setUser(resultOfNewQuery.list[0]);
          });
      });
    }
  }, [props.user_id]);
  return (
    <div style={{ minWidth: "320px", flex: 1 }}>
      <div style={titleStyle}>Total</div>

      <div style={subStyle}>Lifetime Sales</div>
      <div style={valStyle}>
        {user?.num_order_done_price && numberWithCommas(user.num_order_done_price) + "원"}
      </div>
      <div style={subStyle}>Reservations</div>
      <div style={valStyle}>{user?.num_reservation_done}</div>
      <div style={subStyle}>Service Request</div>
      <div style={valStyle}>{user?.num_service_request_done}</div>
    </div>
  );
};

export default UserInfoTotalInfo;
