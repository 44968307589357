import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppCommon } from "../../hooks/useAppCommon";
import BannerDialog from "../organisms/BannerDialog";
import BannerList from "../organisms/BannerList";

const BaseConfigView: React.FC = (props) => {
  const {} = useAppCommon({ headerTitle: "BaseConfig" });

  return (
    <div>
      <div></div>
      <br />
      <br />

      <BannerList />
    </div>
  );
};

export default BaseConfigView;
