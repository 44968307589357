import React, { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  DialogTitle,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import { Warehouse } from "../../../types/elements";
import { AdminWarehouseAPI } from "../../../api/AdminWarehouse";
import { gqladm } from "../../../graphql/gqladm";
import { useAppCommon } from "../../../hooks/useAppCommon";
import StockList from "./StockList";

interface WarehouseAdd {
  open: boolean;
  name: string;
  id?: number;
}

const WarehouseListView: React.FC = (props) => {
  const {} = useAppCommon({ headerTitle: "창고 관리" });

  const [whlist, setWhlist] = useState<Warehouse[]>([]);

  const load = () => {
    return gqladm
      .getWarehouses({})
      .then((result) => {
        setWhlist(result.list ? result.list : []);
      })
      .catch((err) => {
        console.log("Error");
      });
  };
  useEffect(() => {
    load();
  }, []);

  const handleClickDeleteWherehouse = (wh: Warehouse) => {
    if (wh && wh.id && window.confirm("진짜 삭제하시겠습니까?")) {
      AdminWarehouseAPI.deleteWarehouse(wh.id).then((result) => {
        const data = result.data;
        if (data.updatedCount === 1) {
          load();
        }
      });
    }
  };

  const [addDialog, setAddDialog] = useState<WarehouseAdd>({ open: false, name: "" });

  const handleClickOpenModifyWarehouse = (wh: Warehouse) => {
    setAddDialog({ id: wh.id, open: true, name: wh.name ? wh.name : "" });
  };

  const handleClickOpenAddWarehouse = () => {
    setAddDialog({ open: true, name: "", id: undefined });
  };
  const handleChangeName = (evt: any) => {
    console.log("handleChangeName", evt, evt.target.value);
    setAddDialog({ ...addDialog, name: evt.target.value });
  };
  const handleClickAddWarehouse = () => {
    if (addDialog.id === undefined) {
      AdminWarehouseAPI.postWarehouse({ name: addDialog.name }).then((result) => {
        const data = result.data;
        if (data.insertedId !== undefined) {
          load().then((result) => {
            setAddDialog({ ...addDialog, open: false });
          });
        }
      });
    } else {
      AdminWarehouseAPI.putWarehouse({ id: addDialog.id, name: addDialog.name }).then((result) => {
        const data = result.data;
        if (data.updatedCount === 1) {
          load().then((result) => {
            setAddDialog({ ...addDialog, open: false });
          });
        }
      });
    }
  };
  const handleCloseAddWarehouse = () => {
    setAddDialog({ ...addDialog, open: false });
  };
  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpenAddWarehouse}>
        창고 추가
      </Button>
      <Dialog open={addDialog.open} title="재고 추가" onClose={handleCloseAddWarehouse}>
        <DialogTitle>창고 {addDialog.id === undefined ? "추가" : "수정"}</DialogTitle>
        <DialogContent>
          <TextField label="창고 명" onChange={handleChangeName} value={addDialog.name} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickAddWarehouse}>
            {addDialog.id === undefined ? "추가" : "수정"}
          </Button>
          <Button onClick={handleCloseAddWarehouse}>취소</Button>
        </DialogActions>
      </Dialog>

      <div>
        입고 정보
        <Table>
          <TableHead>
            <TableCell>ID</TableCell>
            <TableCell>창고이름</TableCell>
            <TableCell>입력날짜</TableCell>
            <TableCell></TableCell>
          </TableHead>
          <TableBody>
            {whlist.map((wh) => {
              return (
                <TableRow key={wh.id}>
                  <TableCell>{wh.id}</TableCell>
                  <TableCell>{wh.name}</TableCell>
                  <TableCell>{wh.created ? new Date(wh.created).toLocaleString() : ""}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleClickOpenModifyWarehouse(wh)}>
                      수정
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleClickDeleteWherehouse(wh)}>
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <StockList warehouses={whlist} />
      </div>
    </div>
  );
};

export default WarehouseListView;
