import React, { createRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  ButtonGroup,
} from "@mui/material";
import {
  Item,
  ItemSearchOption,
  ItemStatusArr,
  ItemType,
  ItemTypeArr,
  ItemVisibleArr,
  CommonUtil as cu,
} from "../../types/elements";

import { gqladm } from "../../graphql/gqladm";
import Pagination from "@mui/material/Pagination";
import useItemMeta from "../../hooks/useItemMeta";
import ItemMuiCard from "./ItemMuiCard";

const LIMIT = 10;
interface SelectItemDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect: (item: Item) => void;
  fixedType?: ItemType;
  headerNode?: React.ReactNode;
}
const SelectItemDialog: React.FC<SelectItemDialogProps> = (props) => {
  const dcRef = createRef<HTMLDivElement>();
  const { getItemMeta } = useItemMeta();
  const [sopt, setSopt] = useState<ItemSearchOption>({
    ordering: "id_desc",
    limit: LIMIT,
    offset: 0,
    visibles: ["OPEN"],
    statuses: ["SALE"],
  });
  const [total, setTotal] = useState<number>(0);
  const [list, setList] = useState<Item[]>([]);
  const load = (opt: ItemSearchOption) => {
    gqladm.getItems({ ...sopt, ...opt }).then((result) => {
      if (result.list) setList(result.list);
      if (result.total) setTotal(result.total);
      setSopt((_sopt) => ({ ..._sopt, offset: result.offset }));
    });
  };
  useEffect(() => {
    if (props.open) load(sopt);
  }, [props.open]);

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
    if (dcRef.current) dcRef.current.scrollTop = 0;
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
      fullWidth
      PaperProps={{ className: "lessMarginDialogPaper" }}>
      <DialogTitle>상품 선택</DialogTitle>
      <DialogContent ref={dcRef}>
        {props.headerNode}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <details open={true}>
            <summary>Search Condition</summary>
            <div
              style={{ border: "1px solid gray", backgroundColor: "lightgray", padding: "10px" }}>
              {props.fixedType ? (
                <div>Type: {props.fixedType}</div>
              ) : (
                <div style={{ marginBottom: "0.5rem" }}>
                  Type :&nbsp;
                  <ButtonGroup size="small">
                    <Button
                      variant="contained"
                      color={sopt.type === undefined ? "primary" : "inherit"}
                      onClick={() => setSopt((s) => ({ ...s, type: undefined }))}>
                      None
                    </Button>
                    {ItemTypeArr.map((o, idx) => (
                      <Button
                        key={idx}
                        variant="contained"
                        color={sopt.type === o ? "primary" : "inherit"}
                        onClick={() => setSopt((s) => ({ ...s, type: o }))}>
                        {o}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
              )}

              <div style={{ marginBottom: "0.5rem" }}>
                Visible :&nbsp;
                <ButtonGroup size="small">
                  {ItemVisibleArr.map((o, idx) => (
                    <Button
                      key={idx}
                      variant="contained"
                      color={sopt.visibles && sopt.visibles.indexOf(o) > -1 ? "primary" : "inherit"}
                      onClick={() =>
                        sopt.visibles && sopt.visibles.indexOf(o) > -1
                          ? setSopt({ ...sopt, visibles: sopt.visibles.filter((st) => st !== o) })
                          : setSopt({
                              ...sopt,
                              visibles: [...(sopt.visibles ? sopt.visibles : []), o],
                            })
                      }>
                      {o}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              <div style={{ marginBottom: "0.5rem" }}>
                Status :&nbsp;
                <ButtonGroup size="small">
                  {ItemStatusArr.map((o, idx) => (
                    <Button
                      key={idx}
                      variant="contained"
                      color={sopt.statuses && sopt.statuses.indexOf(o) > -1 ? "primary" : "inherit"}
                      onClick={() =>
                        sopt.statuses && sopt.statuses.indexOf(o) > -1
                          ? setSopt({ ...sopt, statuses: sopt.statuses.filter((st) => st !== o) })
                          : setSopt({
                              ...sopt,
                              statuses: [...(sopt.statuses ? sopt.statuses : []), o],
                            })
                      }>
                      {o}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              <div style={{ marginBottom: "0.5rem" }}>
                정렬순서
                <ButtonGroup size="small">
                  <Button
                    variant="contained"
                    color={sopt.ordering === "id_desc" ? "primary" : "inherit"}
                    onClick={() => setSopt((_sopt) => ({ ..._sopt, ordering: "id_desc" }))}>
                    최근생성
                  </Button>
                  <Button
                    variant="contained"
                    color={sopt.ordering === "price_desc" ? "primary" : "inherit"}
                    onClick={() => setSopt((_sopt) => ({ ..._sopt, ordering: "price_desc" }))}>
                    높은가격
                  </Button>
                  <Button
                    variant="contained"
                    color={sopt.ordering === "price_asc" ? "primary" : "inherit"}
                    onClick={() => setSopt((_sopt) => ({ ..._sopt, ordering: "price_asc" }))}>
                    낮은가격
                  </Button>
                </ButtonGroup>
              </div>

              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ whiteSpace: "nowrap" }}>Text :&nbsp;</div>
                <TextField
                  size="small"
                  value={sopt.text}
                  onChange={(e) => setSopt((s) => ({ ...s, text: e.target.value }))}
                />
                <Button onClick={() => load({})}>검색</Button>
              </div>
            </div>
          </details>
          <div>
            검색결과
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}>
              <Pagination
                count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
                page={
                  Math.ceil((sopt.offset ? sopt.offset : 0) / (sopt.limit ? sopt.limit : LIMIT)) + 1
                }
                onChange={handleChangePage}
              />
            </div>
            <div>
              {list.map((item, idx) => (
                <ItemMuiCard
                  key={idx}
                  item={item}
                  renderActions={(_item) => (
                    <>
                      <Button
                        variant="contained"
                        onClick={() => (_item?.stock_avail ?? 0) > 0 && props.onSelect(item)}>
                        Select
                      </Button>
                      {(_item.stock_avail ?? 0) < 1 ? <span>재고없음</span> : ""}
                    </>
                  )}
                />
              ))}
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
              <Pagination
                count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
                page={
                  Math.ceil((sopt.offset ? sopt.offset : 0) / (sopt.limit ? sopt.limit : LIMIT)) + 1
                }
                onChange={handleChangePage}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectItemDialog;
