import React, { useEffect, useState } from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useAppCommon } from "../../../../../hooks/useAppCommon";
import { getDiffTime, getFormattedDate, UserDevice } from "../../../../../types/elements";
import SendAllMessageDialog from "../../../../organisms/sendMessageDialog/SendAllMessageDialog";
import SendMessageDialog from "../../../../organisms/sendMessageDialog/SendMessageDialog";
import { useSearchUserDevice } from "./useSearchUserDevice";
import { gqladm } from "../../../../../graphql/gqladm";

interface MobileDeviceListProps {}
const MobileDeviceList: React.FC<MobileDeviceListProps> = () => {
  useAppCommon({ headerTitle: "Mobile Device List" });
  const { total, list, sopt, load, handleChangePage, LIMIT } = useSearchUserDevice({
    limit: 20,
    withUser: true,
  });

  useEffect(() => {
    load(sopt);
  }, []);

  // send message dialog
  const [targetDeviceIds, setTargetDeviceIds] = useState<number[]>([]);
  const [openSendDialog, setOpenSendDialog] = useState<boolean>(false);
  const handleClickSendMessage = (ud: UserDevice) => {
    if (ud.id) setTargetDeviceIds([ud.id]);
    setOpenSendDialog(true);
  };

  // remove UserDevice
  const handleRemoveUserDevice = (ud: UserDevice) => {
    if (ud.id && window.confirm(`정말로 삭제하시겠습니까? 기기-${ud.os}/${ud.version}`)) {
      gqladm.removeUserDevice(ud.id).then((result) => {
        if (result.ok && result.count === 1) load(sopt);
      });
    }
  };

  // All
  const [openSendAllDialog, setOpenSendAllDialog] = useState<boolean>(false);

  return (
    <div>
      <div>
        <Button onClick={() => setOpenSendAllDialog(true)}>전체 메세지 보내기</Button>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Pagination
          count={Math.ceil(total / (sopt.limit ?? LIMIT))}
          page={Math.ceil((sopt.offset ?? 0) / (sopt.limit ?? LIMIT)) + 1}
          onChange={handleChangePage}
        />
      </div>
      <div>
        <Table style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <th>ID</th>
              <th>OS</th>
              <th>Ver</th>
              <th>App</th>
              <th>fcm</th>
              <th>Created</th>
              <th>Updated</th>
              <th>User</th>
              <th>SKey</th>
              <th>Action</th>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((ud) => {
              return (
                <TableRow key={ud.id + ""}>
                  <TableCell align="center">{ud.id}</TableCell>
                  <TableCell align="center">{ud.os}</TableCell>
                  <TableCell align="center">{ud.version}</TableCell>
                  <TableCell align="center">
                    {ud.app_version}-{ud.app_buildno}
                  </TableCell>
                  <TableCell align="center">
                    <input value={ud.fcmToken} />
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip
                      placement="top"
                      title={ud.created ? getFormattedDate(ud.created, "yyyy-MM-dd HH:mm") : ""}>
                      <Button size="small" color="inherit">
                        {ud.created && getDiffTime(ud.created, Date.now())}
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip
                      placement="top"
                      title={ud.updated ? getFormattedDate(ud.updated, "yyyy-MM-dd HH:mm") : ""}>
                      <Button size="small" color="inherit">
                        {ud.updated && getDiffTime(ud.updated, Date.now())}
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    {ud.user_id && (
                      <a href={"/user/" + ud.user_id} target="_blank" className="uline">
                        #{ud.user_id} {ud.user?.name ?? "-"}
                      </a>
                    )}
                  </TableCell>
                  <TableCell align="center">{ud.sessionKey}</TableCell>

                  <TableCell>
                    <Button variant="contained" onClick={() => handleClickSendMessage(ud)}>
                      Send Message
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleRemoveUserDevice(ud)}>
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Pagination
          count={Math.ceil(total / (sopt.limit ?? LIMIT))}
          page={Math.ceil((sopt.offset ?? 0) / (sopt.limit ?? LIMIT)) + 1}
          onChange={handleChangePage}
        />
      </div>
      <SendMessageDialog
        open={openSendDialog}
        onClose={() => setOpenSendDialog(false)}
        target_device_ids={targetDeviceIds}
      />
      <SendAllMessageDialog open={openSendAllDialog} onClose={() => setOpenSendAllDialog(false)} />
    </div>
  );
};

export default MobileDeviceList;
