import {
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import useEnvironment from "../../../hooks/useEnvironment";
import { getThumbUrl, Item, User, UserDevice } from "../../../types/elements";
import SelectItemDialog from "../SelectItemDialog";
import SelectUserDialog from "../SelectUserDialog";
import AreaPush from "./AreaPush";
import AreaSms from "./AreaSms";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import MiniItemInfo from "./MiniItemInfo";
interface SendMessageDialogProps {
  open: boolean;
  onClose: () => void;
  target_user_ids?: number[];
  target_device_ids?: number[];
}

interface MergedUD {
  user_id?: number;
  name?: string;
  phone?: string;

  device_id?: number;
  os?: string;
  ver?: string;
  fcm?: string;
  target?: "sms" | "push" | "";
  isSend?: boolean;
}
const SendMessageDialog: React.FC<SendMessageDialogProps> = (props) => {
  const [users, setUsers] = useState<User[]>([]);
  const [devices, setDevices] = useState<UserDevice[]>([]);
  const [merged, setMerged] = useState<MergedUD[]>([]);

  const [dataUrl, setDataUrl] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    if (props.open) {
      const userProms = [];
      if (props.target_user_ids && props.target_user_ids.length > 0) {
        userProms.push(gqladm.getUsers({ limit: 99999, offset: 0, ids: props.target_user_ids }));
      }

      Promise.all(userProms).then((result) => {
        const _users = result.length > 0 ? result[0].list ?? [] : [];
        if (result.length > 0 && result[0].list) setUsers(result[0].list);

        const deviceProms = [];
        const userids = (_users.map((o) => o.id).filter((o) => o) ?? []) as number[];
        if (userids.length > 0)
          deviceProms.push(gqladm.getUserDevices({ limit: 99999, offset: 0, user_ids: userids }));
        if (props.target_device_ids && props.target_device_ids.length > 0)
          deviceProms.push(
            gqladm.getUserDevices({ limit: 99999, offset: 0, ids: props.target_device_ids })
          );
        Promise.all(deviceProms).then((results) => {
          const _devices: UserDevice[] = [];
          results.forEach((result) => {
            if (result.list)
              result.list.forEach((ud) => {
                if (!_devices.find((o) => o.id === ud.id)) _devices.push(ud);
              });
          });
          setDevices(_devices);

          console.log(_users, _devices);
          // merged logic
          mergeUserAndDevice(_users, _devices);
        });
      });
    }
  }, [props.open]);

  const mergeUserAndDevice = (users: User[], devices: UserDevice[]) => {
    const merged: MergedUD[] = [];
    devices.forEach((ud) => {
      merged.push({
        user_id: ud.user_id,
        name: ud.user?.name,
        phone: ud.user?.phone,
        device_id: ud.id,
        ver: ud.version,
        os: ud.os,
        fcm: ud.fcmToken,
        isSend: true,
        target: ud.fcmToken ? "push" : ud.user?.phone ? "sms" : "",
      });
    });
    users.forEach((user) => {
      if (!merged.find((o) => o.user_id === user.id && user.id !== undefined)) {
        merged.push({
          user_id: user.id,
          name: user.name,
          phone: user.phone,
          target: "sms",
          isSend: true,
        });
      }
    });
    setMerged(merged.sort((a, b) => ((a.user_id ?? -1) > (b.user_id ?? -1) ? 1 : -1)));
  };

  //   유저 추가
  const [openUserDialog, setOpenUserDialog] = useState<boolean>(false);
  const handleOpenUserDialog = () => {
    setOpenUserDialog(true);
  };
  const handleSelectUser = (user: User) => {
    gqladm.getUserDevicesWithUser({ user_id: user.id }).then((result) => {
      if (result.list && result.list.length > 0) {
        const dvs = [
          ...devices,
          ...(result.list ?? []).filter((o) => !devices.find((dv) => dv.id === o.id)),
        ] as UserDevice[];
        const urs = users.find((o) => o.id === user.id) ? users : [...users, user];
        setDevices(dvs);
        setUsers(urs);
        mergeUserAndDevice(urs, dvs);
      }
    });
  };

  //   상품추가
  const [selectedItem, setSelectedItem] = useState<Item | undefined>(undefined);
  const [openItemDialog, setOpenItemDialog] = useState<boolean>(false);
  const handleOpenItemDialog = () => {
    setOpenItemDialog(true);
  };

  const sendUsers = useMemo(
    () =>
      merged
        .filter((o) => o.target === "sms" && o.isSend === true)
        .map((o) => users.find((ur) => ur.id === o.user_id))
        .filter((o) => o) as User[],
    [merged, users]
  );
  const sendDevices = useMemo(
    () =>
      merged
        .filter((o) => o.target === "push" && o.isSend === true)
        .map((o) => devices.find((dv) => dv.id === o.device_id))
        .filter((o) => o) as UserDevice[],
    [merged, devices]
  );

  return (
    <>
      <Dialog open={props.open} onClose={() => props.onClose()} maxWidth="lg">
        <DialogTitle>메세지 전송</DialogTitle>
        <DialogContent>
          <div>
            <div>
              <Button onClick={handleOpenUserDialog}>유저추가</Button>
              <Button onClick={handleOpenItemDialog}>상품추가</Button>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>UserId</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>DeviceId</TableCell>
                    <TableCell>OS</TableCell>
                    <TableCell>Ver</TableCell>
                    <TableCell>Target</TableCell>
                    <TableCell>Send</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {merged.map((mg) => {
                    return (
                      <TableRow id={"userid-" + mg.user_id + "," + "deviceid-" + mg.device_id}>
                        <TableCell>{mg.user_id}</TableCell>
                        <TableCell>{mg.name}</TableCell>
                        <TableCell>{mg.phone}</TableCell>
                        <TableCell>{mg.device_id}</TableCell>
                        <TableCell>{mg.os}</TableCell>
                        <TableCell>{mg.ver}</TableCell>
                        <TableCell>
                          <ButtonGroup>
                            <Button
                              disabled={!mg.phone}
                              variant={mg.target === "sms" ? "contained" : undefined}
                              onClick={() => {
                                setMerged((mgs) =>
                                  mgs.map((o) =>
                                    o.device_id === mg.device_id && o.user_id === mg.user_id
                                      ? { ...o, target: "sms" }
                                      : o
                                  )
                                );
                              }}>
                              SMS
                            </Button>
                            <Button
                              disabled={!mg.fcm}
                              variant={mg.target === "push" ? "contained" : undefined}
                              onClick={() => {
                                setMerged((mgs) =>
                                  mgs.map((o) =>
                                    o.device_id === mg.device_id && o.user_id === mg.user_id
                                      ? { ...o, target: "push" }
                                      : o
                                  )
                                );
                              }}>
                              Push
                            </Button>
                          </ButtonGroup>
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            size="small"
                            checked={mg.isSend}
                            onChange={(e, v) => {
                              setMerged((mgs) =>
                                mgs.map((o) =>
                                  o.device_id === mg.device_id && o.user_id === mg.user_id
                                    ? { ...o, isSend: v }
                                    : o
                                )
                              );
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
          {selectedItem && (
            <div style={{ padding: "1rem 0 " }}>
              선택된 상품
              <MiniItemInfo
                item={selectedItem}
                onClose={() => setSelectedItem(undefined)}
                onSelectItemUrl={setDataUrl}
                onSelectImageUrl={setImageUrl}
              />
            </div>
          )}
          <div>
            {sendUsers.length > 0 && <AreaSms users={sendUsers} />}
            {sendDevices.length > 0 && (
              <AreaPush devices={sendDevices} dataUrl={dataUrl} imageUrl={imageUrl} />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onClose()}>닫기</Button>
        </DialogActions>
      </Dialog>
      <SelectUserDialog
        open={openUserDialog}
        onClose={() => setOpenUserDialog(false)}
        onSelect={handleSelectUser}
      />
      <SelectItemDialog
        open={openItemDialog}
        onClose={() => setOpenItemDialog(false)}
        onSelect={(item) => {
          setSelectedItem(item);
          setOpenItemDialog(false);
        }}
      />
    </>
  );
};

export default SendMessageDialog;
