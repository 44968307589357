import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CustomImage } from "../../../types/elements";

interface MobileMainSlideProps {
  width: number;
  customImages: CustomImage[];
  defaultImageUrl?: string;
  onClickImage?: (imageLink?: string) => any;
  autoplay?: boolean;
}
const MobileMainSlide: React.FC<MobileMainSlideProps> = (props) => {
  const handleClickImage = (ci: CustomImage) => {
    if (props.onClickImage) props.onClickImage(ci.image_link);
  };

  return (
    <div style={{ width: props.width + "px" }}>
      <Slider
        className="slider main__slide"
        arrows={false}
        dots={true}
        autoplay={props.autoplay ?? true}
        autoplaySpeed={4000}>
        {(props.customImages.length === 0
          ? ([{ image_path: props.defaultImageUrl }] as CustomImage[])
          : props.customImages
        ).map((ci) => {
          return (
            <div key={ci.id}>
              <div className="innerCI" onClick={() => handleClickImage(ci)}>
                {ci.image_path !== undefined && ci.image_path !== "" && (
                  <img src={"/" + ci.image_path} />
                )}
                <div className="main__slide--info">
                  <strong className="main__slide--title">{ci.title_text}</strong>
                  <p className="main__slide--dsc">{ci.desc_text}</p>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default MobileMainSlide;
