import { Pagination, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import useSnack from "../../../hooks/useSnack";
import { AdminHistory, AdminHistorySearchOption } from "../../../types/adm_elements";
import { SearchResult, CommonUtil as cu } from "../../../types/elements";

const LIMIT = 20;
interface AdminHistoryListProps {}
const AdminHistoryList: React.FC<AdminHistoryListProps> = (props) => {
  const { openSnack } = useSnack();

  const [total, setTotal] = useState<number>(0);
  const [list, setList] = useState<Array<AdminHistory>>([]);
  const [sopt, setSopt] = useState<AdminHistorySearchOption>({
    offset: 0,
    limit: LIMIT,
    orderby: "created_desc",
  });

  const load = (sopt: AdminHistorySearchOption) => {
    return gqladm
      .adminHistories(sopt)
      .then((result: SearchResult<AdminHistory>) => {
        if (result.total) setTotal(result.total);
        if (result.list) setList(result.list);

        setSopt({
          ...sopt,
          offset: result.offset !== undefined ? result.offset : sopt.offset,
          limit: result.limit !== undefined ? result.limit : sopt.limit,
        });
      })
      .catch((err: any) => {
        console.log("Error ", err);
        openSnack({ msg: "Error " + err, severity: "error" });
      });
  };

  useEffect(() => {
    load(sopt);
  }, []);

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ?? LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <Pagination
            count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
            page={
              Math.ceil((sopt.offset ? sopt.offset : 0) / (sopt.limit ? sopt.limit : LIMIT)) + 1
            }
            onChange={handleChangePage}
          />
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Target ID</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>AdminID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((ah) => {
            return (
              <TableRow>
                <TableCell>{ah.id}</TableCell>
                <TableCell>{ah.type}</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  {ah.user?.name ? `#${ah.user_id}-${ah.user?.name}` : ah.user_id}
                </TableCell>
                <TableCell>{ah.target_id}</TableCell>
                <TableCell>{ah.msg}</TableCell>
                <TableCell>
                  {ah.created && cu.getFormattedDate(ah.created, "yyyyMMdd HH:mm")}
                </TableCell>
                <TableCell>{ah.admin_id}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <Pagination
            count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
            page={
              Math.ceil((sopt.offset ? sopt.offset : 0) / (sopt.limit ? sopt.limit : LIMIT)) + 1
            }
            onChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminHistoryList;
