import { Alert, AlertTitle, Divider } from "@mui/material";
import React from "react";
import ChangeVisibleButton from "./selectedactions/ChangeVisibleButton";
import SyncCategoryButton from "./selectedactions/SyncCategoryButton";
import SyncItemStockAvailButton from "./selectedactions/SyncItemStockAvail";

interface SelectedActionsProps {
  selected: number[];
  onRefresh: () => void;
}
const SelectedActions: React.FC<SelectedActionsProps> = ({ selected, onRefresh }) => {
  return (
    <Alert severity="info">
      <AlertTitle>{selected.length} rows selected.</AlertTitle>
      <ChangeVisibleButton selected={selected} onRefresh={onRefresh} />
      <SyncItemStockAvailButton selected={selected} onRefresh={onRefresh} />
      <SyncCategoryButton selected={selected} onRefresh={onRefresh} />
    </Alert>
  );
};

export default SelectedActions;
