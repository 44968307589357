import React from "react";
interface DashPanelProps {
  title?: string;
  actions?: any;
  children?: React.ReactNode;
}
const DashPanel: React.FC<DashPanelProps> = (props) => {
  return (
    <div style={{ border: "1px solid gray", margin: "0.5rem" }}>
      <div
        style={{
          padding: "1rem",
          backgroundColor: "lightgray",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <div>{props.title}</div>
        <div>{props.actions}</div>
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default DashPanel;
