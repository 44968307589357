import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../../../graphql/gqladm";
import useSnack from "../../../../hooks/useSnack";
import { User } from "../../../../types/elements";
import ColumnDiv from "../../../molecules/ColumnDiv";

interface NewUserDialogProps {
  open: boolean;
  onClose: (created_user_id?: number, user?: User) => any;
}
const NewUserDialog: React.FC<NewUserDialogProps> = (props) => {
  const { openSnack } = useSnack();

  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const handleAddOfflineUser = () => {
    setLoading(true);
    gqladm
      .addOfflineUser({ name, phone, email })
      .then((result) => {
        setLoading(false);
        if (result?.ok) {
          openSnack({ msg: "오프라인 유저 생성에 성공하였습니다. " });
          props.onClose(result?.id ?? -1, { name, phone, email });
        } else {
          openSnack({ msg: result?.msg ?? "에러가 발생하였습니다." });
        }
      })
      .catch((err) => {
        setLoading(false);
        openSnack({ msg: "에러가 발생하였습니다." });
      });
  };
  return (
    <Dialog className="newUserDialog" open={props.open} fullWidth onClose={() => props.onClose()}>
      <DialogTitle>유저 추가</DialogTitle>
      <DialogContent>
        <div>
          <ColumnDiv label="이름(필수)">
            <TextField size="small" onChange={(e) => setName(e.target.value)}></TextField>
          </ColumnDiv>
          <ColumnDiv label="전화번호(필수)">
            <TextField size="small" onChange={(e) => setPhone(e.target.value)}></TextField>
          </ColumnDiv>
          <ColumnDiv label="이메일">
            <TextField size="small" onChange={(e) => setEmail(e.target.value)}></TextField>
          </ColumnDiv>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={loading === false ? "primary" : "inherit"}
          onClick={handleAddOfflineUser}>
          {loading === false ? "오프라인 유저 생성" : "requesting"}
        </Button>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewUserDialog;
