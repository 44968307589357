const useEnvironment = () => {
  const getServiceUrl = () => {
    return window.location.hostname === "localhost"
      ? "http://192.168.50.51:3000"
      : window.location.hostname === "dev-admin.andersonc-design.com"
      ? "https://dev.andersonc-design.com"
      : "https://andersonc-official.com";
  };
  const isLocalDev =
    window.location.hostname === "localhost" || window.location.hostname === "192.168.50.51";
  const isDev = window.location.hostname === "dev.andersonc-design.com";

  const getServiceApiUrl = () => {
    return window.location.hostname === "localhost"
      ? "http://192.168.50.51:8080"
      : window.location.hostname === "dev-admin.andersonc-design.com"
      ? "https://dev.andersonc-design.com"
      : "https://andersonc-official.com";
  };
  return {
    getServiceUrl,
    getServiceApiUrl,
  };
};

export default useEnvironment;
