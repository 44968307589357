import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Item, ItemSearchOption, SearchResult, ItemLabel } from "../../../types/elements";

import {
  Button,
  IconButton,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import CreatePrivatePayItemDialog from "../../organisms/CreatePrivatePayItemDialog";
import { gqladm } from "../../../graphql/gqladm";
import Pagination from "@mui/material/Pagination";
import ItemDetailView from "../itemDetail/ItemDetailView";
import ItemSearchCondition from "./ItemSearchCondition";
import useSnack from "../../../hooks/useSnack";
import { useAppCommon } from "../../../hooks/useAppCommon";
import ItemResultTable from "./ItemResultTable";
import CSVExtractDialog from "./CSVExtractBtnDialog";
import { useWindowSize } from "../../../hooks/useWindowSize";
import SelectedActions from "./SelectedActions";
import ItemDetailDialog from "../itemDetail/ItemDetailDialog";

const LIMIT = 20;
interface ItemListViewProps {}

const ItemListView: React.FC<ItemListViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "Item List" });
  const { isMobile } = useWindowSize();
  const navi = useNavigate();

  const { openSnack } = useSnack();

  const [selected, setSelected] = useState<number[]>([]);
  const [itemList, setItemList] = useState<Array<Item>>([]);
  const [total, setTotal] = useState<number>(0);

  const [sopt, setSopt] = useState<ItemSearchOption>({
    limit: LIMIT,
    offset: 0,
    text: "",
    type: "NORMAL",
    visibles: ["OPEN", "CLOSED"],
    statuses: ["SALE", "SOLDOUT"],
    ordering: "id_desc",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [elapsed, setElapsed] = useState<number>(0);

  const loadItems = (sopt: ItemSearchOption) => {
    const st = Date.now();
    setLoading(true);
    gqladm
      .getItems(sopt)
      .then((result: SearchResult<Item>) => {
        if (result && result.list) setItemList(result.list);
        if (result && result.total !== undefined) setTotal(result.total);
        if (result && result.offset !== undefined) setSopt({ ...sopt, offset: result.offset });
        setSelected([]);
      })
      .catch((err: any) => {
        openSnack({ msg: "Error on loadItems " + err });
      })
      .finally(() => {
        setLoading(false);
        setElapsed(Date.now() - st);
      });
  };

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      loadItems({ ...sopt, offset: offset });
    }
  };

  useEffect(() => {
    loadItems(sopt);
  }, []);

  const addItem = async () => {
    console.log("addItem");

    gqladm
      .addItem()
      .then((result) => {
        if (result.ok) {
          openSnack({ msg: "상품이 생성되었습니다 id=" + result.id, severity: "success" });
          navi("/item/" + result.id);
        } else {
          openSnack({ msg: "상품생성이 실패하였습니다. " + result, severity: "error" });
        }
      })
      .catch((err) => {
        openSnack({ msg: "상품생성이 실패하였습니다. " + err, severity: "error" });
      });
  };

  const [detailItemId, setDetailItemId] = useState<number | undefined>();
  const openItemDetail = (id?: number, target?: "new") => {
    if (target === "new") {
      window.open("/item/" + id, "_blank");
    } else {
      setDetailItemId(id);
    }
  };

  const handleToggleItem = (item: Item) => (e: any, checked: boolean) => {
    console.log(e.target.checked);
    if (checked === false) {
      setSelected([...selected.filter((o) => o !== item.id)]);
    } else if (item.id !== undefined) {
      setSelected([...selected, item.id]);
    }
  };

  const handleToggleItemAll = () => {
    if (itemList.length === selected.length) {
      setSelected([]);
    } else {
      setSelected(itemList.map((o) => (o.id ? o.id : -1)));
    }
  };

  const handleChangeSortLevel = (item: Item) => {
    const sl = prompt(
      "(id:" +
        item.id +
        ") " +
        item.name +
        "의 sort level을 입력해주세요. 높은 숫자일수록 검색결과에서 먼저 나오게 됩니다.",
      "" + item.sort_level
    );
    if (isNaN(Number(sl))) {
      openSnack({ msg: "숫자를 입력해주셔야합니다." });
    } else {
      if (item && item.id)
        gqladm.updateItemSortLevel(item.id, Number(sl)).then((result) => {
          if (result.ok) {
            loadItems(sopt);
          }
        });
    }
  };

  const handleRefresh = () => {
    loadItems(sopt);
  };

  // Private Pay cra
  const [openCreatePrivatePay, setOpenCreatePrivatePay] = useState<boolean>(false);
  console.log(openCreatePrivatePay);

  const handleToggleLabel = (item_id: number, label_name: ItemLabel, enable: boolean) => {
    gqladm.updateItemLabel(item_id, label_name, enable).then((result) => {
      if (result.ok) {
        loadItems(sopt);
      }
    });
  };

  return (
    <div className={/* classes.root */ "itemListView"}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <Button color="primary" variant="contained" onClick={addItem}>
            Add Item
          </Button>
          <IconButton onClick={() => setOpenCreatePrivatePay(true)}>개인결제생성</IconButton>
          <CreatePrivatePayItemDialog
            open={openCreatePrivatePay}
            onClose={(d) => setOpenCreatePrivatePay(false)}
          />
        </div>

        <ItemSearchCondition
          sopt={sopt}
          setSopt={setSopt}
          onSearch={() => loadItems(sopt)}
          actions={
            <>
              <CSVExtractDialog sopt={sopt} />
            </>
          }
        />

        <div style={{ display: "flex", justifyContent: "space-between", margin: "1rem 0" }}>
          <div>Total {total}</div>
          <Pagination
            siblingCount={10}
            count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
            page={
              Math.ceil((sopt.offset ? sopt.offset : 0) / (sopt.limit ? sopt.limit : LIMIT)) + 1
            }
            onChange={handleChangePage}
          />
          <div>elapsed {elapsed / 1000}s</div>
        </div>

        {selected.length > 0 && <SelectedActions selected={selected} onRefresh={handleRefresh} />}
      </div>
      <hr />

      <ItemResultTable
        items={itemList}
        selected={selected}
        onChangeSortLevel={handleChangeSortLevel}
        onOpenItemDetail={openItemDetail}
        onToggleAll={handleToggleItemAll}
        onToggleItem={handleToggleItem}
        onToggleLabel={handleToggleLabel}
      />

      <div style={{ display: "flex", justifyContent: "center", margin: "1rem 0" }}>
        <Pagination
          count={Math.ceil(total / (sopt.limit ? sopt.limit : LIMIT))}
          page={Math.ceil((sopt.offset ? sopt.offset : 0) / (sopt.limit ? sopt.limit : LIMIT)) + 1}
          onChange={handleChangePage}
        />
      </div>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* 상품 디테일 열기 */}
      <ItemDetailDialog itemId={detailItemId} onClose={() => setDetailItemId(undefined)} />
    </div>
  );
};

export default ItemListView;
