import { gql } from "@apollo/react-hooks";

import {
  Board,
  BoardBlock,
  BoardBlockType,
  BoardSearchOption,
  convertBoard,
  convertBoardBlock,
  SearchResult,
  SimpleResult,
} from "../../types/elements";
import { client } from "../client";

interface QueryIF {
  boards: SearchResult<Board>;
  boardblock: BoardBlock;
}

interface MutationIF {
  admin?: {
    addBoard: SimpleResult;
    saveBoardSortLevel: SimpleResult;
    saveBoardKey: SimpleResult;
    saveBoardTitle: SimpleResult;
    saveBoardSubtitle: SimpleResult;
    saveBoardIsShow: SimpleResult;
    saveBoardPeriod: SimpleResult;
    saveBoardThumbImageId: SimpleResult;
    addBoardBlock: SimpleResult;
    saveBlockImageIds: SimpleResult;
    saveBlockBody: SimpleResult;
    saveBlockSeq: SimpleResult;
    removeBoard: SimpleResult;
    removeBoardBlock: SimpleResult;
  };
}

export const BoardApi = {
  getBoards: async (sopt: BoardSearchOption): Promise<SearchResult<Board>> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetBoards($sopt: BoardSearchInput) {
            boards(sopt: $sopt) {
              total
              offset
              limit
              list {
                id
                sort_level
                board_key
                title
                period
                isShow
                thumb_image {
                  id
                  url
                }
                blocks {
                  id
                  board_id
                  seq
                  type
                  image_ids
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result?.data?.boards)
          return {
            ...result.data.boards,
            list: (result.data.boards.list ?? []).map((o) => convertBoard(o) ?? {}),
          };
        throw new Error("Err");
      });
  },
  getBoard: async (id: number): Promise<Board> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetBoard($sopt: BoardSearchInput) {
            boards(sopt: $sopt) {
              list {
                id
                board_key
                title
                subtitle
                period
                isShow
                thumb_image_id
                thumb_image {
                  id
                  url
                }
                blocks {
                  id
                  board_id
                  seq
                  type
                  image_ids
                  body
                  binded_images {
                    id
                    url
                  }
                  uploaded_images {
                    id
                    url
                  }
                }
              }
            }
          }
        `,
        variables: { sopt: { id } },
      })
      .then((result) => {
        if (result.data.boards.list && result.data.boards.list.length > 0) {
          const ret = convertBoard(result.data.boards.list[0]) ?? {};
          ret.blocks = ret.blocks?.map((o) => convertBoardBlock(o) ?? {});
          return ret;
        }
        throw new Error("err");
      });
  },
  getBoardBlock: (id: number): Promise<BoardBlock> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetBoardBlock($id: BigInt) {
            boardblock(id: $id) {
              id
              board_id
              seq
              type
              image_ids
              body
              binded_images {
                id
                url
              }
              uploaded_images {
                id
                url
              }
            }
          }
        `,
        variables: { id },
      })
      .then((result) => {
        if (result.data.boardblock) return convertBoardBlock(result.data.boardblock) ?? {};
        throw new Error("Err");
      });
  },
  addBoard: async (): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddBoard {
            admin {
              addBoard {
                ok
                id
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data?.admin?.addBoard) return result.data.admin.addBoard;
        throw new Error("Err");
      });
  },
  saveBoardSortLevel: async (id: number, sort_level: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveBoardSortLevel($id: BigInt, $sort_level: BigInt) {
            admin {
              saveBoardSortLevel(id: $id, sort_level: $sort_level) {
                ok
                id
              }
            }
          }
        `,
        variables: { id, sort_level },
      })
      .then((result) => {
        if (result.data?.admin?.saveBoardSortLevel) return result.data.admin.saveBoardSortLevel;
        throw new Error("Err");
      });
  },
  saveBoardKey: async (id: number, board_key: string): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveBoardKey($id: BigInt, $board_key: String) {
            admin {
              saveBoardKey(id: $id, board_key: $board_key) {
                ok
                id
              }
            }
          }
        `,
        variables: { id, board_key },
      })
      .then((result) => {
        if (result.data?.admin?.saveBoardKey) return result.data.admin.saveBoardKey;
        throw new Error("Err");
      });
  },
  saveBoardTitle: async (id: number, title: string): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveBoardTitle($id: BigInt, $title: String) {
            admin {
              saveBoardTitle(id: $id, title: $title) {
                ok
                count
              }
            }
          }
        `,
        variables: { id, title },
      })
      .then((result) => {
        if (result.data?.admin?.saveBoardTitle) return result.data.admin.saveBoardTitle;
        throw new Error("Err");
      });
  },
  saveBoardSubtitle: async (id: number, subtitle: string): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveBoardSubtitle($id: BigInt, $subtitle: String) {
            admin {
              saveBoardSubtitle(id: $id, subtitle: $subtitle) {
                ok
                count
              }
            }
          }
        `,
        variables: { id, subtitle },
      })
      .then((result) => {
        if (result.data?.admin?.saveBoardSubtitle) return result.data.admin.saveBoardSubtitle;
        throw new Error("Err");
      });
  },
  saveBoardIsShow: async (id: number, isShow: boolean): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveBoardIsShow($id: BigInt, $isShow: Boolean) {
            admin {
              saveBoardIsShow(id: $id, isShow: $isShow) {
                ok
                count
              }
            }
          }
        `,
        variables: { id, isShow },
      })
      .then((result) => {
        if (result.data?.admin?.saveBoardIsShow) return result.data.admin.saveBoardIsShow;
        throw new Error("Err");
      });
  },
  saveBoardPeriod: async (id: number, period: string): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveBoardPeriod($id: BigInt, $period: String) {
            admin {
              saveBoardPeriod(id: $id, period: $period) {
                ok
                count
              }
            }
          }
        `,
        variables: { id, period },
      })
      .then((result) => {
        if (result.data?.admin?.saveBoardPeriod) return result.data.admin.saveBoardPeriod;
        throw new Error("Err");
      });
  },
  saveBoardThumbImageId: async (id: number, thumb_image_id: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveBoardThumbImageId($id: BigInt, $thumb_image_id: BigInt) {
            admin {
              saveBoardThumbImageId(id: $id, thumb_image_id: $thumb_image_id) {
                ok
                id
              }
            }
          }
        `,
        variables: { id, thumb_image_id },
      })
      .then((result) => {
        if (result.data?.admin?.saveBoardThumbImageId)
          return result.data.admin.saveBoardThumbImageId;
        throw new Error("Err");
      });
  },
  addBoardBlock: async (board_id: number, type: BoardBlockType): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddBoardBlock($board_id: BigInt, $type: String) {
            admin {
              addBoardBlock(board_id: $board_id, type: $type) {
                ok
                id
              }
            }
          }
        `,
        variables: { board_id, type },
      })
      .then((result) => {
        if (result.data?.admin?.addBoardBlock) return result.data.admin.addBoardBlock;
        throw new Error("Err");
      });
  },
  saveBlockImageIds: async (id: number, image_ids: string): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveBlockImageIds($id: BigInt, $image_ids: String) {
            admin {
              saveBlockImageIds(id: $id, image_ids: $image_ids) {
                ok
                count
              }
            }
          }
        `,
        variables: { id, image_ids },
      })
      .then((result) => {
        if (result.data?.admin?.saveBlockImageIds) return result.data.admin.saveBlockImageIds;
        throw new Error("Err");
      });
  },
  saveBlockBody: async (id: number, body: string): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveBlockBody($id: BigInt, $body: String) {
            admin {
              saveBlockBody(id: $id, body: $body) {
                ok
                count
              }
            }
          }
        `,
        variables: { id, body },
      })
      .then((result) => {
        if (result.data?.admin?.saveBlockBody) return result.data.admin.saveBlockBody;
        throw new Error("Err");
      });
  },
  saveBlockSeq: async (id: number, seq: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveBlockSeq($id: BigInt, $seq: BigInt) {
            admin {
              saveBlockSeq(id: $id, seq: $seq) {
                ok
                count
              }
            }
          }
        `,
        variables: { id, seq },
      })
      .then((result) => {
        if (result.data?.admin?.saveBlockSeq) return result.data.admin.saveBlockSeq;
        throw new Error("Err");
      });
  },
  removeBoard: async (id: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveBoard($id: BigInt) {
            admin {
              removeBoard(id: $id) {
                ok
                id
              }
            }
          }
        `,
        variables: { id },
      })
      .then((result) => {
        if (result.data?.admin?.removeBoard) return result.data.admin.removeBoard;
        throw new Error("Err");
      });
  },
  removeBoardBlock: async (id: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveBoardBlock($id: BigInt) {
            admin {
              removeBoardBlock(id: $id) {
                ok
                id
              }
            }
          }
        `,
        variables: { id },
      })
      .then((result) => {
        if (result.data?.admin?.removeBoardBlock) return result.data.admin.removeBoardBlock;
        throw new Error("Err");
      });
  },
};
