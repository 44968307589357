import { IconButton, InputBase, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import RateReviewIcon from "@mui/icons-material/RateReview";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { gql } from "apollo-boost";
import { gqladm } from "../../graphql/gqladm";

interface UserMemoInputFormProps {
  user_id: number;
  onSuccess?: () => any;
}
const UserMemoInputForm: React.FC<UserMemoInputFormProps> = (props) => {
  const [lastMsg, setLastMsg] = useState<string>("");
  const [msg, setMsg] = useState<string>(lastMsg);
  const handleChangeMsg = (e: any) => {
    setMsg(e.target.value);
  };

  const handleResetMsg = () => {
    gqladm.searchUserMemo({ user_id: props.user_id, limit: 1, offset: 0 }).then((result) => {
      if (result?.list && result.list.length > 0) {
        const last = result.list[0];
        setLastMsg(last.memo ?? "");
        setMsg(last.memo ?? "");
      }
    });
  };

  useEffect(() => {
    handleResetMsg();
  }, []);

  const handleAddMemo = () => {
    console.log("handleAddMemo");
    gqladm.addUserMemo(props.user_id, msg).then((result) => {
      if (result.ok) {
        props.onSuccess && props.onSuccess();
      }
    });
  };

  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
      }}>
      <IconButton onClick={handleResetMsg}>
        <RotateLeftIcon />
      </IconButton>
      <InputBase
        fullWidth
        value={msg}
        onChange={handleChangeMsg}
        onKeyUp={(e) => e.key === "Enter" && handleAddMemo()}></InputBase>
      <IconButton onClick={handleAddMemo}>
        <RateReviewIcon />
      </IconButton>
    </Paper>
  );
};

export default UserMemoInputForm;
