import { WrapperAxios as Axios } from "./base";

import { CommonUtil, APIResponse, QnaSearchOption, Qna, QnaReply } from "../types/elements";
import { ADMIN_API_PATH } from "../types/adm_elements";

export const AdminQnaAPI = {
  getQnas: (opt: QnaSearchOption): Promise<APIResponse<Array<Qna>>> => {
    const query = CommonUtil.createQueryString(opt);
    return Axios.get("/admin" + ADMIN_API_PATH.QNA + query).then((result) => {
      const data: any = result.data;
      if (data.result && data.result instanceof Array) {
        const list: Qna[] = data.result;
        data.result = list.map((o: any) => {
          const replys = o.replys ? o.replys : [];
          const ret: Qna = {
            ...o,
            replys: [
              ...replys.map((rp: any) => {
                const rep: QnaReply = { ...rp, created: new Date(rp.created) };
                return rep;
              }),
            ],
            created: new Date(o.created),
          };
          return ret;
        });
      }

      return result;
    });
  },

  postItemQnaReply: (qnaId: number, qnaReply: QnaReply): Promise<APIResponse<any>> => {
    return Axios.post("/admin" + ADMIN_API_PATH.QNA + "/" + qnaId + "/reply", qnaReply);
  },
};
