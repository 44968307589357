import axios from "axios";
import React, { useEffect, useState } from "react";
import { getThumbUrl, Image } from "../../types/elements";

interface CountOfImageThumbnailProps {
  images?: Image[];
}
const CountOfImageThumbnail: React.FC<CountOfImageThumbnailProps> = ({ images }) => {
  const [num, setNum] = useState<number>(0);
  useEffect(() => {
    if (images) {
      Promise.all(
        images.map((image) => {
          if (image.url)
            return axios
              .get("/" + getThumbUrl(image.url))
              .then((r) => (r.status === 200 ? 1 : 0))
              .catch((e) => 0);
          return 0;
        })
      ).then((result) => {
        // console.log(result);
        setNum(result.reduce((p, n) => p + n, 0));
      });
    }
  }, [images]);
  return (
    <>
      {num}/{images?.length ?? 0}
    </>
  );
};

export default CountOfImageThumbnail;
