import React from "react";

const ColumnDiv: React.FC<{ label?: any; className?: string; children?: React.ReactNode }> = (
  props
) => {
  return (
    <div className={"columnDiv " + (props.className ?? "")}>
      {props.label && <div className="_title">{props.label}</div>}
      {props.children && <div className="_body">{props.children}</div>}
    </div>
  );
};

export default ColumnDiv;
