import { useState } from "react";
import { gqladm } from "../../../../../graphql/gqladm";
import { UserDeviceSearchOption } from "../../../../../types/adm_elements";
import { UserDevice } from "../../../../../types/elements";

export const useSearchUserDevice = (opt: { limit: number; withUser?: boolean }) => {
  const LIMIT = opt.limit;
  const [total, setTotal] = useState<number>(0);
  const [list, setList] = useState<UserDevice[]>([]);
  const [sopt, setSopt] = useState<UserDeviceSearchOption>({
    offset: 0,
    limit: LIMIT,
    orderby: "updated_desc",
  });

  const load = (sopt: UserDeviceSearchOption) => {
    (opt.withUser ? gqladm.getUserDevicesWithUser : gqladm.getUserDevices)(sopt).then((result) => {
      if (result.list) setList(result.list);
      if (result.total !== undefined) setTotal(result.total);
      setSopt({
        ...sopt,
        offset: result.offset ?? sopt.offset,
        limit: result.limit ?? sopt.limit,
      });
    });
  };

  const handleChangePage = (e: any, page: number) => {
    const offset = (page - 1) * (sopt.limit ? sopt.limit : LIMIT);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  return { total, list, sopt, load, handleChangePage, LIMIT };
};
