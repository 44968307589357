import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import HelpIcon from "../../molecules/HelpIcon";
import { OrderResultProps } from "./OrderListView";
import { getKrOrderStatus, Order } from "../../../types/elements";
import useItemMeta from "../../../hooks/useItemMeta";
import useSnack from "../../../hooks/useSnack";
import { useNavigate } from "react-router-dom";

interface OrderResultTableProps extends OrderResultProps {}
const OrderResultTable: React.FC<OrderResultTableProps> = (props) => {
  const { list: orders } = props;
  const navi = useNavigate();
  const { getItemMeta } = useItemMeta();
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>STATUS</TableCell>
          <TableCell>TYPE</TableCell>
          <TableCell>상품</TableCell>
          <TableCell>주문가격</TableCell>
          <TableCell>주문일자</TableCell>
          <TableCell>
            주문자
            <HelpIcon fontSize="small">주문시 입력한 주문자</HelpIcon>
          </TableCell>
          <TableCell>
            주문유저
            <HelpIcon fontSize="small">주문시 로그인 한 유저</HelpIcon>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orders.map((order: Order, idx) => (
          <TableRow key={idx}>
            <TableCell>
              <Button onClick={() => order.id && props.openDetail && props.openDetail(order.id)}>
                {order.id}
              </Button>
            </TableCell>
            <TableCell>{getKrOrderStatus(order.status)}</TableCell>
            <TableCell>{order.type}</TableCell>
            <TableCell>
              <div>
                {(order.orderItems ? order.orderItems : []).map((oi, oiIdx) => {
                  const ordered = (order.orderedItems ? order.orderedItems : []).filter(
                    (o) => o.itemId === oi.id && o.itemOptionId === oi.item_option_id
                  );
                  const appliedCount = ordered.reduce(
                    (p, n) => p + (n.orderedCount ? n.orderedCount : 0),
                    0
                  );
                  const currentDesigner = getItemMeta("Designer", oi.item?.designer_id);
                  return (
                    <div key={oiIdx}>
                      [{oi.item_id}-{oi.item_option_id}] {oi.item?.name}
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>
                          {oi.item?.topImages && oi.item.topImages.length > 0 && (
                            <img className="rect48" src={"/" + oi.item.topImages[0].url} />
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}>
                          <div>- {currentDesigner?.name && <b>{currentDesigner?.name}</b>}</div>
                          <div>{`주문수량: ${oi.count} / (${appliedCount})`}</div>
                        </div>
                      </div>
                      {appliedCount > 0 && oi.count === appliedCount && "완료"}
                    </div>
                  );
                })}
              </div>
            </TableCell>
            <TableCell>{order.price}</TableCell>
            <TableCell>{order.created ? new Date(order.created).toLocaleString() : ""}</TableCell>
            <TableCell>
              {order.useremail}
              <br />
              {order.username}
            </TableCell>
            <TableCell>
              #{order.user_id} {order.user?.name}
              <br /> {order.user?.email} <br />
              {order.user?.phone}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default OrderResultTable;
