import { CircularProgress } from "@mui/material";
import React, { ReactNode } from "react";

interface TileProps {
  title?: string | ReactNode;
  subtitle?: string;
  value?: string;
  isLoading?: boolean;
}
const Tile: React.FC<TileProps> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "148px",
        height: "148px",
        margin: "6px",
        backgroundColor: "#f4f4f4",
        padding: "10px",
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}>
        <div>{props.title}</div>
        <div>{props.subtitle}</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          fontSize: "2rem",
        }}>
        {props.isLoading === true ? <CircularProgress /> : props.value}
      </div>
    </div>
  );
};

export default Tile;
