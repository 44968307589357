import { Button, Chip, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import ColumnDiv from "../../../molecules/ColumnDiv";
import { gqladm } from "../../../../graphql/gqladm";
import {
  StatusCount,
  StylingSearchOption,
  StylingStatus,
  StylingStatusArray,
} from "../../../../types/elements";

interface StylingSearchBoxProps {
  sopt: StylingSearchOption;
  setSopt: React.Dispatch<React.SetStateAction<StylingSearchOption>>;
  onSearch: (opt: StylingSearchOption) => any;
}
const StylingSearchBox: React.FC<StylingSearchBoxProps> = (props) => {
  const { sopt, setSopt } = props;

  const [statusCounts, setStatusCounts] = useState<StatusCount[]>([]);
  const loadStatusCount = () => {
    gqladm.stylingStatusCounts().then((result) => {
      setStatusCounts(result);
    });
  };
  useEffect(() => {
    loadStatusCount();
    handleSearch();
  }, []);

  const handleToggleSearchStatus = (status: StylingStatus) => {
    setSopt((so) => ({
      ...so,
      statuses: (so.statuses ?? []).includes(status)
        ? (so.statuses ?? []).filter((o) => o !== status)
        : [...(so.statuses ?? []), status],
    }));
  };

  const handleSearch = () => {
    props.onSearch(sopt);
  };

  return (
    <div className="consignmentSearchBox">
      <div style={{ marginBottom: "8px", fontWeight: "bold" }}>Styling Search</div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        Status :&nbsp;
        {StylingStatusArray.map((cs, csIdx) => {
          const count = statusCounts
            .filter((o) => o.status === cs)
            .map((o) => o.count)
            .reduce((p, n) => (p ?? 0) + (n ?? 0), 0);
          return (
            <Chip
              key={csIdx}
              className="statusChip"
              label={cs + `(${count})`}
              onClick={() => handleToggleSearchStatus(cs)}
              color={(sopt.statuses ?? []).includes(cs) ? "primary" : "default"}
              size="small"
            />
          );
        })}
      </div>
      <ColumnDiv label="정렬순서">
        <ToggleButtonGroup
          size="small"
          exclusive
          value={sopt.ordering}
          onChange={(e, v) => {
            setSopt((so) => ({ ...so, ordering: v }));
          }}>
          <ToggleButton value="">No</ToggleButton>
          <ToggleButton value="newerfirst">최근부터</ToggleButton>
          <ToggleButton value="olderfirst">과거부터</ToggleButton>
        </ToggleButtonGroup>
      </ColumnDiv>
      <div style={{ padding: "0.2rem", display: "flex" }}>
        {/* <Button>접기</Button> */}
        <Button variant="contained" fullWidth onClick={handleSearch}>
          검색
        </Button>
      </div>
    </div>
  );
};

export default StylingSearchBox;
