import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchedAddress } from "../../api/PublicAddressAPI";
import ColumnDiv from "../molecules/ColumnDiv";
import AddressSelectDialog from "./AddressSelectDialog";

interface AddressFormDialogProps {
  addr1?: string;
  addr2?: string;
  zipcode?: string;
  open: boolean;
  onClose: () => any;
  setAddress: (addr1: string, addr2: string, zipcode: string) => any;
}
const AddressFormDialog: React.FC<AddressFormDialogProps> = (props) => {
  const [addr1, setAddr1] = useState<string>("");
  const [addr2, setAddr2] = useState<string>("");
  const [zipcode, setZipcode] = useState<string>("");

  useEffect(() => {
    if (props.addr1) setAddr1(props.addr1);
  }, [props.addr1]);
  useEffect(() => {
    if (props.addr2) setAddr2(props.addr2);
  }, [props.addr2]);
  useEffect(() => {
    if (props.zipcode) setZipcode(props.zipcode);
  }, [props.zipcode]);

  const [openAddressSelect, setOpenAddressSelect] = useState<boolean>(false);
  const handleOpenAddressSelect = () => {
    setOpenAddressSelect(true);
  };

  const handleSetAddress = (se: SearchedAddress) => {
    setAddr1(se.roadAddr);
    setZipcode(se.zipNo);
  };

  const handleInsert = () => {
    props.setAddress(addr1, addr2, zipcode);
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>주소 입력</DialogTitle>
      <DialogContent>
        <div>
          <ColumnDiv label={"우편번호"}>
            <div style={{ display: "flex" }}>
              <TextField value={zipcode} disabled size="small" />
              <Button
                onClick={handleOpenAddressSelect}
                sx={{ whiteSpace: "nowrap", marginLeft: "0.3rem" }}
                variant="contained"
                size="small">
                우편번호 검색
              </Button>
              <AddressSelectDialog
                open={openAddressSelect}
                onClose={() => setOpenAddressSelect(false)}
                onSelect={handleSetAddress}
              />
            </div>
          </ColumnDiv>
          <ColumnDiv label={"주소1"}>
            <TextField size="small" value={addr1} disabled />
          </ColumnDiv>
          <ColumnDiv label={"주소2"}>
            <TextField size="small" value={addr2} onChange={(e) => setAddr2(e.target.value)} />
          </ColumnDiv>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleInsert}>입력</Button>
        <Button onClick={() => props.onClose()}>취소</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddressFormDialog;
