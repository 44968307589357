import React, { useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Popover } from "@mui/material";
interface HelpIconProps {
  fontSize?: "small" | "inherit" | "medium" | "large" | undefined;
  icon?: any;
  children?: React.ReactNode;
}
const HelpIcon: React.FC<HelpIconProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  // const [open, setOpen] = useState<boolean>(false);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {props.icon ? (
        <span onClick={handlePopoverOpen}>{props.icon}</span>
      ) : (
        <HelpOutlineIcon onClick={handlePopoverOpen} fontSize={props.fontSize} />
      )}

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        {props.children}
      </Popover>
    </>
  );
};

export default HelpIcon;
