import { gqladm } from "../graphql/gqladm";
import { Constant, ConstantKeys } from "../types/elements";

const useConstant = () => {
  const getConstant = (key: ConstantKeys): Promise<Constant | undefined> => {
    return gqladm.searchConstants({ key: key, isUniqueKey: true }).then((result) => {
      if (result?.list && result.list.length > 0) {
        return result.list[0];
      } else return;
    });
  };
  return { getConstant };
};
export default useConstant;
