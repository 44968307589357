import React, { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import { SmsAPI } from "../../api";

interface SmsHistoryDetailProps {
  mid: number;
  open: boolean;
  close: () => any;
}

const SmsHistoryDetailDialog: React.FC<SmsHistoryDetailProps> = (props) => {
  useEffect(() => {
    SmsAPI.getSmsDetail({ mid: props.mid }).then((result) => {
      console.log("result", result);
    });
  }, [props.mid]);

  const handleClose = () => {
    props.close();
  };
  return (
    <Dialog open={props.open} onClose={handleClose}>
      SMS Detail, UnderConstruction
    </Dialog>
  );
};

export default SmsHistoryDetailDialog;
