import { Button, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import useSnack from "../../../../hooks/useSnack";
import { ItemVisible, ItemVisibleArr } from "../../../../types/elements";
import { gqladm } from "../../../../graphql/gqladm";

interface ChangeVisibleButtonProps {
  selected: number[];
  onRefresh: () => any;
}
const ChangeVisibleButton: React.FC<ChangeVisibleButtonProps> = (props) => {
  const { openSnack } = useSnack();
  const [changeVisible, setChangeVisible] = useState<ItemVisible | "default">("default");
  const handleChangeVisibleSelect = (e: any, value: any) => {
    value.props.value && setChangeVisible(value.props.value);
  };
  const handleClick = () => {
    console.log("Set VISIBLE");

    console.log("handleChangeVisible", changeVisible);
    if (changeVisible !== "default") {
      gqladm.updateItemsVisible(props.selected, changeVisible).then((result) => {
        openSnack({
          msg: "작업요청 " + props.selected.length + "건,  완료: " + result.count + "건.",
        });
        props.onRefresh();
      });
    } else {
      openSnack({ msg: "Visible is not set" });
    }
  };
  return (
    <div style={{ display: "inline-block" }}>
      <Select onChange={handleChangeVisibleSelect} defaultValue="default" size="small">
        <MenuItem value="default">&lt;&lt;Visible&gt;&gt;</MenuItem>
        {ItemVisibleArr.map((o, idx) => (
          <MenuItem key={idx} value={o}>
            {o}
          </MenuItem>
        ))}
      </Select>
      으로
      <Button onClick={handleClick} color="primary" variant="contained" size="small">
        Visible 일괄 변경
      </Button>
    </div>
  );
};

export default ChangeVisibleButton;
