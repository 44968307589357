import { Button, Chip } from "@mui/material";
import React from "react";
import { CommonUtil as cu } from "../../../../types/elements";
import { StylingResultProps } from "./StylingListView";

interface StylingResultListProps extends StylingResultProps {}
const StylingResultList: React.FC<StylingResultListProps> = ({ list, openDialog, openUser }) => {
  return (
    <div>
      {list.map((r, rIdx) => (
        <div
          key={r.id}
          style={{ backgroundColor: "#f0f0f0", margin: "0.5rem 0", padding: "0.3rem" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Button size="small" onClick={() => openDialog && r.id && openDialog(r.id)}>
                #{r.id}
              </Button>
            </div>
            <div>
              <Chip size="small" label={r.status} />
            </div>
            <div>{r.created && cu.getFormattedDate(r.created, "yyyy-MM-dd")}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 1 }}>타입:{r.areatype} </div>
            <div style={{ flex: 1 }}>공간:{r.areasize}</div>
            <div style={{ flex: 1 }}>예산:{r.budget}만원</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 1 }}>일정:{r.expect_month}</div>
            <div style={{ flex: 2 }}>
              <Button onClick={() => r.user_id && openUser && openUser(r.user_id)}>
                #{r.user?.id} - {r.user?.name} - {r.user?.phone}
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StylingResultList;
