import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { gqladm } from "../../../graphql/gqladm";
import {
  CommonUtil as cu,
  DAY,
  ReserveSlotStatus,
  ReserveSlotStatusArray,
  ReservationTime,
  ReservationTimeArray,
  ReserveSlot,
} from "../../../types/elements";
import HelpIcon from "../../molecules/HelpIcon";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { FlexBox } from "../../molecules/Layout";
import useSnack from "../../../hooks/useSnack";
export interface ReserveSlotDialogProps {
  open: boolean;
  onClose: () => any;
  dayNum?: number;
  date?: string;
}

const ReserveSlotDialog: React.FC<ReserveSlotDialogProps> = (props) => {
  const { openSnack } = useSnack();
  const cur = props.dayNum ? new Date(props.dayNum * DAY) : new Date();
  const curDate = `${cur.getFullYear()}-${cu.lpad(cur.getMonth() + 1, 2)}-${cu.lpad(
    cur.getDate(),
    2
  )}`;
  const [slots, setSlots] = useState<ReserveSlot[]>([]);

  const load = () => {
    gqladm
      .getReserveSlots({
        date: curDate,
      })
      .then((result) => {
        setSlots(result);
      });
  };
  useEffect(() => {
    if (props.open) {
      load();
    } else setSlots([]);
  }, [props.open, props.dayNum]);

  const handleAddTime = (time: ReservationTime) => {
    const slotFiltered = slots.filter((slot) => slot.time === time);
    if (slotFiltered.length === 0) {
      setSlots([
        ...slots.filter((o) => o.time !== time),
        { date: curDate, time: time, count: 2, show: true, status: "FREE", place: "gallery" },
      ]);
    }
  };
  const handleRemoveTime = (time: ReservationTime) => {
    setSlots(slots.filter((o) => o.time !== time));
  };
  const handleSelectStatus = (time: ReservationTime, status: ReserveSlotStatus) => {
    console.log("handleSelectStatus", time, status);
    const mod = slots.find((slot) => slot.time === time);
    if (mod) {
      setSlots(
        slots.map((o) => {
          return { ...o, status: o.time === time ? status : o.status };
        })
      );
    }
  };

  const handleChangeShow = (time: ReservationTime, show: boolean) => {
    console.log("handleChangeShow", time, show);
    setSlots(
      slots.map((o) => {
        return { ...o, show: o.time === time ? show : o.show };
      })
    );
  };

  const handleChangeCount = (time: ReservationTime, count?: number) => {
    if (count === undefined) {
      const prompt = window.prompt("Count?");
      if (prompt && !isNaN(Number(prompt))) {
        setSlots(
          slots.map((o) => {
            return { ...o, count: o.time === time ? Number(prompt) : o.count };
          })
        );
      }
    } else {
      setSlots(
        slots.map((o) => {
          return { ...o, count: o.time === time ? count : o.count };
        })
      );
    }
  };

  const handleSave = () => {
    gqladm
      .cleanReserveSlotsByDate(curDate)
      .then((result) => {
        if (result.ok) {
          return Promise.all(
            slots.map((o) => {
              return gqladm.saveReserveSlot({
                date: o.date,
                time: o.time,
                place: o.place,
                status: o.status,
                show: o.show,
                count: o.count,
              });
            })
          );
        }
      })
      .then((result) => {
        openSnack({ msg: "저장이 완료되었습니다. " });
        load();
      });
  };
  return (
    <Dialog open={props.open} fullWidth={true} maxWidth="xl">
      <DialogTitle>
        {cur.getFullYear()}-{cur.getMonth() + 1}-{cur.getDate()}
      </DialogTitle>
      <DialogContent>
        <table>
          <thead>
            <tr>
              <th>Time</th>
              <th>
                Show
                <HelpIcon>화면에 표시 될지 안될지 설정</HelpIcon>
              </th>
              <th>
                Method
                <HelpIcon>
                  * FREE: 자동으로 예약 확정
                  <br />* ALLOW: 예약시 무조건 대기상태, 관리자 승인으로 확정변경
                  <br />* REJECT: 마감표시, 예약불가
                </HelpIcon>
              </th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {ReservationTimeArray.map((time) => {
              const cur = slots.find((slot) => slot.time === time);
              return (
                <tr key={time}>
                  <td>
                    <FlexBox
                      first={
                        <Switch
                          checked={cur !== undefined}
                          onClick={() =>
                            cur === undefined ? handleAddTime(time) : handleRemoveTime(time)
                          }
                        />
                      }
                      last={
                        <div
                          style={{
                            width: "100px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          {time}
                        </div>
                      }
                    />
                  </td>
                  <td>
                    {cur && (
                      <Switch
                        checked={cur?.show ? true : false}
                        onChange={(e, b) => handleChangeShow(time, b)}
                      />
                    )}
                  </td>
                  <td>
                    {cur && (
                      <ButtonGroup>
                        {ReserveSlotStatusArray.map((status: ReserveSlotStatus) => {
                          return (
                            <Button
                              variant={cur?.status === status ? "contained" : undefined}
                              onClick={() => handleSelectStatus(time, status)}>
                              {status}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                    )}
                  </td>
                  <td>
                    {cur && (
                      <ButtonGroup variant="contained" size="small">
                        <IconButton
                          onClick={() =>
                            Number(cur.count) > 1 && handleChangeCount(time, Number(cur.count) - 1)
                          }>
                          <RemoveCircleOutlineOutlinedIcon />
                        </IconButton>
                        <Button variant="contained" onClick={() => handleChangeCount(time)}>
                          {cur.count}
                        </Button>
                        <IconButton onClick={() => handleChangeCount(time, Number(cur.count) + 1)}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </ButtonGroup>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => handleSave()}>
          Save
        </Button>
        <Button onClick={props.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReserveSlotDialog;
