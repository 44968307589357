import React, { Suspense, useEffect, useState } from "react";
import { SmsAPI } from "../../../api";
import { ResSmsAligoRemain } from "../../../types/adm_elements";

import PanelOrders from "./PanelOrders";
import PanelItemStocks from "./PanelItemStocks";
import PanelOrderedItems from "./PanelOrderedItems";
import TileContainer from "../../organisms/TileContainer";
import Tile from "../../organisms/Tile";
import { useDashUserCount } from "../../../hooks/useDashUserCount";
import { useAppCommon } from "../../../hooks/useAppCommon";
import useDashUserStatistics from "../../../hooks/useDashUserStatistics";
import PanelLoginUsers from "./PanelLoginUsers";
import { useDashDeviceCount } from "../../../hooks/useDashDeviceCount";
import { MenuItem, Select } from "@mui/material";
import { gqladm } from "../../../graphql/gqladm";
import { PanelOrderWaitings } from "./PanelOrderWaitings";
// import PerformanceChart from "./PerformanceChart";

interface DashBoardViewProps {}

const DashBoardView: React.FC<DashBoardViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "대시보드" });
  const [smsRemain, setSmeRemain] = useState<ResSmsAligoRemain>({
    result_code: 0,
    message: "",
    SMS_CNT: 0,
    LMS_CNT: 0,
    MMS_CNT: 0,
  });
  const [dbConnected, setDBConnected] = useState<number>(0);
  const [dbMaxConn, setDBMaxConn] = useState<number>(0);
  useEffect(() => {
    SmsAPI.getSmsRemain().then((result) => {
      const data = result.data;
      if (data.result) {
        const smsResult = data.result;
        setSmeRemain(smsResult);
      }
    });
    gqladm.getDBConnectedCount().then((count) => setDBConnected(count));
    gqladm.getDBMaxConnCount().then((count) => setDBMaxConn(count));
  }, []);

  const { total_count, online_count, offline_count } = useDashUserCount();
  const { total_device_count, user_device_count, activeDeviceCount, activeHour, setActiveHour } =
    useDashDeviceCount();
  const { lastLogin1hour, lastLogin24hour } = useDashUserStatistics();

  return (
    <div>
      <TileContainer title="주요 지표">
        <Tile title="SMS 가용량" subtitle={smsRemain.message} value={"" + smsRemain.SMS_CNT} />
        <Tile title="LMS 가용량" subtitle={smsRemain.message} value={"" + smsRemain.LMS_CNT} />
        <Tile title="MMS 가용량" subtitle={smsRemain.message} value={"" + smsRemain.MMS_CNT} />
        <Tile title="총 유저수" value={"" + total_count} isLoading={total_count === undefined} />
        <Tile
          title="Online 유저수"
          value={"" + online_count}
          isLoading={online_count === undefined}
        />
        <Tile
          title="Offline 유저수"
          value={"" + offline_count}
          isLoading={offline_count === undefined}
        />
        <Tile
          title="최근 1시간 로그인 유저수"
          value={"" + lastLogin1hour}
          isLoading={lastLogin1hour === undefined}
        />
        <Tile
          title="최근 1일 로그인 유저수"
          value={"" + lastLogin24hour}
          isLoading={lastLogin24hour === undefined}
        />
        <Tile
          title="총 설치된 앱 개수"
          value={"" + total_device_count}
          isLoading={total_device_count === undefined}
        />
        <Tile
          title="로그인된 앱 개수"
          value={"" + user_device_count}
          isLoading={user_device_count === undefined}
        />
        <Tile
          title={
            <>
              액티브 앱
              <Select
                value={activeHour}
                onChange={(e) => setActiveHour(Number(e.target.value))}
                size="small">
                <MenuItem value={1}>1시간</MenuItem>
                <MenuItem value={2}>2시간</MenuItem>
                <MenuItem value={6}>6시간</MenuItem>
                <MenuItem value={12}>12시간</MenuItem>
                <MenuItem value={24}>1일</MenuItem>
                <MenuItem value={24 * 7}>1주일</MenuItem>
              </Select>
            </>
          }
          value={"" + activeDeviceCount}
          isLoading={activeDeviceCount === undefined}
        />
        <Tile
          title={"DB Conn."}
          value={dbConnected + "/" + dbMaxConn}
          isLoading={dbConnected === 0}
        />
      </TileContainer>
      <br />- 문자 사용량은 https://smartsms.aligo.in/shop/charge.html 에서 충전 가능합니다. 아이디
      패스워드는 별도 전달
      <br />- KG이니시스 테스트 결제는 매일 23:30에 자동으로 취소됩니다. (그 전에 별도로 취소를 해도
      상관없습니다.)
      <br />- 관리하는 시스템은 AWS, Aligo(sms), Iamport(결제) 입니다.
      <br />- 각 시스템의 관리계정은 andersonc.official@gmail.com으로 되어있습니다. 패스워드 별도
      전달
      <br />{" "}
      <a href="https://analytics.google.com/" target="_blank">
        Google Analytics
      </a>
      에서 사이트 접속통계등에 관한 여러 지표를 확인할수 있습니다. andersonc.official@gmail.com의
      구글 계정으로 로그인하면 됩니다.
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <PanelOrderWaitings/>
        <PanelOrders />
        <PanelItemStocks />
        <PanelOrderedItems />
        <PanelLoginUsers />
      </div>
      {/* <Suspense fallback={<div>에러발생</div>}>
        <PerformanceChart />
      </Suspense> */}
    </div>
  );
};

export default DashBoardView;
