import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
  IconButtonProps,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { gqladm } from "../../graphql/gqladm";
import useItemMeta from "../../hooks/useItemMeta";
import useSnack from "../../hooks/useSnack";
import { getThumbUrl, Item, CommonUtil as cu, ItemProperty } from "../../types/elements";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
const ExpandMore: React.FC<ExpandMoreProps> = (props) => {
  const { expand, ...others } = props;
  return (
    <IconButton
      style={{
        transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
        marginLeft: "auto",
        transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
      }}
      {...others}
    />
  );
};

interface ItemMuiCardProps {
  item: Item;
  renderActions?: (item: Item) => React.ReactNode;
}
const ItemMuiCard: React.FC<ItemMuiCardProps> = (props) => {
  const { getItemMeta } = useItemMeta();
  const { openSnack } = useSnack();
  const { item } = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  // Properties
  const [propertiesLoaded, setPropertiesLoaded] = useState<boolean>(false);
  const [properties, setProperties] = useState<ItemProperty[]>(item.properties ?? []);
  useEffect(() => {
    if (expanded && !propertiesLoaded && item.id) {
      gqladm.getItemProperties({ item_id: item.id }).then((result) => {
        setProperties(result);
        setPropertiesLoaded(true);
      });
    }
  }, [item, expanded, propertiesLoaded]);

  // useEffect(() => {
  //   setStockAvail(item.stock_avail ?? 0);
  // }, [item.stock_avail]);

  // CategoryIds
  const category_ids =
    item.category_ids
      ?.replace("[", "")
      .replace("]", "")
      .split(",")
      .filter((o) => o && o !== "")
      .map((cid) => Number(cid)) ?? [];

  // stock_avail
  // const [stock_avail, setStockAvail] = useState<number>(item.stock_avail ?? 0);
  // // const handleCheckStockAvail = () => {
  //   if (item.id)
  //     gqladm
  //       .syncItemsStockAvail([item.id])
  //       .then((result) => {
  //         setStockAvail(result);
  //         openSnack({ msg: `재고수량이 ${result}으로 확인되었습니다. `, severity: "success" });
  //       })
  //       .catch((err) => {
  //         openSnack({ msg: "Error " + err, severity: "error" });
  //       });
  // };

  return (
    <Card variant="outlined" style={{ marginBottom: "0.5rem" }}>
      <CardHeader
        title={`[${item.id}]${item.name}`}
        subheader={getItemMeta("Designer", item.designer_id)?.name}
      />
      {item.topImages && item.topImages.length > 0 && (
        <CardMedia
          component="img"
          height="128"
          image={item.topImages[0].url && getThumbUrl("/" + item.topImages[0].url)}
          alt=""
        />
      )}
      <CardContent>
        {category_ids.length === 0
          ? "[No Category]"
          : "[" +
            category_ids.map((cid) => getItemMeta("Category", cid)?.name ?? "").join("-") +
            "]"}
        &nbsp;₩{cu.numberWithCommas(item.price)} - 재고({item.stock_avail}) {item.priceOpen}{" "}
        {item.visible ? "Visible" : "Invisible"} {item.status}
      </CardContent>
      <CardActions>
        {props.renderActions && props.renderActions({ ...item, properties })}
        <ExpandMore
          expand={expanded}
          onClick={() => setExpanded((e) => !e)}
          aria-expanded={expanded}
          aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardActions>
          {/* <Button onClick={handleCheckStockAvail}>재고 재확인</Button> */}
        </CardActions>
        <CardContent>
          <b>Properties</b>
          {properties?.map((prop) => {
            return (
              <div>
                <b>{prop.name}</b> : {prop.value}
              </div>
            );
          })}
          <br />
          <b>Description</b>
          <Typography variant="body2">{item.description}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ItemMuiCard;
