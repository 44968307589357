import { gql } from "@apollo/react-hooks";
import {
  AdminUser,
  AdminUserSearchOption,
  UserSearchOption,
  LogSearchOption,
  WarehouseSearchOption,
  convertAdminUser,
  convertLog,
  ItemPropertySearchOption,
  NotificationTemplate,
  NotificationMeta,
  UserMemoSearchOption,
  UserMemo,
  convertUserMemo,
  AdminNotiType,
  AdminNoti,
  convertAdminNoti,
  AdminNotiSearchOption,
  UserDeviceSearchOption,
  FcmMessage,
  FcmMessageSearchOption,
  convertFcmMessage,
  FcmPushSearchOption,
} from "../types/adm_elements";
import {
  Item,
  Log,
  SearchResult,
  SimpleResult,
  Warehouse,
  convertWarehouse,
  ItemProperty,
  convertItemProperty,
  ItemSearchOption,
  User,
  convertUser,
  OrderSearchOption,
  Order,
  StatusCount,
  ItemOption,
  ItemStock,
  ItemStockSearchOption,
  ItemVisible,
  BannerSearchOption,
  convertBanner,
  Banner,
  ReservationSearchOption,
  Reservation,
  convertReservation,
  ImageSearchOption,
  Image,
  convertImage,
  Constant,
  ConstantSearchOption,
  OrderWaitingSearchOption,
  OrderWaiting,
  convertOrder,
  convertItemOption,
  convertItem,
  OrderedItem,
  convertItemStock,
  convertOrderedItem,
  OrderStatus,
  OrderedItemSearchOption,
  convertOrderWaiting,
  ReserveSlotSearchOption,
  ReserveSlot,
  convertReserveSlot,
  ReservationStatus,
  ItemMetaSearchOption,
  ItemMeta,
  convertItemMeta,
  ItemLabel,
  UserDevice,
  convertUserDevice,
} from "../types/elements";
import { client } from "./client";
import {
  getDBConnectedCount,
  getDBMaxConnCount,
  getFcmPushs,
  getFcmPushUsers,
  searchUserAlertInfo,
} from "./gqls/gaphql_admin_resolver";
import {
  ConsignmentApi,
  MultiImageApi,
  ServiceRequestApi,
  RentalApi,
  StylingApi,
  AdminUserApi,
  FcmPushApi,
} from "./gqls/graphql_admin_api";
import {
  getIamportToken,
  getIamportPaymentsStatus,
  getIamportPaymentsMid,
} from "./gqls/graphql_apis";
import { BoardApi } from "./gqls/graphql_board_api";
import { OrderApi, changeOrderPrice, syncOrderPrice, deleteOrder } from "./gqls/graphql_order_api";
import { UserApi, UserQueryApi } from "./gqls/graphql_user_api";

interface QueryIF {
  item?: Item;
  items?: SearchResult<Item>;
  itemPropertyDistinct?: string[];
  admin?: {
    adminUser?: AdminUser;
    adminNotis?: SearchResult<AdminNoti>;
    adminUsers?: SearchResult<AdminUser>;
    banners?: SearchResult<Banner>;
    constants?: SearchResult<Constant>;
    fcmMessages?: SearchResult<FcmMessage>;
    images?: SearchResult<Image>;
    itemMetas?: SearchResult<ItemMeta>;
    item?: Item;
    itemOption?: ItemOption;
    itemProperties?: ItemProperty[];
    itemStocks?: SearchResult<ItemStock>;
    logs?: SearchResult<Log>;
    notificationTemplates?: SearchResult<NotificationTemplate>;
    notificationMetas?: NotificationMeta[];
    order?: Order;
    orders?: SearchResult<Order>;
    orderWaitings?: SearchResult<OrderWaiting>;
    orderedItems?: SearchResult<OrderedItem>;
    orderStatusCounts?: StatusCount[];
    users?: SearchResult<User>;
    userDevices?: SearchResult<UserDevice>;
    userMemos?: SearchResult<UserMemo>;
    reservations?: SearchResult<Reservation>;
    reserveSlots?: ReserveSlot[];
    warehouses?: SearchResult<Warehouse>;
  };
}

interface MutationIF {
  postAdminLoginRequest?: SimpleResult;
  postAdminLoginSubmit?: SimpleResult;

  saveItemProperty?: SimpleResult;
  removeItemProperty?: SimpleResult;

  saveItemImage?: SimpleResult;
  removeItemImage?: SimpleResult;
  admin?: {
    logout?: SimpleResult;
    removeAdminUserTelegramInfo?: SimpleResult;
    applyAdminNoti?: SimpleResult;
    putAdminUser?: AdminUser;
    changeTelegramLoginAllow?: SimpleResult;

    syncStaticConstants?: SimpleResult;
    addConstant?: SimpleResult;

    addUserMemo?: SimpleResult;
    removeUserMemos?: SimpleResult;

    sendPush?: SimpleResult;
    sendPushs?: SimpleResult;

    removeUserDevice?: SimpleResult;

    saveBanner?: SimpleResult;
    updateBannerShow?: SimpleResult;
    updateBannerOnlyShowNonLogin?: SimpleResult;
    removeBanner?: SimpleResult;

    saveItemMeta?: SimpleResult;
    removeItemMeta?: SimpleResult;
    syncItemMetaCategoryIds?: SimpleResult;

    syncItemsStockAvail?: SimpleResult;

    updateItemSortLevel?: SimpleResult;
    updateItemsVisible?: SimpleResult;
    updateItemLabel?: SimpleResult;

    addOrderedItem?: SimpleResult;
    removeOrderedItem?: SimpleResult;

    saveItemOption?: ItemOption;
    setDefaultItemOption?: Item;

    addItem?: SimpleResult;
    saveItem?: Item;
    // Order
    changeOrderStatus?: SimpleResult;
    createOrderByOrderWaiting?: SimpleResult;
    // Image
    createImageThumbnail?: SimpleResult;

    // ReserveSlot
    saveReserveSlot?: SimpleResult;
    deleteReserveSlot?: SimpleResult;
    cleanReserveSlotsByDate?: SimpleResult;
    updateReservationStatus?: SimpleResult;

    deleteLogsByIds?: SimpleResult;
    deleteLogsDayBefore?: SimpleResult;
  };
}

export const gqladm = {
  removeUserDevice: async (id: number): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveUserDevice($id: BigInt) {
            admin {
              removeUserDevice(id: $id) {
                ok
                count
              }
            }
          }
        `,
        variables: { id },
      })
      .then((result) => {
        if (result.data?.admin?.removeUserDevice) return result.data.admin.removeUserDevice;
        throw new Error("Err");
      }),
  sendPush: (opt: FcmMessage) => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SendPush($opt: FcmMessageInput) {
            admin {
              sendPush(opt: $opt) {
                ok
                id
                count
              }
            }
          }
        `,
        variables: { opt },
      })
      .then((result) => {
        if (result.data?.admin?.sendPushs) return result.data.admin.sendPushs;
        else throw new Error("Error");
      });
  },
  sendPushs: (opt: FcmMessage[]) => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SendPushs($opt: [FcmMessageInput]) {
            admin {
              sendPushs(opt: $opt) {
                ok
                id
                count
                failed
                amount
              }
            }
          }
        `,
        variables: { opt },
      })
      .then((result) => {
        if (result.data?.admin?.sendPushs) return result.data.admin.sendPushs;
        else throw new Error("Error");
      });
  },
  getDBConnectedCount,
  getDBMaxConnCount,
  getFcmPushs,
  getFcmPushUsers,
  getFcmMessages: (sopt: FcmMessageSearchOption) => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetFcmMessages($sopt: FcmMessageSearchInput) {
            admin {
              fcmMessages(sopt: $sopt) {
                total
                offset
                limit
                list {
                  id
                  created
                  updated
                  fcmToken
                  title
                  body
                  dataUrl
                  imageUrl
                  resultKey

                  fcmpush_id
                  usernoti_id
                  user_id
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result.data.admin?.fcmMessages)
          return {
            ...result.data.admin?.fcmMessages,
            list: result.data.admin.fcmMessages.list?.map((r) => convertFcmMessage(r) ?? {}) ?? [],
          };
        else throw new Error("Error");
      });
  },
  putFcmPush: FcmPushApi.putFcmPush,
  delFcmPush: FcmPushApi.delFcmPush,
  delFcmPushUsers: FcmPushApi.delFcmPushUsers,
  addFcmPushUsers: FcmPushApi.addFcmPushUsers,
  addFcmPushUsersFromConds: FcmPushApi.addFcmPushUsersFromConds,
  addFcmPushMessage: FcmPushApi.addFcmPushMessage,
  sendFcmPush: FcmPushApi.sendFcmPush,

  ...UserApi, // ! User
  ...BoardApi, // ! Board
  ...OrderApi, // ! Order
  changeOrderPrice,
  syncOrderPrice,
  deleteOrder,
  ...AdminUserApi, // ! AdminUserApi
  // ! ServiceRequest
  serviceRequests: ServiceRequestApi.serviceRequests,
  // ! Consignment
  consignments: ConsignmentApi.consignments,
  consignmentItem: ConsignmentApi.consignmentItem,
  consignmentItemsByConsignmentId: ConsignmentApi.consignmentItemsByConsignmentId,
  consignmentStatusCounts: ConsignmentApi.consignmentStatusCounts,
  addNewConsignment: ServiceRequestApi.addNewConsignment,
  addNewConsignmentItem: ConsignmentApi.addNewConsignmentItem,
  updateConsignmentStatus: ConsignmentApi.updateConsignmentStatus,
  updateConsignment: ConsignmentApi.updateConsignment,
  updateConsignmentItem: ConsignmentApi.updateConsignmentItem,
  updateConsignmentItemStatus: ConsignmentApi.updateConsignmentItemStatus,
  deleteConsignmentItems: ConsignmentApi.deleteConsignmentItems,
  // ! Rental
  rentals: ServiceRequestApi.rentals,
  rentalStatusCounts: RentalApi.rentalStatusCounts,
  addNewRental: ServiceRequestApi.addNewRental,
  updateRentalStatus: RentalApi.updateRentalStatus,
  updateRental: RentalApi.updateRental,
  // ! Styling
  stylings: ServiceRequestApi.stylings,
  stylingStatusCounts: StylingApi.stylingStatusCounts,
  addNewStyling: ServiceRequestApi.addNewStyling,
  updateStylingStatus: StylingApi.updateStylingStatus,
  updateStyling: StylingApi.updateStyling,
  // ! Etc
  getIamportToken,
  getIamportPaymentsStatus,
  getIamportPaymentsMid,
  // ! Admin
  logout: (): Promise<SimpleResult | undefined> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation Logout {
            admin {
              logout {
                ok
                count
                msg
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data?.admin?.logout) return result.data.admin.logout;
      });
  },
  adminNotis: (sopt: AdminNotiSearchOption): Promise<SearchResult<AdminNoti>> => {
    return client
      .query<QueryIF>({
        query: gql`
          query AdminNotis($sopt: AdminNotiSearchInput) {
            admin {
              adminNotis(sopt: $sopt) {
                total
                limit
                offset
                list {
                  id
                  admin_id
                  msg
                  type
                  path
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result.data.admin?.adminNotis) {
          const ans = result.data.admin.adminNotis;
          return {
            ...ans,
            list: ans?.list ? ans.list.map((r) => convertAdminNoti(r) ?? {}) : [],
          };
        } else throw new Error("Error");
      });
  },
  postAdminLoginRequest: async (phone: string) => {
    return await client
      .mutate<MutationIF>({
        mutation: gql`
          mutation PostAdminLoginRequest($phone: String) {
            postAdminLoginRequest(phone: $phone) {
              ok
              value
            }
          }
        `,
        variables: { phone: phone },
      })
      .then((result) => {
        if (result.data?.postAdminLoginRequest) return result.data.postAdminLoginRequest;
        else throw new Error("Error");
      });
  },
  postAdminLoginSubmit: async (phone: string, check: string, auth: string) => {
    return await client
      .mutate<MutationIF>({
        mutation: gql`
          mutation PostAdminLoginSubmit($phone: String, $check: String, $auth: String) {
            postAdminLoginSubmit(phone: $phone, check: $check, auth: $auth) {
              ok
              value
            }
          }
        `,
        variables: { phone: phone, check: check, auth: auth },
      })
      .then((result) => {
        if (result.data?.postAdminLoginSubmit) return result.data.postAdminLoginSubmit;
        else throw new Error("Error");
      });
  },
  getAdminAuth: async (): Promise<AdminUser | undefined> => {
    return await client
      .query<QueryIF>({
        query: gql`
          query getAdminAuth {
            admin {
              adminUser {
                id
                name
                email
                unreadNotiCount
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data.admin?.adminUser) {
          const ret = convertAdminUser(result.data.admin.adminUser);
          return ret ? ret : result.data.admin.adminUser;
        }
        return;
      });
  },
  getAdminUsers: async (sopt: AdminUserSearchOption): Promise<SearchResult<AdminUser>> => {
    return await client
      .query<QueryIF>({
        query: gql`
          query GetAdminUsers($sopt: AdminUserSearchInput) {
            admin {
              adminUsers(sopt: $sopt) {
                list {
                  id
                  name
                  phone
                  email
                  blocked
                  lastLogined
                  telegramId
                  telegramLoginAllow
                  adminNotiTypes
                }
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then((result) => {
        if (result.data.admin?.adminUsers)
          return {
            ...result.data.admin.adminUsers,
            list: result.data.admin.adminUsers.list
              ? result.data.admin.adminUsers.list.map((r) => {
                  const ret = convertAdminUser(r);
                  return ret ? ret : r;
                })
              : undefined,
          };
        else throw new Error("Error");
      });
  },

  removeAdminUserTelegramInfo: async (adminId: number): Promise<SimpleResult> => {
    return await client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveAdminUserTelegramInfo($adminId: BigInt) {
            admin {
              removeAdminUserTelegramInfo(adminId: $adminId) {
                ok
                count
              }
            }
          }
        `,
        variables: { adminId },
      })
      .then((result) => {
        if (result.data?.admin?.removeAdminUserTelegramInfo)
          return result.data.admin.removeAdminUserTelegramInfo;
        else throw new Error("Error");
      });
  },
  applyAdminNoti: async (admin_id: number, type: AdminNotiType, enabled: boolean) => {
    return await client
      .mutate<MutationIF>({
        mutation: gql`
          mutation ApplyAdminNoti($admin_id: BigInt, $type: String, $enabled: Boolean) {
            admin {
              applyAdminNoti(admin_id: $admin_id, type: $type, enabled: $enabled) {
                ok
                count
              }
            }
          }
        `,
        variables: { admin_id, type, enabled },
      })
      .then((result) => {
        if (result.data?.admin?.applyAdminNoti) return result.data.admin.applyAdminNoti;
        else throw new Error("Error");
      });
  },
  putAdminUser: async (adminUser: AdminUser): Promise<AdminUser> => {
    return await client
      .mutate<MutationIF>({
        mutation: gql`
          mutation PutAdminUser($adminUser: AdminUserInput) {
            admin {
              putAdminUser(adminUser: $adminUser) {
                id
                name
                phone
                email
              }
            }
          }
        `,
        variables: { adminUser: adminUser },
      })
      .then((result) => {
        if (result.data?.admin?.putAdminUser) {
          const ret = convertAdminUser(result.data.admin?.putAdminUser);
          return ret ? ret : result.data.admin.putAdminUser;
        } else throw new Error("Error");
      });
  },
  changeTelegramLoginAllow: async (adminId: number, allow: boolean): Promise<SimpleResult> => {
    return await client
      .mutate<MutationIF>({
        mutation: gql`
          mutation ChangeTelegramLoginAllow($adminId: BigInt, $allow: Boolean) {
            admin {
              changeTelegramLoginAllow(adminId: $adminId, allow: $allow) {
                ok
                count
                msg
              }
            }
          }
        `,
        variables: { adminId, allow },
      })
      .then((result) => {
        if (result.data?.admin?.changeTelegramLoginAllow)
          return result.data?.admin?.changeTelegramLoginAllow;
        else throw new Error("Error");
      });
  },

  // ! Users
  getUsers: UserQueryApi.getUsers,
  // ! UsersByAlertInfo
  getUsersByAlertInfo: UserQueryApi.getUsersByAlertInfo,
  getUserNotis: UserQueryApi.getUserNotis,
  // ! UserDevices
  getUserDevices: (sopt: UserDeviceSearchOption): Promise<SearchResult<UserDevice>> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetUserDevices($sopt: UserDeviceSearchInput) {
            admin {
              userDevices(sopt: $sopt) {
                total
                limit
                offset
                list {
                  id
                  created
                  updated
                  user_id
                  fcmToken
                  os
                  version
                  sessionKey
                  app_version
                  app_buildno
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result.data.admin?.userDevices)
          return {
            ...result.data.admin.userDevices,
            list:
              (result.data.admin.userDevices.list?.map(convertUserDevice) as UserDevice[]) ?? [],
          };
        else throw new Error("Error");
      });
  },

  getUserDevicesWithUser: (sopt: UserDeviceSearchOption): Promise<SearchResult<UserDevice>> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetUserDevices($sopt: UserDeviceSearchInput) {
            admin {
              userDevices(sopt: $sopt) {
                total
                limit
                offset
                list {
                  id
                  created
                  updated
                  user_id
                  fcmToken
                  os
                  version
                  sessionKey
                  app_version
                  app_buildno
                  user {
                    name
                    email
                    phone
                  }
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result.data.admin?.userDevices)
          return {
            ...result.data.admin.userDevices,
            list:
              (result.data.admin.userDevices.list?.map(convertUserDevice) as UserDevice[]) ?? [],
          };
        else throw new Error("Error");
      });
  },

  // ! Log
  getLogs: async (sopt: LogSearchOption): Promise<SearchResult<Log>> => {
    return await client
      .query<QueryIF>({
        query: gql`
          query GetLogs($sopt: LogSearchInput) {
            admin {
              logs(sopt: $sopt) {
                list {
                  id
                  target
                  status
                  created
                  elapsed
                  msg
                  data
                  url
                }
                total
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then((result) => {
        if (result.data.admin?.logs)
          return {
            ...result.data.admin?.logs,
            list: result.data.admin?.logs.list
              ? result.data.admin.logs.list.map((log) => {
                  const ret = convertLog(log);
                  return ret ? ret : log;
                })
              : undefined,
          };
        else throw new Error("Error");
      });
  },
  // ! Warehouse
  getWarehouses: async (sopt: WarehouseSearchOption): Promise<SearchResult<Warehouse>> => {
    return await client
      .query<QueryIF>({
        query: gql`
          query GetWarehouses($sopt: WarehouseSearchInput) {
            admin {
              warehouses(sopt: $sopt) {
                list {
                  id
                  name
                  created
                }
                total
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then((result) => {
        if (result.data.admin?.warehouses)
          return {
            ...result.data.admin.warehouses,
            list: result.data.admin.warehouses.list
              ? result.data.admin.warehouses.list.map((wh) => {
                  const ret = convertWarehouse(wh);
                  return ret ? ret : wh;
                })
              : undefined,
          };
        else throw new Error("Error");
      });
  },
  // MultiImage
  deleteMultiImage: MultiImageApi.deleteMultiImage,
  // ! getItemImages
  getItemImages: async (itemId: number): Promise<Item> => {
    return await client
      .query<QueryIF>({
        query: gql`
          query GetItemImages($itemId: BigInt) {
            item(itemId: $itemId) {
              topImages {
                id
                uid
                name
                url
              }
              images {
                id
                uid
                name
                url
              }
            }
          }
        `,
        variables: { itemId: itemId },
      })
      .then((result) => {
        if (result.data.item) return result.data.item;
        else throw new Error("Error");
      });
  },
  saveItemImage: async (
    itemId: number,
    imageId: number,
    seq: number,
    place: string
  ): Promise<SimpleResult> => {
    return await client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveItemImage($itemId: BigInt, $imageId: BigInt, $seq: BigInt, $place: String) {
            saveItemImage(itemId: $itemId, imageId: $imageId, seq: $seq, place: $place) {
              ok
              count
            }
          }
        `,
        variables: {
          itemId,
          imageId,
          seq,
          place,
        },
      })
      .then((result) => {
        if (result.data?.saveItemImage) return result.data.saveItemImage;
        else throw new Error("Error");
      });
  },
  removeItemImage: async (
    itemId: number,
    imageId: number,
    place: string
  ): Promise<SimpleResult> => {
    return await client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveItemImage($itemId: BigInt, $imageId: BigInt, $place: String) {
            removeItemImage(itemId: $itemId, imageId: $imageId, place: $place) {
              ok
              count
            }
          }
        `,
        variables: { itemId, imageId, place },
      })
      .then((result) => {
        if (result.data?.removeItemImage) return result.data.removeItemImage;
        else throw new Error("Error");
      });
  },
  getItemPropertyDistinct: async (fieldname: string, name?: string): Promise<string[]> => {
    return await client
      .query<QueryIF>({
        query: gql`
          query GetItemPropertyDistinct($fieldname: String, $name: String) {
            itemPropertyDistinct(fieldname: $fieldname, name: $name)
          }
        `,
        variables: { fieldname, name },
      })
      .then((result) => {
        if (result.data.itemPropertyDistinct) return result.data.itemPropertyDistinct;
        else throw new Error("Error");
      });
  },
  getItemProperties: async (sopt: ItemPropertySearchOption): Promise<ItemProperty[]> => {
    return await client
      .query<QueryIF>({
        query: gql`
          query GetItemProperties($sopt: ItemPropertySearchInput) {
            admin {
              itemProperties(sopt: $sopt) {
                id
                item_id
                seq
                name
                value
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then((result) => {
        if (result.data?.admin?.itemProperties)
          return result.data.admin.itemProperties.map((ip) => {
            const ret = convertItemProperty(ip);
            return ret ? ret : ip;
          });
        else throw new Error("Error");
      });
  },
  saveItemProperty: async (
    itemId: number,
    seq?: number,
    name?: string,
    value?: string
  ): Promise<SimpleResult> => {
    return await client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveItemProperty($itemId: BigInt, $seq: Int, $name: String, $value: String) {
            saveItemProperty(itemId: $itemId, seq: $seq, name: $name, value: $value) {
              ok
              count
              id
            }
          }
        `,
        variables: {
          itemId,
          seq,
          name,
          value,
        },
      })
      .then((result) => {
        if (result.data?.saveItemProperty) return result.data.saveItemProperty;
        else throw new Error("Error");
      });
  },
  removeItemProperty: async (id: number): Promise<SimpleResult> => {
    return await client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveItemProperty($id: BigInt) {
            removeItemProperty(id: $id) {
              ok
              count
              id
            }
          }
        `,
        variables: { id: id },
      })
      .then((result) => {
        if (result.data?.removeItemProperty) return result.data.removeItemProperty;
        else throw new Error("Error");
      });
  },
  getItems: async (sopt: ItemSearchOption): Promise<SearchResult<Item>> => {
    return await client
      .query<QueryIF>({
        query: gql`
          query GetItemList($sopt: ItemSearchInput) {
            items(sopt: $sopt) {
              total
              offset
              limit
              list {
                id
                created
                updated
                type
                visible
                status
                priceOpen
                price
                priceBefore
                vatAdded
                defaultItemOptionId

                isLabelConsignment
                isLabelLastOne

                sort_level
                search_text
                stock_avail

                name
                description
                hashtag

                category_ids
                category_id
                brand_id
                designer_id

                sh_desc
                sh_hashtag
                sh_itemoptions

                parent_id

                fcm_sent

                topImages {
                  id
                  name
                  url
                  type
                }
                itemOptions {
                  id
                  count_total
                  count_ordered
                }
                itemOption {
                  id
                }
                discountRate
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then((result) => {
        if (result.data.items)
          return {
            ...result.data.items,
            list: (result?.data?.items?.list ?? []).map((it) => convertItem(it) ?? {}),
          };
        else throw new Error("Error");
      });
  },
  getItemsWithFields: async (
    sopt: ItemSearchOption,
    fields: string[]
  ): Promise<SearchResult<Item>> => {
    return await client
      .query<QueryIF>({
        query: gql`
          query GetItemList($sopt: ItemSearchInput) {
            items(sopt: $sopt) {
              total
              offset
              limit
              list {
                ${fields.join(" ")}               
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then((result) => {
        if (result.data.items)
          return {
            ...result.data.items,
            list: (result?.data?.items?.list ?? []).map((it) => convertItem(it) ?? {}),
          };
        else throw new Error("Error");
      });
  },
  // ! Orders
  getOrders: async (sopt: OrderSearchOption): Promise<SearchResult<Order>> => {
    return await client
      .query<QueryIF>({
        query: gql`
          query GetOrders($sopt: OrderSearchInput) {
            admin {
              orders(sopt: $sopt) {
                total
                offset
                limit
                list {
                  id
                  useremail
                  user_id
                  type
                  user {
                    id
                    name
                    email
                    phone
                  }
                  m_id
                  orderItems {
                    id
                    item_id
                    item_option_id
                    count
                    item {
                      name
                      designer_id
                      topImages {
                        url
                      }
                    }
                  }

                  addr_addr1
                  addr_addr2
                  addr_zipcode

                  deliver_msg
                  price
                  status

                  created
                  ordered
                  updated

                  paytype
                  cash_receipt
                  receipt_target
                  receipt_number

                  direct_name
                  credit_card_number
                  credit_approved_id

                  orderedItems {
                    orderId
                    stockId
                    itemId
                    itemOptionId
                    orderedCount
                  }
                }
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then((result) => {
        if (result.data.admin?.orders) return { ...result.data.admin.orders };
        else throw new Error("Error");
      });
  },
  getOrder: async (order_id: number): Promise<Order> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetOrder($order_id: BigInt) {
            admin {
              order(order_id: $order_id) {
                id
                type
                user_id
                user {
                  id
                  name
                  phone
                }
                m_id
                addr_zipcode
                addr_addr1
                addr_addr2
                addr_phone
                addr_username
                orderItems {
                  id
                  order_id
                  item_id
                  item_option_id
                  count

                  item {
                    id
                    name
                    price
                    vatAdded
                    designer_id
                    topImages {
                      url
                    }
                  }
                  itemOption {
                    id
                    name
                    itemId
                    stocks {
                      stockId
                      stockCount
                      warehouseId
                      warehouse {
                        name
                      }
                      orderedItems {
                        orderId
                        orderedCount
                      }
                    }
                  }
                }
                deliver_msg
                price
                status
                created
                ordered
                updated
                paytype
                cash_receipt
                receipt_target
                receipt_number
                direct_name
                credit_card_number
                credit_approved_id
                receipt_c_num
                receipt_c_bn
                receipt_c_rn
                receipt_c_email
                admin_msg
                adminMsgs {
                  id
                  orderId
                  adminId
                  adminMeta {
                    name
                    phone
                    email
                  }

                  msg
                  created
                }

                orderedItems {
                  id
                  orderId
                  stockId
                  orderedCount
                  itemId
                  itemOptionId
                }

                # Extra function value
                isOrderedDone
              }
            }
          }
        `,
        variables: { order_id: order_id },
      })
      .then((result) => {
        const ret = result.data?.admin?.order ? convertOrder(result.data.admin?.order) : undefined;
        if (result?.data?.admin?.order?.orderItems && ret)
          ret.orderItems = result.data.admin.order.orderItems.map((o) => convertItem(o) ?? {});
        if (ret !== undefined) return ret;
        else throw new Error("Error");
      });
  },
  // ! OrderWaiting
  getOrderWaitings: async (sopt: OrderWaitingSearchOption): Promise<SearchResult<OrderWaiting>> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetOrderWaitings($sopt: OrderWaitingSearchInput) {
            admin {
              orderWaitings(sopt: $sopt) {
                offset
                limit
                total
                list {
                  id
                  created
                  ow_status
                  m_id
                  useremail
                  userphone
                  username
                  addr_zipcode
                  addr_addr1
                  addr_addr2
                  addr_username
                  deliver_msg
                  price

                  receipt_c_num
                  receipt_c_bn
                  receipt_c_rn
                  receipt_c_email

                  orderWaitingItems {
                    id
                    count
                    item_id
                    item_option_id
                    item {
                      id
                      name
                      price
                      topImages {
                        url
                      }
                    }
                    itemOption {
                      id
                      name
                      stock_avail
                    }
                  }
                  user {
                    name
                  }
                  order {
                    id
                    m_id
                  }
                }
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then((result) => {
        if (result.data.admin?.orderWaitings) return result.data.admin.orderWaitings;
        else throw new Error("Error");
      });
  },
  //  ! NotificationTemplates
  getNotificationTemplates: async (): Promise<{
    notificationTemplates: SearchResult<NotificationTemplate>;
    notificationMetas: NotificationMeta[];
  }> => {
    return await client
      .query<QueryIF>({
        query: gql`
          query GetNotificationTemplates {
            admin {
              notificationTemplates {
                total
                offset
                limit
                list {
                  id
                  datatype
                  enabledEmail
                  enabledSms
                  name
                  title
                  body
                  sms
                  type
                  created
                  updated
                }
              }
              notificationMetas {
                name
                meta
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data.admin?.notificationTemplates && result.data.admin?.notificationMetas)
          return {
            notificationTemplates: result.data.admin?.notificationTemplates,
            notificationMetas: result.data.admin?.notificationMetas,
          };
        else throw new Error("Error");
      });
  },

  getOrderStatusCounts: async (): Promise<StatusCount[]> => {
    return await client
      .query<QueryIF>({
        query: gql`
          query GetOrderStatusCount {
            admin {
              orderStatusCounts {
                status
                count
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data.admin?.orderStatusCounts)
          return result.data.admin.orderStatusCounts.map((o) => {
            return {
              status: o.status,
              count: o.count !== undefined ? Number(o.count) : 0,
            } as StatusCount;
          });
        else throw new Error("Error");
      });
  },

  getItem: (itemId: number): Promise<Item> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetItem($id: BigInt) {
            admin {
              item(id: $id) {
                id
                created
                updated
                type
                visible
                status
                priceOpen
                price
                priceBefore
                vatAdded
                defaultItemOptionId

                sort_level
                search_text
                stock_avail

                name
                description
                hashtag

                category_ids
                category_id
                brand_id
                designer_id

                sh_desc
                sh_hashtag
                sh_itemoptions

                parent_id

                private_users

                discountRate
                itemOptions {
                  id
                  itemId
                  name
                  status
                  stock_avail

                  count_total
                  count_ordered
                  count_order

                  stocks {
                    itemId
                    itemOptionId
                    stockId
                    stockCount
                    warehouseId
                    warehouse {
                      name
                    }
                  }
                }
                properties {
                  name
                  value
                }
                images {
                  id
                  name
                  url
                  type
                }
                topImages {
                  id
                  name
                  url
                  type
                }
              }
            }
          }
        `,
        variables: { id: itemId },
      })
      .then((result) => {
        if (result.data?.admin?.item) return result.data.admin.item;
        else throw new Error("Error");
      });
  },
  getItemOption: (id: number): Promise<ItemOption> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetItemStock($id: BigInt) {
            admin {
              itemOption(id: $id) {
                id
                name
                item {
                  id
                  name
                  price
                }
              }
            }
          }
        `,
        variables: { id: id },
      })
      .then((result) => {
        const ret = result.data?.admin?.itemOption
          ? convertItemOption(result.data.admin.itemOption)
          : undefined;
        if (ret !== undefined && ret.item !== undefined) ret.item = convertItem(ret.item);
        if (ret !== undefined) return ret;
        else throw new Error("Error");
      });
  },
  getItemOptions: (itemId: number): Promise<ItemOption[]> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetItem($id: BigInt) {
            admin {
              item(id: $id) {
                itemOptions {
                  id
                  itemId
                  name
                  status
                  stock_avail

                  count_total
                  count_ordered
                  count_order
                }
              }
            }
          }
        `,
        variables: { id: itemId },
      })
      .then((result) => {
        if (result.data?.admin?.item?.itemOptions) return result.data.admin.item.itemOptions;
        else throw new Error("Error");
      });
  },
  getItemStocks: (itemOptionId: number): Promise<ItemStock[]> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetItemStock($id: BigInt) {
            admin {
              itemOption(id: $id) {
                stocks {
                  stockId
                  warehouseId
                  warehouse {
                    name
                  }
                  stockCount
                  created
                  orderedItems {
                    id
                    orderId
                    orderedCount
                    created
                    stockId
                    memo
                  }
                }
              }
            }
          }
        `,
        variables: { id: itemOptionId },
      })
      .then((result) => {
        if (result.data?.admin?.itemOption?.stocks)
          return result.data.admin.itemOption?.stocks.map((o) => {
            const ret = convertItemStock(o);
            if (ret) {
              ret.orderedItems = ret.orderedItems?.map((oi) => {
                const oiret = convertOrderedItem(oi);
                return oiret ? oiret : {};
              });
              return ret;
            } else return {};
          });
        else throw new Error("Error");
      });
  },

  updateItemSortLevel: (item_id: number, sort_level: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation UpdateItemSortLevel($item_id: BigInt, $sort_level: Int) {
            admin {
              updateItemSortLevel(item_id: $item_id, sort_level: $sort_level) {
                ok
                count
              }
            }
          }
        `,
        variables: { item_id, sort_level },
      })
      .then((result) => {
        if (result.data?.admin?.updateItemSortLevel) return result.data.admin.updateItemSortLevel;
        else throw new Error("Error");
      });
  },
  updateItemsVisible: (item_ids: number[], visible: ItemVisible): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation UpdateItemsVisible($item_ids: [BigInt], $visible: String) {
            admin {
              updateItemsVisible(item_ids: $item_ids, visible: $visible) {
                ok
                count
              }
            }
          }
        `,
        variables: { item_ids, visible },
      })
      .then((result) => {
        if (result.data?.admin?.updateItemsVisible) return result.data.admin.updateItemsVisible;
        else throw new Error("Error");
      });
  },
  updateItemLabel: (
    item_id: number,
    label_name: ItemLabel,
    enable: boolean
  ): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation UpdateItemLabel($item_id: BigInt, $label_name: String, $enable: Boolean) {
            admin {
              updateItemLabel(item_id: $item_id, label_name: $label_name, enable: $enable) {
                ok
                count
              }
            }
          }
        `,
        variables: { item_id, label_name, enable },
      })
      .then((result) => {
        if (result.data?.admin?.updateItemLabel) return result.data?.admin?.updateItemLabel;
        else throw new Error("Error");
      });
  },

  getBanners(sopt: BannerSearchOption): Promise<SearchResult<Banner>> {
    return client
      .query<QueryIF>({
        query: gql`
          query GetBanners($sopt: BannerSearchInput) {
            admin {
              banners(sopt: $sopt) {
                total
                offset
                limit
                list {
                  id
                  created
                  show
                  type
                  msg
                  url
                  onlyShowNonLogin
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result.data?.admin?.banners)
          return {
            ...result.data.admin.banners,
            list: result.data.admin.banners.list?.map(convertBanner),
          } as SearchResult<Banner>;
        else throw new Error("Error");
      });
  },

  saveBanner(banner: Banner): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveBanner($banner: BannerInput) {
            admin {
              saveBanner(banner: $banner) {
                ok
                count
              }
            }
          }
        `,
        variables: { banner },
      })
      .then((result) => {
        if (result.data?.admin?.saveBanner) return result.data.admin.saveBanner;
        else throw new Error("Error");
      });
  },
  updateBannerShow(id: number, show: boolean): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation updateBannerShow($id: BigInt, $show: Boolean) {
            admin {
              updateBannerShow(id: $id, show: $show) {
                ok
                count
              }
            }
          }
        `,
        variables: { id, show },
      })
      .then((result) => {
        if (result.data?.admin?.updateBannerShow) return result.data.admin.updateBannerShow;
        else throw new Error("Error");
      });
  },
  updateBannerOnlyShowNonLogin(id: number, onlyShowNonLogin: boolean): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation UpdateBannerOnlyShowNonLogin($id: BigInt, $onlyShowNonLogin: Boolean) {
            admin {
              updateBannerOnlyShowNonLogin(id: $id, onlyShowNonLogin: $onlyShowNonLogin) {
                ok
                count
              }
            }
          }
        `,
        variables: { id, onlyShowNonLogin },
      })
      .then((result) => {
        if (result.data?.admin?.updateBannerOnlyShowNonLogin)
          return result.data.admin.updateBannerOnlyShowNonLogin;
        else throw new Error("Error");
      });
  },
  removeBanner(id: number): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveBanner($id: BigInt) {
            admin {
              removeBanner(id: $id) {
                ok
                count
              }
            }
          }
        `,
        variables: { id },
      })
      .then((result) => {
        if (result.data?.admin?.removeBanner) return result.data.admin.removeBanner;
        else throw new Error("Error");
      });
  },
  addUserMemo(user_id: number, memo: string): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveUserMemo($user_id: BigInt, $memo: String) {
            admin {
              addUserMemo(user_id: $user_id, memo: $memo) {
                ok
                count
              }
            }
          }
        `,
        variables: { user_id, memo },
      })
      .then((result) => {
        if (result.data?.admin?.addUserMemo) return result.data.admin.addUserMemo;
        else throw new Error("Error");
      });
  },
  removeUserMemos(user_ids: number[]): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveUserMemo($user_ids: [BigInt]) {
            admin {
              removeUserMemos(user_ids: $user_ids) {
                ok
                count
              }
            }
          }
        `,
        variables: { user_ids },
      })
      .then((result) => {
        if (result.data?.admin?.removeUserMemos) return result.data.admin.removeUserMemos;
        else throw new Error("Error");
      });
  },
  searchUserMemo(sopt: UserMemoSearchOption): Promise<SearchResult<UserMemo>> {
    return client
      .query<QueryIF>({
        query: gql`
          query SearchUserMemo($sopt: UserMemoSearchInput) {
            admin {
              userMemos(sopt: $sopt) {
                offset
                limit
                total
                list {
                  id
                  created
                  memo
                  user_id
                  admin_id
                  admin {
                    name
                  }
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result.data.admin?.userMemos)
          return {
            ...result.data.admin.userMemos,
            list: result.data.admin.userMemos.list
              ? (result.data.admin.userMemos.list.map(convertUserMemo) as UserMemo[])
              : [],
          };
        else throw new Error("Error");
      });
  },
  searchUserAlertInfo,
  searchReservation(sopt: ReservationSearchOption): Promise<SearchResult<Reservation>> {
    return client
      .query<QueryIF>({
        query: gql`
          query SearchReservation($sopt: ReservationSearchInput) {
            admin {
              reservations(sopt: $sopt) {
                offset
                limit
                total
                list {
                  id
                  created
                  user_id
                  email
                  name
                  phone
                  date
                  time
                  status
                  memberCount
                  memo
                  user {
                    name
                    num_order_done_count
                    userMemos(sopt: { limit: 1 }) {
                      limit
                      total
                      offset
                      list {
                        memo
                      }
                    }
                  }
                  reservedCount
                  noShowCount
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result.data.admin?.reservations)
          return {
            ...result.data.admin.reservations,
            list: result.data.admin.reservations.list
              ? (result.data.admin.reservations.list.map(convertReservation) as Reservation[])
              : [],
          };
        else throw new Error("Error");
      });
  },
  syncStaticConstants(): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation syncStaticConstants {
            admin {
              syncStaticConstants {
                ok
                count
                value
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data?.admin?.syncStaticConstants) return result.data.admin.syncStaticConstants;
        else throw new Error("Error");
      });
  },
  addConstant(constant: Constant): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddConstant($constant: ConstantInput) {
            admin {
              addConstant(constant: $constant) {
                ok
                count
                id
              }
            }
          }
        `,
        variables: { constant: constant },
      })
      .then((result) => {
        if (result.data?.admin?.addConstant) return result.data.admin.addConstant;
        else throw new Error("Error");
      });
  },
  searchConstants(sopt: ConstantSearchOption): Promise<SearchResult<Constant>> {
    return client
      .query<QueryIF>({
        query: gql`
          query SearchConstants($sopt: ConstantSearchInput) {
            admin {
              constants(sopt: $sopt) {
                limit
                total
                offset
                list {
                  id
                  created
                  key
                  value
                  isPublic
                  isJson
                }
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then((result) => {
        console.log("searchConstants", sopt);
        if (result.data.admin?.constants) return result.data.admin.constants;
        else throw new Error("Error");
      });
  },

  searchImages(sopt: ImageSearchOption): Promise<SearchResult<Image>> {
    return client
      .query<QueryIF>({
        query: gql`
          query SearchImages($sopt: ImageSearchInput) {
            admin {
              images(sopt: $sopt) {
                offset
                limit
                total
                list {
                  id
                  name
                  url
                  type
                  itemId
                  modified
                  mi {
                    image_id
                    target
                    targetId
                  }
                }
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then((result) => {
        if (result.data.admin?.images)
          return {
            ...result.data.admin.images,
            list: result.data.admin.images.list
              ? (result.data.admin.images.list.map(convertImage) as Image[])
              : [],
          };
        else throw new Error("Error");
      });
  },
  saveItemOption(itemOption: ItemOption): Promise<ItemOption> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveItemOption($itemOption: ItemOptionInput) {
            admin {
              saveItemOption(itemOption: $itemOption) {
                id
                itemId
                name
                status
              }
            }
          }
        `,
        variables: { itemOption: itemOption },
      })
      .then((result) => {
        if (result.data?.admin?.saveItemOption) {
          const ret = convertItemOption(result.data.admin.saveItemOption);
          return ret ? ret : {};
        } else throw new Error("Error");
      });
  },
  setDefaultItemOption(itemId: number, itemOptionId: number): Promise<Item> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SetDefaultItemOption($itemId: BigInt, $itemOptionId: BigInt) {
            admin {
              setDefaultItemOption(itemId: $itemId, itemOptionId: $itemOptionId) {
                id
                defaultItemOptionId
              }
            }
          }
        `,
        variables: { itemId: itemId, itemOptionId: itemOptionId },
      })
      .then((result) => {
        if (result.data?.admin?.setDefaultItemOption) {
          const ret = convertItem(result.data.admin.setDefaultItemOption);
          return ret ? ret : {};
        } else throw new Error("Error");
      });
  },
  addItem(): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddItem {
            admin {
              addItem {
                ok
                id
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data?.admin?.addItem) {
          const ret = convertItem(result.data.admin.addItem);
          return ret ? ret : {};
        } else throw new Error("Error");
      });
  },
  saveItem(item: Item): Promise<Item> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveItem($item: ItemInput) {
            admin {
              saveItem(item: $item) {
                id
              }
            }
          }
        `,
        variables: { item: item },
      })
      .then((result) => {
        if (result.data?.admin?.saveItem) {
          const ret = convertItem(result.data.admin.saveItem);
          return ret ? ret : {};
        } else throw new Error("Error");
      });
  },
  syncItemsStockAvail(item_ids: number[]): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SyncItemsStockAvail($item_ids: [BigInt]) {
            admin {
              syncItemsStockAvail(item_ids: $item_ids) {
                ok
                count
                elapsed
              }
            }
          }
        `,
        variables: { item_ids },
      })
      .then((result) => {
        if (result.data?.admin?.syncItemsStockAvail) return result.data?.admin.syncItemsStockAvail;
        else throw new Error("Error");
      });
  },
  // syncItemOptionStockAvail(itemOptionId: number): Promise<number> {
  //   return client
  //     .mutate<MutationIF>({
  //       mutation: gql`
  //         mutation SyncItemOptionStockAvail($itemOptionId: BigInt) {
  //           admin {
  //             syncItemOptionStockAvail(itemOptionId: $itemOptionId)
  //           }
  //         }
  //       `,
  //       variables: { itemOptionId: itemOptionId },
  //     })
  //     .then((result) => {
  //       if (result.data?.admin?.syncItemOptionStockAvail !== undefined)
  //         return result.data?.admin.syncItemOptionStockAvail;
  //       else throw new Error("Error");
  //     });
  // },
  // syncItemStockAvail(itemId: number): Promise<number> {
  //   return client
  //     .mutate<MutationIF>({
  //       mutation: gql`
  //         mutation SyncItemStockAvail($itemId: BigInt) {
  //           admin {
  //             syncItemStockAvail(itemId: $itemId)
  //           }
  //         }
  //       `,
  //       variables: { itemId: itemId },
  //     })
  //     .then((result) => {
  //       if (result.data?.admin?.syncItemStockAvail !== undefined)
  //         return result.data?.admin.syncItemStockAvail;
  //       else throw new Error("Error");
  //     });
  // },
  addOrderedItem(orderedItem: OrderedItem): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddOrderedItem($orderedItem: OrderedItemInput) {
            admin {
              addOrderedItem(orderedItem: $orderedItem) {
                ok
                count
              }
            }
          }
        `,
        variables: { orderedItem: orderedItem },
      })
      .then((result) => {
        if (result.data?.admin?.addOrderedItem) return result.data.admin.addOrderedItem;
        else throw new Error("Error");
      });
  },
  removeOrderedItem(id: number): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveOrderedItem($id: BigInt) {
            admin {
              removeOrderedItem(id: $id) {
                ok
                count
              }
            }
          }
        `,
        variables: { id: id },
      })
      .then((result) => {
        if (result.data?.admin?.removeOrderedItem) return result.data.admin.removeOrderedItem;
        else throw new Error("Error");
      });
  },
  changeOrderStatus(orderId: number, status: OrderStatus): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation ChangeOrderStatus($orderId: BigInt, $status: String) {
            admin {
              changeOrderStatus(orderId: $orderId, status: $status) {
                ok
                count
              }
            }
          }
        `,
        variables: { orderId: orderId, status: status },
      })
      .then((result) => {
        if (result.data?.admin?.changeOrderStatus) return result.data.admin.changeOrderStatus;
        else throw new Error("Error");
      });
  },
  createOrderByOrderWaiting(orderWaitingId: number, status: OrderStatus): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation CreateOrderByOrderWaiting($orderWaitingId: BigInt, $status: String) {
            admin {
              createOrderByOrderWaiting(orderWaitingId: $orderWaitingId, status: $status) {
                ok
                count
              }
            }
          }
        `,
        variables: { orderWaitingId: orderWaitingId, status: status },
      })
      .then((result) => {
        if (result.data?.admin?.createOrderByOrderWaiting)
          return result.data.admin.createOrderByOrderWaiting;
        else throw new Error("Error");
      });
  },
  searchItemStocks(sopt: ItemStockSearchOption): Promise<SearchResult<ItemStock>> {
    return client
      .query<QueryIF>({
        query: gql`
          query SearchItemStocks($sopt: ItemStockSearchInput) {
            admin {
              itemStocks(sopt: $sopt) {
                limit
                offset
                total
                list {
                  stockId
                  itemId
                  item {
                    name
                    topImages {
                      url
                    }
                  }
                  itemOptionId
                  itemOption {
                    id
                    name
                    itemId
                    item {
                      id
                      topImages {
                        url
                      }
                    }
                  }
                  stockCount
                  orderedItems {
                    orderedCount
                  }
                  warehouseId
                  warehouse {
                    name
                  }
                  created
                  memo
                }
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then((result) => {
        if (result?.data?.admin?.itemStocks)
          return {
            ...result.data.admin.itemStocks,
            list: result.data.admin.itemStocks.list?.map(convertItemStock) as ItemStock[],
          };
        else throw new Error("Error");
      });
  },
  searchOrderedItems(sopt: OrderedItemSearchOption): Promise<SearchResult<OrderedItem>> {
    return client
      .query<QueryIF>({
        query: gql`
          query SearchOrderedItem($sopt: OrderedItemSearchInput) {
            admin {
              orderedItems(sopt: $sopt) {
                limit
                offset
                total
                list {
                  id
                  orderId
                  orderedCount
                  created
                  order {
                    user_id
                  }
                  itemStock {
                    stockId
                    itemOption {
                      name
                      item {
                        id
                        name
                        topImages {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then((result) => {
        if (result?.data?.admin?.orderedItems)
          return {
            ...result.data.admin.orderedItems,
            list: result.data.admin.orderedItems.list?.map(convertOrderedItem) as OrderedItem[],
          };
        else throw new Error("Error");
      });
  },
  getMerchantCheckerData(
    m_id: string
  ): Promise<{ orderResult: SearchResult<Order>; orderWaitingResult: SearchResult<OrderWaiting> }> {
    return client
      .query<QueryIF>({
        query: gql`
          query GetMerchantCheckerData(
            $o_sopt: OrderSearchInput
            $ow_sopt: OrderWaitingSearchInput
          ) {
            admin {
              orders(sopt: $o_sopt) {
                total
                offset
                limit
                list {
                  id
                  status
                  price
                  user_id
                  user {
                    name
                    email
                    phone
                  }
                  useremail
                  username
                  userphone
                  addr_username
                  addr_phone
                  addr_zipcode
                  addr_addr1
                  addr_addr2
                  m_id
                  orderItems {
                    id
                    item_id
                    item_option_id
                    count
                    item {
                      name
                      topImages {
                        url
                      }
                    }
                  }
                  deliver_msg

                  created
                  ordered
                  updated

                  paytype
                  cash_receipt
                  receipt_target
                  receipt_number

                  direct_name
                  credit_card_number
                  credit_approved_id

                  orderedItems {
                    orderId
                    stockId
                    itemId
                    itemOptionId
                    orderedCount
                  }
                }
              }
              orderWaitings(sopt: $ow_sopt) {
                total
                offset
                limit
                list {
                  id
                  price
                  user_id
                  user {
                    name
                  }
                  useremail
                  username
                  userphone
                  addr_username
                  addr_phone
                  addr_zipcode
                  addr_addr1
                  addr_addr2
                  orderWaitingItems {
                    id
                    item_id
                    item_option_id
                    count
                    item {
                      name
                      topImages {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          o_sopt: { m_id: m_id } as OrderSearchOption,
          ow_sopt: { m_id: m_id } as OrderWaitingSearchOption,
        },
      })
      .then((result) => {
        const ret = {
          orderResult: result.data.admin?.orders ? result.data.admin.orders : {},
          orderWaitingResult: result.data.admin?.orderWaitings
            ? result.data.admin.orderWaitings
            : {},
        };
        if (ret.orderResult?.list) {
          ret.orderResult = {
            list: ret.orderResult.list.map((o) => {
              const r = convertOrder(o);
              return r ? r : {};
            }),
            ...ret.orderResult,
          };
        }
        if (ret.orderWaitingResult?.list) {
          ret.orderWaitingResult = {
            list: ret.orderWaitingResult.list.map((o) => {
              const r = convertOrderWaiting(o);
              return r ? r : {};
            }),
            ...ret.orderWaitingResult,
          };
        }
        return ret;
      });
  },
  createImageThumbnail(imageId: number): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation CreateImageThumbnail($imageId: BigInt) {
            admin {
              createImageThumbnail(imageId: $imageId) {
                ok
                count
              }
            }
          }
        `,
        variables: { imageId: imageId },
      })
      .then((result) => {
        if (result.data?.admin?.createImageThumbnail) return result.data.admin.createImageThumbnail;
        return {};
      });
  },
  getReserveSlots(sopt: ReserveSlotSearchOption): Promise<ReserveSlot[]> {
    return client
      .query<QueryIF>({
        query: gql`
          query getReserveSlots($sopt: ReserveSlotSearchInput) {
            admin {
              reserveSlots(sopt: $sopt) {
                date
                time
                place
                show
                status
                count
                created
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then((result) => {
        if (result.data?.admin?.reserveSlots)
          return result.data.admin.reserveSlots.map((o) => {
            const ret = convertReserveSlot(o);
            return ret ? ret : {};
          });
        else return [];
      });
  },
  cleanReserveSlotsByDate(date: string): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation cleanReserveSlotsByDate($date: String) {
            admin {
              cleanReserveSlotsByDate(date: $date) {
                ok
                count
              }
            }
          }
        `,
        variables: { date: date },
      })
      .then((result) => {
        if (result.data?.admin?.cleanReserveSlotsByDate)
          return result.data.admin.cleanReserveSlotsByDate;
        else return {};
      });
  },
  saveReserveSlot(slot: ReserveSlot): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation saveReserveSlot($reserveSlot: ReserveSlotInput) {
            admin {
              saveReserveSlot(reserveSlot: $reserveSlot) {
                ok
                count
              }
            }
          }
        `,
        variables: { reserveSlot: slot },
      })
      .then((result) => {
        if (result.data?.admin?.cleanReserveSlotsByDate)
          return result.data.admin.cleanReserveSlotsByDate;
        else return {};
      });
  },
  updateReservationStatus(id: number, status: ReservationStatus): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation updateReservationStatus($id: BigInt, $status: String) {
            admin {
              updateReservationStatus(id: $id, status: $status) {
                ok
                count
              }
            }
          }
        `,
        variables: { id, status },
      })
      .then((result) => {
        if (result.data?.admin?.updateReservationStatus)
          return result.data?.admin?.updateReservationStatus;
        else return {};
      });
  },
  getItemMetas(sopt: ItemMetaSearchOption): Promise<SearchResult<ItemMeta>> {
    return client
      .query<QueryIF>({
        query: gql`
          query getItemMetas($sopt: ItemMetaSearchInput) {
            admin {
              itemMetas(sopt: $sopt) {
                limit
                offset
                total
                list {
                  id
                  type
                  name
                  desc
                  seq
                  key
                  parent
                  created
                  updated
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result.data?.admin?.itemMetas) {
          const im = result.data?.admin?.itemMetas;
          return { ...im, list: im.list ? im.list.map(convertItemMeta) : [] };
        } else return {};
      });
  },
  saveItemMeta(itemmeta: ItemMeta): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveItemMeta($itemmeta: ItemMetaInput) {
            admin {
              saveItemMeta(itemmeta: $itemmeta) {
                ok
                count
                id
              }
            }
          }
        `,
        variables: { itemmeta },
      })
      .then((result) => {
        if (result.data?.admin?.saveItemMeta) return result.data.admin.saveItemMeta;
        else return {};
      });
  },
  removeItemMeta(id: number): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveItemMeta($id: BigInt) {
            admin {
              removeItemMeta(id: $id) {
                ok
                count
                id
              }
            }
          }
        `,
        variables: { id },
      })
      .then((result) => {
        if (result.data?.admin?.removeItemMeta) return result.data.admin.removeItemMeta;
        else return {};
      });
  },
  syncItemMetaCategoryIds(itemIds: number[]): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SyncItemMetaCategoryIds($itemIds: [BigInt]) {
            admin {
              syncItemMetaCategoryIds(itemIds: $itemIds) {
                ok
                count
                msg
              }
            }
          }
        `,
        variables: { itemIds },
      })
      .then((result) => {
        if (result.data?.admin?.syncItemMetaCategoryIds)
          return result.data.admin.syncItemMetaCategoryIds;
        else return {};
      });
  },

  getItemInStockManage(itemId: number): Promise<Item | undefined> {
    return client
      .query<QueryIF>({
        query: gql`
          query GetItemInStockManage($itemId: BigInt) {
            item(itemId: $itemId) {
              id
              name
              itemOptions {
                id
                name
                status
                total
                ordered
                stocks {
                  stockId
                  warehouseId
                  stockCount
                  warehouse {
                    name
                  }
                  created
                }
              }
            }
          }
        `,
        variables: { itemId },
      })
      .then((result) => {
        if (result.data.item) return convertItem(result.data.item);
      });
  },
  deleteLogsByIds(ids: number[]): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation DeleteLogsByIds($ids: [BigInt]) {
            admin {
              deleteLogsByIds(ids: $ids) {
                ok
                count
                msg
              }
            }
          }
        `,
        variables: { ids },
      })
      .then((result) => {
        if (result.data?.admin?.deleteLogsByIds) return result.data.admin.deleteLogsByIds;
        return {};
      });
  },
  deleteLogsDayBefore(day: number): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation DeleteLogsDayBefore($day: Int) {
            admin {
              deleteLogsDayBefore(day: $day) {
                ok
                count
                msg
              }
            }
          }
        `,
        variables: { day },
      })
      .then((result) => {
        if (result.data?.admin?.deleteLogsDayBefore) return result.data.admin.deleteLogsDayBefore;
        return {};
      });
  },
};
