import {
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import {
  Item,
  ItemLabel,
  ItemPriceOpenArr,
  ItemStatusArr,
  ItemVisibleArr,
  CommonUtil as cu,
} from "../../../types/elements";

import useItemMeta from "../../../hooks/useItemMeta";
import HelpIcon from "../../molecules/HelpIcon";
import WarningIcon from "@mui/icons-material/Warning";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CountOfImageThumbnail from "../../organisms/CountOfImageThumbnail";
import SwitchWithCounter from "../../atoms/SwitchWithCounter";
import FcmPushListByItemDialog from "./FcmPushListByItemDialog";
import ImgSimple from "../../molecules/ImgSimple";

interface ItemResultTableProps {
  selected: number[];
  onToggleAll?: () => any;
  onToggleItem?: (item: Item) => any;

  onToggleLabel?: (item_id: number, label_name: ItemLabel, enable: boolean) => any;
  onChangeSortLevel?: (item: Item) => any;

  items: Item[];
  onOpenItemDetail?: (id?: number, target?: "new") => any;
}
const ItemResultTable: React.FC<ItemResultTableProps> = (props) => {
  const { selected, items: itemList } = props;
  const { getItemMeta } = useItemMeta();

  // FCM Push open
  const [itemIdFcmPush, setItemIdFcmPush] = useState<number | undefined>();
  const openFcmPush = itemIdFcmPush !== undefined;
  const handleCloseFcmPushDialog = (completed?: boolean) => {
    setItemIdFcmPush(undefined);
  };

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={props.selected.length === itemList.length}
                  onClick={props.onToggleAll && props.onToggleAll}
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>
                <div style={{ display: "flex", flexWrap: "wrap" }}>sort</div>
              </TableCell>
              <TableCell align="center">label</TableCell>
              <TableCell align="center">
                category / <b>name</b> / <i>designer</i>
              </TableCell>
              <TableCell align="center">images</TableCell>
              <TableCell align="right">stock</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="center">VAT</TableCell>
              <TableCell align="center">FCM</TableCell>
              <TableCell>Visible</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>PriceOpen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemList.map((item) => {
              const errFields = [];
              if (!(item.status && ItemStatusArr.indexOf(item.status) > -1))
                errFields.push("status");
              if (item.price === undefined) errFields.push("price");
              if (!(item.priceOpen && ItemPriceOpenArr.indexOf(item.priceOpen) > -1))
                errFields.push("priceOpen");
              if (!(item.visible && ItemVisibleArr.indexOf(item.visible) > -1))
                errFields.push("visible");
              if (item.vatAdded === undefined) errFields.push("vatAdded");
              if (item.sh_desc === undefined) errFields.push("sh_desc");
              if (item.sh_hashtag === undefined) errFields.push("sh_hashtag");
              if (item.sh_itemoptions === undefined) errFields.push("sh_itemoptions");
              if (!item.itemOption) errFields.push("디폴트옵션없음");

              const currentCategory = getItemMeta("Category", item.category_id);
              const currentDesigner = getItemMeta("Designer", item.designer_id);

              return (
                <TableRow
                  key={item.id}
                  className={item.id && selected.indexOf(item.id) > -1 ? "item_selected" : ""}>
                  <TableCell>
                    <Checkbox
                      disableRipple
                      checked={item.id && selected.indexOf(item.id) > -1 ? true : false}
                      onChange={props.onToggleItem && props.onToggleItem(item)}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonGroup>
                      <Button
                        onClick={() => props.onOpenItemDetail && props.onOpenItemDetail(item.id)}
                        variant="contained"
                        color="primary">
                        {item.id}
                      </Button>
                      <Button
                        onClick={() =>
                          props.onOpenItemDetail && props.onOpenItemDetail(item.id, "new")
                        }
                        variant="contained"
                        color="secondary"
                        size="small">
                        <OpenInNewIcon />
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={item.sort_level}
                      color="primary"
                      onClick={() => props.onChangeSortLevel && props.onChangeSortLevel(item)}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label="위탁"
                      color={item.isLabelConsignment ? "primary" : "default"}
                      onClick={() =>
                        item.id &&
                        props.onToggleLabel &&
                        props.onToggleLabel(item.id, "Consignment", !item.isLabelConsignment)
                      }
                    />
                    <Chip
                      label="1 Left"
                      color={item.isLabelLastOne ? "primary" : "default"}
                      onClick={() =>
                        item.id &&
                        props.onToggleLabel &&
                        props.onToggleLabel(item.id, "LastOne", !item.isLabelLastOne)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {currentCategory?.treenodes?.map((o) => o.name).join(" > ")}
                    {`[,${currentCategory?.treenodes?.map((o) => o.id).join(",")},]` !==
                      item.category_ids && (
                      <HelpIcon icon={<WarningIcon />}>
                        카테고리 정보가 부정확합니다. 검색에 영향을 줄수 있습니다. 카테고리 정보를
                        다시 저장하십시오
                      </HelpIcon>
                    )}
                    <br />
                    <b>{item.name}</b>
                    {errFields.length > 0 && (
                      <div style={{ color: "red" }}>*상품정보 오류 : {errFields.join(",")}</div>
                    )}

                    <br />
                    <i>{currentDesigner?.name}</i>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <div>
                        <Chip label={<CountOfImageThumbnail images={item.topImages} />} />
                      </div>
                      <div>
                        {item.topImages && item.topImages.length > 0
                          ? item.topImages
                              .filter((o, i) => i === 0)
                              .map((image) => {
                                return (
                                  <ImgSimple
                                    key={image.url}
                                    src={image.url}
                                    style={{ height: "48px" }}
                                  />
                                );
                              })
                          : "-"}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <div style={{ whiteSpace: "nowrap" }}>
                      {/* <Button onClick={() => openItemStock(item.id)}>
                        재고
                        <br />
                        관리
                      </Button>
                      {item.itemOptions &&
                        item.itemOptions
                          .map((o) => (o.total ? o.total : 0) - (o.ordered ? o.ordered : 0))
                          .reduce((p, n) => p + n, 0)}
                      &nbsp; */}
                      {item.stock_avail}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    {cu.numberWithCommas(item.price)}
                    {item.discountRate !== undefined && item.discountRate > 0 && (
                      <span>
                        <span style={{ color: "#f97c00", fontWeight: "bold" }}>
                          {" "}
                          {item.discountRate}%
                        </span>{" "}
                        &nbsp;
                        <span style={{ textDecoration: "line-through" }}>
                          {cu.numberWithCommas(item.priceBefore)}
                        </span>
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.vatAdded ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "lightgray",
                          borderRadius: "1rem",
                          padding: "0.5rem",
                        }}>
                        {/* <FormatIndentIncreaseIcon style={{ transform: `rotate(0.5turn)` }} /> */}
                        <div>Added</div>
                      </div>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {/* <FormatIndentDecreaseIcon style={{ transform: `rotate(0.5turn)` }} /> */}
                        -
                        {/* &nbsp;
                      <div>
                        Not
                        <br />
                        Added
                      </div> */}
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <SwitchWithCounter
                      num={item.fcm_sent}
                      checked={item.fcm_sent ? true : false}
                      onChange={() => setItemIdFcmPush(item.id)}
                    />
                  </TableCell>

                  <TableCell>
                    {item.visible === "OPEN"
                      ? "노출"
                      : item.visible === "CLOSED"
                      ? "비노출"
                      : item.visible === "DELETED"
                      ? "삭제표시"
                      : ""}
                  </TableCell>
                  <TableCell>{item.status === "SOLDOUT" ? "품절표시" : ""}</TableCell>
                  <TableCell>
                    {item.priceOpen === "ASK" ? "문의" : item.priceOpen === "MEMBER" ? "멤버" : ""}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <FcmPushListByItemDialog
        open={openFcmPush}
        item_id={itemIdFcmPush}
        onClose={handleCloseFcmPushDialog}
      />
    </>
  );
};

export default ItemResultTable;
