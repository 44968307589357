import { atom } from "recoil";
import { ItemMetaExtUI } from "../../types/elements";

export interface ItemMetaStateIF {
  topCategory: ItemMetaExtUI[];
  category: ItemMetaExtUI[];
  brand: ItemMetaExtUI[];
  designer: ItemMetaExtUI[];
}

const INIT_ITEM_META_STATE: ItemMetaStateIF = {
  topCategory: [],
  category: [],
  brand: [],
  designer: [],
};

export const ItemMetaState = atom<ItemMetaStateIF>({
  key: "ItemMetaState",
  default: INIT_ITEM_META_STATE,
});
