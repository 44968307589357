import React from "react";
import { Button, TableCell, TableRow } from "@mui/material";
import { Item } from "../../types/elements";
import ImageBox from "./ImageBox";
import useItemMeta from "../../hooks/useItemMeta";

interface ItemTableBodyRowProps {
  item: Item;
  onSelect?: (item: Item) => any;
}
const ItemTableBodyRow: React.FC<ItemTableBodyRowProps> = (props) => {
  const { getItemMeta } = useItemMeta();
  const o = props.item;
  return (
    <TableRow>
      <TableCell style={{ textAlign: "center" }}>
        {o.id}
        <br />
        {props.onSelect && (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => props.onSelect && props.onSelect(o)}>
            선택
          </Button>
        )}
      </TableCell>
      <TableCell>{getItemMeta("Designer", o.designer_id)?.name}</TableCell>
      <TableCell>
        {o.name}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {o.topImages &&
            o.topImages.length > 0 &&
            o.topImages.map((img, idx) => {
              return (
                <div key={idx}>
                  <ImageBox width="64px" height="128px" src={"/" + img.url} />
                </div>
              );
            })}
        </div>
      </TableCell>
      <TableCell>{o.visible}</TableCell>
      <TableCell>{o.status}</TableCell>
      <TableCell>
        {o.price} - {o.priceOpen}
      </TableCell>
    </TableRow>
  );
};

export default ItemTableBodyRow;
