import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { gqladm } from "../../../../graphql/gqladm";
import useSnack from "../../../../hooks/useSnack";
import { Order, CommonUtil as cu } from "../../../../types/elements";

interface ChangeOrderPriceButtonProps {
  order: Order;
  btn_txt?: string;
  reload?: () => void;
}
const ChangeOrderPriceButton: React.FC<ChangeOrderPriceButtonProps> = ({
  order,
  btn_txt,
  reload,
}) => {
  const { openSnack } = useSnack();
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const calculatedPrice = (order.orderItems ?? [])
    .map((oi) =>
      Math.floor((oi.item?.price ?? 0) * (oi.item?.vatAdded ? 1 : 1.1) * (oi.count ?? 1))
    )
    .reduce((p, n) => p + n, 0);

  const [ns, setNs] = useState<string>((order.price ?? 0) + "");
  const [num, setNum] = useState<number | undefined>(order.price);

  const handleSync = () => {
    // 주문가격 현재 상품가격계산으로 동기화 저장
    if (order.id)
      gqladm.syncOrderPrice(order.id).then((result) => {
        if (result.ok) {
          openSnack({ msg: "주문가격이 상품기준 계산된 금액으로 동기화 저장되었습니다." });
          setOpen(false);
          reload && reload();
        } else {
          openSnack({ msg: "에러가 발생했슴니다. " + result.errtype + " " + result.msg });
        }
      });
  };
  const handleChange = () => {
    // 주문가격 현재 수정금액으로 저장
    if (order.id && num !== undefined && !isNaN(num))
      gqladm.changeOrderPrice(order.id, num).then((result) => {
        if (result.ok) {
          openSnack({ msg: "주문가격이 수정되었습니다." });
          setOpen(false);
          reload && reload();
        } else {
          openSnack({ msg: "에러가 발생했슴니다. " + result.errtype + " " + result.msg });
        }
      });
  };

  return (
    <>
      <Button onClick={handleOpen}>{btn_txt ?? "주문금액변경"}</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>주문금액 변경</DialogTitle>
        <DialogContent>
          주문금액 변경 조건 타입 : Offline
          <br /> Order #{order.id} : {order.type}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">상품</TableCell>
                <TableCell align="right">단가</TableCell>
                <TableCell align="right">VAT</TableCell>
                <TableCell align="center">수량</TableCell>
                <TableCell align="right">가격</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.orderItems?.map((oi) => {
                const itemUnitPrice = oi.item?.price ?? 0;
                const itemVatPrice = oi.item?.vatAdded ? 0 : (oi.item?.price ?? 0) * 0.1;
                const itemCalculatedPrice = Math.floor(
                  (oi.item?.price ?? 0) * (oi.item?.vatAdded ? 1 : 1.1) * (oi.count ?? 1)
                );

                return (
                  <TableRow key={"oi_" + oi.id}>
                    <TableCell></TableCell>
                    <TableCell align="right">{cu.numberWithCommas(itemUnitPrice)}</TableCell>
                    <TableCell align="right">
                      {itemVatPrice === 0 ? "" : cu.numberWithCommas(itemVatPrice)}
                    </TableCell>
                    <TableCell align="center">{oi.count}</TableCell>
                    <TableCell align="right">{cu.numberWithCommas(itemCalculatedPrice)}</TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={3}>상품정보기반 계산 가격</TableCell>
                <TableCell>
                  <Button
                    disabled={order.type === "Online" || calculatedPrice === order.price}
                    variant="contained"
                    onClick={() => {
                      setNs(calculatedPrice + "");
                      setNum(calculatedPrice);
                    }}>
                    SET
                  </Button>
                </TableCell>
                <TableCell align="right">{cu.numberWithCommas(calculatedPrice)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4}>주문가격</TableCell>
                <TableCell align="right">{cu.numberWithCommas(order.price ?? 0)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={1}>주문가격 변경</TableCell>
                <TableCell colSpan={3}>
                  <TextField
                    size="small"
                    value={ns}
                    disabled={order.type === "Online"}
                    onChange={(e) => {
                      setNs(e.target.value);
                      const n = Number(e.target.value);
                      if (isNaN(n)) setNum(n);
                      else setNum(n);
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  {isNaN(num ?? 0) ? num : cu.numberWithCommas(num)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={order.type === "Online"} onClick={handleSync}>
            Sync
          </Button>
          <Button
            variant="contained"
            disabled={order.type === "Online" || (num === order.price && order.price !== undefined)}
            onClick={handleChange}>
            변경
          </Button>
          <Button onClick={handleClose}>닫기</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangeOrderPriceButton;
