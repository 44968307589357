import React, { useEffect, useState } from "react";
import DashPanel from "../../molecules/DashPanel";
import { Table, TableBody, TableFooter, TableHead, TableRow, TableCell } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import {
  SearchResult,
  ItemStockSearchOption,
  ItemStock,
  CommonUtil as cu,
} from "../../../types/elements";
import { gqladm } from "../../../graphql/gqladm";
import ItemCard from "../../molecules/ItemCard";

const PanelItemStocks: React.FC = (props) => {
  const [sopt, setSopt] = useState<ItemStockSearchOption>({
    limit: 10,
    offset: 0,
    orderby: "created_desc",
  });
  const [result, setResult] = useState<SearchResult<ItemStock>>({});

  const load = (opt: ItemStockSearchOption) => {
    gqladm
      .searchItemStocks({ ...sopt, ...opt })
      .then((result) => {
        console.log("result");
        setResult(result);
        setSopt({ ...sopt, offset: result.offset ? result.offset : sopt.offset });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (page: number) => {
    console.log("handleChangePage", page);
    const offset = (page - 1) * (sopt.limit ? sopt.limit : 10);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  useEffect(() => {
    load(sopt);
  }, []);

  return (
    <DashPanel title="최근 입고내역" actions={<div></div>}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>상품</TableCell>
            <TableCell>옵션</TableCell>
            <TableCell>수량</TableCell>
            <TableCell>출고됨</TableCell>
            <TableCell>입고일자</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {result.list?.map((o, idx) => {
            const orderedCount = o.orderedItems
              ? o.orderedItems
                  .map((o) => o.orderedCount)
                  .reduce((p, n) => (p ? p : 0) + (n ? n : 0), 0)
              : 0;
            return (
              <TableRow key={idx}>
                <TableCell>{o.stockId}</TableCell>
                <TableCell>
                  {o.itemOption?.item && <ItemCard type="mini" item={o.itemOption.item} />}
                </TableCell>
                <TableCell>{o.itemOption?.name}</TableCell>
                <TableCell>{o.stockCount}</TableCell>
                <TableCell>{orderedCount}</TableCell>
                <TableCell>{o.created && cu.getFormattedDate(o.created, "MM/dd HH:mm")}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={6}>
              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <Pagination
                  onChange={(e, p) => handleChangePage(p)}
                  count={Math.ceil(
                    (result.total ? result.total : 0) / (result?.limit ? result.limit : 10)
                  )}
                  page={
                    Math.ceil(
                      (result?.offset ? result.offset : 0) / (result?.limit ? result.limit : 10)
                    ) + 1
                  }
                />
              </div>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </DashPanel>
  );
};

export default PanelItemStocks;
