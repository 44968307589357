import React from "react";
import { useParams } from "react-router";
import { useAppCommon } from "../../../hooks/useAppCommon";
import FcmPushDetail from "./FcmPushDetail";

interface FcmPushDetailViewProps {}
const FcmPushDetailView: React.FC<FcmPushDetailViewProps> = (props) => {
  useAppCommon({ headerTitle: "FCM Push Detail" });
  const params = useParams<{ fcmPushId: string }>();
  const fcmPushId = Number(params.fcmPushId);

  return (
    <div>
      <FcmPushDetail fcmPushId={fcmPushId} />
    </div>
  );
};

export default FcmPushDetailView;
