import React, { useState, useEffect, useRef } from "react";

import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../../store/states/SnackState";

import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Grid,
  Theme,
  Select,
  Radio,
  FormControlLabel,
} from "@mui/material";

import {
  NotificationTemplate,
  NotificationTest,
  NotificationMeta,
  NotificationType,
} from "../../types/adm_elements";
import { AdminNotificationTemplateAPI } from "../../api/AdminNotificationTemplateAPI";
import { client } from "../../graphql/client";
import {
  MutationIF,
  REMOVE_NOTIFICATION_TEMPLATE,
  SAVE_NOTIFICATION_SETUP,
  SAVE_NOTIFICATION_TEMPLATE,
} from "../../graphql/types";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { gqladm } from "../../graphql/gqladm";
import { useAppCommon } from "../../hooks/useAppCommon";

interface ViewConfig {
  editId?: number;
  editTemplate?: NotificationTemplate;
  editTemplateTitleResult?: string;
  editTemplateBodyResult?: string;
  editTemplateSmsResult?: string;
  editTemplateData?: string;
  sendTestTarget?: string;
  sendTestType?: NotificationType;
}

interface NotificationTemplateViewProps {}

// const useStyles = makeStyles((theme: Theme) => {
//   return {
//     root: {
//       "& .outlined": {
//         border: "1px solid gray",
//         padding: "10px",
//       },
//     },
//   };
// });

const NotificationTemplateView: React.FC<NotificationTemplateViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "Notification Template" });
  // const classes = useStyles();
  const openSnack = useSetRecoilState(SnackStateSelector);

  const [view, setView] = useState<ViewConfig>({});
  const [list, setList] = useState<NotificationTemplate[]>([]);
  const appliedList = list.filter((o) => o.enabledEmail || o.enabledSms);
  const notAppliedList = list.filter((o) => o.enabledEmail !== true && o.enabledSms !== true);
  const [dataMetas, setDataMetas] = useState<NotificationMeta[]>([]);

  const bodyRef = useRef<HTMLInputElement>();
  const smsRef = useRef<HTMLInputElement>();

  const loadTemplates = async () => {
    try {
      const { notificationTemplates, notificationMetas } = await gqladm.getNotificationTemplates();
      if (notificationTemplates.list) setList(notificationTemplates.list);
      if (notificationMetas)
        setDataMetas(
          notificationMetas.map((o) => {
            const ret: NotificationMeta = { name: o.name, meta: JSON.parse(o.meta) };
            return ret;
          })
        );
    } catch (err) {
      openSnack({ msg: "Error on getNotificationTemplates" + err, severity: "error" });
    }
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  useEffect(() => {
    console.log("useEffect view.editId", view.editId);
    const filtered = list.filter((o) => o.id === view.editId);
    if (filtered.length > 0) {
      let dataJson: string = "";
      const template = filtered[0];
      const datatypeFiltered = dataMetas.filter((o) => o.name === template.datatype);
      if (datatypeFiltered.length > 0) {
        dataJson = JSON.stringify(datatypeFiltered[0].meta);
      }
      setView({ ...view, editTemplate: template, editTemplateData: dataJson });
      if (bodyRef.current !== undefined) bodyRef.current.value = "" + template.body;
      if (smsRef.current !== undefined) smsRef.current.value = "" + template.sms;
    }
  }, [view.editId]);

  const applyTemplate = () => {
    const _window: any = window;
    const Handlebars = _window.Handlebars;
    const editedView = { ...view };

    try {
      const template = Handlebars.compile("" + view.editTemplate?.title);
      const data = JSON.parse("" + view.editTemplateData);
      editedView.editTemplateTitleResult = template(data);
    } catch (err) {
      editedView.editTemplateTitleResult = view.editTemplate?.title;
    }

    try {
      const template = Handlebars.compile("" + bodyRef.current?.value);
      const data = JSON.parse("" + view.editTemplateData);
      editedView.editTemplateBodyResult = template(data);
    } catch (err) {
      editedView.editTemplateBodyResult = bodyRef.current?.value;
    }

    try {
      const template = Handlebars.compile("" + smsRef.current?.value);
      const data = JSON.parse("" + view.editTemplateData);
      editedView.editTemplateSmsResult = template(data);
    } catch (err) {
      editedView.editTemplateSmsResult = view.editTemplate?.title;
    }

    setView(editedView);
    return editedView;
  };

  const handleClickNewNotificationTemplate = () => {
    client
      .mutate<MutationIF, { obj: NotificationTemplate }>({
        mutation: SAVE_NOTIFICATION_TEMPLATE,
        variables: { obj: { name: "new templates" } },
      })
      .then((result) => {
        console.log(result);
        const data = result.data;
        if (data?.saveNotificationTemplate)
          setView({ ...data.saveNotificationTemplate, editId: data.saveNotificationTemplate.id });
        loadTemplates();
      })
      .catch((err) =>
        openSnack({ msg: "Error on postNotificationTemplate" + err, severity: "error" })
      );
  };

  const handleClickDelete = (id?: number) => (e: any) => {
    if (!id) return;
    client
      .mutate<MutationIF, { id: number }>({
        mutation: REMOVE_NOTIFICATION_TEMPLATE,
        variables: { id: id },
      })
      .then((result) => {
        console.log("handleClickDelete", result);
        const data = result.data;
        if (data?.removeNotificationTemplate) {
          if (data.removeNotificationTemplate.ok && data.removeNotificationTemplate.count === 1)
            loadTemplates();
          else openSnack({ msg: "Error on handleClickDelete " + data.removeNotificationTemplate });
        }
      })
      .catch((err) => console.log("Error on handleClickDelete", err));
  };

  const handleClickCloseTemplate = () => {
    setView({ ...view, editId: undefined, editTemplate: undefined });
  };
  const handleChangeTemplateName = (e: any) => {
    setView({ ...view, editTemplate: { ...view.editTemplate, name: e.target.value } });
  };
  const handleChangeTemplateTitle = (e: any) => {
    setView({ ...view, editTemplate: { ...view.editTemplate, title: e.target.value } });
  };
  // const handleChangeTemplateType = (e: any, value: any) => {
  //   setView({ ...view, editTemplate: { ...view.editTemplate, type: value } });
  // };
  // const handleChangeTemplateBody = (e: any) => {
  //     setView({ ...view, editTemplate: { ...view.editTemplate, body: e.target.value } })
  // }
  const handleChangeTemplateData = (e: any) => {
    setView({ ...view, editTemplateData: e.target.value });
  };
  const handleChangeDataType = (e: any) => {
    console.log("handleChangeDataType", e.target.value);
    const datatype = e.target.value;
    const filteredDataMeta = dataMetas.filter((o) => o.name === datatype);
    setView({
      ...view,
      editTemplate: { ...view.editTemplate, datatype: datatype },
      editTemplateData: filteredDataMeta.length > 0 ? JSON.stringify(filteredDataMeta[0].meta) : "",
    });
  };

  const handleClickSaveTemplate = (body: NotificationTemplate) => {
    client
      .mutate<MutationIF, { obj: NotificationTemplate }>({
        mutation: SAVE_NOTIFICATION_TEMPLATE,
        variables: {
          obj: {
            id: body.id,
            name: body.name,
            title: body.title,
            body: body.body,
            sms: body.sms,
            datatype: body.datatype,
          },
        },
      })
      .then((result) => {
        const data = result.data;
        if (data?.saveNotificationTemplate) {
          openSnack({ msg: "Email template updated. " });
          loadTemplates();
        }
      })
      .catch((err) => openSnack({ msg: "Update Failed. " + err }));
  };
  const handleChangeSendTestTarget = (e: any) => {
    setView({ ...view, sendTestTarget: e.target.value });
  };
  const handleClickSendTest = () => {
    console.log("handleClickSendTest", view.sendTestTarget, view);
    const applied = applyTemplate();
    const testBody: NotificationTest = {
      type: applied.sendTestType,
      target: applied.sendTestTarget,
      title: applied.editTemplateTitleResult,
      body: applied.editTemplateBodyResult,
      sms: applied.editTemplateSmsResult,
    };
    AdminNotificationTemplateAPI.postNotificationTest(testBody).then((result) => {
      console.log("postNotificationTest", result);
    });
  };

  const [collapseTempalte, setCollapseTemplate] = useState<boolean>(false);
  const [collapseData, setCollapseData] = useState<boolean>(false);
  const [collapseResult, setCollapseResult] = useState<boolean>(false);

  const handleApplySetup = (nt: NotificationTemplate) => (e: any) => {
    console.log(nt);
    client
      .mutate<MutationIF, { obj: NotificationTemplate }>({
        mutation: SAVE_NOTIFICATION_SETUP,
        variables: {
          obj: {
            id: nt.id,
            datatype: nt.datatype,
            enabledEmail: nt.enabledEmail,
            enabledSms: nt.enabledSms,
          },
        },
      })
      .then((result) => {
        console.log("result", result);
        loadTemplates();
      })
      .catch((err) => openSnack({ msg: "Error on handleApplySetup" }));
  };

  return (
    <div
      className={
        "notificationTemplateView"
        // + classes.root
      }>
      {view.editId !== undefined && (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>ID: {view.editId}</div>
            <div>
              <Button
                onClick={() =>
                  view.editId !== undefined &&
                  view.editTemplate !== undefined &&
                  handleClickSaveTemplate({
                    ...view.editTemplate,
                    id: view.editId,
                    body: bodyRef.current?.value,
                    sms: smsRef.current?.value,
                  })
                }
                variant="contained"
                color={"inherit"}>
                Save
              </Button>
              <Button variant="contained" color={"inherit"} onClick={handleClickCloseTemplate}>
                Close
              </Button>
            </div>
            <div>
              <Button variant="contained" color={"primary"} onClick={handleClickSendTest}>
                Send Test
              </Button>
              <FormControlLabel
                label={"EMAIL"}
                control={
                  <Radio
                    checked={view.sendTestType === "email"}
                    onClick={(e) => setView({ ...view, sendTestType: "email" })}
                  />
                }
              />
              <FormControlLabel
                label={"SMS"}
                control={
                  <Radio
                    checked={view.sendTestType === "sms"}
                    onClick={(e) => setView({ ...view, sendTestType: "sms" })}
                  />
                }
              />

              <TextField value={view.sendTestTarget} onChange={handleChangeSendTestTarget} />
            </div>
          </div>
          <br />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>Name : &nbsp;&nbsp;</div>
            <TextField value={view.editTemplate?.name} onChange={handleChangeTemplateName} />
            Applied on {view.editTemplate?.enabledEmail === true ? " Email " : " "}{" "}
            {view.editTemplate?.enabledSms === true ? " SMS " : " "}
          </div>

          <Grid container>
            <Grid xs={12} item>
              <div className="outlined">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>Data (Json)</div>
                  <div>
                    Type:
                    <Select
                      onChange={handleChangeDataType}
                      value={
                        view && view.editTemplate && view.editTemplate.datatype
                          ? view.editTemplate.datatype
                          : ""
                      }>
                      {dataMetas.map((o, metaIdx) => {
                        return (
                          <option key={metaIdx} value={o.name}>
                            {o.name}
                          </option>
                        );
                      })}
                    </Select>
                    <Button onClick={() => setCollapseData(!collapseData)}>
                      {collapseData ? "Expand" : "Collapse"}
                    </Button>
                  </div>
                </div>
                <hr />
                <div style={{ display: collapseData ? "none" : "" }}>
                  <TextField
                    variant={"filled"}
                    value={view.editTemplateData}
                    rows={10}
                    multiline={true}
                    fullWidth
                    onChange={handleChangeTemplateData}
                  />
                </div>
              </div>
            </Grid>
            <Grid xs={12} item>
              <div className="outlined">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>Template</div>
                  <div style={{ display: "flex" }}>
                    <Button onClick={() => setCollapseTemplate(!collapseTempalte)}>
                      {collapseTempalte ? "Expand" : "Collapse"}
                    </Button>
                  </div>
                </div>
                <hr />
                <div style={{ display: collapseTempalte ? "none" : "flex", width: "100%" }}>
                  <div style={{ width: "80%" }}>
                    <TextField
                      label="Email - Subject"
                      variant={"filled"}
                      value={view.editTemplate?.title}
                      onChange={handleChangeTemplateTitle}
                      fullWidth
                    />
                    <TextField
                      label="Email - Body"
                      variant={"filled"}
                      rows={10}
                      multiline={true}
                      fullWidth
                      inputRef={bodyRef}
                      // value={view.editTemplate?.body} onChange={handleChangeTemplateBody}
                    />
                  </div>

                  <div style={{ width: "20%", minWidth: "300px", borderLeft: "1px solid gray" }}>
                    <TextField
                      label="sms"
                      variant={"filled"}
                      rows={13}
                      multiline={true}
                      fullWidth
                      inputRef={smsRef}
                      // value={view.editTemplate?.body} onChange={handleChangeTemplateBody}
                    />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid xs={12} item>
              <div className="outlined">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>Result</div>
                  <div>
                    <Button onClick={() => applyTemplate()}>Apply</Button>
                    <Button onClick={() => setCollapseResult(!collapseResult)}>
                      {collapseResult ? "Expand" : "Collapse"}
                    </Button>
                  </div>
                </div>
                <hr />
                <div style={{ display: collapseResult ? "none" : "flex", width: "100%" }}>
                  <div style={{ width: "80%" }}>
                    <div
                      dangerouslySetInnerHTML={{ __html: "" + view.editTemplateTitleResult }}></div>
                    <hr />
                    <div
                      dangerouslySetInnerHTML={{ __html: "" + view.editTemplateBodyResult }}></div>
                  </div>
                  <div style={{ width: "20%", minWidth: "300px", borderLeft: "1px solid gray" }}>
                    <div
                      dangerouslySetInnerHTML={{ __html: "" + view.editTemplateSmsResult }}></div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      <br />
      <Button variant="contained" color={"inherit"} onClick={handleClickNewNotificationTemplate}>
        New
      </Button>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>data</TableCell>
              <TableCell>applied</TableCell>
              <TableCell>name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={5}>Applied ({appliedList.length})</TableCell>
            </TableRow>
            {appliedList.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.datatype}</TableCell>
                <TableCell>
                  <Button
                    color={item.enabledEmail === true ? "primary" : "secondary"}
                    onClick={handleApplySetup({ ...item, enabledEmail: !item.enabledEmail })}>
                    {item.enabledEmail === true ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}Email
                  </Button>
                  <Button
                    color={item.enabledSms === true ? "primary" : "secondary"}
                    onClick={handleApplySetup({ ...item, enabledSms: !item.enabledSms })}>
                    {item.enabledSms === true ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}SMS
                  </Button>
                </TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  <Button onClick={() => setView({ ...view, editId: item.id })}>Detail</Button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={5}>Not applied ({notAppliedList.length})</TableCell>
            </TableRow>
            {notAppliedList.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.datatype}</TableCell>
                <TableCell>
                  <Button
                    color={item.enabledEmail === true ? "primary" : "secondary"}
                    onClick={handleApplySetup({ ...item, enabledEmail: !item.enabledEmail })}>
                    {item.enabledEmail === true ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}Email
                  </Button>
                  <Button
                    color={item.enabledSms === true ? "primary" : "secondary"}
                    onClick={handleApplySetup({ ...item, enabledSms: !item.enabledSms })}>
                    {item.enabledSms === true ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}SMS
                  </Button>
                </TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  <Button onClick={() => setView({ ...view, editId: item.id })}>Detail</Button>
                  <Button onClick={handleClickDelete(item.id)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default NotificationTemplateView;
