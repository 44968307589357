import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { Order, getKrOrderStatus } from "../../../types/elements";
import { useRecoilValue } from "recoil";
import { AdminAuthState } from "../../../store/states/AdminAuthState";
import { gqladm } from "../../../graphql/gqladm";
import useSnack from "../../../hooks/useSnack";

interface DeleteOrderDialogProps {
  open: boolean;
  onClose: () => void;
  order: Order;
}
const DeleteOrderDialog: React.FC<DeleteOrderDialogProps> = (props) => {
  const adminAuth = useRecoilValue(AdminAuthState);
  const { openSnack } = useSnack();
  const handleDeleteOrder = () => {
    if (props.order.id)
      gqladm
        .deleteOrder(props.order.id)
        .then((result) => {
          if (result.ok) {
            openSnack({ msg: result.msg, severity: "success" });
            props.onClose();
          } else {
            openSnack({ msg: result.msg, severity: "error" });
          }
        })
        .catch((err) => {
          openSnack({ msg: "" + err.message, severity: "error" });
        });
    else openSnack({ msg: "주문번호를 확인할수 없습니다. ", severity: "error" });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>주문 삭제</DialogTitle>
      <DialogContent>
        <b>주문</b><br/>
        주문번호 : {props.order.id}
        <br />
        주문상태 : {getKrOrderStatus(props.order.status)}
        <br />
        주문가격 : {props.order.price}
        <br />
        주문상품 : <br />
        {props.order.orderItems &&
          props.order.orderItems?.map((o) => (
            <>
              {` - #${o.item?.id} - ${o.item?.name} - ${o.item?.price} * ${o.count} `}
              <br />
            </>
          ))}
        <br />
        <br />
        <b>유저</b>
        <br />
        유저번호 : {props.order.user?.id}
        <br />
        유저이름 : {props.order.user?.name}
        <br />
        유저전화번호 : {props.order.user?.phone}
        <br />
        <br />
        <b>관리자정보</b>
        <br />
        관리자ID : {adminAuth.admin.id} <br />
        관리자이름 : {adminAuth.admin.name} <br />
        관리자전화번호 : {adminAuth.admin.phone}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleDeleteOrder}>
          주문 삭제하기 (!이 작업은 되돌릴수 없습니다.)
        </Button>
        <Button onClick={() => props.onClose()}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOrderDialog;
