import {
  Autocomplete,
  Button,
  CircularProgress,
  IconButton,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { gqladm } from "../../../graphql/gqladm";
import { useAppCommon } from "../../../hooks/useAppCommon";
import { useWindowSize } from "../../../hooks/useWindowSize";
import {
  Board,
  BoardBlockType,
  CommonUtil as cu,
  BoardKeyList,
  getThumbUrl,
} from "../../../types/elements";
import BlockDetail from "./detail/BlockDetail";
import BoardDetail from "./detail/BoardDetail";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DatePicker from "../../molecules/DatePicker";

interface BoardDetailViewProps {}
const BoardDetailView: React.FC<BoardDetailViewProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "Board Detail" });
  const navi = useNavigate();

  const params = useParams<{ id: string }>();
  const id = Number(params.id);

  const [board, setBoard] = useState<Board | undefined>();
  const [board_key, setBoardKey] = useState<string>("");
  const [isShow, setIsShow] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [subtitle, setSubtitle] = useState<string>("");
  const [period, setPeriod] = useState<string>("");
  const [periodStart, setPeriodStart] = useState<string>("");
  const [periodEnd, setPeriodEnd] = useState<string>("");

  const [loadingSaveIsShow, setLoadingIsShow] = useState<boolean>(false);
  const [loadingSaveTitle, setLoadingSaveTitle] = useState<boolean>(false);
  const [loadingSaveSubtitle, setLoadingSaveSubtitle] = useState<boolean>(false);

  const load = (id: number) => {
    setBoard(undefined);
    gqladm.getBoard(id).then((result) => {
      if (result) setBoard(result);
      if (result.board_key) setBoardKey(result.board_key);
      if (result.isShow !== undefined) setIsShow(result.isShow);
      if (result.title) setTitle(result.title);
      if (result.subtitle) setSubtitle(result.subtitle);
      if (result.period) setPeriod(result.period);
    });
  };

  useEffect(() => {
    load(id);
  }, [id]);

  const handleChangeBoardKey = (e: any) => {
    setBoardKey(e.target.value);
  };
  const handleSelectBoardKey = (e: any) => {
    setBoardKey(e.target.value);
  };
  const handleSaveBoardKey = () => {
    gqladm.saveBoardKey(id, board_key).then((result) => {
      if (result.ok) load(id);
    });
  };

  const handleSaveIsShow = () => {
    setLoadingIsShow(true);
    gqladm
      .saveBoardIsShow(id, isShow)
      .then((result) => {
        if (result.ok && result.count === 1) load(id);
      })
      .finally(() => setLoadingIsShow(false));
  };
  const handleSaveTitle = () => {
    setLoadingSaveTitle(true);
    gqladm
      .saveBoardTitle(id, title)
      .then((result) => {
        if (result.ok && result.count === 1) load(id);
      })
      .finally(() => setLoadingSaveTitle(false));
  };

  const handleSaveSubtitle = () => {
    setLoadingSaveSubtitle(true);
    gqladm
      .saveBoardSubtitle(id, subtitle)
      .then((result) => {
        if (result.ok && result.count === 1) load(id);
      })
      .finally(() => setLoadingSaveSubtitle(false));
  };

  // Period
  const handleSavePeriod = () => {
    const str = `${periodStart} ~ ${periodEnd}`;
    gqladm.saveBoardPeriod(id, str).then((result) => {
      if (result.ok && result.count === 1) load(id);
    });
  };
  const handleRemovePeriod = () => {
    gqladm.saveBoardPeriod(id, "").then((result) => {
      if (result.ok && result.count === 1) load(id);
    });
  };
  const handleSelectDate = (target: "start" | "end") => (y: number, m: number, d: number) => {
    const date = new Date(y, m, d);
    const startstr = target === "start" ? cu.getFormattedDate(date, "yyyy.MM.dd") : periodStart;
    const endstr = target === "end" ? cu.getFormattedDate(date, "yyyy.MM.dd") : periodEnd;
    const str = (startstr ? startstr : "") + " ~ " + (endstr ? endstr : "");

    setPeriod(str);
    if (target === "start") setPeriodStart(startstr);
    else if (target === "end") setPeriodEnd(endstr);
  };

  const handleSelectThumbnailId = (thumb_image_id: number) => {
    gqladm.saveBoardThumbImageId(id, thumb_image_id).then((result) => {
      if (result.ok) load(id);
    });
  };

  const handleAddBlock = (type: BoardBlockType) => {
    gqladm.addBoardBlock(id, type).then((result) => {
      if (result.ok) load(id);
    });
  };

  return (
    <div>
      <IconButton onClick={() => navi(-1)}>
        <ArrowBackIcon />
      </IconButton>
      {board !== undefined && (
        <>
          <h2>내용수정</h2>
          <div style={{ border: "2px solid gray", padding: "0.5rem" }}>
            <div style={{ display: "flex" }}>
              List Key:
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={board_key}
                options={BoardKeyList}
                onSelect={handleSelectBoardKey}
                onReset={() => setBoardKey("")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    style={{ minWidth: "200px" }}
                    onChange={handleChangeBoardKey}
                  />
                )}
              />
              <Button disabled={(board.board_key ?? "") === board_key} onClick={handleSaveBoardKey}>
                Save
              </Button>
            </div>
            <div>
              Show on List: <Switch value={isShow} onChange={(e, c) => setIsShow(c)} />
              <Button disabled={board.isShow === isShow} onClick={handleSaveIsShow}>
                Save
              </Button>
            </div>

            <div style={{ margin: "1rem 0" }}>
              Title:
              <TextField size="small" value={title} onChange={(e) => setTitle(e.target.value)} />
              {loadingSaveTitle ? (
                <CircularProgress />
              ) : (
                <Button disabled={board.title === title} onClick={handleSaveTitle}>
                  Save
                </Button>
              )}
            </div>
            <div style={{ margin: "1rem 0" }}>
              SubTitle:
              <TextField
                size="small"
                value={subtitle}
                onChange={(e) => setSubtitle(e.target.value)}
              />
              {loadingSaveSubtitle ? (
                <CircularProgress />
              ) : (
                <Button disabled={board.subtitle === subtitle} onClick={handleSaveSubtitle}>
                  Save
                </Button>
              )}
            </div>

            <div>
              Thumbnail Image:
              {board.thumb_image_id && board.thumb_image?.url ? (
                <img
                  src={"/" + getThumbUrl(board.thumb_image.url)}
                  style={{ maxHeight: "5rem", objectFit: "contain" }}
                />
              ) : (
                "없음"
              )}
            </div>
            <div style={{ margin: "1rem 0" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                Period: {board.period}{" "}
                <DatePicker size="small" value={periodStart} onSelect={handleSelectDate("start")} />
                &nbsp;~&nbsp;
                <DatePicker size="small" value={periodEnd} onSelect={handleSelectDate("end")} />
                <Button disabled={board.period === period} onClick={handleSavePeriod}>
                  Save
                </Button>
                <Button onClick={handleRemovePeriod}>Remove</Button>
              </div>
            </div>
            <div>
              블럭들
              {board.blocks?.map((o) => (
                <BlockDetail
                  key={o.id}
                  block={o}
                  onRefresh={() => load(id)}
                  onSelectThumbnaleId={handleSelectThumbnailId}
                />
              ))}
            </div>
            <div>
              <Button onClick={() => handleAddBlock("image")}>이미지 블럭 추가</Button>
              <Button onClick={() => handleAddBlock("text")}>텍스트 블럭 추가</Button>
            </div>
          </div>
        </>
      )}
      {board !== undefined && (
        <>
          <h2>미리보기</h2>
          <div style={{ border: "2px solid gray", padding: "0.5rem" }}>
            <BoardDetail title={board.title} blocks={board.blocks ?? []} period={board.period} />
          </div>
        </>
      )}
    </div>
  );
};

export default BoardDetailView;
