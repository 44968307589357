import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gqladm } from "../../../../graphql/gqladm";
import { useAppCommon } from "../../../../hooks/useAppCommon";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { Consignment, ConsignmentSearchOption } from "../../../../types/elements";
import ConsignmentDetail from "./ConsignmentDetail";
import ConsignmentResultList from "./ConsignmentResultList";
import ConsignmentResultTable from "./ConsignmentResultTable";
import ConsignmentSearchBox from "./ConsignmentSearchBox";

export interface ConsignmentResultProps {
  list: Consignment[];
  openDialog?: (id: number) => any;
  openUser?: (user_id: number) => void;
}

interface ConsignmentListViewProps {}
const ConsignmentListView: React.FC<ConsignmentListViewProps> = (props) => {
  const navi = useNavigate();
  const {} = useAppCommon({ headerTitle: "위탁 리스트" });
  const { isMobile } = useWindowSize();
  const [list, setList] = useState<Consignment[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);

  const [sopt, setSopt] = useState<ConsignmentSearchOption>({ ordering: "newerfirst", limit: 20 });
  const handleSearch = (opt: ConsignmentSearchOption) => {
    load(opt);
  };
  const load = (opt: ConsignmentSearchOption) => {
    gqladm.consignments(opt).then((result) => {
      if (result.list !== undefined) setList(result.list);
      if (result.total !== undefined) setTotal(result.total);
      if (result.offset !== undefined) setOffset(result.offset);
      if (result.limit !== undefined) setLimit(result.limit);
      setSopt((_sopt) => ({ ..._sopt, ...opt }));
    });
  };

  const handleChangePage = (page: number) => {
    console.log("handleChangePage", page);
    const offset = (page - 1) * (sopt.limit ? sopt.limit : 20);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  // for Dialog
  const [consignment_id, setConsignmentId] = useState<number | undefined>();
  const handleOpenDialog = (id: number) => {
    console.log("handleOpenDialog", id);
    setConsignmentId(id);
  };

  const handleSaveDoneConsignment = () => {
    setConsignmentId(undefined);
    load(sopt);
  };

  return (
    <div className="consignmentListView">
      <ConsignmentSearchBox onSearch={handleSearch} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          onChange={(e, p) => handleChangePage(p)}
          count={Math.ceil(total / limit)}
          page={Math.ceil(offset / limit) + 1}
        />
      </div>
      <div>
        {isMobile ? (
          <ConsignmentResultList
            list={list}
            openDialog={handleOpenDialog}
            openUser={(user_id) => navi("/user/" + user_id)}
          />
        ) : (
          <ConsignmentResultTable
            list={list}
            openDialog={handleOpenDialog}
            openUser={(user_id) => navi("/user/" + user_id)}
          />
        )}
        <Dialog open={consignment_id !== undefined} onClose={() => setConsignmentId(undefined)}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            {consignment_id && (
              <ConsignmentDetail id={consignment_id} onSaveDone={handleSaveDoneConsignment} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConsignmentId(undefined)}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ConsignmentListView;
