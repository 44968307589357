import React, { useState, useEffect } from "react";

import Axios from "axios";
import { AuthHistory } from "../../types/adm_elements";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { ItemSearchOption } from "../../types/elements";
import { useAppCommon } from "../../hooks/useAppCommon";

interface AuthHistoryProps {}

const AuthHistoryView: React.FC<AuthHistoryProps> = (props) => {
  const {} = useAppCommon({ headerTitle: "" });

  const [list, setList] = useState<Array<AuthHistory>>([]);

  useEffect(() => {
    const opt: ItemSearchOption = {};

    Axios.get("/admin/authhistory")
      .then((result) => {
        console.log(result);
        setList([...result.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>type</TableCell>
              <TableCell>target</TableCell>
              <TableCell>valid</TableCell>
              <TableCell>authorized</TableCell>
              <TableCell>str_key</TableCell>
              <TableCell>num_key</TableCell>
              <TableCell>awsMessageId</TableCell>
              <TableCell>awsRequestId</TableCell>
              <TableCell>requestedDate</TableCell>
              <TableCell>authorizedDate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell>{item.type}</TableCell>
                <TableCell>{item.target}</TableCell>
                <TableCell>{item.valid === true ? "O" : "X"}</TableCell>
                <TableCell>{item.authorized === true ? "O" : "X"}</TableCell>
                <TableCell>{item.str_key}</TableCell>
                <TableCell>{item.num_key}</TableCell>
                <TableCell>{item.awsMessageId}</TableCell>
                <TableCell>{item.awsRequestId}</TableCell>
                <TableCell>{item.requestedDate}</TableCell>
                <TableCell>{item.authorizedDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>{" "}
    </div>
  );
};

export default AuthHistoryView;
