import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  TextField,
} from "@mui/material";
import { ItemOption, ItemOptionStatus, ItemOptionStatusArr } from "../../types/elements";
import { useSetRecoilState } from "recoil";
import { SnackState } from "../../store/states/SnackState";
import { gqladm } from "../../graphql/gqladm";

interface ItemOptionDialogProps {
  open: boolean;
  onClose: (completed?: boolean) => any;
  editOption?: ItemOption;
  isDefault?: boolean;
}

const ItemOptionDialog: React.FC<ItemOptionDialogProps> = (props) => {
  const openSnack = useSetRecoilState(SnackState);
  const [edit, setEdit] = useState<ItemOption>({});
  useEffect(() => {
    const io: ItemOption = {};
    io.id = props.editOption?.id;
    io.itemId = props.editOption?.itemId;
    io.status = props.editOption?.status;
    io.name = props.editOption?.name;
    io.created = props.editOption?.created;
    setEdit({ ...io });
  }, [props.editOption]);

  const handleChangeOptionName = (e: any) => {
    setEdit({ ...edit, name: e.target.value });
  };
  const handleChangeOptionStatus = (e: any) => {
    const status = e.target.value === "SHOW" ? "SHOW" : "HIDDEN";
    setEdit({ ...edit, status: status });
  };

  const handleSave = (e: any) => {
    gqladm
      .saveItemOption(edit)
      .then((result) => {
        if (result) props.onClose(true);
      })
      .catch((err) => {
        console.log("Error", err);
        openSnack({ msg: "Error on saveItemOption", severity: "error" });
      });
  };

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>Item Option {edit.id === undefined ? "등록" : "수정"}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs={4}>옵션명</Grid>
          <Grid xs={8}>
            <TextField value={edit.name} onChange={handleChangeOptionName} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={4}>상태</Grid>
          <Grid xs={8}>
            <Select
              disabled={props.isDefault}
              value={props.isDefault ? ("SHOW" as ItemOptionStatus) : edit.status}
              onChange={handleChangeOptionStatus}
              defaultValue="SHOW">
              {ItemOptionStatusArr.map((ios) => (
                <option key={ios} value={ios}>
                  {ios}
                </option>
              ))}
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>{edit.id === undefined ? "등록" : "수정"}</Button>
        <Button onClick={() => props.onClose()}>취소</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemOptionDialog;
