import React, { useEffect, useState } from "react";
import { gqladm } from "../../../graphql/gqladm";
import {
  Order,
  OrderSearchOption,
  SearchResult,
  CommonUtil as cu,
  getKrOrderStatus,
} from "../../../types/elements";
import DashPanel from "../../molecules/DashPanel";
import { Table, TableBody, TableFooter, TableHead, TableRow, TableCell } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import ItemCard from "../../molecules/ItemCard";

const OrderPanel: React.FC = (props) => {
  const [sopt, setSopt] = useState<OrderSearchOption>({
    limit: 10,
    offset: 0,
    ordering: "created_desc",
  });
  const [result, setResult] = useState<SearchResult<Order>>({});

  const load = (opt: OrderSearchOption) => {
    gqladm
      .getOrders({ ...sopt, ...opt })
      .then((result) => {
        // console.log("result");
        setResult(result);
        setSopt({ ...sopt, offset: result.offset ? result.offset : sopt.offset });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (page: number) => {
    console.log("handleChangePage", page);
    const offset = (page - 1) * (sopt.limit ? sopt.limit : 10);
    if (offset !== sopt.offset) {
      load({ ...sopt, offset: offset });
    }
  };

  useEffect(() => {
    load(sopt);
  }, []);

  return (
    <DashPanel title="최근 주문" actions={<div></div>}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Items</TableCell>
            <TableCell>상태</TableCell>
            <TableCell>금액</TableCell>
            <TableCell>주문생성</TableCell>
            <TableCell>Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {result.list?.map((o, idx) => {
            return (
              <TableRow key={idx}>
                <TableCell>{o.id}</TableCell>
                <TableCell>
                  <div style={{ display: "flex" }}>
                    {o.orderItems?.map(
                      (oi, oiidx) => oi.item && <ItemCard key={oiidx} type="mini" item={oi.item} />
                    )}
                  </div>
                </TableCell>
                <TableCell>{getKrOrderStatus(o.status)}</TableCell>
                <TableCell>{o.price}</TableCell>
                <TableCell>{o.created && cu.getFormattedDate(o.created, "MM/dd HH:mm")}</TableCell>
                <TableCell>{o.updated && cu.getFormattedDate(o.updated, "MM/dd HH:mm")}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={6}>
              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <Pagination
                  onChange={(e, p) => handleChangePage(p)}
                  count={Math.ceil(
                    (result.total ? result.total : 0) / (result?.limit ? result.limit : 10)
                  )}
                  page={
                    Math.ceil(
                      (result?.offset ? result.offset : 0) / (result?.limit ? result.limit : 10)
                    ) + 1
                  }
                />
              </div>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </DashPanel>
  );
};

export default OrderPanel;
