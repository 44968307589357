import { useEffect, useState } from "react";
import { gqladm } from "../graphql/gqladm";
import { DAY, HOUR } from "../types/elements";

const useDashUserStatistics = () => {
  const [lastLogin1hour, setLastLogin12hour] = useState<number | undefined>();
  const [lastLogin24hour, setLastLogin24hour] = useState<number | undefined>();
  useEffect(() => {
    const currentLogin = Date.now() + DAY / 12;
    gqladm.getUsers({ limit: 1, expiredAfter: currentLogin - HOUR }).then((result) => {
      if (result.total !== undefined) setLastLogin12hour(result.total);
    });
    gqladm.getUsers({ limit: 1, expiredAfter: currentLogin - DAY }).then((result) => {
      if (result.total !== undefined) setLastLogin24hour(result.total);
    });
  }, []);
  return { lastLogin1hour, lastLogin24hour };
};
export default useDashUserStatistics;
