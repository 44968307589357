import { Search } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PublicAddressAPI, SearchedAddress } from "../../api/PublicAddressAPI";

interface AddressSelectDialogProps {
  open?: boolean;
  onClose?: () => any;
  onSelect?: (se: SearchedAddress) => any;
  children?: React.ReactNode;
}
const AddressSelectDialog: React.FC<AddressSelectDialogProps> = (props) => {
  const [localOpen, setLocalOpen] = useState<boolean>(false);
  useEffect(() => {
    if (props.open !== undefined) setLocalOpen(props.open);
  }, [props.open]);
  const handleClose = () => {
    setLocalOpen(false);
    props.onClose && props.onClose();
  };

  const [list, setList] = useState<SearchedAddress[]>([]);

  const [text, setText] = useState<string>("");
  const handleSearch = () => {
    console.log("handleSearch", text);
    if (text)
      PublicAddressAPI.searchAddress(text).then((result) => {
        console.log(result);
        setList(result.data);
      });
  };

  const handleSelect = (se: SearchedAddress) => {
    props.onSelect && props.onSelect(se);
    handleClose();
  };

  return (
    <div style={{ display: "inline-block" }}>
      {props.children && <div onClick={() => setLocalOpen(true)}>{props.children}</div>}
      <Dialog open={localOpen} onClose={handleClose}>
        <DialogTitle>우편번호 검색</DialogTitle>
        <DialogContent>
          <div>
            <Paper>
              <TextField
                variant="filled"
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={(e) => (e.key === "Enter" ? handleSearch() : undefined)}
              />
              <IconButton onClick={handleSearch}>
                <Search />
              </IconButton>
            </Paper>
            <div>
              {list.map((se) => {
                return (
                  <Paper style={{ margin: "0.5rem", padding: "0.5rem" }}>
                    <div>
                      우편번호{se.zipNo}
                      <Button variant="contained" onClick={() => handleSelect(se)}>
                        선택
                      </Button>
                      <br />
                      도로명: {se.roadAddr}
                      <br />
                      지번: {se.jibunAddr}
                    </div>
                  </Paper>
                );
              })}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>닫기</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddressSelectDialog;
