import React from "react";
import { UILoad } from "../../types/elements";
interface UILoadElProps {
  uiload: UILoad;
  init?: any;
  error?: any;
  success?: any;
  loading?: any;
}
const UILoadEl: React.FC<UILoadElProps> = (props) => {
  switch (props.uiload) {
    case "init":
      return <>{props.init}</>;
    case "error":
      return <>{props.error}</>;
    case "success":
      return <>{props.success}</>;
    case "loading":
      return <>{props.loading}</>;
  }
};

export default UILoadEl;
