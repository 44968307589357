import { gql } from "@apollo/react-hooks";
import {
  AdminHistory,
  AdminHistorySearchOption,
  convertAdminHistory,
  convertWithDrawUser,
  FcmPush,
  WithDrawUserSearchOption,
} from "../../types/adm_elements";
import {
  Consignment,
  ConsignmentItem,
  ConsignmentItemStatus,
  ConsignmentSearchOption,
  ConsignmentStatus,
  convertConsignment,
  convertConsignmentItem,
  convertRental,
  convertServiceRequest,
  convertStatusCount,
  convertStyling,
  MultiImageTarget,
  Order,
  Rental,
  // RentalItem,
  RentalSearchOption,
  RentalStatus,
  SearchResult,
  ServiceRequest,
  ServiceRequestSearchOption,
  SimpleResult,
  StatusCount,
  Styling,
  StylingSearchOption,
  StylingStatus,
  User,
  UserAlertInfoSearchCond,
  WithDrawUser,
} from "../../types/elements";
import { client } from "../client";

interface QueryIF {
  admin?: {
    adminHistories?: SearchResult<AdminHistory>;
    serviceRequests?: SearchResult<ServiceRequest>;
    consignments?: SearchResult<Consignment>;
    consignmentStatusCounts?: StatusCount[];
    consignmentItem?: ConsignmentItem;
    consignmentItemsByConsignmentId?: ConsignmentItem[];
    rentals?: SearchResult<Rental>;
    rentalStatusCounts?: StatusCount[];
    stylings?: SearchResult<Styling>;
    stylingStatusCounts?: StatusCount[];
    withDrawUsers?: SearchResult<WithDrawUser>;
  };
}
interface MutationIF {
  admin?: {
    // FcmPush
    putFcmPush?: SimpleResult;
    delFcmPush?: SimpleResult;
    addFcmPushUsers?: SimpleResult;
    addFcmPushUsersFromConds?: SimpleResult;
    delFcmPushUsers?: SimpleResult;
    addFcmPushMessage?: SimpleResult;
    sendFcmPush?: SimpleResult;
    //
    deleteMultiImage?: SimpleResult;
    // Consignment
    addNewConsignment?: SimpleResult;
    updateConsignmentStatus?: SimpleResult;
    updateConsignment?: SimpleResult;
    updateConsignmentItemStatus?: SimpleResult;
    deleteConsignments?: SimpleResult;
    // ConsignmentItem
    addNewConsignmentItem?: SimpleResult;
    updateConsignmentItem?: SimpleResult;
    deleteConsignmentItems?: SimpleResult;
    // Rental
    addNewRental?: SimpleResult;
    updateRentalStatus?: SimpleResult;
    setRentalItem?: SimpleResult;
    updateRental?: SimpleResult;
    // Styling
    addNewStyling?: SimpleResult;
    updateStylingStatus?: SimpleResult;
    updateStyling?: SimpleResult;
  };
}

export const AdminUserApi = {
  adminHistories: async (sopt: AdminHistorySearchOption): Promise<SearchResult<AdminHistory>> =>
    client
      .query<QueryIF>({
        query: gql`
          query AdminHistories($sopt: AdminHistorySearchInput) {
            admin {
              adminHistories(sopt: $sopt) {
                total
                limit
                offset
                list {
                  id
                  created
                  admin_id
                  type
                  target_id
                  user_id
                  msg
                  user {
                    name
                  }
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        const ah = result.data.admin?.adminHistories;
        if (ah)
          return { ...ah, list: ah.list ? ah.list.map((r) => convertAdminHistory(r) ?? {}) : [] };
        throw new Error("Error");
      }),
  withDrawUsers: async (sopt: WithDrawUserSearchOption): Promise<SearchResult<WithDrawUser>> =>
    client
      .query<QueryIF>({
        query: gql`
          query WithDrawUsers($sopt: WithDrawUserSearchInput) {
            admin {
              withDrawUsers(sopt: $sopt) {
                total
                limit
                offset
                list {
                  id
                  admin_id
                  created

                  user_id
                  email
                  name
                  phone
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        const ah = result.data.admin?.withDrawUsers;
        if (ah)
          return { ...ah, list: ah.list ? ah.list.map((r) => convertWithDrawUser(r) ?? {}) : [] };
        throw new Error("Error");
      }),
};

export const FcmPushApi = {
  putFcmPush(fp: FcmPush): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation PutFcmPush($fp: FcmPushInput) {
            admin {
              putFcmPush(fp: $fp) {
                ok
                count
                id
                msg
              }
            }
          }
        `,
        variables: { fp },
      })
      .then((result) => {
        if (result.data?.admin?.putFcmPush) return result.data.admin.putFcmPush;
        throw new Error("Err");
      });
  },
  delFcmPush(fp_id: number): Promise<SimpleResult> {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation DelFcmPush($fp_id: BigInt) {
            admin {
              delFcmPush(fp_id: $fp_id) {
                ok
                count
                msg
              }
            }
          }
        `,
        variables: { fp_id },
      })
      .then((result) => {
        if (result.data?.admin?.delFcmPush) return result.data.admin.delFcmPush;
        throw new Error("Err");
      });
  },
  addFcmPushUsers(fp_id: number, user_ids: number[]) {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddFcmPushUsers($fp_id: BigInt, $user_ids: [BigInt]) {
            admin {
              addFcmPushUsers(fp_id: $fp_id, user_ids: $user_ids) {
                ok
                count
                id
                msg
                errtype
              }
            }
          }
        `,
        variables: { fp_id, user_ids },
      })
      .then((result) => {
        if (result.data?.admin?.addFcmPushUsers) return result.data?.admin?.addFcmPushUsers;
        throw new Error("Err");
      });
  },
  addFcmPushUsersFromConds(fp_id: number, conds: UserAlertInfoSearchCond[]) {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddFcmPushUsersFromConds($fp_id: BigInt, $conds: [UserAlertInfoSearchCond]) {
            admin {
              addFcmPushUsersFromConds(fp_id: $fp_id, conds: $conds) {
                ok
                count
                id
                msg
                errtype
              }
            }
          }
        `,
        variables: { fp_id, conds },
      })
      .then((result) => {
        if (result.data?.admin?.addFcmPushUsersFromConds)
          return result.data?.admin?.addFcmPushUsersFromConds;
        throw new Error("Err");
      });
  },
  delFcmPushUsers(fp_id: number, user_ids: number[]) {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation DelFcmPushUsers($fp_id: BigInt, $user_ids: [BigInt]) {
            admin {
              delFcmPushUsers(fp_id: $fp_id, user_ids: $user_ids) {
                ok
                count
                id
                msg
                errtype
              }
            }
          }
        `,
        variables: { fp_id, user_ids },
      })
      .then((result) => {
        if (result.data?.admin?.delFcmPushUsers) return result.data?.admin?.delFcmPushUsers;
        throw new Error("Err");
      });
  },
  addFcmPushMessage(fp_id: number, tokens: string[]) {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddFcmPushMessage($fp_id: BigInt, $tokens: [String]) {
            admin {
              addFcmPushMessage(fp_id: $fp_id, tokens: $tokens) {
                ok
                count
                id
                msg
              }
            }
          }
        `,
        variables: { fp_id, tokens },
      })
      .then((result) => {
        if (result.data?.admin?.addFcmPushMessage) return result.data?.admin?.addFcmPushMessage;
        throw new Error("Err");
      });
  },
  sendFcmPush(fp_id: number) {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SendFcmPush($fp_id: BigInt) {
            admin {
              sendFcmPush(fp_id: $fp_id) {
                ok
                count
                id
                hasNext
                limit
                total
              }
            }
          }
        `,
        variables: { fp_id },
      })
      .then((result) => {
        if (result.data?.admin?.sendFcmPush) return result.data?.admin?.sendFcmPush;
        throw new Error("Err");
      });
  },
};
// ! ConsignmentApi
export const ConsignmentApi = {
  consignments: async (sopt: ConsignmentSearchOption): Promise<SearchResult<Consignment>> => {
    return client
      .query<QueryIF>({
        query: gql`
          query Consignments($sopt: ConsignmentSearchInput) {
            admin {
              consignments(sopt: $sopt) {
                total
                offset
                limit
                list {
                  id
                  created
                  updated
                  status
                  user_id

                  request_detail
                  delivery_zipcode
                  delivery_addr1
                  delivery_addr2

                  account_bank
                  account_number
                  account_name

                  user {
                    name
                    email
                    phone
                  }

                  items {
                    id
                    designer
                    brand
                    pop
                    yom
                    origin_price
                    expect_price
                    status

                    images {
                      id
                      url
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result.data.admin?.consignments)
          return {
            ...result.data.admin.consignments,
            list: result.data.admin.consignments.list?.map((r) => convertConsignment(r) ?? r),
          };
        else throw new Error("Err");
      });
  },
  consignmentStatusCounts: (): Promise<StatusCount[]> => {
    return client
      .query<QueryIF>({
        query: gql`
          query consignmentStatusCounts {
            admin {
              consignmentStatusCounts {
                status
                count
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data.admin?.consignmentStatusCounts)
          return result.data.admin.consignmentStatusCounts.map((r) => convertStatusCount(r) ?? r);
        else throw new Error("Err");
      });
  },

  consignmentItem: (consignmentItemId: number): Promise<ConsignmentItem> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetConsignmentItem($id: BigInt) {
            admin {
              consignmentItem(id: $id) {
                id
                consignment_id
                status
                count
                origin_price
                expect_price
                category
                designer
                brand
                yom
                pop

                images {
                  id
                  url
                }
              }
            }
          }
        `,
        variables: { id: consignmentItemId },
      })
      .then((result) => {
        const item = result.data.admin?.consignmentItem;
        if (item) return convertConsignmentItem(item) ?? item;
        else throw new Error("Err");
      });
  },
  consignmentItemsByConsignmentId: (consignment_id: number): Promise<ConsignmentItem[]> => {
    return client
      .query<QueryIF>({
        query: gql`
          query ConsignmentItemsByConsignmentId($id: BigInt) {
            admin {
              consignmentItemsByConsignmentId(id: $id) {
                id
                consignment_id
                status
                count
                origin_price
                expect_price
                category
                designer
                brand
                yom
                pop
              }
            }
          }
        `,
        variables: { id: consignment_id },
      })
      .then((result) => {
        const items = result.data.admin?.consignmentItemsByConsignmentId;
        if (items) return items.map((r) => convertConsignmentItem(r) ?? r);
        else throw new Error("Err");
      });
  },

  addNewConsignmentItem: async (consignment_id: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddNewConsignmentItem($consignment_id: BigInt) {
            admin {
              addNewConsignmentItem(consignment_id: $consignment_id) {
                ok
                id
              }
            }
          }
        `,
        variables: { consignment_id },
      })
      .then((result) => {
        const item = result.data?.admin?.addNewConsignmentItem;
        if (item) return item;
        else throw new Error("Err");
      });
  },
  updateConsignmentStatus: async (id: number, status: ConsignmentStatus): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation UpdateConsignmentStatus($id: BigInt, $status: String) {
            admin {
              updateConsignmentStatus(id: $id, status: $status) {
                ok
                id
                count
              }
            }
          }
        `,
        variables: {
          id,
          status,
        },
      })
      .then((result) => {
        if (result.data?.admin?.updateConsignmentStatus)
          return result.data.admin.updateConsignmentStatus;
        else throw new Error("Err");
      });
  },

  updateConsignment: async (consignment: Consignment): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation UpdateConsignment($consignment: ConsignmentInput) {
            admin {
              updateConsignment(consignment: $consignment) {
                ok
                count
                msg
              }
            }
          }
        `,
        variables: { consignment },
      })
      .then((result) => {
        if (result.data?.admin?.updateConsignment) return result.data?.admin?.updateConsignment;
        else throw new Error("Err");
      });
  },
  updateConsignmentItemStatus: async (
    id: number,
    status: ConsignmentItemStatus
  ): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation UpdateConsignmentItemStatus($id: BigInt, $status: String) {
            admin {
              updateConsignmentItemStatus(id: $id, status: $status) {
                ok
                id
                count
              }
            }
          }
        `,
        variables: {
          id,
          status,
        },
      })
      .then((result) => {
        if (result.data?.admin?.updateConsignmentItemStatus)
          return result.data.admin.updateConsignmentItemStatus;
        else throw new Error("Err");
      });
  },
  updateConsignmentItem: async (consignmentItem: ConsignmentItem): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation UpdateConsignmentItem($consignmentItem: ConsignmentItemInput) {
            admin {
              updateConsignmentItem(consignmentItem: $consignmentItem) {
                ok
                id
              }
            }
          }
        `,
        variables: { consignmentItem },
      })
      .then((result) => {
        if (result.data?.admin?.updateConsignmentItem)
          return result.data.admin.updateConsignmentItem;
        else throw new Error("Err");
      });
  },

  deleteConsignmentItems: async (ids: number[]): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation DeleteConsignmentItems($ids: [BigInt]) {
            admin {
              deleteConsignmentItems(ids: $ids) {
                ok
                count
              }
            }
          }
        `,
        variables: { ids },
      })
      .then((result) => {
        if (result.data?.admin?.deleteConsignmentItems)
          return result.data.admin.deleteConsignmentItems;
        else throw new Error("Err");
      });
  },
};

// ! Rental API
export const RentalApi = {
  rentalStatusCounts: (): Promise<StatusCount[]> => {
    return client
      .query<QueryIF>({
        query: gql`
          query RentalStatusCounts {
            admin {
              rentalStatusCounts {
                status
                count
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data.admin?.rentalStatusCounts)
          return result.data.admin.rentalStatusCounts.map((r) => convertStatusCount(r) ?? r);
        else throw new Error("Err");
      });
  },
  updateRentalStatus: (id: number, status: RentalStatus): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation UpdateRentalStatus($id: BigInt, $status: String) {
            admin {
              updateRentalStatus(id: $id, status: $status) {
                ok
                id
                count
              }
            }
          }
        `,
        variables: { id, status },
      })
      .then((result) => {
        if (result.data?.admin?.updateRentalStatus) return result.data.admin.updateRentalStatus;
        throw new Error("Err");
      }),

  // setRentalItem: async (rentalItem: RentalItem): Promise<SimpleResult> =>
  //   client
  //     .mutate<MutationIF>({
  //       mutation: gql`
  //         mutation SetRentalItem($rentalItem: RentalItemInput) {
  //           admin {
  //             setRentalItem(rentalItem: $rentalItem) {
  //               ok
  //               count
  //               id
  //             }
  //           }
  //         }
  //       `,
  //       variables: { rentalItem },
  //     })
  //     .then((result) => {
  //       if (result.data?.admin?.setRentalItem) return result.data.admin.setRentalItem;
  //       else throw "Err";
  //     }),
  updateRental: (rental: Rental): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation UpdateRental($rental: RentalInput) {
            admin {
              updateRental(rental: $rental) {
                ok
                count
                msg
                id
              }
            }
          }
        `,
        variables: { rental },
      })
      .then((result) => {
        if (result.data?.admin?.updateRental) return result.data.admin.updateRental;
        else throw new Error("Err");
      }),
};

// ! Styling API
export const StylingApi = {
  stylingStatusCounts: (): Promise<StatusCount[]> => {
    return client
      .query<QueryIF>({
        query: gql`
          query StylingStatusCounts {
            admin {
              stylingStatusCounts {
                status
                count
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data.admin?.stylingStatusCounts)
          return result.data.admin.stylingStatusCounts.map((r) => convertStatusCount(r) ?? r);
        else throw new Error("Err");
      });
  },
  updateStylingStatus: (id: number, status: StylingStatus): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation UpdateStylingStatus($id: BigInt, $status: String) {
            admin {
              updateStylingStatus(id: $id, status: $status) {
                ok
                id
                count
              }
            }
          }
        `,
        variables: { id, status },
      })
      .then((result) => {
        if (result.data?.admin?.updateStylingStatus) return result.data.admin.updateStylingStatus;
        throw new Error("Err");
      }),
  updateStyling: (styling: Styling): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation UpdateStyling($styling: StylingInput) {
            admin {
              updateStyling(styling: $styling) {
                ok
                count
                id
              }
            }
          }
        `,
        variables: { styling },
      })
      .then((result) => {
        if (result.data?.admin?.updateStyling) return result.data.admin.updateStyling;
        throw new Error("Err");
      }),
};

// ! ServiceRequestApi
export const ServiceRequestApi = {
  serviceRequests(sopt: ServiceRequestSearchOption): Promise<SearchResult<ServiceRequest>> {
    return client
      .query<QueryIF>({
        query: gql`
          query ServiceRequests($sopt: ServiceRequestSearchInput) {
            admin {
              serviceRequests(sopt: $sopt) {
                total
                offset
                limit
                list {
                  id
                  user_id
                  created
                  updated

                  type
                  consignment {
                    id
                    created
                    updated
                    status
                  }
                  rental {
                    id
                    created
                    updated
                    status
                  }
                  styling {
                    id
                    created
                    updated
                    status
                  }
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result.data.admin?.serviceRequests)
          return {
            ...result.data.admin?.serviceRequests,
            list: result.data.admin.serviceRequests.list?.map((r) => convertServiceRequest(r) ?? r),
          };
        throw new Error("Err");
      });
  },
  rentals(sopt: RentalSearchOption): Promise<SearchResult<Rental>> {
    return client
      .query<QueryIF>({
        query: gql`
          query Rentals($sopt: RentalSearchInput) {
            admin {
              rentals(sopt: $sopt) {
                total
                offset
                limit
                list {
                  id
                  user_id
                  created
                  updated
                  status

                  rental_start
                  rental_end
                  days
                  budget

                  receipt_target
                  receipt_number

                  user {
                    id
                    name
                    email
                    phone
                  }
                  images {
                    id
                    url
                  }
                  companyImages {
                    id
                    url
                  }
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result.data.admin?.rentals)
          return {
            ...result.data.admin.rentals,
            list: result.data.admin.rentals.list?.map((r) => convertRental(r) ?? r),
          };
        throw new Error("Err");
      });
  },
  stylings(sopt: StylingSearchOption): Promise<SearchResult<Styling>> {
    return client
      .query<QueryIF>({
        query: gql`
          query Stylings($sopt: StylingSearchInput) {
            admin {
              stylings(sopt: $sopt) {
                total
                offset
                limit
                list {
                  id
                  user_id
                  created
                  updated
                  status

                  zipcode
                  addr1
                  addr2
                  areatype
                  areasize
                  expect_month
                  budget
                  request_detail

                  user {
                    id
                    name
                    email
                    phone
                  }
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then((result) => {
        if (result.data.admin?.stylings)
          return {
            ...result.data.admin.stylings,
            list: result.data.admin.stylings.list?.map((r) => convertStyling(r) ?? r),
          };
        throw new Error("Err");
      });
  },
  addNewConsignment: async (user_id: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddNewConsignment($user_id: BigInt) {
            admin {
              addNewConsignment(user_id: $user_id) {
                ok
                id
              }
            }
          }
        `,
        variables: { user_id },
      })
      .then((result) => {
        if (result.data?.admin?.addNewConsignment) return result.data.admin.addNewConsignment;
        throw new Error("Err");
      });
  },
  // ! addNewRental
  addNewRental: async (user_id: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddNewRental($user_id: BigInt) {
            admin {
              addNewRental(user_id: $user_id) {
                ok
                id
              }
            }
          }
        `,
        variables: { user_id },
      })
      .then((result) => {
        if (result.data?.admin?.addNewRental) return result.data.admin.addNewRental;
        throw new Error("Err");
      });
  },

  addNewStyling: async (user_id: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddNewStyling($user_id: BigInt) {
            admin {
              addNewStyling(user_id: $user_id) {
                ok
                id
              }
            }
          }
        `,
        variables: { user_id },
      })
      .then((result) => {
        if (result.data?.admin?.addNewStyling) return result.data.admin.addNewStyling;
        throw new Error("Err");
      });
  },
};

export const MultiImageApi = {
  deleteMultiImage(target: MultiImageTarget, targetId: number, image_id: number) {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation DeleteMultiImage($target: String, $targetId: BigInt, $image_id: BigInt) {
            admin {
              deleteMultiImage(target: $target, targetId: $targetId, image_id: $image_id) {
                ok
                count
              }
            }
          }
        `,
        variables: {
          target,
          targetId,
          image_id,
        },
      })
      .then((result) => {
        if (result.data?.admin?.deleteMultiImage) return result.data.admin.deleteMultiImage;
        throw new Error("Err");
      });
  },
};
