import React, { useState, useEffect } from "react";
import {
  TableContainer,
  TableRow,
  TableHead,
  Table,
  TableCell,
  TableBody,
  Button,
  Dialog,
  Input,
  DialogContent,
  DialogTitle,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { AdminUser } from "../../types/adm_elements";
import { AdminUserAPI } from "../../api/AdminUserAPI";
import { useSetRecoilState } from "recoil";
import { SnackStateSelector } from "../../store/states/SnackState";
import { gqladm } from "../../graphql/gqladm";
interface AdminUserDialogProps {
  open: boolean;
  onClose: (isCompleted?: boolean) => any;
  adminUser?: AdminUser;
}
const AdminUserDialog: React.FC<AdminUserDialogProps> = (props) => {
  const openSnack = useSetRecoilState(SnackStateSelector);
  const [adminUser, setAdminUser] = useState<AdminUser>({});

  useEffect(() => {
    if (props.open === true) setAdminUser({ ...props.adminUser });
  }, [props.open]);

  const handleUpdateFields = (fields: string) => (e: any) => {
    const mobj: any = {};
    mobj[fields] = e.currentTarget.value;
    setAdminUser({ ...adminUser, ...mobj });
  };
  const handleChangeBlocked = (e: any, checked: boolean) => {
    setAdminUser({ ...adminUser, blocked: checked ? Date.now() : undefined });
  };

  const handleTreat = () => {
    // if (adminUser.id === undefined) {
    gqladm
      .putAdminUser({
        id: adminUser.id,
        name: adminUser.name,
        phone: adminUser.phone,
        email: adminUser.email,
        blocked: adminUser.blocked,
      })
      .then((result) => {
        if (result.id !== undefined) props.onClose(true);
      })
      .catch((err) => {
        openSnack({ msg: "Error on putAdminUser " + err, severity: "error" });
      });

    //   AdminUserAPI.postAdminUser(adminUser)
    //     .then((result) => {
    //       props.onClose(true);
    //     })
    //     .catch((err) => {
    //       openSnack({ msg: "Error on postAdminUser " + err, severity: "error" });
    //     });
    // } else {
    //   AdminUserAPI.putAdminUser(adminUser)
    //     .then((result) => {
    //       props.onClose(true);
    //     })
    //     .catch((err) => {
    //       openSnack({ msg: "Error on putAdminUser " + err, severity: "error" });
    //     });
    // }
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>{adminUser.id === undefined ? "Register" : "Modify"} Admin User</DialogTitle>
      <DialogContent>
        name : <Input value={adminUser.name} onChange={handleUpdateFields("name")} /> <br />
        phone : <Input value={adminUser.phone} onChange={handleUpdateFields("phone")} /> <br />
        email : <Input value={adminUser.email} onChange={handleUpdateFields("email")} /> <br />
        blocked:
        <Checkbox
          checked={!(adminUser.blocked === undefined || adminUser.blocked === null)}
          onChange={handleChangeBlocked}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleTreat}>{adminUser.id === undefined ? "Register" : "Modify"}</Button>
        <Button onClick={() => props.onClose()}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminUserDialog;
