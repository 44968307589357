import { gql } from "@apollo/react-hooks";
import { client } from "../client";
import { Order, OrderAddress, OrderReceipt, SimpleResult, User } from "../../types/elements";

interface QueryIF {
  admin?: {};
}
interface MutationIF {
  admin?: {
    addOfflineOrder?: SimpleResult;
    changeOrderAddress?: SimpleResult;
    changeOrderReceipt?: SimpleResult;
    changeOrderItemCount?: SimpleResult;
    changeOrderPrice?: SimpleResult;
    syncOrderPrice?: SimpleResult;
    deleteOrder?: SimpleResult;
  };
}

export const deleteOrder = async (order_id: number): Promise<SimpleResult> =>
  client
    .mutate<MutationIF>({
      mutation: gql`
        mutation SyncOrderPrice($order_id: BigInt) {
          admin {
            deleteOrder(order_id: $order_id) {
              ok
              msg
            }
          }
        }
      `,
      variables: { order_id },
    })
    .then((result) => {
      if (result.data?.admin?.deleteOrder) return result.data.admin.deleteOrder;
      else throw new Error("Err");
    });

export const changeOrderPrice = async (order_id: number, price: number): Promise<SimpleResult> =>
  client
    .mutate<MutationIF>({
      mutation: gql`
        mutation ChangeOrderPrice($order_id: BigInt, $price: BigInt) {
          admin {
            changeOrderPrice(order_id: $order_id, price: $price) {
              ok
              errtype
              id
              amount
            }
          }
        }
      `,
      variables: { order_id, price },
    })
    .then((result) => {
      if (result.data?.admin?.changeOrderPrice) return result.data.admin.changeOrderPrice;
      else throw new Error("Err");
    });

export const syncOrderPrice = async (order_id: number): Promise<SimpleResult> =>
  client
    .mutate<MutationIF>({
      mutation: gql`
        mutation SyncOrderPrice($order_id: BigInt) {
          admin {
            syncOrderPrice(order_id: $order_id) {
              ok
              errtype
              id
              amount
            }
          }
        }
      `,
      variables: { order_id },
    })
    .then((result) => {
      if (result.data?.admin?.syncOrderPrice) return result.data.admin.syncOrderPrice;
      else throw new Error("Err");
    });

export const OrderApi = {
  addOfflineOrder: async (order: Order): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddOfflineOrder($order: OrderInput) {
            admin {
              addOfflineOrder(order: $order) {
                ok
                id
                count
                msg
              }
            }
          }
        `,
        variables: { order },
      })
      .then((result) => {
        if (result.data?.admin?.addOfflineOrder) return result.data.admin.addOfflineOrder;
        else throw new Error("Err");
      }),
  changeOrderAddress: async (oa: OrderAddress): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation ChangeOrderAddress($oa: OrderAddressInput) {
            admin {
              changeOrderAddress(orderAddress: $oa) {
                ok
                id
                msg
              }
            }
          }
        `,
        variables: { oa },
      })
      .then((result) => {
        if (result.data?.admin?.changeOrderAddress) return result.data?.admin?.changeOrderAddress;
        throw new Error("Err");
      }),
  changeOrderReceipt: async (or: OrderReceipt): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation ChangeOrderReceipt($or: OrderReceiptInput) {
            admin {
              changeOrderReceipt(orderReceipt: $or) {
                ok
                id
                msg
              }
            }
          }
        `,
        variables: { or },
      })
      .then((result) => {
        if (result.data?.admin?.changeOrderReceipt) return result.data?.admin?.changeOrderReceipt;
        throw new Error("Err");
      }),
  changeOrderItemCount: async (
    order_id: number,
    item_id: number,
    item_option_id: number,
    count: number
  ): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation ChangeOrderItemCount(
            $order_id: BigInt
            $item_id: BigInt
            $item_option_id: BigInt
            $count: BigInt
          ) {
            admin {
              changeOrderItemCount(
                order_id: $order_id
                item_id: $item_id
                item_option_id: $item_option_id
                count: $count
              ) {
                ok
                count
                amount
                id
                msg
              }
            }
          }
        `,
        variables: { order_id, item_id, item_option_id, count },
      })
      .then((result) => {
        if (result.data?.admin?.changeOrderItemCount)
          return result.data?.admin?.changeOrderItemCount;
        throw new Error("Err");
      }),
};
